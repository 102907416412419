import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/loginFormStyle.css";
import ProductInput from "../customInputs/ProductInput";
import {
  useUserTypeSelectQuery,
  useSubCategorySelectQuery,
  useGetNewOtpMutation,
} from "../../store/services/loginService";
import CityInput from "../customInputs/CityInput";
import { useSelector } from "react-redux";
import { useCreateLeadApiMutation } from "../../store/services/registerApiService";
import "../../styles/inquiryFormStyle.css";
import RegisterNowPopup from "../general/RegisterNowCpPopup";

function CreateLead(props) {
  const loginUserData = useSelector((state) => state?.userReducer?.userData);
  
  const [otpInput, setOtpInput] = useState(true);
  const [signupBtn, setSignupBtn] = useState(false);
  const [validationCheck, setValidationCheck] = useState("");
  const [validationSuccess, setValidationSuccess] = useState("");
  const [successStatus, setSuccessStatus] = useState("fade");
  const [registerNowPopup, setRegisterNowPopup] = useState(false);
  

  const closeSuccessModal = () => {
    setSuccessStatus("fade");
    props.closeCreateLead();
  };

  const closeRegPopup = () => {
    setRegisterNowPopup(false);
    props.closeCreateLead()
  };

  // subcategory list profession
  const subcategoryResp = useSubCategorySelectQuery();
  const professionSelectData =
    subcategoryResp?.isSuccess &&
    !subcategoryResp?.isLoading &&
    subcategoryResp.status === "fulfilled" &&
    !subcategoryResp?.isError &&
    subcategoryResp?.data?.subCategoryList;

  // API FOR USERTYPE
  const userTypeResp = useUserTypeSelectQuery();
  const userTypeData =
    userTypeResp?.isSuccess &&
    !userTypeResp?.isLoading &&
    userTypeResp.status === "fulfilled" &&
    !userTypeResp?.isError &&
    userTypeResp?.data?.categoryList;

    // const { name, email, phone, product, subCategory, userType, city, state, country, pin, role, sourceType ,sourceQId ,sourceName  } 

  // Create Lead fields
 
  const [leadDetails, setLeadDetails] = useState({
    name: "",
    email: "",
    phone: "",
    subCategory: "",
    product: [],
    city: "",
    userType: "",
    state: "",
  });

  const changeHandler = (e) => {
    setLeadDetails({ ...leadDetails, [e.target.name]: e.target.value });
   
  };

  const selectedProductData = (products) => {
    setLeadDetails({ ...leadDetails, product: products });
  };
  const cityHandler = (selectedCity) => {
    setLeadDetails({ ...leadDetails, city: selectedCity });
  };

  const [postLeadData, leadResp = {}] = useCreateLeadApiMutation();
  useEffect(() => {
    // console.log(leadResp?.data);
    if (
      leadResp &&
      !leadResp?.isLoading &&
      leadResp?.isSuccess &&
      !leadResp?.data?.success
    ) {
      setValidationCheck("Lead Already Registered");
      setTimeout(() => {
        setValidationCheck("");
      }, 6000);
    }
    if (
      leadResp &&
      !leadResp?.isLoading &&
      leadResp?.isSuccess &&
      leadResp?.data?.success
    ) {
      setSuccessStatus("d-flex");
    }
  }, [leadResp]);
  // submitHandler form
  const submitHandler = (e) => {
    e.preventDefault();
    var finalData = leadDetails;
    finalData.id = loginUserData?._id;
    finalData.role = loginUserData?.role;
    finalData.sourceQId = loginUserData?._id
    finalData.sourceName = loginUserData?.name
    finalData.sourceType = loginUserData?.role
    finalData.slug = leadDetails.userType === "64535fd33384aae2be60bb78" ? "Channel-Partner" : "Investor"
    console.log(finalData.slug );
    if (leadDetails.phone.length === 10) {
      setValidationCheck("");

      if (leadDetails.product.length > 0) {
        console.log(finalData);
        postLeadData(finalData);
      } else {
        setValidationCheck("Please select product/s");
        setTimeout(() => {
          setValidationCheck("");
        }, 5000);
      }
    } else {
      setValidationCheck("Enter 10 digit mobile number");
      setTimeout(() => {
        setValidationCheck("");
      }, 5000);
    }
  };

  useEffect(() => {
    if (window.screen.width >= 768) {
      window.scrollTo({ top: 500, left: 0, behavior: "instant" });
    }
    if (window.screen.width < 768) {
      window.scrollTo({ top: 280, left: 0, behavior: "instant" });
    }

    let mobInpt = document.getElementById("otp-num");
    mobInpt.oninput = () => {
      if (mobInpt.value.length > mobInpt.maxLength)
        mobInpt.value = mobInpt.value.slice(0, mobInpt.maxLength);
    };
  }, []);

  return (
    <>
      <div className="form_popups popup__container">
        <div className="chagne_pass_form">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex align-items-center position-relative justify-content-center">
                <div
                  className="formByScan_container CreatLeadFormWrapper h-auto"
                  id="formByScan_container"
                  style={{ overflow: "visible", padding: "10px 15px" }}
                >
                  <div
                    className="popup_close_icon createLead"
                    onClick={props.closeCreateLead}
                  >
                    <i
                      className="fa fa-xmark"
                      onClick={props.closeCreateLead}
                    ></i>
                  </div>
                  <form onSubmit={submitHandler} autoComplete="off">
                    <h2
                      className="form-heading text-center d-block mb-1"
                      style={{ fontSize: "22px" }}
                    >
                      Create New Lead
                    </h2>
                    {validationCheck !== "" && (
                      <p className="text-center text-danger mb-0">
                        {validationCheck}
                      </p>
                    )}
                    {validationSuccess !== "" && (
                      <p className="text-center text-success mb-0">
                        {validationSuccess}
                      </p>
                    )}
                    <div className="d-flex mb-1 mt-2 align-items-center justify-content-between">
                    </div>
                    <input
                      type="text"
                      placeholder="Name *"
                      name="name"
                      value={leadDetails.name}
                      onChange={changeHandler}
                      required
                    />
                    <input
                      type="number"
                      placeholder="Mobile Number *"
                      id="otp-num"
                      maxLength="10"
                      required
                      name="phone"
                      value={leadDetails.phone}
                      onChange={changeHandler}
                    />
                    <input
                      type="email"
                      placeholder="Email *"
                      name="email"
                      value={leadDetails.email}
                      onChange={changeHandler}
                      required
                    />
                    {/* Subcategory occupation */}
                    <select
                      className="form-select subCategorySelect"
                      id="userType"
                      name="userType"
                      value={leadDetails.userType}
                      onChange={changeHandler}
                     
                    >
                      <option value="" required>Select Role *</option>
                      {userTypeData &&
                        userTypeData?.map((item, index) => {
                          return (
                            <option key={index} value={item?._id} >{item?.name}</option>
                          )})}
                    
                    </select>
                    <select
                      className="form-select subCategorySelect"
                      name="subCategory"
                      value={leadDetails.subCategory}
                      onChange={changeHandler}
                    >
                      <option value="">Select Occupation</option>
                      {professionSelectData &&
                        professionSelectData?.map((item, index) => {
                          return (
                            <option key={index} value={item?._id}>
                              {item?.name}
                            </option>
                          );
                        })}
                    </select>

                    {/* multiSElect input */}
                    <div
                      className="createLeadMultiSelect"
                      style={{ marginBottom: "13px", marginTop:'13px' }}
                    >
                      <ProductInput sendProductData={selectedProductData} />
                    </div>

                    <div className="cityInput createLeadMultiSelect">
                      <CityInput
                        cityHandler={cityHandler}
                        cityPlaceholder={"Select City"}
                      />
                    </div>

                    <div className="mt-3 text-center">
                      <button className="subBtn"  disabled={signupBtn} >
                      Create Lead
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <SuccessModal successStatus={'d-flex'} /> */}
      <div
        className={`modal custom-modal align-items-center ${successStatus}`}
        id="formSuccessModal"
        style={{ background: "rgba(0,0,0,0.3)" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="formSuccessModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document"  style={{ width: "350px" }}>
          <div className="modal-content">
            <div className="modal-header " style={{ background: "#FF4B2B" }}>
              <h5 className="modal-title" id="formSuccessModalLabel">
                Success
              </h5>
              <button
                type="button"
                className="close"
                onClick={closeSuccessModal}
                // data-bs-dismiss='modal'
                // aria-label='Close'
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-popup-inner">
                <div className="form-icon success">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 130.2 130.2"
                  >
                    <circle
                      className="path circle"
                      fill="none"
                      strokeWidth="6"
                      strokeMiterlimit="10"
                      cx="65.1"
                      cy="65.1"
                      r="62.1"
                    />
                    <polyline
                      className="path check"
                      fill="none"
                      strokeWidth="6"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      points="100.2,40.2 51.5,88.8 29.8,67.5 "
                    />
                  </svg>
                </div>
                <div className="form-body">
                  <p style={{letterSpacing: "2px"}}>Your lead created successfully.</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Link
                to="#"
                className="popup-btn "
                style={{ background: "#FF4B2B" }}
                // data-bs-dismiss='modal'
                onClick={closeSuccessModal}
              >
                Okay
              </Link>
            </div>
          </div>
        </div>
      </div>
        { registerNowPopup && (
            <RegisterNowPopup closeRegPopup={closeRegPopup} />
          )
        }
          
    </>
  );
}

export default CreateLead;
