import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Api_Base_Url } from '../../constant/matcher'

const registerApiService = createApi({
    reducerPath: 'apiPopupForm',
    tagTypes: ['query'],
    baseQuery: fetchBaseQuery({
        baseUrl: Api_Base_Url
    }),
    endpoints: builder => {
        return {
            //==Fixed appointment api for prelead Dashboard
            fixedAppointmentApi: builder.mutation({
                query: (formData) => {
                    return {
                        url: 'fixedappoinment',
                        method: 'POST',
                        body: formData
                    }
                },
            }),
            
            // landing page register now api
            registerNowApi: builder.mutation({
                query: (details) => {
                    return {
                        url: 'register/register-now',
                        method: 'POST',
                        body: details,
                        
                    }
                },
            }),
            // langing page raise query
            raiseQueryApi: builder.mutation({
                query: (queryData) => {
                    return {
                        url: 'query/raise-query',
                        method: 'POST',
                        body: queryData
                    }
                },
                invalidatesTags: ['query']
            }),
            // my Raise Queries 
            myRaiseQueryApi: builder.query({
                query: (userId) => {
                    return {
                        url: `query/my-raise-query?userId=${userId}`,
                        method: 'GET',
                    }
                },
                providesTags: ['query']
            }),
            // create Lead Api For Team Login
            createLeadApi: builder.mutation({
                query: (leadData) => {
                    return {
                        url: 'lead/create-lead',
                        method: 'POST',
                        body: leadData
                    }
                }
            }),
            // ====Create homepage lead
            homepageCreateLeadApi: builder.mutation({
                query: (leadData) => {
                    return {
                        url: 'lead/home-user',
                        method: 'POST',
                        body: leadData
                    }
                }
            }),
        }
    }
})

export const {
    useFixedAppointmentApiMutation,
    useRegisterNowApiMutation,
    useRaiseQueryApiMutation,
    useMyRaiseQueryApiQuery,
    useCreateLeadApiMutation,
    useHomepageCreateLeadApiMutation,
} = registerApiService
export default registerApiService