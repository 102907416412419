import React from 'react'
import '../../styles/buttonsStyle.css'

function Loader() {
  return (
    <>
    <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "grid",
      placeItems: "center"
    }}>

        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
    </>
  )
}

export default Loader