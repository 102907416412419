import { configureStore } from '@reduxjs/toolkit'
import authService from './services/loginService'
import authReducer from './reducer/authReducer'
import dashboardService from '../store/services/dashboardService'
import dashboardReducer from './reducer/dashboardReducer'
import fetchApiService from './services/fetchApiService'
import registerApiService from './services/registerApiService'
import leadManagementService from './services/leadManagementService';

const store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [dashboardService.reducerPath]: dashboardService.reducer,
    [fetchApiService.reducerPath]: fetchApiService.reducer,
    [registerApiService.reducerPath]: registerApiService.reducer,
    [leadManagementService.reducerPath]: leadManagementService.reducer,
    'userReducer': authReducer,
    'dashReducer': dashboardReducer,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware().concat(
      [authService.middleware],
      [dashboardService.middleware],
      [fetchApiService.middleware],
      [registerApiService.middleware],
      [leadManagementService.middleware]
    )
  }
})

export default store
