import React from 'react'

function MonthSummary (props) {
  return (
    <>
      <div className='franchise_amnt_dashboardInfo land_card_1'>
        <div className='wrap'>
          <div className='franchise_inve'>
            <div className='card_6_cont'>
              <div className='franchise_inve_content mb-3'>
                <h6 className='inve_cont_main text-start'>Month Summary</h6>
                {/* <p className='inve_cont_main'>
                  <div className='dropdown'>
                    <form className='position-relative'>
                      <i className='fa-solid fa-calendar-days calender_icon'></i>
                      <select className='form-select select_time_inpt'>
                        <option value=''>Selected Month</option>
                        <option value='1'>Quarterly</option>
                        <option value='2'>This year</option>
                      </select>
                    </form>
                  </div>
                </p> */}
              </div>
              <div className='franchise_inve_content'>
                <h6>Number of New Meetings</h6>
                <p>{props.newMeetings}</p>
              </div>
              <div className='franchise_inve_content'>
                <h6>Number Of Repeat Meetings</h6>
                <p>{props.repeatMeetings}</p>
              </div>
              <div className='franchise_inve_content'>
                <h6>MOUs Signed</h6>
                <p>{props.mouSign}</p>
              </div>
              <div className='franchise_inve_content'>
                <h6>New Clients Added</h6>
                <p>{props.NewClAdded}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MonthSummary
