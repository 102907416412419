import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PUBLIC_PATH } from '../constant/matcher'
import '../styles/buttonsStyle.css'

function VideoBoxes (props) {
  const [completedBtn, setCompletedBtn] = useState('');
  return (
    <>
      <div className='video-box list-card-wrapper basic-card listing-card card-bg-1 card-bg'>
        <div className='video-title'>
          <span className='card-title text-white text-center px-2'>
            {props.videoTitle}
          </span>
        </div>
        <div
          className='img-container text-center position-relative'
          style={{ minHeight: '100px' }}
        >
          {/* <img src={props.videoImgSrc} alt={'video'} className='img-fluid' />
          <div className='play-btn-container'>
            <a
              rel='noopener noreferrer'
              target='_blank'
              className='play-btn'
              href={props.videoLink ? props.videoLink : '!'}
            >
              {' '}
            </a>
          </div> */}
          <div className='iVedio-container videoBoxes' dangerouslySetInnerHTML={{ __html: props.videoSrc }} style={{height:'230px !important'}} >
            {/* <iframe
              className='testi-iframe'
              src={props.videoSrc}
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen={true}
              title='Embedded youtube'
            ></iframe> */}
            {/* {props.videoSrc} */}
          </div>
        </div>
        <div className='card-link'>
          {/* <Link
            to={props.videoLink ? props.videoLink : ''}
            title='Read Full'
            target='_blank'
            className='twenty-one'
          >
            <span>Viewed</span>
          </Link> */}
          <Link
            to={props.completedLink ? props.completedLink : ''}
            title='Read Full'
            className={`twenty-one ${completedBtn}`}
            onClick={() => setCompletedBtn('completed')}
          >
            Completed
          </Link>
        </div>
      </div>
    </>
  )
}

export default VideoBoxes
