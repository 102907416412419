import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

const Searchbar = (props) => {
const [searchTerm, setSearchTerm] = useState("");


useEffect(() => {
    // Filter the lead data based on the search term
    const filtered = searchTerm
      ? props.lead?.filter(result => {
          const nameMatch = result.name && result.name.toLowerCase().includes(searchTerm.toLowerCase());
          const stageMatch = result.stage && result.stage.toLowerCase().includes(searchTerm.toLowerCase());
          const phoneMatch = result.phone && result.phone.toString().includes(searchTerm);
          const cityMatch = result.cityinfo && result.cityinfo.some(city => city.city && city.city.toLowerCase().includes(searchTerm.toLowerCase()));
          const productMatch =  result.product && result.product.some(product => product.name && product.name.toLowerCase().includes(searchTerm.toLowerCase()));
          const statusMatch = result.statusinfo && result.statusinfo.some(status => status.status && status.status.toLowerCase().includes(searchTerm.toLowerCase()));

          return nameMatch || stageMatch || phoneMatch || cityMatch || productMatch || statusMatch;
        })
      : props.lead;
    props.setFilteredLead(filtered); 
  }, [searchTerm, props.lead]);


  return (
    <>
    <input
                  type="text"
                  className="search-functionality"
                  placeholder="Search....."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
    </>
  )
}

export default Searchbar