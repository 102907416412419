import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PUBLIC_PATH } from "../../constant/matcher";
import ProductInput from "../customInputs/ProductInput";
// import DateTimePickerComp from "../customInputs/DateTimePickerComp";
import { useFixedAppointmentApiMutation } from "../../store/services/registerApiService";
import { useSelector } from "react-redux";
import "../../styles/inquiryFormStyle.css";

function BookAppointment(props) {
  const loginUserData = useSelector((state) => state?.userReducer?.userData);
  // console.log('loginUserData', loginUserData);

  // const [selectedDateTime, setSelectedDateTime] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [validCheck, setValidCheck] = useState("");
  const [successStatus, setSuccessStatus] = useState("fade");

  const closeSuccessModal = () => {
    setSuccessStatus("fade");
    props.closeRegPopup();
  };

  // old date and time
  // const setDateTimeHandler = (datetimeData) => {
  //   setSelectedDateTime(datetimeData);
  // };

  // Api to Fixed appointment
  const [appointmentData, appointmentResp = {}] =
    useFixedAppointmentApiMutation();

  useEffect(() => {
    if (
      appointmentResp &&
      !appointmentResp?.isLoading &&
      appointmentResp?.isSuccess &&
      appointmentResp?.data?.success
    ) {
      setSuccessStatus("d-flex");
      setFormAppData({
        userid: "",
        product: "",
        prefrence: "",
        remark: "",
        // product: [],
        // datetime:
      });
    }
    if (
      appointmentResp &&
      !appointmentResp?.isLoading &&
      appointmentResp?.isSuccess &&
      !appointmentResp?.data?.success
    ) {
      setValidCheck("An error occured please try again");
      setTimeout(() => {
        setValidCheck("");
      }, 5000);
    }
  }, [appointmentResp]);

  const [formAppData, setFormAppData] = useState({
    userid: "",
    prefrence: "",
    remark: "",
    product: [],
    // datetime:
  });
  const changeHandler = (e) => {
    setFormAppData({ ...formAppData, [e.target.name]: e.target.value });
  };
  const sendProductData = (ArrData) => {
    // console.log(ArrData);
    // setFormAppData({ ...formAppData, product: ArrData })
    setFormAppData({ ...formAppData, product: ArrData });
    // setFormAppData({ ...formAppData, product: ArrData[0] })
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let userData = formAppData;
    // console.log("userData initial : ", userData);
    // userData.datetime = selectedDateTime;
    userData.datetime = selectedDate + " " + selectedTime;
    userData.userid = loginUserData?._id;
    // console.log("userData end : ", userData);
    // console.log(formAppData.product);
    if (formAppData.product.length > 0) {
      setValidCheck("");
      if (formAppData.prefrence !== "") {
        setValidCheck("");
        appointmentData(userData);
      } else {
        setValidCheck("Please Select preference");
        setTimeout(() => {
          setValidCheck("");
        }, 5000);
      }
    } else {
      setValidCheck("Please Select product/s type");
      setTimeout(() => {
        setValidCheck("");
      }, 5000);
    }
  };

  return (
    <>
      <div className="form_popups popup__container">
        <div className="chagne_pass_form">
          <div className="container">
            <div className="row responsivePopup">
              <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="formByScan_container p-0 overflow-visible position-relative book__appointment overflow-hidden">
                  <div
                    className="popup_close_icon"
                    onClick={props.closeRegPopup}
                  >
                    <i
                      className="fa fa-xmark"
                      onClick={props.closeRegPopup}
                    ></i>
                  </div>
                  <div
                    className="raiseQimg text-center p-2 rounded pt-0 mb-1"
                    style={{ background: "aliceblue" }}
                  >
                    <img
                      src={PUBLIC_PATH + "assets/img/appointmentbg.png"}
                      className="img-fluid m-auto text-center"
                      alt="icon"
                      style={{ width: "180px" }}
                    />
                    {/* <div className="queryTitle">
                <h3>Enter Your Query To Get A Quote From Us</h3>
            </div> */}
                  </div>
                  <div className="queryForm px-3 pb-3">
                    {validCheck !== "" && (
                      <p className="text-center text-danger">{validCheck}</p>
                    )}
                    <form onSubmit={submitHandler}>
                      <div className="form-group mb-2">
                        <label className="">
                          Selct Product/s, you are interested in{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="react-multiselect form-group">
                          <ProductInput sendProductData={sendProductData} />
                        </div>
                      </div>
                      <div className="form-group mb-2">
                        <label className="">
                          Preference for the communication
                        </label>
                        <select
                          className="form-control form-select"
                          name="prefrence"
                          onChange={changeHandler}
                        >
                          <option value="">Select Preference</option>
                          <option value="voice">Voice</option>
                          <option value="whatsapp">Whatsapp</option>
                          <option value="google meet">Google Meet</option>
                        </select>
                      </div>

                      <div className="mb-2 dateTimePicker">
                        <label className="">
                          Select Preferred Date & Time
                          <span className="text-danger">*</span>
                        </label>

                        {/* <DateTimePickerComp setDateTime={setDateTimeHandler} /> */}
                        <span className="d-flex flex-column flex-sm-row justify-content-between">
                          <input
                            className="me-2"
                            type="date"
                            name="date_time"
                            id="date_time"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                          />

                          <input
                            type="time"
                            name="time"
                            id="time"
                            className="mt-2 mt-md-0 "
                            value={selectedTime}
                            onChange={(e) => setSelectedTime(e.target.value)}
                          />
                        </span>
                      </div>

                      <div className="form-group mb-2">
                        <label className="">Remark for the meeting</label>
                        <textarea
                          rows="2"
                          className="form-control"
                          placeholder="Type Your Message..."
                          name="remark"
                          onChange={changeHandler}
                        ></textarea>
                      </div>
                      <div className="text-end mt-3">
                        <button className="btn subBtn">
                          {appointmentResp?.isLoading ? "Submit..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <SuccessModal successStatus={'d-flex'} /> */}
        <div
          className={`modal custom-modal align-items-center ${successStatus}`}
          id="formSuccessModal"
          style={{ background: "rgba(0,0,0,0.3)" }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="formSuccessModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header " style={{ background: "#FF4B2B" }}>
                <h5 className="modal-title" id="formSuccessModalLabel">
                  Success
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeSuccessModal}
                  // data-bs-dismiss='modal'
                  // aria-label='Close'
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-popup-inner">
                  <div className="form-icon success">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 130.2 130.2"
                    >
                      <circle
                        className="path circle"
                        fill="none"
                        strokeWidth="6"
                        strokeMiterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <polyline
                        className="path check"
                        fill="none"
                        strokeWidth="6"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="100.2,40.2 51.5,88.8 29.8,67.5 "
                      />
                    </svg>
                  </div>
                  <div className="form-body">
                    <p>
                      Thank you for filling out our appointment form. We are
                      glad that you joined us.
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="popup-btn "
                  style={{ background: "#FF4B2B" }}
                  // data-bs-dismiss='modal'
                  onClick={closeSuccessModal}
                >
                  Okay
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookAppointment;
