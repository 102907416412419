import React from "react";
import { useState } from "react";
import { createContext } from "react";
import { Api_Base_Url } from "../constant/matcher";

export const ProfileCompletionContext = createContext();

export const ProfileCompletionProvider = ({ children }) => {
  const storedProfileComplete = localStorage.getItem("profileComplete");
  const [isProfileComplete, setIsProfileComplete] = useState(
    storedProfileComplete === "true"
  );
  const [showAgreement, setShowAgreement] = useState(false);
  const fetchProfileStatus = async () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    const type = loginUserData.role;
    try {
      const response = await fetch(
        `${Api_Base_Url}/cp/getdetail?type=${type}&id=${id}`,
        {
          method: "GET",
        }
      );

      const data = await response.json();
      if (data?.data?.personalinfo?.status === 2) {
        setIsProfileComplete(true);
        localStorage.setItem("profileComplete", "true");
      } else {
        setIsProfileComplete(false);
        localStorage.setItem("profileComplete", "false");
      }
      // Check agreementStatus and update setShowAgreement accordingly
      if (data?.data?.personalinfo?.agreementStatus === 1) {
        setShowAgreement(true);
      } else if (data?.data?.personalinfo?.agreementStatus === 0) {
        setShowAgreement(false);
      }
    } catch (error) {
      console.error("Error fetching profile status:", error);
    }
  };

  return (
    <ProfileCompletionContext.Provider
      value={{ isProfileComplete, setIsProfileComplete, fetchProfileStatus , showAgreement, setShowAgreement}}
    >
      {children}
    </ProfileCompletionContext.Provider>
  );
};
