import React from 'react'
import '../../styles/card.css'
import '../../styles/loaders.css'

function SkeletonLoader () {
  return (
    <>
      <div className='container px-4 my-4'>
        <section className='row about-product skeleton_wrpper p-3 inner-bg'>
          <div className="col-12">
            <div className='skel_heading'></div>
          </div>
          <div className="col-12 mt-4 mb-4">
            <div className="row">
              <div className="col-md-4">
                <div className='skel_card_cont ps-1 pt-3'>
                  <div className='skel_cards'>
                    <div className="skel_card_img"></div>
                    <div className='skel_heading mx-3 w-100 my-3'></div>
                    <div className="skel_card_desc px-3"><p></p></div>
                    <div className="skel_counters d-flex align-items-center justify-content-between p-3">
                      <h2></h2>
                      <h2></h2>
                    </div>
                    <div className="skel_card_offer pt-0 pb-2 px-3">
                      <p></p>
                    </div>
                  </div>
                  <div className='skel_cards'></div>
                  <div className='skel_cards'></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className='skel_card_cont ps-1 pt-3'>
                  <div className='skel_cards'>
                    <div className="skel_card_img"></div>
                    <div className='skel_heading mx-3 my-3'></div>
                    <div className="skel_card_desc px-3"><p></p></div>
                    <div className="skel_counters d-flex align-items-center justify-content-between p-3">
                      <h2></h2>
                      <h2></h2>
                    </div>
                    <div className="skel_card_offer pt-0 pb-2 px-3">
                      <p></p>
                    </div>
                  </div>
                  <div className='skel_cards'></div>
                  <div className='skel_cards'></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className='skel_card_cont ps-1 pt-3'>
                  <div className='skel_cards'>
                    <div className="skel_card_img"></div>
                    <div className='skel_heading mx-3 my-3'></div>
                    <div className="skel_card_desc px-3"><p></p></div>
                    <div className="skel_counters d-flex align-items-center justify-content-between p-3">
                      <h2></h2>
                      <h2></h2>
                    </div>
                    <div className="skel_card_offer pt-0 pb-2 px-3">
                      <p></p>
                    </div>
                  </div>
                  <div className='skel_cards'></div>
                  <div className='skel_cards'></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default SkeletonLoader
