import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PUBLIC_PATH } from "../../constant/matcher";
import FranchiseSidebar from "../../components/franchise/FranchiseSidebar";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import MyClientsCards from "../../components/cardsDesigns/MyClientsCards";
import {
  useProductSelectQuery,
  useGetCityListApiQuery,
} from "../../store/services/loginService";
import { useFetchMyClientsQuery } from "../../store/services/fetchApiService";
import DashboardBanner from "../../components/general/DashboardBanner";
import AlertCompleteProfile from "../../components/cardsDesigns/AlertCompleteProfile";
import Loader from "../../components/general/Loader";


function MyClients() {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLead, setFilteredLead] = useState([]);
  const [dynamicRoute, setdynamicRoute] = useState("");
  const [mri, setMRI] = useState("");
  const [equity, setEquity] = useState("");
  const [mutualFund, setMutualFund] = useState("");
  const [isloading, setIsLoading] = useState(true)
  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
  }, [loggedInUserData]);

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  // api for Get Products
  const productApi = useProductSelectQuery();
  var productApiData =
    productApi?.isSuccess &&
    !productApi?.isLoading &&
    productApi.status === "fulfilled" &&
    !productApi?.isError &&
    productApi?.data?.productsList;

  // Funtion to Get Product Name By its ID
  const productNameById = (productId) => {
    let productName = [];
    let arr = [];
    for (let i = 0; i < productId?.length; i++) {
      arr =
        productApiData && productApiData?.find((el) => el._id === productId[i]);
      if (arr !== false) productName.push(arr?.name);
    }
    return productName.length > 0
      ? productName[0] +
          (productName.length > 1
            ? "+" + (productName.length - 1).toString()
            : "")
      : "";
  };


  // Get CITY API
  const cityResp = useGetCityListApiQuery();
  const cityFetchedData =
    cityResp?.isSuccess &&
    !cityResp?.isLoading &&
    cityResp.status === "fulfilled" &&
    !cityResp?.isError &&
    cityResp?.data?.cities;

  // Funtion to Get City Name By its Id
  const cityNameById = (cityId) => {
    let city = "";
    for (let i = 0; i < 1; i++) {
      city =
        cityFetchedData && cityFetchedData?.find((el) => el._id === cityId);
      if (city !== false) return city?.city;
    }
  };

  // My CLients Api
  const [clientData, setclientData] = useState(null);
  const myClientsResp = useFetchMyClientsQuery(loggedInUserData);

  useEffect(() => {
    if (
      myClientsResp?.isSuccess &&
      !myClientsResp?.isLoading &&
      !myClientsResp?.isError &&
      myClientsResp?.data?.success
    ) {
      const data = myClientsResp?.data?.myClient
      setclientData(data);
      const MF = data.filter((result) =>
      result.product.some((product) => product.name === "Mutual Fund")
    );
      const mutualFund = MF.length;
      const EQ = data.filter((result) =>
      result.product.some((product) => product.name === "Equity Advisory")
    );
      const equity = EQ.length;
      const MRI = data.filter((result) =>
      result.product.some((product) => product.name === "Monthly Rental Income")
    );
      const mri = MRI.length;

      setEquity(equity)
      setMRI(mri)
      setMutualFund(mutualFund)
    }
  }, [myClientsResp]);

  useEffect(() => {
    // Filter the lead data based on the search term
    const filtered = searchTerm
      ? clientData?.filter(result => {
          const nameMatch = result.name && result.name.toLowerCase().includes(searchTerm.toLowerCase());
          const phoneMatch = result.phone && result.phone.toString().includes(searchTerm);
          const productMatch =  result.product.some(product => product.name && product.name.toLowerCase().includes(searchTerm.toLowerCase()))
          const cityNameMatch = cityFetchedData.some(city => city.city && city.city.toLowerCase().includes(searchTerm.toLowerCase()));
          return nameMatch || phoneMatch || productMatch || cityNameMatch;
        })
      : clientData;
    setFilteredLead(filtered); 
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
    
  }, [searchTerm, clientData, cityFetchedData, productApiData]);

  const getStatusLabel = (status) => {
    if (status === 0) {
      return "InActive";
    } else if (status === 1) {
      return "Active";
    } else if (status === 2) {
      return "Closed";
    } else {
      return ""; 
    }
  };

  return (
    <>
      {
      isloading ? (
       <Loader/>
      )
      : 
      (
   
        <>
      <FranchiseSidebar navContWrapp={navContWrapp} />
      {loggedInUserData?.role === "cp" && (
    <AlertCompleteProfile/>
      )}
      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
       
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>Dashboard</Link>{" "}
              <i className="fa-solid fa-angle-right"></i> My Clients
            </p>
          </div>
        </div>

        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          style={{ background: "whitesmoke" }}
        >
          <div className="row mb-4">
            <div className="col-md-4 col-lg-3 mb-2">
              <MyClientsCards
                cardTitle={"Equity"}
                cardValues={equity}     
                borderColor={3}
                bgGradient={3}
                textColor={3}
                iconsNumb={8}
              />
            </div>
            <div className="col-md-4 col-lg-3 mb-2">
              <MyClientsCards
                cardTitle={"Mutual Funds"}
                cardValues={mutualFund}
                borderColor={3}
                bgGradient={3}
                textColor={3}
                iconsNumb={10}
              />
            </div>
            <div className="col-md-4 col-lg-3 mb-2">
              <MyClientsCards
                cardTitle={"MRI"}
                cardValues={mri}
                // updateTime={"Since last week"}
                borderColor={3}
                bgGradient={3}
                textColor={3}
                iconsNumb={9}
              />
            </div>
            <div className="col-md-4 col-lg-3 mb-2">
              <MyClientsCards
                cardTitle={"Total"}
                cardValues={clientData !== null ? clientData?.length : 0}
                // updateTime={"Since last week"}
                borderColor={4}
                bgGradient={4}
                textColor={4}
                iconsNumb={5}
              />
            </div>
          </div>
          <div className="row table__row__wrapp">
            <div className="client_table_container py-2">
            <div className="d-flex justify-content-between">
              <h2>My Clients</h2>
              <input
                  type="text"
                  className="search-functionality"
                  placeholder="Search....."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="w-100 overflow-auto">
                <table className="table table-striped client__table">
                  <thead>
                    <tr>
                      <th scope="col">Sr No.</th>
                      <th scope="col">Name</th>
                      {
                        loggedInUserData.role === 'rm' ? (
                          <th scope="col">CP</th>
                        )
                        : (
                          <th scope="col">Client Code</th>
                        )
                      }
                     
                      <th scope="col">Phone</th>
                      <th scope="col">Products</th>
                      <th scope="col">City</th>
                      {
                        loggedInUserData.role !== "rm" && (
                          <th scope="col">Status</th>
                        )
                      }
                      <th scope="col">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredLead &&
                      filteredLead !== null &&
                      filteredLead?.map((client, index) => {
                        return (
                          <tr key={client?._id}>
                            <th scope="row">{index + 1}</th>
                            <td className="text-capitalize">{client?.name}</td>
                            {
                              loggedInUserData.role === 'rm' ? (
                                client?.cpinfo?.length > 0 ? (
                               
                                  client?.cpinfo?.map((i) => {
                                    return (
                                      <td>{i?.name}</td>
                                    )
                                  })
                                
                              )
                            : 
                            (
                              <td></td>
                            )
                            
                              )
                              : 
                              (
                                <td>{client?.clintcode}</td>
                              )
                            }
                           
                           
                            
                            <td>{client?.phone}</td>
                            
                                <td>
                                  {client?.product?.map((i) => i?.name).join(' , ')
                                  
                                  }</td>
                        

                            
                            <td className="text-capitalize">
                              {client?.city && cityNameById(client?.city)}
                            </td>
                            {
                        loggedInUserData.role !== "rm" && (
                          <td>{getStatusLabel(client?.status)}</td>
                  
                        )
                      }
                            <td className="ps-4">
                            <Link to={`${client?._id}/${client?.clintcode}`} className="ps-3"
                           //  onClick={() => chatPopuHandler(result?._id)}
                            >
                              <i className="fa-solid fa-eye"></i>
                            </Link>
                          </td>
                          </tr>
                        );
                      })}
                    {myClientsResp?.isLoading && (
                      <p className="mb-0 ms-2 mt-2">
                        Loading clients...
                      </p>
                    )}
                    {
                      !myClientsResp?.isLoading && clientData === null && (
                        // <tr>
                        <p className="mb-0 ms-2 mt-4">
                          You don't have any client
                        </p>
                      )
                      // </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
      </>
           )
          }
    </>
  );
}

export default MyClients;
