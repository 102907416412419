import React, { useState, useEffect, useRef } from 'react'
import { Api_Base_Url, PUBLIC_PATH } from '../../constant/matcher'
import '../../styles/loginFormStyle.css'
import { useGetProfileQuery } from '../../store/services/dashboardService'
import {
  Link,
  useLocation,
  NavLink,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { loggedInUserData, logout, setLoginUserToken } from '../../store/reducer/authReducer'
import { useSelector } from 'react-redux'
import CryptoJS from 'crypto-js'
import FranchisePopup from '../general/FranchisePopup'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Sidebar (props) {
  const { navContWrapp } = props

  const quickInvestSiDash = useRef()
  let dashboard = props.quickInvestSiDash

  // for quick invest
  const [searchParams, setSearchParams] = useSearchParams()
  const [quickPopupSi, setQuickPopupSi] = useState(false)
  const [navSideHide, setNavSideHide] = useState(true)
  const [smallScreenSidebar, setSmallScreenSidebar] = useState(
    ' smallScreenSidebar '
  )
  const [showPortal, setShowPortal] = useState(true)
  useEffect(() => {
    let popup = document.getElementsByClassName('invli_poppup')
    popup = Array.from(popup)
    // console.log(popup);
    if (popup[1]) {
      popup[1].style.display = 'none'
    }
  }, [quickPopupSi])

  const removeQueryParams = () => {
    const param = searchParams.get('et')
    if (param) {
      searchParams.delete('et')
      setSearchParams(searchParams)
    }
  }
  const closePopupHandler = () => {
    removeQueryParams()
    setQuickPopupSi(false)
  }
  useEffect(() => {
    if (searchParams?.size > 0) {
      setQuickPopupSi(true)
    }
  }, [searchParams])

  const userData = useSelector(state => state?.userReducer?.userData)
  const logoutSlug = userData && userData?.slug

  const [quickInvestSi, setquickInvestSi] = useState({
    username: userData?.eliteusername,
    password: userData?.elitepassword,
    loginType: userData?.eliterole,
    loginFor: userData?.eliterole,
    callbackUrl: window.location.href
  })

  const [qickPass, setQickPass] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const profileResp = useGetProfileQuery(userData?._id)

  const userProfileData =
    profileResp?.isSuccess &&
    !profileResp?.isLoading &&
    profileResp.status === 'fulfilled' &&
    !profileResp?.isError &&
    profileResp?.data?.success &&
    profileResp?.data?.user

  // useEffect(() => {
  //   console.log(userData);
  // }, [userData]);

  const [url, setUrl] = useState(null)
  const location = useLocation()

  useEffect(() => {
    setUrl(location.pathname)
  }, [location])

  let sidebarNavIcons = document.getElementsByClassName('sidebar_icons')
  sidebarNavIcons = Array.from(sidebarNavIcons)
  sidebarNavIcons.forEach(icons => {
    icons.addEventListener('mousemove', () => {
      icons.style.color = 'black'
    })
    icons.addEventListener('mouseout', () => {
      icons.style.color = '#fff'
    })
  })

  const sidebarController = () => {
    let sidebarNavIcons = document.getElementsByClassName('sidebar_icons')
    sidebarNavIcons = Array.from(sidebarNavIcons)

    let sidebarNavLinks = document.getElementsByClassName('side_hide')
    sidebarNavLinks = Array.from(sidebarNavLinks)

    if (navSideHide === true) {
      setNavSideHide(false)
      navContWrapp(false)
      // setNavShow(false)
      sidebarNavIcons.forEach(icons => {
        // icons.style.display = 'block'
        setTimeout(() => {
          icons.style.display = 'block'
        }, 200)
        icons.style.marginRight = '0px'
      })
      sidebarNavLinks.forEach(links => {
        links.style.display = 'none'
      })
    } else {
      setNavSideHide(true)
      // setNavShow(true)
      navContWrapp(true)
      sidebarNavIcons.forEach(icons => {
        icons.style.marginRight = '5px'
        icons.style.display = 'inline'
      })
      sidebarNavLinks.forEach(links => {
        setTimeout(() => {
          links.style.display = 'inline'
        }, 200)
      })
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })

    let sidebarNavIcons = document.getElementsByClassName('sidebar_icons')
    sidebarNavIcons = Array.from(sidebarNavIcons)

    let sidebarNavLinks = document.getElementsByClassName('side_hide')
    sidebarNavLinks = Array.from(sidebarNavLinks)

    if (window.screen.width <= 992) {
      setNavSideHide(false)
      sidebarNavIcons.forEach(icons => {
        icons.style.display = 'block'
        icons.style.marginRight = '0px'
      })
      sidebarNavLinks.forEach(links => {
        links.style.display = 'none'
      })
    } else {
      setNavSideHide(true)
      sidebarNavIcons.forEach(icons => {
        icons.style.display = 'inline'
        icons.style.marginRight = '7px'
      })
      sidebarNavLinks.forEach(links => {
        links.style.display = 'inline'
      })
    }
    const portalStatus =  localStorage.getItem("showPortalButton")
    if(portalStatus === "null"){
     setShowPortal(false)
    }
    else{
     setShowPortal(true)
    }
  }, [])

  const [logoutPopup, setLogoutPopup] = useState(false)

  const onLogout = () => {
    dispatch(logout())
    navigate(logoutSlug)
  }

  const handlePortalChange = (e) => {
    e.preventDefault()
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const phone = loginUserData?.phone
    const requestBody = {
       "phone": phone.toString()
    }
    axios.post(`${Api_Base_Url}/investor/portal-switch`, requestBody, {
       headers: {
          "Content-Type": "application/json"
       }
    })
    .then(response => {
       // Access the success property from the response data
       const success = response.data.success;
 
       // Check the value of success
       if (success) {
          // Handle success here
          console.log("Success:", success);
          localStorage.removeItem("user-token");
          localStorage.removeItem("loginUserData",); 
          localStorage.setItem("user-token", response?.data?.token);
      localStorage.setItem(
         "loginUserData",
         JSON.stringify(response?.data?.user)
       );
           dispatch(loggedInUserData(response?.data?.user));
           dispatch(setLoginUserToken(response?.data?.token));
           navigate(`/dashboard/${response?.data?.user?.role}`);
       } else {
          // Handle failure here
          toast.error("You dont have valid Credentials to move")
          // console.log("Request was not successful.");
       }
    })
    .catch(error => {
       // console.error("Error:", error);
    });
 }
  return (
    <>
    <ToastContainer />
      <div className='container-fluid dashboard-container'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div
            className={`sidebar-container p-0 ${smallScreenSidebar}`}
            style={{
              width: navSideHide ? '230px' : '70px',
              left:
                window.screen.width < 600 ? (navSideHide ? '0px' : '-230px') : 0
            }}
          >
            <div className='dash-sidebar'>
              <div className='sidebar-top py-3 px-3'>
                {/* <h3 className='text-white text-center user-name'>Hi, USER</h3> */}
                <div className='user-panel'>
                  <div className='pull-left image'>
                    <img
                      src={PUBLIC_PATH + 'assets/img/team/profile.png'}
                      className='img-circle'
                      alt='userImage'
                    />
                  </div>
                  <div className='pull-left side_hide info'>
                    <p className='ms-2 mb-0 text-capitalize'>
                      {userProfileData?.name
                        ? userProfileData?.name
                        : userData?.name}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className='main-nav py-5'>
                <h3 className='text-white text-center user-name'>Hi, USER</h3>
              </div> */}
              <div className='sidebar-links'>
                <ul className='side-list pb-2'>
                  <li>
                    <Link
                      to={`/dashboard/investor`}
                      className={
                        'sidebar_link_a' +
                        (url === (`/dashboard/investor`) ? ' active' : '')
                      }
                    >
                      <i className='fa-solid sidebar_icons fa-house'></i>{' '}
                      <span className='side_hide'>Home</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      // to={`/investor/xp-content`}
                      to='/dashboard/investor/x-content'
                      className={
                        'sidebar_link_a' +
                        (url === (`/investor/xp-content`) ? ' active' : '')
                      }
                    >
                      <i className='fa-solid sidebar_icons fa-folder-open'></i>{' '}
                      <span className='side_hide'>XP-Content</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      // to='/investor/goal-partner'
                      to=''
                      className='sidebar_link_a'
                    >
                      <i className='fa-solid sidebar_icons fa-users'></i>{' '}
                      <span className='side_hide'>Goal Planner</span>
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      // to='/investor/support'
                      to='/dashboard/investor/x-support'
                      className='sidebar_link_a'
                    >
                      <i className='fa-solid sidebar_icons fa-headset'></i>{' '}
                      <span className='side_hide'>Support</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      
                      to="/dashboard/investor/invprofile"
                      className='sidebar_link_a'
                    >
                      <i className='fa-solid sidebar_icons fa-user'></i>{' '}
                      <span className='side_hide'>My Profile</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='#' onClick={() => setLogoutPopup(true)}>
                      <i
                        className='fa-solid sidebar_icons fa-right-from-bracket'
                        onClick={() => setLogoutPopup(true)}
                      ></i>{' '}
                      <span
                        className='side_hide'
                        onClick={() => setLogoutPopup(true)}
                      >
                        Logout
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='p-0'>
            <header
              id='header'
              className='header sidebarHeader px-3 d-flex align-items-center top-nav justify-content-between'
            >
              <div className='container-fluid d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-between'>
                  <Link
                    to=''
                    className='logo sidebar_link_a'
                    //onClick={activeHandler}
                    // className=''
                  >
                    {/* <!-- Uncomment the line below if you also wish to use an image logo --> */}
                    <img src={PUBLIC_PATH + 'xPandLogo.png'} alt='brandLogo' />
                  </Link>

                  <i
                    onClick={sidebarController}
                    className='bi bi-list show-sidebar-menu'
                    style={{ fontSize: '30px', cursor: 'pointer' }}
                  ></i>
                </div>
                {/* <!-- .navbar --> */}
                <div className='loguser-links'>
                  <div className='register-links'>
                  {showPortal && (
                    <div
                      className='reg-btn'
                      // onClick={() => setLogoutPopup(true)}
                    >
                      <Link
                        to=''
                        className='sidebar_link_a'
                        onClick={handlePortalChange}
                      >
                       Switch to CP
                      </Link>
                    </div>
                  )}
                  </div>
                </div>
              </div>
            </header>
            {/* <!-- End Header --> */}

            {/* <!-- End Footer --> */}
          </div>
        </div>
      </div>
      {logoutPopup && (
        <div className='cd-popup' role='alert'>
          <div className='cd-popup-container'>
            <img
              src={PUBLIC_PATH + 'assets/img/icons/logout.png'}
              alt='logoutLogo'
              style={{width:'120px'}}
            />
            <h5>Are you sure you want to logout?</h5>
            <ul className='cd-buttons'>
              <li>
                <Link to='' onClick={onLogout}>
                  Yes
                </Link>
              </li>
              <li>
                <Link to='' onClick={() => setLogoutPopup(false)}>
                  No
                </Link>
              </li>
            </ul>
            <Link
              to=''
              className='cd-popup-close img-replace'
              onClick={() => setLogoutPopup(false)}
            ></Link>
          </div>
        </div>
      )}
      {/* <!-- cd-popup-container --> */}
      {/* <!-- cd-popup --> */}
    </>
  )
}

export default Sidebar
