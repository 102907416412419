import React, { useEffect, useRef, useState }  from 'react'
import { Link } from "react-router-dom";
import FranchiseSidebar from "../FranchiseSidebar";
import { useSelector } from "react-redux";
import DashboardBanner from "../../general/DashboardBanner";
import { Api_Base_Url} from "../../../constant/matcher";
import Footer from "../../Footer";
import MyClientsCards from "../../cardsDesigns/MyClientsCards";
import axios from "axios";
import DiscussionForum from '../../general/DiscussionForum';
import Loader from '../../general/Loader';

const LPQuery = () => {
    const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
    const [dynamicRoute, setdynamicRoute] = useState("");
    const [selectedQueryId, setSelectedQueryId] = useState(null);
    const [queryPopup, setQueryPopup] = useState(false);
    const [myQueries, setMyQueries] = useState(null);
    const [active, setActive] = useState("");
  const [inactive, setInactive] = useState("");
  const [closed, setClosed] = useState("");
  const [ticket, setTicket] = useState("")
  const [subject, setSubject] = useState("");
  const [isloading, setIsLoading] = useState(true)
  const [remark, setRemark] = useState("")
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
  const lmsuserid = loginUserData._id
    useEffect(() => {
        loggedInUserData?.role === "rm"
          ? setdynamicRoute("team")
          : setdynamicRoute("dashboard");
      }, [loggedInUserData]);
      const [navShow, setNavShow] = useState(true);
      // navigation content wrapper handler
      const navContWrapp = (boolData) => {
        setNavShow(boolData);
      };

      const chatPopuHandler = (queryId) => {
        // console.log("Opening popup for item ID:", userId);
        setSelectedQueryId(queryId);
        setQueryPopup(true);
      };
    
       // close discussion chat popup
       const closeChatPopup = () => {
        setQueryPopup(false);
      };

      const getStatusLabel = (status) => {
        if (status === 0) {
          return "InActive";
        } else if (status === 1) {
          return "Active";
        } else if (status === 2) {
          return "Closed";
        } else {
          return ""; 
        }
      };

      const getQuery = () => {
        axios.get(`${Api_Base_Url}/rm/ladingpagequery?rmid=${loggedInUserData._id}`).then((res) => {
          const data = res?.data?.data
      setMyQueries(data);
      const active = data.filter(result => result.status === 1).length;
      const inActive = data.filter(result => result.status === 0).length;
      const closed = data.filter(result => result.status === 2).length;
      setActive(active)
      setInactive(inActive)
      setClosed(closed)
      setSubject(res?.data?.data[0].subject)
      setTicket(res?.data?.data[0]?.ticket)
      setRemark(res?.data?.data[0]?.description)
        })
      }

      useEffect(() => {
        getQuery()
        setTimeout(() => {
          setIsLoading(false)
        }, 1000);
      }, [])
  return (
    <>
     {
      isloading ? (
       <Loader/>
      )
      : 
      (
   
        <>
     <FranchiseSidebar navContWrapp={navContWrapp} />
      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                Dashboard
              </Link>{" "}
              <i className="fa-solid fa-angle-right"></i> Landing Page Query
            </p>
          </div>
        </div>
        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          style={{ background: "whitesmoke" }}
        >
          <div className="row mb-4">
            <div className="col-md-4 mb-2">
              <MyClientsCards
                cardTitle={"Active"}
                cardValues={active}
                // updateTime={"Since last month"}
                borderColor={4}
                bgGradient={4}
                textColor={4}
                iconsNumb={2}
              />
            </div>
            <div className="col-md-4 mb-2">
              <MyClientsCards
                cardTitle={"In Active"}
                cardValues={inactive}
                borderColor={3}
                bgGradient={3}
                textColor={3}
                iconsNumb={1}
              />
            </div>
            <div className="col-md-4 mb-2">
              <MyClientsCards
                cardTitle={"Closed"}
                cardValues={closed}
                
                borderColor={4}
                bgGradient={4}
                textColor={4}
                iconsNumb={4}
              />
            </div>
          </div>
          <div className="row table__row__wrapp">
            <div className="client_table_container py-2">
            <div className="d-flex justify-content-between">
              <h2>Landing Page Query </h2>
             
              </div>
              <div className="w-100 overflow-auto">
                <table className="table table-striped client__table">
                  <thead>
                    <tr>
                      <th scope="col">Sr No.</th>
                      <th scope="col">Query Number</th>
                      <th scope="col">Ticket Id</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Query Description</th>
                      <th scope="col">Status</th>
                      <th scope="col">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                     {myQueries &&
                      myQueries?.length > 0 &&
                    myQueries?.map((result, index) => {
                      return (
                        <tr key={result._id}>
                          <td>{index + 1}</td>
                          <td>{result?._id}</td>
                          <td>{result?.ticket}</td>
                          <td>{result?.subject}</td>
                          <td>{result?.description}</td>
                          <td>{getStatusLabel(result?.status)}</td>
                          <td className="ps-4">
                            <Link
                              to=""
                              className="ps-3"
                              onClick={() => chatPopuHandler(result?._id)}
                            >
                              <i className="fa-solid fa-eye"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                    {myQueries?.isLoading && "Loading your Queries..."}
                    {!myQueries?.isLoading &&
                      myQueries?.length === 0 &&
                      "Queries not found"}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* =========discussion chat forum========= */}
        {queryPopup && (
        <DiscussionForum
        closeChatPopup={closeChatPopup}
        queryId={selectedQueryId}
        lmsuserid={lmsuserid}
        subject={subject}
        ticket={ticket}
        remark={remark}
        />
        
        )}
        
 
         {/* <!-- ======= Footer ======= --> */}
         <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
        </section>
        </>
           )
          }
    </>
  )
}

export default LPQuery