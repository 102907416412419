import React, { useEffect,  useState } from "react";
import { Link } from "react-router-dom";
import FranchiseSidebar from "../components/franchise/FranchiseSidebar";
import { useSelector } from "react-redux";
import DashboardBanner from "../components/general/DashboardBanner";
import { Api_Base_Url} from "../constant/matcher";
import Footer from "../components/Footer";
import axios from "axios";
import { formatDate, showformatDate } from "../utils/helper";
import Loader from "../components/general/Loader";

const RevenueUpdate = () => {
// loggedIn user Data form react-redux
const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
const [dynamicRoute, setdynamicRoute] = useState("");
const [revenue, setRevenue] = useState([])
const [isloading, setIsLoading] = useState(true)
useEffect(() => {
  loggedInUserData?.role === "rm"
    ? setdynamicRoute("team")
    : setdynamicRoute("dashboard");
    setTimeout(() => {
        setIsLoading(false)
      }, 1000);
}, [loggedInUserData]);

const [navShow, setNavShow] = useState(true);
// navigation content wrapper handler
const navContWrapp = (boolData) => {
  setNavShow(boolData);
};

const getRevenue = () => {
  const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
  const bpId = loginUserData.bp_id;
  axios.get(`${Api_Base_Url}/cp/cprevnue?bpcode=${bpId}`).then((res) => {
    setRevenue(res?.data?.data)
    // console.log(res?.data?.data);
  })
}
function getMonthName(dateStr) {
  const date = new Date(dateStr);
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const monthName = monthNames[date.getMonth()];

  return monthName;
}
function convertDateToCustomFormat(dateStr) {
  const date = new Date(dateStr);
  
  // Extract year, month, and day from the date
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
  const day = date.getDate().toString().padStart(2, '0');

  // Create the custom date format: DD-MM-YYYY
  const customDate = `${day}-${month}-${year}`;

  return customDate;
}

useEffect(() => {
  getRevenue()
}, [])
  return (
    <>
     {
      isloading ? (
        <Loader/>
      )
      : 
      (
   
        <>
      <FranchiseSidebar navContWrapp={navContWrapp} />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                Dashboard
              </Link>{" "}
              <i className="fa-solid fa-angle-right"></i> Register Business
            </p>
          </div>
        </div>
        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          style={{ background: "whitesmoke" }}
        >
         
          <div className="row table__row__wrapp">
            <div className="client_table_container py-2">
              <div className="d-flex justify-content-between ">
                <h2> Revenue Updates </h2>
              </div>
              <div className="w-100 overflow-auto">
                <table className="table table-hover  table-striped  client__table">
                  <thead>
                    <tr>
                      <th >Sr No.</th>
                      <th >Months </th>
                      <th >Sum of UF </th>
                      <th  >Sum of Trial </th>
                      <th >Sum of After GST @ 18%</th>
                      <th  >Pre Dues</th>
                      <th scope="col">HC</th>
                      <th scope="col">CP </th>
                      <th scope="col">Bonds</th>
                      <th scope="col">Total</th>
                      <th scope="col">TDS</th>
                      <th scope="col">Finak</th>
                      <th scope="col">Payment</th>
                      <th scope="col">Date</th>
                      <th scope="col">Due / Balance</th>
                      <th scope="col">Status / Feedback</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      revenue && revenue?.map((i, index) => {
                        return ( 
                          <tr key={i._id}>
                          <td>{index+1}</td>
                          <td>{getMonthName(i?.paymonth)}</td>
                          <td>{i?.sumofUF}</td>
                          <td>{Math.round(i?.sumofTrail)}</td>
                          <td >{Math.round(i?.sumofAfterGST)}</td>
                          <td>{Math.round(i?.PreDues)}</td>
                          <td>{Math.round(i?.HC)}</td>
                          <td>{i?.CP ? Math.round(i?.CP) : 0}</td>
                          <td>{Math.round(i?.bonds)}</td>
                          <td>{Math.round(i?.total)}</td>
                          <td>{Math.round(i?.tds)}</td>
                          <td>{Math.round(i?.final)}</td>
                          <td>{Math.round(i?.payment)}</td>
                          <td>{i?.paydate !== 'N/A' ? convertDateToCustomFormat(i?.paydate) : "N/A"}</td>
                          <td>{Math.round(i?.balance)}</td>
                          <td>{i?.feedback}</td>
                        </tr>
                        )
                      })
                    }

                    </tbody>
                  </table>
              </div>
            </div>
          </div>
        </div>
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
      </>
      )
          }
    </>
  )
}

export default RevenueUpdate