import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/card.css";
import { PUBLIC_PATH } from "../constant/matcher";
import RaisedQueryComp from "./general/RaisedQueryComp";

function DetailsCard(props) {
  const [paragraph, setParagraph] = useState("");
  const [queryPopup, setQueryPopup] = useState(false);
  const [raiseQueryPopup, setRaiseQueryPopup] = useState(false);

  // close popup on esc key
  window.addEventListener("keyup", function (e) {
    var key = e.which || e.keyCode;
    if (key === 27) {
      setQueryPopup(false);
    }
  });

  const closeQueryPopup = () => {
    setQueryPopup(false);
  };

  useEffect(() => {
    if (window.screen.width) {
      if (props.cardDesc?.length > 10) {
        setParagraph(props.cardDesc?.substring(0, 60));
      } else {
        setParagraph(props.cardDesc);
      }
    }

    if (window.screen.width > 1199) {
      if (props.cardDesc?.length > 10) {
        setParagraph(props.cardDesc?.substring(0, 50));
      } else {
        setParagraph(props.cardDesc);
      }
    }

    if (window.screen.width > 1284) {
      if (props.cardDesc?.length > 10) {
        setParagraph(props.cardDesc?.substring(0, 60));
      } else {
        setParagraph(props.cardDesc);
      }
    }
  }, [props.cardDesc]);
  return (
    <>
      <Link to={props.pdfLink ? props.pdfLink : ""} className="dash_card_link">
        <div className="cards details-card dash_card_link">
          <div className="cards_item">
            <div className="card">
              <div className="card_image">
                <img src={props.cardImg} alt="" />
              </div>
              <div className="card_content">
                <h2 className="card_title">{props.cardTitle}</h2>
                <div className="mb-1 para_overflow_cont">
                  <p className="card_text d-inline mb-0">{paragraph}</p>
                  {paragraph !== "" && (
                    <Link to={props.seeMore} className="see_more">
                      ..See More
                    </Link>
                  )}
                </div>
                <div className="doc-img">
                  <div className="pdf-img text-center">
                    <Link to={props.pdfLink} className="pdfLink">
                      <img
                        src={PUBLIC_PATH + "assets/img/pdf.png"}
                        className="d-block"
                        alt="pdf"
                      />
                      <span className="pdf-counter">{props.pdfCounter}</span>
                    </Link>
                  </div>
                  <div className="video-img text-center">
                    <Link to={props.videoLink} className="videoLink">
                      <img
                        src={PUBLIC_PATH + "assets/img/video.png"}
                        className="d-block"
                        alt="vid"
                      />
                      <span className="video-counter">
                        {props.VideoCounter}
                      </span>
                    </Link>
                  </div>
                </div>
                <Link to="" onClick={() => setQueryPopup(true)}>
                  <div className="offer_cont text-center">
                    <span className="offer_text">
                      <i className="fa-solid fa-circle-exclamation"></i> Raise
                      Query
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Link>
      {queryPopup && (
        <RaisedQueryComp
          title={props.cardTitle}
          closeQueryPopup={closeQueryPopup}
          productId={props.productId}
          topicId={props.topcId}
          productType={props.productType}
        />
      )}
    </>
  );
}

export default DetailsCard;
