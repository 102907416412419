import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

function PublicRouteEmployee ({ children }) {
  const userToken = useSelector(state => state.userReducer.userTokenFmRm)
  const userData = useSelector(state => state.userReducer.userData)
  

  return userToken && userToken !== 'undefined' ? <Navigate to={`/team/rm`}/> : children 
}

export default PublicRouteEmployee
