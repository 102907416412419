import React from 'react'
import '../styles/card.css'

const TestiCards = (props) => {
  return (
    <>
        <div className="test_wrapper pb-2">
            <div className="tesi_card_cont m-auto position-relative">
                <div className="quotes_mark position-absolute">
                    <i className="fa-solid fa-quote-right"></i>
                    {/* <i className='bi bi-quote quote-icon-right'></i> */}
                </div>
                <div className="testi_content">
                    <p className="mb-2">{props.testiPara}</p>
                    <h3 className="text-capitalize mb-0">{props.writerName}</h3>
                    <h4>{props.location}</h4>
                </div>
            </div>
        </div>   
    </>
  )
}

export default TestiCards