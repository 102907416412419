import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import { useProductSelectQuery } from '../../store/services/loginService.js'

function ProductInput({ sendProductData }) {
     // api for select options
  const [selectedProduct, setSelectedProduct] = useState(null)
    // const [productIds, setProductIds] = useState([]);

  // api for select options for multiselect
  const multiResp = useProductSelectQuery()
  var multiSelectData =
    multiResp?.isSuccess &&
    !multiResp?.isLoading &&
    multiResp.status === 'fulfilled' &&
    !multiResp?.isError &&
    multiResp?.data?.productsList

    const searchOptions =
    multiSelectData &&
    multiSelectData?.map(({ name, _id }) => ({
      label: name,
      value: _id
    }))

  useEffect(() => {
    // let productData = [] 
    var products = []
    if (selectedProduct?.length > 0) {
      for (let i = 0; i < selectedProduct.length; i++) {
        products.push(selectedProduct[i].value)
      }
      // productData = products
    }
    sendProductData(products)
  }, [selectedProduct]);

  return (
    <>
        <Select
            closeMenuOnSelect={false}
            isMulti
            className='basic-single w-100'
            classNamePrefix='select'
            defaultValue={selectedProduct}
            onChange={setSelectedProduct}
            isClearable={true}
            placeholder='Select Products*'
            isSearchable={true}
            name='product'
            options={searchOptions?searchOptions:[]}
          />
    </>
  )
}

export default ProductInput