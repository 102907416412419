import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import FranchiseSidebar from '../../../components/franchise/FranchiseSidebar'
import Footer from '../../../components/Footer'
import DashboardBanner from '../../../components/general/DashboardBanner'
import RmProfile from '../../../components/franchise/rm/RmProfile';
import { PUBLIC_PATH } from '../../../constant/matcher'
import { useSelector } from 'react-redux';
import Loader from '../../../components/general/Loader'

function ProfilePage () {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [isloading, setIsLoading] = useState(true)
  const [dynamicRoute, setdynamicRoute] = useState("");
  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
      setTimeout(() => {
        setIsLoading(false)
      }, 1000);
  }, [loggedInUserData]);

  const [SmallWrapper, setSmallWrapper] = useState(' small-wrapper ')
  const [navShow, setNavShow] = useState(true)
   // navigation content wrapper handler
   const navContWrapp = boolData => {
    setNavShow(boolData)
  }
  return (
    <>
    {
      isloading ? (
       <Loader/>
      )
      : 
      (
   
        <>
      <FranchiseSidebar navContWrapp={navContWrapp} />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper  ${SmallWrapper}`}
        style={{
          paddingLeft: navShow ? '230px' : '70px',
          background: 'whitesmoke'
        }}
      >
        <DashboardBanner />

        <div className='breadcrumbs'>
          <div className='container'>
            <p>
              <Link to={"/" + dynamicRoute + "/" + loggedInUserData?.role}>Dashboard</Link>{' '}
              <i className='fa-solid fa-angle-right'></i> Profile
            </p>
          </div>
        </div>

        <div className='container content-wrapper details-page px-4 py-3'>
          <RmProfile
            imgSrc={PUBLIC_PATH + 'assets/img/team/team-2.jpg'}
            profileTag={'FiNC Fit'}
            username={'Mikichan'}
            userProfession={'Finance Manager'}
            fbLink={''}
            instaLink={''}
            twitterLink={''}
            editLink={''}
          />
        </div>

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={'/'}
          home={'/#home'}
          about={'/#about'}
          service={'/#service'}
          faq={'/#faq'}
          testimonial={'/#testimonials'}
        />
      </section>
      </>
           )
          }
    </>
  )
}

export default ProfilePage
