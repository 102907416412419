import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/hoverCards.css";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { PUBLIC_PATH } from "../../constant/matcher";
import { saveAs } from 'file-saver';

function CreativesImageComp(props) {
  const userData = useSelector((state) => state?.userReducer?.userData);
  const [downloadPopup, setdownloadPopup] = useState(false);
  const domEl = useRef(null);
  const [popupImgSrc, setPopupImgSrc] = useState('');

  const downloadImage = async () => {
    html2canvas(domEl.current, { useCORS: true, allowTaint: true, logging: true }).then((canvas) => {
      const base64img = canvas.toDataURL("image/jpeg");
            saveAs(base64img, 'creative-product')
    });
    setTimeout(() => {
      setdownloadPopup(false)
    }, 300);
  }
  const activeDownPopup = (e) => {
      setPopupImgSrc(e.target.getAttribute('dataurl'))
      setdownloadPopup(true)
  }
  return (
    <>
      <div className="creative__image_wrap col-sm-6 col-md-4 col-xl-3 mb-2 d-flex justify-content-center align-items-center">
        <div className="box">
          <div className="imgBox">
            <img src={props.creativrSrc} alt="img" />
          </div>
          <div className="content position-relative">
            <h2>
              {/* {props.creativeTitle} <br /> */}
              <Link
                to=""
                className="subBtn"
                dataurl={props.creativrSrc}
                onClick={activeDownPopup}
              >
                Preview
              </Link>
            </h2>
          </div>
        </div>
      </div>
      {/* =====html to image dowload */}
      {downloadPopup &&
      <div className="form_popups popup__container">
        {/* <div className='chagne_pass_form'> */}
        <div
          className="raised_query_wrapper p-0 position-relative overflow-visible"
          style={{ width: "350px" }}
        >
          <div
            className="popup_close_icon"
            onClick={() => setdownloadPopup(false)}
          >
            <i
              className="fa fa-xmark"
              onClick={() => setdownloadPopup(false)}
            ></i>
          </div>
          <div
            id="creativeDownload"
            ref={domEl}
            className="html2img bg-white border px-3 pb-2 pt-3 my-3"
            style={{ width: "fit-content", zIndex: 2, margin: "auto" }}
          >
            <div className="htmlImg border bg-white">
              <img
                // src={PUBLIC_PATH + "assets/img/card-img.jpeg"}
                src={popupImgSrc}
                alt="img"
                style={{ width: "270px", height: "280px" }}
              />
            </div>
            <div className="userDetails pt-3 px-2 bg-white">
              <div className="username d-flex align-items-center justify-content-between">
                <h6 className="text-capitalize" style={{ fontWeight: "600" }}>
                  {userData?.name}
                </h6>
                <h6 style={{ fontWeight: "600" }}>
                  <i className="fa fa-phone text-primary" style={{}}></i>{" "}
                  {userData?.phone}
                </h6>
              </div>
            </div>
          </div>
          <div
            id="contentDownload"
            className="subBtn w-100 text-center p-2 rounded"
            style={{cursor:'pointer'}}
            onClick={downloadImage}
          >
            Download
          </div>
        </div>
        {/* </div> */}
      </div>
      }
    </>
  );
}

export default CreativesImageComp;
