import React, { useState, useEffect } from "react";
import profileQues from "../../constant/updateProfileQues.json";

function UpdateProfile() {
  const [formSlide, setFormSlide] = useState("0%"); //to slide form
  const [contHeight, setContHeight] = useState("618px"); // height maitain according to ques
  const [showNavBtn, setShowNavBtn] = useState(true); // next prev btn on submit screen
  const [prevBtn, setPrevBtn] = useState(false); // to show and hide prev btn
  const [next, setNext] = useState(true); // to show and hide next btn
  const [lastPrev, setLastPrev] = useState(false); // prev btn with submit

  const slideNextHandler = () => {
    if (formSlide === "0%") setFormSlide("-33.3%");
    if (formSlide === "-33.3%") setFormSlide("-62.5%");

    if (contHeight === "618px") {
      setContHeight("545px");
      // to show prev btn
      setPrevBtn(true);
    }
    if (contHeight === "545px") {
      // to set height of container according to questions
      setContHeight("700px");
      // to hide next prev btn
      setShowNavBtn(false);
      setPrevBtn(true);
      // ====to show prev btn with submit
      setLastPrev(true);
    }
  };
  const slidePrevHandler = () => {
    if (formSlide === "-33.3%") setFormSlide("0%");
    if (formSlide === "-62.5%") setFormSlide("-33.3%");

    if (contHeight === "545px") {
      setContHeight("618px");
      setPrevBtn(false);
    }
    if (contHeight === "700px") {
      // to set height of container according to questions
      setContHeight("545px");
      setLastPrev(false);
      // to hide next prev btn
      setShowNavBtn(true);
    }
  };
  return (
    <>
      <div className="updateProfileForm position-relative overflow-hidden inner-content inner-bg pb-0">
        <div
          className="profile_form__container"
          style={{ maxHeight: contHeight }}
        >
          <form
            className="updpro_form"
            style={{ transform: `translateY(${formSlide})` }}
          >
            {profileQues &&
              profileQues?.map((val, index) => {
                return (
                  <div className={`form-group mb-2`} key={val?._id}>
                    <div className="profile___ques">
                      {index + 1 + ". " + val?.question}
                    </div>
                    {val?.answeroption?.map((option, i) => {
                      return (
                        <>
                          <div className="input__group">
                            <input
                              type="radio"
                              className="form-check-input ans_check_input "
                              value={option?._id}
                              name={option?.questionId}
                              key={option?._id}
                            />
                            <label className="form-check-label ms-1">
                              {option?.option}
                            </label>
                          </div>
                        </>
                      );
                    })}
                  </div>
                );
              })}
            <div className="submit__btn d-flex justify-content-end py-2">
              <button className="pro__ques_subm">Submit</button>
            </div>
          </form>
        </div>
        <div
          className={`slide__navigation ${showNavBtn ? "d-flex" : "d-none"}`}
        >
          <button
            className={`pro__ques_subm arrows me-2 ${prevBtn ? "" : "d-none"}`}
            onClick={slidePrevHandler}
          >
            <i className="fa-solid fa-arrow-up jumpAnima"></i>
          </button>
          <button className="pro__ques_subm arrows" onClick={slideNextHandler}>
            <i className={`fa-solid fa-arrow-down jumpAnima`}></i>
          </button>
        </div>
        {/* =====Preb Button for last slide */}
        <button
          className={`pro__ques_subm slide__navigation lastPrev arrows ${
            lastPrev ? "" : "lastPrevBtnTran"
          }`}
          onClick={slidePrevHandler}
        >
          <i className="fa-solid fa-arrow-up jumpAnima"></i>
        </button>
      </div>
    </>
  );
}

export default UpdateProfile;
