import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FranchiseSidebar from "../../components/franchise/FranchiseSidebar";
import Footer from "../../components/Footer";
import { PUBLIC_PATH } from "../../constant/matcher";
import CreativesImageComp from "../../components/cardsDesigns/CreativesImageComp";
import DashboardBanner from "../../components/general/DashboardBanner";
import { useSelector } from "react-redux";
import { useCreativeDownloadApiQuery } from "../../store/services/fetchApiService";
import AlertCompleteProfile from "../../components/cardsDesigns/AlertCompleteProfile";
import Loader from "../../components/general/Loader";
// import { saveAs } from 'file-saver'

function CreativePage() {
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const userProducts = loggedInUserData?.product;
  const [isloading, setIsLoading] = useState(true)
  const [dynamicRoute, setdynamicRoute] = useState("");
  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
      setTimeout(() => {
        setIsLoading(false)
      }, 1000);
  }, [loggedInUserData]);


  // const [productId, setProductId] = useState();
  var productId = "";
  let productArr = loggedInUserData?.product?.map((items, index) => {
    productId =
      productId +
      items +
      (loggedInUserData?.product.length === index + 1 ? "" : ",");

    return productId;
  });

  const creativeResp = useCreativeDownloadApiQuery(productId);
  const ServerPath =
    creativeResp?.isSuccess &&
    !creativeResp?.isLoading &&
    creativeResp?.status === "fulfilled" &&
    !creativeResp?.isError &&
    creativeResp?.data?.path;

  const creativeData =
    creativeResp?.isSuccess &&
    !creativeResp?.isLoading &&
    creativeResp?.status === "fulfilled" &&
    !creativeResp?.isError &&
    creativeResp?.data?.success &&
    creativeResp?.data?.ads;

  // useEffect(() => {
  //   console.log(creativeData);
  // }, [creativeResp]);

  // download image handler

  const downloadImgHandler = (url) => {
    // saveAs(url, 'product-image')
  };

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };
  return (
    <>
      {
      isloading ? (
        <Loader/>
      )
      : 
      (
   
        <>
      <FranchiseSidebar navContWrapp={navContWrapp} />
      {loggedInUserData?.role === "cp" && (
    <AlertCompleteProfile/>
      )}
      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{
          paddingLeft: navShow ? "230px" : "70px",
        }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>Dashboard</Link>{" "}
              {/* <i className='fa-solid fa-angle-right'></i> Home{' '} */}
              <i className="fa-solid fa-angle-right"></i> Creative
            </p>
          </div>
        </div>

        <div className="container content-wrapper details-page pt-1 pb-5 px-4">
          <div
            className="row franchise_inve pt-0 pb-4"
            style={{ background: "#f1fff3" }}
          >
            <div className="col-12 about-product-inner mb-4 pb-2">
              <div className="mt-2 about-products d-flex align-items-center justify-content-between">
                <div className="sec-title sec-title_list">
                  <h2 className="text-capitalize">Creative Products </h2>
                </div>
                {/* <div className="type_filter">
                  <form>
                    <select className="form-select">
                      <option value="">Mutual Fund</option>
                      <option value="">Equity Advisory</option>
                      <option value="">Monthly Rental</option>
                    </select>
                  </form>
                </div> */}
              </div>
            </div>
            {creativeData &&
              creativeData?.map((item, index) => {
                return (
                  <>
                    <CreativesImageComp
                      key={item?._id + index}
                      creativrSrc={ServerPath + item?.image}
                      creativeTitle={"John Doe"}
                      // downloadLink={ServerPath + item?.image}
                      // downloadImg={()=>downloadImgHandler(ServerPath + item?.image)}
                    />
                  </>
                );
              })}
              {creativeResp?.isLoading && (
              <p className="mb-0 ms-2 mt-2">Loading images...</p>
              )}
              {!creativeResp?.isLoading && creativeData?.length === 0 &&
                (
                  <p>Forms Not Available</p>
                )
              }
          </div>
        </div>

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
      </>
           )
          }
    </>
  );
}

export default CreativePage;
