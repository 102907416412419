import React, { useState } from "react";
import data from "../../../constant/question.json";
import axios from "axios";
import { Api_Base_Url } from "../../../constant/matcher";
import { useEffect } from "react";

const Questions = (props) => {
  
 let fitScore = [];

  const handleAnswerChange = (questionIndex, selectedOption) => {
    const updatedResponses = [...fitScore];
    const marks = selectedOption === "Yes" ? 2 : 0;

    updatedResponses[questionIndex] = {
      question: data?.data[questionIndex]?.question,
      answer: selectedOption,
      marks: marks,
    };
    // console.log("updatedResponses", updatedResponses);
   fitScore = updatedResponses
      // console.log("fitScore", fitScore);
    // console.log("data", data.data);
  };

  const handleFitScore = async (e) => {
    e.preventDefault();   

    const requestBody = {
  usertype: props.role,
  userid: props.userId,
  response: fitScore?.map((response) => ({
    question: response.question,
    answer: response.answer,
    marks: response.marks,
  })),
};
    try {
      const response = await axios.post(
        `${Api_Base_Url}/cp/fitscore`,
        requestBody,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
       
        // console.log("Request successful");
        props.getFitScore();
        props.setFitscorePopup(false)
       
      }
    } catch (error) {
      // Handle any errors
      console.error("Error:", error);
    }

    // console.log(requestBody);
  };

  
useEffect(() => {
  props.getFitScore(props.userId);
    props.getEngScore(props.userId);
    console.log("fitScore:", fitScore);
console.log("data:", data);
}, [])
  return (
    <>
      {/* <div className="field__container col-12 p-3 d-flex flex-wrap">
        {
          !props.fitScoreAnswer?.length > 0 || showfitques  ? (
        <form className="d-flex flex-wrap" action="" onSubmit={handleFitScore}>
          {data?.data?.map((res, index) => {
            return (
              <div className="mb-3 col-6" key={res?._id}>
                <h6>
                  <span className="me-3">{index + 1}&#41;</span> {res?.question}
                </h6>
                {res?.answeroption?.map((i) => {
                  return (
                    <div class="form-check form-check ms-5 " key={i._id}>
                      <input
                        class="form-check-input"
                        name={`questionOptions_${index}`}
                        type="radio"
                        value={i.options}
                        id={`reverseCheck${i._id}`}
                        onChange={() => handleAnswerChange(index, i.options)}
                      />
                      <label
                        htmlFor={`reverseCheck${i._id}`}
                        class="form-check-label"
                      >
                        {i?.options}
                      </label>
                    </div>
                  );
                })}
              </div>
            );
          }) }

          <button
            type="submit"
            className="btn btn-sm text-white "
            style={{
              backgroundColor: "#08554e",
              width: "130px",
              height: "40px",
              float: "right",
            }}
          >
            Submit
          </button>
          <button
            type="btnt"
            className="btn btn-sm text-white ms-3"
            style={{
              backgroundColor: "#08554e",
              width: "130px",
              height: "40px",
              float: "right",
            }}
            onClick={() => setShowfitques(false)}
          >
            Cancel
          </button>
        </form>
           
           )  
           : 
           (
          
        <div className="d-flex flex-wrap">
        {
          props.fitScoreAnswer?.map((i, index) => {
            return (
              <div className="mb-3 col-6">
          <h6><span className="me-3">{index+1}&#41;</span> {i?.question}</h6>
          <div class="form-check form-check ms-5 ">&#128073; {i?.answer}</div>
        </div>
            )
          })
        }
        <button
          className="btn btn-sm text-white"
          style={{
            backgroundColor: "#08554e",
            height: "40px",
            width: "50px",
          }}
          onClick={handleEdit}
        >
          <i className="fa-solid fa-pen-to-square fa-2xs"></i>{" "}
        </button>
        </div>
          
          )
        }
      </div>
      <div className="field__container col-12 p-3 mt-2 d-flex flex-wrap">
       {!props.engScoreAnswer?.length > 0 || showEngues  ? (
        <form
          className="d-flex flex-wrap"
          action=""
          onSubmit={handleEngagementScore}
        >
          {data?.score?.map((res, index) => {
            return (
              <div className="mb-3 col-6" key={res?._id}>
                <h6>
                  <span className="me-3">{index + 1})</span> {res?.question}
                </h6>
                {res?.answeroption?.map((j) => {
                  return (
                    <div class="form-check form-check ms-5">
                      <input
                        class="form-check-input"
                        name={`questionOptions_${index}`}
                        type="radio"
                        value={j.options}
                        id={`reverseCheck${j._id}`}
                        onChange={() =>
                          handleEngagementChange(index, j.options)
                        }
                      />
                      <label
                        htmlFor={`reverseCheck${j._id}`}
                        class="form-check-label"
                      >
                        {j?.options}
                      </label>
                    </div>
                  );
                })}
              </div>
            );
          })}

          <button
            type="submit"
            className="btn btn-sm text-white "
            style={{
              backgroundColor: "#08554e",
              width: "130px",
              height: "40px",
              float: "right",
            }}
          >
            Submit
          </button>
          <button
            type="btnt"
            className="btn btn-sm text-white ms-3"
            style={{
              backgroundColor: "#08554e",
              width: "130px",
              height: "40px",
              float: "right",
            }}
            onClick={() => setshowEngues(false)}
          >
            Cancel
          </button>
        </form>
        
        )
        : 
        (
       
        <div className="d-flex flex-wrap">
        {
          props.engScoreAnswer?.map((i, index) => {
            return (
              <div className="mb-3 col-6">
          <h6><span className="me-3">{index+1})</span> {i?.question}</h6>
          <div class="form-check form-check ms-5 ">&#128073; {i?.answer}</div>
        </div>
            )
          })
        }
        <button
          className="btn btn-sm text-white"
          style={{
            backgroundColor: "#08554e",
            height: "40px",
            width: "50px",
          }}
          onClick={handleEditchange}
        >
          <i className="fa-solid fa-pen-to-square fa-2xs"></i>{" "}
        </button>
        </div>
           
           )
          }
      </div> */}
    
      <div className="form_popups popup__container">
        <div className="chagne_pass_form">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex align-items-center position-relative justify-content-center">
                <div
                  className="formByScan_container CreatLeadFormWrapper h-auto"
                  id="formByScan_container"
                  style={{ overflow: "visible", padding: "10px 15px" }}
                >
                  <div
                    className="popup_close_icon createLead"
                    onClick={() => props.setFitscorePopup(false)}
                  >
                    <i
                      className="fa fa-xmark"
                      onClick={() => props.setFitscorePopup(false)}
                    ></i>
                  </div>
                  <form  action="" onSubmit={handleFitScore} style={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column"
                  }}>
                     <h5>Fit Score Questions</h5>
          {data?.data?.map((res, index) => {
            return (
              <div className="mb-6 col-12" key={res?._id}>
                <h6>
                  <span className="me-3">{index + 1}&#41;</span> {res?.question}
                </h6>
                <div className="d-flex">
                {res?.answeroption?.map((i) => {
                  return (
                    <div class="form-check form-check ms-5 " key={i._id}>
                      <input
                        class="form-check-input"
                        name={`questionOptions_${index}`}
                        type="radio"
                        value={i.options}
                        id={`reverseCheck${i._id}`}
                        onChange={() => handleAnswerChange(index, i.options)}
                      />
                     
                      <label
                        htmlFor={`reverseCheck${i._id}`}
                        class="form-check-label"
                      >
                        {i?.options}
                    
                      </label>
                    </div>
                  );
                })}
                 
                </div>
              </div>
            );
          }) }

          <button
            type="submit"
            className="btn btn-sm text-white "
            style={{
              backgroundColor: "#08554e",
              width: "130px",
              height: "40px",
            

            }}
          >
            Submit
          </button>
        </form>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
             
    </>
  );
};

export default Questions;
