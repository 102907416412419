import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../styles/card.css'
import { PUBLIC_PATH } from '../constant/matcher'
import '../styles/buttonsStyle.css'

function DashCards (props) {
  const [completedBtn, setCompletedBtn] = useState('');
  return (
    <>
      {/* <Link to={props.cardLink}> */}
      {/* <div className={'basic-card listing-card details-card items slides ' + props.bgColor}> */}
      <div className='list-card-wrapper basic-card listing-card card-bg-1 card-bg'>
        <div className='listing-content card-content'>
          <div className='title'>
            <div className="list_pdf_title">
            <span className='card-title'>{props.cardTitle}</span>
            </div>
            {props.thmbImg && (
            <div className='listing-img'>
              <img
                src={props.thmbImg}
                alt='listing-img'
                // className='img-fluid'
              />
            </div>
          )}
            {/* <p className='card-text'>{props.cardDesc}</p> */}
          </div>
          {/* <div className="description">
              </div> */}
        </div>

        <div className='card-link'>
          <Link
            to={props.srcLink ? props.srcLink : '#!'}
            title='Read Full'
            target='_blank'
            className='twenty-one'
          >
            <span>Read More</span>
          </Link>
          <Link
            to={props.completedLink ? props.completedLink : ''}
            title='Read Full'
            className={`twenty-one ${completedBtn}`}
            onClick={() => setCompletedBtn('completed')}
          >
            Completed
          </Link>
        </div>
      </div>
      {/* </div> */}
      {/* </Link> */}
    </>
  )
}

export default DashCards
