import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/mainCards.css";
import "../../styles/loginFormStyle.css";
import { useGetOtpMutation } from "../../store/services/loginService";
import { useSelector } from "react-redux";
import { useRef } from "react";
import axios from "axios";
import { Api_Base_Url, Server_Path_static, qrpath, urlpath } from "../../constant/matcher";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import Support from "../cardsDesigns/Support";

const InvProfile = (props) => {
    const [signupBtn, setSignupBtn] = useState(true);
    const [popupForm, setPopupForm] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [changePassForm, setChangePassForm] = useState(false);
 
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [panCard, setPanCard] = useState("");
    const [aadharCard, setAadharCard] = useState("");
    const [cheque, setCheque] = useState("");
    const [fileName, setFileName] = useState('');
    const [aadharfileName, setAadharFileName] = useState('');
    const [chequefileName, setChequeFileName] = useState('');
    const [error, setError] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [userInfo, setUserInfo] = useState([]);
    const [downloadPopup, setdownloadPopup] = useState(false);
    const [qrContent, setQrContent] = useState("");
    const domEl = useRef(null);
    const [popupImgSrc, setPopupImgSrc] = useState("");
    const userLogginId = JSON.parse(localStorage.getItem("loginUserData"));
    const userId = userLogginId._id;
    const type = userLogginId.role;
    const path = `?type=${type}&qid=${userId}`
    useEffect(() => {
        setName(props.username);
        setEmail(props.email);
        setMobile(props.mobile);
     }, [props.username, props.email, props.mobile]);
  
     const profDetails = useSelector(
        (state) => state?.dashReducer?.profileDetails
     );
  
     const rmDetMail =
        profDetails?.slug?.toUpperCase() === "Investor"
           ? "support@xpandwealth.com"
           : "help@xpandwealth.com";
  
     const showChang_form = () => {
        setChangePassForm(true);
        setPopupForm(true);
        setEditForm(false);
     };

     const closeChngPassPopup = () => {
        setChangePassForm(false);
        setEditForm(false);
        setPopupForm(false);
     };

     const [prevDetails, setprevDetails] = useState({
        old_password: "",
        new_password: "",
        c_password: "",
     });
  
     const changePassHandler = (e) => {
        setprevDetails({ ...prevDetails, [e.target.name]: e.target.value });
     };
  
     // api to get otp
     const [optPhone, otpResp = {}] = useGetOtpMutation();

     useEffect(() => {
        const otpInput = document.getElementById("otp-input");
        if (
           otpResp?.isSuccess &&
           !otpResp?.isLoading &&
           otpResp.status === "fulfilled" &&
           !otpResp?.isError &&
           otpResp?.data.message &&
           !otpResp?.data?.otpData
        ) {
           console.log("notfournd");
        }
        if (
           otpResp?.isSuccess &&
           !otpResp?.isLoading &&
           otpResp.status === "fulfilled" &&
           !otpResp?.isError &&
           otpResp?.data?.otpData
        ) {
           if (otpInput) {
              otpInput.value = otpResp?.data?.otpData?.otp;
              prevDetails.otp = otpResp?.data?.otpData?.otp;
           }
        }
     }, [otpResp]);
     // opt num submit handler
     const otpBtnClick = () => {
        var otpNum = document.getElementById("mobile");
        if (otpNum?.value?.length === 10) {
           optPhone({ phone: otpNum?.value });
        }
     };
     const userChangePass = (e) => {
        e.preventDefault();
        if (prevDetails.new_password === prevDetails.c_password) {
           console.log("passmatch", prevDetails);
        } else {
           console.log("npass and cpass must be equal");
        }
     };
  
     useEffect(() => {
        let mobInpt = document.getElementById("mobile");
        if (mobInpt) {
           mobInpt.oninput = () => {
              if (mobInpt.value.length > mobInpt.maxLength) {
                 mobInpt.value = mobInpt.value.slice(0, mobInpt.maxLength);
              }
           };
        }
     }, []);


     const handlePanCard = (e) => {
      const selectedFile = e.target.files[0];
      setPanCard(selectedFile);
      if (selectedFile) {
         setFileName(selectedFile.name);
       } else {
         setFileName('');
       }
     };
     const handleAadharCard = (e) => {
      const selectedFile = e.target.files[0];
      setAadharCard(selectedFile);
      if (selectedFile) {
         setAadharFileName(selectedFile.name);
       } else {
         setAadharFileName('');
       }
     };
     const handleCheque = (e) => {
      const selectedFile = e.target.files[0];
      setCheque(selectedFile);
      if (selectedFile) {
         setChequeFileName(selectedFile.name);
       } else {
         setChequeFileName('');
       }
     };
  
     const validateInput = () => {
        if (!panCard && !aadharCard && !cheque) {
           setError("Please upload at least one Document");
           return false;
        }
        setError("");
        return true;
     };
     const handleUpload = async (e) => {
        e.preventDefault();
        if (!validateInput()) {
           return;
        }
        const formData = new FormData();
        formData.append("panCard", panCard);
        formData.append("aadharCard", aadharCard);
        formData.append("cheque", cheque);
        const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
        formData.append("userId", loginUserData?._id || "");
        try {
           const response = await axios.post(
              `${Api_Base_Url}/investor/upload-document?userId=${loginUserData?._id}`,
              formData,
              {
                 headers: {
                    "Content-Type": "multipart/form-data",
                 },
              }
           );
         //   console.log("response:", response.data);
           setIsSubmitted(true);
           getUserData()
        } catch (error) {
           console.error("handleUpload : ", error);
        }
     };
  
     const getUserData = async () => {
      try {
         const loginUserData = JSON.parse(
            localStorage.getItem("loginUserData")
         );
         const id = loginUserData._id;
         const response = await axios.get(
            `${Api_Base_Url}/investor/my-profile?id=${id}`
         );
         setUserInfo(response?.data?.data);
         const clientId = response?.data?.data?.personalinfo?.clintcode
       //   console.log("clientId", clientId);
         localStorage.setItem("clientId", clientId)
      } catch (error) {
         console.log("getUserData : ", error);
      }
   }

   const productNames = userInfo?.personalinfo?.product.map((product) => product.name);

     useEffect(() => {
        
        getUserData();
     }, []);
  
     

  return (
    <>
     <>
         <div className="container ">
            {/* ----- Page Heading && Edit Btn ----- */}
            <div className="row mb-2">
               <div className="col-md-12">
                  <div className="profile_header d-flex align-items-center justify-content-between">
                     <div className="">
                        <h3>My Profile</h3>
                     </div>

                    
                  </div>
               </div>
            </div>

            {/* ----- Main Section 1 ----- */}
            <div className="row justify-content-between">
               {/* ----- Personal Details Section ------ */}
               <div className="col-12 col-md-5  profile_wrapper">
                  <div className="row">
                     {/* ----- Header Text ----- */}
                     <div className="col-12 border-bottom border-light heading-text-container">
                        <div>
                           <h4 className="heading-text">Personal Details</h4>
                        </div>
                     </div>
                     {/* ---- Details Content ----- */}
                     <div className="col-12">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="profile_contact_details user_details">
                                 <div className="contact_rows">
                                    <span className="det_sep">Name</span> :
                                    <span className="details_span">
                                       {userInfo?.personalinfo?.name}
                                    </span>
                                 </div>
                                 <div className="contact_rows">
                                    <span className="det_sep">Mobile</span> :{" "}
                                    <span className="details_span">
                                       {" "}
                                       {userInfo?.personalinfo?.phone}
                                    </span>
                                 </div>
                                 <div className="contact_rows">
                                    <span className="det_sep">Email</span> :
                                    <span className="details_span">
                                       {" "}
                                       {userInfo?.personalinfo?.email}
                                    </span>
                                 </div>
                                 <div className="contact_rows">
                                    <span className="det_sep">Client Id</span> :
                                    <span className="details_span">{userInfo?.personalinfo?.clintcode}</span>
                                 </div>
                                 <div className="contact_rows">
                                    <span className="det_sep">Address</span> :
                                    <span className="details_span text-capitalize">
                                       {" "}
                                       {userInfo?.personalinfo?.address}
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               {/* ----- Your Relationship Manager Section ----- */}
               <div className="col-12 mt-4 col-md-6 mt-md-0 profile_wrapper">
                  <div className="row">
                     {/* ----- Header Text ----- */}
                     <div className="col-12 border-bottom border-light heading-text-container">
                        <div>
                           <h4 className="">Your Relationship Manager</h4>
                        </div>
                     </div>
                     {/* ---- Relationship Content ----- */}
                     <div className="col-12">
                        <div className="profile_detail profile_contact_details">
                           <div className="contact_rows">
                              <span className="det_sep">Name</span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 {userInfo?.personalinfo?.assignTo?.name}
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Mobile </span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 {userInfo?.personalinfo?.assignTo?.phone}
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Email</span> :
                              <span className="details_span">
                                 {" "}
                                 {userInfo?.personalinfo?.assignTo?.email}
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            {/* ----- Main Section 2 ----- */}
            <div className="row justify-content-between profile_wrapper my-5 ">
               {/* ----- Your Document Section ------ */}
               <div className="col-12 col-md-12 ">
                  <div className="row">
                     {/* ----- Header Text ----- */}
                     <div className="col-12 border-bottom border-light heading-text-container">
                        <div>
                           <h4 className="heading-text">Your Document </h4>
                        </div>
                     </div>
                     {/* ---- Document Content ----- */}
                     <div className="col-12">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="profile_contact_details user_details">
                                 <form
                                    action=""
                                    method="post"
                                    onSubmit={handleUpload}
                                    encType="multipart/form-data"
                                 >
                                    <div className="contact_rows">
                                       <span className="det_sep long-key">
                                          PAN Number
                                       </span>
                                       :
                                       <span className="details_span">
                                          {userInfo?.document?.panNumber}
                                       </span>
                                    </div>
                                    <div className="contact_rows">
                                       <span className="det_sep long-key">
                                          PAN Card
                                       </span>{" "}
                                       {userInfo?.document?.panCard ? (
                                          <>
                                             :{" "}
                                             <span className="details_span">
                                                {userInfo?.document?.panCard}
                                             </span>
                                          </>
                                       ) : (
                                          <div className="upload-file">
                                             {
                                                !fileName ? (
                                                   <label
                                                   htmlFor="panCard"
                                                   className="upload-small-container"
                                                >
                                                   <div>
                                                   
                                                      <i className="fa-solid fa-file-circle-plus "></i>
                                                      <p className="m-0 p-0 ">
                                                         Select File
                                                      </p>
                                                   </div>
                                                </label>
                                                )
                                                : 
                                                (
                                                   fileName && <p className="selected-file-name"> {fileName}</p>
                                                )
                                             }
                                             <input
                                                type="file"
                                                name="panCard"
                                                id="panCard"
                                                onChange={handlePanCard}
                                                disabled={isSubmitted}
                                                placeholder=" Select File"
                                             />
                                          </div>
                                       )}
                                    </div>
                                    <div className="contact_rows">
                                       <span className="det_sep long-key">
                                          Aadhar Card
                                       </span>{" "}
                                       {userInfo?.document?.aadharCard ? (
                                          <>
                                             :{" "}
                                             <span className="details_span text-capitalize">
                                                {userInfo?.document?.aadharCard}
                                             </span>
                                          </>
                                       ) : (
                                          <div className="upload-file">
                                            {
                                                !aadharfileName ? (
                                             <label
                                                htmlFor="aadharCard"
                                                className="upload-small-container"
                                             >
                                                <div>
                                                   <i className="fa-solid fa-file-circle-plus "></i>
                                                   <p className="m-0 p-0 ">
                                                      Select File
                                                   </p>
                                                </div>
                                             </label>
                                             )
                                                : 
                                                (
                                                   aadharfileName && <p className="selected-file-name"> {aadharfileName}</p>
                                                )
                                             }
                                             <input
                                                type="file"
                                                name="aadharCard"
                                                id="aadharCard"
                                                onChange={handleAadharCard}
                                                disabled={isSubmitted}
                                             />
                                          </div>
                                       )}
                                    </div>
                                    <div className="contact_rows">
                                       <span className="det_sep long-key">
                                          Cheque{" "}
                                       </span>{" "}
                                       {userInfo?.document?.cheque ? (
                                          <>
                                             :{" "}
                                             <span className="details_span text-capitalize">
                                                {userInfo?.document?.cheque}
                                             </span>
                                          </>
                                       ) : (
                                          <div className="upload-file">
                                             {
                                                !chequefileName ? (
                                             <label
                                                htmlFor="cheque"
                                                className="upload-small-container"
                                             >
                                                <div>
                                                   <i className="fa-solid fa-file-circle-plus"></i>
                                                   <p className="m-0 p-0">
                                                      Select File
                                                   </p>
                                                </div>
                                             </label>
                                              )
                                              : 
                                              (
                                                 chequefileName && <p className="selected-file-name"> { chequefileName}</p>
                                              )
                                           }
                                             <input
                                                type="file"
                                                name="cheque"
                                                id="cheque"
                                                onChange={handleCheque}
                                                // disabled={isSubmitted}
                                             />
                                          </div>
                                       )}
                                    </div>
                                    {/* ----- Upload Document Button----- */}
                                    <div className="upload-button pt-3">
                                       {error && (
                                          <p className="text-danger">{error}</p>
                                       )}
                                       
                                       <button
                                          className="sub_documnet"
                                          type="submit"
                                          style={{ display: userInfo?.document?.cheque && userInfo?.document?.aadharCard && userInfo?.document?.panCard ? 'none' : 'block' }}
                                       >
                                          Upload
                                       </button>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               
            </div>
            <div className="row justify-content-between profile_wrapper my-5 ">
         <div className="col-12">
                  <div className="row">
                     {/* ----- Header Text ----- */}
                     <div className="col-12 border-bottom border-light heading-text-container">
                        <div>
                           <h4 className="">Your Support Team</h4>
                        </div>
                     </div>
                    
                     <div className="col-12">
                     {/* <div className="profile_detail profile_contact_details">
                           <div className="contact_rows">
                              <span className="det_sep">Name </span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Madhu Diwakar
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Mobile</span> :
                              <span className="details_span">
                                 {" "}
                                 8130388996
                              </span>
                           </div>
                        </div>
                     {productNames?.includes("Mutual Fund") && (
                        <div className="profile_detail profile_contact_details">
                           <div className="contact_rows">
                              <span className="det_sep">Product</span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Mutual Fund
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Name </span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Krishan Kadu
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Mobile</span> :
                              <span className="details_span">
                                 {" "}
                                 9911005771
                              </span>
                           </div>
                        </div>
                     )}
                      {productNames?.includes("Real State") && (
                        <div className="profile_detail profile_contact_details">
                           <div className="contact_rows">
                              <span className="det_sep">Product</span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Real State
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Name </span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Parikshit Jha
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Product</span> :
                              <span className="details_span">
                                 {" "}
                                 9711801195
                              </span>
                           </div>
                        </div>
                      )}
                      {productNames?.includes("Equity Advisory") && (
                        <div className="profile_detail profile_contact_details">
                           <div className="contact_rows">
                              <span className="det_sep">Product</span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Equity Advisory
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Product </span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Shashi Kant
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Product</span> :
                              <span className="details_span">
                                 {" "}
                                 9899215244
                              </span>
                           </div>
                        </div>
                      )} */}
                     <Support
                     type={type}
                     />

                     </div>
                  </div>
               </div>
               </div>
         </div>

         {popupForm && (
            <div className="form_popups">
               {changePassForm && (
                  <div className="chagne_pass_form">
                     <div className="container">
                        <div className="row">
                           <div className="col-12 d-flex align-items-center justify-content-center">
                              <div
                                 className="formByScan_container pb-4 pt-5 position-relative"
                                 id="formByScan_container"
                                 style={{ overflow: "visible" }}
                              >
                                 <div
                                    className="popup_close_icon"
                                    onClick={closeChngPassPopup}
                                 >
                                    <i
                                       className="fa fa-xmark"
                                       onClick={closeChngPassPopup}
                                    ></i>
                                 </div>
                                 <form
                                    onSubmit={userChangePass}
                                    autoComplete="off"
                                 >
                                    <h2
                                       className="form-heading text-center mb-4"
                                       style={{ fontSize: "22px" }}
                                    >
                                       Change Password
                                    </h2>
                                    <input
                                       type="password"
                                       placeholder="Old Password *"
                                       required
                                       autoComplete="new-password"
                                       name="old_password"
                                       value={prevDetails.old_password}
                                       onChange={changePassHandler}
                                    />
                                    <input
                                       type="password"
                                       placeholder="New Password *"
                                       required
                                       autoComplete="new-password"
                                       name="new_password"
                                       value={prevDetails.new_password}
                                       onChange={changePassHandler}
                                    />
                                    <input
                                       type="password"
                                       placeholder="Confirm Password *"
                                       required
                                       autoComplete="new-password"
                                       name="c_password"
                                       value={prevDetails.c_password}
                                       onChange={changePassHandler}
                                    />
                                    <div className="mt-2 text-center">
                                       <button disabled={signupBtn}>
                                          Submit
                                       </button>
                                    </div>
                                 </form>
                              </div>
                           </div>
                           m
                        </div>
                     </div>
                  </div>
               )}
            </div>
         )}
      </>
    </>
  )
}

export default InvProfile