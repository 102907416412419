import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../../styles/mainCards.css";
import { useSelector } from "react-redux";
import {
  useNoticeBoardApiQuery,
  useNoticeBoardUpcomingApiQuery,
} from "../../store/services/fetchApiService";

function NoticeBoard() {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  var loggedUserCategory = loggedInUserData && loggedInUserData?.userType;
  let fetchId = "";
  if (loggedInUserData?.role === "rm") {
    fetchId = "647989d7a66ca4a4ef91bda9";
  } else if (loggedInUserData?.role === "fm") {
    fetchId = "647989e3a66ca4a4ef91bdaa";
  } else if (loggedInUserData?.role === "cp") {
    fetchId = "647d74468d3ff9520b95f7bd";
  } else if (loggedInUserData?.role === "investor") {
    fetchId = "647989b5a66ca4a4ef91bda8";
  } else {
    fetchId = loggedUserCategory;
  }
  const noticeResp = useNoticeBoardApiQuery(fetchId);
  const ServerPath =
    noticeResp?.isSuccess &&
    !noticeResp?.isLoading &&
    noticeResp?.status === "fulfilled" &&
    !noticeResp?.isError &&
    noticeResp?.data?.path;

  const noticeData =
    noticeResp?.isSuccess &&
    !noticeResp?.isLoading &&
    noticeResp?.status === "fulfilled" &&
    !noticeResp?.isError &&
    noticeResp?.data?.success &&
    noticeResp?.data?.notice;

  // useEffect(() => {
  //   console.log(noticeData);
  // }, [noticeData]);

  const upcomingNoticeResp = useNoticeBoardUpcomingApiQuery(fetchId);
  const upcomingNoticeData =
    upcomingNoticeResp?.isSuccess &&
    !upcomingNoticeResp?.isLoading &&
    upcomingNoticeResp?.status === "fulfilled" &&
    !upcomingNoticeResp?.isError &&
    upcomingNoticeResp?.data?.success &&
    upcomingNoticeResp?.data?.upcomingNews;

  // useEffect(() => {
  //   console.log(upcomingNoticeResp?.data);
  // }, [upcomingNoticeResp]);

  // Date in DD-MM-YYYY formate
  const dateFormate = (datestr) => {
    let splitdate = datestr.split("-")
    return splitdate[2]+'-'+splitdate[1]+'-'+splitdate[0]
  }
  // convert time with 12hours formate with am pm
  function tConvert (time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }
  
  // ====OPEN full URL in NEW Tab==========
  const openUrl = (url) => {
    if(url.includes('http')) {
      window.open(url, '_blank')
    } else{
      window.open("https://"+url, '_blank')
    }
  }
  return (
    <>
      <div className="notice__board">
        <div className="sec-title_list h2">
          <h2>Notice Board</h2>
        </div>
        <div className=" mb-2 h-50">
          <h4 className="mb-2">Upcoming Events</h4>
          <div className={`notice_container overflow-hidden`}>
            <div
              className={upcomingNoticeData?.length > 4 ? "marquee p-0" : "p-0"}
            >
              {upcomingNoticeData && upcomingNoticeData?.length > 0 ? (
                upcomingNoticeData?.map((event, i) => {
                  return (
                    <>
                      <div className="eneventLinks" key={event?._id + 1}>
                        <div className="events_container">
                          <div className="">
                            <div className="event_topic">
                              {event?.title}
                            </div>
                          </div>
                          <div className="eventLink d-flex align-items-center justify-content-between">
                            <div className="date__time">
                              <span>{dateFormate(event?.date)}</span> |{" "}
                              <span>{tConvert(event?.time)}</span>
                            </div>
                            <div onClick={() => openUrl(event?.url)} style={{cursor:'pointer'}} className="joinBtn">
                            {/* <Link to="" className="joinBtn"> */}
                              Join Now
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="eneventLinks">
                  <div className="events_container">
                    <div className="">
                      <div className="event_topic mb-1">There is no any upcoming events.</div>
                    </div>
                    <div className="eventLink d-flex align-items-center justify-content-between"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="notice__board h-50">
          <h4>Current News</h4>
          <div className={`notice_container overflow-hidden`}>
            <div
              className={noticeData && noticeData?.length > 4 ? "marquee" : "newsPara"}
            >
              <ul>
                {noticeData && noticeData?.length > 0 ? (
                  noticeData?.map((currentNews, index) => {
                    return (
                      <>
                        <li key={currentNews?._id}>
                          <a target="_blank" rel="noopener noreferrer" href={'/'+currentNews?.url}>
                            {currentNews?.title}
                          </a>
                        </li>
                      </>
                    );
                  })
                ) : (
                  <li>
                    <Link to={""}>There is no any current news.</Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoticeBoard;
