import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FranchiseSidebar from "../components/franchise/FranchiseSidebar";
import { useSelector } from "react-redux";
import DashboardBanner from "../components/general/DashboardBanner";
import Footer from "../components/Footer";
import axios from "axios";
import { Api_Base_Url } from "../constant/matcher";
import CPBusinessPlan from "../components/CPBusinessPlan";
import Loader from "../components/general/Loader";
import { formatNumberWithCommas } from "../utils/helper";

const BusinessPlan = (props) => {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [dynamicRoute, setdynamicRoute] = useState("");
  const [channelPartner, setChannelPartner] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [inputData, setInputData] = useState({});
  const [successStatus, setSuccessStatus] = useState("fade");
  const [businessData, setBusinessData] = useState([]);
  const [showPlan, setShowPlan] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isloading, setIsLoading] = useState(true);
  const [tabindex, setTabIndex] = useState(1);
  const [showButton, setShowButton] = useState(false);
  const [data, setData] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [showData, setShowData] = useState(false);
  const [teamId, setTeamId] = useState("");
  const [details, setDetails] = useState([]);
  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
  }, [loggedInUserData]);

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };
  const closeSuccessModal = () => {
    setSuccessStatus("fade");
  };

  const toggleTab = (index) => {
    setTabIndex(index);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleNameChange = (event) => {
    const name = event.target.value;
    setSelectedName(name);
    setLoading(true);
    const selectedPartner = channelPartner.find(
      (partner) => partner.name === name
    );
    if (selectedPartner) {
      setSelectedId(selectedPartner._id);
      let cpId = selectedPartner._id;
      getBusinessData(cpId);
      setShowPlan(true);
    } else {
      setSelectedId("");
    }
    const initialInputData = {};
    if (selectedPartner) {
      selectedPartner.product.forEach((product) => {
        initialInputData[product._id] = "";
      });
    }
    setInputData(initialInputData);
  };

  const handleInputChange = (event, productId, month, fieldType = null) => {
    const { value } = event.target;

    // Check if the entered value is not a negative number
    if (fieldType && (value >= 0 || value === "")) {
      setInputData((prevInputData) => ({
        ...prevInputData,
        [`${productId}-${month}-${fieldType}`]: value,
      }));
    } else if (!fieldType && (value >= 0 || value === "")) {
      setInputData((prevInputData) => ({
        ...prevInputData,
        [`${productId}-${month}`]: value,
      }));
    }
  };

  // Function to calculate the sum for a specific product
  const calculateMutualFundSum = (productId, fieldType) => {
    const productData = inputData;
    let sum = 0;

    for (const month of months) {
      if (fieldType) {
        const inputKey = `${productId}-${month}-${fieldType}`;
        if (productData[inputKey]) {
          sum += parseFloat(productData[inputKey]) || 0;
        }
      } else {
        const inputKeySIP = `${productId}-${month}-SIP`;
        const inputKeySIP_LS = `${productId}-${month}-SIP-LS`;
        const inputKeyMRI = `${productId}-${month}-MRI`;
        const inputKeyEquity = `${productId}-${month}-Equity`;

        if (productData[inputKeySIP]) {
          sum += parseFloat(productData[inputKeySIP]) || 0;
        }

        if (productData[inputKeySIP_LS]) {
          sum += parseFloat(productData[inputKeySIP_LS]) || 0;
        }
        if (productData[inputKeyMRI]) {
          sum += parseFloat(productData[inputKeyMRI]) || 0;
        }

        if (productData[inputKeyEquity]) {
          sum += parseFloat(productData[inputKeyEquity]) || 0;
        }
      }
    }

    return sum;
  };

  const getChannelPartnerData = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios.get(`${Api_Base_Url}/rm/my-channel-partner?id=${id}`).then((res) => {
      setChannelPartner(res?.data?.myChannel);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;

    // Create the payload structure with channelPartner and monthlyData
    const payload = {
      channelPartner: selectedId,
      monthlyData: [],
    };
    // Loop through months and products to populate monthlyData

    months.forEach((month) => {
      const productData = [];

      // Loop through your products and add product data
      channelPartner
        .find((partner) => partner.name === selectedName)
        .product.forEach((product) => {
          const sipInput = inputData[`${product._id}-${month}-SIP`];
          const sipLsInput = inputData[`${product._id}-${month}-SIP-LS`];
          const equityInput = inputData[`${product._id}-${month}-Equity`];
          const mriInput = inputData[`${product._id}-${month}-MRI`];

          productData.push({
            productId: product._id,
            sipAmount: sipInput || 0,
            lumpsumAmount: sipLsInput || 0,
            equityAmount: equityInput || 0,
            mriAmount: mriInput || 0,
          });
        });

      // Add monthly data if there's at least one product with input data for the month
      if (productData.length > 0) {
        payload.monthlyData.push({
          month: month,
          productData: productData,
        });
      }
    });

    try {
      const response = await axios.post(
        `${Api_Base_Url}/rm/rmBussinessplan?rmid=${id}`,
        payload
      );
      // console.log('Data sent successfully:', response.data);
      setSuccessStatus("d-flex");
      setShowPlan(false);
      setSelectedName("");
      setInputData({});
      getBusinessData();
    } catch (error) {
      console.log("Error sending data:", error);
    }
  };

  const getBusinessData = (cpId) => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios
      .get(`${Api_Base_Url}/rm/rmBussinessplan?rmid=${id}&cp=${cpId}`)
      .then((res) => {
        setBusinessData(res?.data[0]?.monthlyData);

        setLoading(false);

        // console.log("response:", res?.data[0]?.monthlyData);
      });
  };

  const getData = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios.get(`${Api_Base_Url}/rm/profile?id=${id}`).then((res) => {
      const data = res?.data?.profileData;
      if (data?.assignTo !== null) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  };

  const handleTeamName = (e) => {
    const name = e.target.value;
    setTeamName(name);
    setLoading(true);
    const selectedPartner = data.find((partner) => partner.name === name);
    if (selectedPartner) {
      setTeamId(selectedPartner._id);
      let cpId = selectedPartner._id;
      getTeamData(cpId);
      setShowData(true);
    } else {
      setTeamId("");
    }
  };

  const getTeamName = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios.get(`${Api_Base_Url}/rm/my-rm?id=${id}`).then((res) => {
      setData(res?.data?.RMData);
    });
  };

  const getTeamData = (cpId) => {
    axios.get(`${Api_Base_Url}/rm/teamplan?id=${cpId}`).then((res) => {
      setDetails(res?.data?.businessPlans[0]?.monthlyData);
      console.log(res?.data?.businessPlans[0]?.monthlyData);
      setLoading(false);

      // console.log("response:", res?.data[0]?.monthlyData);
    });
  };

  useEffect(() => {
    getChannelPartnerData();
    getData();
    getTeamName();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });

  return (
    <>
      {isloading ? (
        <Loader />
      ) : (
        <>
          <FranchiseSidebar navContWrapp={navContWrapp} />
          <section
            className={`dash-content-wrapper small-wrapper`}
            style={{ paddingLeft: navShow ? "230px" : "70px" }}
          >
            <DashboardBanner />
            <div className="breadcrumbs">
              <div className="container">
                <p>
                  <Link to={"/" + dynamicRoute + "/" + loggedInUserData?.role}>
                    Dashboard
                  </Link>{" "}
                  <i className="fa-solid fa-angle-right"></i> Create Business
                  Plan
                </p>
              </div>
            </div>

            <div
              className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
              style={{ background: "whitesmoke" }}
            >
              <div className="row table__row__wrapp">
                <div className="client_table_container py-2">
                  <div className="d-flex ">
                    <button
                      className="generate_support px-3 py-2"
                      onClick={() => toggleTab(1)}
                    >
                      {" "}
                      My Business Plan{" "}
                    </button>
                    {loggedInUserData?.role === "rm" && showButton && (
                      <button
                        className="ms-3 generate_support px-3 py-2"
                        onClick={() => toggleTab(2)}
                      >
                        {" "}
                        Team Business Plan{" "}
                      </button>
                    )}
                  </div>
                  {loggedInUserData?.role === "rm" ? (
                    <div className="w-100 overflow-auto">
                      <form
                        // onSubmit={onFormSubmit}
                        className={
                          tabindex === 1
                            ? "contact-form pt-0 pb-0 mt-4"
                            : "contact-form pt-0 pb-0 mt-4 show-business-plan"
                        }
                        autoComplete="off"
                        onSubmit={handleSubmit}
                      >
                        <div className="col">
                          <select
                            name="name"
                            id="name"
                            className="form-select  rounded border-white mb-2 form-input"
                            required
                            onChange={handleNameChange}
                            value={selectedName}
                          >
                            <option value="">My Channel Partner *</option>
                            {channelPartner &&
                              channelPartner?.map((result) => {
                                return (
                                  <option value={result?.name}>
                                    {result?.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        {showPlan && selectedName && (
                          <div className="col mt-3 business-plan mb-3">
                            {!businessData ? (
                              <div class="container text-left">
                                {loading ? (
                                  <div className="loading">
                                    <span className="p-3">Loading....</span>
                                  </div>
                                ) : (
                                  <div class="row">
                                    <h5 className="my-3 text-center">
                                      Create your Business Plan for the{" "}
                                      {currentYear}
                                    </h5>
                                    <hr />
                                    <div class="col mt-2">
                                      <h6>Months</h6>
                                      <div className="create-business">
                                        {months.map((month, index) => (
                                          <span key={index} className="mt-3">
                                            {month}
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                    <div class="col mt-2">
                                      <h6>Year</h6>
                                      <div className="create-business">
                                        {months.map((month, index) => (
                                          <span key={index} className="mt-3">
                                            {currentYear}
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                    {channelPartner
                                      .find(
                                        (partner) =>
                                          partner.name === selectedName
                                      )
                                      .product.map((product) => (
                                        <div class="col mt-2">
                                          <h6>{product.name}</h6>
                                          <div className="create-business">
                                            {months.map((month, index) => (
                                              <div key={index}>
                                                {product.name ===
                                                "Mutual Fund" ? (
                                                  <div className="d-flex">
                                                    <input
                                                      required
                                                      className="mb-2 me-2"
                                                      type="number"
                                                      name={`${product._id}-${month}-SIP`}
                                                      id={`${product._id}-${month}-SIP`}
                                                      placeholder="Enter SIP"
                                                      onChange={(e) =>
                                                        handleInputChange(
                                                          e,
                                                          product._id,
                                                          month,
                                                          "SIP"
                                                        )
                                                      }
                                                      onWheel={(e) =>
                                                        e.currentTarget.blur()
                                                      }
                                                      value={
                                                        inputData[
                                                          `${product._id}-${month}-SIP`
                                                        ] || ""
                                                      }
                                                      disabled={
                                                        months.indexOf(
                                                          currentMonth
                                                        ) >
                                                        months.indexOf(month)
                                                      }
                                                    />
                                                    <input
                                                      required
                                                      className="mb-2"
                                                      type="number"
                                                      name={`${product._id}-${month}-SIP-LS`}
                                                      id={`${product._id}-${month}-SIP-LS`}
                                                      placeholder="Enter Lumpsum"
                                                      onChange={(e) =>
                                                        handleInputChange(
                                                          e,
                                                          product._id,
                                                          month,
                                                          "SIP-LS"
                                                        )
                                                      }
                                                      onWheel={(e) =>
                                                        e.currentTarget.blur()
                                                      }
                                                      value={
                                                        inputData[
                                                          `${product._id}-${month}-SIP-LS`
                                                        ] || ""
                                                      }
                                                      disabled={
                                                        months.indexOf(
                                                          currentMonth
                                                        ) >
                                                        months.indexOf(month)
                                                      }
                                                    />
                                                  </div>
                                                ) : (
                                                  <>
                                                    {product.name ===
                                                      "Equity Advisory" && (
                                                      <input
                                                        required
                                                        className="mb-2"
                                                        type="number"
                                                        name={`${product._id}-${month}-Equity`}
                                                        id={`${product._id}-${month}-Equity`}
                                                        placeholder="Enter Equity"
                                                        onChange={(e) =>
                                                          handleInputChange(
                                                            e,
                                                            product._id,
                                                            month,
                                                            "Equity"
                                                          )
                                                        }
                                                        onWheel={(e) =>
                                                          e.currentTarget.blur()
                                                        }
                                                        value={
                                                          inputData[
                                                            `${product._id}-${month}-Equity`
                                                          ] || ""
                                                        }
                                                        disabled={
                                                          months.indexOf(
                                                            currentMonth
                                                          ) >
                                                          months.indexOf(month)
                                                        }
                                                      />
                                                    )}
                                                    {product.name ===
                                                      "Monthly Rental Income" && (
                                                      <input
                                                        required
                                                        className="mb-2"
                                                        type="number"
                                                        name={`${product._id}-${month}-MRI`}
                                                        id={`${product._id}-${month}-MRI`}
                                                        placeholder="Enter MRI"
                                                        onChange={(e) =>
                                                          handleInputChange(
                                                            e,
                                                            product._id,
                                                            month,
                                                            "MRI"
                                                          )
                                                        }
                                                        onWheel={(e) =>
                                                          e.currentTarget.blur()
                                                        }
                                                        value={
                                                          inputData[
                                                            `${product._id}-${month}-MRI`
                                                          ] || ""
                                                        }
                                                        disabled={
                                                          months.indexOf(
                                                            currentMonth
                                                          ) >
                                                          months.indexOf(month)
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            ))}
                                            {product.name === "Mutual Fund" ? (
                                              <div className="d-flex">
                                                <div className="me-4">
                                                  Total:{" "}
                                                  {calculateMutualFundSum(
                                                    product._id,
                                                    "SIP"
                                                  ) || 0}
                                                </div>
                                                <div className="ms-3">
                                                  Total:{" "}
                                                  {calculateMutualFundSum(
                                                    product._id,
                                                    "SIP-LS"
                                                  ) || 0}
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                {product.name ===
                                                  "Monthly Rental Income" && (
                                                  <div className="me-4">
                                                    Total:{" "}
                                                    {calculateMutualFundSum(
                                                      product._id,
                                                      "MRI"
                                                    ) || 0}
                                                  </div>
                                                )}
                                                {product.name ===
                                                  "Equity Advisory" && (
                                                  <div className="ms-3">
                                                    Total:{" "}
                                                    {calculateMutualFundSum(
                                                      product._id,
                                                      "Equity"
                                                    ) || 0}
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="w-100 overflow-auto ps-3">
                                <h5 className="my-3 text-center">
                                  Business Plan for the {currentYear}
                                </h5>
                                {loading ? (
                                  <div className="loading">
                                    <span className="p-3">Loading....</span>
                                  </div>
                                ) : (
                                  <table className="table table-striped client__table">
                                    <thead>
                                      <tr>
                                        <th>Sr. No.</th>
                                        <th scope="col">Month</th>
                                        <th>Year</th>
                                        <React.Fragment>
                                          {businessData &&
                                            
                                              businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Equity Advisory" &&
                                                    parseFloat(
                                                      data.equityAmount
                                                    ) !== 0
                                                )
                                              ) && <th>Equity Advisory</th>}
                                            
                                          {businessData &&
                                            businessData.some((month) =>
                                              month.productData.some(
                                                (data) =>
                                                  data.productId.name ===
                                                    "Monthly Rental Income" &&
                                                  parseFloat(data.mriAmount) !==
                                                    0
                                              )
                                            ) && <th>Monthly Rental Income</th>}

                                          {businessData && businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Mutual Fund" &&
                                                    parseFloat(
                                                      data.sipAmount
                                                    ) !== 0
                                                )
                                              ) && <th>Mutual Fund</th>}
                                           

                                          {businessData && businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Mutual Fund" &&
                                                    parseFloat(
                                                      data.sipAmount
                                                    ) !== 0
                                                )
                                              ) && <th></th>}
                                          
                                        </React.Fragment>
                                      </tr>
                                    </thead>
                                    <thead>
                                      <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>

                                        <React.Fragment>
                                          {businessData && businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Equity Advisory" &&
                                                    parseFloat(
                                                      data.equityAmount
                                                    ) !== 0
                                                )
                                              ) && <th></th>}
                                           
                                          {businessData && businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Monthly Rental Income" &&
                                                    parseFloat(
                                                      data.mriAmount
                                                    ) !== 0
                                                )
                                              ) && <th></th>}
                                           
                                          {businessData && businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Mutual Fund" &&
                                                    parseFloat(
                                                      data.sipAmount
                                                    ) !== 0
                                                )
                                              ) && <th>SIP</th>}
                                            
                                          {businessData && businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Mutual Fund" &&
                                                    parseFloat(
                                                      data.lumpsumAmount
                                                    ) !== 0
                                                )
                                              ) && <th>Lumpsum</th>}
                                           
                                        </React.Fragment>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {businessData &&
                                        businessData?.map((result, index) => {
                                          return (
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>{result?.month}</td>
                                              <td>{currentYear}</td>
                                              {result?.productData?.map((i) => {
                                                return (
                                                  <React.Fragment key={i._id}>
                                                    {i.equityAmount !== 0 && (
                                                      <td>
                                                        {formatNumberWithCommas(
                                                          i.equityAmount
                                                            ? i.equityAmount
                                                            : "00"
                                                        )}
                                                      </td>
                                                    )}
                                                    {i.mriAmount !== 0 && (
                                                      <td>
                                                        {formatNumberWithCommas(
                                                          i.mriAmount
                                                            ? i.mriAmount
                                                            : "00"
                                                        )}
                                                      </td>
                                                    )}
                                                    {i.sipAmount !== 0 && (
                                                      <td>
                                                        {formatNumberWithCommas(
                                                          i.sipAmount
                                                            ? i.sipAmount
                                                            : "00"
                                                        )}
                                                      </td>
                                                    )}
                                                    {i.lumpsumAmount !== 0 && (
                                                      <td>
                                                        {formatNumberWithCommas(
                                                          i.lumpsumAmount
                                                            ? i.lumpsumAmount
                                                            : "00"
                                                        )}
                                                      </td>
                                                    )}
                                                  </React.Fragment>
                                                );
                                              })}
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {!businessData && (
                          <button
                            className="reg-btn mt-3"
                            style={{ float: "right" }}
                          >
                            Submit
                          </button>
                        )}
                      </form>
                      <div className="w-100 overflow-auto">
                        <div
                          className={
                            tabindex === 2
                              ? "w-100 contact-form overflow-auto ps-3 rounded border-white mb-2"
                              : "w-100 contact-form overflow-auto ps-3 rounded border-white mb-2 show-business-plan"
                          }
                        >
                          <div className="col">
                            <select
                              name="name"
                              id="name"
                              className="form-select  rounded border-white mb-2 form-input"
                              required
                              onChange={handleTeamName}
                              value={teamName}
                            >
                              <option value="">My Team *</option>
                              {data &&
                                data?.map((result) => {
                                  return (
                                    <option value={result?.name}>
                                      {result?.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          {teamName && showData && (
                            <div className="w-100 overflow-auto ps-3 business-plan">
                              {loading ? (
                                <div className="loading">
                                  <span className="p-3">Loading....</span>
                                </div>
                              ) : (
                                <table className="table table-striped client__table ">
                                  <thead>
                                    <tr>
                                      <th>Sr. No.</th>
                                      <th scope="col">Month</th>
                                      {details &&
                                        details[0]?.productData.map((i) => (
                                          <React.Fragment key={i.productId}>
                                            {i.equityAmount !== 0 && (
                                              <th scope="col">
                                                Equity Advisory
                                              </th>
                                            )}
                                            {i.mriAmount !== 0 && (
                                              <th scope="col">
                                                Monthly Rental Income
                                              </th>
                                            )}
                                            {i.sipAmount !== 0 && (
                                              <th scope="col">Mutual Fund</th>
                                            )}
                                            {i.lumpsumAmount !== 0 && (
                                              <th scope="col"></th>
                                            )}
                                          </React.Fragment>
                                        ))}
                                    </tr>
                                  </thead>
                                  <thead>
                                    <tr>
                                      <th scope="col"></th>
                                      <th scope="col"></th>

                                      {details &&
                                        details[0]?.productData.map((i) => (
                                          <React.Fragment key={i.productId}>
                                            {i.equityAmount !== 0 && (
                                              <th scope="col"></th>
                                            )}
                                            {i.mriAmount !== 0 && (
                                              <th scope="col"></th>
                                            )}
                                            {i.sipAmount !== 0 && (
                                              <th scope="col">SIP</th>
                                            )}
                                            {i.lumpsumAmount !== 0 && (
                                              <th scope="col">MF-LS</th>
                                            )}
                                          </React.Fragment>
                                        ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {details && details.length > 0 ? (
                                      details?.map((result, index) => {
                                        return (
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>{result?.month}</td>
                                            {result?.productData?.map((i) => {
                                              return (
                                                <React.Fragment key={i._id}>
                                                  {i.equityAmount !== 0 && (
                                                    <td>
                                                      {formatNumberWithCommas(
                                                        i.equityAmount
                                                      )}
                                                    </td>
                                                  )}
                                                  {i.mriAmount !== 0 && (
                                                    <td>
                                                      {formatNumberWithCommas(
                                                        i.mriAmount
                                                      )}
                                                    </td>
                                                  )}
                                                  {i.sipAmount !== 0 && (
                                                    <td>
                                                      {formatNumberWithCommas(
                                                        i.sipAmount
                                                      )}
                                                    </td>
                                                  )}
                                                  {i.lumpsumAmount !== 0 && (
                                                    <td>
                                                      {formatNumberWithCommas(
                                                        i.lumpsumAmount
                                                      )}
                                                    </td>
                                                  )}
                                                </React.Fragment>
                                              );
                                            })}
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <p className="mb-0 ms-2 ">
                                        No Plan Available
                                      </p>
                                    )}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <CPBusinessPlan />
                  )}
                </div>
              </div>
            </div>
            {/* <SuccessModal successStatus={'d-flex'} /> */}
            <div
              className={`modal custom-modal align-items-center ${successStatus}`}
              id="formSuccessModal"
              style={{ background: "rgba(0,0,0,0.3)" }}
              tabIndex="-1"
              role="dialog"
              aria-labelledby="formSuccessModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div
                    className="modal-header "
                    style={{ background: "#FF4B2B" }}
                  >
                    <h5 className="modal-title" id="formSuccessModalLabel">
                      Success
                    </h5>
                    <button
                      type="button"
                      className="close"
                      onClick={closeSuccessModal}
                      // data-bs-dismiss='modal'
                      // aria-label='Close'
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="form-popup-inner">
                      <div className="form-icon success">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 130.2 130.2"
                        >
                          <circle
                            className="path circle"
                            fill="none"
                            strokeWidth="6"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                          />
                          <polyline
                            className="path check"
                            fill="none"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                          />
                        </svg>
                      </div>
                      <div className="form-body">
                        <p>
                          Thank you for filling out form. Your Business plan
                          created successfully.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Link
                      to="#"
                      className="popup-btn "
                      style={{ background: "#FF4B2B" }}
                      // data-bs-dismiss='modal'
                      onClick={closeSuccessModal}
                    >
                      Okay
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
        </>
      )}
    </>
  );
};

export default BusinessPlan;
