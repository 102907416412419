import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { useGetCityListApiQuery } from '../../store/services/loginService.js'

function CityInput ({ cityHandler, cityPlaceholder }) {
  // api for select options
  const [selectedCity, setSelectedCity] = useState(null)

  const cityResp = useGetCityListApiQuery()
  const cityFetchedData =
    cityResp?.isSuccess &&
    !cityResp?.isLoading &&
    cityResp.status === 'fulfilled' &&
    !cityResp?.isError &&
    cityResp?.data?.cities

  const searchOptions =
    cityFetchedData &&
    cityFetchedData?.map(({ city, _id }) => ({
      label: city,
      value: _id
    }))

    useEffect(() => {
      cityHandler(selectedCity?.value)
    }, [selectedCity]);
  
  return (
    <>
          <Select
            className='basic-single w-100'
            classNamePrefix='select'
            defaultValue={selectedCity}
            onChange={setSelectedCity}
            isClearable={true}
            placeholder={cityPlaceholder}
            isSearchable={true}
            name='city'
            options={searchOptions?searchOptions:[]}
            
          />
    </>
  )
}

export default CityInput
