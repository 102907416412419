import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Assessment from '../../constant/certificationQuestions.json'
import Quest from '../../constant/question.json'
import '../../styles/multiStep.css'

function FirstPage (props) {
  
  let formStepsNum = 0

  const submitAssessment = e => {
    e.preventDefault()
    formStepsNum++
    updateFormSteps()
    updateProgressbar()
  }

  const nextClick = () => {
    formStepsNum++
      updateFormSteps()
      updateProgressbar()
  }
  
  const prevClick = () => {
    formStepsNum--
    updateFormSteps()
    updateProgressbar()
  }
 
  function updateFormSteps () {
    const formSteps = document.querySelectorAll('.form-step')
    
    formSteps?.forEach(formStep => {
      formStep?.classList?.contains('form-step-active') &&
        formStep?.classList?.remove('form-step-active')
    })
    formSteps[formStepsNum]?.classList?.add('form-step-active')
    window.scrollTo({ top: 160, left: 0, behavior: 'instant' })
  }

  function updateProgressbar () {
    const progress = document.getElementById('progress')
    const progressSteps = document.querySelectorAll('.progress-step')

    progressSteps?.forEach((progressStep, idx) => {
      if (idx < formStepsNum + 1) {
        progressStep?.classList?.add('progress-step-active')
      } else {
        progressStep?.classList?.remove('progress-step-active')
      }
    })

    const progressActive = document.querySelectorAll('.progress-step-active')

    progress.style.width =
      ((progressActive?.length - 1) / (progressSteps?.length - 1)) * 100 + '%'

    // window.scrollTo({ top: 200, left: 0, behavior: 'instant' })
  }

  useEffect(() => {
    console.log(Quest);
  }, []);

  return (
    <>
      <form className='form assessment_form' onSubmit={submitAssessment}>
        <h1 className='text-center'>Assessment</h1>
        {/* <!-- Progress bar --> */}
        <div className='progressbar'>
          <div className='progress' id='progress'></div>

          <div
            className='progress-step progress-step-active'
            data-title='firstPage'
          ></div>
          <div className='progress-step' data-title='secondPage'></div>
          <div className='progress-step' data-title='thirdPage'></div>
          <div className='progress-step' data-title='fourthPage'></div>
          <div className='progress-step' data-title='fifthPage'></div>
        </div>

        {/* <!-- Steps --> */}

        {/* First Step */}
        <div className='form-step form-step-active'>
          {Assessment?.slice(0, 5).map((elem, i) => {
            return (
              <>
                <div className='quiz_ass_qa' key={i + elem.id}>
                  <div className='quiz__questions'>
                    <p>{elem.id + '. ' + elem.ques}</p>
                  </div>
                  <div className='quiz__options mb-3'>
                    {elem.options.map((option, i) => {
                      return (
                        <>
                          <div className='input-group'>
                            <input
                              key={i + elem.id}
                              className='form-check-input ans_check_input'
                              style={{ cursor: 'pointer' }}
                              type='radio'
                              name={elem.name}
                              id={elem.name + '_' + elem.id}
                              value={option.isCorrect}
                              onChange={props.changeHandler(elem)}
                            />
                            <label
                              className='form-check-label'
                              htmlFor={elem.name}
                            >
                              {option.text}
                            </label>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </>
            )
          })}
          <div className='btn__cont'>
            <div className='btn btn-next width-50 ml-auto' onClick={nextClick}>Next</div>
          </div>
        </div>

        {/* second step */}
        <div className='form-step'>
          {Assessment?.slice(5, 10).map((elem, i) => {
            return (
              <>
                <div className='quiz_ass_qa' key={i + elem.id}>
                  <div className='quiz__questions'>
                    <p>{elem.id + '. ' + elem.ques}</p>
                  </div>
                  <div className='quiz__options mb-3'>
                    {elem.options.map((option, i) => {
                      return (
                        <>
                          <div className='input-group'>
                            <input
                              key={i + elem.id}
                              className='form-check-input ans_check_input'
                              style={{ cursor: 'pointer' }}
                              type='radio'
                              name={elem.name}
                              id={elem.name + '_' + elem.id}
                              value={option.isCorrect}
                              onChange={props.changeHandler(elem)}
                            />
                            <label
                              className='form-check-label'
                              htmlFor={elem.name}
                            >
                              {option.text}
                            </label>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </>
            )
          })}
          <div className='btns-group btn__cont'>
            <div className='btn btn-prev' onClick={prevClick}>Previous</div>
            <div className='btn btn-next' onClick={nextClick}>Next</div>
          </div>
        </div>

        {/* third step */}
        <div className='form-step'>
          {Assessment?.slice(10, 15).map((elem, i) => {
            return (
              <>
                <div className='quiz_ass_qa' key={i + elem.id}>
                  <div className='quiz__questions'>
                    <p>{elem.id + '. ' + elem.ques}</p>
                  </div>
                  <div className='quiz__options mb-3'>
                    {elem.options.map((option, i) => {
                      return (
                        <>
                          <div className='input-group'>
                            <input
                              key={i + elem.id}
                              className='form-check-input ans_check_input'
                              style={{ cursor: 'pointer' }}
                              type='radio'
                              name={elem.name}
                              id={elem.name + '_' + elem.id}
                              value={option.isCorrect}
                              onChange={props.changeHandler(elem)}
                            />
                            <label
                              className='form-check-label'
                              htmlFor={elem.name}
                            >
                              {option.text}
                            </label>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </>
            )
          })}
          <div className='btns-group btn__cont'>
            <div className='btn btn-prev' onClick={prevClick}>Previous</div>
            <div className='btn btn-next' onClick={nextClick}>Next</div>
          </div>
        </div>

        {/* fourthStep */}
        <div className='form-step'>
          {Assessment?.slice(15).map((elem, i) => {
            return (
              <>
                <div className='quiz_ass_qa' key={i + elem.id}>
                  <div className='quiz__questions'>
                    <p>{elem.id + '. ' + elem.ques}</p>
                  </div>
                  <div className='quiz__options mb-3'>
                    {elem.options.map((option, i) => {
                      return (
                        <>
                          <div className='input-group'>
                            <input
                              key={i + elem.id}
                              className='form-check-input ans_check_input'
                              style={{ cursor: 'pointer' }}
                              type='radio'
                              name={elem.name}
                              id={elem.name + '_' + elem.id}
                              value={option.isCorrect}
                              onChange={props.changeHandler(elem)}
                            />
                            <label
                              className='form-check-label'
                              htmlFor={elem.name}
                            >
                              {option.text}
                            </label>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </>
            )
          })}
          <div className='btns-group btn__cont'>
            <div className='btn btn-prev' onClick={prevClick}>Previous</div>
            <input
              type='submit'
              value='Submit'
              className='btn btn-submit btn-primary'
            />
          </div>
        </div>

        {/* final step */}
        <div className='form-step'>
          <div className='text-center mb-4'>
            <h2>
              Your Score Is : <span className='score_board'>Moderate</span>
            </h2>
          </div>
          <div className='btns-group btn__cont justify-content-center'>
            <div className='btn btn_download'>Download Certificate</div>
          </div>
        </div>
      </form>
    </>
  )
}

export default FirstPage
