import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/mainCards.css";
import "../../styles/loginFormStyle.css";
import { useGetOtpMutation } from "../../store/services/loginService";
import { useSelector } from "react-redux";
import { useRef } from "react";
import axios from "axios";
import {
  Api_Base_Url,
  Server_Path_static,
  qrpath,
  urlpath,
} from "../../constant/matcher";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { BsCheck2 } from "react-icons/bs";
import Support from "../cardsDesigns/Support";

const ClientProfile = (props) => {
  const [signupBtn, setSignupBtn] = useState(true);
  const [popupForm, setPopupForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [changePassForm, setChangePassForm] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [panCard, setPanCard] = useState("");
  const [aadharCard, setAadharCard] = useState("");
  const [cheque, setCheque] = useState("");
  const [error, setError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fileName, setFileName] = useState("");
  const [aadharfileName, setAadharFileName] = useState("");
  const [chequefileName, setChequeFileName] = useState("");
  const [userInfo, setUserInfo] = useState([]);
  const [arn, setArn] = useState("");
  const [downloadPopup, setdownloadPopup] = useState(false);
  const [qrContent, setQrContent] = useState("");
  const domEl = useRef(null);
  const [popupImgSrc, setPopupImgSrc] = useState("");
  const [isuploading, setIsuploading] = useState(true);
  const userLogginId = JSON.parse(localStorage.getItem("loginUserData"));
  const [panNumber, setPanNumber] = useState("")
  const userId = userLogginId._id;
  const type = userLogginId.role;
  const path = `?type=${type}&qid=${userId}`;
  useEffect(() => {
    setName(props.username);
    setEmail(props.email);
    setMobile(props.mobile);
  }, [props.username, props.email, props.mobile]);

  const profDetails = useSelector(
    (state) => state?.dashReducer?.profileDetails
  );

  const rmDetMail =
    profDetails?.slug?.toUpperCase() === "Investor"
      ? "support@xpandwealth.com"
      : "help@xpandwealth.com";

  const showChang_form = () => {
    setChangePassForm(true);
    setPopupForm(true);
    setEditForm(false);
  };

  const closeChngPassPopup = () => {
    setChangePassForm(false);
    setEditForm(false);
    setPopupForm(false);
  };

  const [prevDetails, setprevDetails] = useState({
    old_password: "",
    new_password: "",
    c_password: "",
  });

  const changePassHandler = (e) => {
    setprevDetails({ ...prevDetails, [e.target.name]: e.target.value });
  };

  // api to get otp
  const [optPhone, otpResp = {}] = useGetOtpMutation();

  useEffect(() => {
    const otpInput = document.getElementById("otp-input");
    if (
      otpResp?.isSuccess &&
      !otpResp?.isLoading &&
      otpResp.status === "fulfilled" &&
      !otpResp?.isError &&
      otpResp?.data.message &&
      !otpResp?.data?.otpData
    ) {
      console.log("notfournd");
    }
    if (
      otpResp?.isSuccess &&
      !otpResp?.isLoading &&
      otpResp.status === "fulfilled" &&
      !otpResp?.isError &&
      otpResp?.data?.otpData
    ) {
      if (otpInput) {
        otpInput.value = otpResp?.data?.otpData?.otp;
        prevDetails.otp = otpResp?.data?.otpData?.otp;
      }
    }
  }, [otpResp]);
  // opt num submit handler
  const otpBtnClick = () => {
    var otpNum = document.getElementById("mobile");
    if (otpNum?.value?.length === 10) {
      optPhone({ phone: otpNum?.value });
    }
  };
  const userChangePass = (e) => {
    e.preventDefault();
    if (prevDetails.new_password === prevDetails.c_password) {
      console.log("passmatch", prevDetails);
    } else {
      console.log("npass and cpass must be equal");
    }
  };

  useEffect(() => {
    let mobInpt = document.getElementById("mobile");
    if (mobInpt) {
      mobInpt.oninput = () => {
        if (mobInpt.value.length > mobInpt.maxLength) {
          mobInpt.value = mobInpt.value.slice(0, mobInpt.maxLength);
        }
      };
    }
  }, []);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handlePanCard = (e) => {
    const selectedFile = e.target.files[0];
    setPanCard(selectedFile);
    if (selectedFile) {
      setFileName(selectedFile.name);
    } else {
      setFileName("");
    }
  };
  const handleAadharCard = (e) => {
    const selectedFile = e.target.files[0];
    setAadharCard(selectedFile);
    if (selectedFile) {
      setAadharFileName(selectedFile.name);
    } else {
      setAadharFileName("");
    }
  };
  const handleCheque = (e) => {
    const selectedFile = e.target.files[0];
    setCheque(selectedFile);
    if (selectedFile) {
      setChequeFileName(selectedFile.name);
    } else {
      setChequeFileName("");
    }
  };

  const validateInput = () => {
    if (!panCard && !aadharCard && !cheque) {
      setError("Please upload at least one Document");
      return false;
    }
    setError("");
    return true;
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    if (!validateInput()) {
      return;
    }
    const formData = new FormData();
    formData.append("panCard", panCard);
    formData.append("aadharCard", aadharCard);
    formData.append("cheque", cheque);
    formData.append("panNumber", panNumber || userInfo?.document?.panNumber)
    formData.append("address", userInfo?.personalinfo?.address)
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    formData.append("userId", loginUserData?._id || "");

   //  console.log("FormData entries:");
   //              for (const entry of formData.entries()) {
   //                console.log(entry);
   //              }
    try {
      const response = await axios.post(
        `${Api_Base_Url}/cp/upload-document`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("response:", response.data);
      if (response) {
        setIsuploading(false);
        setIsSubmitted(true);
      }
    } catch (error) {
      console.error("handleUpload : ", error);
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
        const id = loginUserData._id;
        const type = loginUserData.role;
        const response = await axios.get(
          `${Api_Base_Url}/cp/getdetail?type=${type}&id=${id}`
        );
        setUserInfo(response?.data?.data);
        const clientId = userInfo?.personalinfo?.bp_id;
        localStorage.setItem("clientId", clientId);
      } catch (error) {
        console.log("getUserData : ", error);
      }
    };
    getUserData();
  }, []);
  const productNames = userInfo?.personalinfo?.product.map(
    (product) => product.name
  );

  const downloadImage = async () => {
    html2canvas(domEl.current, {
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const base64img = canvas.toDataURL("image/jpg");
      saveAs(base64img, "creative-product");
    });
    let timerId = setTimeout(() => {
      setdownloadPopup(false);
    }, 300);
    clearTimeout(timerId);
  };
  const activeDownPopup = (e) => {
    setPopupImgSrc(e.target.getAttribute("dataurl"));
    if (e.target.getAttribute("qrType") === "cp") {
      setQrContent(
        "Join Hands with Xpand Wealth and create a passive revenue for yourself Become Channel Partner Today!"
      );
    } else {
      setQrContent(
        "To conquer your financial goals, Make your financial plan and align your investments today!"
      );
    }
    setdownloadPopup(true);
  };

  return (
    <>
      <div className="container ">
        {/* ----- Page Heading && Edit Btn ----- */}
        <div className="row mb-2">
          <div className="col-md-12">
            <div className="profile_header d-flex align-items-center justify-content-between">
              <div className="">
                <h3>My Profile</h3>
              </div>
            </div>
          </div>
        </div>

        {/* ----- Main Section 1 ----- */}
        <div className="row justify-content-between">
          {/* ----- Personal Details Section ------ */}
          <div className="col-12 col-md-5  profile_wrapper">
            <div className="row">
              {/* ----- Header Text ----- */}
              <div className="col-12 border-bottom border-light heading-text-container">
                <div>
                  <h4 className="heading-text">Personal Details</h4>
                </div>
              </div>
              {/* ---- Details Content ----- */}
              <div className="col-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile_contact_details user_details">
                      <div className="contact_rows">
                        <span className="det_sep">Name</span> :
                        <span className="details_span">
                          {userInfo?.personalinfo?.name}
                        </span>
                      </div>
                      <div className="contact_rows">
                        <span className="det_sep">Mobile</span> :{" "}
                        <span className="details_span">
                          {" "}
                          {userInfo?.personalinfo?.phone}
                        </span>
                      </div>
                      <div className="contact_rows">
                        <span className="det_sep">Email</span> :
                        <span className="details_span">
                          {" "}
                          {userInfo?.personalinfo?.email}
                        </span>
                      </div>
                      <div className="contact_rows">
                        <span className="det_sep">Client Id</span> :
                        <span className="details_span">
                          {userInfo?.personalinfo?.bp_id}
                        </span>
                      </div>
                      <div className="contact_rows">
                        <span className="det_sep">Address</span> :
                        <span className="details_span text-capitalize">
                          {" "}
                          {userInfo?.personalinfo?.address}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ----- Your Relationship Manager Section ----- */}
          <div className="col-12 mt-4 col-md-6 mt-md-0 profile_wrapper">
            <div className="row">
              {/* ----- Header Text ----- */}
              <div className="col-12 border-bottom border-light heading-text-container">
                <div>
                  <h4 className="">Your Relationship Manager</h4>
                </div>
              </div>
              {/* ---- Relationship Content ----- */}
              <div className="col-12">
                <div className="profile_detail profile_contact_details">
                  <div className="contact_rows">
                    <span className="det_sep">Name</span> :{" "}
                    <span className="details_span">
                      {" "}
                      {userInfo?.personalinfo?.assignTo?.name}
                    </span>
                  </div>
                  <div className="contact_rows">
                    <span className="det_sep">Mobile </span> :{" "}
                    <span className="details_span">
                      {" "}
                      {userInfo?.personalinfo?.assignTo?.phone}
                    </span>
                  </div>
                  <div className="contact_rows">
                    <span className="det_sep">Email</span> :
                    <span className="details_span">
                      {" "}
                      {userInfo?.personalinfo?.assignTo?.email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----- Main Section 2 ----- */}
        <div className="row justify-content-between profile_wrapper my-5 ">
          {/* ----- Your Document Section ------ */}
          <div className="col-12 col-md-7  ">
            <div className="row">
              {/* ----- Header Text ----- */}
              <div className="col-12 border-bottom border-light heading-text-container">
                <div>
                  <h4 className="heading-text">Your Document </h4>
                </div>
              </div>
              {/* ---- Document Content ----- */}
              <div className="col-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile_contact_details user_details">
                      <form
                        action=""
                        method="post"
                        onSubmit={handleUpload}
                        encType="multipart/form-data"
                      >
                        <div className="contact_rows">
                          <span className="det_sep long-key">PAN Number</span>:
                          {userInfo?.document?.panNumber ? (
                            <span className="details_span">
                              {userInfo?.document?.panNumber}
                            </span>
                          ) : (
                            <input type="text" placeholder="Enter PAN" className="h-25" value={panNumber} onChange={(e) => setPanNumber(e.target.value)} />
                          )}
                        </div>
                        <div className="contact_rows">
                          <span className="det_sep long-key">ARN Number</span>:
                          {userInfo?.document?.document ? (
                            <span className="details_span">
                              {userInfo?.document?.arn}
                            </span>
                          ) : (
                            <span className="details_span">{"NA"}</span>
                          )}
                        </div>
                        <div className="contact_rows">
                          <span className="det_sep long-key">Signature</span> :
                          <span className="details_span">
                            {userInfo?.document?.signature}
                          </span>
                        </div>
                        <div className="contact_rows">
                          <span className="det_sep long-key">PAN Card</span>{" "}
                          {userInfo?.document?.panCard ? (
                            <>
                              :{" "}
                              <span className="details_span">
                                {userInfo?.document?.panCard}
                              </span>
                            </>
                          ) : (
                            <>
                              <div className="upload-file">
                                {!fileName ? (
                                  <label
                                    htmlFor="panCard"
                                    className="upload-small-container"
                                  >
                                    <div>
                                      <i className="fa-solid fa-file-circle-plus "></i>
                                      <p className="m-0 p-0 ">Select File</p>
                                    </div>
                                  </label>
                                ) : (
                                  fileName && (
                                    <p className="selected-file-name">
                                      {" "}
                                      {fileName}
                                    </p>
                                  )
                                )}

                                <input
                                  type="file"
                                  name="panCard"
                                  id="panCard"
                                  onChange={handlePanCard}
                                  disabled={isSubmitted}
                                  placeholder=" Select File"
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="contact_rows">
                          <span className="det_sep long-key">Aadhar Card</span>{" "}
                          {userInfo?.document?.aadharCard ? (
                            <>
                              :{" "}
                              <span className="details_span text-capitalize">
                                {userInfo?.document?.aadharCard}
                              </span>
                            </>
                          ) : (
                            <div className="upload-file">
                              {!aadharfileName ? (
                                <label
                                  htmlFor="aadharCard"
                                  className="upload-small-container"
                                >
                                  <div>
                                    <i className="fa-solid fa-file-circle-plus "></i>
                                    <p className="m-0 p-0 ">Select File</p>
                                  </div>
                                </label>
                              ) : (
                                aadharfileName && (
                                  <p className="selected-file-name">
                                    {" "}
                                    {aadharfileName}
                                  </p>
                                )
                              )}
                              <input
                                type="file"
                                name="aadharCard"
                                id="aadharCard"
                                onChange={handleAadharCard}
                                disabled={isSubmitted}
                              />
                            </div>
                          )}
                        </div>
                        <div className="contact_rows">
                          <span className="det_sep long-key">Cheque </span>{" "}
                          {userInfo?.document?.cheque ? (
                            <>
                              :{" "}
                              <span className="details_span text-capitalize">
                                {userInfo?.document?.cheque}
                              </span>
                            </>
                          ) : (
                            <div className="upload-file">
                              {!chequefileName ? (
                                <label
                                  htmlFor="cheque"
                                  className="upload-small-container"
                                >
                                  <div>
                                    <i className="fa-solid fa-file-circle-plus"></i>
                                    <p className="m-0 p-0">Select File</p>
                                  </div>
                                </label>
                              ) : (
                                chequefileName && (
                                  <p className="selected-file-name">
                                    {" "}
                                    {chequefileName}
                                  </p>
                                )
                              )}
                              <input
                                type="file"
                                name="cheque"
                                id="cheque"
                                onChange={handleCheque}
                                // disabled={isSubmitted}
                              />
                            </div>
                          )}
                        </div>
                        {/* ----- Upload Document Button----- */}
                        <div className="upload-button pt-3">
                          {error && <p className="text-danger">{error}</p>}
                          {isuploading ? (
                            <button
                              className="sub_documnet"
                              type="submit"
                              style={{
                                display:
                                  userInfo?.document?.cheque &&
                                  userInfo?.document?.aadharCard &&
                                  userInfo?.document?.panCard
                                    ? "none"
                                    : "block",
                              }}
                            >
                              Upload
                            </button>
                          ) : (
                            <button
                              className="sub_documnet"
                              type="btn"
                              style={{
                                display:
                                  userInfo?.document?.cheque &&
                                  userInfo?.document?.aadharCard &&
                                  userInfo?.document?.panCard
                                    ? "none"
                                    : "block",
                              }}
                            >
                              Uploaded{" "}
                              <BsCheck2
                                style={{
                                  fontSize: "22px",
                                  fontWeight: "bold",
                                }}
                              />
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ----- QR Code Section ----- */}
          <div className="col-12  col-md-5 ">
            <div className="row">
              {/* ----- Header Text ----- */}
              <div className="col-12 border-bottom border-light heading-text-container">
                <div>
                  <h4 className="">Your BP QR Code</h4>
                </div>
              </div>
              {/* ---- QR Content ----- */}
              <div className="col-12 primary-wrapper">
                <div
                  className="col col-md-6 secondary-wrapper"
                  style={{ cursor: "pointer" }}
                >
                  <div className="ternary-wrapper">
                    <div>
                      <img
                        src={`${qrpath}${userInfo?.personalinfo?.qrCode}`}
                        alt={`QR Code`}
                        onClick={handleImageClick}
                        className="qr-code"
                      />

                      <div>
                        <button
                          className="btn download-qr-btn"
                          dataUrl={
                            Server_Path_static +
                            "output-file-path/" +
                            userInfo?.personalinfo?.qrCode
                          }
                          qrType="investor"
                          onClick={activeDownPopup}
                        >
                          <span className="d-flex justify-content-evenly fw-bold ">
                            <span
                              className="pe-1"
                              style={{ fontSize: "12px" }}
                              dataUrl={
                                Server_Path_static +
                                "output-file-path/" +
                                userInfo?.personalinfo?.qrCode
                              }
                            >
                              Download
                            </span>
                            <i
                              style={{ fontSize: "12px" }}
                              class="fa-solid fa-download"
                              qrType="investor"
                              dataUrl={
                                Server_Path_static +
                                "output-file-path/" +
                                userInfo?.personalinfo?.qrCode
                              }
                            ></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-between profile_wrapper my-5 ">
        <div className="col-12">
          <div className="row">
            {/* ----- Header Text ----- */}
            <div className="col-12 border-bottom border-light heading-text-container">
              <div>
                <h4 className="">Your Support Team</h4>
              </div>
            </div>

            <div className="col-12">
              {/* <div className="profile_detail profile_contact_details">
                           <div className="contact_rows">
                              <span className="det_sep">Name </span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Madhu Diwakar
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Mobile</span> :
                              <span className="details_span">
                                 {" "}
                                 8130388996
                              </span>
                           </div>
                        </div>
                     {productNames?.includes("Mutual Fund") && (
                        <div className="profile_detail profile_contact_details">
                           <div className="contact_rows">
                              <span className="det_sep">Product</span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Mutual Fund
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Name </span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Krishan Kadu
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Mobile</span> :
                              <span className="details_span">
                                 {" "}
                                 9911005771
                              </span>
                           </div>
                        </div>
                     )}
                      {productNames?.includes("Real State") && (
                        <div className="profile_detail profile_contact_details">
                           <div className="contact_rows">
                              <span className="det_sep">Product</span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Real State
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Name </span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Parikshit Jha
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Product</span> :
                              <span className="details_span">
                                 {" "}
                                 9711801195
                              </span>
                           </div>
                        </div>
                      )}
                      {productNames?.includes("Equity Advisory") && (
                        <div className="profile_detail profile_contact_details">
                           <div className="contact_rows">
                              <span className="det_sep">Product</span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Equity Advisory
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Product </span> :{" "}
                              <span className="details_span">
                                 {" "}
                                 Shashi Kant
                              </span>
                           </div>
                           <div className="contact_rows">
                              <span className="det_sep">Product</span> :
                              <span className="details_span">
                                 {" "}
                                 9899215244
                              </span>
                           </div>
                        </div>
                      )} */}
              <Support />
            </div>
          </div>
        </div>
      </div>
      {/* =====QR download popup */}
      {/* =====html to image dowload */}
      {downloadPopup && (
        <div className="form_popups popup__container">
          {/* <div className='chagne_pass_form'> */}
          <div
            className="raised_query_wrapper p-0 position-relative overflow-visible"
            style={{ width: "350px" }}
          >
            <div
              className="popup_close_icon"
              onClick={() => setdownloadPopup(false)}
            >
              <i
                className="fa fa-xmark"
                onClick={() => setdownloadPopup(false)}
              ></i>
            </div>
            <div
              id="creativeDownload"
              ref={domEl}
              className="html2img bg-white px-3 pb-2 pt-3 mt-3"
              style={{ zIndex: 2, margin: "auto" }}
            >
              <div className="para text-center">
                <p className="mb-2" style={{ fontSize: "14px" }}>
                  {qrContent}
                </p>
              </div>
              <div className="htmlImg text-center bg-white">
                <img
                  src={popupImgSrc}
                  alt="img"
                  className="m-auto"
                  style={{ width: "150px", height: "150px" }}
                />
                <div className="mb-3">Scan the QR for more information</div>
              </div>
              <div className="userDetails pb-2 px-2 bg-white">
                <div className="username d-flex align-items-center justify-content-between">
                  <h6
                    className=" border p-1 py-2 rounded"
                    style={{
                      fontWeight: "500",
                      fontSize: "12px",
                      overflow: "hidden",
                    }}
                  >
                    {`${urlpath}${path}`}
                  </h6>
                </div>
              </div>
            </div>
            <div
              id="contentDownload"
              className="subBtn w-100 text-center p-2 rounded"
              style={{ cursor: "pointer" }}
              onClick={downloadImage}
            >
              Download
            </div>
          </div>
          {/* </div> */}
        </div>
      )}
      {/* =====END QR download popup */}

      {popupForm && (
        <div className="form_popups">
          {changePassForm && (
            <div className="chagne_pass_form">
              <div className="container">
                <div className="row">
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <div
                      className="formByScan_container pb-4 pt-5 position-relative"
                      id="formByScan_container"
                      style={{ overflow: "visible" }}
                    >
                      <div
                        className="popup_close_icon"
                        onClick={closeChngPassPopup}
                      >
                        <i
                          className="fa fa-xmark"
                          onClick={closeChngPassPopup}
                        ></i>
                      </div>
                      <form onSubmit={userChangePass} autoComplete="off">
                        <h2
                          className="form-heading text-center mb-4"
                          style={{ fontSize: "22px" }}
                        >
                          Change Password
                        </h2>
                        <input
                          type="password"
                          placeholder="Old Password *"
                          required
                          autoComplete="new-password"
                          name="old_password"
                          value={prevDetails.old_password}
                          onChange={changePassHandler}
                        />
                        <input
                          type="password"
                          placeholder="New Password *"
                          required
                          autoComplete="new-password"
                          name="new_password"
                          value={prevDetails.new_password}
                          onChange={changePassHandler}
                        />
                        <input
                          type="password"
                          placeholder="Confirm Password *"
                          required
                          autoComplete="new-password"
                          name="c_password"
                          value={prevDetails.c_password}
                          onChange={changePassHandler}
                        />
                        <div className="mt-2 text-center">
                          <button disabled={signupBtn}>Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                  m
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ClientProfile;
