import React, { useEffect } from 'react'
import '../../styles/loginFormStyle.css'

function OtpInput (props) {
  const { getOtpVal, otpRespNum } = props

  const inputs = document.querySelectorAll('.otp-field input')

  inputs.forEach((input, index) => {
    input.dataset.index = index
    input.addEventListener('keyup', handleOtp)
    input.addEventListener('paste', handleOnPasteOtp)
  })

  function handleOtp (e) {
    /**
     * <input type="text" 👉 maxlength="1" />
     * 👉 NOTE: On mobile devices `maxlength` property isn't supported,
     * So we to write our own logic to make it work. 🙂
     */
    const input = e.target
    let value = input.value
    let isValidInput = value.match(/[0-9a-z]/gi)
    input.value = ''
    input.value = isValidInput ? value[0] : ''

    let fieldIndex = input.dataset.index
    if (fieldIndex < inputs.length - 1 && isValidInput) {
      input.nextElementSibling.focus()
      submit()
    }

    if (e.key === 'Backspace' && fieldIndex > 0) {
      input.previousElementSibling.focus()
      submit()
    }

    if (fieldIndex == inputs.length - 1 && isValidInput) {
      submit()
    }
  }

    // add input value dynamicaly
  useEffect(() => {
    if (otpRespNum && otpRespNum !== null) {
      const value = otpRespNum.toString().split('')
      if (value.length === inputs.length) {
        inputs.forEach((input, index) => (input.value = value[index]))
        submit()
      }
    }
  }, [otpRespNum])

    //   for paste handler
  function handleOnPasteOtp (e) {
    const data = e.clipboardData.getData('text')
    const value = data.split('')
    if (value.length === inputs.length) {
      inputs.forEach((input, index) => (input.value = value[index]))
      submit()
    }
  }

  function submit () {
    props.otpInputValid()
    // 👇 Entered OTP
    let otp = ''
    inputs.forEach(input => {
      otp += input.value
    //   input.disabled = true
      input.classList.add('disabled')
    })
    getOtpVal(otp)
    // 👉 Call API below
  }
  return (
    <>
      <div className='opt__input__wrapp'>
        <div className={`otp-field ${props.validation ? 'validationErr': ''}`}>
          <input type='number' maxlength='1' />
          <input type='number' maxlength='1' />
          <input type='number' maxlength='1' />
          <input type='number' maxlength='1' />
          <input type='number' maxlength='1' />
          <input type='number' maxlength='1' />
        </div>
      </div>
    </>
  )
}

export default OtpInput
