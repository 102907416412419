import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PUBLIC_PATH } from "../../constant/matcher";
import FranchiseSidebar from "../../components/franchise/FranchiseSidebar";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import MyClientsCards from "../../components/cardsDesigns/MyClientsCards";
import ImageCards from "../../components/cardsDesigns/ImageCards";
import { useApiFormDownloadQuery } from "../../store/services/fetchApiService";
import DashboardBanner from "../../components/general/DashboardBanner";
import AlertCompleteProfile from "../../components/cardsDesigns/AlertCompleteProfile";
import Loader from "../../components/general/Loader";

function FormDownload() {
  // loggedIn user Data form react-redux
  const [isloading, setIsLoading] = useState(true)
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [dynamicRoute, setdynamicRoute] = useState("");
  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
  }, [loggedInUserData]);

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  const [formsDownData, setFormsDownData] = useState(null);
  const FormDownloadApi = useApiFormDownloadQuery();

  let serverPath =
    FormDownloadApi?.isSuccess &&
    !FormDownloadApi?.isLoading &&
    !FormDownloadApi?.isError &&
    FormDownloadApi?.data?.success &&
    FormDownloadApi?.data?.path;

  useEffect(() => {
    if (
      FormDownloadApi?.isSuccess &&
      !FormDownloadApi?.isLoading &&
      !FormDownloadApi?.isError &&
      FormDownloadApi?.data?.success
    ) {
      setFormsDownData(FormDownloadApi?.data?.downloadForms);
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, [FormDownloadApi]);

  return (
    <>
     {
      isloading ? (
        <Loader/>
      )
      : 
      (
   
        <>
      <FranchiseSidebar navContWrapp={navContWrapp} />
      {loggedInUserData?.role === "cp" && (
    <AlertCompleteProfile/>
      )}
      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
       
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                Dashboard
              </Link>{" "}
              <i className="fa-solid fa-angle-right"></i> Form Downloads
            </p>
          </div>
        </div>

        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-0 px-3 pb-5"
          style={{ background: "whitesmoke" }}
        >
          <div className="row mb-4">
            <div className="col-12 about-product-inner mt-0 mb-2 pb-2">
              <div className="mt-2 about-products d-flex align-items-center justify-content-between">
                <div className="sec-title sec-title_list">
                  <h2 className="text-capitalize">Form Download</h2>
                </div>
              </div>
            </div>
            {formsDownData &&
              formsDownData !== null &&
              formsDownData?.map((item, index) => {
                return (
                  <>
                    <div className="col-md-4 col-lg-3">
                      <ImageCards
                        bgImg={serverPath + item?.download}
                        downloadLink={serverPath + item?.download}
                        imageName={item?.download}
                        cardCaption={item?.caption}
                      />
                    </div>
                  </>
                );
              })}
            {FormDownloadApi?.isLoading && (
              <p className="mb-0 ms-2 mt-2">Loading images...</p>
            )}
            {!FormDownloadApi?.isLoading && formsDownData?.length === 0 && (
              <p>Forms Not Available</p>
            )}
          </div>
        </div>

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
      </>
           )
          }
    </>
  );
}

export default FormDownload;
