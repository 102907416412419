import React, { useEffect, useState } from 'react'
import { PUBLIC_PATH } from '../../constant/matcher'
import {
  useUserTypeSelectQuery,
  useSubCategorySelectQuery
} from '../../store/services/loginService'
import { useHomepageCreateLeadApiMutation } from '../../store/services/registerApiService'

function InterestedFrom () {
  const [errMsg, setErrMsg] = useState('')
  // subcategory list profession
  const subcategoryResp = useSubCategorySelectQuery()
  const professionSelectData =
    subcategoryResp?.isSuccess &&
    !subcategoryResp?.isLoading &&
    subcategoryResp.status === 'fulfilled' &&
    !subcategoryResp?.isError &&
    subcategoryResp?.data?.subCategoryList

  // API FOR USERTYPE
  const userTypeResp = useUserTypeSelectQuery()
  const userTypeData =
    userTypeResp?.isSuccess &&
    !userTypeResp?.isLoading &&
    !userTypeResp?.isError &&
    userTypeResp?.data?.categoryList

  const [captchaValidations, setCaptchaValidations] = useState(false)
  const [mobileValidation, setMobileValidation] = useState(false)
  const [succesAlert, setSuccesAlert] = useState(false)

  const [formDetails, setFormDetails] = useState({
    name: '',
    email: '',
    phone: '',
    userType: '',  //category
    subcategory: '', //profession
    captcha: ''
  })

  const changeHandler = e => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value })
  }
  // create lead api
  const [postLeadData, leadResp={}] = useHomepageCreateLeadApiMutation()
  useEffect(() => {
    if(
      leadResp?.isSuccess &&
      !leadResp?.isLoading &&
      !leadResp?.isError &&
      leadResp?.data?.success 
    ) {
      setSuccesAlert(true)
      setFormDetails({
        name: '',
        email: '',
        phone: '',
        userType: '',  //category
        subcategory: '', //profession
        captcha: ''
      })
    }
    if(
      leadResp?.isSuccess &&
      !leadResp?.isLoading &&
      leadResp?.isError &&
      !leadResp?.data?.success 
    ) {
      setErrMsg('Please try again')
      setTimeout(() => {
        setErrMsg('')
      }, 5000);
    }
  }, []);

  const onFormSubmit = e => {
    e.preventDefault()
    if (formDetails.mobile.length === 10) {
      if (
        formDetails.captcha === document.getElementById('captcha_int').value
      ) {
        setCaptchaValidations(false)
        postLeadData(formDetails)

        setMobileValidation(false)
        setFormDetails({
          name: '',
          email: '',
          phone: '',
          userType: '',  //category
          subcategory: '', //profession
          captcha: ''
        })
      } else {
        setCaptchaValidations(true)
        setMobileValidation(false)
      }
    } else {
      setCaptchaValidations(false)
      setMobileValidation(true)
    }
  }

  let captcha = []
  let q = 0
  let theCaptcha

  function createCaptcha () {
    const activeCaptcha = document.getElementById('captcha_int')
    for (q = 0; q < 6; q++) {
      if (q % 2 === 0) {
        captcha[q] = String.fromCharCode(Math.floor(Math.random() * 26 + 65))
      } else {
        captcha[q] = Math.floor(Math.random() * 10 + 0)
      }
    }
    theCaptcha = captcha.join('')
    activeCaptcha.value = `${theCaptcha}`
  }

  useEffect(() => {
    createCaptcha()
    let mobInpt_2 = document.getElementById('mobile_b')
    mobInpt_2.oninput = () => {
      if (mobInpt_2.value.length > mobInpt_2.maxLength) {
        mobInpt_2.value = mobInpt_2.value.slice(0, mobInpt_2.maxLength)
      }
    }
  }, [])
  
  return (
    <>
      <div className='inquiry snipcss-2o8Zx hompageInterested'>
        <div className='inquiry-title'>I AM INTERESTED</div>
        {/* captcha validation */}
        {captchaValidations && (
          <p className='mb-2 text-danger text-center'>
            Please Enter Valid Captcha !
          </p>
        )}

        {/* mobile validation */}
        {mobileValidation && (
          <p className='mb-2 text-danger text-center'>
            Please put 10 digit mobile number !
          </p>
        )}

        {/* mobile validation */}
        {succesAlert && (
          <p className='mb-2 text-success text-center'>
            Succesfully Registered
          </p>
        )}
        <form id='myform' onSubmit={onFormSubmit} autoComplete='off'>
          <div className='set-div'>
            <span className='i-icon'>
              <img
                className='set-img'
                src={PUBLIC_PATH + 'assets/img/i-user.png'}
                alt=''
              />
            </span>
            <input
              type='text'
              maxLength='50'
              name='name'
              placeholder='Name *'
              pattern='^[a-zA-Z\s]+$'
              required
              className='i-input form-control'
              onChange={changeHandler}
              value={formDetails.name}
            />
          </div>
          <div className='set-div'>
            <span className='i-icon'>
              <img
                className='set-img'
                src={PUBLIC_PATH + 'assets/img/i-mobile.png'}
                alt=''
              />
            </span>
            <input
              type='number'
              name='phone'
              id='mobile_b'
              placeholder='Mobile Number *'
              className='i-input form-control mobile'
              title='Enter valid Mobile Number'
              maxLength='10'
              required
              autoComplete='new-mobile'
              onChange={changeHandler}
              value={formDetails.mobile}
            />
            <span className='moberror style-hEvmp' id='style-hEvmp'></span>
          </div>
          <div className='set-div'>
            <span className='i-icon'>
              <img
                className='set-img'
                src={PUBLIC_PATH + 'assets/img/location.png'}
                alt=''
              />
            </span>
            <input
              type='text'
              name='email'
              placeholder='Email *'
              className='i-input form-control pincode'
              required
              onChange={changeHandler}
              value={formDetails.email}
            />
            <span className='pinerror style-ewdk4' id='style-ewdk4'></span>
          </div>
          <div className='set-div'>
            <span className='i-icon'>
              <img
                className='set-img'
                src={PUBLIC_PATH + 'assets/img/pro.png'}
                alt=''
              />
            </span>
            <select
              className='i-input1'
              required
              name='userType'
              onChange={changeHandler}
              value={formDetails.role}
            >
              <option value='1'>Select Role *</option>
              {userTypeData &&
                userTypeData?.map((item, index) => {
                  return (
                    <option key={index} value={item?._id}>
                      {item?.name}
                    </option>
                  )
                })}
            </select>
          </div>

          <div className='set-div'>
            <span className='i-icon'>
              <img
                className='set-img'
                src={PUBLIC_PATH + 'assets/img/i-user.png'}
                alt=''
              />
            </span>
            <select
              className='i-input1'
              required
              name='subCategory'
              onChange={changeHandler}
              value={formDetails.profession}
            >
              <option value='1'>Select Profession *</option>
              {professionSelectData &&
                professionSelectData?.map((item, index) => {
                  return (
                    <option key={index} value={item?._id}>
                      {item?.name}
                    </option>
                  )
                })}
            </select>
          </div>

          <div className='set-div d-flex justify-content-between align-items-center'>
            <div className='d-flex justify-content-between align-items-center' style={{width:'110px'}}>
              <input
                id='captcha_int'
                className='captcha_input_inte'
                style={{ fontSize: '22px' }}
                readOnly
              />
              <div onClick={createCaptcha}>
                <i
                  style={{ cursor: 'pointer' }}
                  className='fa-solid fa-rotate-right text-secondary '
                ></i>
              </div>
            </div>
            <input
              type='text'
              placeholder='Enter Captcha *'
              className='form-control style-v4Aw'
              id='cpatchaTextBox'
              name='captcha'
              style={{ width: '48% !important' }}
              onChange={changeHandler}
              value={formDetails.captcha}
              required
            />
          </div>
          <button className='w-100 i-btn'>{leadResp?.isLoading?'Submit...':'Submit'}</button>
          <p className='mob-dis-text'>
            Disclaimer: By filling this inquiry form, you are permitting us to
            communicate with you via Call, Email, SMS or Whatsapp.
          </p>
        </form>
      </div>
    </>
  )
}

export default InterestedFrom
