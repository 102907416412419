import React, { useEffect, useState, useRef } from "react";
import "../../styles/mainCards.css";
import { Api_Base_Url } from "../../constant/matcher";
import { formatDate } from "../../utils/helper";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function DiscussionForum(props) {
    const [userQueries, setUserQueries] = useState([]);
  const [postQuery, setPostQuery] = useState("");
  const msgList = useRef();
  const [messages, setMessages] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  function sendMessage(message, reply) {
    var messageList = msgList.current;

    var scrollToBottom =
      messageList?.scrollHeight -
        messageList?.scrollTop -
        messageList?.clientHeight <
      80;

    var lastMessage = messageList?.children[messageList?.children.length - 1];

    var newMessage = document.createElement("span");
    newMessage.innerHTML = message;

    var className;

    if (reply) {
      className = "me";
      scrollToBottom = true;
    } else {
      className = "not-me";
    }

    if (lastMessage && lastMessage.classList.contains(className)) {
      lastMessage?.appendChild(document.createElement("br"));
      lastMessage?.appendChild(newMessage);
    } else {
      var messageBlock = document.createElement("div");
      messageBlock?.classList.add(className);
      messageBlock?.appendChild(newMessage);
      messageList?.appendChild(messageBlock);
    }

    if (messageList && scrollToBottom) messageList.scrollTop = messageList?.scrollHeight;

    
  }

// ...
const handleKeyPress = (event) => {
  if (event.key === "Enter" && postQuery.trim() !== "") {
    sendQuery(event); // Pass the event object to sendQuery
  }
};

const sendQuery = async (event) => {
  
  const message = postQuery.trim(); 

  if (message !== "") {
    setPostQuery(""); 


    sendMessage(message, false);
    setUserQueries([...userQueries, message]);

    try {
      // Retrieve the loginUserData from session storage
      const loginUserData = JSON.parse(localStorage.getItem('loginUserData'));

      // Extract the userId and productId from the loginUserData object
      const userId = loginUserData?._id;
      const productId = loginUserData?.product[0];
      const role = loginUserData?.role
      const requestBody = {
        queryId: props.queryId,
        description: message,
        userId: userId,
        productId: productId,
        sendBy: role === 'rm' ? 'rm' : 'user',
        status: document.querySelector("#checkbox").checked ? 2 : "",
      };
      
      const response = await fetch(`${Api_Base_Url}/query/add-query-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();
        // Update messages state with the newly added message
        setMessages([...messages, responseData.message]);
       
      } else {
        // console.log('Error sending message');
      }
    } catch (error) {
      // console.error('Error sending message:', error);
    }
  }

};


    useEffect(() => {
      const fetchMessages = async () => {
        try {
          const response = await fetch(`${Api_Base_Url}/query/query-messages?queryId=${props.queryId}`);
          if (response.ok) {
            const responseData = await response.json();
            setMessages(responseData);
            // console.log(responseData)
          } else {
            // console.log('Error fetching messages');
          }
        } catch (error) {
          // console.error('Error fetching messages:', error);
        }
      };
  
      fetchMessages();
    }, [props.queryId]);

   
    

  return (
    <>
    <ToastContainer/>
      <div className="chatPopup">
        <div className="chatBgoverlay"></div>
        <div className="chat-box">
          <header>
            <h1><span>Ticket Id: {props.ticket}</span></h1>
            <span style={{ flex: 1 }}></span>
            <span className="close-button" onClick={props.closeChatPopup}>
              X
            </span>
          </header>
          <section id="message-list" ref={msgList} className='pt-1'> 
          <span>Subject: {props.subject}</span>
          <span>Remark: {props.remark}</span>
              <hr className='m-0'/>
          {messages?.queryDetailsList?.map((message) => (
            <div key={message.id} className={`mx-2 ${message?.userId === props.lmsuserid  ? 'me' : 'not-me'}`}>
              <span>{message.message}
              <p>{formatDate(message?.createdAt)}</p>
              </span>
            </div>
          ))}
          </section>
          <footer>
          <div style={{backgroundColor: "#fff"}}>
          <div className="d-flex align-items-center" >
          <input type="checkbox" name="checkbox" id="checkbox" className='ms-2'  required />
            <input
            //  onKeyPress={handleKeyPress}
              onChange={(e) => setPostQuery(e.target.value)}
              value={postQuery}
              id="message-input"
              type="text"
              placeholder="Type a message..."
              
            />
             <button className="send-query" onClick={sendQuery} isabled={!postQuery.trim()}><i class="fa-solid fa-arrow-up"></i></button>
             </div>
             
              
              </div>
          </footer>
        </div>
      </div>
    </>
  );
}

export default DiscussionForum;
