import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import FranchiseSidebar from "../FranchiseSidebar";
import { useSelector } from "react-redux";
import DashboardBanner from "../../general/DashboardBanner";
import { Api_Base_Url, PUBLIC_PATH } from "../../../constant/matcher";
import Footer from "../../Footer";
import Sidebar from "../../Sidebar";
import MyClientsCards from "../../cardsDesigns/MyClientsCards";
import axios from "axios";
import ChatBox from "../../../pages/ChatBox";
import { dateFormat } from "../../../utils/helper";
import Loader from "../../general/Loader";

function SupportTickets(props) {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [selectedQueryId, setSelectedQueryId] = useState(null);
  const [dynamicRoute, setdynamicRoute] = useState("");
  const [queryPopup, setQueryPopup] = useState(false);
  const [active, setActive] = useState("");
  const [inactive, setInactive] = useState("");
  const [closed, setClosed] = useState("");
  const [remark, setRemark] = useState("")
  const [statusLabel, setStatusLabel] = useState("")
  const [subject, setSubject] = useState("")
  const [ticket, setTicket] = useState("")
  
  const [isloading, setIsLoading] = useState(true)
  const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
  const lmsuserid = loginUserData._id
  const name = loginUserData.name
  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
  }, [loggedInUserData]);
  const [supportList, setSupportList] = useState([]);
  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  const chatPopuHandler = (_id) => {
    setSelectedQueryId(_id);
    
    setQueryPopup(true);
  };

   // close discussion chat popup
   const closeChatPopup = () => {
    setQueryPopup(false);
  };

 
  const getStatusLabel = (status) => {
    if (status === 0) {
      return "InActive";
    } else if (status === 1) {
      return "Active";
    } else if (status === 2) {
      return "Closed";
    } else {
      return ""; 
    }
  };
  
  const getSupportList = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const rmId = loginUserData._id;

    axios.get(`${Api_Base_Url}/rm/supportrequest?rmid=${rmId}`).then((res) => {
      const data = res?.data?.data
      setSupportList(data);
      const active = res?.data?.data.filter(result => result.status === 1).length;
      const inActive = res?.data?.data.filter(result => result.status === 0).length;
      const closed = res?.data?.data.filter(result => result.status === 2).length;
      setActive(active)
      setInactive(inActive)
       setClosed(closed)
      setSubject(res?.data?.data[0].subject)
      setTicket(res?.data?.data[0].ticket)
      setRemark(res?.data?.data[0].description)
     
     
    });
  };

  useEffect(() => {
    getSupportList();
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, []);


  return (
    <>
    {
      isloading ? (
       <Loader/>
      )
      : 
      (
   
        <>
      <FranchiseSidebar navContWrapp={navContWrapp} />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                Dashboard
              </Link>{" "}
              <i className="fa-solid fa-angle-right"></i> Support Tickets
            </p>
          </div>
        </div>

        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          style={{ background: "whitesmoke" }}
        >
          <div className="row mb-4">
            <div className="col-md-4 mb-2">
              <MyClientsCards
                cardTitle={"Pending"}
                cardValues={active}
                borderColor={4}
                bgGradient={4}
                textColor={4}
                iconsNumb={2}
              />
            </div>
            <div className="col-md-4 mb-2">
              <MyClientsCards
                cardTitle={"InProgress"}
                cardValues={inactive}
                // updateTime={"Since last week"}
                borderColor={3}
                bgGradient={3}
                textColor={3}
                iconsNumb={1}
              />
            </div>
            <div className="col-md-4 mb-2">
              <MyClientsCards
                cardTitle={"Closed"}
                cardValues={closed}
                // updateTime={"Since last week"}
                borderColor={3}
                bgGradient={3}
                textColor={3}
                iconsNumb={3}
              />
            </div>
          </div>
          <div className="row table__row__wrapp">
            <div className="client_table_container py-2">
              <h2> Supporting Tickets</h2>
              <div className="w-100 overflow-auto">
                <table className="table table-striped client__table">
                  <thead>
                    <tr>
                      <th scope="col">Sr No.</th>
                      <th scope="col">Date</th>
                      <th scope="col">Ticket no.</th>
                      <th scope="col">CP / INV Code</th>
                      <th scope="col">Created By</th>
                      <th scope="col">Category</th>
                      <th scope="col">Status</th>
                      <th scope="col">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {supportList?.map((result, index) => {
                      return (
                        <tr key={result._id}>
                          <td>{index + 1}</td>
                          <td>{dateFormat(result?.createdAt)}</td>
                          <td>{result?.ticket}</td>
                          <td>{result?.creatorcode ? result?.creatorcode : ""}</td>
                          <td>{result?.createdby}</td>
                          <td>{result?.category}</td>
                          <td>{getStatusLabel(result?.status)}</td>
                          <td className="ps-4">
                            <Link
                              to=""
                              className="ps-3"
                              onClick={() => chatPopuHandler(result?._id, result?.userId)}
                            >
                              <i className="fa-solid fa-eye"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* =========discussion chat forum========= */}
        {queryPopup && (
         <ChatBox
         selectedQueryId={selectedQueryId}
         closeChatPopup={closeChatPopup}
         queryPopup={queryPopup}
         lmsuserid={lmsuserid}
         name={name}
         subject={subject}
         ticket={ticket}
         remark={remark}
         />
        )}
        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
      </>
           )
          }
    </>
  );
}

export default SupportTickets;
