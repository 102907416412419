import React, { useState, useEffect } from 'react'
import Product from './Product'
import SubCategory from './SubCategory'
import City from './City'
import Email from './Email'
import Name from './Name'
import Button from './Button'
import './form.css'
import { validateEmail } from '../../../constant/utils.js'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { loggedInUserData } from '../../../store/reducer/authReducer.js'
import { useCompleteProfileMutation } from '../../../store/services/dashboardService.js'
import { useSearchParams } from 'react-router-dom'
import Readonlycategory from './Readonlycategory'
import Readonlyproduct from './Readonlyproduct'
import { useEventQrDetailsFetchQuery } from '../../../store/services/fetchApiService'

function Form1 (props) {
  // const { userId, userType } = props
  const userData = useSelector(state => state?.userReducer?.userData)
  const [page, setPage] = useState(0)
  const [validationErr, setValidationErr] = useState('')
  

  const dispatch = useDispatch()
  // useEffect(() => {
  //   let progressDots = document.getElementsByClassName('progress-step')
  //   if (progressDots) {
  //     progressDots = Array.from(progressDots)
  //     progressDots.forEach(dots => {
  //       dots?.classList?.contains('active') && dots?.classList?.remove('active')
  //     })
  //     progressDots[page].classList?.add('active')
  //   }
  // }, [page])

  const [searchParams, setSearchParams] = useSearchParams();
  const scanId = searchParams.get("qid");

  const [qrEventDeatils, setQrEventDeatils] = useState();
  const getEventDetailsResp = useEventQrDetailsFetchQuery(scanId);
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subCategory: '',
    city: '',
    product: []
  })

  const [compProfiData, setCompProfiData] = useState({
    id: "",
    product: [],
    subCategory: "",
    userType: ""
  });

  // post complete profile api
  const [postDataApi, completeProResp = {}] = useCompleteProfileMutation()
  useEffect(() => {
    if (
      completeProResp?.isSuccess &&
      !completeProResp?.isLoading &&
      completeProResp.status === 'fulfilled' &&
      !completeProResp?.isError
    ) {
      localStorage.setItem(
        'loginUserData',
        JSON.stringify(completeProResp?.data?.data)
      )
      dispatch(loggedInUserData(completeProResp?.data?.data))
    } else if (completeProResp?.isError) {
      setValidationErr('An error occured')
      setTimeout(() => {
        setValidationErr('')
      }, 5000)
    }

  }, [completeProResp])

  useEffect(() => {
    if (getEventDetailsResp?.isSuccess && getEventDetailsResp?.data?.success) {
      setQrEventDeatils(getEventDetailsResp?.data?.eventData);
      setCompProfiData({
        ...compProfiData,
        product: getEventDetailsResp?.data?.eventData?.product,
        subCategory: getEventDetailsResp?.data?.eventData?.subCategory,
        userType: getEventDetailsResp?.data?.eventData?.category,
       
      }); 
      // console.log(getEventDetailsResp?.data?.eventData?.category);
    }
  }, [getEventDetailsResp]);

   // const finalData = formData
  
  const submitData = async e => {
    e.preventDefault()

    const finalData = { ...formData };
    if (!finalData.email.trim()) {
      finalData.email = `${finalData.name}@gmail.com`; 
    }

    finalData.id = userData?._id
    finalData.userType = userData?.userType
    const qid = localStorage.getItem("qid");
    let type = localStorage.getItem("qrType");
    let qrType = localStorage.getItem("qrType")
    if (!type) {
      type = "website";
    }
    finalData.qid = qid;
  finalData.type = type;
finalData.qrType = qrType;
  
    // for validations
    setValidationErr('')
    if (page === 0 && formData.name === '') {
      setValidationErr('Please Enter Your Name')
      return
    } else if (page === 1 && type !== "event" && formData.subCategory === "") {
      setValidationErr('Please Select Your Occupation')
      return
    } else if (page === 2 && type !== "event" && formData.product.length === 0) {
      setValidationErr('Please Select Product Type')
      return
    }

    // console.log(finalData);
    if (page === FormTitle.length - 1) {
      if (type === "event") {
        finalData.subCategory = compProfiData.subCategory
        finalData.product = compProfiData.product;
        finalData.userType = compProfiData.userType
      }
      postDataApi(finalData)
      // console.log(finalData);
      localStorage.removeItem('qrDetails')
      localStorage.removeItem('qrdetails')
      localStorage.removeItem("qid");
    localStorage.removeItem("qrType");
    } else {
      setPage(currPage => currPage + 1)
    }
  }
  
  const FormTitle = ['email', 'subcategory', 'city', 'product', 'name']
  const [labelText, setLabelText] = useState('Complete your registration')
  let type = localStorage.getItem("qrType");
  // dispalay pages on click
  useEffect(() => {
    if (page === 0) {
      setLabelText('Please tell us your name')
    } else if (page === 1) {
      if (type === 'event') {
        setLabelText('Please choose your subcategory');
      } else {
        setLabelText('Please choose your occupation');
      }
    } else if (page === 2) {
      setLabelText('Please select product/s, you are interested in')
    } else if (page === 3) {
      setLabelText('Please enter your city')
    } else {
      setLabelText('please enter your email')
    }
  }, [page, type])
  
 
 

  const PageDisplay = () => {
    if (page === 0) {
      return <Name formData={formData} setFormData={setFormData} />;
    } else if (page === 1) {
      return type === 'event' ? (
        // Display the read-only product and subcategory components
          <Readonlycategory subCategory={compProfiData?.subCategory} 
          onSubCategoryChange={(selectedSubCategory) =>
            setCompProfiData({ ...compProfiData, subCategory: selectedSubCategory })
          }
          />
      
      ) : (
        <SubCategory formData={formData} setFormData={setFormData} />
      );
    } else if (page === 2) {
      return type === 'event' ? (
        <Readonlyproduct product={compProfiData?.product}  setFormData={setFormData}  /> 
        
      ) : 
      (<Product formData={formData} setFormData={setFormData} />)
    } else if (page === 3) {
      return <City formData={formData} setFormData={setFormData} />;
    } else {
      return <Email formData={formData} setFormData={setFormData} />;
    }
  };

  return (
    <>
      <div className=''>
        <div className='topSec'>
          <p>
            Just answer {FormTitle.length} simple questions to get more
            personalised content
          </p>
        </div>
        {/* <div className=''>
          <img
            src='https://cdn-icons-png.flaticon.com/128/753/753210.png'
            alt='cookies-img'
            className='popupImg completeProfilePopImg'
          />
        </div> */}
        <div className='py-3'>
          <h2 className='text-capitalize'>{labelText}</h2>
          {/* validation checks */}
          {validationErr && <p className='mb-0 text-danger'>{validationErr}</p>}

          <form className='moreDetailsForm px-3'>
            {/* diplay all inputs */}
            <div className='text-start'>{PageDisplay()}</div>

            {/* display buttons */}
            <div className='mt-4 d-flex registration__popup div justify-content-between'>
              {window.screen.width > 550 ? (
                <>
                  <Button
                    text='Previous'
                    color='#fff'
                    isDisabled={page === 0 ? true : false}
                    side='left'
                    onClick={e => {
                      e.preventDefault()
                      setPage(currPage => currPage - 1)
                    }}
                  />
                  <div className='skipbtnwrap'>
                   
                    {/* {page === 4 && (
                      <span
                        onClick={submitData}
                        className='text-primary skipTxt'
                        style={{
                          cursor: 'pointer',
                          dispalay: 'inline-block'
                        }}
                      >
                        Skip{' '}
                      </span>
                    )} */}

                    <Button
                      text={
                        page !== FormTitle.length - 1
                          ? 'Next'
                          : completeProResp?.isLoading
                          ? 'Submit...'
                          : 'Submit'
                      }
                      color='#fff'
                      side={page !== FormTitle.length - 1 ? 'right' : ''}
                      onClick={submitData}
                    />
                  </div>
                </>
              ) : (
                <>
                  <Button
                    text={<i className='fa-solid fa-arrow-left'></i>}
                    color='#fff'
                    isDisabled={page === 0 ? true : false}
                    side='left'
                    onClick={e => {
                      e.preventDefault()
                      setPage(currPage => currPage - 1)
                    }}
                  />
                  <div className='skipbtnwrap'>
                    {page === 3 && (
                      <span
                        onClick={submitData}
                        className='text-primary skipTxt'
                        style={{
                          cursor: 'pointer',
                          dispalay: 'inline-block'
                        }}
                      >
                        Skip{' '}
                      </span>
                    )}
                    {page === 4 && (
                      <span
                        onClick={submitData}
                        className='text-primary skipTxt'
                        style={{
                          cursor: 'pointer',
                          dispalay: 'inline-block'
                        }}
                      >
                        Skip{' '}
                      </span>
                    )}
                    <Button
                      text={
                        page !== FormTitle.length - 1 ? (
                          <i className='fa-solid fa-arrow-right'></i>
                        ) : completeProResp?.isLoading ? (
                          'Submit...'
                        ) : (
                          'Submit'
                        )
                      }
                      color='#fff'
                      side={page !== FormTitle.length - 1 ? 'right' : ''}
                      onClick={submitData}
                    />
                  </div>
                </>
              )}
            </div>

            {/* progressive dots */}
            <div className='progressDotsCont tex-center'>
              <div className='totalStepsCount'>
                step {page + 1} of {FormTitle.length}
              </div>
              {/* <div className='progressbar d-flex'>
                <div className='progress-step'></div>
                <div className='progress-step'></div>
                <div className='progress-step'></div>
                <div className='progress-step'></div>
                <div className='progress-step'></div>
              </div> */}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Form1
