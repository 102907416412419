import React, { useEffect } from 'react'
import { useSubCategorySelectQuery } from '../../../store/services/loginService.js'
import { useSelector } from 'react-redux'

function SubCategory ({ formData, setFormData }) {
  // // ==========QR EVENT DETAILS===============
  // const qrEventData = useSelector(state => state?.userReducer?.qrDetails)
  // const userSubCategory = qrEventData?.subCategory

  // subcategory list profession
  const subcategoryResp = useSubCategorySelectQuery()
  const professionSelectData =
    subcategoryResp?.isSuccess &&
    !subcategoryResp?.isLoading &&
    subcategoryResp.status === 'fulfilled' &&
    !subcategoryResp?.isError &&
    subcategoryResp?.data?.subCategoryList

  // useEffect(() => {
  //   if (qrEventData !== null && userSubCategory !== '')
  //     setFormData({ ...formData, subCategory: userSubCategory })
  // }, [userSubCategory])
  return (
    <>
      <div className='row my-3'>
        {/* <label>Select Your Profession</label> */}
        <div className='form-group'>
          <select
            className='form-select subCategorySelect'
            name='subCategory'
            value={formData.subCategory}
            onChange={e =>
              setFormData({
                ...formData,
                subCategory: e.target.value
              })
            }
          >
            <option value=''>Select Occupation*</option>
            {professionSelectData &&
              professionSelectData?.map((item, index) => {
                return (
                  <option key={index} value={item?._id}>
                    {item?.name}
                  </option>
                )
              })}
          </select>
        </div>
      </div>
    </>
  )
}

export default SubCategory
