import React, { useRef, useState }  from 'react'
import { useEffect } from 'react';
import { formatDate } from '../../../utils/helper';
import { Api_Base_Url } from '../../../constant/matcher';


const DisscussionBox = (props) => {
    const [userQueries, setUserQueries] = useState([]);
    const [postQuery, setPostQuery] = useState("");
    const msgList = useRef();
    const [messages, setMessages] = useState([]);
    const [rMMsg, setRMMsg] = useState([]);

    const sendQuery = async () => {
        // console.log("clicked")
        const message = postQuery.trim(); 
        // if (message !== "") {
          setPostQuery(""); 
          // sendMessage(message, false);
          setUserQueries([...userQueries, message]);
      
          try {
            const loggedInUserData = JSON.parse(localStorage.getItem("loginUserData"))
            const conversationId = props.selectedQueryId;
            // const receiverId = loggedInUserData.assignTo;
            const role = loggedInUserData.role
            const requestBody = {
              conversationId: conversationId,
              // receiverId: receiverId,
              senderId: props.lmsuserid,
              message: message,
              sendBy: role==="rm" ? "rm" : "user",
              name: props.name
            }
            // console.log(requestBody);
            const response = await fetch(`${Api_Base_Url}/rm/startconversation`, {
              method:"POST",
              headers: {
               'Content-Type': 'application/json'
              },
              body: JSON.stringify(requestBody)
            })
            if (response.ok) {
              const responseData = await response.json();
              // Update messages state with the newly added message
              setMessages([...messages, responseData.message]);
             fetchClientMessages()
             
            } else {
              // console.log('Error sending message');
            }
          } catch (error) {
            
          }
        // }
      
      };
    
      const fetchClientMessages = async (_id) => {
        const conversationId = props.selectedQueryId;
      
        try {
          const response = await fetch(`${Api_Base_Url}/rm/getconversation?conversationId=${conversationId}`);
          if (response.ok) {
            const responseData = await response.json();
            setRMMsg(responseData);
          } else {
            // console.log('Error fetching messages');
          }
        } catch (error) {
          // console.error('Error fetching messages:', error);
        }
      };
      useEffect(() => {
        fetchClientMessages()
          let intervalId;
          if (props.queryPopup) {
            // Start the interval when the chat box is open
            intervalId = setInterval(() => {
              // Call your GET API here
              fetchClientMessages();
            },  20000); 
          }
      
          // Clear the interval when the chat box is closed
          return () => {
            clearInterval(intervalId);
          };
      
      }, [props.queryPopup]);
      

  return (
    <>
     <div className="chatPopup">
         <div className="chatBgoverlay"></div>
         <div className="chat-box">
           <header>
             <h1><span>Ticket Id: {props.ticket}</span></h1>
             <span style={{ flex: 1 }}></span>
             <span className="close-button" onClick={props.closeChatPopup}>
               X
             </span>
           </header>
           <section id="message-list" ref={msgList} className='pt-1'> 
           <span>Subject: {props.subject}</span>
           <span>Remark: {props.remark}</span>
              <hr className='m-0'/>
            {
              
            rMMsg?.result?.map((res) => {

              return (
                <div key={res.id} className={`mx-2 ${res?.senderId === props.lmsuserid  ? 'me' : 'not-me'}`}>
                <span>{res?.message}
               <p>{res?.name}</p>
               <p>{formatDate(res?.createdAt)}</p>
                </span>
              </div>
              )
            }) 
           }
           </section>
           <footer>
          <div className="d-flex" style={{backgroundColor: "#fff"}}>
             <input
             //  onKeyPress={handleKeyPress}
               onChange={(e) => setPostQuery(e.target.value)}
               value={postQuery}
               id="message-input"
               type="text"
               placeholder="Type a message..."
               
             />
              <button className="send-query" onClick={sendQuery}><i class="fa-solid fa-arrow-up"></i></button>
              </div>
           </footer>
         </div>
       </div>
   </>
  )
}

export default DisscussionBox