import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

function PublicRoutePre ({ children }) {
  const userToken = useSelector(state => state.userReducer.userToken)
  const userData = useSelector(state => state.userReducer.userData)
  // set dynamic url for client login of cp and fm
  let dynamicRoute = ''
  if(userData?.role && userData?.role !== 'rm') {
    dynamicRoute =  ('/'+userData?.role)
  } 
  
  return userToken && userToken !== 'undefined' ? <Navigate to={`/dashboard${dynamicRoute}`}/> : children 
}

export default PublicRoutePre
