import React from 'react'
import data from "../../../constant/InvesterQuestion.json";
import axios from "axios";
import { Api_Base_Url } from "../../../constant/matcher";
import { useEffect } from "react";
import { useState } from 'react';

const InvEngQuestion = (props) => {
    let engagementScore = []

    const scoreMapping = {
        "Very good": 2,
        Excited: 2,
        Yes: 2,
        OK: 1,
        No: 0,
        "Not Excited": 0,
      };
    
      const handleEngagementChange = (questionIndex, selectedOption) => {
        const updatedScores = [...engagementScore];
    
        const marks = scoreMapping[selectedOption] || 0; 
    
        updatedScores[questionIndex] = {
          question: data?.score[questionIndex]?.question,
          answer: selectedOption,
          marks: marks,
        };
    
         engagementScore = updatedScores;
      };
    
      const handleEngagementScore = async (e) => {
        e.preventDefault();
        const requestBody = {
          usertype: props.role,
          userid: props.userId,
          response: engagementScore?.map((response) => ({
            question: response.question,
            answer: response.answer,
            marks: response.marks,
          })),
        };
    
        try {
          const response = await axios.post(
            `${Api_Base_Url}/cp/engagementscore`,
            requestBody,
            {
              headers: { "Content-Type": "application/json" },
            }
          );
    
          if (response.status === 200) {
            props.setEngscorePopup(false)
            props.getEngScore();
          }
        } catch (error) {
          // Handle any errors
          console.error("Error:", error);
        }
      };

      useEffect(() => {
        props.getFitScore(props.userId);
          props.getEngScore(props.userId)
      }, [])

  return (
   <>
    <div className="form_popups popup__container">
        <div className="chagne_pass_form">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex align-items-center position-relative justify-content-center">
                <div
                  className="formByScan_container CreatLeadFormWrapper h-auto"
                  id="formByScan_container"
                  style={{ overflow: "visible", padding: "10px 15px" }}
                >
                  <div
                    className="popup_close_icon createLead"
                    onClick={() => props.setEngscorePopup(false)}
                  >
                    <i
                      className="fa fa-xmark"
                      onClick={() => props.setEngscorePopup(false)}
                    ></i>
                  </div>
                  <form
         style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column"
         }}
          action=""
          onSubmit={handleEngagementScore}
        >
             <h5>Engagement Score Questions</h5>
          {data?.score?.map((res, index) => {
            return (
              <div className="mb-6 col-12" key={res?._id}>
                <h6>
                  <span className="me-3">{index + 1}&#41;</span> {res?.question}
                </h6>
                <div className="d-flex ms-2">
                {res?.answeroption?.map((j) => {
                  return (
                    <div class="form-check form-check ms-3 mb-3">
                      <input
                        class="form-check-input"
                        name={`questionOptions_${index}`}
                        type="radio"
                        value={j.options}
                        id={`reverseCheck${j._id}`}
                        onChange={() =>
                          handleEngagementChange(index, j.options)
                        }
                      />
                      <label
                        htmlFor={`reverseCheck${j._id}`}
                        class="form-check-label "
                      >
                        {j?.options}
                      </label>
                    </div>
                  );
                })}
                </div>
              </div>
            );
          })}

          <button
            type="submit"
            className="btn btn-sm text-white "
            style={{
              backgroundColor: "#08554e",
              width: "130px",
              height: "40px",
              float: "right",
            }}
          >
            Submit
          </button>
        </form>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div> 
   </>
  )
}

export default InvEngQuestion