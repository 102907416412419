import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  NavLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { logout } from "../store/reducer/authReducer";
import { Api_Base_Url, PUBLIC_PATH } from "../constant/matcher";
import "../styles/loginFormStyle.css";
import { useGetProfileQuery } from "../store/services/dashboardService";
import RegistrationPopupForm from "./mutltiStep/RegistrationPopupForm";
import RegisterNowPopup from "./general/RegisterNowPopup";
import RegisterNowCpPopup from "./general/RegisterNowCpPopup";
import BookAppointment from "../components/general/BookAppointment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function Sidebar(props) {
  const { navContWrapp } = props;

  const userData = useSelector((state) => state?.userReducer?.userData);
  const logoutSlug = userData && userData?.slug;

  // =======register now popup=======
  const [registerNowPopup, setRegisterNowPopup] = useState(false);
  
  const checkUserStatus = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const leadUserId = loginUserData?.lmsId;

    fetch(`${Api_Base_Url}/register/checkstatus?id=${leadUserId}`)
      .then((response) => response.json())
      .then((data) => {
        const status = data?.data?.status;
        const note = data?.data?.note

        // Check the user's status
        if (data.data === null) {
          // Status is empty, allow registration
          setRegisterNowPopup(true);
        } else if (status === 2) {
          // Status is 2, display rejection message
          toast.warn("Your registration is currently being processed.");
        } else if (status === 1) {
          // Status is 1, display already registered message
          toast.success("Registration Completed");
        } else if (status === 3) {
          // Status is 3, display already registered message
          toast.error(`Registration Rejected: ${note}, Please try again.`);
          setTimeout(() => {
            setRegisterNowPopup(true);
          }, 2000);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
      });
    console.log("clicked");

  };

  // ========Book appointment popup=======
  const [bookAppointment, setBookAppointment] = useState(false);

  const closeRegPopup = () => {
    setRegisterNowPopup(false);
    setBookAppointment(false);
  };

  const [navSideHide, setNavSideHide] = useState(true);
  const [smallScreenSidebar, setSmallScreenSidebar] = useState(
    " smallScreenSidebar "
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [url, setUrl] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const profileResp = useGetProfileQuery(userData?._id);

  const userProfileData =
    profileResp?.isSuccess &&
    !profileResp?.isLoading &&
    profileResp.status === "fulfilled" &&
    !profileResp?.isError &&
    profileResp?.data?.success &&
    profileResp?.data?.user;

  // useEffect(() => {
  //   if(userProfileData !== '' ) {
  //     dispatch(setProfileDetails(userProfileData))
  //   }
  // }, [userProfileData, dispatch]);

  let sidebarNavIcons = document.getElementsByClassName("sidebar_icons");
  sidebarNavIcons = Array.from(sidebarNavIcons);

  sidebarNavIcons.forEach((icons) => {
    icons.addEventListener("mousemove", () => {
      icons.style.color = "black";
    });
    icons.addEventListener("mouseout", () => {
      icons.style.color = "#fff";
    });
  });

  const sidebarController = () => {
    let sidebarNavIcons = document.getElementsByClassName("sidebar_icons");
    sidebarNavIcons = Array.from(sidebarNavIcons);

    let sidebarNavLinks = document.getElementsByClassName("side_hide");
    sidebarNavLinks = Array.from(sidebarNavLinks);

    if (navSideHide === true) {
      setNavSideHide(false);
      navContWrapp(false);
      // setNavShow(false)
      sidebarNavIcons.forEach((icons) => {
        // icons.style.display = 'block'
        setTimeout(() => {
          icons.style.display = "block";
        }, 200);
        icons.style.marginRight = "0px";
      });
      sidebarNavLinks.forEach((links) => {
        links.style.display = "none";
      });
    } else {
      setNavSideHide(true);
      // setNavShow(true)
      navContWrapp(true);
      sidebarNavIcons.forEach((icons) => {
        icons.style.marginRight = "5px";
        icons.style.display = "inline";
      });
      sidebarNavLinks.forEach((links) => {
        setTimeout(() => {
          links.style.display = "inline";
        }, 200);
      });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });

    let sidebarNavIcons = document.getElementsByClassName("sidebar_icons");
    sidebarNavIcons = Array.from(sidebarNavIcons);

    let sidebarNavLinks = document.getElementsByClassName("side_hide");
    sidebarNavLinks = Array.from(sidebarNavLinks);

    if (window.screen.width <= 992) {
      setNavSideHide(false);
      sidebarNavIcons.forEach((icons) => {
        icons.style.display = "block";
        icons.style.marginRight = "0px";
      });
      sidebarNavLinks.forEach((links) => {
        links.style.display = "none";
      });
    } else {
      setNavSideHide(true);
      sidebarNavIcons.forEach((icons) => {
        icons.style.display = "inline";
        icons.style.marginRight = "7px";
      });
      sidebarNavLinks.forEach((links) => {
        links.style.display = "inline";
      });
    }
  }, []);

  const [logoutPopup, setLogoutPopup] = useState(false);

  const onLogout = () => {
    dispatch(logout());
    navigate(logoutSlug);
  };
  return (
    <>
      <ToastContainer />

      <div className="container-fluid dashboard-container">
        <div className="row d-flex align-items-center justify-content-center">
          <div
            className={`sidebar-container p-0 ${smallScreenSidebar}`}
            // className={`sidebar-container p-0 ${props.smallScreenSidebar}`}
            // style={{ left: `${props.navLeftVal}` }}
            style={{
              // left: 0,
              // width: props.navSideHide ? '230px' : '70px',
              // left: window.screen.width < 600 ? props.navSideHide ? '0px' : '-230px' : 0
              width: navSideHide ? "230px" : "70px",
              left:
                window.screen.width < 600
                  ? navSideHide
                    ? "0px"
                    : "-230px"
                  : 0,
              // left: `${props.navLeftVal}`
            }}
            id={"sidebar_container"}
          >
            <div className="dash-sidebar">
              <div className="sidebar-top py-3 px-3">
                {/* <h3 className='text-white text-center user-name'>Hi, USER</h3> */}
                <div className="user-panel">
                  <div className="pull-left image">
                    <img
                      src={PUBLIC_PATH + "assets/img/team/profile.png"}
                      className="img-circle"
                      alt="userImage"
                    />
                  </div>
                  <div className="pull-left side_hide info active">
                    <p className="ms-2 mb-0 text-capitalize">
                      {userProfileData?.name && userData?.name}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className='main-nav py-5'>
                <h3 className='text-white text-center user-name'>Hi, USER</h3>
              </div> */}
              <div className="sidebar-links" id="sidebar-links">
                <ul className="side-list pb-2">
                  <li className="">
                    <Link
                      to="/dashboard"
                      className={
                        "sidebar_link_a" +
                        (url === "/dashboard" ? " active" : "")
                      }
                    >
                      <i className="fa-solid sidebar_icons fa-house"></i>{" "}
                      <span className="side_hide ">Home</span>
                    </Link>
                  </li>
                  <li>
                    <NavLink to="/dashboard/listing-xpand">
                      <i className="fa-solid sidebar_icons fa-inbox"></i>{" "}
                      <span className="side_hide">Xpand Academy</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/dashboard/listing-product">
                      {/* <i className='fa-brands sidebar_icons fa-readme'></i> */}
                      <i className="fa-solid sidebar_icons fa-dumpster-fire"></i>{" "}
                      <span className="side_hide">Know Products</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/dashboard/my-queries">
                      {/* <i className='fa-brands sidebar_icons fa-readme'></i> */}
                      <i className="fa-solid sidebar_icons fa-clipboard-question"></i>{" "}
                      <span className="side_hide">My Queries</span>
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to='/dashboard/certification'>
                    <i className="fa-solid sidebar_icons fa-graduation-cap"></i>{' '}
                      <span className='side_hide'>Certification</span>
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/dashboard/profile">
                      {/* <Link to='#' className={"sidebar_link_a" + (url === "/dashboard/profile" ?" active" : "")}> */}
                      <i className="fa-solid sidebar_icons fa-user"></i>{" "}
                      <span className="side_hide">Profile</span>
                    </NavLink>
                  </li>
                  <li className="hidden-bookAppointment">
                    <Link to="" onClick={() => setBookAppointment(true)}>
                      <i class="fa-solid fa-calendar-check"></i>
                      <span className="side_hide">Book An Appointment</span>
                    </Link>
                  </li>
                  <li className="hidden-bookAppointment">
                    <Link onClick={checkUserStatus}>
                      <i class="fa-solid fa-xs fa-arrow-right"></i>
                      <span className="side_hide">Register Now</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => setLogoutPopup(true)}>
                      <i
                        className="fa-solid sidebar_icons fa-right-from-bracket"
                        onClick={() => setLogoutPopup(true)}
                      ></i>{" "}
                      <span
                        className="side_hide"
                        onClick={() => setLogoutPopup(true)}
                      >
                        Logout
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="p-0">
            <header
              id="header"
              className="header sidebarHeader px-3 d-flex align-items-center top-nav justify-content-between"
            >
              <div className="container-fluid d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between">
                  <NavLink to="" className="logo">
                    {/* <!-- Uncomment the line below if you also wish to use an image logo --> */}
                    <img src={PUBLIC_PATH + "xPandLogo.png"} alt="brandLogo" />
                  </NavLink>

                  <span id="sidebar_nav_toggle">
                    <i
                      onClick={sidebarController}
                      // onClick={props.sidebarController}
                      className="fa-solid fa-bars show-sidebar-menu "
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                </div>
                {/* <!-- .navbar --> */}
                <div className="loguser-links">
                  {/* <div className='user-name'>
                    <h3>Hi, User</h3>
                  </div> */}
                  <div className="register-links d-flex">
                    <div className="reg-btn me-2">
                      <Link to="" onClick={() => setBookAppointment(true)}>
                        Book An Appointment
                      </Link>
                    </div>
                    <div className="reg-btn me-2 ">
                      <Link to="" onClick={checkUserStatus}>
                        Register Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* <!-- End Header --> */}

            {/* <!-- End Footer --> */}
          </div>
        </div>
      </div>
      {logoutPopup && (
        <div className="cd-popup" role="alert">
          <div className="cd-popup-container">
            <img
              src={PUBLIC_PATH + "assets/img/icons/logout.png"}
              alt="logoutLogo"
              className="popupImg"
            />
            <h5>Are you sure you want to logout?</h5>
            <ul className="cd-buttons">
              <li>
                <Link to="" onClick={onLogout}>
                  Yes
                </Link>
              </li>
              <li>
                <Link to="" onClick={() => setLogoutPopup(false)}>
                  No
                </Link>
              </li>
            </ul>
            <Link
              to=""
              className="cd-popup-close img-replace"
              onClick={() => setLogoutPopup(false)}
            ></Link>
          </div>
        </div>
      )}
      {/* ====popup for complete profile form======= */}
      {userData?.iscomplete === 0 && (
        <RegistrationPopupForm
          isPopupShow={userData?.iscomplete}
          // userType={userData?.userType}
        />
      )}

      {/* ===========Popup for Book An Appointment ================= */}
      {bookAppointment && <BookAppointment closeRegPopup={closeRegPopup} />}

      {/* ===========Popup for REGISTER NOW ================= */}
      {userData && userData.userType === "64535fd33384aae2be60bb78" //id of channel-partner
        ? registerNowPopup && (
            <RegisterNowCpPopup closeRegPopup={closeRegPopup} />
          )
        : registerNowPopup && (
            <RegisterNowPopup closeRegPopup={closeRegPopup} />
          )}
      {/* <div className="registration-status"
              >
             <span>X</span>
    </div>     */}
    </>
  );
}

export default Sidebar;
