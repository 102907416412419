import React, { useState, useEffect } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from 'recharts'

function BarChartComp (props) {
  const [chartHeight, setChartHeight] = useState(3);
  
  useEffect(() => {
    if(window.screen.width < 768) {
      setChartHeight(1.5)
    } else {
      setChartHeight(3)
    }
    // console.log(chartHeight);
  }, [chartHeight]);

  return (
    <>
      <ResponsiveContainer width={'100%'} aspect={chartHeight} >
        <BarChart data={props.revenueChartData} height={'300'} barSize={50}>
       
          <XAxis dataKey={props.xTags} padding={{ left: 10, right: 10 }} />
          <YAxis tickCount={10}  tickFormatter={(value) => {
    if (value >= 1e7) {
      return (value / 1e7).toFixed(1) + ' Cr';
    } else if (value >= 1e5) {
      return (value / 1e5).toFixed(1) + ' L';
    } else {
      return value;
    }
  }}>
  </YAxis>
          <Tooltip formatter={(value) =>  Intl.NumberFormat('en-IN').format(value)}  />
          <Legend />
          <Bar dataKey={props.dataTags} fill='#0ea89a' />
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default BarChartComp
