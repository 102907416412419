import React, { useEffect, useState } from 'react'
import '../../styles/franchiseSecStyle.css'
import Form1 from './multistep/Form1.jsx'

function FranchisePopup (props) {
  const { isPopupShow } = props

  useEffect(() => {
    if(isPopupShow && isPopupShow === 0) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isPopupShow])

  return (
    <>
      <div
        className='cd-popup registration__popup register__overlay invli_poppup'
        role='alert'
      >
        <div className='invalid_access position-absolute'>
          <div className='container'>
            <div className='cookiesContent' id='cookiesPopup'>
              {/* <button className='close' onClick={props.closePopupHandler}>✖</button> */}
              <div className='formMulti w-100'>
                <Form1 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FranchisePopup
