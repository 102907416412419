import React from 'react'
import { Link } from 'react-router-dom'
import { PUBLIC_PATH } from '../constant/matcher'

function Footer (props) {
  return (
    <>
      <footer id='footer' className='footer'>
        <div className='container'>
          <div className='row gy-4'>
            <div className='col-lg-5 col-md-12 footer-info'>
              <Link
                // rel='noopener noreferrer'
                to={props.logoLink ? props.logoLink : '/'}
                className='logo d-flex align-items-center'
              >
                <img
                  src={PUBLIC_PATH + 'xPandLogo.png'}
                  alt='footer-logo'
                  className='img-fluid'
                />
              </Link>
              <p className="ps-2">
                Xpand Wealth, a new age platform with its state-of-the-art
                financial distributor network model, offers one stop solution to
                help channel partners to expand their business horizon. Xpand
                Wealth with its unique blend of technology in investment
                management helps in building bespoke investment solutions
                encompassing Financial advisory, Mutual fund Investments and
                Monthly rental income investments.
              </p>
              {/* <div className='social-links d-flex mt-4'>
                <a rel='noopener noreferrer' href='#!' className='twitter'>
                  <i className='bi bi-twitter'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='facebook'>
                  <i className='bi bi-facebook'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='instagram'>
                  <i className='bi bi-instagram'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='linkedin'>
                  <i className='bi bi-linkedin'></i>
                </a>
              </div> */}
            </div>

            <div className='col-lg-2 col-6 footer-links'>
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a rel='noopener noreferrer' href={props.home?props.home:'#hero'}>
                    Home
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href={props.about?props.about:'#about'}>
                    About
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href={props.service?props.service:'#service'}>
                    Products
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href={props.faq?props.faq:'#faq'}>
                    FAQs
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href={props.testimonial?props.testimonial:'#testimonials'}>
                    Testimonials
                  </a>
                </li>
                {/* <li>
                  <Link to='/login/team'>Team Login</Link>
                </li> */}
                {/* <li>
                  <Link to='/franchise'>Partner Login</Link>
                </li>
                <li>
                  <Link to='/form-by-scan'>Qr Scan</Link>
                </li> */}
              </ul>
            </div>

            <div className='col-lg-5 col-md-12 footer-contact'>
              <h4>Contact Us</h4>
              <p>
                <div>
                  <h6 className='regOff'>REGISTERED OFFICE:</h6>
                  <div className='mb-2'>
                    109-P NO-4 LSC, Shrestha Vihar, New Delhi- 110092
                  </div>
                </div>
                {/* <br /> */}
                <div>
                  <h6 className='regOff'>CORPORATE OFFICE:</h6>
                  <p className='mb-3'>
                    304, Tower 4, Assotech Business Cresterra, Sector-135,
                    Noida–201304
                  </p>
                </div>
                <div className='' style={{ lineHeight: 0 }}>
                  <strong>Phone:</strong> +91-8800308006
                </div>
                <br />
                <div className='mb-3' style={{ lineHeight: 1 }}>
                  <strong>For Investor Support:</strong> support@xpandwealth.com
                </div>
                {/* <br /> */}
                <div className='' style={{ lineHeight: 1 }}>
                  <strong>For Business Partner Support:</strong>{' '}
                  help@xpandwealth.com
                </div>
                <br />
              </p>
            </div>
          </div>
        </div>

        <div className='container mt-4'>
          <div className='copyright'>
            &copy; Copyright{' '}
            <strong>
              <span>Xpand Wealth</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
