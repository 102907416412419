import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Link,
  NavLink,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { PUBLIC_PATH } from "../constant/matcher";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../styles/loginFormStyle.css";
import {
  useGetNewOtpMutation,
  useUserTypeSelectQuery,
  useCpInvestorLoginMutation,
} from "../store/services/loginService";
import OtpInput from "../components/general/OtpInput";
import {
  setLoginUserToken,
  loggedInUserData,
} from "../store/reducer/authReducer";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { ProfileCompletionContext } from "../components/ProfileCompletionContext";

function LoginScreen(props) {
  const mobileRef = useRef();

  const [loginMobileValidation, setLoginMobileValidation] = useState("");
  const [validation, setValidation] = useState("");
  const [otpSuccess, setOtpSuccess] = useState("");
  const [slideClass, setSlideClass] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [userTypeCate, setUserTypeCate] = useState("");
  const [blankFields, setBlankFields] = useState(null);
  const {  isProfileComplete, fetchProfileStatus } = useContext(ProfileCompletionContext);
  const [disableContinue, setDisableContinue] = useState(false);
  const [disableVerify, setDisableVerify] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //  to save otp from response
  const [OtpRespValue, setOtpRespValue] = useState(null);

  const { loginuser } = useParams();

  const [timerSeconds, setTimerSeconds] = useState(10);
  useEffect(() => {
    if (slideClass) {
      const timer = setInterval(() => {
        setTimerSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      if (timerSeconds === 0) {
        setShowResend(true);
        clearInterval(timer);
      }
      // Clean up the interval when the component is unmounted
      return () => clearInterval(timer);
    }
  }, [slideClass, timerSeconds]);

  // API FOR USERTYPE
  const userTypeResp = useUserTypeSelectQuery();
  const userTypeData =
    userTypeResp?.isSuccess &&
    !userTypeResp?.isLoading &&
    !userTypeResp?.isError &&
    userTypeResp?.data?.categoryList;

  useEffect(() => {
    setUserTypeCate(
      loginuser && loginuser.toLowerCase() === "investor"
        ? userTypeData[1]?._id
        : userTypeData[0]?._id
    );
  }, [loginuser, userTypeData]);

  // login data
  const [otpInptVal, setotpInptVal] = useState();
  const [loginDetails, setLoginDetails] = useState({
    phone: "",
  });

  // to get otp number form all otp inputs
  const getOtpVal = (otpval) => {
    setotpInptVal(otpval);
  };

  const loginChangeHandler = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };

  //   api for otp
  const [optPhone, otpResp = {}] = useGetNewOtpMutation();

  // handler of otp response
  useEffect(() => {
    if (
      otpResp?.isSuccess &&
      !otpResp?.isError &&
      otpResp?.data.message &&
      !otpResp?.data?.otp
    ) {
      if (!slideClass) {
        setDisableContinue(false);
        setLoginMobileValidation("Try again");
      }
      setTimeout(() => {
        setLoginMobileValidation("");
      }, 5000);
    }

    if (
      otpResp?.isSuccess &&
      !otpResp?.isLoading &&
      !otpResp?.isError &&
      otpResp?.data?.otp
    ) {
      setOtpRespValue(otpResp?.data?.otp);
      setOtpSuccess(otpResp?.data.message);
      setSlideClass(true);
      setDisableContinue(true);
      setTimeout(() => {
        setOtpSuccess("");
      }, 5000);
    }
  }, [otpResp]);

  // change number handler
  const changeNumber = () => {
    setDisableContinue(false);
    setSlideClass(false);
    mobileRef.current.autoFocus = true;
  };

  //  resend otp handler
  const resendOtp = () => {
    let numForOtp = {};
    numForOtp.phone = loginDetails.phone;
    numForOtp.userType = userTypeCate;

    if (loginDetails.phone !== "") {
      setShowResend(false);
      setTimerSeconds(10);
      optPhone(numForOtp);
    } else {
      setBlankFields("Please try again");
    }
  };

  //   submit otp form
  const submitNumbForOtp = e => {
    let numForOtp = {}
    numForOtp.phone = loginDetails.phone
    numForOtp.userType = userTypeCate

    e.preventDefault()
    if (loginDetails?.phone?.length === 10) {
      setLoginMobileValidation(false)
      // optPhone({ phone: loginDetails.phone })
      // ====api to get otp
      optPhone(numForOtp)
      setShowResend(false)
      // =-==to disable continueBtn
      setDisableContinue(true)
      setTimerSeconds(10)
    } else {
      setLoginMobileValidation('Please put 10 digit Mobile Number !')
      setTimeout(() => {
        setLoginMobileValidation('Please put 10 digit Mobile Number !')
      }, 5000)
    }
  }

  

  // LOGIN API
  const [loginWithOpt, loginResp = {}] = useGetNewOtpMutation();

  // login data response handler
  useEffect(() => {
    // ====to disable verify otp button====
    if (loginResp?.isLoading) {
      setDisableVerify(true);
    } else {
      setDisableVerify(false);
    }

    // ======To manage redirections
    if (
      loginResp?.isSuccess &&
      !loginResp?.isLoading &&
      !loginResp?.isError &&
      loginResp?.data?.success
    ) {
      
      setValidation(false);
     localStorage.removeItem("user_Fm_Rm-token");
     localStorage.setItem("user-token", loginResp?.data?.token);
     localStorage.setItem(
        "loginUserData",
        JSON.stringify(loginResp?.data?.user)
      );
      dispatch(loggedInUserData(loginResp?.data?.user));
      dispatch(setLoginUserToken(loginResp?.data?.token));

      const isClient = loginResp?.data?.isClient;

      if (isClient) {
        if (loginResp?.data?.user?.userType === "rm") {
          setBlankFields("Registered as relationship manager");
          setTimeout(() => {
            setBlankFields("");
          }, 5000);
        } else {
          localStorage.setItem("showPortalButton", loginResp?.data?.portal)
          
          navigate(`/dashboard/${loginResp?.data?.user?.userType}`);
        }
      } else {
        navigate("/dashboard");
      }
      fetchProfileStatus();
      // console.log(isProfileComplete);
    }

    if (
      loginResp?.isSuccess &&
      !loginResp?.isLoading &&
      !loginResp?.isError &&
      loginResp?.data?.message &&
      !loginResp?.data?.success
    ) {
      setBlankFields("Please Enter Valid OTP!");
      setValidation(true);
      setTimeout(() => {
        setBlankFields("");
      }, 5000);
    }

    if (
      !loginResp?.isSuccess &&
      !loginResp?.isLoading &&
      loginResp?.isError &&
      loginResp?.error?.data &&
      !loginResp?.error?.data?.success
    ) {
      setBlankFields(loginResp?.error?.data?.message);
      setTimeout(() => {
        setBlankFields("");
      }, 5000);
    }
  }, [loginResp, isProfileComplete]);

  // final submit
  const loginSubmit = (e) => {
    e.preventDefault();
    var finalData = loginDetails;
    finalData.userType = userTypeCate;
    finalData.otp = otpInptVal;

    if (finalData.otp && finalData.otp.length === 6) {
      setBlankFields(null);
      loginWithOpt(finalData);
    } else {
      setBlankFields("Please enter 6 digit otp");
      setTimeout(() => {
        setBlankFields(null);
      }, 5000);
    }
  };

  useEffect(() => {
    if (window.screen.width >= 768) {
      window.scrollTo({ top: 350, left: 0, behavior: "instant" });
    }
    if (window.screen.width < 768) {
      window.scrollTo({ top: 280, left: 0, behavior: "instant" });
    }

    mobileRef.current.oninput = () => {
      if (mobileRef.current.value.length > mobileRef.current.maxLength)
        mobileRef.current.value = mobileRef.current.value.slice(
          0,
          mobileRef.current.maxLength
        );
    };
  }, []);

  const otpInputValidColor = () => {
    setValidation(false);
  };

  return (
    <>
      <Header
        home="/"
        about="/#about"
        features="/#features"
        service="/#service"
        testimonial="/#testimonials"
        faq="/#faq"
      />

      {/* hero section */}
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row gy-4 d-flex justify-content-between">
            <div className="hero-form col-md-7 d-flex flex-column justify-content-center position-relative">
              <h2 className="text-uppercase hero-heading">
                Take a First Step Towards Your Independent Financial Journey
              </h2>
              <div className="banner-text">
                <h3 className="mt-4 text-center text-white text-uppercase">
                  Unlock Potential Of <br /> Unlimited Passive Income
                </h3>
              </div>
            </div>

            <div className="d-none d-md-block col-md-5 col-lg-5 hero-img">
              <img
                src={PUBLIC_PATH + "assets/img/hero-img.svg"}
                className="img-fluid mb-3 mb-lg-0"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className="px-3 main d-flex align-items-center justify-content-center"
        style={{ background: "whitesmoke" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 p-0 d-flex align-items-center justify-content-center">
              <div
                className="container form-slider-container otp__form__responheight scanNewForm"
                id="container"
              >
                <div
                  className="form-container sign-up-container"
                  id="sign-up-container"
                ></div>
                <div
                  className="form-container form_by_scan sign-in-container"
                  id="sign-in-container"
                >
                  <div className="img_wrapper">
                    <img
                      src={PUBLIC_PATH + "assets/img/loginForm.jpg"}
                      alt="signup"
                      className="img-fluid"
                    />
                  </div>
                  <div
                    className={`forms__container ${slideClass ? "slide" : ""}`}
                  >
                    <form
                      onSubmit={submitNumbForOtp}
                      className="num__form_slide"
                    >
                      <div className="para_tags position-relative">
                        <p>Enter your 10-digit Mobile Number</p>
                        {/* <p>
                          Enter your 10-digit mobile number
                        </p> */}
                      </div>

                      {/* valid credentials */}
                      {/* {validCred !== '' && (
                        <p className='mb-2 text-danger text-center'>
                          {validCred}
                        </p>
                      )} */}
                      <div className="numb_opt position-relative w-100 my-2">
                        <input
                          type="number"
                          id="optMobile"
                          maxLength="10"
                          name="phone"
                          placeholder="Mobile Number *"
                          required
                          autoFocus
                          ref={mobileRef}
                          value={loginDetails.phone}
                          onChange={loginChangeHandler}
                        />
                        <span className="country_code position-absolute">
                          +91
                        </span>
                      </div>
                     
                      <button
                      type='submit'
                        className="otp_send_btn"
                        disabled={disableContinue}
                      >
                        {otpResp?.isLoading ? "Continue..." : "Continue"}
                        {/* Send OTP */}
                      </button>
                      {/* mobile validation */}
                      {loginMobileValidation !== "" && (
                        <p className="text-danger text-start w-100">
                          {loginMobileValidation}
                        </p>
                      )}
                    </form>

                    <form
                      onSubmit={loginSubmit}
                      className="otp__form_slide pt-0 pb-0"
                    >
                      <div className="otp__para text-start mb-2">
                        <p className="m-0">
                          Enter OTP sent to {loginDetails.phone}
                        </p>
                        <span
                          className="reset__otp__form text-uppercase"
                          onClick={changeNumber}
                        >
                          Change Number
                        </span>
                      </div>

                      {/* OTP Fields desgin */}
                      <OtpInput
                        validation={validation}
                        getOtpVal={getOtpVal}
                        otpInputValid={otpInputValidColor}
                        // otpRespNum={OtpRespValue}
                      />

                      <div className="resend__opt ms-1 mb-1">
                        {showResend && (
                          <span
                            onClick={resendOtp}
                            className="resend__otp text-uppercase"
                          >
                            Resend OTP
                          </span>
                        )}
                        {!showResend && (
                          <span>
                            Resend OTP in{" "}
                            <span id="resendTimer">{timerSeconds}s</span>
                          </span>
                        )}
                      </div>
                      <button
                        className="otp_send_btn mt-2 py-2"
                        disabled={disableVerify}
                      >
                        {loginResp?.isLoading ? "Verifying..." : "Verify OTP"}
                      </button>
                      {blankFields && (
                        <p className="text-danger mb-1 mt-1 text-start w-100 m-0">
                          {blankFields}
                        </p>
                      )}
                    </form>
                  </div>
                </div>
                <div className="overlay-container">
                  <div className="overlay">
                    <div className="overlay-panel overlay-left">
                      <h1>Hello, Friend!</h1>
                      <p>
                        Enter your personal details and start journey with us
                      </p>
                    </div>
                    <div className="overlay-panel overlay-right">
                      <h1>Welcome !</h1>
                      <p>
                        To keep connected with us please login/signup with your
                        personal info
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Footer ======= --> */}
      {/* <Footer
        logoLink="/"
        home="/"
        about="/#about"
        features="/#features"
        service="/#service"
        testimonial="/#testimonial"
        faq="/#faq"
      /> */}
       <footer id='footer' className='footer'>
        <div className='container'>
          <div className='row gy-4'>
            <div className='col-lg-5 col-md-12 footer-info'>
              <Link
                // rel='noopener noreferrer'
                to={props.logoLink ? props.logoLink : '/'}
                className='logo d-flex align-items-center'
              >
                <img
                  src={PUBLIC_PATH + 'xPandLogo.png'}
                  alt='footer-logo'
                  className='img-fluid'
                />
              </Link>
              <p className="ps-2">
                Xpand Wealth, a new age platform with its state-of-the-art
                financial distributor network model, offers one stop solution to
                help channel partners to expand their business horizon. Xpand
                Wealth with its unique blend of technology in investment
                management helps in building bespoke investment solutions
                encompassing Financial advisory, Mutual fund Investments and
                Monthly rental income investments.
              </p>
              {/* <div className='social-links d-flex mt-4'>
                <a rel='noopener noreferrer' href='#!' className='twitter'>
                  <i className='bi bi-twitter'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='facebook'>
                  <i className='bi bi-facebook'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='instagram'>
                  <i className='bi bi-instagram'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='linkedin'>
                  <i className='bi bi-linkedin'></i>
                </a>
              </div> */}
            </div>

            <div className='col-lg-2 col-6 footer-links'>
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a rel='noopener noreferrer' href='/#hero'>
                    Home
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href='/#about'>
                    About
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href='/#service'>
                    Products
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href='/#faq'>
                    FAQs
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href='/#testimonials'>
                    Testimonials
                  </a>
                </li>
                <li>
                  <Link to='/login/team'>Team Login</Link>
                </li>
                {/* <li>
                  <Link to='/franchise'>Partner Login</Link>
                </li>
                <li>
                  <Link to='/form-by-scan'>Qr Scan</Link>
                </li> */}
              </ul>
            </div>

            <div className='col-lg-5 col-md-12 footer-contact'>
              <h4>Contact Us</h4>
              <p>
                <div>
                  <h6 className='regOff'>REGISTERED OFFICE:</h6>
                  <div className='mb-2'>
                    109-P NO-4 LSC, Shrestha Vihar, New Delhi- 110092
                  </div>
                </div>
                {/* <br /> */}
                <div>
                  <h6 className='regOff'>CORPORATE OFFICE:</h6>
                  <p className='mb-3'>
                    304, Tower 4, Assotech Business Cresterra, Sector-135,
                    Noida–201304
                  </p>
                </div>
                <div className='' style={{ lineHeight: 0 }}>
                  <strong>Phone:</strong> +91-8800308006
                </div>
                <br />
                <div className='mb-3' style={{ lineHeight: 1 }}>
                  <strong>For Investor Support:</strong> support@xpandwealth.com
                </div>
                {/* <br /> */}
                <div className='' style={{ lineHeight: 1 }}>
                  <strong>For Business Partner Support:</strong>{' '}
                  help@xpandwealth.com
                </div>
                <br />
              </p>
            </div>
          </div>
        </div>

        <div className='container mt-4'>
          <div className='copyright'>
            &copy; Copyright{' '}
            <strong>
              <span>Xpand Wealth</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
      {/* <!-- End Footer --> */}
    </>
  );
}

export default LoginScreen;
