import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../../styles/franchiseSecStyle.css'

function FranchisePopup (props) {
  const [countdown, setCountdown] = useState(5); 
  const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
  let eliteRole = loginUserData?.eliterole
  
  const navigate = useNavigate()
  useEffect(() => {
    const timer = setTimeout(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1); 
      } else {
       
        clearTimeout(timer);
        let link = '';
        switch (eliteRole) {
          case 'BRANCH':
            link = 'https://wealthelite.in/branch-login';
            break;
          case 'CLIENT':
            link = 'https://wealthelite.in/client-login';
            break;
          case 'EMPLOYEE':
            link = 'https://wealthelite.in/emp-login';
            break;
          case 'ARN':
            link = 'https://wealthelite.in/arn-login';
            break;
          default:
            link = 'https://wealthelite.in/broker-login';
            break;
        }
        props.closePopupHandler()
        window.open(link, '_blank'); 
      }
    }, 1000);

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
  }, [countdown, navigate]);

  return (
    <>
      <div className='cd-popup invli_poppup' role='alert'>
        <div className='invalid_access position-absolute'>
          <div className='container'>
            <div className='cookiesContent' id='cookiesPopup'>
              <button className='close' onClick={props.closePopupHandler}>✖</button>
              {/* <img
                src='https://cdn-icons-png.flaticon.com/512/1047/1047711.png'
                alt='cookies-img'
              /> */}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='text-danger'
                style={{width:'150px', height: "150px"}}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
                />
              </svg>

              <h2>Warning !</h2>
              <p>
                Invalid Access
              </p>
              <button className='accept'>{`Redirecting in ${countdown} s`}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FranchisePopup
