import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FranchiseSidebar from "../../components/franchise/FranchiseSidebar";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import MyClientsCards from "../../components/cardsDesigns/MyClientsCards";
import {
  useProductSelectQuery,
  useGetCityListApiQuery,
} from "../../store/services/loginService";
import { useFetchMyCpQuery } from "../../store/services/fetchApiService";
import DashboardBanner from "../../components/general/DashboardBanner";
import Loader from "../../components/general/Loader";
import { formatAmount } from "../../utils/helper";

function MyCP() {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLead, setFilteredLead] = useState([]);
  const [dynamicRoute, setdynamicRoute] = useState("");
  const [active, setActive] = useState("");
  const [inactive, setInactive] = useState("");
  const [inactiveXpand, setInactiveXpand] = useState("");
  const [isloading, setIsLoading] = useState(true);
  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
  }, [loggedInUserData]);

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  // api for Get Products
  const productApi = useProductSelectQuery();
  var productApiData =
    productApi?.isSuccess &&
    !productApi?.isLoading &&
    productApi.status === "fulfilled" &&
    !productApi?.isError &&
    productApi?.data?.productsList;

  // Funtion to Get Product Name By its ID
  const productNameById = (productId) => {
    let productName = [];
    let arr = [];
    for (let i = 0; i < productId?.length; i++) {
      arr =
        productApiData && productApiData?.find((el) => el._id === productId[i]);
      if (arr !== false) productName.push(arr?.name);
    }
    return productName.length > 0
      ? productName[0] +
          (productName.length > 1
            ? "+" + (productName.length - 1).toString()
            : "")
      : "";
  };
  // ++=====Function For Product Toolpit========
  const productToolpit = (productId) => {
    let productString = "";
    let productName = [];
    let arr = [];
    for (let i = 0; i < productId?.length; i++) {
      arr =
        productApiData && productApiData?.find((el) => el._id === productId[i]);
      if (arr !== false) productName.push(arr?.name);
    }
    return productName.length > 0
      ? productName.join(" , ") // Join the product names with commas
      : "";
  };

  // Get CITY API
  const cityResp = useGetCityListApiQuery();
  const cityFetchedData =
    cityResp?.isSuccess &&
    !cityResp?.isLoading &&
    cityResp.status === "fulfilled" &&
    !cityResp?.isError &&
    cityResp?.data?.cities;
  // Funtion to Get City Name By its Id
  const cityNameById = (cityId) => {
    let city = "";
    for (let i = 0; i < 1; i++) {
      city =
        cityFetchedData && cityFetchedData?.find((el) => el._id === cityId);
      if (city !== false) return city?.city;
    }
  };

  // My CLients Api
  const [clientData, setclientData] = useState(null);
  const myCpResp = useFetchMyCpQuery(loggedInUserData);

  useEffect(() => {
    // console.log(myCpResp)
    if (
      myCpResp?.isSuccess &&
      !myCpResp?.isLoading &&
      !myCpResp?.isError &&
      myCpResp?.data?.success
    ) {
      const data = myCpResp?.data?.myChannel;
      setclientData(data);
      const active = data?.filter((result) => result?.status === 1).length;
      const inActive = data?.filter((result) => result?.status === 3).length;
      const inActiveXpand = data?.filter((result) => result?.status === 2).length;
      setActive(active);
      setInactive(inActive);
      setInactiveXpand(inActiveXpand)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [myCpResp]);


  

  useEffect(() => {
    // Filter the lead data based on the search term
    const filtered = searchTerm
      ? clientData.filter((result) => {
          const nameMatch =
            result.name &&
            result.name.toLowerCase().includes(searchTerm.toLowerCase());
          const phoneMatch =
            result.phone && result.phone.toString().includes(searchTerm);
          const productMatch = productApiData.some(
            (product) =>
              product.name &&
              product.name.toLowerCase().includes(searchTerm.toLowerCase())
          );
          const cityNameMatch = cityFetchedData.some(
            (city) =>
              city.city &&
              city.city.toLowerCase().includes(searchTerm.toLowerCase())
          );
          return nameMatch || phoneMatch || productMatch || cityNameMatch;
        })
      : clientData;
    setFilteredLead(filtered);
  }, [searchTerm, clientData, cityFetchedData, productApiData]);

  return (
    <>
      {isloading ? (
        <Loader />
      ) : (
        <>
          <FranchiseSidebar navContWrapp={navContWrapp} />

          {/* content wrapper section */}
          <section
            className={`dash-content-wrapper small-wrapper`}
            style={{ paddingLeft: navShow ? "230px" : "70px" }}
          >
            {/* banner sec */}
            {/* <div id="banner">
          <div className="container-fluid p-0">
            <div className="banner-img p-0">
              <img
                src={PUBLIC_PATH + "assets/img/dash-banner.jpg"}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div> */}
            <DashboardBanner />

            <div className="breadcrumbs">
              <div className="container">
                <p>
                  <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                    Dashboard
                  </Link>{" "}
                  <i className="fa-solid fa-angle-right"></i> My Channel Partner
                </p>
              </div>
            </div>

            <div
              className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
              style={{ background: "whitesmoke" }}
            >
              <div className="row mb-4">
                <div className="col-md-4 col-lg-3 mb-2">
                  <MyClientsCards
                    cardTitle={"Active"}
                    cardValues={active ? active : 0}
                    borderColor={1}
                    bgGradient={1}
                    textColor={1}
                    iconsNumb={6}
                  />
                </div>
                <div className="col-md-4 col-lg-3 mb-2">
                  <MyClientsCards
                    cardTitle={"InActive Xpand"}
                    cardValues={inactiveXpand ? inactiveXpand : 0}
                    // updateTime={"Since last week"}
                    borderColor={2}
                    bgGradient={2}
                    textColor={2}
                    iconsNumb={1}
                  />
                </div>
                <div className="col-md-4 col-lg-3 mb-2">
                  <MyClientsCards
                    cardTitle={"InActive"}
                    cardValues={inactive ? inactive : 0}
                    borderColor={3}
                    bgGradient={3}
                    textColor={3}
                    iconsNumb={7}
                  />
                </div>
                <div className="col-md-4 col-lg-3 mb-2">
                  <MyClientsCards
                    cardTitle={"Total Clients"}
                    cardValues={clientData !== null ? clientData?.length : 0}
                    // updateTime={"Since last week"}
                    borderColor={4}
                    bgGradient={4}
                    textColor={4}
                    iconsNumb={5}
                  />
                </div>
              </div>
              <div className="row table__row__wrapp">
                <div className="client_table_container py-2">
                  <div className="d-flex justify-content-between">
                    <h2>My Channel Partner</h2>
                    <input
                      type="text"
                      className="search-functionality"
                      placeholder="Search....."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div className="w-100 overflow-auto">
                    <table className="table table-striped client__table">
                      <thead>
                        <tr>
                          <th scope="col">Sr No.</th>
                          <th scope="col">Name</th>
                          <th scope="col">Phone</th>
                          <th scope="col">City</th>
                          <th scope="col">Products</th>
                          <th scope="col">Sip</th>
                          <th scope="col">Equity</th>
                          <th scope="col">Total AUM</th>
                          <th scope="col">View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredLead &&
                          filteredLead !== null &&
                          filteredLead?.map((client, index) => {
                            return (
                              <tr key={client?._id}>
                                <th scope="row">{index + 1}</th>
                                <td className="text-capitalize text-black">
                                  {client?.name}
                                </td>
                                <td>{client?.phone}</td>
                                <td className="text-capitalize">
                                  {client?.city && cityNameById(client?.city)}
                                </td>
                                <td
                                  className="text-capitalize"
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={client?.product?.name}
                                >
                                  {client?.product?.name}
                                </td>
                                {client?.sipdetail?.length > 0 ? (
                                 <td>
                                 {formatAmount(client?.sipdetail?.reduce(
                                   (total, item) =>
                                     total + item?.totalvalue,
                                   0
                                 ))}
                               </td>
                                    
                                  
                                ) : (
                                  <td>0</td>
                                )}
                                {client?.clientinflow?.length > 0 ? (
                                  <td>
                                    {formatAmount(client?.clientinflow?.reduce(
                                      (total, item) =>
                                        total + item?.totalamount,
                                      0
                                    ))}
                                  </td>
                                ) : (
                                  <td>0</td>
                                )}
                                {client?.aumdata.length > 0 ? (
                                 <td>
                                 {formatAmount(client?.aumdata?.reduce(
                                   (total, item) =>
                                     total + item?.totalaum,
                                   0
                                 ))}
                               </td>
                                   
                                
                                ) : (
                                  <td>0</td>
                                )}

                                <td className="ps-4">
                            <Link to={`${client?._id}/${client?.role}`} className="ps-3"
                            >
                              <i className="fa-solid fa-eye"></i>
                            </Link>
                          </td>
                              </tr>
                            );
                          })}
                        {myCpResp?.isLoading && (
                          <p className="mb-0 ms-2 mt-2">
                            Loading channel partners...
                          </p>
                        )}
                        {!myCpResp?.isLoading && clientData === null && (
                          <p className="mb-0 ms-2 mt-2">
                            You don't have any channel partner
                          </p>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- ======= Footer ======= --> */}
            <Footer
              logoLink={"/"}
              home={"/#home"}
              about={"/#about"}
              service={"/#service"}
              faq={"/#faq"}
              testimonial={"/#testimonials"}
            />
          </section>
        </>
      )}
    </>
  );
}

export default MyCP;
