import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Api_Base_Url } from '../../constant/matcher'

const fetchApiService = createApi({
  reducerPath: 'fetchApReq',
  tagTypes: ['mycp, myclient'],
  baseQuery: fetchBaseQuery({
    baseUrl: Api_Base_Url
  }),
  endpoints: builder => {

    return {
      eventQrDetailsFetch: builder.query({
        query: eventId => {
          return {
            url: `event/event-details?id=${localStorage.getItem("eventId")}`,
            method: 'GET'
           
          }
          
        }
        
      }),
     
      // my clients api
      fetchMyClients: builder.query({
        query: userData => {
          return {
            url: `${userData?.role}/my-client?id=${userData?._id}`, //cp/my-client?id=${cpId}
            method: 'GET'
          }
        },
        providesTags: ['myclient']
      }),
      // my channel partner api
      fetchMyCp: builder.query({
        query: userData => {
          return {
            //admin/api/v1/rm/my-channel-partner?id=${rmid}
            url: `${userData?.role}/my-channel-partner?id=${userData?._id}`,
            method: 'GET'
          }
        },
        providesTags: ['mycp']
      }),
      // FOrm Download Api
      apiFormDownload: builder.query({
        query: userData => {
          return {
            url: 'download/download-form',
            method: 'GET'
          }
        }
      }),
      // Notice Board Api for current news
      noticeBoardApi: builder.query({
        query: categoryId => {
          return {
            url: `notice/get-notice/${categoryId}`,
            method: 'GET'
          }
        }
      }),
      // Notice board api for upcoming events
      noticeBoardUpcomingApi: builder.query({
        query: categoryId => {
          return {
            url: `notice/get-upcoming-news/${categoryId}`,
            method: 'GET'
          }
        }
      }),
      // Creative download api
      creativeDownloadApi: builder.query({
        query: productId => {
          return {
            url: `ads/get-ads?product=${productId}`,
            method: 'GET'
          }
        }
      })
    }
  }
})
export const {
  useEventQrDetailsFetchQuery,
  useFetchMyClientsQuery,
  useFetchMyCpQuery,
  useApiFormDownloadQuery,
  useNoticeBoardApiQuery,
  useNoticeBoardUpcomingApiQuery,
  useCreativeDownloadApiQuery,
} = fetchApiService
export default fetchApiService
