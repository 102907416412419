import React, { useEffect } from "react";
import { useDashBannerApiQuery } from "../../store/services/dashboardService";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import dData from "../../constant/dummyData.json";
import { PUBLIC_PATH } from "../../constant/matcher";

function DashboardBanner() {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  var loggedUserCategory = loggedInUserData && loggedInUserData?.userType;
  let fetchId = "";
  if (loggedInUserData?.role === "rm") {
    fetchId = "647989d7a66ca4a4ef91bda9";     
  } else if (loggedInUserData?.role === "fm") {
    fetchId = "647989e3a66ca4a4ef91bdaa";
  } else if (loggedInUserData?.role === "cp") {
    fetchId = "647d74468d3ff9520b95f7bd";
  } else if (loggedInUserData?.role === "investor") {
    fetchId = "647989b5a66ca4a4ef91bda8";
  } else { 
    fetchId = loggedUserCategory;
  }
  const apiBanner = useDashBannerApiQuery(fetchId);

  // console.log("1", apiBanner);
  // API For Banner
  const bannerServerPath =
    apiBanner?.isSuccess &&
    !apiBanner?.isLoading && 
    apiBanner?.status === "fulfilled" &&
    !apiBanner?.isError &&
    apiBanner?.data?.path;

    // console.log(bannerServerPath);

  const bannerSrc =
    apiBanner?.isSuccess &&
    !apiBanner?.isLoading &&
    apiBanner?.status === "fulfilled" &&
    !apiBanner?.isError &&
    apiBanner?.data?.banner;

    // console.log(bannerSrc);
  // useEffect(() => {
  //   console.log(bannerSrc)
  // }, [bannerSrc])

  var sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      {/* banner sec */}
      {/* {apiBanner?.isSuccess && !apiBanner?.isLoading && apiBanner?.data && apiBanner?.data?.banner */}
      <div className="bannerSlider">
        {bannerSrc && bannerSrc?.length > 0 && (
          <Slider {...sliderSettings}>
          {bannerSrc && bannerSrc?.length > 0 &&
              bannerSrc?.map((items, index) => (
                // <>
                <div id="banner" key={items?._id + index}>
                  <div className="container-fluid p-0">
                    <div className="banner-img p-0">
                      <img
                        src={bannerServerPath + items?.banner}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                // </>
              ))}
          </Slider>
        )}
        {/* <h2>banner</h2> */}
      </div>
    </>
  );
}

export default DashboardBanner;
