import React, { useEffect, useState } from "react";
import data from "../../../constant/InvesterQuestion.json";
import axios from "axios";
import { Api_Base_Url } from "../../../constant/matcher";

const InvQuestion = (props) => {
  const [fitScore, setFitScore] = useState([]);
  
  const handleAnswerChange = (questionIndex, selectedOption) => {
    const updatedResponses = [...fitScore];
    const marks = selectedOption === "Yes" ? 2 : 0;

    updatedResponses[questionIndex] = {
      question: data?.data[questionIndex]?.question,
      answer: selectedOption,
      marks: marks,
    };
    setFitScore(updatedResponses);
  };

  const handleFitScore = async (e) => {
    e.preventDefault();
    const requestBody = {
      usertype: props.role,
      userid: props.userId,
      response: fitScore?.map((response) => ({
        question: response.question,
        answer: response.answer,
        marks: response.marks,
      })),
    };
   
    try {
      const response = await axios.post(
        `${Api_Base_Url}/cp/fitscore`,
        requestBody,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        props.getFitScore();
        props.setFitscorePopup(false)
       
      }
    } catch (error) {
      // Handle any errors
      console.error("Error:", error);
    }

    // console.log(requestBody);
  };

  
useEffect(() => {
  props.getFitScore(props.userId);
    props.getEngScore(props.userId);
}, [])
  return (
    <>
    
      <div className="form_popups popup__container">
        <div className="chagne_pass_form">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex align-items-center position-relative justify-content-center">
                <div
                  className="formByScan_container CreatLeadFormWrapper h-auto"
                  id="formByScan_container"
                  style={{ overflow: "visible", padding: "10px 15px" }}
                >
                  <div
                    className="popup_close_icon createLead"
                    onClick={() => props.setFitscorePopup(false)}
                  >
                    <i
                      className="fa fa-xmark"
                      onClick={() => props.setFitscorePopup(false)}
                    ></i>
                  </div>
                  <form action="" onSubmit={handleFitScore}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column"
                }}
                  >
                    <h5>Fit Score Questions</h5>
          {data?.data?.map((res, index) => {
            return (
              <div className="mb-6 col-12" key={res?._id}>
                <h6>
                  <span className="me-3">{index + 1}&#41;</span> {res?.question}
                </h6>
                <div className="d-flex">
                {res?.answeroption?.map((i) => {
                  return (
                    <div class="form-check form-check ms-5 " key={i._id}>
                      <input
                        class="form-check-input"
                        name={`questionOptions_${index}`}
                        type="radio"
                        value={i.options}
                        id={`reverseCheck${i._id}`}
                        onChange={() => handleAnswerChange(index, i.options)}
                      />
                      <label
                        htmlFor={`reverseCheck${i._id}`}
                        class="form-check-label"
                      >
                        {i?.options}
                      </label>
                    </div>
                  );
                })}
                </div>
              </div>
            );
          }) }

          <button
            type="submit"
            className="btn btn-sm text-white "
            style={{
              backgroundColor: "#08554e",
              width: "130px",
              height: "40px",
            }}
          >
            Submit
          </button>
        </form>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
    </>
  );
};

export default InvQuestion;
