import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FranchiseSidebar from "../components/franchise/FranchiseSidebar";
import { useSelector } from "react-redux";
import DashboardBanner from "../components/general/DashboardBanner";
import Footer from "../components/Footer";
import axios from "axios";
import { Api_Base_Url } from "../constant/matcher";
import { formatNumberWithCommas } from "../utils/helper";

const CPBusinessPlan = () => {
  const [channelPartner, setChannelPartner] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [inputData, setInputData] = useState({});
  const [successStatus, setSuccessStatus] = useState("fade");
  const [businessData, setBusinessData] = useState([]);
  const [showPlan, setShowPlan] = useState(false)
  const [loading, setLoading] = useState(true)

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const handleInputChange = (event, productId, month, fieldType = null) => {
    const { value } = event.target;
  
    // Check if the entered value is not a negative number
    if (fieldType && (value >= 0 || value === '')) {
      setInputData((prevInputData) => ({
        ...prevInputData,
        [`${productId}-${month}-${fieldType}`]: value,
      }));
    } else if (!fieldType && (value >= 0 || value === '')) {
      setInputData((prevInputData) => ({
        ...prevInputData,
        [`${productId}-${month}`]: value,
      }));
    }
  };

  // Function to calculate the sum for a specific product
  const calculateMutualFundSum = (productId, fieldType) => {
    const productData = inputData;
    let sum = 0;
  
    for (const month of months) {
      if (fieldType) {
        const inputKey = `${productId}-${month}-${fieldType}`;
        if (productData[inputKey]) {
          sum += parseFloat(productData[inputKey]) || 0;
        }
      } else {
        const inputKeySIP = `${productId}-${month}-SIP`;
        const inputKeySIP_LS = `${productId}-${month}-SIP-LS`;
        const inputKeyMRI = `${productId}-${month}-MRI`;
        const inputKeyEquity = `${productId}-${month}-Equity`;
  
        if (productData[inputKeySIP]) {
          sum += parseFloat(productData[inputKeySIP]) || 0;
        }
  
        if (productData[inputKeySIP_LS]) {
          sum += parseFloat(productData[inputKeySIP_LS]) || 0;
        }
        if (productData[inputKeyMRI]) {
          sum += parseFloat(productData[inputKeyMRI]) || 0;
        }
  
        if (productData[inputKeyEquity]) {
          sum += parseFloat(productData[inputKeyEquity]) || 0;
        }
      }
    }
  
    return sum;
  };

  const getChannelPartnerData = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    const role = loginUserData?.role
    axios
      .get(`${Api_Base_Url}/rm/channel-partners?cpId=${id}&role=${role}`)
      .then((res) => {
        setChannelPartner(res?.data?.cpDetails);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const rmId = loginUserData.assignTo
    const id = loginUserData._id;
    // Create the payload structure with channelPartner and monthlyData
    const payload = {
      channelPartner: id,
      monthlyData: [],
    };
    // Loop through months and products to populate monthlyData
  
    months.forEach((month) => {
      const productData = [];
  
      // Loop through your products and add product data
      channelPartner?.product?.forEach((product) => {
        const sipInput = inputData[`${product._id}-${month}-SIP`];
        const sipLsInput = inputData[`${product._id}-${month}-SIP-LS`];
        const equityInput = inputData[`${product._id}-${month}-Equity`];
        const mriInput = inputData[`${product._id}-${month}-MRI`];
  
          productData.push({
            productId: product._id,
            sipAmount: sipInput || 0,
            lumpsumAmount: sipLsInput || 0,
            equityAmount: equityInput || 0,
            mriAmount: mriInput || 0,
          });
        
      });
  
      // Add monthly data if there's at least one product with input data for the month
      if (productData.length > 0) {
        payload.monthlyData.push({
          month: month,
          productData: productData,
        });
      }
    });

    try {
      const response = await axios.post(`${Api_Base_Url}/rm/rmBussinessplan?rmid=${rmId}`, payload);
      // console.log('Data sent successfully:', response.data);
      setSuccessStatus("d-flex")
      setShowPlan(false)
      setSelectedName('')
      setInputData({});
      getBusinessData()
    } catch (error) {
      console.log('Error sending data:', error);
    }
  };

 
  
  const getBusinessData = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    const rmId = loginUserData.assignTo
    axios.get(`${Api_Base_Url}/rm/rmBussinessplan?rmid=${rmId}&cp=${id}`).then((res) => {
      setBusinessData(res?.data[0]?.monthlyData)
      setLoading(false); 
  
      // console.log("response:", res?.data[0]?.monthlyData);
    })
  }


  useEffect(() => {
    getBusinessData()
    getChannelPartnerData()
  }, []);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });


  return (
   <>
   <div className="w-100 overflow-auto">
                <form
                  // onSubmit={onFormSubmit}
                  className="contact-form pt-0 pb-0 mt-4"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                
    <div className="col mt-3 business-plan mb-3">
      {
       !businessData ? (
          <div class="container text-left">
              {
              loading ? (
                <div className="loading">
              <span className="p-3">Loading....</span>
            </div>
              )
              : 
              (
          <div class="row">
             <h5 className="my-3 text-center">
                                      Create your Business Plan for the{" "}
                                      {currentYear}
                                    </h5>
                                    <hr />
            <div class="col mt-2">
              <h6>Months</h6>
              <div className="create-business">
                {months.map((month, index) => (
                  <span key={index} className="mt-3">
                    {month}
                  </span>
                ))}
              </div>
            </div>
            <div class="col mt-2">
                                      <h6>Year</h6>
                                      <div className="create-business">
                                        {months.map((month, index) => (
                                          <span key={index} className="mt-3">
                                            {currentYear}
                                          </span>
                                        ))}
                                      </div>
                                    </div>
            {channelPartner?.product?.map((product) => (
                <div class="col mt-2">
                  <h6>{product.name}</h6>
                  <div className="create-business">
                    {months.map((month, index) => (
                      <div key={index}>
                        {product.name === 'Mutual Fund' ? (
                          <div className="d-flex">
                            <input
                              required
                              className="mb-2 me-2"
                              type="number"
                              name={`${product._id}-${month}-SIP`}
                              id={`${product._id}-${month}-SIP`}
                              placeholder='Enter SIP'
                              onChange={(e) => handleInputChange(e, product._id, month, 'SIP')}
                              onWheel={(e) => e.currentTarget.blur()}
                              value={inputData[`${product._id}-${month}-SIP`] || ''}
                              disabled={
                                months.indexOf(
                                  currentMonth
                                ) >
                                months.indexOf(month)
                              }
                            />
                            <input
                            required
                              className="mb-2"
                              type="number"
                              name={`${product._id}-${month}-SIP-LS`}
                              id={`${product._id}-${month}-SIP-LS`}
                              placeholder='Enter Lumpsum'
                              onChange={(e) => handleInputChange(e, product._id, month, 'SIP-LS')}
                              onWheel={(e) => e.currentTarget.blur()}
                              value={inputData[`${product._id}-${month}-SIP-LS`] || ''}
                              disabled={
                                months.indexOf(
                                  currentMonth
                                ) >
                                months.indexOf(month)
                              }
                            />
                          </div>
                        ) : (
                          <>
                          {
                            product.name === 'Equity Advisory' && (
                          <input
                          required
                          className="mb-2"
                          type="number"
                          name={`${product._id}-${month}-Equity`}
                          id={`${product._id}-${month}-Equity`}
                          placeholder='Enter Equity'
                          onChange={(e) => handleInputChange(e, product._id, month, 'Equity')}
                          onWheel={(e) => e.currentTarget.blur()}
                          value={inputData[`${product._id}-${month}-Equity`] || ''}
                          disabled={
                            months.indexOf(
                              currentMonth
                            ) >
                            months.indexOf(month)
                          }
                          />
                            )}
                          {
                            product.name === 'Monthly Rental Income' && (
                              <input
                              required
                          className="mb-2"
                          type="number"
                          name={`${product._id}-${month}-MRI`}
                          id={`${product._id}-${month}-MRI`}
                          placeholder='Enter MRI'
                          onChange={(e) => handleInputChange(e, product._id, month, 'MRI')}
                          onWheel={(e) => e.currentTarget.blur()}
                          value={inputData[`${product._id}-${month}-MRI`] || ''}
                          disabled={
                            months.indexOf(
                              currentMonth
                            ) >
                            months.indexOf(month)
                          }
                        />
                            )
                          }
                        </>
                        )}
                      </div>
                    ))}
                    {product.name === 'Mutual Fund' ? (
                          <div className="d-flex">
                          <div className="me-4">Total : {calculateMutualFundSum(product._id, 'SIP') || 0}</div>
                          <div className="ms-3">Total : {calculateMutualFundSum(product._id, 'SIP-LS') || 0}</div>
                          </div>
                        ) : (
                          <>
                          {product.name === 'Monthly Rental Income' && (
                            <div className="me-4">Total: {calculateMutualFundSum(product._id, 'MRI') || 0}</div>
                          )}
                            {product.name === 'Equity Advisory' && (
                          <div className="ms-3">Total: {calculateMutualFundSum(product._id, 'Equity') || 0}</div>
                            )}
                          </>
                        )}
                  </div>
                </div>
              ))}
          </div>
              )}
        </div>
        
        )
        : 
        (
          <div className="w-100 overflow-auto ps-3">
             <h5 className="my-3 text-center">
                                  Business Plan for the {currentYear}
                                </h5>
            {
              loading ? (
                <div className="loading">
              <span className="p-3">Loading....</span>
            </div>
              )
              : 
              (
                <table className="table table-striped client__table">
                <thead>
                       <tr>
                       <th>Sr. No.</th>
                       <th scope="col">Month</th>
                       <th>Year</th>
                       <React.Fragment>
                                          {businessData &&
                                            
                                              businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Equity Advisory" &&
                                                    parseFloat(
                                                      data.equityAmount
                                                    ) !== 0
                                                )
                                              ) && <th>Equity Advisory</th>}
                                            
                                          {businessData &&
                                            businessData.some((month) =>
                                              month.productData.some(
                                                (data) =>
                                                  data.productId.name ===
                                                    "Monthly Rental Income" &&
                                                  parseFloat(data.mriAmount) !==
                                                    0
                                              )
                                            ) && <th>Monthly Rental Income</th>}

                                          {businessData && businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Mutual Fund" &&
                                                    parseFloat(
                                                      data.sipAmount
                                                    ) !== 0
                                                )
                                              ) && <th>Mutual Fund</th>}
                                           

                                          {businessData && businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Mutual Fund" &&
                                                    parseFloat(
                                                      data.sipAmount
                                                    ) !== 0
                                                )
                                              ) && <th></th>}
                                          
                                        </React.Fragment>
                     </tr>
                </thead>
                <thead>
                       <tr>
                       <th scope="col"></th>
                       <th scope="col"></th>
                       <th scope="col"></th>
                       
                       <React.Fragment>
                                          {businessData && businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Equity Advisory" &&
                                                    parseFloat(
                                                      data.equityAmount
                                                    ) !== 0
                                                )
                                              ) && <th></th>}
                                           
                                          {businessData && businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Monthly Rental Income" &&
                                                    parseFloat(
                                                      data.mriAmount
                                                    ) !== 0
                                                )
                                              ) && <th></th>}
                                           
                                          {businessData && businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Mutual Fund" &&
                                                    parseFloat(
                                                      data.sipAmount
                                                    ) !== 0
                                                )
                                              ) && <th>SIP</th>}
                                            
                                          {businessData && businessData.some((month) =>
                                                month.productData.some(
                                                  (data) =>
                                                    data.productId.name ===
                                                      "Mutual Fund" &&
                                                    parseFloat(
                                                      data.lumpsumAmount
                                                    ) !== 0
                                                )
                                              ) && <th>Lumpsum</th>}
                                           
                                        </React.Fragment>
                     </tr>
                </thead>
                <tbody>
                {
                   businessData && businessData?.map((result, index) => {
                     return (
                 <tr>
                   <td>{index + 1}</td>
                   <td>{result?.month}</td>
                   <td>{currentYear}</td>
                   {
                     result?.productData?.map((i) => {
                       return (
                        <React.Fragment key={i._id}>
                       
                        {i.equityAmount !== 0 && <td>{formatNumberWithCommas(i.equityAmount)}</td>}
                      {i.mriAmount !== 0 && <td>{formatNumberWithCommas(i.mriAmount)}</td>}
                      {i.sipAmount !== 0 && <td>{formatNumberWithCommas(i.sipAmount)}</td>}
                      {i.lumpsumAmount !== 0 && <td>{formatNumberWithCommas(i.lumpsumAmount)}</td>}
                         
                         </React.Fragment>
                       )
                     })
                   }
                   
                 </tr>
                     )
                     })}
                </tbody>
                </table>
              )
            }
             <button className="reg-btn mt-2" style={{float: "right"}} 
            onClick={() => setBusinessData(!businessData)}
            > <i className="fa-solid fa-pen-to-square fa-2xs"></i></button>
          </div>
        )
      }
     
    
    </div>

{
  !businessData && (
    <button className="reg-btn mt-3" style={{float: "right"}}>Submit</button>
  )
}
                </form>
              </div>
   </>
  )
}

export default CPBusinessPlan