import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useSendNewsLetterMutation } from '../../store/services/loginService';

function NewsLetter () {
  const [email, setEmail] = useState('');
  const [successStatus, setSuccessStatus] = useState('fade')

  const closeSuccess = () => {
    setSuccessStatus('fade')
  }

  const [sentMail, response={}] = useSendNewsLetterMutation()
  useEffect(() => {
    // console.log(response?.data)
    if(
      response?.isSuccess &&
      !response?.isLoading &&
      response.status === 'fulfilled' &&
      !response?.isError &&
      response?.data?.success 
    ) {
      setEmail('')
      setSuccessStatus('d-flex')
    }
  }, [response]);
  const submitHandler = (e) => {
      e.preventDefault()
      if(email !== '') {
        sentMail({email: email})
      }
  }
  return (
    <>
      <div className='news p-3' style={{ background: 'aliceblue' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 d-flex align-items-center'>
              <h1 className='news-heading mx-auto m-0'>Subscribe To Our Newsletter.</h1>
            </div>
            <div className='col-lg-6'>
              <form action='' onSubmit={submitHandler}>
                <input
                  type='email'
                  maxLength='50'
                  required
                  className='landing-email-input'
                  placeholder='Enter your email address'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className='bt'>Subscribe</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      
        {/* <!-- Success Modal Start --> */}
        <div
          className={`modal custom-modal align-items-center ${successStatus}`}
          style={{ background: 'rgba(0,0,0,0.3)' }}
          id='formSuccessModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='formSuccessModalLabel'
          aria-hidden='true'
          >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header success'>
                <h5 className='modal-title' id='formSuccessModalLabel'>
                  Success
                </h5>
                <button
                  type='button'
                  className='close'
                  onClick={closeSuccess}
                  // data-bs-dismiss='modal'
                  // aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='form-popup-inner'>
                  <div className='form-icon success'>
                    <svg
                      version='1.1'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 130.2 130.2'
                    >
                      <circle
                        className='path circle'
                        fill='none'
                        strokeWidth='6'
                        strokeMiterlimit='10'
                        cx='65.1'
                        cy='65.1'
                        r='62.1'
                      />
                      <polyline
                        className='path check'
                        fill='none'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeMiterlimit='10'
                        points='100.2,40.2 51.5,88.8 29.8,67.5 '
                      />
                    </svg>
                  </div>
                  <div className='form-body'>
                    <p>
                      Thank you for filling our form. We will keep your information safe. 
                    </p>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <Link
                  to='#'
                  className='popup-btn success'
                  // data-bs-dismiss='modal'
                  onClick={closeSuccess}
                >
                  Okay
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Success Modal End --> */}
    </>
  )
}

export default NewsLetter
