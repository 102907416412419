import React from 'react'

function NewMeetings (props) {
  return (
    <>
      <div className='franchise_amnt_dashboardInfo land_card_2'>
        <div className='wrap'>
          <div className='franchise_inve'>
            <div className='card_6_cont'>
              <div className='franchise_inve_content mb-3'>
                <h6 className='inve_cont_main text-start'>New Investment</h6>
                <p className='inve_cont_main'>{props.newInvestment}</p>
              </div>
              <div className='franchise_inve_content'>
                <h6>Equity</h6>
                <p>{props.equity}</p>
              </div>
              <div className='franchise_inve_content'>
                <h6>MF-SIP</h6>
                <p>{props.MFSIP}</p>
              </div>
              <div className='franchise_inve_content'>
                <h6>MF-Lumpsum</h6>
                <p>{props.MFLump}</p>
              </div>
              <div className='franchise_inve_content'>
                <h6>Real Estate</h6>
                <p>{props.RealEstate}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewMeetings
