import React from 'react'
import { useSubCategorySelectQuery } from '../../../store/services/loginService.js'

function Readonlycategory({ subCategory, onSubCategoryChange }) {

  const subcategoryResp = useSubCategorySelectQuery()
  const professionSelectData =
    subcategoryResp?.isSuccess &&
    !subcategoryResp?.isLoading &&
    subcategoryResp.status === 'fulfilled' &&
    !subcategoryResp?.isError &&
    subcategoryResp?.data?.subCategoryList

    const handleSelectChange = (event) => {
      const selectedValue = event.target.value;
      onSubCategoryChange(selectedValue);
    };
   
      
  return (
  <div className='row my-3'>
  <div className='form-group'>
    {subCategory ? (
    
    <select className='form-select subCategorySelect' value={subCategory}
    >
  {professionSelectData &&
    professionSelectData?.map((item, index) => {
      return (
        <option key={index} value={item?._id} >
          {item?.name} 
        </option>
      )
    })}
    </select>
    ) : (
      // Render the select input when subCategory is empty
      <select
        className='form-select subCategorySelect'
        value={subCategory}
        onChange={handleSelectChange}
      >
        <option value=''>Select a subcategory</option>
        {professionSelectData &&
          professionSelectData?.map((item, index) => {
            return (
              <option key={index} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
      </select>
    )}
  </div>
</div>


  )
}

export default Readonlycategory
