import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { PUBLIC_PATH } from '../../constant/matcher'
import Sidebar from '../../components/Sidebar'
import ListingCard from '../../components/ListingCard'
import Footer from '../../components/Footer'
import VideoListing from '../../components/VideoListing'
import VideoBoxes from '../../components/VideoBoxes'
import DashboardBanner from '../../components/general/DashboardBanner'
import { useGetSingleProductsApiQuery } from '../../store/services/dashboardService'
import Loader from '../../components/general/Loader'

function ListingPage () {
  const [navShow, setNavShow] = useState(true)
  const [SmallWrapper, setSmallWrapper] = useState(' small-wrapper ')
  const [navSideHide, setNavSideHide] = useState(true)

  const params = useParams()

  const singleProductApi = useGetSingleProductsApiQuery(params?.categoryid)

  // serverpath for dynamic images
  const serverPath =
    singleProductApi &&
    singleProductApi?.isSuccess &&
    !singleProductApi?.isLoading &&
    singleProductApi.status === 'fulfilled' &&
    !singleProductApi?.isError &&
    singleProductApi?.data?.path

    // product data iterate from api
  const singleProData =
    singleProductApi &&
    singleProductApi?.isSuccess &&
    !singleProductApi?.isLoading &&
    singleProductApi.status === 'fulfilled' &&
    !singleProductApi?.isError &&
    singleProductApi?.data?.product
  // useEffect(() => {
  //   console.log(singleProData);
  // }, [singleProData]);

  // navigation content wrapper handler
  const navContWrapp = boolData => {
    setNavShow(boolData)
  }

  return (
    <>
      <Sidebar navContWrapp={navContWrapp} />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper  ${SmallWrapper}`}
        style={{ paddingLeft: navShow ? '230px' : '70px' }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className='breadcrumbs'>
          <div className='container'>
            <p>
              <Link to='/dashboard'>Dashboard</Link>{' '}
              {/* <i className='fa-solid fa-angle-right'></i>{' '}
              <Link to='/dashboard'>Home</Link>{' '} */}
              <i className='fa-solid fa-angle-right'></i> Details{' '}
              <i className='fa-solid fa-angle-right'></i> {singleProData?.topic}
            </p>
          </div>
        </div>

        <div className='container content-wrapper  listing-page-sec inner-bg'>
          {/* <div className='row inner-content py-2 mt-4'>
              <div className=''>
                <div className='sec-title'>
                  <h2 className="m-0">Course Detail</h2>
                </div>
              </div>
            </div> */}
          <div className='row inner-content pt-2'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-md-6 courses-desc'>
                  <h2>
                    {singleProData?.topic}
                    {singleProductApi?.isLoading && <Loader />}
                  </h2>
                  <img
                    src={serverPath + singleProData?.image}
                    className=' d-block d-md-none text-center mx-auto rounded my-3 listing_abt_img'
                    style={{
                      width: '100%',
                      maxHeight: '210px',
                      boxShadow: '3px 3px 10px rgba(0,0,0,0.3)'
                    }}
                    alt='img'
                  />
                  <p className='listing_main_p'>{singleProData?.description}</p>
                </div>
                <div className='col-md-6 tex-center mt-1'>
                  <img
                    src={serverPath + singleProData?.image}
                    className=' d-none d-md-block text-center mx-auto rounded'
                    style={{
                      width: '100%',
                      maxWidth: '350px',
                      maxHeight: '210px',
                      boxShadow: '3px 3px 10px rgba(0,0,0,0.3)'
                    }}
                    alt='img'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {singleProData && singleProData?.pdf?.length > 0 && (
          <div className='container content-wrapper listing-page-sec inner-bg'>
            <div className='row inner-content'>
              <div className='col-12'>
                <div className='mt-2 mb-3'>
                  <div className='sec-title_list sec-title'>
                    <h2>Course PDFs</h2>
                  </div>
                </div>
              </div>

              {/* <div className='list-card-container'> */}
              {singleProData &&
                singleProData?.pdf?.map((pdfLi, index) => {
                  return (
                    <div
                      className='col-sm-6 col-md-4 list-card-container'
                      key={index}
                    >
                      <ListingCard
                        cardTitle={pdfLi?.pdfTitle}
                        // cardDesc={singleProData?.pdf?.pdfTitle}
                        completedLink={'#'}
                        srcLink={serverPath + pdfLi?.pdfUrl}
                        thmbImg={PUBLIC_PATH + 'assets/img/pdf-bg.png'}
                      />
                    </div>
                  )
                })}
              {singleProData?.pdf?.length === 0 && (
                <div className='text-secondary'>Pdf Not Available</div>
              )}
              {singleProductApi?.isLoading && <Loader />}
            </div>
          </div>
        )}
        {singleProductApi?.isLoading && (
          <div className='container content-wrapper video-listing listing-page-sec inner-bg'>
            <div className='row inner-content'>
              <Loader />
            </div>
          </div>
        )}

        {singleProData && singleProData?.videos?.length > 0 && (
          <div className='container content-wrapper video-listing listing-page-sec inner-bg'>
            <div className='row inner-content'>
              <div className='col-12'>
                <div className='mt-2 mb-3'>
                  <div className='sec-title_list sec-title'>
                    <h2>Course Videos</h2>
                  </div>
                </div>
              </div>
              {singleProData &&
                singleProData?.videos?.map((videoLi, index) => {
                  return (
                    <div
                      className='col-sm-6 col-md-4 mb-3 video-listing-sec'
                      key={index}
                    >
                      <VideoBoxes
                        videoTitle={videoLi?.videoTitle}
                        videoLink={videoLi?.videoUrl}
                        videoImgSrc={PUBLIC_PATH + 'assets/img/team/user2.jpg'}
                        completedLink={''}
                        videoSrc={videoLi?.videoUrl}
                      />
                    </div>
                  )
                })}
              {singleProData?.videos?.length === 0 && (
                <div className='text-secondary'>Video Not Available</div>
              )}
              {singleProductApi?.isLoading && <Loader />}
            </div>
          </div>
        )}
        {singleProductApi?.isLoading && (
          <div className='container content-wrapper video-listing listing-page-sec inner-bg'>
            <div className='row inner-content'>
              <Loader />
            </div>
          </div>
        )}

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={'/'}
          home={'/#home'}
          about={'/#about'}
          service={'/#service'}
          faq={'/#faq'}
          testimonial={'/#testimonials'}
        />
      </section>
    </>
  )
}

export default ListingPage
