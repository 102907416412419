import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { PUBLIC_PATH } from "../constant/matcher";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../styles/loginFormStyle.css";
import {
  useGetNewOtpMutation,
  useUserTypeSelectQuery,
} from "../store/services/loginService";
import { useCompleteProfileMutation } from "../store/services/dashboardService.js";
import { useEventQrDetailsFetchQuery } from "../store/services/fetchApiService";
import OtpInput from "../components/general/OtpInput";
import {
  setLoginUserToken,
  loggedInUserData,
  setQrDetails,
} from "../store/reducer/authReducer";
import { useDispatch } from "react-redux";

function LoginScreen(props) {
  const [validCred, setValidCred] = useState(false);
  const [loginMobileValidation, setLoginMobileValidation] = useState(false);
  const [otpValidation, setOtpValidation] = useState("");
  const [otpSuccess, setOtpSuccess] = useState("");
  const [slideClass, setSlideClass] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [userTypeCate, setUserTypeCate] = useState("");
  const [blankFields, setBlankFields] = useState(null);
  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //  to save otp from response
  const [OtpRespValue, setOtpRespValue] = useState(null);

  const { loginuser } = useParams();

  // -======get query params=======
  const [searchParams, setSearchParams] = useSearchParams();
  const scanId = searchParams.get("qid");
  const qrType = searchParams.get("type");
  const qrFor = searchParams.get("for");

  
  // ======= Get Event QR prefield details
  const [qrEventDeatils, setQrEventDeatils] = useState();
  const getEventDetailsResp = useEventQrDetailsFetchQuery(scanId);
  
  // =====completeProfileFields======
  const [compProfiData, setCompProfiData] = useState({
    id: "",
    name: "",
    product: [],
    subCategory: "",
    userType: "",

  });

  // post complete profile api
  const [postMorePofileData, completeProResp = {}] =
    useCompleteProfileMutation();

  useEffect(() => {
    // console.log(completeProResp);
    if (
      
      completeProResp?.isSuccess &&
      !completeProResp?.isLoading &&
      !completeProResp?.isError &&
      completeProResp?.data?.success
    ) {
      localStorage.setItem(
        "loginUserData",
        JSON.stringify(completeProResp?.data?.data)
      );
      dispatch(loggedInUserData(completeProResp?.data?.data));
      navigate("/dashboard");
    }
  }, [completeProResp]);

  useEffect(() => {
    if (getEventDetailsResp?.isSuccess && getEventDetailsResp?.data?.success) {
      setQrEventDeatils(getEventDetailsResp?.data?.eventData);
      setCompProfiData({
        ...compProfiData,
        product: getEventDetailsResp?.data?.eventData?.product,
        subCategory: getEventDetailsResp?.data?.eventData?.subCategory,
        userType: getEventDetailsResp?.data?.eventData?.category,
       
      }); 
    }
  }, [getEventDetailsResp]);

  const [timerSeconds, setTimerSeconds] = useState(10);
  useEffect(() => {
    if (slideClass) {
      const timer = setInterval(() => {
        setTimerSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      if (timerSeconds === 0) {
        setShowResend(true);
        clearInterval(timer);
      }
      // Clean up the interval when the component is unmounted
      return () => clearInterval(timer);
    }
  }, [slideClass, timerSeconds]);

  // API FOR USERTYPE
  const userTypeResp = useUserTypeSelectQuery();
  const userTypeData =
    userTypeResp?.isSuccess &&
    !userTypeResp?.isLoading &&
    userTypeResp.status === "fulfilled" &&
    !userTypeResp?.isError &&
    userTypeResp?.data?.categoryList;

  useEffect(() => {
    setUserTypeCate(
      loginuser && loginuser.toLowerCase() === "investor"
        ? userTypeData[1]?._id
        : userTypeData[0]?._id
    );
  }, [loginuser, userTypeData]);

  // login data
  const [otpInptVal, setotpInptVal] = useState();
  const [loginDetails, setLoginDetails] = useState({
    phone: "",
  });

  // to get otp number form all otp inputs
  const getOtpVal = (otpval) => {
    setotpInptVal(otpval);
  };

  const loginChangeHandler = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };

  //   api for otp
  const [optPhone, otpResp = {}] = useGetNewOtpMutation();

  // handler of otp response
  useEffect(() => {
    if (
      otpResp?.isSuccess &&
      otpResp.status === "fulfilled" &&
      !otpResp?.isError &&
      otpResp?.data.message &&
      !otpResp?.data?.otp
    ) {
      if (!slideClass) {
        setOtpValidation("Already generated please check your sms");
      }
      setTimeout(() => {
        setOtpValidation("");
      }, 5000);
    }

    if (
      otpResp?.isSuccess &&
      !otpResp?.isLoading &&
      otpResp.status === "fulfilled" &&
      !otpResp?.isError &&
      otpResp?.data?.otp
    ) {
      setOtpRespValue(otpResp?.data?.otp);
      setOtpSuccess(otpResp?.data.message);
      setSlideClass(true);
      setTimeout(() => {
        setOtpSuccess("");
      }, 5000);
    }
  }, [otpResp]);

  // change number handler
  const changeNumber = () => {
    setSlideClass(false);
    const phoneInpt = document.getElementById("optMobile");
    if (phoneInpt) {
      phoneInpt.focus();
    }
  };

  //  resend otp handler
  const resendOtp = () => {
    let numForOtp = {}
    numForOtp.phone = loginDetails.phone
    numForOtp.userType = userTypeCate

    if (loginDetails.phone !== '') {
      setShowResend(false)
      setTimerSeconds(10)
      optPhone(numForOtp)
    } else {
      setBlankFields('Please try again')
    }
  }

  //   submit otp form
  const submitNumbForOtp = (e) => {
    let numForOtp = {}
    numForOtp.phone = loginDetails.phone
    numForOtp.userType = userTypeCate

    e.preventDefault();
    if (loginDetails?.phone?.length === 10) {
      setLoginMobileValidation(false);
      // optPhone({ phone: loginDetails.phone });
      optPhone(numForOtp)
      setShowResend(false);
      setTimerSeconds(10);
    } else {
      setLoginMobileValidation(true);
      setTimeout(() => {
        setLoginMobileValidation(false);
      }, 5000);
    }
  };

  // LOGIN API
  const [loginWithOpt, loginResp = {}] = useGetNewOtpMutation();

  // login data response handler
  useEffect(() => {
    if (
      loginResp?.isSuccess &&
      !loginResp?.isLoading &&
      loginResp.status === "fulfilled" &&
      !loginResp?.isError
    ) {
      localStorage.removeItem('user_Fm_Rm-token')
      localStorage.setItem("user-token", loginResp?.data?.token);
      localStorage.setItem(
        "loginUserData",
        JSON.stringify(loginResp?.data?.user)
      );
      localStorage.setItem("qid", scanId);
      localStorage.setItem("qrType", qrType);   
      localStorage.setItem("eventId", scanId)
      dispatch(loggedInUserData(loginResp?.data?.user));

      dispatch(setLoginUserToken(loginResp?.data?.token));
      // storing event qr data
      // if (qrType === "event") {
      //   // post data to complete profile=====
      //   let completeProfileFields = compProfiData;
      //   completeProfileFields.id = loginResp?.data?.user?._id;

      //   postMorePofileData(completeProfileFields);
      //   console.log(completeProfileFields)
      //   console.log(postMorePofileData);

      //   //   localStorage.setItem('qrdetails', JSON.stringify(getEventDetailsResp?.data?.eventData))
      //   // dispatch(setQrDetails(getEventDetailsResp?.data?.eventData))
      // } else {
      //   navigate("/dashboard");
      // }
      // else {
      //   localStorage.removeItem("qrdetails");
      // }
      navigate('/dashboard')
    }
  }, [loginResp]);

  // final submit
  const loginSubmit = (e) => {
    e.preventDefault();
    var finalData = loginDetails;
   
    if (qrType === "event") {
      finalData.userType = qrEventDeatils?.category;
    } else if (qrFor && qrFor === "cp") {
      finalData.userType = userTypeData[0]?._id;
    } else if (qrFor && qrFor === "ivt") {
      finalData.userType = userTypeData[1]?._id;
    } else {
      finalData.userType = '64535fd33384aae2be60bb79';
    }
    finalData.otp = otpInptVal;
    finalData.qid = scanId;
    finalData.qrType = qrType;

    if (finalData.otp && finalData.otp.length === 6) {
      setBlankFields(null);
      loginWithOpt(finalData);
      
    } else {
      setBlankFields("Please enter 6 digit otp");
      setTimeout(() => {
        setBlankFields(null);
      }, 5000);
    }
  };
  
  useEffect(() => {
    if (window.screen.width >= 768) {
      window.scrollTo({ top: 350, left: 0, behavior: "instant" });
    }
    if (window.screen.width < 768) {
      window.scrollTo({ top: 280, left: 0, behavior: "instant" });
    }

    let mobInpt = document.getElementById("optMobile");
    if (mobInpt) {
      mobInpt.oninput = () => {
        if (mobInpt.value.length > mobInpt.maxLength)
          mobInpt.value = mobInpt.value.slice(0, mobInpt.maxLength);
      };
    }
  }, []);

  const otpInputValidColor = () => {
    setOtpValidation(false)
  }

  return (
    <>
      <Header
        home="/"
        about="/#about"
        features="/#features"
        service="/#service"
        testimonial="/#testimonials"
        faq="/#faq"
      />

      {/* hero section */}
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row gy-4 d-flex justify-content-between">
            <div className="hero-form col-md-7 d-flex flex-column justify-content-center position-relative">
              <h2 className="text-uppercase hero-heading">
                Take a First Step Towards Your Independent Financial Journey
              </h2>
              <div className="banner-text">
                <h3 className="mt-4 text-center text-white text-uppercase">
                  Unlock Potential Of <br /> Unlimited Passive Income
                </h3>
              </div>
            </div>

            <div className="d-none d-md-block col-md-5 col-lg-5 hero-img">
              <img
                src={PUBLIC_PATH + "assets/img/hero-img.svg"}
                className="img-fluid mb-3 mb-lg-0"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className="px-4 main d-flex align-items-center justify-content-center"
        style={{ background: "whitesmoke" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 p-0 d-flex align-items-center justify-content-center">
              <div
                className="container form-slider-container scanNewForm"
                id="container"
              >
                <div
                  className="form-container sign-up-container"
                  id="sign-up-container"
                ></div>
                <div
                  className="form-container form_by_scan sign-in-container"
                  id="sign-in-container"
                >
                  <div className="img_wrapper">
                    <img
                      src={PUBLIC_PATH + "assets/img/loginForm.jpg"}
                      alt="signup"
                      className="img-fluid"
                    />
                  </div>
                  <div
                    className={`forms__container ${slideClass ? "slide" : ""}`}
                  >
                    <form
                      onSubmit={submitNumbForOtp}
                      className="num__form_slide"
                    >
                      <div className="para_tags position-relative">
                        <p>Enter your 10-digit Mobile Number</p>
                        {/* <p>
                          Enter your 10-digit mobile number
                        </p> */}
                      </div>

                      {/* valid credentials */}
                      {validCred && (
                        <p className="mb-2 text-danger text-center">
                          Please Enter Valid credentials !
                        </p>
                      )}
                      <div className="numb_opt position-relative w-100 my-2">
                        <input
                          type="number"
                          id="optMobile"
                          maxLength="10"
                          name="phone"
                          placeholder="Mobile Number *"
                          required
                          value={loginDetails.phone}
                          onChange={loginChangeHandler}
                        />
                        <span className="country_code position-absolute">
                          +91
                        </span>
                      </div>
                      <button className="otp_send_btn">
                        {otpResp?.isLoading ? "Continue..." : "Continue"}
                        {/* Send OTP */}
                      </button>
                      {/* mobile validation */}
                      {loginMobileValidation && (
                        <p className="text-danger text-start w-100">
                          Please put 10 digit mobile number !
                        </p>
                      )}
                      {otpValidation && (
                        <p className="text-danger text-start w-100 mt-2">
                          {otpValidation}
                        </p>
                      )}
                    </form>

                    <form
                      onSubmit={loginSubmit}
                      className="otp__form_slide pt-0 pb-0"
                    >
                      <div className="otp__para text-start mb-2">
                        <p className="m-0">
                          Enter OTP sent to {loginDetails.phone}
                        </p>
                        <span
                          className="reset__otp__form text-uppercase"
                          onClick={changeNumber}
                        >
                          Change Number
                        </span>
                      </div>

                      {/* OTP Fields desgin */}
                      <OtpInput
                        getOtpVal={getOtpVal}
                        otpInputValid={otpInputValidColor}
                      />

                      <div className="resend__opt ms-1 mb-1">
                        {showResend && (
                          <span
                            onClick={resendOtp}
                            className="resend__otp text-uppercase"
                          >
                            Resend OTP
                          </span>
                        )}
                        {!showResend && (
                          <span>
                            Resend OTP in{" "}
                            <span id="resendTimer">{timerSeconds}s</span>
                          </span>
                        )}
                      </div>
                      <button className="otp_send_btn mt-2">
                        {loginResp?.isLoading ? "Verifying..." : "Verify OTP"}
                      </button>
                      {blankFields && (
                        <p className="text-danger text-start w-100 m-0">
                          {blankFields}
                        </p>
                      )}
                    </form>
                  </div>
                </div>
                <div className="overlay-container">
                  <div className="overlay">
                    <div className="overlay-panel overlay-left">
                      <h1>Hello, Friend!</h1>
                      <p>
                        Enter your personal details and start journey with us
                      </p>
                    </div>
                    <div className="overlay-panel overlay-right">
                      <h1>Welcome !</h1>
                      <p>
                        To keep connected with us please login/signup with your
                        personal info
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Footer ======= --> */}
      <Footer
        logoLink="/"
        home="/"
        about="/#about"
        features="/#features"
        service="/#service"
        testimonial="/#testimonial"
        faq="/#faq"
      />
      {/* <!-- End Footer --> */}
    </>
  );
}

export default LoginScreen;
