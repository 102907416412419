import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRegisterNowApiMutation } from "../../store/services/registerApiService";
import { Api_Base_Url, PUBLIC_PATH } from "../../constant/matcher";
import "../../styles/inquiryFormStyle.css";
import Agreement from "./Agreement";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

function RegisterNowPopup(props) {
  const userData = useSelector((state) => state.userReducer.userData);
  const [checkValidation, setCheckValidation] = useState("");
  const [isProductMF, setIsProductMF] = useState(false);
  const [ifNoArn, setIfNoArn] = useState(false);
  const [ifArn, setIfArn] = useState(false);
  const [blankInputFileds, setBlankInputFileds] = useState(false);
  const [slideScreen, setSlideScreen] = useState(false);
  const [OptVerifyScreen, setOptVerifyScreen] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [panNumberError, setPanNumberError] = useState(false);
  const [newformData, setNewFormData] = useState([]);
  const [signatureUrl, setSignatureUrl] = useState(null);
  const [showButtons, setShowButtons] = useState(true);
  const [isDisableBtn, setIsDisableBtn] = useState(true);
  const [declineAgreement, setDeclineAgreement] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);

  const sixDigitOtpLimit = () => {
    let mobInpt = document.getElementById("otp__input_field");
    if (mobInpt.value.length > mobInpt.maxLength)
      mobInpt.value = mobInpt.value.slice(0, mobInpt.maxLength);
  };

  const panRef = useRef();
  const adharRef = useRef();
  const signRef = useRef();
  const chequeRef = useRef();
  
  const [panLabel, setPanLabel] = useState("No file Chosen");
  const [aadharLabel, setAadharLabel] = useState("No file Chosen");
  const [chequeLabel, setChequeLabel] = useState("No file Chosen");
  const [signLabel, setSignLabel] = useState("No file Chosen");
  const [timerSeconds, setTimerSeconds] = useState(10);
  useEffect(() => {
      const timer = setInterval(() => {
        setTimerSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      if (timerSeconds === 0) {
        setShowResend(true);
        clearInterval(timer);
      }
      // Clean up the interval when the component is unmounted
      return () => clearInterval(timer);
  }, [timerSeconds]);
  // const panCapitalize = () => {
  //   const panVal = document.getElementById("panNumber");
  //   if (panVal) {
  //     panVal.value = panVal.value.toUpperCase();
  //   }
  // };

  // =====ARN Handler=======
  useEffect(() => {
    if (userData) {
      let MF = userData?.product.includes("64535ad13384aae2be5d34ba");
      if (MF === true) {
        setIsProductMF(true);
      } else {
        setIsProductMF(false);
      }
    }
  }, []);
  // if product is mutual fund
  const arnCheckHandler = (e) => {
    setBlankInputFileds(false);
    let arnNumber = e.target.value;
    let arnCheckBox = document.getElementsByClassName("arnCheck");
    arnCheckBox = Array.from(arnCheckBox);
    arnCheckBox.forEach((element) => {
      element.checked = false;
    });
    e.target.checked = true;
    if (arnNumber === "yes") {
      setIfArn(true);
      setIfNoArn(false);
    } else {
      setIfArn(false);
      setIfNoArn(true);
    }
  };

  const loginUserData = JSON.parse(
    localStorage.getItem("loginUserData")
  );

  const [selectedFile, setSelectedFile] = useState({
    name: loginUserData?.name,
    panNumber: "",
    address: "",
    panCard: "",
    aadharCard: "",
    cheque: "",
    signature: "",
    leadUserId: "",
    termsContidions: "",
    arnNumber: "",
    // termsContidions: "",
  });

  const fileChangeHandler = (e) => {
    let filename = e.target.files;
    setSelectedFile({ ...selectedFile, [e.target.name]: filename[0] });
    // to set filename in dummy inputs
    
    e.target.name === "panCard" && setPanLabel(filename[0].name);
    e.target.name === "aadharCard" && setAadharLabel(filename[0].name);
    e.target.name === "signature" && setSignLabel(filename[0].name);
    e.target.name === "cheque" && setChequeLabel(filename[0].name);
  };

  useEffect(() => {
    setSelectedFile({ ...selectedFile, leadUserId: userData._id });
  }, [userData]);

  // register now api
  const [postRegData, resp = {}] = useRegisterNowApiMutation();

  useEffect(() => {
    if (resp && !resp?.isLoading && resp?.isSuccess && resp?.data?.success) {
      setSuccessStatus("d-flex");
    }
  }, [resp]);

  // Convert html to pdf and download pdf

  const handleDownloadPDF = async () => {
    const element = document.getElementById("pdf-content");
    const pages = Array.from(element.children);

    // Create a new jsPDF instance
    const pdf = new jsPDF("p", "pt", "a4");

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];

      // Wait for html2canvas to render the page
      const canvas = await html2canvas(page);
      // Convert canvas to base64 image data
      const imgData = canvas.toDataURL("image/jpeg", 0.7);
      // console.log(imgData);

      // Add page to the PDF
      if (i !== 0) {
        pdf.addPage();
      }

      // Adjust font size as needed
      const fontSize = " 8px";
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Apply font size and add the image to the PDF
      pdf.setFontSize(fontSize);
      pdf.addImage(imgData, "jpeg", 0, 0, pageWidth, pageHeight);
    }

    // Save the PDF
    const blob = new Blob([pdf.output("blob")], {
      type: "application/pdf",
    });
    return blob;
  };

  // const handleView = () => {
  //   setShowSpinner(true);
  //   handleDownloadPDF();
  // };

  const regSubmitHandler = async (e) => {
    e.preventDefault();
    if (!slideScreen) {
      if (selectedFile.termsContidions === true) {
        if (selectedFile.signature !== "") {
          setCheckValidation("");
          const panRegex = /^(?=.{1,10}$)[A-Z]{5}[0-9]{1,4}[A-Z]{1}$/;
          if (!panRegex.test(selectedFile.panNumber)) {
            setCheckValidation("Please enter a valid PAN number");
            setShowSpinner(false);
            setTimeout(() => {
              setCheckValidation("");
            }, 5000);
            return;
          }
          const loginUserData = JSON.parse(
            localStorage.getItem("loginUserData")
          );
          const leadUserId = loginUserData?.lmsId;
          const usertype = loginUserData?.userType
          // Make the API request to check if PAN number already exists
          const queryParams = `?pan=${encodeURIComponent(
            selectedFile.panNumber
          )}&leadUserId=${encodeURIComponent(leadUserId)}&userType=${encodeURIComponent(usertype)}`;
          const apiUrl = `${Api_Base_Url}/register/checkexistpan${queryParams}`;
          fetch(apiUrl, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((result) => {
              // Check if PAN number already exists
              if (result.success === false) {
                setCheckValidation("PAN Number already registered");
                setShowSpinner(false);
                setTimeout(() => {
                  setCheckValidation("");
                }, 5000);
                return;
              } else {
                const formData = new FormData();
                formData.append("panNumber", selectedFile.panNumber);
                formData.append("agreementname", selectedFile.name);
                formData.append("address", selectedFile.address);
                formData.append("panCard", selectedFile.panCard);
                formData.append("aadharCard", selectedFile.aadharCard);
                formData.append("cheque", selectedFile.cheque);
                formData.append("signature", selectedFile.signature);
                const loginUserData = JSON.parse(
                  localStorage.getItem("loginUserData")
                );
                formData.append("leadUserId", loginUserData?._id || "");
                if (isProductMF === true) {
                  let arnCheckBox = document.getElementsByClassName("arnCheck");
                  arnCheckBox = Array.from(arnCheckBox);
                  if (!arnCheckBox[0].checked === !arnCheckBox[1].checked) {
                    setBlankInputFileds(true);
                    return;
                  } else {
                    setBlankInputFileds(false);
                  }
                  formData.append("arnNumber", selectedFile.arnNumber);
                }

                setNewFormData(formData);
                // console.log("FormData entries:");
                // for (const entry of formData.entries()) {
                //   console.log(entry);
                // }
                const signatureUrl = URL.createObjectURL(
                  selectedFile.signature
                );
                setSignatureUrl(signatureUrl);
                // Move to the slide screen
                setShowSpinner(false);
                setSlideScreen(true);
              }
            })
            .catch((error) => {
              // Handle error
              console.error(error);
            });
        } else {
          setCheckValidation("Please upload your signature");
          setTimeout(() => {
            setCheckValidation("");
          }, 5000);
        }
      } else {
        setCheckValidation("Please accept terms & conditions");
        setTimeout(() => {
          setCheckValidation("");
        }, 5000);
      }
    }
  };

  const handleClick = async () => {
    setDeclineAgreement(false);
    setOptVerifyScreen(true);
    setShowButtons(false);
    try {
      // Make the API request
      const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
      const leadUserId = loginUserData?._id;

      const data = {
        leadUserId: leadUserId,
      };

      const response = await fetch(`${Api_Base_Url}/register/generate-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      // Check if the API request was successful
      if (result.success) {
        // console.log(result.message);
      } else {
        // console.error(result.message);
      }
    } catch (error) {
      // Handle any errors during the API request
      // console.error(error);
    }
  };

  const handleVerify = () => {
    // Make the API request to verify the OTP
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const leadUserId = loginUserData?._id;

    // Prepare the request body
    const verifyData = {
      leadUserId: leadUserId,
      otp: otp,
    };

    fetch(`${Api_Base_Url}/register/verify-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(verifyData),
    })
      .then((response) => response.json())
      .then((result) => {
        // Handle the OTP verification API response
        console.log(result);

        // Check if OTP verification was successful
        if (result.success) {
          // Call the existing onClick event handler
          postRegData(newformData).then(() => {
              successPopupHandler();
          });

          console.log(newformData);
        } else {
          // Handle unsuccessful OTP verification
          setOtpError(true);
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  const [successStatus, setSuccessStatus] = useState("fade");
  const successPopupHandler = () => {
    if (!panNumberError) {
      setSuccessStatus("d-flex");
    }
  };
  const closeSuccessModal = () => {
    setSuccessStatus("fade");
    props.closeRegPopup();
  };

  const handleDecline = () => {
    setSuccessStatus("fade");
    props.closeRegPopup();
  };

  //  resend otp handler
const resendOtp = () => {
  const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
  const leadUserId = loginUserData?._id;

  const data = {
    leadUserId: leadUserId,
  };
    const response = axios.post(
      `${Api_Base_Url}/register/generate-otp`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setShowResend(false);
    setTimerSeconds(10);
   
};

  // const downloadAsPDF = async () => {
  //   const pdfBlob = await handleDownloadPDF();

  //   // Create a temporary link element to trigger the download
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = URL.createObjectURL(pdfBlob);
  //   downloadLink.download = "agreement.pdf";
  //   downloadLink.click();
  // };

  // capitalize
  const alphabetsCapitalize = (str) => str.toUpperCase();

  // Enable view agreement
  useEffect(() => {
    const element = document.getElementById("viewAgreementBtn");

    // Check if all required fields are filled and terms & conditions are accepted
    if (
      selectedFile.panNumber !== "" &&
      selectedFile.address !== "" &&
      selectedFile.signature !== "" &&
      selectedFile.termsContidions === true
    ) {
      // Enable the "View Agreement" button only when all conditions are met
      setIsDisableBtn(false);
      element.style.backgroundColor = "#ff4b2b";
    } else {
      // Disable the "View Agreement" button only when all conditions are met
      setIsDisableBtn(true);
      element.style.backgroundColor = "grey";
      element.style.borderColor = "grey";
    }
  }, [selectedFile]);

  return (
    <>
      <div className="form_popups popup__container">
        <div
          className={`registerNowSlide chagne_pass_form overflow-hidden pt-3 ${
            slideScreen ? "moreTabAgreeent" : ""
          }`}
          // style={{ maxHeight: "500px", overflowY: "auto" }}
        >
          <div className="container register_form__container">
            <div className="row">
              <div className="col-12 d-flex align-items-center position-relative justify-content-center">
                <div className="fixing-position">
                  <div
                    className="formByScan_container cp__regNow_form CreatLeadFormWrapper"
                    id="formByScan_container"
                    style={{ overflow: "visible", padding: "8px 15px" }}
                  >
                    <div
                      className="popup_close_icon"
                      // style={{ right: "5px" }}
                      onClick={props.closeRegPopup}
                    >
                      <i
                        className="fa fa-xmark"
                        onClick={props.closeRegPopup}
                      ></i>
                    </div>

                    <form
                      autoComplete="off"
                      encType="multipart/form-data"
                      className="registerNowForm"
                      onSubmit={regSubmitHandler}
                      id="channelPartnerRegisterNowForm"
                    >
                      <h2
                        className="form-heading text-center mb-1 "
                        style={{ fontSize: "22px" }}
                      >
                        Register Now
                      </h2>
                      {checkValidation !== "" && (
                        <p className="text-danger mb-0 text-center">
                          {checkValidation}
                        </p>
                      )}
                       <label>
                        Agreement Name{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Name"
                        required
                        autoComplete="new-password"
                        name="name"
                        id="name"
                        // onKeyUp={panCapitalize}
                        value={selectedFile.name}
                        onChange={(e) =>
                          setSelectedFile({
                            ...selectedFile,
                            name: e.target.value,
                          })
                        }
                      />
                      <label>
                        Enter Your PAN Number{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="PAN Number"
                        required
                        autoComplete="new-password"
                        name="panNumber"
                        id="panNumber"
                        // onKeyUp={panCapitalize}
                        value={selectedFile.panNumber}
                        onChange={(e) => {
                          setSelectedFile({
                            ...selectedFile,
                            panNumber: alphabetsCapitalize(e.target.value),
                          });
                        }}
                      />
                      <label>
                        Enter Complete Address{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Complete Address"
                        required
                        name="address"
                        id="address"
                        //   onKeyUp={panCapitalize}
                        value={selectedFile.address}
                        onChange={(e) =>
                          setSelectedFile({
                            ...selectedFile,
                            address: e.target.value,
                          })
                        }
                      />
                      <div className="fileInputWrapper">
                        <label>
                          Upload Signature Image{" "}
                          <span className="text-danger">*</span>
                        </label>
                        {/* hidden input fields */}
                        <input
                          type="file"
                          placeholder="Upload PAN Card"
                          name="signature"
                          accept="image/*"
                          ref={signRef}
                          className="regFileInputs"
                          // value={selectedFile.sign}
                          onChange={fileChangeHandler}
                        />
                        {/* dummy input for file inputs */}
                        <div className="dummyInput">
                          <div className="dummyInputPlaceholder">
                            <span className="panFile">{signLabel}</span>
                          </div>
                          <div
                            className="fileIcon btn"
                            onClick={() => signRef.current.click()}
                          >
                            Browse
                          </div>
                        </div>
                      </div>

                      <div className="fileInputWrapper">
                        <label>Upload PAN Image</label>
                        <input
                          type="file"
                          placeholder="Upload PAN Card"
                          name="panCard"
                          accept="image/*"
                          ref={panRef}
                          className="regFileInputs"
                          // value={selectedFile.panImage}
                          onChange={fileChangeHandler}
                        />
                        <div className="dummyInput">
                          <div className="dummyInputPlaceholder">
                            <span className="panFile">{panLabel}</span>
                          </div>
                          <div
                            className="fileIcon btn"
                            onClick={() => panRef.current.click()}
                          >
                            Browse
                          </div>
                        </div>
                      </div>
                      <div className="fileInputWrapper">
                        <label>Upload Aadhar Image</label>
                        <input
                          type="file"
                          placeholder="Upload PAN Card"
                          name="aadharCard"
                          accept="image/*"
                          ref={adharRef}
                          className="regFileInputs"
                          // value={selectedFile.aadhar}
                          onChange={fileChangeHandler}
                        />
                        <div className="dummyInput">
                          <div className="dummyInputPlaceholder">
                            <span className="panFile">{aadharLabel}</span>
                          </div>
                          <div
                            className="fileIcon btn"
                            onClick={() => adharRef.current.click()}
                          >
                            Browse
                          </div>
                        </div>
                      </div>
                      <div className="fileInputWrapper">
                        <label>Upload Cancelled Cheque</label>
                        {/* hidden input field */}
                        <input
                          type="file"
                          placeholder="Upload PAN Card"
                          name="cheque"
                          accept="image/*"
                          ref={chequeRef}
                          className="regFileInputs"
                          // value={selectedFile.cheque}
                          onChange={fileChangeHandler}
                        />
                        {/* dummy input for file */}
                        <div className="dummyInput">
                          <div className="dummyInputPlaceholder">
                            <span className="panFile">{chequeLabel}</span>
                          </div>
                          <div
                            className="fileIcon btn"
                            onClick={() => chequeRef.current.click()}
                          >
                            Browse
                          </div>
                        </div>
                      </div>
                      {/* ====show when product type is MF======== */}
                      {isProductMF && (
                        <div className="form-group mb-1">
                          <label>
                            Do you have ARN ?{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <div className="check-box">
                              <input
                                className="form-check-input arnCheck mb-0"
                                type="checkbox"
                                name="arnCheckBox"
                                value="yes"
                                onClick={arnCheckHandler}
                                style={{
                                  border: blankInputFileds
                                    ? "1px solid red"
                                    : "",
                                }}
                              />
                              <label
                                className={`form-check-label ${
                                  blankInputFileds ? "text-danger" : ""
                                }`}
                              >
                                Yes
                              </label>
                              <input
                                className="form-check-input ms-3 mb-0 arnCheck"
                                type="checkbox"
                                name="arnCheckBox"
                                value="no"
                                onClick={arnCheckHandler}
                                style={{
                                  border: blankInputFileds
                                    ? "1px solid red"
                                    : "",
                                }}
                              />
                              <label
                                className={`form-check-label ${
                                  blankInputFileds ? "text-danger" : ""
                                }`}
                              >
                                No
                              </label>
                              {/* ====if not have arn */}
                              {ifNoArn && (
                                <Link
                                  className="d-block"
                                  to=""
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  Know how to generate ARN.{" "}
                                </Link>
                              )}
                              {/* =======If have arn====== */}
                              {ifArn && (
                                <input
                                  type="text"
                                  placeholder="Enter ARN*"
                                  required
                                  autoComplete="new-password"
                                  name="arnNumber"
                                  value={selectedFile.arnNumber}
                                  onChange={(e) =>
                                    setSelectedFile({
                                      ...selectedFile,
                                      arnNumber: e.target.value,
                                    })
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="termsContidions"
                          id="t&c"
                          required
                          value={selectedFile.termsContidions}
                          onChange={(e) =>
                            setSelectedFile({
                              ...selectedFile,
                              termsContidions: e.target.checked,
                            })
                          }
                        />
                        <label
                          className="termsLabel form-check-label"
                          htmlFor="terms"
                        >
                          Accept Terms & Conditions{" "}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      {/* ====VIEW Agreement Link */}
                        <div className=" text-center py-1 ">
                          <button
                            id="viewAgreementBtn"
                            className="subBtn"
                            style={{ padding: "8px 30px" }}
                            disabled={isDisableBtn}
                          >
                            View Agreement
                          </button>
                        </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pdf__viewer__slide mt-5">
            <div
              id="Iframe-Master-CC-and-Rs"
              className={`set-margin set-padding position-relative set-border set-box-shadow center-block-horiz ${
                slideScreen ? "" : "pdfHide"
              }`}
            >
              <div className="responsive__pdf_btn">
                <div className="responsive-wrapper responsive-wrapper-wxh-572x612">
                  <Agreement
                    formData={{
                      name: selectedFile.name,
                      panNumber: selectedFile.panNumber,
                      address: selectedFile.address,
                      signatureUrl: signatureUrl,
                    }}
                  />
                </div>
                <div className="bg-white w-100">
                  {showButtons && (
                    <div className="agreement__view bg-white w-100">
                      {/* <button onClick={handleDecline}>Decline</button> */}
                      <button onClick={() => setDeclineAgreement(true)}>
                        Decline
                      </button>
                      <button onClick={handleClick}>I Agree</button>
                    </div>
                  )}
                </div>

                {/* =====accept agreement and verifyotp */}
                <div
                  className={`agreement__doc__wrap ${
                    OptVerifyScreen ? "" : "otpVerifyHide"
                  }`}
                >
                  <div className="doc__input_wrap text-center pt-3">
                    <img
                      style={{ width: "100px" }}
                      src={PUBLIC_PATH + "assets/img/icons/checked.png"}
                      alt="icon"
                    />
                    <div className="text-success text-center mt-2">
                      OTP sent to your registered Mobile Number
                    </div>
                    {otpError && (
                      <div className="text-danger mt-2">
                        Invalid OTP. Please try again.
                      </div>
                      
                    )}
                    <div className="opt_input overflow-hidden mt-3">
                      <input
                        type="number"
                        placeholder="Enter OTP*"
                        maxLength="6"
                        required
                        autoComplete="one-time-code"
                        name="otp"
                        id="otp__input_field"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        onInput={sixDigitOtpLimit}
                        // value={selectedFile.otp}
                        // onChange={(e) =>
                        //   setSelectedFile({
                        //     ...selectedFile,
                        //     otp: e.target.value,
                        //   })
                        // }
                      />
                      
                      <div
                        className="verify h-100 fileIcon"
                        onClick={handleVerify}
                      >
                        Verify
                      </div>
                    </div>
                    {showResend && (
                        <span
                          onClick={resendOtp}
                          className="resend__otp text-uppercase mt-3"
                        >
                          Resend OTP
                        </span>
                      )}
                      {!showResend && (
                        <span>
                          Resend OTP in{" "}
                          <span id="resendTimer" className="mt-3">{timerSeconds}s</span>
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>
            {}
          </div>
        </div>

        {/* <===============================> Decline Agreement <=========================> */}
        {declineAgreement && (
          <div className="cd-popup" role="alert">
            <div className="cd-popup-container">
              <img
                src={PUBLIC_PATH + "assets/img/icons/logout.png"}
                alt="logoutLogo"
                className="popupImg"
              />
              <h5>Are you sure you want to decline agreement?</h5>
              <ul className="cd-buttons">
                <li>
                  <Link onClick={handleDecline}>Yes</Link>
                </li>
                <li>
                  <Link onClick={() => setDeclineAgreement(false)}>No</Link>
                </li>
              </ul>
              {/* <Link className="cd-popup-close img-replace"></Link> */}
            </div>
          </div>
        )}
        {/* <SuccessModal successStatus={'d-flex'} /> */}
        <div
          className={`modal custom-modal align-items-center ${successStatus}`}
          id="formSuccessModal"
          style={{ background: "rgba(0,0,0,0.3)" }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="formSuccessModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header " style={{ background: "#FF4B2B" }}>
                <h5 className="modal-title" id="formSuccessModalLabel">
                  Success
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeSuccessModal}
                  // data-bs-dismiss='modal'
                  // aria-label='Close'
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-popup-inner">
                  <div className="form-icon success">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 130.2 130.2"
                    >
                      <circle
                        className="path circle"
                        fill="none"
                        strokeWidth="6"
                        strokeMiterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <polyline
                        className="path check"
                        fill="none"
                        strokeWidth="6"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="100.2,40.2 51.5,88.8 29.8,67.5 "
                      />
                    </svg>
                  </div>
                  <div className="form-body">
                    <p>
                      Thank you for filling out our registration form. We are
                      glad that you joined us.
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="popup-btn "
                  style={{ background: "#FF4B2B" }}
                  // data-bs-dismiss='modal'
                  onClick={closeSuccessModal}
                >
                  Okay
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterNowPopup;
