import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  useUserTypeSelectQuery,
  useSubCategorySelectQuery
} from '../../store/services/loginService'
import { useHomepageCreateLeadApiMutation } from '../../store/services/registerApiService'

function HeroForm () {
  const [errMsg, setErrMsg] = useState('')
  
  // subcategory list profession
  const subcategoryResp = useSubCategorySelectQuery()
  const professionSelectData =
    subcategoryResp?.isSuccess &&
    !subcategoryResp?.isLoading &&
    subcategoryResp.status === 'fulfilled' &&
    !subcategoryResp?.isError &&
    subcategoryResp?.data?.subCategoryList

  // API FOR USERTYPE
  const userTypeResp = useUserTypeSelectQuery()
  const userTypeData =
    userTypeResp?.isSuccess &&
    !userTypeResp?.isLoading &&
    userTypeResp.status === 'fulfilled' &&
    !userTypeResp?.isError &&
    userTypeResp?.data?.categoryList

  const [captchaValidations, setCaptchaValidations] = useState(false)
  const [mobileValidation, setMobileValidation] = useState(false)
  const [succesAlert, setSuccesAlert] = useState(false)

  const [formDetails, setFormDetails] = useState({
    name: '',
    email: '',
    mobile: '',
    role: '',
    profession: '',
    captcha: ''
  })

  const changeHandler = e => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value })
  }

  // create lead api
  const [postLeadData, leadResp={}] = useHomepageCreateLeadApiMutation()
  useEffect(() => {
    if(
      leadResp?.isSuccess &&
      !leadResp?.isLoading &&
      !leadResp?.isError &&
      leadResp?.data?.success 
    ) {
      setSuccesAlert(true)
      setFormDetails({
        name: '',
        email: '',
        mobile: '',
        role: '',
        profession: '',
        captcha: ''
      })
    }
    if(
      leadResp?.isSuccess &&
      !leadResp?.isLoading &&
      leadResp?.isError &&
      !leadResp?.data?.success 
    ) {
      setErrMsg('Please try again')
      setTimeout(() => {
        setErrMsg('')
      }, 5000);
    }
  }, []);

  const onFormSubmit = e => {
    e.preventDefault()
    if (formDetails.mobile.length === 10) {
      if (formDetails.captcha === document.getElementById('captcha').value) {
        setCaptchaValidations(false)
          // post data in api
          postLeadData(formDetails)
        setCaptchaValidations(false)
        setMobileValidation(false)
      } else {
        setMobileValidation(false)
        setCaptchaValidations(true)
        setTimeout(() => {
          setCaptchaValidations(false)
        }, 5000);
      }
    } else {
      setCaptchaValidations(false)
      setMobileValidation(true)
      setTimeout(() => {
        setMobileValidation(false)
      }, 5000);
    }
  }

  let captcha = []
  let q = 0
  let theCaptcha

  function createCaptcha () {
    const activeCaptcha = document.getElementById('captcha')
    for (q = 0; q < 6; q++) {
      if (q % 2 === 0) {
        captcha[q] = String.fromCharCode(Math.floor(Math.random() * 26 + 65))
      } else {
        captcha[q] = Math.floor(Math.random() * 10 + 0)
      }
    }
    theCaptcha = captcha.join('')
    activeCaptcha.value = `${theCaptcha}`
  }

  useEffect(() => {
    createCaptcha()
    let mobInpt = document.getElementById('mobile')
    mobInpt.oninput = () => {
      if (mobInpt.value.length > mobInpt.maxLength) {
        mobInpt.value = mobInpt.value.slice(0, mobInpt.maxLength)
      }
    }
  }, [])

  
  return (
    <>
      <div className='inquiry snipcss-2o8Zx'>
        <form
          action='#'
          onSubmit={onFormSubmit}
          className='contact-form'
          autoComplete='off'
        >
          <p className='description'>
            BECOME A FINANCIAL <br /> PRODUCT DISTRIBUTOR
          </p>
          {/* captcha validation */}
          {captchaValidations && (
            <p className='mb-2 text-danger text-center'>
              Please Enter Valid Captcha !
            </p>
          )}

          {/* mobile validation */}
          {mobileValidation && (
            <p className='mb-2 text-danger text-center'>
              Please put 10 digit mobile number !
            </p>
          )}

          {/* when entry is not submitted */}
          {errMsg!=='' && (
            <p className='mb-2 text-danger text-center'>
              {errMsg}
            </p>
          )}
          {/* mobile validation */}
          {succesAlert && (
            <p className='mb-2 text-success text-center'>
              Succesfully Registered
            </p>
          )}

          <div>
            <input
              type='text'
              className='form-control rounded border-white mb-2 form-input'
              id='name'
              name='name'
              placeholder='Name *'
              required
              onChange={changeHandler}
              value={formDetails.name}
            />
          </div>
          <div>
            <input
              type='number'
              className='form-control rounded border-white form-input'
              id='mobile'
              name='mobile'
              placeholder='Mobile Number *'
              maxLength='10'
              required
              onChange={changeHandler}
              value={formDetails.moblie}
            />
          </div>
          <div>
            <input
              type='email'
              className='form-control rounded border-white form-input mb-2 mt-2'
              placeholder='Email *'
              name='email'
              autoComplete='new-email'
              required
              onChange={changeHandler}
              value={formDetails.email}
            />
          </div>
          <div>
            <select
              name='role'
              className='form-select rounded border-white sel-inpt mb-2'
              onChange={changeHandler}
              value={formDetails.role}
            >
              <option value='1'>Select Role *</option>
              {userTypeData &&
                userTypeData?.map((item, index) => {
                  return (
                    <option key={index} value={item?._id}>
                      {item?.name}
                    </option>
                  )
                })}
            </select>
          </div>

          <div>
            <select
              name='profession'
              className='form-select rounded border-white sel-inpt mb-3'
              onChange={changeHandler}
              value={formDetails.profession}
            >
              <option value='1'>Select Profession *</option>
              {professionSelectData &&
                professionSelectData?.map((item, index) => {
                  return (
                    <option key={index} value={item?._id}>
                      {item?.name}
                    </option>
                  )
                })}
            </select>
          </div>

          <div className='set-div d-flex justify-content-between align-items-center'>
            <div className='w-100 d-flex justify-content-between align-items-center'>
              <div className='d-flex justify-content-start align-items-center'>
                <input id='captcha' className='captcha_input' readOnly />

                {/* </div> */}
                <div onClick={createCaptcha} className='text-center ms-1'>
                  <i
                    style={{ cursor: 'pointer' }}
                    className='fa-solid fa-rotate-right text-secondary'
                  ></i>
                </div>
              </div>
            </div>
            <input
              type='text'
              placeholder='Enter Captcha *'
              className='form-control rounded px-1'
              id='cpatchaTextBox'
              name='captcha'
              style={{
                width: '50% !important',
                height: '35px',
                border: '1px solid #c1c1c1'
              }}
              onChange={changeHandler}
              value={formDetails.captcha}
              required
            />
          </div>

          <div className='submit-button-wrapper mt-3'>
            <button>{leadResp?.isLoading?'Registering...':'Register Now'}</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default HeroForm
