import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FranchiseSidebar from "../components/franchise/FranchiseSidebar";
import { useSelector } from "react-redux";
import DashboardBanner from "../components/general/DashboardBanner";
import Footer from "../components/Footer";
import axios from "axios";
import { Api_Base_Url } from "../constant/matcher";
import CPBusinessPlan from "../components/CPBusinessPlan";
import Loader from "../components/general/Loader";


const EquityRequest = () => {
 // loggedIn user Data form react-redux
 const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
 const [dynamicRoute, setdynamicRoute] = useState("");
 const [isloading, setIsLoading] = useState(true)
 const [equity, setEquity] = useState([])
 useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
      setTimeout(() => {
        setIsLoading(false)
      }, 1000);
  }, [loggedInUserData]);

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  const getEquity = () => {
    const loggedInUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const Id = loggedInUserData?._id;
    axios.get(`${Api_Base_Url}/rm/equaity-topup?id=${Id}`).then((res) => {
      setEquity(res?.data?.topupData)
    })
  }

  useEffect(() => {
    getEquity()
  }, [])
  return (
    <>
    {
        isloading ? (
         <Loader/>
        )
        : 
        (
     
          <>
        <FranchiseSidebar navContWrapp={navContWrapp} />
        <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                Dashboard
              </Link>{" "}
              <i className="fa-solid fa-angle-right"></i> Equity Request
            </p>
          </div>
        </div>
        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          style={{ background: "whitesmoke" }}
        >
         
          <div className="row table__row__wrapp">
            <div className="client_table_container py-2">
              <div className="d-flex justify-content-between ">
                <h2> Equity Request </h2>
                </div>
             
              <div className="w-100 overflow-auto">
                <table className="table table-hover  table-striped  client__table">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Amount</th>
                            <th>Remark</th>
                        </tr>
                    </thead>
                    <tbody>
                      {
                        equity && equity.length > 0 ? ( equity?.map((result, index) => {
                          return (
                            <tr key={result._id}>
                            <td>{index + 1}</td>
                            <td>{result?.amount}</td>
                            <td>{result?.remarks}</td>
                        </tr>
                          )
                        })
                        )
                        : 
                        (
                          <p className="mb-0 ms-2 mt-4">
                          You don't have any Equity Request
                        </p>
                        )
                      }
                        
                    </tbody>
                </table>
                </div>
              </div>
              </div>
              </div>
             
</section>
            </>
        )}
        </>
  )
}

export default EquityRequest