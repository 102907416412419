import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InvestorSidebar from '../../components/investor/InvestorSidebar'
import Footer from "../../components/Footer";
import DashboardBanner from "../../components/general/DashboardBanner";
import Loader from "../../components/general/Loader";
import { useSelector } from "react-redux";
import axios from "axios";
import { Api_Base_Url } from "../../constant/matcher";
import { formatNumberWithCommas } from "../../utils/helper";

const RealStateList = () => {
// loggedIn user Data form react-redux
const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
const [dynamicRoute, setdynamicRoute] = useState("");
const [isloading, setIsLoading] = useState(true)
const [listData, setListData] = useState([])
useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");

      setTimeout(() => {
        setIsLoading(false)
      }, 1000);
  }, [loggedInUserData]);

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  const getList = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const clientcode = loginUserData.clintcode
    axios.get(`${Api_Base_Url}/investor/mrisummary?clientcode=${clientcode}`).then((res) => {
        setListData(res?.data?.data)
        
    })
  }

  useEffect(() => {
    getList()
  }, [])

  function convertDateToCustomFormat(dateStr) {
    const date = new Date(dateStr);
    
    // Extract year, month, and day from the date
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const day = date.getDate().toString().padStart(2, '0');
  
    // Create the custom date format: DD-MM-YYYY
    const customDate = `${day}-${month}-${year}`;
  
    return customDate;
  }

  return (
    <>
      {
      isloading ? (
       <Loader/>
      )
      : 
      (
   
        <>
      <InvestorSidebar  navContWrapp={navContWrapp} />
      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
       
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>Dashboard</Link>{" "}
              <i className="fa-solid fa-angle-right"></i> Real Estate
            </p>
          </div>
        </div>

        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          style={{ background: "whitesmoke" }}
        >
            <div className="row table__row__wrapp">
            <div className="client_table_container py-2">
            <div className="d-flex justify-content-between">
              <h2></h2>
             
              </div>
              <div className="w-100 overflow-auto">
                <table className="table table-striped client__table">
                <thead>
                    <tr>
                      <th scope="col">Sr No.</th>
                      <th scope="col">Unit No.</th>
                     
                          <th scope="col">Property Name</th>
                       
                          <th scope="col">Property Type</th>
                       
                      <th scope="col">Property Code</th>
                      <th scope="col">Size in Sqft</th>
                      <th scope="col">Date of Investment</th>
                     
                          <th scope="col">Investment Value</th>
                     
                      <th scope="col">Other Associated Cost</th>
                      <th scope="col">Registry Done</th>
                      <th scope="col">Registry Handover Date</th>
                      <th scope="col">Total Rent / AR</th>
                      <th scope="col">Total Expenses</th>
                      <th scope="col">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        listData && listData?.map((result, index) => {
                            return (
                                <tr key={result?._id}>
                                <td className="text-capitalize">{index+1}</td>
                                <td className="text-capitalize">{result?.unitinfo}</td>
                                <td className="text-capitalize">{result?.propertyName}</td>
                                <td className="text-capitalize">{result?.propertyType}</td>
                                <td className="text-capitalize">{result?.propertyCode}</td>
                                <td className="text-capitalize">{result?.size}</td>
                                <td className="text-capitalize">{convertDateToCustomFormat(result?.investmentDate)}</td>
                                <td className="text-capitalize">{result?.investmentValue}</td>
                                <td className="text-capitalize">{formatNumberWithCommas(result?.OtherAssociatedCost)}</td>
                                <td className="text-capitalize">{result?.registry}</td>
                                <td className="text-capitalize">{result?.registryDate}</td>
                                <td className="text-capitalize">{formatNumberWithCommas(result?.totalRent)}</td>
                                <td className="text-capitalize">{formatNumberWithCommas(result?.totalExpenses)}</td>
                                <td className="ps-4">
                                    <Link to={`${result?.clientcode}/${result?.propertyCode}`} className="ps-3"
                                   
                                    >
                                      <i className="fa-solid fa-eye"></i>
                                    </Link>
                                  </td>
                                </tr>

                            )
                        })
                    }
                       
                  </tbody>
                </table>
                </div>
                </div>
                </div>
        </div>
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
      </>
           )
          }
    
    </>
  )
}

export default RealStateList