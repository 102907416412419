import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Api_Base_Url} from "../../../constant/matcher";
import FranchiseSidebar from "../../../components/franchise/FranchiseSidebar";
import Footer from "../../../components/Footer";
import { useSelector } from "react-redux";
import "../../../styles/lms.css";
import DashboardBanner from "../../../components/general/DashboardBanner";
import FieldOutput from "./FieldOutput";
import Select from "react-select";
import axios from "axios";
import FieldDateInput from "./FieldDateInput";
import {
   concatTwoStrWithParenthesis,
   dateFormat,
   formatDate,
   showformatDate,
} from "../../../utils/helper";
import Questions from "./Questions";
import InvQuestion from "./InvQuestion";
import EngQuestion from "./EngQuestion";
import InvEngQuestion from "./InvEngQuestion";

function LeadDetails() {
   // loggedIn user Data form react-redux
   const userData = useSelector((state) => state?.userReducer?.userData);
   const loggedInUserData = useSelector(
      (state) => state?.userReducer?.userData
   );
   const [dynamicRoute, setdynamicRoute] = useState("");
   const [showAssignment, setShowAssignment] = useState(false);
   const { leadid } = useParams();
   const [leadStatusList, setLeadStatusList] = useState([]);
   const [conversationData, setConversationData] = useState([]);
   const [externalStatus, setExternalStatus] = useState("");
   const [checkValidation, setCheckValidation] = useState("");
   const [answered, setAnswered] = useState('')
   const [unanswered, setUnAnswered] = useState('')
   const [showConversationRecords, setShowConversationRecords] = useState(false);
   const [fitScorePopup, setFitscorePopup] = useState(false);
   const [fitScoreInvPopup, setFitscoreInvPopup] = useState(false);
   const [engScorePopup, setEngscorePopup] = useState(false);
   const [engScoreInvPopup, setEngscoreInvPopup] = useState(false);
   const { usertype } = useParams()
   // const [userId, setUserId] = useState('')
   const [userType, setUserType] = useState('')
   const [fitScoreAnswer, setFitScoreAnswer] = useState([]);
  const [engScoreAnswer, setEngScoreAnswer] = useState([]);
  const [fitScore, setFitScore] = useState("");
   const [engagementScore, setEngagementScore] = useState('')
const [isDisable, setIsDisable] = useState(false)
   const [crossSelling, setCrossSelling] = useState({
      furtherOpportunity: [],
   });
   const [leadDetails, setLeadDetails] = useState({
      name: "",
      email: " ",
      phone: "",
      userType: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pin: "",
      leadCreatedByRole: "",
      createdDate: "",
      source: "",
      sourceName: "",
      coAssignedTo: "",
      channelPartnerName: " ",
      franchiseName: "",
      interestedIn: [],
      assignmentFunnel: [
      ],
      lastContacted: " ",
      noOfMeetingDone: "",
      stage: "",
      statusOfLead: "",
      nextMeetingDate: "",
      remark: "",
      conversationRecords: "View Button",
      fitScore: "",
      engagementScore: "",
      furtherOpportunity: [],
      modeOfMeeting: "",
      meetingCategory: "",
   });

   const getLeadStatus = () => {
      axios.get(`${Api_Base_Url}/lead/status-of-lead`).then((response) => {
         setLeadStatusList(response?.data?.status);
      });
   };

   useEffect(() => {
      // getLeadStatus();
      getLeadDetails();
   }, [leadStatusList]);

   const getLeadDetails = () => {
      axios
         .get(`${Api_Base_Url}/lead/lead-detail?leadid=${leadid}`)
         .then((res) => {
            const data = res?.data?.data;
            // setUserId(data[0]?._id)
            setUserType(data[0]?.userType)
            if (data && data.length > 0) {
               const assignInfoLog = data[0]?.assigninfolog;
               const productInfo = data[0]?.product;
               const createdAtDate = new Date(data[0]?.createdAt);
               const formattedCreatedAt = createdAtDate
                  .toISOString()
                  .split("T")[0];
               const leadStatusID = data[0]?.leadstatus || "";
               const leadStatus = leadStatusList.find(
                  (status) => status._id === leadStatusID
               );

               const leadStatusName = leadStatus ? leadStatus.status : "";
               const externalStatusValue = data[0].status;
               setExternalStatus(externalStatusValue);
               setLeadDetails({
                  name: data[0]?.name || "",
                  userType: data[0]?.cpdata.length > 0 ? "Client" : "Lead",
                  email: data[0]?.email || "",
                  state: data[0]?.state || "",
                  country: data[0]?.country || "",
                  pin: data[0]?.pin || "",
                  phone: data[0]?.phone.toString(),
                  city: data[0]?.cityinfo[0]?.city || "",
                  leadCreatedByRole: data[0]?.sourceName || "",
                  createdDate: formattedCreatedAt || "",
                  source: data[0]?.sourceType || "",
                  coAssignedTo:  "",
                  channelPartnerName: "",
                  franchiseName: "",
                  interestedIn: productInfo ? productInfo : [],
                  lastContacted: data[0]?.lastContacted || "",
                  noOfMeetingDone: data[0]?.appoinmentCount || "",
                  stage: data[0]?.stage || "Not Initiated",
                  statusOfLead: leadStatusName || "Not Initiated",
                  nextMeetingDate: data[0]?.nextContacted || "",
                  remark: data[0]?.remark || "",
                  sourceName: data[0]?.sourceName || "",
                  modeOfMeeting: data[0]?.modeofmeeting || "",
                  meetingCategory: data[0]?.meetingcategory || "",
                  assignmentFunnel: assignInfoLog ? assignInfoLog : []
               });
            }
         });
   };

  

  

   const options = [
      { value: "Equity", label: "Equity" },
      { value: "Mutual Fund", label: "Mutual Fund" },
      { value: "Real State", label: "Real State" },
      { value: "Term Insurance", label: "Term Insurance" },
      { value: "Demat", label: "Demat" },
      { value: "Health Insurence", label: "Health Insurence" },
   ];

   

   const [isEditMode, setIsEditMode] = useState({
      leadStatus: false,
      qualityScore: false,
      crossSelling: false,
   });

   useEffect(() => {
      loggedInUserData?.role === "rm"
         ? setdynamicRoute("team")
         : setdynamicRoute("dashboard");
   }, [loggedInUserData]);

   const [navShow, setNavShow] = useState(true);
   // navigation content wrapper handler
   const navContWrapp = (boolData) => {
      setNavShow(boolData);
   };


const handleRescord = () => {
   setShowConversationRecords(true)
}

   const leadStatusHandleInputChange = ({ name, value }) => {
      // const { name, value } = data;

      setLeadDetails({
         ...leadDetails,
         [name]: value,
      });
   };

   const handleSelectChange = (selectedOptions) => {
      setCrossSelling({
         ...crossSelling,
         furtherOpportunity: selectedOptions.map((option) => option.value),
      });
   };



   const handleEdit = (e) => {
      e.preventDefault();
      const originalLeadDetails = { ...leadDetails };
      setIsEditMode({
         ...isEditMode,
         leadStatus: true,
      });
      const updatedLeadDetails = {
         ...leadDetails, // Create a shallow copy of the current state
         nextMeetingDate: "",
         modeOfMeeting: "",
         meetingCategory: "",
         remark: "",
      };
      setLeadDetails(updatedLeadDetails);
      setIsEditMode({
         ...isEditMode,
         leadStatus: true,
         originalLeadDetails: originalLeadDetails, // Store original details
      });
   };

   const handleCancel = (e) => {
      e.preventDefault();
      // Revert back to the original lead details and disable edit mode
      setLeadDetails(isEditMode.originalLeadDetails);
      setIsEditMode({
         ...isEditMode,
         leadStatus: false,
         originalLeadDetails: null, // Clear the stored original details
      });
      setCheckValidation("")
   };

   const getCurrentDate = () => {
      const currentDate = new Date();
      return currentDate.toISOString().split("T")[0];
   };

   const handleSubmit = async (e, updatedLeadDetails) => {
      e.preventDefault();
      setIsDisable(true)
      // Check if required fields are empty
      if (
         !leadDetails.statusOfLead ||
         !leadDetails.remark ||
         !leadDetails.modeOfMeeting ||
         !leadDetails.meetingCategory
      ) {
         setCheckValidation("Please fill all the required fields.");
         setIsDisable(false)
         return; // Exit the function
      }
      setLeadDetails(updatedLeadDetails);
      try {
         const loginUserData = JSON.parse(
            localStorage.getItem("loginUserData")
         );
         const userId = loginUserData._id;

         const selectedStatus = leadStatusList?.find(
            (status) => status.status === leadDetails?.statusOfLead
         );

         const formData = new FormData();
         formData.append("status", selectedStatus._id);
         formData.append("nextfollowup", leadDetails.nextMeetingDate);
         formData.append("remark", leadDetails.remark);
         formData.append("leadid", leadid);
         formData.append("currentdate", getCurrentDate());
         formData.append("leaduserid", userId);
         formData.append("modeofmeeting", leadDetails.modeOfMeeting);
         formData.append("meetingcategory", leadDetails.meetingCategory);
         const response = await axios.post(
            `${Api_Base_Url}/lead/updateleadstatus`,
            formData,
            {
               headers: {
                  "Content-Type": "application/json",
               },
            }
         );

         setIsEditMode({ ...isEditMode, leadStatus: false });
         getLeadDetails();
         getconversationData();
         setCheckValidation("")
         setIsDisable(false) 
         // console.log("Response:", response.data);
      } catch (error) {
         // console.error("An error occurred:", error);

         if (error.response) {
            // console.error("Response data:", error.response.data)
         }
      }
   };

   const getconversationData = () => {
      axios
         .get(
            `${Api_Base_Url}/lead/leadconversation?leadid=${leadid}`
         )
         .then((response) => {
            const record = response?.data?.data || []
            record.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            setConversationData(record);
         });
   };

   const getQuery = () => {
      axios
         .get(
            `${Api_Base_Url}/rm/ladingpagequery?rmid=${loggedInUserData._id}`
         )
         .then((response) => {
            response = response?.data?.data
            if(response){
             const  answerd = response.filter((item) => item.status !== 0).length;
             const  unAnswerd = response.filter((item) => item.status === 0).length;

             setAnswered(answerd)
             setUnAnswered(unAnswerd)
            }
          
         });
   };

   const getFitScore =  () => {
      axios
        .get(`${Api_Base_Url}/cp/fitscoreresult?userid=${leadid}`)
        .then((response) => {
            setFitScore( response?.data?.data?.sum[0]?.totalscore)
           
         
          setFitScoreAnswer(response?.data?.data?.data);
  
        })
        .catch((error) => {
          console.error("Error fetching fit score:", error);
        });
    };
  
   const getEngScore =  () => {
      axios
         .get(`${Api_Base_Url}/cp/engagementscoreresult?userid=${leadid}`)
         .then((response) => {
           
            setEngagementScore(response?.data?.data?.sum[0]?.totalscore)
             
             setEngScoreAnswer(response?.data?.data?.data);
             
             
           })
           .catch((error) => {
            console.error("Error fetching fit score:", error);
          });
           
   }
   
   useEffect(() => {
      getLeadStatus();
      getLeadDetails();
      getconversationData();
      getQuery()
      getFitScore()
      getEngScore() 
   }, [ leadid]);
   return (
      <>
         <FranchiseSidebar navContWrapp={navContWrapp} />

         {/*<=========== content wrapper section ===============> */}
         <section
            className={`dash-content-wrapper small-wrapper`}
            style={{ paddingLeft: navShow ? "230px" : "70px" }}
         >
            {/*<=============== banner section ==================>*/}
            <DashboardBanner />

            <div className="breadcrumbs">
               <div className="container">
                  <p>
                     <Link
                        to={"/" + dynamicRoute + "/" + loggedInUserData?.role}
                     >
                        Dashboard
                     </Link>{" "}
                     <i className="fa-solid fa-angle-right"></i>
                     <Link
                        to={`/${
                           dynamicRoute + "/" + loggedInUserData?.role
                        }/my-leads`}
                     >
                        {" "}
                        My Leads
                     </Link>{" "}
                     <i className="fa-solid fa-angle-right"></i> Lead Detials
                  </p>
               </div>
            </div>

            <div
               className="content-wrapper viewLeadDetails main-dash-wrapper px-md-4 py-5 "
               // style={{ background: "" }}
            >
               <div className="container wrapper-dekstop">
                  <div
                     className="px-1 mx-1 overflow-hidden "
                     style={{
                        overflowY:
                           window.screen.width < "768" ? "auto" : "hidden",
                     }}
                  >
                     {/* <================== Lead Personal Details ================> */}
                     <div
                        className="field__container"
                        style={{ margin: "30px 0" }}
                     >
                        <div>
                           {/* <======= heading  ======> */}
                           <div className="position-absolute">
                              <div
                                 className="position-relative"
                                 style={{ top: "-40px" }}
                              >
                                 <h2 className="fs-4 ps-3 heading-text-large">
                                    Lead Personal Details
                                 </h2>
                              </div>
                           </div>
                           {/* <========== content =========> */}
                           <div className="row py-2 px-md-2 mt-2 mx-2">
                              {leadDetails?.name && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-4 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Name" /> */}
                                             <p>Name: </p>
                                          </span>
                                          <div className="detail_value col col-8 col-md-6 col-xl-7">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={leadDetails?.name}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                             
                              {leadDetails?.phone && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-3 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-4 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Phone" /> */}
                                             <p>Phone: </p>
                                          </span>
                                          <div className="detail_value col col-8 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={leadDetails?.phone}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                               {leadDetails?.email && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-4 col-md-4 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Email" /> */}
                                             <p> Email: </p>
                                          </span>
                                          <div className="detail_value col col-8 col-md-8 col-xl-9 content-tag" >
                                             <div className="">
                                                <FieldOutput
                                                   value={leadDetails?.email}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                               {leadDetails?.userType && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-4 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Phone" /> */}
                                             <p className="me-3">UserType: </p>
                                          </span>
                                          <div className="detail_value col col-8 col-md-6 col-xl-9">
                                             <div className="overflow-auto ms-3">
                                                <FieldOutput
                                                   value={leadDetails?.userType}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              {leadDetails?.address && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col-4 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Address" /> */}
                                             <p>Address: </p>
                                          </span>
                                          <div className="detail_value col-8 col-md-6 col-xl-9 content-tag">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={leadDetails?.address}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              {leadDetails?.city && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col-4 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="City" /> */}
                                             <p>City: </p>
                                          </span>
                                          <div className="detail_value col-8 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={leadDetails?.city}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              
                              {leadDetails?.country && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-4 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Country" /> */}
                                             <p>Country</p>
                                          </span>
                                          <div className="detail_value col col-8 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={leadDetails?.country}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              {leadDetails?.pin && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-4 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Pin" /> */}
                                             <p>Pin: </p>
                                          </span>
                                          <div className="detail_value col col-8 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={leadDetails?.pin}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                           </div>
                        </div>
                     </div>

                     {/* <================== Prospect Info && Lead Status ========================> */}
                     <div
                        className="row justify-content-between "
                        style={{ margin: "70px 0" }}
                     >
                        {/* <================== Prospect Info ==================> */}
                        <div
                           className="field__container col col-12 col-xl-5 prospect_info "
                           // style={{ margin: "70px 0" }}
                        >
                           <div>
                              {/* <======= heading-container  ======> */}
                              <div className="position-absolute">
                                 <div
                                    className="position-relative"
                                    style={{ top: "-40px" }}
                                 >
                                    <h2 className="fs-4 ps-1 heading-text-large">
                                       Prospect Info
                                    </h2>
                                 </div>
                              </div>
                              {/* <========== content-container =========> */}
                              <div className="row py-2  mt-2 mx-2 fur_oppertunity prospect">
                                 {leadDetails?.leadCreatedByRole && (
                                    <>
                                       {/* <div className="col col-12 col-md-6 px-1 mt-1 "> */}
                                       <div className="row">
                                          <span className="detail_heading col col-6 col-md-5  text-capitalize">
                                             <p>Lead Created By Role: </p>
                                          </span>
                                          <span className="detail_value col col-6 col-md-7">
                                             <p>
                                                <FieldOutput
                                                   value={
                                                      leadDetails?.leadCreatedByRole
                                                   }
                                                />
                                             </p>
                                          </span>
                                       </div>
                                       {/* </div> */}
                                    </>
                                 )}
                                 {leadDetails?.createdDate && (
                                    <>
                                       {/* <div className="col col-12 col-md-6 px-1 mt-1 "> */}
                                       <div className="row">
                                          <span className="detail_heading col col-6 col-md-5 text-capitalize">
                                             <p> Created Date: </p>
                                          </span>
                                          <span className="detail_value col col-6 col-md-7">
                                             <p>
                                                <FieldOutput
                                                   value={showformatDate(
                                                      leadDetails?.createdDate
                                                   )}
                                                />
                                             </p>
                                          </span>
                                       </div>
                                       {/* </div> */}
                                    </>
                                 )}
                                 {leadDetails?.source && (
                                    <>
                                       {/* <div className="col col-12 col-md-6 px-1 mt-1 "> */}
                                       <div className="row">
                                          <span className="detail_heading col col-6 col-md-5 text-capitalize">
                                             <p> Source: </p>
                                          </span>
                                          <span className="detail_value col col-6 col-md-7">
                                             <p>
                                                <FieldOutput
                                                   // when sourceName is available it return sourceName in parenthesis with source
                                                   value={concatTwoStrWithParenthesis(
                                                      leadDetails?.source,
                                                      leadDetails?.sourceName
                                                   )}
                                                />
                                             </p>
                                          </span>
                                       </div>
                                       {/* </div> */}
                                    </>
                                 )}
                                 {leadDetails?.coAssignedTo && (
                                    <>
                                       {/* <div className="col col-12 col-md-6 px-1 mt-1 "> */}
                                       <div className="row">
                                          <span className="detail_heading col-4 col-md-5 text-capitalize">
                                             <p> Co-Assigned: </p>
                                          </span>
                                          <span className="detail_value col-8 col-md-7">
                                             <p>
                                                <FieldOutput
                                                   value={
                                                      leadDetails.coAssignedTo
                                                   }
                                                />
                                             </p>
                                          </span>
                                       </div>
                                       {/* </div> */}
                                    </>
                                 )}
                                 {leadDetails?.channelPartnerName && (
                                    <>
                                       {/* <div className="col col-12 col-md-6 px-1 mt-1 "> */}
                                       <div className="row">
                                          <span className="detail_heading col-4 col-md-5 text-capitalize">
                                             <p> CP Name: </p>
                                          </span>
                                          <span className="detail_value col-8 col-md-7 content-tag" >
                                             <p>
                                                <FieldOutput
                                                   value={
                                                      leadDetails.channelPartnerName
                                                   }
                                                />
                                             </p>
                                          </span>
                                       </div>
                                       {/* </div> */}
                                    </>
                                 )}
                                 {leadDetails?.franchiseName && (
                                    <>
                                       {/* <div className="col col-12 col-md-6 px-1 mt-1 "> */}
                                       <div className="row">
                                          <span className="detail_heading col-6 col-md-5 text-capitalize">
                                             <p>Franchise Name: </p>
                                          </span>
                                          <span className="detail_value col-6 col-md-7 content-tag" >
                                             <p>
                                                <FieldOutput
                                                   value={
                                                      leadDetails.franchiseName
                                                   }
                                                />
                                             </p>
                                          </span>
                                       </div>
                                       {/* </div> */}
                                    </>
                                 )}
                                 {leadDetails?.interestedIn && (
                                    <>
                                       {/* <div className="col col-12 col-md-6 px-1 mt-1 "> */}
                                       <div className="row">
                                          <span className="detail_heading col-6 col-md-5 text-capitalize">
                                             <p>Interested In: </p>
                                          </span>
                                          <span className="detail_value col-6 col-md-7 content-tag" >
                                             <p>
                                                {leadDetails?.interestedIn
                                                   .map(
                                                      (product) => product?.name
                                                   )
                                                   .join(", ")}
                                             </p>
                                             {/* <===== Dropdown ====> */}
                                          </span>
                                       </div>
                                       {/* </div> */}
                                    </>
                                 )}
                                 {leadDetails?.lastContacted && (
                                    <>
                                       {/* <div className="col col-12 col-md-6 px-1 mt-1 "> */}
                                       <div className="row">
                                          <span className="detail_heading col-6 col-md-5 text-capitalize">
                                             <p> Last Contacted: </p>
                                          </span>
                                          <span className="detail_value col-6 col-md-7">
                                             <p>
                                                <FieldOutput
                                                   value={showformatDate(
                                                      leadDetails.lastContacted
                                                   )}
                                                />
                                             </p>
                                          </span>
                                       </div>
                                       {/* </div> */}
                                    </>
                                 )}
                                 {leadDetails?.noOfMeetingDone && (
                                    <>
                                       {/* <div className="col col-12 col-md-6 px-1 mt-1 "> */}
                                       <div className="row">
                                          <span className="detail_heading  col-8 col-md-5 text-capitalize">
                                             <p> No of Meeting Done: </p>
                                          </span>
                                          <span className="detail_value  col-4 col-md-7">
                                             <p>
                                                <FieldOutput
                                                   value={
                                                      leadDetails?.noOfMeetingDone
                                                      
                                                   }
                                                />
                                             </p>
                                          </span>
                                       </div>
                                       {/* </div> */}
                                    </>
                                 )}
                                 {leadDetails?.assignmentFunnel &&
                                    leadDetails?.assignmentFunnel?.length !==
                                       0 && (
                                       <>
                                          <div className="row">
                                             <span className="detail_heading col col-9 col-md-5 text-capitalize">
                                                <p> Assignment Funnel: </p>
                                             </span>
                                             <span className="detail_value col col-3 col-md-7">
                                                <p>
                                                   <i
                                                      className="fa-solid fa-eye small_icon"
                                                      onClick={() =>
                                                         setShowAssignment(true)
                                                      }
                                                   ></i>
                                                </p>
                                             </span>
                                             {showAssignment && (
                                                <div className="tool-tip">
                                                   <i
                                                      className="fa-solid fa-xmark"
                                                      onClick={() =>
                                                         setShowAssignment(
                                                            false
                                                         )
                                                      }
                                                   ></i>
                                                   <table>
                                                      <thead>
                                                         <tr>
                                                            <th>Name</th>
                                                            <th>Date</th>
                                                         </tr>
                                                      </thead>
                                                      <tbody>
                                                            {
                                                               leadDetails?.assignmentFunnel.map((i) => {
                                                                  return (
                                                                     <>
                                                                     <tr>
                                                                     <td>{i?.name}</td>
                                                            
                                                            <td>{dateFormat(i?.createdAt)}</td>
                                                         </tr>
                                                            </>
                                                                  )
                                                               })
                                                            }  
                                                      </tbody>
                                                   </table>
                                                </div>
                                             )}
                                          </div>
                                       </>
                                    )}
                              </div>
                           </div>
                        </div>

                        {/* <================== Lead Status ========================> */}
                        <div
                           className="field__container col  col-12 col-xl-7 col-xxl-6 lead-status"
                           // style={{ margin: "70px 0" }}
                        >
                           <form action="">
                              <div>
                                 {/* <======= heading-container  ======> */}
                                 <div className="position-absolute">
                                    <div
                                       className="position-relative"
                                       style={{ top: "-40px" }}
                                    >
                                       <h2 className="fs-4 ps-1 heading-text-large">
                                          Lead Status
                                       </h2>
                                    </div>
                                 </div>
                                 {/* <========== content-container =========> */}
                                 <div className="row py-2 px-3 mt-2 ">
                                    {/* ----- stage ------ */}
                                    {/* {isEditMode?.leadStatus && ( */}
                                    <>
                                       <div className="row">
                                          <span className="detail_heading col-6 col-md-5  text-capitalize">
                                             <p> Stage: </p>
                                          </span>
                                          <div className="detail_value col-6 col-md-6">
                                             <FieldOutput
                                                value={leadDetails?.stage}
                                             />
                                          </div>
                                       </div>
                                    </>
                                    {/* )} */}

                                    {/* ------ status of lead ----- */}
                                    <div className="row">
                                       <span className="detail_heading  col-6 col-md-5  text-capitalize">
                                          {!isEditMode.leadStatus ? (
                                             <p> Status Of Lead: </p>
                                          ) : (
                                             <p>
                                                {" "}
                                                Status Of Lead{" "}
                                                <span className="text-danger">
                                                   *
                                                </span>{" "}
                                                :
                                             </p>
                                          )}
                                       </span>
                                       {isEditMode?.leadStatus && leadDetails?.statusOfLead !== "Won" ? (
                                          <>
                                             {/* <===== Dropdown ====> */}
                                             <span className="detail_value col col-12 col-md-6 mb-2 p-0 ">
                                                <div className="dropdown">
                                                   <button
                                                      className="btn dropdown-toggle form-control text-start h-auto leadstatus-option"
                                                      type="button"
                                                      data-bs-toggle={
                                                         "dropdown"
                                                      }
                                                      aria-expanded="false"
                                                      style={{
                                                         border:
                                                            "1px solid lightgray",
                                                      }}
                                                   >
                                                      {leadDetails?.statusOfLead
                                                         ? leadDetails.statusOfLead
                                                         : "Select Status of Lead"}
                                                   </button>
                                                   <ul className="dropdown-menu">
                                                      {leadStatusList &&
                                                         leadStatusList?.map(
                                                            (j) => {
                                                               return (
                                                                  <li
                                                                     key={j?.id}
                                                                     onClick={() =>
                                                                        j?.status !==
                                                                           "Won" &&
                                                                        leadStatusHandleInputChange(
                                                                           {
                                                                              name: "statusOfLead",
                                                                              value: j?.status,
                                                                           }
                                                                        )
                                                                     }
                                                                  >
                                                                     <span
                                                                        className={`dropdown-item ${
                                                                           j?.status ===
                                                                           "Won"
                                                                              ? "disabled"
                                                                              : ""
                                                                        }`}
                                                                     >
                                                                        {
                                                                           j?.status
                                                                        }
                                                                     </span>
                                                                  </li>
                                                               );
                                                            }
                                                         )}
                                                   </ul>
                                                </div>
                                             </span>
                                          </>
                                       ) : (
                                          <span className="detail_value col-6 col-md-6">
                                             <FieldOutput
                                                value={
                                                   leadDetails?.statusOfLead
                                                }
                                             />
                                          </span>
                                       )}
                                    </div>

                                    {/* ----- mode of meeting -----  */}
                                    <div className="row">
                                       <span className="detail_heading  col-6 col-md-5  text-capitalize">
                                          {!isEditMode.leadStatus ? (
                                             <p> Mode of Meeting: </p>
                                          ) : (
                                             <p>
                                                {" "}
                                                Mode of Meeting{" "}
                                                <span className="text-danger">
                                                   *
                                                </span>{" "}
                                                :
                                             </p>
                                          )}
                                       </span>
                                       {isEditMode?.leadStatus ? (
                                          <>
                                             {/* <===== Dropdown ====> */}
                                             <span className="detail_value col col-12 col-md-6 mb-2 p-0 ">
                                                <div className="dropdown">
                                                   <button
                                                      className="btn dropdown-toggle form-control text-start h-auto leadstatus-option"
                                                      type="button"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                      style={{
                                                         border:
                                                            "1px solid lightgray",
                                                      }}
                                                   >
                                                      {leadDetails?.modeOfMeeting
                                                         ? leadDetails?.modeOfMeeting
                                                         : "Select Mode of Meeting"}
                                                   </button>
                                                   <ul className="dropdown-menu ">
                                                      <li
                                                         onClick={() =>
                                                            leadStatusHandleInputChange(
                                                               {
                                                                  name: "modeOfMeeting",
                                                                  value: "Office Visit",
                                                               }
                                                            )
                                                         }
                                                      >
                                                         <a className="dropdown-item">
                                                            Office Visit
                                                         </a>
                                                      </li>
                                                      <li
                                                         onClick={() =>
                                                            leadStatusHandleInputChange(
                                                               {
                                                                  name: "modeOfMeeting",
                                                                  value: "Online",
                                                               }
                                                            )
                                                         }
                                                      >
                                                         <a className="dropdown-item">
                                                            Online
                                                         </a>
                                                      </li>
                                                      <li
                                                         onClick={() =>
                                                            leadStatusHandleInputChange(
                                                               {
                                                                  name: "modeOfMeeting",
                                                                  value: "Call/Phone",
                                                               }
                                                            )
                                                         }
                                                      >
                                                         <a className="dropdown-item">
                                                            Call/Phone
                                                         </a>
                                                      </li>
                                                   </ul>
                                                </div>
                                             </span>
                                          </>
                                       ) : (
                                          <>
                                             <div className="detail_value  col-6 col-md-6">
                                                <FieldOutput
                                                   value={
                                                      leadDetails?.modeOfMeeting
                                                   }
                                                />
                                             </div>
                                          </>
                                       )}
                                    </div>

                                    {/* ----- meeting category ----- */}
                                    <div className="row">
                                       <span className="detail_heading  col-6 col-md-5  text-capitalize">
                                          {!isEditMode.leadStatus ? (
                                             <p> Meeting Category: </p>
                                          ) : (
                                             <p>
                                                {" "}
                                                Meeting Category{" "}
                                                <span className="text-danger">
                                                   *
                                                </span>{" "}
                                                :
                                             </p>
                                          )}
                                       </span>
                                       {isEditMode?.leadStatus ? (
                                          <>
                                             {/* <===== Dropdown ====> */}
                                             <span className="detail_value col col-12 col-md-6 mb-2 p-0 ">
                                                <div className="dropdown ">
                                                   <button
                                                      className="btn dropdown-toggle form-control text-start h-auto leadstatus-option"
                                                      type="button"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                      style={{
                                                         border:
                                                            "1px solid lightgray",
                                                      }}
                                                   >
                                                      {leadDetails?.meetingCategory
                                                         ? leadDetails?.meetingCategory
                                                         : "Select Meeting Category"}
                                                   </button>
                                                   <ul className="dropdown-menu ">
                                                      <li
                                                         onClick={() =>
                                                            leadStatusHandleInputChange(
                                                               {
                                                                  name: "meetingCategory",
                                                                  value: "Product",
                                                               }
                                                            )
                                                         }
                                                      >
                                                         <a className="dropdown-item">
                                                            Product
                                                         </a>
                                                      </li>
                                                      <li
                                                         onClick={() =>
                                                            leadStatusHandleInputChange(
                                                               {
                                                                  name: "meetingCategory",
                                                                  value: "Business Plan",
                                                               }
                                                            )
                                                         }
                                                      >
                                                         <a className="dropdown-item">
                                                            Business Plan
                                                         </a>
                                                      </li>
                                                      <li
                                                         onClick={() =>
                                                            leadStatusHandleInputChange(
                                                               {
                                                                  name: "meetingCategory",
                                                                  value: "Business",
                                                               }
                                                            )
                                                         }
                                                      >
                                                         <a className="dropdown-item">
                                                            Business
                                                         </a>
                                                      </li>
                                                      <li
                                                         onClick={() =>
                                                            leadStatusHandleInputChange(
                                                               {
                                                                  name: "meetingCategory",
                                                                  value: "Other",
                                                               }
                                                            )
                                                         }
                                                      >
                                                         <a className="dropdown-item">
                                                            Other
                                                         </a>
                                                      </li>
                                                   </ul>
                                                </div>
                                             </span>
                                          </>
                                       ) : (
                                          <div className="detail_value col-6 col-md-6">
                                             <FieldOutput
                                                value={
                                                   leadDetails?.meetingCategory
                                                }
                                             />
                                          </div>
                                       )}
                                    </div>

                                    {/* ----- next meeting date ----- */}
                                    <div className="row">
                                       <span className="detail_heading  col-6 col-md-5 text-capitalize">
                                          <p> Next Meeting Date: </p>
                                       </span>
                                       {isEditMode?.leadStatus ? (
                                          <span className="detail_value col-12 col-md-6  mb-2 p-0 ">
                                             <FieldDateInput
                                                name="nextMeetingDate"
                                                value={
                                                   leadDetails?.nextMeetingDate
                                                }
                                                handleChange={
                                                   leadStatusHandleInputChange
                                                }
                                             />
                                          </span>
                                       ) : (
                                          <>
                                             <span className="detail_value  col-6 col-md-6 ">
                                                <p>
                                                   <FieldOutput
                                                      value={showformatDate(
                                                         leadDetails?.nextMeetingDate
                                                      )}
                                                   />
                                                </p>
                                             </span>
                                          </>
                                       )}
                                       {/* </span> */}
                                    </div>

                                    {/* ----- remark ----- */}
                                    {
                                       <div className="row">
                                          <span className="detail_heading  col-6 col-md-5 text-capitalize">
                                             {!isEditMode.leadStatus ? (
                                                <p> Remark: </p>
                                             ) : (
                                                <p>
                                                   {" "}
                                                   Remark{" "}
                                                   <span className="text-danger">
                                                      *
                                                   </span>{" "}
                                                   :
                                                </p>
                                             )}
                                          </span>
                                          {isEditMode.leadStatus ? (
                                             <span className="detail_value col col-12 col-md-6  mb-2 p-0">
                                                <input
                                                   type="text"
                                                   className="updateInputBox form-control h-auto leadstatus-option"
                                                   aria-describedby="inputGroup-sizing-sm"
                                                   name="remark"
                                                   value={leadDetails?.remark}
                                                   onChange={(e) =>
                                                      leadStatusHandleInputChange(
                                                         {
                                                            name: e.target.name,
                                                            value: e.target
                                                               .value,
                                                         }
                                                      )
                                                   }
                                                />
                                             </span>
                                          ) : (
                                             <span className="detail_value col-6 col-md-6">
                                                <FieldOutput
                                                   value={leadDetails?.remark}
                                                />
                                             </span>
                                          )}
                                       </div>
                                    }

                                    {/* ----- conversation records ----- */}
                                    {!isEditMode?.leadStatus && (
                                       <div className="row">
                                          <span className="detail_heading col-9 col-md-5 text-capitalize">
                                             <p> Conversation Records: </p>
                                          </span>
                                          <span className="detail_value col-3 col-md-6">
                                             <span>
                                                <i
                                                   className="fa-solid fa-message small_icon"
                                                   onClick={() =>
                                                      handleRescord(leadDetails?._id)
                                                   }
                                                ></i>
                                             </span>
                                             {showConversationRecords && (
                                                <>
                                                   <div
                                                      className="chatPopup"
                                                      style={{ zIndex: "9999" }}
                                                   >
                                                      <div className="chatBgoverlay"></div>
                                                      <div className="chat-box">
                                                         <header>
                                                            <h1> Remarks </h1>
                                                            <span
                                                               style={{
                                                                  flex: 1,
                                                               }}
                                                            ></span>
                                                            <span
                                                               className="close-button"
                                                               onClick={() =>
                                                                  setShowConversationRecords(
                                                                     false
                                                                  )
                                                               }
                                                            >
                                                               X
                                                            </span>
                                                         </header>
                                                         <section id="message-list">
                                                            {conversationData?.map(
                                                               (message) => (
                                                                  <div
                                                                     key={
                                                                        message?._id
                                                                     }
                                                                     className={
                                                                        message.reply
                                                                           ? "me ms-2"
                                                                           : "not-me ms-2"
                                                                     }
                                                                  >
                                                                     <span>
                                                                        {
                                                                           message.convesation
                                                                        }
                                                                     <p>{message?.rminfo?.name}</p>
                                                                     <p>{formatDate(message?.createdAt)}</p>
                                                                     </span>
                                                                  </div>
                                                               )
                                                            )}
                                                         </section>
                                                      </div>
                                                   </div>
                                                </>
                                             )}
                                          </span>
                                       </div>
                                    )}

                                    {/* ----- button ----- */}
                                    {
                                       <div
                                          className="row position-relative"
                                          style={{ padding: "0px 20px" }}
                                       >
                                          {/* <span className="detail_heading col col-12 col-md-5 text-capitalize"> */}
                                          {checkValidation !== "" && (
                                             <p className="text-danger m-2">
                                                {checkValidation}
                                             </p>
                                          )}
                                          {/* </span> */}
                                          <span className="detail_heading col col-11  m-2 p-0 text-capitalize">
                                             {isEditMode?.leadStatus ? (
                                                <div className="d-flex flex-row justify-content-end  ">
                                                   <button
                                                      type="button"
                                                      className="btn text-white mx-1 "
                                                      style={{
                                                         backgroundColor:
                                                            "#08554e",
                                                      }}
                                                      onClick={handleCancel}
                                                   >
                                                      Cancel
                                                   </button>
                                                   <button
                                                      type="submit"
                                                      className="btn btn-sm text-white "
                                                      style={{
                                                         backgroundColor:
                                                            "#08554e",
                                                      }}
                                                      onClick={handleSubmit}
                                                      disabled={isDisable}
                                                   >
                                                      Submit
                                                   </button>
                                                </div>
                                             ) : (
                                              
                                                <div
                                                   className="position-absolute"
                                                   style={{
                                                      right: "0",
                                                      bottom: "0",
                                                   }}
                                                >
                                                    { loggedInUserData.role === 'rm' && (
                                                   <button
                                                      className="btn btn-sm text-white"
                                                      style={{
                                                         backgroundColor:
                                                            "#08554e",
                                                      }}
                                                      onClick={handleEdit}
                                                   >
                                                      <i className="fa-solid fa-pen-to-square fa-2xs"></i>{" "}
                                                   </button>
                                                )}
                                                </div>
                                             )}
                                          </span>
                                       </div>
                                    }
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>

                     {/* <============ Cross Selling && Quality Score && Raise Query ===============> */}
                     <div
                        className="row justify-content-between cross-selling"
                        style={{ margin: "50px 0" }}
                     >
                        {/* <==================  Cross Selling ==================> */}
                        <div className="field__container col-12 col-xl-3">
                           <div>
                              {/* <======= heading   ======> */}
                              <div className="position-absolute">
                                 <div
                                    className="position-relative"
                                    style={{ top: "-60px" }}
                                 >
                                    <h2 className="fs-4 ps-1 heading-text-large">
                                       Cross Selling
                                    </h2>
                                 </div>
                              </div>
                              {/* <====== Button =======> */}
                              <div className="position-relative">
                                 <div
                                    className="position-absolute"
                                    style={{ right: "10px" }}
                                 >
                                    {isEditMode?.crossSelling ? (
                                       <button
                                          className="btn btn-sm text-white"
                                          style={{ backgroundColor: "#08554e" }}
                                          onClick={() =>
                                             setIsEditMode({
                                                ...isEditMode,
                                                crossSelling: false,
                                             })
                                          }
                                       >
                                          Submit
                                       </button>
                                    ) : (
                                       <div>
                                       { loggedInUserData.role === 'rm' && (
                                       <button
                                          className="btn btn-sm text-white"
                                          style={{ backgroundColor: "#08554e" }}
                                          onClick={() =>
                                             setIsEditMode({
                                                ...isEditMode,
                                                crossSelling: true,
                                             })
                                          }
                                       >
                                          <i className="fa-solid fa-pen-to-square fa-2xs"></i>{" "}
                                       </button>
                                       )}
                                       </div>
                                    )}
                                 </div>
                              </div>
                              {/* <========== content =========> */}
                              {/* <div className="row px-md-3 mt-2">
                      <div className="col col-4  mt-1"> */}
                              <div className="row px-4 my-4 fur_oppertunity">
                                 <span className="detail_heading col col-12 text-capitalize ">
                                    <p>Further Opportunity: </p>
                                 </span>

                                 {isEditMode?.crossSelling ? (
                                    <div className="detail_value col col-12 ">
                                       <Select
                                          options={options}
                                          isMulti
                                          value={options.filter((option) =>
                                             crossSelling.furtherOpportunity.includes(
                                                option.value
                                             )
                                          )}
                                          onChange={handleSelectChange}
                                          menuPortalTarget={document.body} // Render the menu outside the component's hierarchy to avoid overflow issues
                                          menuPosition="fixed"
                                       />
                                    </div>
                                 ) : (
                                    <>
                                       <div className="detail_value col col-12 ">
                                          {crossSelling?.furtherOpportunity?.map(
                                             (item) => (
                                                <p> {item}</p>
                                             )
                                          )}
                                       </div>
                                    </>
                                 )}
                              </div>
                              {/* </div>
                    </div> */}
                           </div>
                        </div>

                        {/* <=============== Quality Score  ==========> */}
                        <div className="field__container col-12 col-xl-5 quality-score">
                           {/* <======= heading-container  ======> */}
                           <div className="position-absolute">
                              <div
                                 className="position-relative"
                                 style={{ top: "-35px" }}
                              >
                                 <h2 className="fs-4 ps-1 heading-text-large">
                                    {" "}
                                    Quality Score{" "}
                                 </h2>
                              </div>
                           </div>
                           
                          {
                           usertype === 'cp' ? (
                              <div className="row py-2  mt-2 mx-2">
                             
                              <>
                                 <div className="col col-6">
                                    {/* <SingleValuePieChart
                                       value={fitScore}
                                       title={`Your Fit Score ${fitScore}%`}
                                    /> */}
                                     <p className="detail_heading col col-12 text-capitalize "
                                    style={{
                                       height: "50px"
                                    }}
                                    >{`Your Fit Score ${fitScore ? fitScore : "00"} / 10`}</p>
                                     <div className="score">
                                    <span className="engagementScore">{`${fitScore ? fitScore  : "00"} / 10`}</span>
                                    <button
                                       className="btn btn-sm text-white"
                                       style={{ backgroundColor: "#08554e" }}
                                       onClick={() =>
                                          setFitscorePopup(true)
                                       }
                                    >
                                       <i className="fa-solid fa-pen-to-square fa-2xs"></i>{" "}
                                    </button>
                                    </div>
                                 </div>
                                 <div className="col col-6">
                                 <p className="detail_heading col col-12 text-capitalize " style={{
                                       height: "50px"
                                    }}>{`Your Engagement Score ${engagementScore ? engagementScore : "00"} / 10`}</p>
                                    {/* <SingleValuePieChart
                                       value={engagementScore}
                                       title={`Your Engagement Score ${engagementScore}%`}
                                    /> */}
                                     <div className="score"> 
                                     <span className="engagementScore">{`${engagementScore ? engagementScore : "00"} / 10`}</span>
                                     <button
                                       className="btn btn-sm text-white"
                                       style={{ backgroundColor: "#08554e" }}
                                       onClick={() =>
                                          setEngscorePopup(true)
                                       }
                                    >
                                       <i className="fa-solid fa-pen-to-square fa-2xs"></i>{" "}
                                    </button>
                                    </div>
                                 </div>
                              </>
                          
                        </div>
                           )
                           : 
                           (
                              <div className="row py-2  mt-2 mx-2">
                             
                              <>
                                 <div className="col col-6">
                                    <p className="detail_heading col col-12 text-capitalize "
                                    style={{
                                       height: "50px"
                                    }}
                                    >{`Your Fit Score ${fitScore ? fitScore  : "00"} / 10`}</p>
                                    {/* <SingleValuePieChart
                                       value={fitScore}
                                       title={`Your Fit Score ${fitScore}%`}
                                    /> */}
                                      <div className="score">
                                    <span className="engagementScore">{`${fitScore ? fitScore  : "00"} / 10`}</span>
                                    <button
                                       className="btn btn-sm text-white"
                                       style={{ backgroundColor: "#08554e" }}
                                       onClick={() =>
                                          setFitscoreInvPopup(true)
                                       }
                                    >
                                       <i className="fa-solid fa-pen-to-square fa-2xs"></i>{" "}
                                    </button>
                                    </div>
                                 </div>
                                 <div className="col col-6">
                                 <p className="detail_heading col col-12 text-capitalize "  style={{
                                       height: "50px"
                                    }}>{`Your Engagement Score ${engagementScore ? engagementScore : "00"} / 10`}</p>
                                    {/* <SingleValuePieChart
                                       value={engagementScore}
                                       title={`Your Engagement Score ${engagementScore}%`}
                                    /> */}
                                    <div className="score"> 
                                     <span className="engagementScore">{`${engagementScore ? engagementScore : "00"} / 10`}</span>
                                     <button
                                       className="btn btn-sm text-white"
                                       style={{ backgroundColor: "#08554e" }}
                                       onClick={() =>
                                          setEngscoreInvPopup(true)
                                       }
                                    >
                                       <i className="fa-solid fa-pen-to-square fa-2xs"></i>{" "}
                                    </button>
                                    </div>
                                 </div>
                              </>
                          
                        </div>
                           )
                          }
                          
                        </div>

                        {/* <=============== Query ====================> */}
                        <div
                           className="field__container col-12 col-xl-3 query-area"
                           style={{ padding: "0" }}
                        >
                           <div>
                              {/* <======= heading   ======> */}
                              <div className="position-absolute">
                                 <div
                                    className="position-relative"
                                    style={{ top: "-40px" }}
                                 >
                                    <h2 className="fs-4 ps-1 heading-text-large">
                                       Lead Query
                                    </h2>
                                 </div>
                              </div>

                            <div className="row pt-3 mt-2 mx-2 ">
                                 {/* <==== Answered =======> */}
                                 <div className="view-query"
                                 >
                                    <h6 > Answered</h6>
                                    <p>{answered}</p>
                                 </div>
                                    <div className="col col-12 d-flex justify-content-end ">
                                       <Link
                                          className="reg-btn "
                                          to={`/${dynamicRoute}/${userData?.role}/lpquery`}
                                       >
                                          View
                                       </Link>
                                       </div>
                                 {/* <===== UnAnswered ======> */}
                                 <hr className="mt-3"/>
                                 <div className="view-query mt-1">
                                    <h6 > Un-Answered</h6>
                                    <p >{unanswered}</p>
                                 </div>
                                    <div className="col col-12 d-flex justify-content-end ">
                                       <Link
                                          className="reg-btn"
                                          to={`/${dynamicRoute}/${userData?.role}/lpquery`}
                                       >
                                          View
                                       </Link>
                                    </div>
                              </div>
                           </div>
                        </div>
                       
                     </div>
                       {/* <============ Questions ===============> */}
                       <div
                        className="row justify-content-between cross-selling"
                        style={{ margin: "0" }}
                     >
                         
                                    {
                                       usertype === 'cp' ? (
                                          <>
                                         {
                                          fitScorePopup && (
                                             <Questions
                                             role={userType}
                                             userId={leadid}
                                             getFitScore={getFitScore}
                                             getEngScore={getEngScore}
                                             fitScoreAnswer={fitScoreAnswer}
                                             engScoreAnswer={engScoreAnswer}
                                             setFitscorePopup={setFitscorePopup}
                                             />
                                          )
                                         }
                                           {
                                             engScorePopup && (
                                           
                                                <EngQuestion
                                             role={userType}
                                             userId={leadid}
                                             getFitScore={getFitScore}
                                             getEngScore={getEngScore}
                                             fitScoreAnswer={fitScoreAnswer}
                                             engScoreAnswer={engScoreAnswer}
                                             setEngscorePopup={setEngscorePopup}
                                             />
                                                   
                                             )
                                          }
                                             </>
                                         
                                       )
                                          : 
                                          ( 
                                             <>
                                         {
                                          fitScoreInvPopup && (
                                             <InvQuestion
                                             role={userType}
                                             userId={leadid}
                                             getFitScore={getFitScore}
                                             getEngScore={getEngScore}
                                             fitScoreAnswer={fitScoreAnswer}
                                             engScoreAnswer={engScoreAnswer}
                                             setFitscorePopup={setFitscoreInvPopup}
                                             />
                                          )
                                         }
                                           {
                                             engScoreInvPopup && (
                                           
                                                <InvEngQuestion
                                             role={userType}
                                             userId={leadid}
                                             getFitScore={getFitScore}
                                             getEngScore={getEngScore}
                                             fitScoreAnswer={fitScoreAnswer}
                                             engScoreAnswer={engScoreAnswer}
                                             setEngscorePopup={setEngscoreInvPopup}
                                             />
                                                   
                                             )
                                          }
                                             </>
                                            
                                          )
                                    }
                        </div>
                  </div>
               </div>
            </div>
            
            {/* <!-- ======= Footer ======= --> */}
            <Footer
               logoLink={"/"}
               home={"/#home"}
               about={"/#about"}
               service={"/#service"}
               faq={"/#faq"}
               testimonial={"/#testimonials"}
            />
         </section>
      </>
   );
}

export default LeadDetails;
