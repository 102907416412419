import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Api_Base_Url, PUBLIC_PATH } from "../../../constant/matcher";
import FranchiseSidebar from "../../../components/franchise/FranchiseSidebar";
import Footer from "../../../components/Footer";
import { useSelector } from "react-redux";
import MyClientsCards from "../../../components/cardsDesigns/MyClientsCards";
import DashboardBanner from "../../../components/general/DashboardBanner";
import axios from "axios";
import AlertCompleteProfile from "../../../components/cardsDesigns/AlertCompleteProfile";
import Searchbar from "../../../components/Searchbar";
import Loader from "../../../components/general/Loader";

function AllLeads() {
  const [lead, setLead] = useState([]);
  const [filteredLead, setFilteredLead] = useState([]);
  const [notInitiated, setNotInitiated] = useState('')
  const [won, setWon] = useState('')
  const [lost, setLost] = useState('')
  const [registration, setRegistration] = useState('')
  const [follow, setFollow] = useState('')
  const [isloading, setIsLoading] = useState(true)
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [dynamicRoute, setdynamicRoute] = useState("");
  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
  }, [loggedInUserData]);

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  const getLead = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    const role = loginUserData.role
    const type = role === 'fm' ? '/fm' : ""
    const list = role === 'fm' ? '' : "/list"
    axios
      .get(`${Api_Base_Url}${type}/lead${list}?id=${id}&usertype=cp`)
      .then((response) => {
        const lead = response?.data?.lead
        setLead(lead);
        const notInitiated = lead.filter((result) => !result.stage).length;
        const wonLeads = lead.filter(result => result.stage === "Won").length;
        const lostLeads = lead.filter(result => result.stage === "Lost").length;
        const follow = lead.filter(result => result.stage === "Followup").length;
        const registration = lead.filter(result => result.status === 1).length;
        setNotInitiated(notInitiated)
        setWon(wonLeads)
        setLost(lostLeads)
        setFollow(follow)
        setRegistration(registration)

      });
  };

  useEffect(() => {
    getLead();
   
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, []);

 
  return (
    <>
     {
      isloading ? (
       <Loader/>
      )
      : 
      (
   
        <>
      <FranchiseSidebar navContWrapp={navContWrapp} />
      {loggedInUserData?.role === "cp" && <AlertCompleteProfile />}
      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        {/* banner sec */}
        {/* <div id="banner">
          <div className="container-fluid p-0">
            <div className="banner-img p-0">
              <img
                src={PUBLIC_PATH + "assets/img/dash-banner.jpg"}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div> */}
       
        <DashboardBanner />
        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to={"/" + dynamicRoute + "/" + loggedInUserData?.role}>
                Dashboard
              </Link>{" "}
              <i className="fa-solid fa-angle-right"></i> My Leads
            </p>
          </div>
        </div>
        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          style={{ background: "whitesmoke" }}
        >
          <div className="row mb-4">
            <div className="col">
              <MyClientsCards
                cardTitle={"Not Initiated"}
                cardValues={notInitiated}
                borderColor={2}
                bgGradient={2}
                textColor={2}
                iconsNumb={1}
              />
            </div>
            <div className="col">
              <MyClientsCards
                cardTitle={"Registration Initiated"}
                cardValues={registration}
                borderColor={4}
                bgGradient={4}
                textColor={4}
                iconsNumb={4}
              />
            </div>
            <div className="col">
              <MyClientsCards
                cardTitle={"Follow-up"}
                cardValues={follow}
                // updateTime={"Since last week"}
                borderColor={1}
                bgGradient={1}
                textColor={1}
                iconsNumb={5}
              />
            </div>
            <div className="col">
              <MyClientsCards
                cardTitle={"Won"}
                cardValues={won}
                // updateTime={"Since last week"}
                borderColor={3}
                bgGradient={3}
                textColor={3}
                iconsNumb={3}
              />
            </div>
            <div className="col">
              <MyClientsCards
                cardTitle={"Lost/NI"}
                cardValues={lost}
                // updateTime={"Since last week"}
                borderColor={2}
                bgGradient={2}
                textColor={2}
                iconsNumb={2}
              />
            </div>
            
          </div>
          <div className="row table__row__wrapp">
            <div className="client_table_container py-2">
              <div className="d-flex justify-content-between">
                <h2>My Leads</h2>
               <Searchbar
               lead={lead}
               setFilteredLead={setFilteredLead}
               />
              </div>
              <div className="w-100 overflow-auto">
                <table className="table table-striped client__table">
                  <thead>
                    <tr>
                      <th scope="col">Sr No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">User Type</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Products</th>
                      <th scope="col">Stage</th>
                      <th scope="col">Status</th>
                      <th scope="col">City</th>
                      <th scope="col">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredLead &&
                      filteredLead?.map((result, index) => {
                        return (
                          <tr key={result?.id}>
                            <td className="text-black">{index + 1}</td>
                            <td
                              style={{
                                color:
                                  result.status === 1
                                    ? "rgb(183, 183, 6)"
                                    : "inherit",
                                fontWeight:
                                  result.status === 1 ? "bold" : "inherit",
                              }}
                            >
                              {result?.name}
                            </td>

                            <td>
                              {result?.cpdata?.length > 0 ? "Client" : "Lead"}
                            </td>

                            <td>{result?.phone}</td>
                            {result?.product.length > 0 ? (
                              <td>
                                {result?.product
                                  .map((product) => {
                                    return product?.name;
                                  })
                                  .join(", ")}
                              </td>
                            ) : (
                              <td>&nbsp;</td>
                            )}
                            <td>{result?.stage ? result?.stage : "Not Initiated"}</td>
                            {result?.statusinfo?.length > 0 ? (
                              result?.statusinfo?.map((i) => {
                                return (
                                <td key={i?._id}>{i?.status}</td>
                                )
                              })
                            ) : (
                              <td>Not Initiated</td>
                            )}

                            {result?.cityinfo?.length > 0 ? (
                              result?.cityinfo?.map((i) => (
                                <td key={i?.city}>{i?.city}</td>
                              ))
                            ) : (
                              <td>&nbsp;</td>
                            )}
                            <td>
                              <Link to={`${result._id}/cp`}>
                                <i className="fa-solid fa-eye"></i>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
      </>
           )
          }
    </>
  );
}

export default AllLeads;
