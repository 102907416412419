import React from 'react'
import './form.css'
import { Link } from 'react-router-dom'

function Email ({ formData, setFormData }) {
  return (
    <>
      <div className='row my-3'>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Name*'
            name='email'  
            className='form-control'
            value={formData.name}
            onChange={
              (e) => setFormData({ ...formData, name: e.target.value })
            }
            required
          />
        </div>
      </div>
    </>
  )
}

export default Email
