import { createSlice } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'

const verifyToken = () => {
  const userStorage = localStorage.getItem('user-token')
  if (userStorage && userStorage !== 'undefined') {
    const decodedToken = jwt_decode(userStorage)
    const expiresIn = new Date(decodedToken.exp * 1000)
    // console.log(expiresIn);
    if (expiresIn < new Date()) {
      localStorage.removeItem('user-token')
      return null
    } else {
      return userStorage
    }
  } else {
    return null
  }
}
const verifyRmFmToken = () => {
  const userStorage = localStorage.getItem('user_Fm_Rm-token')
  if (userStorage && userStorage !== 'undefined') {
    const decodedToken = jwt_decode(userStorage)
    const expiresIn = new Date(decodedToken.exp * 1000)
    // console.log(expiresIn);
    if (expiresIn < new Date()) {
      localStorage.removeItem('user-token')
      return null
    } else {
      return userStorage
    }
  } else {
    return null
  }
}

const loginUserData = () => {
  const loginUser = localStorage.getItem('loginUserData');
  if(loginUser && loginUser !== 'undefined') {
    return JSON.parse(loginUser);
  } else {
    return null
  }
}
const qrVeryDetails = () => {
  const loginUser = localStorage.getItem('qrdetails');
  if(loginUser && loginUser !== 'undefined') {
    return JSON.parse(loginUser);
  } else {
    return null
  }
}
const authReducer = createSlice({
  name: 'userReducer',
  initialState: {
    userToken: verifyToken(),
    userTokenFmRm: verifyRmFmToken(),
    userData: loginUserData(),
    qrDetails: qrVeryDetails(),
  },
  reducers: {
    setLoginUserToken: (state, action) => {
      state.userToken = action.payload
    },
    setLoginUserTokenFmRm: (state, action) => {
      state.userTokenFmRm = action.payload
    },
    loggedInUserData: (state, action) => {
      state.userData = action.payload
    },
    setQrDetails: (state, action) => {
      state.qrDetails = action.payload
    },
    logout: state => {
      localStorage.removeItem('user-token')
      localStorage.removeItem('user_Fm_Rm-token')
      localStorage.removeItem('loginUserData')
      localStorage.removeItem('qrdetails')
      state.userToken = null
      state.userTokenFmRm = null
      state.userData = null
    }
  }
})

export const { setLoginUserToken, setLoginUserTokenFmRm, logout, loggedInUserData, setQrDetails } =
  authReducer.actions
export default authReducer.reducer
