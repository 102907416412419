import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { Api_Base_Url, PUBLIC_PATH, pdfpath } from "../../constant/matcher";
import FranchiseSidebar from "../../components/franchise/FranchiseSidebar";
import "../../styles/card.css";
import Footer from "../../components/Footer";
import LandingCards from "../../components/franchise/LandingCards";
import "../../styles/franchiseSecStyle.css";
import BarChartComp from "../../components/charts/BarChartComp";
import CryptoJS from "crypto-js";
import { useSelector } from "react-redux";
import FranchisePopup from "../../components/general/FranchisePopup";
import NumOfInvestor from "../../components/franchise/NumOfInvestor";
import NumOfCP from "../../components/franchise/NumOfCP";
import NoticeBoard from "../../components/general/NoticeBoard";
import CreateLead from "../../components/franchise/CreateLead";
import DashboardBanner from "../../components/general/DashboardBanner";
import AlertCompleteProfile from "../../components/cardsDesigns/AlertCompleteProfile";
import axios from "axios";
import { useDispatch } from "react-redux";
import { logout } from "../../store/reducer/authReducer";
import { ProfileCompletionContext } from "../../components/ProfileCompletionContext";
import { formatAmount, formatDate } from "../../utils/helper";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
function Dashboard() {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);

  // console.log(loggedInUserData);
  const [dynamicRoute, setdynamicRoute] = useState("");
  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
  }, [loggedInUserData]);

  const [show, setShow] = useState(false);
  const [createLeadPopup, setCreateLeadPopup] = useState(false);
  const closeCreateLead = () => {
    setCreateLeadPopup(false);
  };
  const [showButtons, setShowButtons] = useState(true);
  const [OptVerifyScreen, setOptVerifyScreen] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [declineAgreement, setDeclineAgreement] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(10);
  const [sipSum, setSipSum] = useState(0);
  const [equitySum, setEquitySum] = useState(0);
  const [mfSum, setMFSum] = useState(0);
  const [realSum, setRealSum] = useState(0);
  const [totalEUM, setTotalEUM] = useState("");
  const [invester, setInvester] = useState("");
  const [equityClient, setEquityClient] = useState("");
  const [realClient, setRealClient] = useState("");
  const [mutualClient, setMutualClient] = useState("");
  const [totaleqclient, setTotaleqclient] = useState("");
  const [totalsipclient, setTotalsipclient] = useState("");
  const [totallumpclient, setTotallumpclient] = useState("");
  const [totalrealclient, setTotalrealclient] = useState("");
  const [invEquity, setInvEquity] = useState("");
  const [invReal, setInvReal] = useState("");
  const [invsip, setInvsip] = useState("");
  const [invlump, setInvlump] = useState("");
  const [remptioneEquity, setRemptioneEquity] = useState("");
  const [remptioneReal, setRemptioneReal] = useState("");
  const [remptionesip, setRemptionesip] = useState("");
  const [remptionelump, setRemptionelump] = useState("");
  const [update, setUpdate] = useState("");
  const [notInitiated, setNotInitiated] = useState("");
  const [won, setWon] = useState("");
  const [lost, setLost] = useState("");
  const [registration, setRegistration] = useState("");
  const [follow, setFollow] = useState("");
  const { showAgreement, setShowAgreement, fetchProfileStatus } = useContext(
    ProfileCompletionContext
  );
  const userData = useSelector((state) => state?.userReducer?.userData);
  const logoutSlug = userData && userData?.slug;
  const [pdfExists, setPdfExists] = useState(true);
  const dispatch = useDispatch();
  // for quick invest
  const [searchParams, setSearchParams] = useSearchParams();
  const [quickPopup, setQuickPopup] = useState(false);
  const navigate = useNavigate();
  const removeQueryParams = () => {
    const param = searchParams.get("et");
    if (param) {
      searchParams.delete("et");
      setSearchParams(searchParams);
    }
  };
  const closePopupHandler = () => {
    removeQueryParams();
    setQuickPopup(false);
  };
  useEffect(() => {
    if (searchParams?.size > 0) {
      setQuickPopup(true);
    }
  }, [searchParams]);

  const [navShow, setNavShow] = useState(true);
  const [SmallWrapper, setSmallWrapper] = useState(" small-wrapper ");

  // const quickInvestDash = useRef()

  const [quickInvest, setQuickInvest] = useState({
    username: loggedInUserData?.eliteusername,
    password: loggedInUserData?.elitepassword,
    loginType: loggedInUserData?.eliterole,
    loginFor: loggedInUserData?.eliterole,
    callbackUrl: window.location.href,
  });
  // useEffect(() => {
  //   console.log(quickInvest);
  // }, [quickInvest]);

  const sixDigitOtpLimit = () => {
    let mobInpt = document.getElementById("otp__input_field");
    if (mobInpt.value.length > mobInpt.maxLength)
      mobInpt.value = mobInpt.value.slice(0, mobInpt.maxLength);
  };

  const [qickPass, setQickPass] = useState("");

  // for foramting encription
  var CryptoJSAesJson = {
    stringify: function (cipherParams) {
      var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j);
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(j.ct),
      });
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
      return cipherParams;
    },
  };

  useEffect(() => {
    var key = "W@ealth!@$#$(%#H(^%)";
    // Encrypt
    var EncryptedPass = CryptoJS.AES.encrypt(
      JSON.stringify(quickInvest.password),
      key,
      { format: CryptoJSAesJson }
    ).toString();

    // console.log(EncryptedPass)

    setQickPass(EncryptedPass);

    setQuickInvest({ ...quickInvest, [quickInvest.password]: EncryptedPass });
  }, []);

  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  // revenue charts data
  const revenueChartData = [
    {
      name: "Total",
      this_month: 761500,
      lastMonth: 1100000,
    },
    {
      name: "Eq",
      this_month: 2000000,
      lastMonth: 1000000,
    },
    {
      name: "MF",
      this_month: 1000000,
      lastMonth: 50000,
    },
    {
      name: "MF-SIP",
      this_month: 1500000,
      lastMonth: 50000,
    },
  ];

  const showMF = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    const role = loginUserData.role;
    axios
      .get(`${Api_Base_Url}/cp/getdetail?type=${role}&id=${id}`)
      .then((res) => {
        const data = res?.data?.data?.personalinfo;
        if (data.eliterole && data.eliteusername && data.elitepassword) {
          setShow(true);
        }
      });
  };

  const handleDecline = () => {
    setShowAgreement(false);
    dispatch(logout());
    navigate(logoutSlug);
  };

  const handleClick = async () => {
    setDeclineAgreement(false);
    setOptVerifyScreen(true);
    setShowButtons(false);
    try {
      // Make the API request
      const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
      const leadUserId = loginUserData?._id;

      const data = {
        cpid: leadUserId,
      };

      const response = await fetch(`${Api_Base_Url}/cp/genrateOTP`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      // Check if the API request was successful
      if (result.success) {
        // console.log(result.message);
      } else {
        // console.error(result.message);
      }
    } catch (error) {
      // Handle any errors during the API request
      // console.error(error);
    }
  };

  const handleVerify = () => {
    // Make the API request to verify the OTP
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const leadUserId = loginUserData?._id;

    // Prepare the request body
    const verifyData = {
      cpid: leadUserId,
      otp: otp,
    };

    fetch(`${Api_Base_Url}/cp/verifyotp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(verifyData),
    })
      .then((response) => response.json())
      .then((result) => {
        // Check if OTP verification was successful
        if (result.success) {
          setShowAgreement(false);
          fetchProfileStatus();
        } else {
          // Handle unsuccessful OTP verification
          setOtpError(true);
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimerSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    if (timerSeconds === 0) {
      setShowResend(true);
      clearInterval(timer);
    }
    // Clean up the interval when the component is unmounted
    return () => clearInterval(timer);
  }, [timerSeconds]);

  const resendOtp = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const leadUserId = loginUserData?._id;

    const data = {
      cpid: leadUserId,
    };
    const response = axios.post(`${Api_Base_Url}/cp/genrateOTP`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    setShowResend(false);
    setTimerSeconds(10);
  };

  const getUserData = async () => {
    try {
      const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
      const id = loginUserData._id;
      const type = loginUserData.role;
      const response = await axios.get(
        `${Api_Base_Url}/cp/getdetail?type=${type}&id=${id}`
      );
      const data = response?.data?.data;
      setPdf(data?.document?.agreement);
      console.log("pdf", data?.document?.agreement);
    } catch (error) {
      console.log("getUserData : ", error);
    }
  };

  const getSIP = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios
      .get(`${Api_Base_Url}/cp/dashboard/totalsip?cpid=${id}`)
      .then((res) => {
        setSipSum(res?.data?.data[0]?.sip[0]?.sum || 0);
        setEquitySum(res?.data?.data[0]?.equity[0]?.totalvalue || 0);
        setMFSum(res?.data?.data[0]?.mutualfund[0]?.totalvalue || 0);
        setRealSum(res?.data?.data[0]?.realestate[0]?.totalvalue || 0);
        setTotalEUM(
          (res?.data?.data[0]?.sip[0]?.sum || 0) +
            (res?.data?.data[0]?.equity[0]?.totalvalue || 0) +
            (res?.data?.data[0]?.mutualfund[0]?.totalvalue || 0) +
            (res?.data?.data[0]?.realestate[0]?.totalvalue || 0)
        );
        setUpdate(res?.data?.data[0]?.lastupdate[0]?.createdAt);
      });
  };

  const getMyinvester = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const leadUserId = loginUserData?._id;
    axios.get(`${Api_Base_Url}/cp/my-client?id=${leadUserId}`).then((res) => {
      const data = res?.data?.myClient;
      setInvester(data.length);
      const realClient = data?.filter((result) =>
        result?.product.some((name) => name.name === "Monthly Rental Income")
      ).length;
      const equityClient = data?.filter((result) =>
        result?.product.some((name) => name.name === "Equity Advisory")
      ).length;
      const mutualClient = data?.filter((result) =>
        result?.product.some((name) => name.name === "Mutual Fund")
      ).length;
      setRealClient(realClient);
      setEquityClient(equityClient);
      setMutualClient(mutualClient);
    });
  };

  // ==================== API for  Business Updated=========================
  const getEquity = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios.get(`${Api_Base_Url}/cp/businessupdate?cpid=${id}`).then((res) => {
      // setEquity(res?.data?.data[0]?.equity[0]?.sum || 0);
      setTotaleqclient(
        res?.data?.data[0]?.["totalclient-Equity"][0]?.clientcode || 0
      );
      setTotalrealclient(
        res?.data?.data[0]?.["totalclient-realstate"][0]?.clientcode || 0
      );
      setTotallumpclient(
        res?.data?.data[0]?.["totalclient-mf-lumpsum"][0]?.clientcode || 0
      );
      setTotalsipclient(
        res?.data?.data[0]?.["totalclient-mfsip"][0]?.clientcode || 0
      );
      setInvEquity(res?.data?.data[0]?.["invesment-Equity"][0]?.sum || 0);
      setInvReal(
        res?.data?.data[0]?.["invesment-realestate"][0]?.totalvalue || 0
      );
      setInvlump(res?.data?.data[0]?.["invesment-mf"][0]?.totalvalue || 0);
      setInvsip(res?.data?.data[0]?.["invesment-sip"][0]?.totalvalue || 0);
      setRemptioneEquity(res?.data?.data[0]?.["remption-equity"][0]?.sum || 0);
      setRemptioneReal(res?.data?.data[0]?.["remption-realstate"][0]?.sum || 0);
      setRemptionelump(res?.data?.data[0]?.["invesment-sip"][0]?.sum || 0);
      setRemptionesip(res?.data?.data[0]?.["invesment-sip"][0]?.sum || 0);
      // console.log("Total Client", res?.data?.data[0]?.["totalclient-Equity"][0]?.clientcode);
    });
  };

  const getLead = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios
      .get(`${Api_Base_Url}/cp/leads?sourceQId=${id}&usertype=invt`)
      .then((response) => {
        const lead = response?.data?.data;
        const notInitiated = lead.filter((result) => !result.stage).length;
        const wonLeads = lead.filter((result) => result.stage === "Won").length;
        const lostLeads = lead.filter(
          (result) => result.stage === "Lost"
        ).length;
        const follow = lead.filter(
          (result) => result.stage === "Followup"
        ).length;
        const registration = lead.filter(
          (result) => result.status === 1
        ).length;
        setNotInitiated(notInitiated);
        setWon(wonLeads);
        setLost(lostLeads);
        setFollow(follow);
        setRegistration(registration);
      });
  };

  useEffect(() => {
    showMF();
    getUserData();
    getSIP();
    getMyinvester();
    getEquity();
    getLead();
    fetch(`${pdfpath}${pdf}`).then((response) => {
      if (!response.ok) {
        setPdfExists(false);
      }
    });
  }, [pdf]);

  const data = [
    { name: "Group A", value: notInitiated },
    { name: "Group B", value: won },
    { name: "Group C", value: lost },
    { name: "Group D", value: follow },
    { name: "Group E", value: registration },
  ];
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#bf00ff"];
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
  }) => {
    if (value === 0) return null;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {value}
      </text>
    );
  };
  const filteredData = data.filter((entry) => entry.value !== 0);
  return (
    <>
      <FranchiseSidebar navContWrapp={navContWrapp} />

      <AlertCompleteProfile />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper  ${SmallWrapper}`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        {/* banner sec */}
        <DashboardBanner />
        <div className="breadcrumbs">
          <div className="container-fluid" style={{ padding: "0 10px" }}>
            <p>
              <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                Dashboard{" "}
              </Link>{" "}
              {/* <i className='fa-solid fa-angle-right'></i> Franchise */}
            </p>
          </div>
        </div>
        <div className="container-fluid mb-4" style={{ padding: "0 10px" }}>
          <div className="row">
            <div className="col-12 p-0">
              <div className="franchise_tab">
                <div className="tab_title_btn">
                  {/* <div className='container'> */}
                  <div
                    className="position-absolute"
                    style={{
                      zIndex: "999",
                      left: "50%",
                      right: "20%",
                      opacity: "0",
                      visiblity: "hidden",
                    }}
                  ></div>
                  {/* </div> */}
                  {show && (
                    <div className="btn me-3 reg-btn">
                      <form
                        method="post"
                        className="quickLogForm"
                        action="https://wealthelite.in/Login/login/validate-user"
                      >
                        <input
                          type="text"
                          placeholder="Name *"
                          name="username"
                          readOnly
                          value={quickInvest.username}
                        />
                        <input
                          type="text"
                          placeholder="Password *"
                          name="password"
                          readOnly
                          value={qickPass}
                        />
                        <input
                          type="text"
                          placeholder="Login Type *"
                          name="loginType"
                          readOnly
                          value={quickInvest.loginType}
                        />
                        <input
                          type="text"
                          placeholder="Login For *"
                          name="loginFor"
                          readOnly
                          value={quickInvest.loginFor}
                        />
                        <input
                          type="text"
                          placeholder="Callback *"
                          name="callbackUrl"
                          readOnly
                          value={window.location.href}
                        />
                        {}
                        <input
                          type="submit"
                          // ref={quickInvestDash}
                          value="Invest In MF"
                          style={{ marginLeft: "-28px" }}
                        />
                      </form>
                      {/* Quick Invest */}
                    </div>
                  )}

                  {/* <div className='btn me-3 reg-btn' onClick={() => setInvalidAccess(true)}>
                    Quick Invest  
                  </div> */}
                  {/* <div className='edit btn reg-btn'> */}
                  <div
                    className="btn reg-btn"
                    onClick={() => setCreateLeadPopup(true)}
                  >
                    {/* <i className='fa fa-pencil'></i> */}
                    Create lead
                  </div>
                </div>
              </div>
              {quickPopup && (
                <FranchisePopup closePopupHandler={closePopupHandler} />
              )}
            </div>
          </div>
        </div>

        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          // style={{ margin: '0 10px' }}
        >
          {/* for business_dashboard tab */}
          <div id="business_dashboard" className="tabcontent active">
            <div className="tab_title d-flex mb-3 justify-content-between align-items-center">
              <h3>
                <img
                  className="me-1"
                  src={PUBLIC_PATH + "assets/img/icons/assets.png"}
                  alt=""
                  width="25"
                />
                Total AUM{" "}
                <span className="title_amnt">
                  : &#8377; {formatAmount(totalEUM)}
                </span>{" "}
                <span className="title_updated">
                  last updated as on, {update ? formatDate(update) : ""}
                </span>
              </h3>
            </div>
            <div className="container-fluid px-0">
              <div className="row mb-3 frachise_plan_cards d-flex justify-content-between align-items-center">
                <LandingCards
                  cardTitle={"Equity"}
                  cardAmount={formatAmount(equitySum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/cash.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_up_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-up"></i>}
                />
                <LandingCards
                  cardTitle={"Mutual Fund"}
                  cardAmount={formatAmount(mfSum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/report.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_up_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-up"></i>}
                />
                <LandingCards
                  cardTitle={"Real Estate"}
                  cardAmount={formatAmount(realSum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/payment.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_up_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-up"></i>}
                />
                <LandingCards
                  cardTitle={"SIP Book"}
                  cardAmount={formatAmount(sipSum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/real-estate.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_down_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-down"></i>}
                />
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-8 franchise_chart_inv">
                  <div className="row">
                    <div className="col-md-6 mb-3 numb_inv_card">
                      {/* <div className='franchise_chart franchise_amnt_card'> */}
                      {/* =====Number of CP=========== */}
                      <NumOfCP
                        name={"Number of Investors"}
                        totalCP={invester ? invester : 0}
                        eqClients={equityClient ? equityClient : 0}
                        SIPClients={mutualClient ? mutualClient : 0}
                        RECLients={realClient ? realClient : 0}
                      />
                      {/* </div> */}
                    </div>
                    <div className="col-md-6 mb-3 pie_chart_cont">
                      {/* <div className='franchise_chart'> */}

                      {/* =======pie chart for cp===== */}
                      {loggedInUserData && loggedInUserData?.role === "cp" && (
                        <div className="franchise_amnt_dashboardInfo land_card_2">
                          <div className="wrap">
                            <h4>Lead Stage</h4>

                            <PieChart width={300} height={180}>
                              <Pie
                                data={data}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                              >
                                {filteredData.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ))}
                              </Pie>
                            </PieChart>
                            <div className=" d-flex  flex-wrap  justify-content-around ">
                              <div className=" d-flex  align-items-center ">
                                <span
                                  className="chart-dot me-2"
                                  style={{ backgroundColor: "#00C49F" }}
                                ></span>
                                {won} Won
                              </div>
                              <div className=" d-flex  align-items-center ">
                                <span
                                  className="chart-dot me-2"
                                  style={{ backgroundColor: "#0088FE" }}
                                ></span>
                                {notInitiated} Not Initiated
                              </div>
                              <div className=" d-flex  align-items-center ">
                                <span
                                  className="chart-dot me-2"
                                  style={{ backgroundColor: "#bf00ff" }}
                                ></span>
                                {registration} Registration Initiated
                              </div>
                              <div className=" d-flex  align-items-center ">
                                <span
                                  className="chart-dot me-2"
                                  style={{ backgroundColor: "#FF8042" }}
                                ></span>
                                {follow} Follow Up
                              </div>
                              <div className=" d-flex  align-items-center ">
                                <span
                                  className="chart-dot me-2"
                                  style={{ backgroundColor: "#FFBB28" }}
                                ></span>
                                {lost} Lost
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* =========Num Of Investor For FM========== */}
                      {loggedInUserData && loggedInUserData?.role === "fm" && (
                        <NumOfInvestor
                          totalInvestor={0}
                          eqClients={0}
                          SIPClients={0}
                          RECLients={0}
                        />
                      )}
                      {/* </div> */}
                    </div>
                    <div
                      className="revenue_barchar_container mb-3 col-md-12"
                      // style={{ width: '99%' }}
                    >
                      <div className="franchise_chart revenue_chart">
                        <div className="mb-3 chart_text d-flex justify-content-between align-items-center">
                          <h6 className="">
                            Revenue Update{" "}
                            <span className="revenue_amount">(&#8377; 0k)</span>
                          </h6>
                          <div className="more_details">
                            <div className="dropdown">
                              <form className="position-relative">
                                <i className="fa-solid fa-calendar-days calender_icon"></i>
                                <select className="form-select select_time_inpt">
                                  <option value="">This month</option>
                                  <option value="1">Quarterly</option>
                                  <option value="2">This year</option>
                                </select>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="revenue_barchart">
                          <BarChartComp
                            revenueChartData={revenueChartData}
                            xTags={"name"}
                            dataTags={"this_month"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 mb-3 meeting_list_grid">
                  <div className="meeting_sec">
                    <div className="adjustHeight">
                      <NoticeBoard />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 table_grid_container">
                  <div className="franchise_inve table_data_container">
                    <div className="table_sec_heading mb-3 d-flex align-items-center justify-content-between">
                      <h3>Business Update</h3>
                      <div className="dropdown">
                        <form className="position-relative">
                          <i className="fa-solid fa-calendar-days calender_icon"></i>
                          <select className="form-select select_time_inpt">
                            <option value="">This month</option>
                            <option value="1">Quarterly</option>
                            <option value="2">This year</option>
                          </select>
                        </form>
                      </div>
                    </div>
                    <div className="table_wrapper">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Equity</th>
                            <th scope="col">MF-SIP</th>
                            <th scope="col">MF-Lumpsum</th>
                            <th scope="col">Real Estate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Total Client</th>
                            <td>{totaleqclient}</td>
                            <td>{totalsipclient}</td>
                            <td>{totallumpclient}</td>
                            <td>{totalrealclient}</td>
                          </tr>
                          <tr>
                            <th scope="row">Investment</th>
                            <td>{invEquity}</td>
                            <td>{invsip}</td>
                            <td>{invlump}</td>
                            <td>{invReal}</td>
                          </tr>
                          <tr>
                            <th scope="row">Redemption</th>
                            <td>{remptioneEquity}</td>
                            <td>{remptionesip}</td>
                            <td>{remptionelump}</td>
                            <td>{remptioneReal}</td>
                          </tr>
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* =================create Lead Popup============== */}
        {createLeadPopup && (
          <CreateLead
            closeCreateLead={closeCreateLead}
            closeLeadPopup={closeCreateLead}
          />
        )}

        {/* =================Agreement============== */}
        {showAgreement && (
          <div className="form_popups popup__container">
            {/* <div className="pdf__viewer__slide mt-5"> */}
            <div
              id=""
              className={`set-margin set-padding position-relative set-border set-box-shadow center-block-horiz`}
            >
              <div className="responsive__pdf_btn" style={{ width: "350px" }}>
                <div className="responsive-wrapper responsive-wrapper-wxh-572x612">
                  <iframe
                    style={{ maxWidth: "100%", display: "block" }}
                    src={`${pdfpath}${pdf}`}
                    // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}
                    title="view_agreement"
                  ></iframe>
                </div>
                <div className="agreement__view bg-white text-center w-100">
                  <button onClick={() => setDeclineAgreement(true)}>
                    Decline
                  </button>

                  <button onClick={handleClick}>I Agree</button>
                </div>
                {/* =====accept agreement and verifyotp */}
                <div
                  className={`agreement__doc__wrap ${
                    OptVerifyScreen ? "" : "otpVerifyHide"
                  }`}
                >
                  <div className="doc__input_wrap text-center pt-3">
                    <img
                      style={{ width: "100px" }}
                      src={PUBLIC_PATH + "assets/img/icons/checked.png"}
                      alt="icon"
                    />
                    <div className="text-success text-center mt-2">
                      OTP sent to your registered Mobile Number
                    </div>
                    <div className="opt_input overflow-hidden mt-3">
                      <input
                        type="number"
                        placeholder="Enter OTP*"
                        maxLength="6"
                        required
                        autoComplete="new-password"
                        name="otp"
                        id="otp__input_field"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        onInput={sixDigitOtpLimit}
                      />
                      <div
                        className="verify h-100 fileIcon"
                        onClick={handleVerify}
                      >
                        Verify
                      </div>
                    </div>
                    {showResend && (
                      <span
                        onClick={resendOtp}
                        className="resend__otp text-uppercase mt-3"
                      >
                        Resend OTP
                      </span>
                    )}
                    {!showResend && (
                      <span>
                        Resend OTP in{" "}
                        <span id="resendTimer" className="mt-3">
                          {timerSeconds}s
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
            {/* <===============================> Decline Agreement <=========================> */}
            {declineAgreement && (
              <div className="cd-popup" role="alert">
                <div className="cd-popup-container">
                  <img
                    src={PUBLIC_PATH + "assets/img/icons/logout.png"}
                    alt="logoutLogo"
                    className="popupImg"
                  />
                  <h5>Are you sure you want to decline agreement?</h5>
                  <ul className="cd-buttons">
                    <li>
                      <Link onClick={handleDecline}>Yes</Link>
                    </li>
                    <li>
                      <Link onClick={() => setDeclineAgreement(false)}>No</Link>
                    </li>
                  </ul>
                  {/* <Link className="cd-popup-close img-replace"></Link> */}
                </div>
              </div>
            )}
          </div>
        )}
        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
    </>
  );
}

export default Dashboard;
