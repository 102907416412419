import React from "react";
import "../../styles/card.css";

const MyClientCard = (props) => {
  return (
    <>
      <div
        className={`card client__card radius-10 border-start border-0 border-3 card_border-${props.borderColor}`}
        style={{ height: "95%" }}
      >
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div>
              <p className="mb-0 client_card_title text-secondary text-uppercase">
                {props.cardTitle}
              </p>
              <h4 className={`my-1 card_text_clr_${props.textColor}`}>
                {props.cardValues}
              </h4>
              <p className="mb-0 font-13">{props.updateTime}</p>
            </div>
            <div
              className={`widgets-icons-2 rounded-circle bg-gradient-${props.bgGradient} text-white ms-auto`}
            >
              {props.iconsNumb === 1 && <i className="fa fa-list-check"></i>}
              {props.iconsNumb === 2 && <i className="fa fa-circle-xmark"></i>}
              {props.iconsNumb === 3 && <i className="fa fa-check"></i>}
              {props.iconsNumb === 4 && <i className="fa fa-question"></i>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyClientCard;
