import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Api_Base_Url } from "../../constant/matcher";

const authService = createApi({
  reducerPath: "loginHandler",
  baseQuery: fetchBaseQuery({
    baseUrl: Api_Base_Url, //https://xpandwealth.com/admin/api/v1/
    // credentials: "same-origin",
    mode: "cors",
    prepareHeaders: (headers, { getState }) => {
      headers.set("Access-Control-Allow-Origin", "*");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      // prelead login
      cpInvestorLogin: builder.mutation({
        query: (loginData) => {
          return {
            url: "user/login",
            method: "POST",
            body: loginData,
          };
        },
      }),
       
      // prelead user registeration ====>> not in use
      userRegistration: builder.mutation({
        query: (userDetails) => {
          return {
            url: "user/register",
            method: "POST",
            body: userDetails,
          };
        },
      }),
      // old otp api ======> not in use
      getOtp: builder.mutation({
        query: (phone) => {
          return {
            url: "/user/reset-password-phone",
            method: "POST",
            body: phone,
          };
        },
      }),
      // new otp api
      getNewOtp: builder.mutation({
        query: (phone) => {
          return {
            url: "user/loginwithotp",
            method: "POST",
            body: phone,
          };
        },
      }),
      // all fetch prodcts List api
      productSelect: builder.query({
        query: () => {
          return {
            url: "list/product-list",
            method: "GET",
          };
        },
      }),
      // fetch userType
      userTypeSelect: builder.query({
        query: () => {
          return {
            url: "list/category-list",
            method: "GET",
          };
        },
      }),
      // subCategory for profession
      subCategorySelect: builder.query({
        query: () => {
          return {
            url: "list/sub-category-list",
            method: "GET",
          };
        },
      }),
      // to get city list 
      getCityListApi: builder.query({
        query: () => {
          return {
            url: `list/city`,
            method: "GET",
          };
        },
      }),
      // newsletter endpoints
      sendNewsLetter: builder.mutation({
        query: (email) => {
          return {
            url: "news-letter/create-news-letter",
            method: "POST",
            body: email,
          };
        },
      }),
      // qr registration api
      userRegistrationByScan: builder.mutation({
        query: (userDetails) => {
          return {
            url: "lead/register",
            method: "POST",
            body: userDetails,
          };
        },
      }),
    };
  },
});

export const {
  useCpInvestorLoginMutation,
  useGetOtpMutation,
  useGetNewOtpMutation,
  useUserRegistrationMutation,
  useProductSelectQuery,
  useUserTypeSelectQuery,
  useSubCategorySelectQuery,
  useSendNewsLetterMutation,
  useGetCityListApiQuery,
  useUserRegistrationByScanMutation,
} = authService;
export default authService;
