import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import PureCounter from '@srexi/purecounterjs'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../styles/inquiryFormStyle.css'
import { PUBLIC_PATH } from '../constant/matcher'
import Footer from '../components/Footer'
import HeroForm from "../components/general/HeroForm";
import InterestedFrom from "../components/general/InterestedFrom";
import NewsLetter from "../components/general/NewsLetter";
import Header from '../components/Header';
import TestiCards from '../components/TestiCards';

function Homepage () {
  const [mobileSlick, setMobileSlick] = useState(false)
  
  const navigate = useNavigate();

  useEffect(() => {
    if (window.screen.width < 768) {
      setMobileSlick(true)
    } else {
      setMobileSlick(false)
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }, [])

  var settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  var productSetting = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  useEffect(() => {
    AOS.init({
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false
    })
  }, [])
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <Header headLogoLink="/investor" />
      {/* <!-- End Header --> */}
      {/* <!-- End Header --> */}

      {/* <!-- ======= Hero Section ======= --> */}
      <section id='hero' className='hero d-flex align-items-center'>
        <div className='container'>
          <div className='row gy-4 d-flex justify-content-between'>
            <div className='hero-form col-md-6 d-flex flex-column justify-content-center position-relative'>
              <h2 className='text-uppercase hero-heading' data-aos='fade-up'>
                Take a First Step Towards Your Independent Financial Journey
              </h2>
              <div className='position-absolute arrow-animated'>
                <img
                  src={PUBLIC_PATH + 'assets/img/arrowGif.gif'}
                  alt='arrow'
                  className=''
                />
              </div>
              <div className='banner-text'>
                <h3
                  data-aos='fade-up'
                  className='text-center text-white text-uppercase'
                >
                  Unlock Potential Of <br /> Unlimited Passive Income
                </h3>
              </div>
            </div>

            <div className='col-md-6 col-lg-4 hero-img' data-aos='zoom-out'>
              {/* <div className='inquiry snipcss-2o8Zx'>
                <form action='#' className='contact-form'>
                  <p className='description'>
                    BECOME A FINANCIAL <br /> PRODUCT DISTRIBUTOR
                  </p>
                  <div>
                    <input
                      type='text'
                      className='form-control rounded border-white mb-3 form-input'
                      id='name'
                      placeholder='Name'
                      required
                    />
                  </div>
                  <div>
                    <input
                      type='number'
                      className='form-control rounded border-white form-input'
                      id='mobile'
                      placeholder='Mobile Number'
                      maxLength='10'
                      required
                    />
                  </div>
                  <div>
                    <input
                      type='email'
                      className='form-control rounded border-white form-input mb-3 landing-email-input'
                      placeholder='Email'
                      required
                    />
                  </div>
                  <div>
                    <select className='form-select rounded border-white sel-inpt mb-3'>
                      <option value='2'>Investor</option>
                      <option value='1'>Channel Partner</option>
                    </select>
                  </div>
                  <div className='submit-button-wrapper mt-4'>
                    <button>SEND</button>
                  </div>
                </form>
              </div> */}
              <HeroForm />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero Section --> */}

      <main id='main'>
        {/* <!-- ======= About Us Section ======= --> */}
        <section id='about' className='about'>
          <div className='container' data-aos='fade-up'>
            <div className='row d-block d-lg-none'>
              <div className='section-header p-0'>
                <h2>About Xpand Wealth</h2>
              </div>
            </div>
            <div className='row gy-4'>
              <div
                className='col-lg-6 position-relative align-self-start order-lg-last order-first'
                data-aos='fade-left'
              >
                <img
                  src={PUBLIC_PATH + 'assets/img/about.jpg'}
                  className='img-fluid aboutImg'
                  // style={{display:"block",width:"100%",maxHeight: '360px'}}
                  alt='img'
                />
                {/* <a
                  rel='noopener noreferrer'
                  href='#!'
                  className='glightbox play-btn'
                >
                </a> */}
              </div>
              <div className='col-lg-6 content order-last  order-lg-first'>
                <h2 className='about-heading d-none d-lg-block mb-0'>
                  About Xpand Wealth
                </h2>
                <p>
                  Xpand Wealth, a new age platform with its state-of-the-art
                  technology makes your investment journey focused and
                  hassle-free. Xpand Wealth brings you the boutique of
                  Investments products like listed equity, Fixed Income
                  products, monthly rental income products etc. at one single
                  place.
                </p>
                <p className=''>
                  Xpand Wealth aggregate the best-in-class investment options
                  for you so that you can plan your financial goal easily. Xpand
                  Wealth brings you SEBI registered financial advisors with
                  strong industry experience at your fingertip who can guide you
                  to optimize your asset allocation strategy to achieve your
                  financial goals.
                </p>
                <ul className='tabs-cp-in'>
                  <li className='invest_btn me-3'>
                    <Link to='/' className={''}>
                      Channel Partner
                    </Link>
                  </li>
                  <li className=''>
                    <Link to='/investor' className=''>
                      Investor
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id='features' className='features pb-0'>
          <div className='container'>
            <div className='row d-block d-lg-none'>
              <div className='section-header p-0'>
                <h2>Why Xpand Wealth</h2>
              </div>
            </div>
            <div
              className='row gy-4 align-items-center features-item'
              data-aos='fade-up'
            >
              <div className='col-md-5' data-aos='fade-right'>
                <img
                  src={PUBLIC_PATH + 'assets/img/serv-3.jpg'}
                  className='img-fluid'
                  style={{
                    display: 'block',
                    maxHeight: '250px',
                    margin: 'auto',
                  }}
                  alt=''
                />
              </div>
              <div className='col-md-7' data-aos='fade-left'>
                <h2 className='mb-0 d-none d-lg-block about-heading'>
                  Why Xpand Wealth
                </h2>
                <ul className='flex-lists '>
                  <li>
                    {/* <h5> */}
                    <i className='bi bi-check'></i>
                    You get a 360-degree view of your financial investment needs
                    {/* </h5> */}
                  </li>
                  <li>
                    {/* <h5> */}
                    <i className='bi bi-check'></i>
                    Lets you take control of your financial plans.
                    {/* </h5> */}
                  </li>
                  <li>
                    {/* <h5> */}
                    <i className='bi bi-check'></i>
                    Financial Awareness Program
                    {/* </h5> */}
                  </li>
                  <li>
                    {/* <h5> */}
                    <i className='bi bi-check'></i>
                    Reports on Financial World to help you strategize
                    {/* </h5> */}
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- Features Item --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        {/* <!-- ======= Services Section ======= --> */}
        <section id='service' className='services'>
          <div className='container' data-aos='fade-up'>
            <div className='section-header p-0'>
              <h2>Financial Products</h2>
            </div>

            {/* sevice cards for mobile slider */}
            {mobileSlick === true && (
              <div className='row px-4'>
                <Slider {...productSetting}>
                  <div
                    // className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards moblieSlickCards_inves'
                    data-aos='zoom-in'
                    data-aos-delay='200'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={
                            PUBLIC_PATH + 'assets/img/logistic-service-1.jpg'
                          }
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                        >
                          Equity Investment Advisory
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          SEBI Registered Investment Advisors with strong track
                          record.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Value Investing Based approach for wealth generation.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Superior Risk Management advise for your portfolio.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Active Portfolio checkups and updates.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- End Card Item --> */}

                  <div
                    // className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards moblieSlickCards_inves'
                    data-aos='zoom-in'
                    data-aos-delay='100'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/mutual-fund.jpg'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                        >
                          Mutual Fund Investments
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          Model Mutual Fund Portfolio Construction.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Active Mutual Fund Return Optimization.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Monthly update of Mutual Fund Schemes.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Active Back-office services support.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- End Card Item --> */}

                  <div
                    // className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards moblieSlickCards_inves'
                    data-aos='zoom-in'
                    data-aos-delay='300'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/storage-service-1.jpg'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                        >
                          Monthly Rental Income Investments
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          Strong experienced team to support in Real Estate
                          Investments.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Guidance on rental yield from RERA compliant Real
                          Estate brokers.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Regular update on rental management in Commercial Real
                          Estate.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Documents facilitation.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- End Card Item --> */}

                  <div
                    // className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards moblieSlickCards_inves'
                    data-aos='zoom-in'
                    data-aos-delay='400'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/fund-investor.jpg'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                        >
                          Term Insurance
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          Basket of Insurance Schemes to choose from.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Need based Insurance Advise of schemes.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Strong support in claims settlement.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Strong back-office documents facilitation.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- End Card Item --> */}

                  <div
                    // className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards moblieSlickCards_inves'
                    data-aos='in-in'
                    data-aos-delay='400'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/health.png'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                        >
                          Health Insurance
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          Basket of Insurance Schemes to choose from.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Need based Insurance Advise of schemes.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Strong support in claims settlement.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Strong back-office documents facilitation.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    // className='col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards moblieSlickCards_inves'
                    data-aos='zoom-in'
                    data-aos-delay='600'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/dmat.jpg'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                        >
                          Demat Account
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          India’s reputed stock brokers on one single platform.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Reputed stock broker selection guide.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Demat Account opening Facilitation.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Document handling support.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    // className='col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards moblieSlickCards_inves'
                    data-aos='zoom-in'
                    data-aos-delay='600'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/loans.jpg'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                        >
                          Loan Syndication
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          Collaboration with various banks for loans facility.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Working Capital loan and overdraft loan arrangement
                          for SME/MSME.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Attractive Interest rate display board.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Document handling support.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    // className='col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards moblieSlickCards_inves'
                    data-aos='zoom-in'
                    data-aos-delay='600'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/marketing-support.jpg'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                          // style={{ textTransform: 'capitalize' }}
                        >
                          Fixed Income Products
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          Investment options of high rated Fixed income
                          products.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Regular update on AAA rated Fixed Deposits & Tax-free
                          Bonds.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          High Yielding debt portfolio guide.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Back-office support from Head office.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- End Card Item --> */}
                </Slider>
              </div>
            )}

            {/* services cards for large screens */}
            {mobileSlick === false && (
              <div className='row gy-4'>
                <div
                  className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='200'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/logistic-service-1.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Equity Investment Advisory
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        SEBI Registered Investment Advisors with strong track
                        record.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Value Investing Based approach for wealth generation.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Superior Risk Management advise for your portfolio.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Active Portfolio checkups and updates.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Card Item --> */}

                <div
                  className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/mutual-fund.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Mutual Fund Investments
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        Model Mutual Fund Portfolio Construction.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Active Mutual Fund Return Optimization.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Monthly update of Mutual Fund Schemes.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Active Back-office services support.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Card Item --> */}

                <div
                  className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='300'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/storage-service-1.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Monthly Rental Income Investments
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        Strong experienced team to support in Real Estate
                        Investments.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Guidance on rental yield from RERA compliant Real Estate
                        brokers.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Regular update on rental management in Commercial Real
                        Estate.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Documents facilitation.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Card Item --> */}

                <div
                  className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='400'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/fund-investor.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Term Insurance
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        Basket of Insurance Schemes to choose from.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Need based Insurance Advise of schemes.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Strong support in claims settlement.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Strong back-office documents facilitation.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Card Item --> */}

                <div
                  className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='400'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/health.png'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Health Insurance
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        Basket of Insurance Schemes to choose from.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Need based Insurance Advise of schemes.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Strong support in claims settlement.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Strong back-office documents facilitation.
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <div
                className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                data-aos='fade-up'
                data-aos-delay='500'>
                <div className='card'>
                  <div className='card-img'>
                    <img
                      src={PUBLIC_PATH + 'assets/img/health.png'}
                      alt=''
                      className='img-fluid'
                    />
                  </div>
                  <h3>
                    <a
                      rel='noopener noreferrer'
                      href='#!'
                      className='stretched-link'
                    >
                      Health
                    </a>
                  </h3>
                  <ul>
                    <li>
                      <i className='bi bi-check'></i>
                      
                    </li>
                    <li>
                      <i className='bi bi-check'></i>
                      
                    </li>
                    <li>
                      <i className='bi bi-check'></i>
                      
                    </li>
                    <li>
                      <i className='bi bi-check'></i>
                      
                    </li>
                  </ul>
                </div>
              </div> */}
                {/* <!-- End Card Item --> */}

                <div
                  className='col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='600'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/dmat.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Demat Account
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        India’s reputed stock brokers on one single platform.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Reputed stock broker selection guide.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Demat Account opening Facilitation.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Document handling support.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className='col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='600'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/loans.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Loan Syndication
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        Collaboration with various banks for loans facility.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Working Capital loan and overdraft loan arrangement for
                        SME/MSME.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Attractive Interest rate display board.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Document handling support.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className='col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='600'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/marketing-support.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                        // style={{ textTransform: 'capitalize' }}
                      >
                        Fixed Income Products
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        Investment options of high rated Fixed income products.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Regular update on AAA rated Fixed Deposits & Tax-free
                        Bonds.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        High Yielding debt portfolio guide.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Back-office support from Head office.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Card Item --> */}
              </div>
            )}
          </div>
        </section>
        {/* <!-- End Services Section --> */}

        {/* <!-- ======= Call To Action Section ======= --> */}
        <section
          id='call-to-action'
          className='call-to-action call-to-action-cp pt-0'
        >
          <div className='row'>
            <div className='section-header'>
              {/* <span>Features</span> */}
              <h2 className='mb-0'>XPAND SUPPORT</h2>
            </div>
          </div>
          <div className='container-fluid' data-aos='zoom-out'>
            <div className='row justify-content-center p-0'>
              <div className='call-sec-img p-0'>
                <img
                  src={PUBLIC_PATH + 'assets/img/investor-support.png'}
                  className=''
                  alt='call-to-atcn'
                />
              </div>
              {/* <div className='col-lg-8 text-center'>
                <h3>Start your Journey with Us</h3>
                <p>
                  Start your investment journey with us and let the wealth generation happen in most pleasurable way.
                </p>
                <p>
                Xpand Wealth will take care of all your financial need being taken care by industry experts having deep domain knowledge and track record of wealth generation.
                </p>
                <p>
                With Xpand Wealth, make yourself knowledge ready and increase your financial wisdom.
                </p>
                <Link to='' className='cta-btn'>
                  Call To Action
                </Link>
              </div> */}
            </div>
          </div>
        </section>
        {/* <!-- End Call To Action Section --> */}

        {/* <!-- ======= Testimonials Section ======= --> */}
        <section id='testimonials' className='testimonials'  style={{paddingBottom:'90px'}}>
          <div className='container-fluid'>
            <div className='row'>
              <div className='section-header mb-0 pb-0'>
                {/* <span>Testimonials</span> */}
                <h2 style={{ color: 'white', margin: 0, paddingBottom: '20px' }}>Testimonials</h2>
              </div>
            </div>
            <div className='row'>
            <div className='col-lg-12 px-5 testiCards_arrow investor__testimonial'>
                <Slider {...settings}>
                  <TestiCards 
                    testiPara={`Proin iaculis purus consequat sem cure digni ssim donec
                    porttitora entum suscipit rhoncus. Accusantium quam,
                    ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                    risus at semper.`}
                    writerName={'Daiane smith'}
                    location={'London, England'}
                  />
                  <TestiCards 
                    testiPara={`Proin iaculis purus consequat sem cure digni ssim donec
                    porttitora entum suscipit rhoncus. Accusantium quam,
                    ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                    risus at semper.`}
                    writerName={'Daiane smith'}
                    location={'London, England'}
                  />
                  <TestiCards 
                    testiPara={`Proin iaculis purus consequat sem cure digni ssim donec
                    porttitora entum suscipit rhoncus. Accusantium quam,
                    ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                    risus at semper.`}
                    writerName={'Daiane smith'}
                    location={'London, England'}
                  />
                  <TestiCards 
                    testiPara={`Proin iaculis purus consequat sem cure digni ssim donec
                    porttitora entum suscipit rhoncus. Accusantium quam,
                    ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                    risus at semper.`}
                    writerName={'Daiane smith'}
                    location={'London, England'}
                  />
                </Slider>
              </div>
            </div>
          </div>
        </section>

        <section className='testi_iframe px-3 pb-0'>
          <div className='container'>
            <div className='row'>
              <div className='video-responsive'>
                <iframe
                  width='100%'
                  height='500'
                  className='youtubeIframe'
                  src={`https://www.youtube.com/embed/dEVbhejrVNA`}
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  title='Embedded youtube'
                />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Testimonials Section --> */}

        {/* <!-- ======= Frequently Asked Questions Section ======= --> */}
        <section id='faq' className='faq'>
          <div className='container' data-aos='fade-up'>
            <div className='section-header'>
              {/* <span>Frequently Asked Questions</span> */}
              <h2>Frequently Asked Questions</h2>
            </div>

            <div
              className='row justify-content-center'
              data-aos='fade-up'
              data-aos-delay='200'
            >
              <div className='col-lg-10'>
                <div className='accordion accordion-flush' id='faqlist'>
                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-1'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        How to open your account with Xpand Wealth?
                      </button>
                    </h3>
                    <div
                      id='faq-content-1'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        It’s hassle-free and simple. You need to download the
                        app and fill your credentials.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-2'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Do I need to submit money as fee to be on Xpand
                        Platform?
                      </button>
                    </h3>
                    <div
                      id='faq-content-2'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        No upfront fee is required to use the Xpands Wealth App.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-3'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        What Investment products will I get while using Xpand
                        Wealth?
                      </button>
                    </h3>
                    <div
                      id='faq-content-3'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        You will get host of investment products at one place.
                        Xpand Wealth has a product basket of Equity Investment
                        Advisory, Mutual Fund Investment, Monthly Income
                        Products, Corporate Fixed Deposit, Insurance Products
                        and Bonds.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-4'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Does Xpand Wealth helps me to open my Demat account.
                      </button>
                    </h3>
                    <div
                      id='faq-content-4'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        {/* <i className='bi bi-question-circle question-icon'></i> */}
                        Yes, Xpand Wealth helps you in choosing the right stock
                        broker to open your account.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-5'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Does Xpand Wealth helps me in Investing in Mutual Funds?
                      </button>
                    </h3>
                    <div
                      id='faq-content-5'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        Yes, it will help you to invest in right mutual fund
                        schemes according to your need.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-6'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Do I get advisory for Equity Investments?
                      </button>
                    </h3>
                    <div
                      id='faq-content-6'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        Yes, you will get advisory for your equity portfolio
                        from SEBI registered Investment advisors.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-7'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Do I get regular update on Fixed Income product
                        investments?
                      </button>
                    </h3>
                    <div
                      id='faq-content-7'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        Yes, you will be updated regularly about high rated
                        bonds and NCDs.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-8'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Does Xpand Wealth helps me in finding monthly income
                        investment options?
                      </button>
                    </h3>
                    <div
                      id='faq-content-8'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        Yes, Xpand Wealth helps you to invest in Commercial Real
                        Estate to get good monthly rental income.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-9'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Do I get back-office support for my documentation need?
                      </button>
                    </h3>
                    <div
                      id='faq-content-9'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        Yes, Xpand Wealth will facilitate you for all your
                        documentation related need.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-10'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Do I get any knowledge building support from Xpand
                        Wealth?
                      </button>
                    </h3>
                    <div
                      id='faq-content-10'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        Yes, you can improve your wisdom by reading the regular
                        newsletter, Factsheets and investment learning series
                        from Xpand Wealth.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Frequently Asked Questions Section --> */}
      </main>
      {/* <!-- End #main --> */}

      <section id='get-quote' className='interested mt-0 pt-0'>
        <div className='container'>
          <div className='row'>
            <div className='section-header' data-aos='fade-up'>
              {/* <span>Get A Quote</span> */}
              <h2>Register Now</h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6' data-aos='fade-right'>
              <div className='form-img' style={{ height: '100%' }}>
                <img
                  src={PUBLIC_PATH + 'assets/img/get-tch.jpg'}
                  alt=''
                  className='img-responsive'
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            </div>
            <div className='col-lg-6' data-aos='fade-left'>
              <InterestedFrom />
            </div>
          </div>
        </div>
      </section>

      <section
        className='container news-letter footer-top pt-0 pb-2'
        id='News-letter'
      >
        <hr />
              
            {/* newsletter */}
            <NewsLetter />
        <hr />
      </section>

      {/* <!-- ======= Footer ======= --> */}
      {/* <Footer logoLink={'/investor'}/> */}
      <footer id='footer' className='footer'>
        <div className='container'>
          <div className='row gy-4'>
            <div className='col-lg-5 col-md-12 footer-info'>
              <Link
                // rel='noopener noreferrer'
                to='/investor'
                className='logo d-flex align-items-center'
              >
                <img
                  src={PUBLIC_PATH + 'xPandLogo.png'}
                  alt='footer-logo'
                  className='img-fluid'
                />
              </Link>
              <p className="ps-2">
                Xpand Wealth, a new age platform with its state-of-the-art
                financial distributor network model, offers one stop solution to
                help channel partners to expand their business horizon. Xpand
                Wealth with its unique blend of technology in investment
                management helps in building bespoke investment solutions
                encompassing Financial advisory, Mutual fund Investments and
                Monthly rental income investments.
              </p>
              {/* <div className='social-links d-flex mt-4'>
                <a rel='noopener noreferrer' href='#!' className='twitter'>
                  <i className='bi bi-twitter'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='facebook'>
                  <i className='bi bi-facebook'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='instagram'>
                  <i className='bi bi-instagram'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='linkedin'>
                  <i className='bi bi-linkedin'></i>
                </a>
              </div> */}
            </div>

            <div className='col-lg-2 col-6 footer-links'>
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a rel='noopener noreferrer' href='/#hero'>
                    Home
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href='/#about'>
                    About
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href='/#service'>
                    Products
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href='/#faq'>
                    FAQs
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href='/#testimonials'>
                    Testimonials
                  </a>
                </li>
                <li>
                  <Link to='/login/team'>Team Login</Link>
                </li>
                {/* <li>
                  <Link to='/franchise'>Partner Login</Link>
                </li>
                <li>
                  <Link to='/form-by-scan'>Qr Scan</Link>
                </li> */}
              </ul>
            </div>

            <div className='col-lg-5 col-md-12 footer-contact'>
              <h4>Contact Us</h4>
              <p>
                <div>
                  <h6 className='regOff'>REGISTERED OFFICE:</h6>
                  <div className='mb-2'>
                    109-P NO-4 LSC, Shrestha Vihar, New Delhi- 110092
                  </div>
                </div>
                {/* <br /> */}
                <div>
                  <h6 className='regOff'>CORPORATE OFFICE:</h6>
                  <p className='mb-3'>
                    304, Tower 4, Assotech Business Cresterra, Sector-135,
                    Noida–201304
                  </p>
                </div>
                <div className='' style={{ lineHeight: 0 }}>
                  <strong>Phone:</strong> +91-8800308006
                </div>
                <br />
                <div className='mb-3' style={{ lineHeight: 1 }}>
                  <strong>For Investor Support:</strong> support@xpandwealth.com
                </div>
                {/* <br /> */}
                <div className='' style={{ lineHeight: 1 }}>
                  <strong>For Business Partner Support:</strong>{' '}
                  help@xpandwealth.com
                </div>
                <br />
              </p>
            </div>
          </div>
        </div>

        <div className='container mt-4'>
          <div className='copyright'>
            &copy; Copyright{' '}
            <strong>
              <span>Xpand Wealth</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
      {/* <!-- End Footer --> */}
      {/* <!-- End Footer --> */}
    </>
  )
}

export default Homepage
