import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FranchiseSidebar from "../components/franchise/FranchiseSidebar";
import { useSelector } from "react-redux";
import DashboardBanner from "../components/general/DashboardBanner";
import Footer from "../components/Footer";
import axios from "axios";
import { Api_Base_Url } from "../constant/matcher";
import CPBusinessPlan from "../components/CPBusinessPlan";
import Loader from "../components/general/Loader";
import BarChartComp from "../components/charts/BarChartComp";
import { formatNumberWithCommas } from "../utils/helper";

const RMBusinessDesign = () => {
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [isloading, setIsLoading] = useState(true);
  const [dynamicRoute, setdynamicRoute] = useState("");
  const [showMutualFund, setshowMutualFund] = useState(false);
  const [showEquity, setShowEquity] = useState(false);
  const [business, setBusiness] = useState([]);
  const [formData, setFormData] = useState({
    mfRetail: {
      reatailCA: "",
      clientPerFamily: 1,
      numberOfFamily: "",
      averageBusinessPerClient: "",
      growth: 10,
    },
    mfHNI: {
      HNICA: "",
      clientPerFamily: 1,
      numberOfFamily: "",
      averageBusinessPerClient: "",
      growth: 10,
    },
    equity: {
      equityCA: "",
      clientPerFamily: 1,
      numberOfFamily: "",
      averageBusinessPerClient: "",
      growth: 10,
    },
  });

  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [loggedInUserData]);

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === "showMutualFund") {
      setshowMutualFund(checked);
    } else if (name === "showEquity") {
      setShowEquity(checked);
    }
  };

  const hideDesignArea = (e) => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    e.preventDefault();
    axios.get(`${Api_Base_Url}/rm/recalculate?id=${id}`).then((res) => {
      setBusiness("");
    });
  };

  const handleInputChange = (event, category, field) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [field]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    const retailgrowth = formData.mfRetail.growth / 100;
    const hnigrowth = formData.mfHNI.growth / 100;
    const eqgrowth = formData.equity.growth / 100;
    const requestBody = {
      retailCA: formData.mfRetail.reatailCA,
      HNICA: formData.mfHNI.HNICA,
      equityCA: formData.equity.equityCA,
      retailClients: formData.mfRetail.clientPerFamily,
      retailFamily: formData.mfRetail.numberOfFamily,
      retailAverage: formData.mfRetail.averageBusinessPerClient,
      retailgrowth: retailgrowth,
      hniClients: formData.mfHNI.clientPerFamily,
      hniFamily: formData.mfHNI.numberOfFamily,
      hniAverage: formData.mfHNI.averageBusinessPerClient,
      hnigrowth: hnigrowth,
      equityClients: formData.equity.clientPerFamily,
      equityFamily: formData.equity.numberOfFamily,
      equityAverage: formData.equity.averageBusinessPerClient,
      equitygrowth: eqgrowth,
      usertype1: showEquity ? "equity" : null,
      usertype2: showMutualFund ? "mf" : null,
      
    };
    // console.log(requestBody);

    fetch(`${Api_Base_Url}/rm/designbussiness?id=${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          getBusinessDesign();
        }
        setFormData({
          mfRetail: {
            clientPerFamily: "",
            numberOfFamily: "",
            averageBusinessPerClient: "",
            growth: "",
          },
          mfHNI: {
            clientPerFamily: "",
            numberOfFamily: "",
            averageBusinessPerClient: "",
            growth: "",
          },
          equity: {
            clientPerFamily: "",
            numberOfFamily: "",
            averageBusinessPerClient: "",
            growth: "",
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getBusinessDesign = async () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    await axios
      .get(`${Api_Base_Url}/rm/designbussiness?id=${id}`)
      .then((res) => {
        setBusiness(res?.data?.designBusiness);

        // console.log(res?.data?.designBusiness);
      });
  };

  const mfData = business && business?.find((item) => item?.userType === "mf");
  const equityData =
    business && business?.find((item) => item?.userType === "equity");

  let combinedData = [];

  if (mfData && equityData) {
    // Both "MF" and "Equity" data are available, so combine them.
    combinedData = mfData?.yearlyData?.map((mfYear, index) => ({
      year: mfYear?.year,
      equityTotalClients: equityData?.yearlyData[index]?.equityTotalClients,
      equityRevenue: equityData?.yearlyData[index]?.equityRevenue,
      mfRetailTotalClients: mfYear?.retailTotalClients,
      mfRetailRevenue: mfYear?.retailRevenue,
      mfHNITotalClients: mfYear?.hniTotalClients,
      mfHNIRevenue: mfYear?.hniRevenue,
    }));
  } else if (mfData) {
    // Only "MF" data is available, so use it directly.
    combinedData = mfData?.yearlyData?.map((mfYear) => ({
      year: mfYear?.year,
      mfRetailTotalClients: mfYear?.retailTotalClients,
      mfRetailRevenue: mfYear?.retailRevenue,
      mfHNITotalClients: mfYear?.hniTotalClients,
      mfHNIRevenue: mfYear?.hniRevenue,
    }));
  } else if (equityData) {
    // Only "Equity" data is available, so use it directly.
    combinedData = equityData?.yearlyData?.map((equityYear) => ({
      year: equityYear?.year,
      equityTotalClients: equityYear?.equityTotalClients,
      equityRevenue: equityYear?.equityRevenue,
    }));
  }

    // revenue charts data
    const revenueChartData = combinedData?.map((yearData) => ({
      year: yearData.year,
      Total_Revenue: ((yearData.equityRevenue || 0) +
      (yearData.mfRetailRevenue || 0) +
      (yearData.mfHNIRevenue || 0)) * 0.20,
  }));
  
  const evenYearsChartData = revenueChartData.filter((data) => data.year % 2 === 0);


  useEffect(() => {
    getBusinessDesign();
  }, []);

  return (
    <>
      {isloading ? (
        <Loader />
      ) : (
        <>
          <FranchiseSidebar navContWrapp={navContWrapp} />
          <section
            className={`dash-content-wrapper small-wrapper`}
            style={{ paddingLeft: navShow ? "230px" : "70px" }}
          >
            <DashboardBanner />
            <div className="breadcrumbs">
              <div className="container">
                <p>
                  <Link to={"/" + dynamicRoute + "/" + loggedInUserData?.role}>
                    Dashboard
                  </Link>{" "}
                  <i className="fa-solid fa-angle-right"></i> Design Business
                  Plan
                </p>
              </div>
            </div>
            <div
              className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
              style={{ background: "whitesmoke" }}
            >
              {!business.length > 0 ? (
                <div className="row table__row__wrapp">
                  <div className="client_table_container py-2 ">
                    <div className="d-flex ">
                      <h2>Design Business Plan</h2>
                    </div>
                    <hr />
                    <div className="w-100 overflow-auto ">
                      <div className="col">
                        <h6>Business Vertical</h6>
                        <p>
                          Choose one or both options to design your business
                          plan:
                        </p>
                        <div className=" vertical mt-3">
                          <label htmlFor="mutualFund">
                            Mutual Fund
                            <input
                              type="checkbox"
                              name="showMutualFund"
                              id="mutualFund"
                              checked={showMutualFund}
                              onChange={handleChange}
                            />
                          </label>
                          <label htmlFor="equity">
                            Equity
                            <input
                              type="checkbox"
                              name="showEquity"
                              id="equity"
                              checked={showEquity}
                              onChange={handleChange}
                            />
                          </label>
                        </div>
                        {(showMutualFund || showEquity) && (
                          <form action="" onSubmit={handleSubmit}>
                            <table className="table table-striped client__table vertical-table">
                              <thead>
                                <tr>
                                  <th>Projected Client & Business</th>
                                  <th>No. of CA</th>
                                  <th>Client per Family</th>
                                  <th>No. of Family</th>
                                  <th>Client growth per year (%)</th>
                                  <th>Average Business per Client (Rs)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {showMutualFund && (
                                  <>
                                    <tr>
                                      <td>MF Retail</td>
                                      <td>
                                        <input required type="text" 
                                        value={formData.mfRetail.reatailCA}
                                        onChange={(event) => handleInputChange(
                                          event,
                                          "mfRetail",
                                          "reatailCA"
                                        )}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          required
                                          type="text"
                                          value={
                                            formData.mfRetail.clientPerFamily
                                          }
                                          onChange={(event) =>
                                            handleInputChange(
                                              event,
                                              "mfRetail",
                                              "clientPerFamily"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          required
                                          type="text"
                                          value={
                                            formData.mfRetail.numberOfFamily
                                          }
                                          onChange={(event) =>
                                            handleInputChange(
                                              event,
                                              "mfRetail",
                                              "numberOfFamily"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          required
                                          type="text"
                                          value={formData.mfRetail.growth}
                                          onChange={(event) =>
                                            handleInputChange(
                                              event,
                                              "mfRetail",
                                              "growth"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          required
                                          type="text"
                                          value={
                                            formData.mfRetail
                                              .averageBusinessPerClient
                                          }
                                          onChange={(event) =>
                                            handleInputChange(
                                              event,
                                              "mfRetail",
                                              "averageBusinessPerClient"
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>MF HNI</td>
                                      <td>
                                        <input required type="text" 
                                        value={formData.mfHNI.HNICA}
                                        onChange={(event) => handleInputChange(
                                          event,
                                          "mfHNI",
                                          "HNICA"
                                        )}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          required
                                          type="text"
                                          value={formData.mfHNI.clientPerFamily}
                                          onChange={(event) =>
                                            handleInputChange(
                                              event,
                                              "mfHNI",
                                              "clientPerFamily"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          required
                                          type="text"
                                          value={formData.mfHNI.numberOfFamily}
                                          onChange={(event) =>
                                            handleInputChange(
                                              event,
                                              "mfHNI",
                                              "numberOfFamily"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          required
                                          type="text"
                                          value={formData.mfHNI.growth}
                                          onChange={(event) =>
                                            handleInputChange(
                                              event,
                                              "mfHNI",
                                              "growth"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          required
                                          type="text"
                                          value={
                                            formData.mfHNI
                                              .averageBusinessPerClient
                                          }
                                          onChange={(event) =>
                                            handleInputChange(
                                              event,
                                              "mfHNI",
                                              "averageBusinessPerClient"
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {showEquity && (
                                  <tr>
                                    <td>Equity</td>
                                    <td>
                                        <input required type="text" 
                                         value={formData.equity.equityCA}
                                         onChange={(event) => handleInputChange(
                                           event,
                                           "equity",
                                           "equityCA"
                                         )}
                                        />
                                      </td>
                                    <td>
                                      <input
                                        required
                                        type="text"
                                        value={formData.equity.clientPerFamily}
                                        onChange={(event) =>
                                          handleInputChange(
                                            event,
                                            "equity",
                                            "clientPerFamily"
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        required
                                        type="text"
                                        value={formData.equity.numberOfFamily}
                                        onChange={(event) =>
                                          handleInputChange(
                                            event,
                                            "equity",
                                            "numberOfFamily"
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        required
                                        type="text"
                                        value={formData.equity.growth}
                                        onChange={(event) =>
                                          handleInputChange(
                                            event,
                                            "equity",
                                            "growth"
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        required
                                        type="text"
                                        value={
                                          formData.equity
                                            .averageBusinessPerClient
                                        }
                                        onChange={(event) =>
                                          handleInputChange(
                                            event,
                                            "equity",
                                            "averageBusinessPerClient"
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <button
                              type="submit"
                              // onClick={showDesignArea}
                              className="reg-btn mt-3"
                              style={{ float: "right" }}
                            >
                              Calculate
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row table__row__wrapp">
                  <div className="client_table_container py-2">
                    <div className="revenue_barchart">
                      <BarChartComp
                         revenueChartData={evenYearsChartData}
                         xTags={"year"}
                         dataTags={"Total_Revenue"}
                      />
                    </div>
                  </div>

                  {business && (
                    <div className="client_table_container py-2 mt-3">
                      <h5>Mutual Fund and Equity Advisory Business Plan</h5>
                      <table className="table table-bordered border-light table-hover table-striped client__table vertical-table">
                        <tr
                          className="top-border"
                          style={{
                            backgroundColor: "#08554e",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          <td className="extra" style={{ color: "white" }}>
                            Year
                          </td>
                          {equityData ? <th>Equity Client</th> : null}
                          {mfData ? <th> MF Retail Client</th> : null}
                          {mfData ? <th> MF HNI Client</th> : null}
                          <td>Monthly payout & Bonus (Rs)</td>
                          <td>Annual payout & Bonus (Rs)</td>
                        </tr>
                        <tbody>
                          {combinedData?.map((yearData, yearIndex) => (
                            <tr key={yearData.year}>
                              <td style={{ color: "black" }}>
                                {yearData?.year}
                              </td>
                              {equityData ? (
                                <>
                                  <td>{yearData?.equityTotalClients}</td>
                                </>
                              ) : null}
                              {mfData ? (
                                <>
                                  <td>{yearData?.mfRetailTotalClients}</td>

                                  <td>{yearData?.mfHNITotalClients}</td>
                                </>
                              ) : null}
                              <td>
                               {
                                formatNumberWithCommas(Math.round(
                                ( ( (yearData?.equityRevenue ?? 0) + 
                                    (yearData?.mfRetailRevenue ?? 0) +
                                    (yearData?.mfHNIRevenue ?? 0)) * 0.20) / 12
                                ))
                               }
                              </td>
                              <td>
                                {formatNumberWithCommas(Math.round((
                                 ((yearData?.equityRevenue ?? 0) +
                                 (yearData?.mfRetailRevenue ?? 0) +
                                 (yearData?.mfHNIRevenue ?? 0)) * 0.20)
                                ))}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button
                        onClick={hideDesignArea}
                        className="reg-btn mt-3"
                        style={{ float: "right" }}
                      >
                        Recalculate
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            <Footer />
          </section>
        </>
      )}
    </>
  );
};

export default RMBusinessDesign;
