import { createSlice } from '@reduxjs/toolkit'

const dashboardReducer = createSlice({
  name: 'dasboardReducers',
  initialState: {
    homeBanner: '',
    profileDetails:'',
    additionalId: '',
  
  },
  reducers: {
    setHomeBanner: (state, action) => {
      state.homeBanner = action.payload
    },
    setProfileDetails: (state, action) => {
      state.profileDetails = action.payload
    },
    setAdditionalId: (state, action) => {
      state.additionalId = action.payload;
    },
  }
})

export const { setHomeBanner, setProfileDetails, setAdditionalId } = dashboardReducer.actions

export default dashboardReducer.reducer
