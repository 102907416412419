import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import DashboardBanner from "../../components/general/DashboardBanner";
import { Link } from "react-router-dom";
import { PUBLIC_PATH } from "../../constant/matcher";
import Assessment from "../../constant/certificationQuestions.json";
import FirstPage from "../../components/mutltiStep/FirstPage";
import UpdateProfile from "../../components/mutltiStep/UpdateProfile";

function Certification() {
  // const [formStepNum, setFormStepNum] = useState(1)

  // to increase progress bar and also for next page
  // const increment = () => {
  //   setFormStepNum(formStepNum + 1)
  // }

  const [navShow, setNavShow] = useState(true);

  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  return (
    <>
      <Sidebar navContWrapp={navContWrapp} />

      <div>Certification</div>
      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper mt-0 small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px", paddingTop: "36px" }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to="/dashboard">Dashboard</Link>{" "}
              <i className="fa-solid fa-angle-right"></i> Update Profile{" "}
            </p>
          </div>
        </div>

        <div
          className="container content-wrapper"
          style={{ padding: "5px 30px 15px 30px" }}
        >
          <div className="row mt-0 p-0">
            <div className="col-12 px-0">
              <div className="">
                <UpdateProfile
                // formStepNum={formStepNum}
                // increment={increment}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
    </>
  );
}

export default Certification;
