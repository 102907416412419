import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Api_Base_Url } from '../../constant/matcher'

const dashboardService = createApi({
  reducerPath: 'dashboardData',
  baseQuery: fetchBaseQuery({
    baseUrl: Api_Base_Url,
    // credentials: "same-origin",
    mode: 'cors',
    prepareHeaders: (headers, { getState }) => {
      headers.set('Access-Control-Allow-Origin', '*');
      
      const reducerState = getState();
      const token = reducerState?.userReducer?.userToken;
      if(token) {
        headers.set('authorization', token ? `bearer ${token}` : '' )
      }
      
      return headers
    }
  }),
  
  tagTypes: ['profile'],
  endpoints: builder => {
    return {
      // to get banner
    
      dashBannerApi: builder.query({
        query: categoryId => {
          return {
            url: `banner/get-banner/${categoryId}`,
            method: 'GET'
          }
        }
      }),
     
      // to get product using filter
      getProductsApi: builder.query({
        query: filterData => {
          return {
            url: `product/get-by-role?${filterData}`,
            method: 'GET'
          }
        }
      }),
      // to get product using filter
      getProfile: builder.query({
        query: userId => {
          return {
            url: `user/profile?id=${userId}`,
            method: 'GET'
          }
        },
        providesTags: ['profile']
      }),

      // get single product api
      getSingleProductsApi: builder.query({
        query: categoryId => {
          return {
            url: `product/get-single-product/${categoryId}`,
            method: 'GET'
          }
        }
      }),
      // to get positioning product using filter
      getAllProducts: builder.query({
        query: filterData => {
          return {
            url: `product/get-product-position?${filterData}`,
            method: 'GET'
          }
        }
      }),
      // x-content api
        // x-content detail api
        getARNSingleProductsApi: builder.query({
          query: filterData => {
            return {
              url: `arn-product/get-single-product/${filterData}`,
              method: 'GET'
            }
          }
        }),

      getAllFranchiseProducts: builder.query({
        query: filterData => {
          return {
            url: `arn-product/get-product-position?${filterData}`,
            method: 'GET'
          }
        }
      }),
    
      // quiz assessment api
      postQuizData: builder.mutation({
        query: (quizData) => {
          console.log(quizData)
          return {
            url: '/questions/test/submit',
            method: 'POST',
            body: quizData
          }
        }
      }),
      completeProfile: builder.mutation({
        query: (moreDetails) => {
          return {
            url: '/user/completeprofie',
            method: 'POST',
            body: moreDetails
          }
        }
      })
    }
  }
})

export const {
  useDashBannerApiQuery,
  useGetProductsApiQuery,
  useGetSingleProductsApiQuery,
  useGetARNSingleProductsApiQuery,
  useGetProductsXpandApiQuery,
  useGetProfileQuery,
  useGetAllProductsQuery,
  useGetAllFranchiseProductsQuery,
  usePostQuizDataMutation,
  useCompleteProfileMutation
} = dashboardService
export default dashboardService
