import React from 'react'
import './form.css'
import { Link } from 'react-router-dom'

function Email ({ formData, setFormData }) {
  return (
    <>
      <div className='row my-3'>
        {/* <label>Enter Your Email</label> */}
        <div className='form-group'>
          <input 
            type='email'
            placeholder='Email (Optional)'
            name='email'  
            className='form-control'
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
        </div>
      </div>
    </>
  )
}

export default Email
