import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../components/Sidebar'
import Footer from '../../components/Footer'
import DashboardBanner from '../../components/general/DashboardBanner'
import Profile from '../../components/general/Profile'
import { PUBLIC_PATH } from '../../constant/matcher'
import { useGetProfileQuery } from '../../store/services/dashboardService'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import '../../styles/loaders.css'
import { setProfileDetails } from '../../store/reducer/dashboardReducer'

function ProfilePage () {
  const [SmallWrapper, setSmallWrapper] = useState(' small-wrapper ')
  const [navLeftVal, setNavLeftVal] = useState('0')
  const [navShow, setNavShow] = useState(true)
  const [navSideHide, setNavSideHide] = useState(true)
  const [smallScreenSidebar, setSmallScreenSidebar] = useState(
    ' smallScreenSidebar '
  )

  const dispatch = useDispatch()

  const loggedInUserData = useSelector(state => state?.userReducer?.userData)

  const profileResp = useGetProfileQuery(loggedInUserData?._id)

  const userProfileData =
    profileResp?.isSuccess &&
    !profileResp?.isLoading &&
    profileResp.status === 'fulfilled' &&
    !profileResp?.isError &&
    profileResp?.data?.success &&
    profileResp?.data?.user

  useEffect(() => {
    if (userProfileData !== '') {
      dispatch(setProfileDetails(userProfileData))
    }
  }, [userProfileData, dispatch])

  // navigation content wrapper handler
  const navContWrapp = boolData => {
    setNavShow(boolData)
  }

  // const sidebarController = () => {
  //   let sidebarNav = document.getElementsByClassName('sidebar_icons')
  //   sidebarNav = Array.from(sidebarNav)

  //   let sidebarNavLinks = document.getElementsByClassName('side_hide')
  //   sidebarNavLinks = Array.from(sidebarNavLinks)

  //   if (navSideHide === true) {
  //     setNavSideHide(false)
  //     setNavShow(false)
  //     sidebarNav.forEach(icons => {
  //       icons.style.marginRight = '0px'
  //       // icons.style.display = 'block'
  //       setTimeout(() => {
  //         icons.style.display = 'block'
  //       }, 200);
  //     })
  //     sidebarNavLinks.forEach(links => {
  //       links.style.display = 'none'
  //     })
  //   } else {
  //     setNavSideHide(true)
  //     setNavShow(true)
  //     sidebarNav.forEach(icons => {
  //       icons.style.display = 'inline'
  //       icons.style.marginRight = '5px'
  //     })
  //     sidebarNavLinks.forEach(links => {
  //       setTimeout(() => {
  //         links.style.display = 'inline'
  //       }, 200);
  //       // links.style.display = 'inline'
  //     })
  //   }
  // }

  

  return (
    <>
      <Sidebar navContWrapp={navContWrapp} />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper  ${SmallWrapper}`}
        style={{
          paddingLeft: navShow ? '230px' : '70px',
          background: 'whitesmoke'
        }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className='breadcrumbs'>
          <div className='container'>
            <p>
              <Link to='/dashboard'>Dashboard</Link>{' '}
              {/* <i className='fa-solid fa-angle-right'></i> Home{' '} */}
              <i className='fa-solid fa-angle-right'></i> Profile
            </p>
          </div>
        </div>

        {/* <div className="profile_sec px-4 py-5 position-relative"> */}
        <Profile
          username={
            profileResp?.isLoading ? (
              <div className='skel_profile_min'></div>
            ) : (
              userProfileData?.name
            )
          }
          mobile={
            profileResp?.isLoading ? (
              <div className='skel_profile_min'></div>
            ) : (
              userProfileData?.phone
            )
          }
          rm_name={
            profileResp?.isLoading ? (
              <div className='skel_profile_min'></div>
            ) : (
              userProfileData?.assignTo?.name
            )
          }
          rm_mobile={
            profileResp?.isLoading ? (
              <div className='skel_profile_min'></div>
            ) : (
              userProfileData?.assignTo?.phone
            )
          }
          rm_email={
            profileResp?.isLoading ? (
              <div className='skel_profile_max'></div>
            ) : (
              userProfileData?.assignTo?.email
            )
          }
          email={
            profileResp?.isLoading ? (
              <div className='skel_profile_max'></div>
            ) : (
              userProfileData?.email
            )
          }
          userId={
            profileResp?.isLoading ? (
              <div className='skel_profile_min'></div>
            ) : (
              userProfileData?.phone
            )
          }
          city={
            profileResp?.isLoading ? (
              <div className='skel_profile_min'></div>
            ) : (
              userProfileData?.city?.city
            )
          }
          editLink={''}
        />
        {/* </div> */}
        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={'/'}
          home={'/#home'}
          about={'/#about'}
          service={'/#service'}
          faq={'/#faq'}
          testimonial={'/#testimonials'}
        />
      </section>
    </>
  )
}

export default ProfilePage
