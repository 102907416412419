import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PUBLIC_PATH } from "../../constant/matcher";
import Sidebar from "../../components/Sidebar";
import DashCards from "../../components/DashCards";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/card.css";
import dummyJson from "../../constant/dummyData.json";
import VideoBoxes from "../../components/VideoBoxes";
import Footer from "../../components/Footer";
import { unslugify } from "../../constant/utils";
import SkeletonLoader from "../../components/skeleton/SkeletonLoader";
import SkeletonCardLoader from "../../components/skeleton/SkeletonCardLoader";
import { useGetAllProductsQuery } from "../../store/services/dashboardService";
import { useSelector } from "react-redux";
import DashboardBanner from "../../components/general/DashboardBanner";
import Loader from "../../components/general/Loader";
import TestiCards from "../../components/TestiCards";
import testimonialData from "../../constant/testimonialData.json";
import NoticeBoard from "../../components/general/NoticeBoard";
import AlertCompleteProfile from "../../components/cardsDesigns/AlertCompleteProfile";

function Dashboard() {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  var loggedUserCategory = loggedInUserData && loggedInUserData?.userType;

  // API to get product using filter
  let subCategoryId = loggedInUserData?.subCategory;
  let userType = loggedInUserData?.userType;
  var productId = "";
  let productArr = loggedInUserData?.product?.map((items, index) => {
    productId =
      productId +
      items +
      (loggedInUserData?.product.length === index + 1 ? "" : ",");

    return productId;
  });

  // filter params for xpand products
  let filterData =
    "category=" +
    userType +
    "&subCategory=" +
    subCategoryId +
    "&productType=xpand&product=" +
    // loggedInUserData?.product[0]
    productId;

  // filter params for product products
  let filterData_1 =
    "category=" +
    userType +
    "&subCategory=" +
    subCategoryId +
    "&productType=product&product=" +
    // loggedInUserData?.product[0]
    productId;

  // api call for xpand
  const productApiData = useGetAllProductsQuery(filterData);

  // useEffect(() => {
  //   console.log(productApiData);
  // }, [productApiData]);

  // api call for products
  const productApiData_1 = useGetAllProductsQuery(filterData_1);

  // dynamic server path for files
  const serverPath =
    productApiData?.isSuccess &&
    !productApiData?.isLoading &&
    productApiData.status === "fulfilled" &&
    !productApiData?.isError &&
    productApiData?.data?.path;

  // dynamic server path for files
  const serverPath_1 =
    productApiData_1?.isSuccess &&
    !productApiData_1?.isLoading &&
    productApiData_1.status === "fulfilled" &&
    !productApiData_1?.isError &&
    productApiData_1?.data?.path;

  const dashContent =
    productApiData?.isSuccess &&
    !productApiData?.isLoading &&
    productApiData.status === "fulfilled" &&
    !productApiData?.isError &&
    productApiData?.data?.products;

  const dashContent_1 =
    productApiData_1?.isSuccess &&
    !productApiData_1?.isLoading &&
    productApiData_1.status === "fulfilled" &&
    !productApiData_1?.isError &&
    productApiData_1?.data?.products;

  let uniqueXpandData = [];
  let uniqueProductData = [];
  // useEffect(() => {
  //   console.log(productApiData)
  // }, [productApiData])

  // to hide blank section
  useEffect(() => {
    const proSec = document.getElementsByClassName("prod_div_sec");
    const proColSec = document.getElementsByClassName("slick-track");

    const proColSecArr = Array.from(proColSec);
    const proSecArr = Array.from(proSec);

    if (proColSec) {
      for (let i = 0; i < proColSecArr.length; i++) {
        const element = proColSecArr[i];
        if (element.childNodes.length === 0) {
          element.parentNode.parentNode.parentNode.parentNode.style.display =
            "none";
        }
      }
    }
  }, [productApiData_1]);

  const [navShow, setNavShow] = useState(true);
  const [SmallWrapper, setSmallWrapper] = useState(" small-wrapper ");

  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  var settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        // breakpoint: 1545,
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  var testiSettings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1245,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
     
      <Sidebar navContWrapp={navContWrapp} />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper ${SmallWrapper}`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        {/* banner sec */}
       
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to="/dashboard">Dashboard</Link>{" "}
              {/* <i className='fa-solid fa-angle-right'></i> Home */}
            </p>
          </div>
        </div>

        <div className="content-wrapper main-dash-wrapper">
          {dashContent && dashContent?.length !== 0 && (
            <section className="xpand-acandemy pt-0 inner-bg">
              <div className="container container-wrapper">
                <div className="row inner-content">
                  <div className="col-12 about-product-inner">
                    <div className="mt-2 about-products">
                      <div className="sec-title sec-title_list">
                        <h2 className="text-capitalize">Xpand Academy</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 dash_slider_content_card about-product-inner-2 slider-container">
                    <Slider {...settings}>
                      {dashContent &&
                        dashContent?.length !== 0 &&
                        dashContent?.map((productItems, index) => {
                          if (
                            !uniqueXpandData.includes(
                              productItems?.contentId?.topic
                            )
                          ) {
                            uniqueXpandData.push(
                              productItems?.contentId?.topic
                            );
                            return (
                              <>
                                <DashCards
                                  key={productItems?.contentId?._id}
                                  cardTitle={productItems?.contentId?.topic}
                                  cardDesc={
                                    productItems?.contentId?.description
                                  }
                                  cardLink={`/dashboard/details/${productItems?.contentId?._id}`}
                                  cardImg={
                                    serverPath + productItems?.contentId?.image
                                  }
                                  pdfCounter={
                                    productItems?.contentId?.pdf?.length
                                  }
                                  VideoCounter={
                                    productItems?.contentId?.videos?.length
                                  }
                                />
                              </>
                            );
                          }
                        })}
                      {dashContent?.length === 0 && (
                        <div className="text-secondary">Data Not Available</div>
                      )}
                      {productApiData?.isLoading && (
                        <>
                          <div className="d-flex align-items-center py-3 px-2">
                            <SkeletonCardLoader />
                            <SkeletonCardLoader />
                            <SkeletonCardLoader />
                          </div>
                        </>
                      )}
                    </Slider>
                  </div>
                  {/* <div className='col-12 dash_slider_content_card about-product-inner-2 slider-container'>
                  <Slider {...settings}>
                    {dashContent &&
                      dashContent?.length !== 0 &&
                      dashContent?.map((productItems, index) => {
                        if(!uniqueXpandData.includes(productItems?.topic)) {
                          uniqueXpandData.push(productItems?.topic)
                          return (
                            <>
                              <DashCards
                                key={productItems?._id}
                                cardTitle={productItems?.topic}
                                cardDesc={
                                  productItems?.description
                                }
                                cardLink={`/dashboard/details/${productItems?._id}`}
                                cardImg={
                                  serverPath + productItems?.image
                                }
                                pdfCounter={productItems?.pdf?.length}
                                VideoCounter={
                                  productItems?.videos?.length
                                }
                              />
                            </>
                          )
                        }
                      })}
                      {dashContent?.length === 0 && (
                        <div className='text-secondary'>
                          Data Not Available
                        </div>
                      )}
                    {productApiData?.isLoading && (
                      <>
                        <div className='d-flex align-items-center py-3 px-2'>
                          <SkeletonCardLoader />
                          <SkeletonCardLoader />
                          <SkeletonCardLoader />
                        </div>
                      </>
                    )}
                  </Slider>
                </div> */}
                </div>
              </div>
            </section>
          )}

          {dashContent_1 &&
            dashContent_1?.length !== 0 &&
            dashContent_1?.map((productItems, index) => {
              return (
                <>
                  {Object.keys(productItems)?.map((itemName, index) => {
                    return (
                      <>
                        <section
                          className="about-product pt-0 inner-bg"
                          key={index}
                        >
                          <div className="container container-wrapper">
                            <div className="row inner-content">
                              <div className="col-12 about-product-inner">
                                <div className="mt-2 about-products">
                                  <div className="sec-title sec-title_list">
                                    <h2 className="text-capitalize">
                                      About {unslugify(itemName)}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`col-12 dash_slider_content_card about-product-inner-2 slider-container prod_col_cont prod_col_cont_${index}`}
                                key={index}
                              >
                                <Slider {...settings}>
                                  {productItems[itemName]?.map(
                                    (items, index) => {
                                      if (
                                        !uniqueProductData.includes(
                                          items?.contentId?.topic
                                        )
                                      ) {
                                        uniqueProductData.push(
                                          items?.contentId?.topic
                                        );
                                        return (
                                          <DashCards
                                            key={items?.contentId?._id}
                                            cardTitle={items?.contentId?.topic}
                                            cardDesc={
                                              items?.contentId?.description
                                            }
                                            cardLink={`/dashboard/details/${items?.contentId?._id}`}
                                            cardImg={
                                              serverPath_1 +
                                              items?.contentId?.image
                                            }
                                            pdfCounter={
                                              items?.contentId?.pdf?.length
                                            }
                                            VideoCounter={
                                              items?.contentId?.videos?.length
                                            }
                                          />
                                        );
                                      }
                                    }
                                  )}
                                  {productItems[itemName]?.length === 0 && (
                                    <div className="text-secondary">
                                      Data Not Available
                                    </div>
                                  )}
                                </Slider>
                              </div>
                            </div>
                          </div>
                        </section>
                      </>
                    );
                  })}
                </>
              );
            })}
          {productApiData_1?.isLoading && <SkeletonLoader />}

          {/* tesitimonial video */}
          <section className="testi-video pt-0 inner-bg">
            <div className="container container-wrapper">
              <div className="row inner-content">
                <div className="col-12 testi-video-inner">
                  <div className="mt-2 mb-3"></div>
                </div>
                <div className="col-md-7 col-lg-8 ladingpageTesti testi-video-inner-2 mb-3">
                  <div className="border_box mt-2 px-4 pb-5 testiCards_arrow">
                    <div className="sec-title sec-title_list">
                      <h2>Business Partner Testimonials</h2>
                    </div>
                    <Slider {...testiSettings}>
                      {testimonialData?.map((items, index) => {
                        return (
                          <TestiCards
                            key={index}
                            testiPara={items?.description}
                            writerName={items?.name}
                            location={items?.location}
                          />
                        );
                      })}
                    </Slider>
                  </div>
                </div>
                <div className="col-md-5 col-lg-4 mb-3">
                  <div className="border_box testi__notice mt-2">
                    <NoticeBoard />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
    </>
  );
}

export default Dashboard;
