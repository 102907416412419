import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "../../../styles/mainCards.css";
import "../../../styles/loginFormStyle.css";
import { useGetOtpMutation } from "../../../store/services/loginService";
import { useSelector } from "react-redux";
import {
   Api_Base_Url,
   PUBLIC_PATH,
   Server_Path_static,
   qrpath,
   urlpath,
} from "../../../constant/matcher";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Profile(props) {
   const profDetails = useSelector((state) => state?.userReducer?.userData);

   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [mobile, setMobile] = useState("");
   const [downloadPopup, setdownloadPopup] = useState(false);
   const [qrContent, setQrContent] = useState("");
   const domEl = useRef(null);
   const [popupImgSrc, setPopupImgSrc] = useState("");
   const [profile, setProfile] = useState([]);
   const [popupForm, setPopupForm] = useState(false);
   const [changePassForm, setChangePassForm] = useState(false)
   const [editForm, setEditForm] = useState(false);
   const [successStatus, setSuccessStatus] = useState("fade");
   
   const [path, setPath] = useState("")
   const [prevDetails, setprevDetails] = useState({
      old_password: '',
      new_password: '',
      c_password: '',
    })
    const [editProfile, setEditProfile] = useState({
      name: '',
      email: '',
      phone: '',
      otp: ''
    })
   const downloadImage = async () => {
      html2canvas(domEl.current, {
         useCORS: true,
         allowTaint: true,
         logging: true,
      }).then((canvas) => {
         const base64img = canvas.toDataURL("image/jpeg");
         saveAs(base64img, "creative-product.jpg");
      });
      setTimeout(() => {
         setdownloadPopup(false);
      }, 300);
   };
   const generateURL = (type) => {
      const userLogginId = JSON.parse(localStorage.getItem("loginUserData"));
      const userId = userLogginId._id;
      const usertype = userLogginId.role;
      const generatedUrl = `?type=${usertype}&for=${type}&qid=${userId}`;
      setPath(generatedUrl);
    };

   const activeDownPopup = (e) => {
      setPopupImgSrc(e.target.getAttribute("dataurl"));
      if (e.target.getAttribute("qrType") === "cp") {
         setQrContent(
            "Join Hands with Xpand Wealth and create a passive revenue for yourself Become Channel Partner Today!"
         );
      } else {
         setQrContent(
            "To conquer your financial goals, Make your financial plan and align your investments today!"
         );
      }
      setdownloadPopup(true);
   };

   const closeSuccessModal = () => {
      setSuccessStatus("fade");
      document.getElementById("formSuccessModal").classList.add("d-none");
    };

   useEffect(() => {
      setName(profDetails?.name);
      setEmail(profDetails?.email);
      setMobile(profDetails?.phone);
   }, [profDetails]);

   const showChang_form = () => {
      setChangePassForm(true)
      setPopupForm(true)
      setEditForm(false)
    }
  
  
    const closeChngPassPopup = () => {
      setChangePassForm(false)
      setEditForm(false)
      setPopupForm(false)
    }


    const changePassHandler = (e) => {
      setprevDetails({ ...prevDetails, [e.target.name]: e.target.value })
    }
   
    // const {newPass,oldPass}=req.body
    // to change the password

    const changePassword = () => { 
      const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
      const id = loginUserData._id;
      const formData = new FormData()
      formData.append("oldPass", prevDetails.old_password)
      formData.append("newPass", prevDetails.new_password)
      const response = axios.post(
        `${Api_Base_Url}/rm/changepassword?id=${id}`, formData ,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
         setSuccessStatus("d-flex");
      } else {
        console.log("error");
      }
      setprevDetails({
        old_password: '',
      new_password: '',
      c_password: '',
      })
    };

    const userChangePass = (e) => {
      e.preventDefault()
      if (prevDetails.new_password === prevDetails.c_password) {
         changePassword(prevDetails.c_password)
      //   console.log('passmatch', prevDetails)
      } else {
        toast.error('Passwords must match. Please confirm your password to continue.')
      }
    }


   const getrmprofile = () => {
      const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
      const id = loginUserData._id;
      axios.get(`${Api_Base_Url}/rm/profile?id=${id}`).then((res) => {
         setProfile(res?.data?.profileData);
      });
   };
   useEffect(() => {
      let mobInpt = document.getElementById('mobile')
      if (mobInpt) {
        mobInpt.oninput = () => {
          if (mobInpt.value.length > mobInpt.maxLength) {
            mobInpt.value = mobInpt.value.slice(0, mobInpt.maxLength)
          }
        }
      }
      getrmprofile();
   }, []);
   return (
      <>
      <ToastContainer/>
         <div className="container content-wrapper px-4 py-0 details-page">
            <div className="row mb-4">
               <div className="col-md-12">
                  <div className="profile_header d-flex align-items-center justify-content-between">
                     <div className="">
                        <h3>My Profile</h3>
                     </div>

                     {/* <div className="edit_profile">
                        <Link
                           to={props?.editLink ? props?.editLink : "#"}
                           className="edit_profie_btn btn"
                           onClick={showEditform}
                        >
                           <i className="fa-solid fa-pen-to-square"></i> Edit
                           Profile
                        </Link>
                     </div> */}
                  </div>
               </div>
            </div>
            <div className="row profile_wrapper">
               <div className="col-md-8">
                  <div className="row">
                     <div className="col-md-12 col-task-header">
                        <div className="task_header d-flex align-items-center justify-content-between">
                           <h3 className="m-0">Details</h3>
                           <div className='edit_profile'>
                  <Link
                    to={props?.editLink ? props?.editLink : '#'}
                    className='edit_profie_btn chnge_pass btn'
                    onClick={showChang_form}
                  >
                    <i className='fa-solid fa-unlock' onClick={showChang_form}></i> Change Password
                  </Link>
                </div>
                        </div>
                     </div>
                     <div className="col-12">
                        <div className="row">
                           <div className="col-md-12">
                              <div className="profile_contact_details user_details">
                                 <div className="contact_rows">
                                    <span className="det_sep">Name</span> :{" "}
                                    <span className="details_span">
                                       {" "}
                                       {profile?.name}
                                    </span>
                                 </div>
                                 <div className="contact_rows">
                                    <span className="det_sep">Mobile</span> :{" "}
                                    <span className="details_span">
                                       {" "}
                                       {profile?.phone}
                                    </span>
                                 </div>
                                 <div className="contact_rows">
                                    <span className="det_sep">Email</span> :
                                    <span className="details_span">
                                       {" "}
                                       {profile?.email}
                                    </span>
                                 </div>
                                 <div className="contact_rows">
                                    <span className="det_sep">City</span> :
                                    <span className="details_span">
                                       {" "}
                                       {profile?.city?.city}
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-4 p-0 col-img-profile">
                  <div className="dp_sec_cont ">
                     <div className="rm_head d-flex align-items-center">
                        <i className="fa-solid fa-qrcode me-2"></i>{" "}
                        <h3 style={{ fontSize: "22px" }}>QR Code</h3>
                     </div>
                     <div className="w-100 p-2 pt-1 profile_detail d-flex align-items-center justify-content-between profile_contact_details rm_details">
                        <div
                           className="contact_rows align-items-center mb-0"
                           style={{ flexDirection: "column" }}
                        >
                           <div className="mb-2">
                              <img
                                 className="me-1"
                                 src={`${qrpath}${profile?.qrCode}`}
                                 alt=""
                                 width="20"
                              />
                           </div>
                           <div className="qr__img">
                              <span style={{ fontWeight: "500" }}>CP</span>
                              {/* <img
                      className="me-1"
                      src={
                        Server_Path_static +
                        "output-file-path/" +
                        profDetails?.qrCode
                      }
                      alt=""
                      width="80"
                    /> */}
                           </div>
                           <div
                              className="mt-2"
                              onClick={activeDownPopup}
                              style={{ cursor: "pointer" }}
                           >
                             
                              <button
                                 className="btn download-qr-btn"
                                 dataUrl={
                                    Server_Path_static +
                                    "output-file-path/" +
                                    profDetails?.qrCode
                                 }
                                 onClick={() => generateURL('cp')}
                                 qrType="cp"
                              >
                                 <span className="d-flex justify-content-evenly fw-bold ">
                                          <span
                                             className="pe-1"
                                             style={{ fontSize: "12px" }}
                                             dataUrl={
                                              Server_Path_static +
                                              "output-file-path/" +
                                              profDetails?.qrCode
                                           }
                                          >
                                             Download
                                          </span>
                                 <i
                                    class="fa-solid fa-download qr-download"
                                    dataUrl={
                                       Server_Path_static +
                                       "output-file-path/" +
                                       profDetails?.qrCode
                                    }
                                    qrType="cp"
                                 ></i>
                                 </span>
                              </button>
                           </div>
                        </div>
                        <div
                           className="contact_rows align-items-center mb-0"
                           style={{ flexDirection: "column" }}
                        >
                           <div className="mb-2">
                              <img
                                 className="me-1"
                                 src={`${qrpath}${profile?.qrCodeivt}`}
                                 alt=""
                                 width="20"
                              />
                           </div>
                           <div className="qr__img">
                              <span style={{ fontWeight: "500" }}>
                                 Investor
                              </span>
                              {/* <img
                      className="me-1"
                      src={
                        Server_Path_static +
                        "output-file-path/" +
                        profDetails?.qrCodeivt
                      }
                      alt=""
                      width="80"
                    /> */}
                           </div>
                           <div
                              className="mt-2"
                              onClick={activeDownPopup}
                              style={{ cursor: "pointer" }}
                           >
                              <button
                                 className=" btn download-qr-btn"
                                 dataUrl={
                                    Server_Path_static +
                                    "output-file-path/" +
                                    profDetails?.qrCodeivt
                                 }
                                 onClick={() => generateURL('ivt')}
                                 qrType="investor"
                              >
                                 <span className="d-flex justify-content-evenly fw-bold ">
                                          <span
                                             className="pe-1"
                                             style={{ fontSize: "12px" }}
                                             dataUrl={
                                              Server_Path_static +
                                              "output-file-path/" +
                                              profDetails?.qrCodeivt
                                           }
                                          >
                                             Download
                                          </span>
                                 <i
                                    className="fa-solid fa-download qr-download"
                                    dataUrl={
                                       Server_Path_static +
                                       "output-file-path/" +
                                       profDetails?.qrCodeivt
                                    }
                                    qrType="investor"
                                 ></i>
                                 </span>
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {popupForm &&
    <div className='form_popups'>
      {changePassForm && (
        <div className='chagne_pass_form'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 d-flex align-items-center justify-content-center'>
                <div
                  className='formByScan_container pb-4 pt-5 position-relative'
                  id='formByScan_container'
                  style={{overflow:'visible'}}
                >
                  <div className="popup_close_icon" onClick={closeChngPassPopup}>
                    <i className="fa fa-xmark" onClick={closeChngPassPopup}></i>
                  </div>
                  <form onSubmit={userChangePass} autoComplete='off'>
                    <h2
                      className='form-heading text-center mb-4'
                      style={{ fontSize: '22px' }}
                    >
                      Change Password
                    </h2>
                    <input
                      type='password'
                      placeholder='Old Password *'
                      required
                      autoComplete='new-password'
                      name='old_password'
                      value={prevDetails.old_password}
                      onChange={changePassHandler}
                    />
                    <input
                      type='password'
                      placeholder='New Password *'
                      required
                      autoComplete='new-password'
                      name='new_password'
                      value={prevDetails.new_password}
                      onChange={changePassHandler}
                    />
                    <input
                      type='password'
                      placeholder='Confirm Password *'
                      required
                      autoComplete='new-password'
                      name='c_password'
                      value={prevDetails.c_password}
                      onChange={changePassHandler}
                    />
                    <div className='mt-2 text-center'>
                      <button className="generate_support">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
    </div>
    }
         {/* =====QR download popup */}
         {/* =====html to image dowload */}
         {downloadPopup && (
            <div className="form_popups popup__container">
               {/* <div className='chagne_pass_form'> */}
               <div
                  className="raised_query_wrapper p-0 position-relative overflow-visible"
                  style={{ width: "350px" }}
               >
                  <div
                     className="popup_close_icon"
                     onClick={() => setdownloadPopup(false)}
                  >
                     <i
                        className="fa fa-xmark"
                        onClick={() => setdownloadPopup(false)}
                     ></i>
                  </div>
                  <div
                     id="creativeDownload"
                     ref={domEl}
                     className="html2img bg-white px-3 pb-2 pt-3 mt-3"
                     style={{ zIndex: 2, margin: "auto" }}
                  >
                     <div className="para text-center">
                        <p className="mb-2" style={{ fontSize: "14px" }}>
                           {qrContent}
                        </p>
                     </div>
                     <div className="htmlImg text-center bg-white">
                        <img
                           src={popupImgSrc}
                           alt="img"
                           className="m-auto"
                           style={{ width: "150px", height: "150px" }}
                        />
                        <div className="mb-3">
                           Scan the QR for more information
                        </div>
                     </div>
                     <div className="userDetails pb-2 px-2 bg-white">
                        <div className="username d-flex align-items-center justify-content-between"
                       
                        >
                           
                           <h6
                              className=" border p-1 py-2 rounded"
                              style={{
                                 fontWeight: "400",
                                 fontSize: "12px",
                                 
                                 overflow: "hidden"
                              }}
                           >
                               {`${urlpath}${path}`}
                           </h6>
                        </div>
                     </div>
                  </div>
                  <div
                     id="contentDownload"
                     className="subBtn w-100 text-center p-2 rounded"
                     style={{ cursor: "pointer" }}
                     onClick={downloadImage}
                  >
                     Download
                  </div>
               </div>
               {/* </div> */}
            </div>
         )}
         {/* =====END QR download popup */}
          {/* <SuccessModal successStatus={'d-flex'} /> */}
        <div
          className={`modal custom-modal align-items-center ${successStatus}`}
          id="formSuccessModal"
          style={{ background: "rgba(0,0,0,0.3)" }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="formSuccessModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header " style={{ background: "#FF4B2B" }}>
                <h5 className="modal-title" id="formSuccessModalLabel">
                  Success
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeSuccessModal}
                  // data-bs-dismiss='modal'
                  // aria-label='Close'
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-popup-inner">
                  <div className="form-icon success">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 130.2 130.2"
                    >
                      <circle
                        className="path circle"
                        fill="none"
                        strokeWidth="6"
                        strokeMiterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <polyline
                        className="path check"
                        fill="none"
                        strokeWidth="6"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="100.2,40.2 51.5,88.8 29.8,67.5 "
                      />
                    </svg>
                  </div>
                  <div className="form-body">
                    <p>
                     Password changed successfully
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="popup-btn "
                  style={{ background: "#FF4B2B" }}
                  // data-bs-dismiss='modal'
                  onClick={closeSuccessModal}
                >
                  Okay
                </Link>
              </div>
            </div>
          </div>
        </div>
       
      </>
   );
}

export default Profile;
