import React, { useEffect, useState, useRef } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { Api_Base_Url, PUBLIC_PATH } from '../constant/matcher'
import FranchiseSidebar from '../components/franchise/FranchiseSidebar'
import '../styles/card.css'
import Footer from '../components/Footer'
import LandingCards from '../components/franchise/LandingCards'
import '../styles/franchiseSecStyle.css'
import BarChartComp from '../components/charts/BarChartComp'
import CryptoJS from 'crypto-js'
import { useSelector } from 'react-redux'
import FranchisePopup from '../components/general/FranchisePopup'
import NumOfInvestor from '../components/franchise/NumOfInvestor'
import NumOfCP from '../components/franchise/NumOfCP'
import CreateLead from '../components/franchise/CreateLead'
import DashboardBanner from '../components/general/DashboardBanner'
import axios from 'axios';
import FieldOutput from "./franchise/Lms/FieldOutput";
import { formatAmount } from '../utils/helper'


const CPDetails = () => {
 // loggedIn user Data form react-redux
 const loggedInUserData = useSelector(state => state?.userReducer?.userData)
 const [userInfo, setUserInfo] = useState([])
 const [sipSum, setSipSum] = useState(0);
  const [equitySum, setEquitySum] = useState(0);
  const [mfSum, setMFSum] = useState(0);
  const [realSum, setRealSum] = useState(0);
  const [totalEUM, setTotalEUM] = useState(0)
  const [invester, setInvester] = useState("")
  const [equityClient, setEquityClient] = useState("")
  const [realClient, setRealClient] = useState("")
  const [mutualClient, setMutualClient] = useState("")
  const [totaleqclient, setTotaleqclient] = useState('')
const [totalsipclient, setTotalsipclient] = useState('')
const [totallumpclient, setTotallumpclient] = useState('')
const [totalrealclient, setTotalrealclient] = useState('')
const [invEquity, setInvEquity] = useState('')
const [invReal, setInvReal] = useState('')
const [invsip, setInvsip] = useState('')
const [invlump, setInvlump] = useState('')
const [remptioneEquity, setRemptioneEquity] = useState('')
const [remptioneReal, setRemptioneReal] = useState('')
const [remptionesip, setRemptionesip] = useState('')
const [remptionelump, setRemptionelump] = useState('')
 // console.log(loggedInUserData);
 const [dynamicRoute, setdynamicRoute] = useState("");
 useEffect(() => {
   loggedInUserData?.role === "rm"
     ? setdynamicRoute("team")
     : setdynamicRoute("dashboard");
 }, [loggedInUserData]);


 const [createLeadPopup, setCreateLeadPopup] = useState(false)
 const closeCreateLead = () => {
   setCreateLeadPopup(false)
 }

 // for quick invest
 const [searchParams, setSearchParams] = useSearchParams()
 const [quickPopup, setQuickPopup] = useState(false)

 const removeQueryParams = () => {
    const param = searchParams.get('et')
    if (param) {
      searchParams.delete('et')
      setSearchParams(searchParams)
    }
  }
  const closePopupHandler = () => {
    removeQueryParams()
    setQuickPopup(false)
  }
  useEffect(() => {
    if (searchParams?.size > 0) {
      setQuickPopup(true)
    }
  }, [searchParams])

  const [navShow, setNavShow] = useState(true)
  const [SmallWrapper, setSmallWrapper] = useState(' small-wrapper ')

  // const quickInvestDash = useRef()

  const [quickInvest, setQuickInvest] = useState({
    username: loggedInUserData?.eliteusername,
    password: loggedInUserData?.elitepassword,
    loginType: loggedInUserData?.eliterole,
    loginFor: loggedInUserData?.eliterole,
    callbackUrl: window.location.href
  })
  // useEffect(() => {
  //   console.log(quickInvest);
  // }, [quickInvest]);

  const [qickPass, setQickPass] = useState('')

  // for foramting encription
  var CryptoJSAesJson = {
    stringify: function (cipherParams) {
      var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) }
      if (cipherParams.iv) j.iv = cipherParams.iv.toString()
      if (cipherParams.salt) j.s = cipherParams.salt.toString()
      return JSON.stringify(j)
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr)
      var cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(j.ct)
      })
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
      return cipherParams
    }
  }
  useEffect(() => {
    var key = 'W@ealth!@$#$(%#H(^%)'
    // Encrypt
    var EncryptedPass = CryptoJS.AES.encrypt(
      JSON.stringify(quickInvest.password),
      key,
      { format: CryptoJSAesJson }
    ).toString()

    // console.log(EncryptedPass)

    setQickPass(EncryptedPass)

    setQuickInvest({ ...quickInvest, [quickInvest.password]: EncryptedPass })
  }, [])

  // navigation content wrapper handler
  const navContWrapp = boolData => {
    setNavShow(boolData)
  }

  // revenue charts data
  const revenueChartData = [
    {
      name: 'Total',
      this_month: 761500,
      lastMonth: 1100000
    },
    {
      name: 'Eq',
      this_month: 2000000,
      lastMonth: 1000000
    },
    {
      name: 'MF',
      this_month: 1000000,
      lastMonth: 50000
    },
    {
      name: 'MF-SIP',
      this_month: 1500000,
      lastMonth: 50000
    }
  ]
  const { _id, role } = useParams()

  const getUser = () => {
    axios.get(`${Api_Base_Url}/rm/channel-partners?cpId=${_id}&role=${role}`).then((res) => {
      setUserInfo(res?.data?.cpDetails)
    })
  }

  const getSIP = () => {
    axios
      .get(`${Api_Base_Url}/cp/dashboard/totalsip?cpid=${_id}`)
      .then((res) => {
        setSipSum(res?.data?.data[0]?.sip[0]?.sum || 0);
        setEquitySum(res?.data?.data[0]?.equity[0]?.totalvalue || 0);
        setMFSum(res?.data?.data[0]?.mutualfund[0]?.totalvalue || 0);
        setRealSum(res?.data?.data[0]?.realestate[0]?.totalvalue || 0);
        setTotalEUM(
          (res?.data?.data[0]?.sip[0]?.sum || 0) +
          (res?.data?.data[0]?.equity[0]?.totalvalue || 0) +
          (res?.data?.data[0]?.mutualfund[0]?.totalvalue || 0) +
          (res?.data?.data[0]?.realestate[0]?.totalvalue || 0)
        )
      });
  };

  const getMyinvester = () => {
    axios.get(`https://xpand-u.com/admin/api/v1/cp/my-client?id=${_id}`).then((res) => {
      const data = res?.data?.myClient
      setInvester(data.length)
      const realClient = data?.filter((result) => result?.product.some((name) => name.name === 'Monthly Rental Income')).length || 0
      const equityClient = data?.filter((result) => result?.product.some((name) => name.name === 'Equity Advisory')).length || 0
      const mutualClient = data?.filter((result) => result?.product.some((name) => name.name === 'Mutual Fund')).length || 0
      setRealClient(realClient)
      setEquityClient(equityClient)
      setMutualClient(mutualClient)
    })
  }

  // ==================== API for  Business Updated=========================
const getEquity = () => {
  const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
  const id = loginUserData._id;
  axios
    .get(`${Api_Base_Url}/cp/businessupdate?cpid=${id}`) 
    .then((res) => {
      // setEquity(res?.data?.data[0]?.equity[0]?.sum || 0);
      setTotaleqclient(res?.data?.data[0]?.["totalclient-Equity"][0]?.clientcode || 0);
      setTotalrealclient(res?.data?.data[0]?.["totalclient-realstate"][0]?.clientcode || 0)
      setTotallumpclient(res?.data?.data[0]?.["totalclient-mf-lumpsum"][0]?.clientcode || 0)
      setTotalsipclient(res?.data?.data[0]?.["totalclient-mfsip"][0]?.clientcode || 0)
      setInvEquity(res?.data?.data[0]?.["invesment-Equity"][0]?.sum || 0)
      setInvReal(res?.data?.data[0]?.["invesment-realestate"][0]?.totalvalue || 0)
      setInvlump(res?.data?.data[0]?.["invesment-mf"][0]?.totalvalue || 0)
      setInvsip(res?.data?.data[0]?.["invesment-sip"][0]?.totalvalue || 0)
      setRemptioneEquity(res?.data?.data[0]?.["remption-equity"][0]?.sum || 0)
      setRemptioneReal(res?.data?.data[0]?.["remption-realstate"][0]?.sum || 0)
      setRemptionelump(res?.data?.data[0]?.["invesment-sip"][0]?.sum || 0)
      setRemptionesip(res?.data?.data[0]?.["invesment-sip"][0]?.sum || 0)
      // console.log("Total Client", res?.data?.data[0]?.["totalclient-Equity"][0]?.clientcode);
    });
};

  useEffect(() => {
    getUser()
    getSIP()
    getMyinvester()
    getEquity()
  }, [])

  return (
    <>
      <FranchiseSidebar navContWrapp={navContWrapp} />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper  ${SmallWrapper}`}
        style={{ paddingLeft: navShow ? '230px' : '70px' }}
      >
         
        {/* banner sec */}
       <DashboardBanner/>
     
          <div className='breadcrumbs'>
        {/* <div className='wrap'> */}
          <div className='container'>
          <p>
                     <Link className='ps-3' to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                        Dashboard
                     </Link>{" "}
                     <i className="fa-solid fa-angle-right"></i> My Channel
                     Partner
                  </p>
           
          {/* </div> */}
        </div>
                    </div>

                     <div
                        className="field__container"
                        style={{ margin: "60px 20px" }}
                     >
                        <div>
                           {/* <======= heading  ======> */}
                           <div className="position-absolute">
                              <div
                                 className="position-relative"
                                 style={{ top: "-40px" }}
                              >
                                 <h2 className="fs-4 ps-3 heading-text-large">
                                     Personal Details
                                 </h2>
                              </div>
                           </div>
                           {/* <========== content =========> */}
                           <div className="row py-2 px-md-3 mt-2 mx-2">
                              {userInfo?.name && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-12 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Name" /> */}
                                             <p>Name: </p>
                                          </span>
                                          <div className="detail_value col col-12 col-md-6 col-xl-7">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={userInfo?.name}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              {userInfo?.email && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-12 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Email" /> */}
                                             <p> Email: </p>
                                          </span>
                                          <div className="detail_value col col-12 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={userInfo?.email}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              {userInfo?.phone && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-12 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Phone" /> */}
                                             <p>Phone: </p>
                                          </span>
                                          <div className="detail_value col col-12 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={userInfo?.phone}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              {userInfo?.address && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-12 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Address" /> */}
                                             <p>Address: </p>
                                          </span>
                                          <div className="detail_value col col-12 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={userInfo?.address}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              {userInfo?.city && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-12 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="City" /> */}
                                             <p>City: </p>
                                          </span>
                                          <div className="detail_value col col-12 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={userInfo?.city?.city}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              {userInfo?.product && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-12 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="State" /> */}
                                             <p> Product:</p>
                                          </span>
                                          <div className="detail_value col col-12 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={userInfo?.product?.map((result) => result?.name).join(', ')}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                           
                           
                           </div>
                        </div>
                     </div>
        <div
          className='content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5'
          // style={{ margin: '0 10px' }}
        >
          {/* for business_dashboard tab */}
          <div id='business_dashboard' className='tabcontent active'>
            <div className='tab_title d-flex mb-3 justify-content-between align-items-center'>
              <h3>
                <img
                  className='me-1'
                  src={PUBLIC_PATH + 'assets/img/icons/assets.png'}
                  alt=''
                  width='25'
                />
                Total AUM <span className='title_amnt'>: &#8377; {formatAmount(totalEUM)}</span>{' '}
                <span className='title_updated'>
                  last updated as on, 1 May, 2023
                </span>
              </h3>
            </div>
            <div className='container-fluid px-0'>
              <div className='row mb-3 frachise_plan_cards d-flex justify-content-between align-items-center'>
              <LandingCards
                  cardTitle={'Equity'}
                  cardAmount={formatAmount(equitySum)}
                  iconSrc={PUBLIC_PATH + 'assets/img/icons/cash.png'}
                  putUpValue={'0.00%'}
                  updatesTime={'NA'}
                  investType={'put_up_value'}
                  arrowDirection={<i className='fa-solid fa-arrow-up'></i>}
                />
                <LandingCards
                  cardTitle={'Mutual Fund'}
                  cardAmount={formatAmount(mfSum)}
                  iconSrc={PUBLIC_PATH + 'assets/img/icons/report.png'}
                  putUpValue={'0.00%'}
                  updatesTime={'NA'}
                  investType={'put_up_value'}
                  arrowDirection={<i className='fa-solid fa-arrow-up'></i>}
                />
                <LandingCards
                  cardTitle={'Real Estate'}
                  cardAmount={formatAmount(realSum)}
                  iconSrc={PUBLIC_PATH + 'assets/img/icons/payment.png'}
                  putUpValue={'0.00%'}
                  updatesTime={'NA'}
                  investType={'put_up_value'}
                  arrowDirection={<i className='fa-solid fa-arrow-up'></i>}
                />
                <LandingCards
                  cardTitle={'SIP Book'}
                  cardAmount={formatAmount(sipSum)}
                  iconSrc={PUBLIC_PATH + 'assets/img/icons/real-estate.png'}
                  putUpValue={'0.00%'}
                  updatesTime={'NA'}
                  investType={'put_down_value'}
                  arrowDirection={<i className='fa-solid fa-arrow-down'></i>}
                />
              </div>
              <div className='row'>
                <div className='col-sm-12 col-md-8 franchise_chart_inv ' style={{width: "100%"}}>
                  <div className='row'>
                    <div className='col-md-4 mb-3 numb_inv_card'>
                      {/* <div className='franchise_chart franchise_amnt_card'> */}
                      {/* =====Number of CP=========== */}
                      <NumOfCP
                      name={"My Investors"}
                        totalCP={invester ? invester : 0}
                        eqClients={equityClient ? equityClient : 0}
                        SIPClients={mutualClient ? mutualClient : 0}
                        RECLients={realClient ? realClient : 0}
                      />
                      {/* </div> */}
                    </div>
                    <div
                      className='revenue_barchar_container mb-3 col-md-12 ms-3'
                      style={{width: "65%"}}
                    >
                      <div className='franchise_chart revenue_chart'>
                        <div className='mb-3 chart_text d-flex justify-content-between align-items-center'>
                          <h6 className=''>
                            Revenue Update{' '}
                            <span className='revenue_amount'>(&#8377; 0k)</span>
                          </h6>
                          <div className='more_details'>
                            <div className='dropdown'>
                              <form className='position-relative'>
                                <i className='fa-solid fa-calendar-days calender_icon'></i>
                                <select className='form-select select_time_inpt'>
                                  <option value=''>This month</option>
                                  <option value='1'>Quarterly</option>
                                  <option value='2'>This year</option>
                                </select>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className='revenue_barchart'>
                          <BarChartComp
                            revenueChartData={revenueChartData}
                            xTags={'name'}
                            dataTags={'this_month'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 table_grid_container'>
                  <div className='franchise_inve table_data_container'>
                    <div className='table_sec_heading mb-3 d-flex align-items-center justify-content-between'>
                      <h3>Business Update</h3>
                      <div className='dropdown'>
                        <form className='position-relative'>
                          <i className='fa-solid fa-calendar-days calender_icon'></i>
                          <select className='form-select select_time_inpt'>
                            <option value=''>This month</option>
                            <option value='1'>Quarterly</option>
                            <option value='2'>This year</option>
                          </select>
                        </form>
                      </div>
                    </div>
                    <div className='table_wrapper'>
                      <table className='table table-striped'>
                        <thead>
                          <tr>
                            <th scope='col'></th>
                            <th scope='col'>Equity</th>
                            <th scope='col'>MF-SIP</th>
                            <th scope='col'>MF-Lumpsum</th>
                            <th scope='col'>Real Estate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope='row'>Total Client</th>
                            <td>{totaleqclient}</td>
                            <td>{totalsipclient}</td>
                            <td>{totallumpclient}</td>
                            <td>{totalrealclient}</td>
                          </tr>
                          <tr>
                            <th scope='row'>Investment</th>
                            <td>{invEquity}</td>
                            <td>{invsip}</td>
                            <td>{invlump}</td>
                            <td>{invReal}</td>
                          </tr>
                          <tr>
                            <th scope='row'>Redemption</th>
                            <td>{remptioneEquity}</td>
                            <td>{remptionesip}</td>
                            <td>{remptionelump}</td>
                            <td>{remptioneReal}</td>
                          </tr>
                          <tr style={{ background: '#0ea89a' }}>
                            <th scope='row'>Net Investment</th>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* =================create Lead Popup============== */}
        {createLeadPopup && <CreateLead closeCreateLead={closeCreateLead} closeLeadPopup={closeCreateLead} />}

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={'/'}
          home={'/#home'}
          about={'/#about'}
          service={'/#service'}
          faq={'/#faq'}
          testimonial={'/#testimonials'}
        />
      </section>
    </>
  )
}

export default CPDetails