import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { PUBLIC_PATH } from '../../constant/matcher'
import Sidebar from '../../components/Sidebar'
import { unslugify } from '../../constant/utils'
import DetailsCard from '../../components/DetailsCard'
import Footer from '../../components/Footer'
import DashboardBanner from '../../components/general/DashboardBanner'
import { useSelector } from 'react-redux'
import {
  useGetProductsApiQuery,
  useGetAllProductsQuery
} from '../../store/services/dashboardService'
import Loader from '../../components/general/Loader'
import SkeletonLoader from '../../components/skeleton/SkeletonLoader'
import SkeletonCardLoader from '../../components/skeleton/SkeletonCardLoader'
import dummyJson from '../../constant/dummyData.json'
import RaisedQueryComp from '../../components/general/RaisedQueryComp';

function DetailsPage () {
  const [navShow, setNavShow] = useState(true)
  const [SmallWrapper, setSmallWrapper] = useState(' small-wrapper ')

  const loggedInUserData = useSelector(state => state?.userReducer?.userData)
  var loggedUserCategory = loggedInUserData && loggedInUserData?.userType

  // API to get product using filter
  let subCategoryId = loggedInUserData?.subCategory
  let userType = loggedInUserData?.userType
  var productId = ''
  let productArr = loggedInUserData?.product?.map((items, index) => {
    productId =
      productId +
      items +
      (loggedInUserData?.product.length === index + 1 ? '' : ',')
    return productId
  })

  let filterData =
    'category=' +
    userType +
    '&subCategory=' +
    subCategoryId +
    '&productType=xpand&product=' +
    // loggedInUserData?.product[0]
    productId

  const productApiData = useGetAllProductsQuery(filterData)

  //   useEffect(() => {
  //   console.log(productApiData)
  // }, [productApiData]);

  // useEffect(() => {
  //   console.log()
  // }, []);

  

  const serverPath =
    productApiData?.isSuccess &&
    !productApiData?.isLoading &&
    productApiData.status === 'fulfilled' &&
    !productApiData?.isError &&
    productApiData?.data?.path

  const dashContent =
    productApiData?.isSuccess &&
    !productApiData?.isLoading &&
    productApiData.status === 'fulfilled' &&
    !productApiData?.isError &&
    productApiData?.data?.products

  let uniqueData = []
  
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData)
  }
  return (
    <>
      <Sidebar
        navContWrapp={navContWrapp}
        // navLeftVal={navLeftVal}
        // sidebarController={sidebarController}
        // smallScreenSidebar={smallScreenSidebar}
        // navSideHide={navSideHide}
      />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper  ${SmallWrapper}`}
        style={{ paddingLeft: navShow ? '230px' : '70px' }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className='breadcrumbs'>
          <div className='container'>
            <p>
              <Link to='/dashboard'>Dashboard</Link>{' '}
              {/* <i className='fa-solid fa-angle-right'></i> Home{' '} */}
              {/* <i className='fa-solid fa-angle-right'></i> Listing{' '} */}
              <i className='fa-solid fa-angle-right'></i> Xpand Academy
            </p>
          </div>
        </div>

        <div
          className='container content-wrapper details-page'
          style={{ padding: '5px 15px 15px 15px' }}
        >
          <div
            className='row details-card-wrapper sideXpandWrapp pt-0 mt-0 px-0 inner-content details-page-card inner-bg'
            style={{ background: '#d6e7e5' }}
          >
            <div className='col-12 about-product-inner'>
              <div className='mt-2 about-products'>
                <div className='sec-title sec-title_list'>
                  <h2
                    className='text-capitalize'
                    style={{ marginBottom: '20px' }}
                  >
                    Xpand Academy
                  </h2>
                </div>
              </div>
            </div>

            {/* {dashContent &&
              dashContent !== 'undefined' &&
              dashContent?.map((items, index) => {
                if(!uniqueData.includes(items?.topic)) {
                    uniqueData.push(items?.topic)
                  return (
                    <div
                      className='col-xl-4 col-md-6 mb-2 col_details xpand_card'
                      key={index}
                    >
                      <DetailsCard
                        cardTitle={items?.topic}
                        cardDesc={items?.description}
                        pdfLink={`/dashboard/details/${items?._id}`}
                        videoLink={`/dashboard/details/${items?._id}`}
                        cardImg={serverPath + items?.image}
                        seeMore={`/dashboard/details/${items?._id}`}
                        pdfCounter={items?.pdf?.length}
                        VideoCounter={items?.videos?.length}
                        raiseQueryLink={''}
                      />
                    </div>
                  )
                }
              })}
            {dashContent &&
              dashContent !== 'undefined' &&
              dashContent?.length === 0 && (
                <div className='text-secondary'>Data Not Available</div>
              )}
            {productApiData?.isLoading && (
              <>
                <div className='col-xl-4 col-md-6 mb-2 col_details'>
                  <SkeletonCardLoader />
                </div>
                <div className='col-xl-4 col-md-6 mb-2 col_details'>
                  <SkeletonCardLoader />
                </div>
                <div className='col-xl-4 col-md-6 mb-2 col_details'>
                  <SkeletonCardLoader />
                </div>
              </>
            )} */}

            {dashContent &&
              dashContent?.length !== 0 &&
              dashContent?.map((productItems, index) => {
                if (!uniqueData.includes(productItems?.contentId?.topic)) {
                  uniqueData.push(productItems?.contentId?.topic)
                  return (
                    <>
                      <div
                        className='col-xl-4 col-md-6 mb-2 col_details xpand_card'
                        key={index + 1}
                      >
                        <DetailsCard
                          key={index}
                          cardTitle={productItems?.contentId?.topic}
                          cardDesc={productItems?.contentId?.description}
                          pdfLink={`/dashboard/details/${productItems?.contentId?._id}`}
                          videoLink={`/dashboard/details/${productItems?.contentId?._id}`}
                          cardImg={serverPath + productItems?.contentId?.image}
                          seeMore={`/dashboard/details/${productItems?.contentId?._id}`}
                          pdfCounter={productItems?.contentId?.pdf?.length}
                          VideoCounter={productItems?.contentId?.videos?.length}
                          productId={productItems?.product}
                          topcId={productItems?.contentId?._id}
                        />
                      </div>
                    </>
                  )
                }
              })}
            {dashContent?.length === 0 && (
              <div className='text-secondary'>Data Not Available</div>
            )}
            {productApiData?.isLoading && (
              <>
                <div className='col-lg-4 col-md-6 mb-2 col_details'>
                  <SkeletonCardLoader />
                </div>
                <div className='col-lg-4 col-md-6 mb-2 col_details'>
                  <SkeletonCardLoader />
                </div>
                <div className='col-lg-4 col-md-6 mb-2 col_details'>
                  <SkeletonCardLoader />
                </div>
              </>
            )}
          </div>
        </div>
        {/* <!-- ======= Footer ======= --> */}
        <Footer logoLink={'/'}
          home={'/#home'}
          about={'/#about'}
          service={'/#service'}
          faq={'/#faq'}
          testimonial={'/#testimonials'}
        />
      </section>
    </>
  )
}

export default DetailsPage
