import React from 'react'
import '../../styles/card.css'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

function LandingCards (props) {


  return (
    <>
      <div className='c-dashboardInfo langing_cards_main'>
        <div className='wrap'>
          <h4 className='heading heading5 hind-font medium-font-weight langing_cards_main_title'>
            <i>
              <img src={props.iconSrc} alt='icon' />
            </i>
            <Link to={props.link}>
            {props.cardTitle}
            </Link>
          </h4>
          <div className='landing_amount_cont mb-2 d-flex align-items-center'>
            <span className='hind-font caption-12 landing_cards__count'>
              &#8377; {props.cardAmount}
            </span>
            <span className={props.investType}>
              {props.arrowDirection} {props.putUpValue}
            </span>
          </div>
          <span className='updates__cont'>
            vs Last month:{' '}
            <span className='updates__cont_amount'>
              {props.updatesTime ? props.updatesTime : ''}
            </span>
          </span>
        </div>
      </div>
      {/* <div className='c-dashboardInfo col-sm-6 col-md-4 col-lg-3'>
        <div className='wrap'>
          <h4 className='heading heading5 hind-font medium-font-weight c-dashboardInfo__title'>
            {props.cardTitle}
            <svg
              className='MuiSvgIcon-root-19'
              focusable='false'
              viewBox='0 0 24 24'
              aria-hidden='true'
              role='presentation'
            >
              <path fill='none' d='M0 0h24v24H0z'></path>
              <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z'></path>
            </svg>
          </h4>
          <span className='hind-font caption-12 c-dashboardInfo__count'>
            &#8377; {props.cardAmount}
          </span>
        </div>
      </div> */}
    </>
  )
}

export default LandingCards
