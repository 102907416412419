import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/mainCards.css'
import '../../styles/loginFormStyle.css'
import { useGetOtpMutation } from '../../store/services/loginService'
import { useSelector } from 'react-redux';
import axios from 'axios'
import { Api_Base_Url } from '../../constant/matcher'

function Profile (props) {
  const [signupBtn, setSignupBtn] = useState(true)
  const [popupForm, setPopupForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [changePassForm, setChangePassForm] = useState(false)

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [userInfo, setUserInfo] = useState([])

  useEffect(() => {
    setName(props.username)
    setEmail(props.email)
    setMobile(props.mobile)
  }, [props.username,props.email,props.mobile]);

  const profDetails = useSelector(state => state?.dashReducer?.profileDetails)

  const rmDetMail = profDetails?.slug?.toUpperCase() === 'Investor' ? 'support@xpandwealth.com' : 'help@xpandwealth.com'
  
  const showChang_form = () => {
    setChangePassForm(true)
    setPopupForm(true)
    setEditForm(false)
  }

  const showEditform = () => {
    setChangePassForm(false) 
    setPopupForm(true)
    setEditForm(true)
  }

  const closeChngPassPopup = () => {
    setChangePassForm(false)
    setEditForm(false)
    setPopupForm(false)
  }

  const [editProfile, setEditProfile] = useState({
    name: profDetails?.name,
    email: profDetails?.email,
    phone: profDetails?.phone,
    otp: ''
  })

  // useEffect(() => {
  //   console.log(profDetails);
  // }, [profDetails]);

  const edtiProHandler = e => {
    setprevDetails({ ...editProfile, [e.target.name]: e.target.value })
  }

  const submitEditForm = (e) => {
    e.preventDefault(
      console.log('edit')
    )
  }

  const [prevDetails, setprevDetails] = useState({
    old_password: '',
    new_password: '',
    c_password: '',
  })

  const changePassHandler = e => {
    setprevDetails({ ...prevDetails, [e.target.name]: e.target.value })
  }

  // api to get otp
  const [otpNum, setOtpNum] = useState(false)
  const [optPhone, otpResp = {}] = useGetOtpMutation()

  useEffect(() => {
    const otpInput = document.getElementById('otp-input')
    if (
      otpResp?.isSuccess &&
      !otpResp?.isLoading &&
      otpResp.status === 'fulfilled' &&
      !otpResp?.isError &&
      otpResp?.data.message &&
      !otpResp?.data?.otpData
    ) {
      console.log('notfournd')
    }
    if (
      otpResp?.isSuccess &&
      !otpResp?.isLoading &&
      otpResp.status === 'fulfilled' &&
      !otpResp?.isError &&
      otpResp?.data?.otpData
    ) {
      if (otpInput) {
        otpInput.value = otpResp?.data?.otpData?.otp
        prevDetails.otp = otpResp?.data?.otpData?.otp
      }
    }
  }, [otpResp])
  // opt num submit handler
  const otpBtnClick = () => {
    var otpNum = document.getElementById('mobile')
    if (otpNum?.value?.length === 10) {
      optPhone({ phone: otpNum?.value })
    }
  }
  const userChangePass = e => {
    e.preventDefault()
    if (prevDetails.new_password === prevDetails.c_password) {
      console.log('passmatch', prevDetails)
    } else {
      console.log('npass and cpass must be equal')
    }
  }

  useEffect(() => {
    let mobInpt = document.getElementById('mobile')
    if (mobInpt) {
      mobInpt.oninput = () => {
        if (mobInpt.value.length > mobInpt.maxLength) {
          mobInpt.value = mobInpt.value.slice(0, mobInpt.maxLength)
        }
      }
    }
  }, [])

  const loginUserData = JSON.parse(
    localStorage.getItem("loginUserData")
  );
   let id = loginUserData._id
  let type = loginUserData.role

const getUserData = () => {
  axios.get(`${Api_Base_Url}/cp/getdetail?type=${type}&id=${id}`).then((res) => {
    setUserInfo(res?.data?.data)
    // console.log(res?.data?.data)
  })
}

useEffect(() => {
  getUserData()
}, [])


  const userLogginId = JSON.parse(localStorage.getItem('loginUserData'))
  const userId = userLogginId._id
  return (
    <>
    <div className='container content-wrapper px-4 py-5 details-page'>
      <div className='row mb-4'>
        <div className='col-md-12'>
          <div className='profile_header d-flex align-items-center justify-content-between'>
            <div className=''>
              <h3>My Profile</h3>
            </div>

            <div className='edit_profile'>
              <Link
                to={props?.editLink ? props?.editLink : '#'}
                className='edit_profie_btn btn'
                onClick={showEditform}
              >
                <i
                  className='fa-solid fa-pen-to-square'
                  onClick={showEditform}
                ></i>{' '}
                Edit Profile
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='row profile_wrapper'>
        <div className='col-md-8'>
          <div className='row'>
            <div className='col-md-12 col-task-header'>
              <div className='task_header d-flex align-items-center justify-content-between' >
                <h3 className='m-0' style={{fontSize: "17px", fontWeight: "600"}}>Personal Details</h3>
                {/* <div className='edit_profile'>
                  <Link
                    to={props?.editLink ? props?.editLink : '#'}
                    className='edit_profie_btn chnge_pass btn'
                    onClick={showChang_form}
                  >
                    <i className='fa-solid fa-unlock' onClick={showChang_form}></i> Change Password
                  </Link>
                </div> */}
              </div>
            </div>
            <div className='col-12'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='profile_contact_details user_details'>
                    <div className='contact_rows'>
                      <span className='det_sep'>Name</span> :{' '}
                      <span className='details_span'> {props?.username}</span>
                    </div>
                    <div className='contact_rows'>
                      <span className='det_sep'>Mobile</span> :{' '}
                      <span className='details_span'> {props?.mobile}</span>
                    </div>
                    <div className='contact_rows'>
                      <span className='det_sep'>Email</span> :
                      <span className='details_span'> {props?.email}</span>
                    </div>
                    <div className='contact_rows'>
                      <span className='det_sep'>City</span> :
                      <span className='details_span text-capitalize'>
                        {' '}
                        {props?.city}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       <div className='col-md-4 p-0 col-img-profile'>
<div className='dp_sec_cont'>
  <div className='rm_head d-flex align-items-center'>
    <i className='fa fa-handshake me-1'></i>{' '}
    <h3>Your Relationship Manager</h3>
  </div>
  <div className='profile_detail profile_contact_details'>
    <div className='contact_rows'>
      <span className='det_sep'>Name</span> :{' '}
      <span className='details_span'>{props?.rm_name} </span>
    </div>
    <div className='contact_rows'>
      <span className='det_sep'>Mobile </span> :{' '}
      <span className='details_span'> {props?.rm_mobile} </span>
    </div>
    <div className='contact_rows'>
      <span className='det_sep'>Email</span> :
      <span className='details_span'>{props?.rm_email?props?.rm_email:rmDetMail} </span>
    </div>
  </div>
</div>
</div>
      </div>
    </div>
  
    {popupForm &&
    <div className='form_popups'>
      {changePassForm && (
        <div className='chagne_pass_form'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 d-flex align-items-center justify-content-center'>
                <div
                  className='formByScan_container pb-4 pt-5 position-relative'
                  id='formByScan_container'
                  style={{overflow:'visible'}}
                >
                  <div className="popup_close_icon" onClick={closeChngPassPopup}>
                    <i className="fa fa-xmark" onClick={closeChngPassPopup}></i>
                  </div>
                  <form onSubmit={userChangePass} autoComplete='off'>
                    <h2
                      className='form-heading text-center mb-4'
                      style={{ fontSize: '22px' }}
                    >
                      Change Password
                    </h2>
                    <input
                      type='password'
                      placeholder='Old Password *'
                      required
                      autoComplete='new-password'
                      name='old_password'
                      value={prevDetails.old_password}
                      onChange={changePassHandler}
                    />
                    <input
                      type='password'
                      placeholder='New Password *'
                      required
                      autoComplete='new-password'
                      name='new_password'
                      value={prevDetails.new_password}
                      onChange={changePassHandler}
                    />
                    <input
                      type='password'
                      placeholder='Confirm Password *'
                      required
                      autoComplete='new-password'
                      name='c_password'
                      value={prevDetails.c_password}
                      onChange={changePassHandler}
                    />
                    <div className='mt-2 text-center'>
                      <button disabled={signupBtn}>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {editForm && (
        <div className='chagne_pass_form'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 d-flex align-items-center justify-content-center'>
                <div
                  className='formByScan_container pb-4 pt-5 position-relative'
                  id='formByScan_container'
                  style={{overflow:'visible'}}
                >
                  <div className="popup_close_icon" onClick={closeChngPassPopup}>
                    <i className="fa fa-xmark" onClick={closeChngPassPopup}></i>
                  </div>
                  <form onSubmit={submitEditForm} autoComplete='off'>
                    <h2
                      className='form-heading text-center mb-4'
                      style={{ fontSize: '22px' }}
                    >
                      Edit Profile
                    </h2>
                    <input
                      type='text'
                      placeholder='Name'
                      required
                      autoComplete='new-password'
                      name='name'
                      value={editProfile.name}
                      onChange={edtiProHandler}
                    />
                    <input
                      type='email'
                      placeholder='Email'
                      required
                      autoComplete='new-password'
                      name='email'
                      value={editProfile.email}
                      onChange={edtiProHandler}
                    />
                    <div className='optMobile position-relative w-100'>
                      <input
                        type='number'
                        placeholder='Mobile Number *'
                        id='mobile'
                        maxLength='10'
                        required
                        name='phone'
                        value={editProfile.phone}
                        onChange={edtiProHandler}
                      />
                      <div
                        className='otp-btn btn_formbyscan'
                        onClick={otpBtnClick}
                      >
                        Send OTP
                      </div>
                    </div>
                    <input
                      type='number'
                      placeholder='OTP *'
                      id='otp-input'
                      required
                      name='otp'
                      value={editProfile.otp}
                      onChange={edtiProHandler}
                    />
                    <div className='mt-2 text-center'>
                      <button disabled={signupBtn}>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    }
    </>
  )
}

export default Profile


