import React from 'react'
import madhuImg from "../../signature/madhu-400x400-1.jpg"
import sashiImg from "../../signature/Shashi-400x400-1-300x300.jpg"
import sanketImg from "../../signature/Sanket-400x400-1.jpg"
import krushnaImg from "../../signature/krushna.jpeg"
import parikshitImg from "../../signature/Parixit-480x480-1.jpg"
import rajeevImg from "../../signature/Rajeev.jpg"
const Support = (props) => {

  return (
    <>
      <div className="SupportTeam">
        <div className="container">
            <div className="row mt-3">
                <div className="col-sm-6 mb-2">
                    <div className="card h-100 ">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-center col-md-6">
                                <div><img src={krushnaImg} alt=""
                                        className="avatar-md rounded-circle img-thumbnail" /></div>

                            </div>
                            <div className="cardContent col-md-6">
                                <div className="flex-1 my-2">
                                    <span className="badge bg-success mb-0">Mutual Fund Operations</span>
                                    <h5 className="font-size-16 mb-1"> Krushna Kadu</h5>

                                </div>
                                <p className="text-muted mb-0"><i className="fa-solid fa-phone"></i> &nbsp; 9911005771</p>
                                <p className="text-muted mb-0 mt-2"><i className="fa-solid fa-envelope"></i> &nbsp;
                                    krushna.aufait@gmail.com </p>

                            </div>

                        </div>
                    </div>
                </div> 
                <div className="col-sm-6 mb-2">
                    <div className="card h-100">
                        <div className="card-body">

                            <div className="d-flex align-items-center justify-content-center col-md-6">
                                <div><img src={madhuImg} alt=""
                                        className="avatar-md rounded-circle img-thumbnail" /></div>

                            </div>
                            <div className="cardContent col-md-6">
                                <div className="flex-1 my-2">
                                {
                                    props.type === "investor" ? (

                                        <span className="badge bg-success mb-0">Client Relationship Desk</span>
                                    )
                                    : 
                                    (
                                        <span className="badge bg-success mb-0">Channel Relationship Desk</span>
                                    )
                                }

                                    <h5 className="font-size-16 mb-1"> Ms Madhu Diwaker
                                    </h5>
                                </div>
                                <p className="text-muted mb-0"><i className="fa-solid fa-phone"></i> &nbsp; 8130388996</p>
                                <p className="text-muted mb-0 mt-2"><i className="fa-solid fa-envelope"></i> &nbsp;
                                    madhu.aufait@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 mb-2">
                    <div className="card h-100">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-center col-md-6">
                                <div><img src={sashiImg} alt=""
                                        className="avatar-md rounded-circle img-thumbnail" /></div>
                            </div>
                            <div className="cardContent col-md-6">
                                <div className="flex-1 my-2">

                                    <span className="badge bg-success mb-0">Equity Advisory Support</span>
                                    <h5 className="font-size-16 mb-1"> Shashi Kant
                                    </h5>
                                </div>
                                <p className="text-muted mb-0"><i className="fa-solid fa-phone"></i> &nbsp; 9899215244</p>
                                <p className="text-muted mb-0 mt-2"><i className="fa-solid fa-envelope"></i> &nbsp;
                                    shashikant@brightermind.in </p>
                            </div>
                        </div>
                    </div>
                </div>
             
                <div className="col-sm-6 mb-2">
                    <div className="card h-100">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-center col-md-6">
                                <div><img src={sanketImg} alt=""
                                        className="avatar-md rounded-circle img-thumbnail" /></div>
                            </div>
                            <div className="cardContent col-md-6">
                                <div className="flex-1 my-2">

                                    <span className="badge bg-success mb-0">Tech Support</span>
                                    <h5 className="font-size-16 mb-1"> Sanket Borgaonkar
                                    </h5>
                                </div>
                                <p className="text-muted mb-0"><i className="fa-solid fa-phone"></i> &nbsp; 8999463307</p>
                                <p className="text-muted mb-0 mt-2"><i className="fa-solid fa-envelope"></i> &nbsp;
                                    sanket.brighterminds@gmail.com </p>
                            </div>
                        </div>
                    </div>
                </div>
          
            </div>
        </div>
        <div className="First-Level-Escalation my-5">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <h4 className="card-title">First Level Escalation </h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center level">
                                    <div><img src={parikshitImg} alt=""
                                            className="avatar-md rounded-circle img-thumbnail" /></div>
                                    <div className="flex-1 ms-3">
                                        <h5 className="font-size-16 mb-1 mx-4"> Parikshit
                                            Jha </h5>

                                    </div>
                                </div>

                                <p className="text-muted mb-0"><i className="fa-solid fa-phone"></i> &nbsp; 9711801195
                                </p>
                                <p className="text-muted mb-0"><i className="fa-solid fa-envelope"></i> &nbsp;
                                    parixitjha@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div className="Second-Level-Escalation my-5">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <h4 className="card-title">Second Level Escalation </h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center level  ">
                                    <div><img src={rajeevImg} alt=""
                                            className="avatar-md rounded-circle img-thumbnail" /></div>
                                    <div className="flex-1 ms-3">
                                        <h5 className="font-size-16 mb-1"> CA Rajeev
                                            Ranjan</h5>

                                    </div>
                                </div>

                                <p className="text-muted mb-0"><i className="fa-solid fa-phone"></i> &nbsp; 9818311864
                                </p>
                                <p className="text-muted mb-0"><i className="fa-solid fa-envelope"></i> &nbsp;
                                    rajeevranjan@varindia.net </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Support