import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PUBLIC_PATH } from "../../constant/matcher";
import "../../styles/hoverCards.css";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";
function HoverCards(props) {
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [quickInvest, setQuickInvest] = useState({
    username: loggedInUserData?.eliteusername,
    password: loggedInUserData?.elitepassword,
    loginType: loggedInUserData?.eliterole,
    loginFor: loggedInUserData?.eliterole,
    callbackUrl: window.location.href,
  });
  const [qickPass, setQickPass] = useState("");

  var CryptoJSAesJson = {
    stringify: function (cipherParams) {
      var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j);
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(j.ct),
      });
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
      return cipherParams;
    },
  };

  useEffect(() => {
    var key = "W@ealth!@$#$(%#H(^%)";
    // Encrypt
    var EncryptedPass = CryptoJS.AES.encrypt(
      JSON.stringify(quickInvest.password),
      key,
      { format: CryptoJSAesJson }
    ).toString();

    // console.log(EncryptedPass)

    setQickPass(EncryptedPass);

    setQuickInvest({ ...quickInvest, [quickInvest.password]: EncryptedPass });
  }, []);

  return (
    <>
      <div className="hover__card  mb-lg-3  ">
        <form
          method="post"
          className="quickLogForm w-100 "
          action="https://wealthelite.in/Login/login/validate-user"
        >
          <input
            type="text"
            placeholder="Name *"
            name="username"
            readOnly
            value={quickInvest.username}
            className=" position-absolute "
          />
          <input
            type="text"
            placeholder="Password *"
            name="password"
            readOnly
            value={qickPass}
            className=" position-absolute "
          />
          <input
            type="text"
            placeholder="Login Type *"
            name="loginType"
            readOnly
            value={quickInvest.loginType}
            className=" position-absolute "
          />
          <input
            type="text"
            placeholder="Login For *"
            name="loginFor"
            readOnly
            value={quickInvest.loginFor}
            className=" position-absolute "
          />
          <input
            type="text"
            placeholder="Callback *"
            name="callbackUrl"
            readOnly
            value={window.location.href}
            className=" position-absolute "
          />
          {}
          <button
            type="submit"
            className={`hover_card card_hover_sha border-0 w-100  ${props.colorClass}`}
          >
            <div className="circle position-relative">
              <div className="overlay"></div>
              <img src={PUBLIC_PATH + props.iconsSrc} alt="icons" />
            </div>
            <p>{props.cardTitle}</p>
          </button>
        </form>
      </div>
    </>
  );
}

export default HoverCards;
