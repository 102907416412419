import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../styles/mainCards.css'
import { PUBLIC_PATH } from '../constant/matcher'

function DashCards (props) {
  const [paragraph, setParagraph] = useState('')

  useEffect(() => {
    if (props.cardDesc?.length > 10) {
      setParagraph(props.cardDesc?.substring(0, 50))
    } else {
      setParagraph(props.cardDesc)
    }
    if (window.screen.width < 1318 && window.screen.width >= 1200) {
      if (props.cardDesc?.length > 10) {
        setParagraph(props.cardDesc?.substring(0, 40))
      } else {
        setParagraph(props.cardDesc)
      }
    }
    if (window.screen.width < 400) {
      if (props.cardDesc?.length > 10) {
        setParagraph(props.cardDesc?.substring(0, 19))
      } else {
        setParagraph(props.cardDesc)
      }
    }
  }, [props.cardDesc, paragraph])

  return (
    <>
      <Link
        to={props.cardLink ? props.cardLink : ''}
        className='dash_card_link'
      >
        <div className='cards dash_card_w'>
          <div className='cards_item'>
            <div className='card w-100'>
              <div className='card_image'>
                <img src={props.cardImg} alt='' />
              </div>
              <div className='card_content'>
                <h2 className='card_title'>{props.cardTitle}</h2>
                <div className='mb-1 para_overflow_cont'>
                  <p className='card_text d-inline card_text_semore mb-0'>
                    {paragraph}
                  </p>
                  {paragraph !== '' && (
                    <Link
                      to={props.cardLink ? props.cardLink : ''}
                      className='see_more'
                    >
                      ..See More
                    </Link>
                  )}
                </div>
                <div className='doc-img'>
                  <div className='pdf-img text-center'>
                    {/* <Link to={props.pdfLink} className='pdfLink'> */}
                    <img
                      src={PUBLIC_PATH + 'assets/img/pdf.png'}
                      className='d-block'
                      alt='pdf'
                    />
                    <span className='pdf-counter'>
                      {props.pdfCounter ? props.pdfCounter : '0'}
                    </span>
                    {/* </Link> */}
                  </div>
                  <div className='video-img text-center'>
                    {/* <Link to={props.videoLink} className='videoLink'> */}
                    <img
                      src={PUBLIC_PATH + 'assets/img/video.png'}
                      className='d-block'
                      alt='vid'
                    />
                    <span className='video-counter'>
                      {props.VideoCounter ? props.VideoCounter : '0'}
                    </span>
                    {/* </Link> */}
                  </div>
                </div>
                <div className='offer_cont position-relative text-center'>
                  <span className='offer_text'>
                    <i className='fa-solid fa-circle-exclamation'></i> Click to
                    complete this course
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  )
}

export default DashCards
