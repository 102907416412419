import React, { useRef } from "react";
import { PUBLIC_PATH } from "../../constant/matcher";
import xpandSignature from '../../signature/xpandSignature1.png';

import brandLogo from "../../signature/logo192.png"


const Agreement = ({formData}) => {
  const { panNumber, address, signatureUrl, name } = formData;

  const getDayWithOrdinalSuffix = (day) => {
    const suffixes = ['st', 'nd', 'rd'];
    const specialCases = [11, 12, 13];
    
    const lastDigit = day % 10;
    const suffix = suffixes[lastDigit - 1] || 'th';
    
    return specialCases.includes(day) ? `${day}th` : `${day}${suffix}`;
  };

  const currentDate = new Date();
  const day = getDayWithOrdinalSuffix(currentDate.getDate());
  const month = currentDate.toLocaleString('default', { month: 'long' });
  const year = currentDate.getFullYear();

  
  
  
  return (
    <>
       <div className="pdf-page" id="pdf-content" >
         
        <div className="page" id="page">
          <div className="pdf-logo">
            <img src={brandLogo} alt="brandLogo" />
          </div>
          <p style={{ textAlign: "center" }} className="agreement-heading">
            <strong>BUSINESS PARTNER AGREEMENT</strong>
          </p>
          <p>
            This Au-Fait Fincare Private Limited Business Partner Agreement has
            been made and entered
          </p>
          <p>
            into at <span style={{textDecoration: "underline", fontWeight: "bold"}}>Noida</span> as on{" "}
            <span  style={{textDecoration: "underline", fontWeight: "bold"}}>{day}</span>day of <span  style={{textDecoration: "underline", fontWeight: "bold"}}>{month}</span> of the year{" "}
            <span  style={{textDecoration: "underline", fontWeight: "bold"}}>{year}</span> between
          </p>
          <p>
            1. Au-Fait Fincare Private Limited, a company registered under the
            Companies Act, 2013 bearing CIN No. U74140DL2013PTC251311 and PAN:
            AALCA5509Q and having Corporate Office address at 304, Tower-4,
            Assotech Business Cresterra Sector-135,Noida 201304; and includes
            its successors, executors, administrators, officers and permitted
            assigns of the First Part. Hereafter referred as “Xpand Wealth” is
            the technology platform which is solely owned by Au-Fait Fincare
            Private Limited with all rights reserved.
          </p>
          <strong>AND</strong>
          <p>
            2. 
              <span style={{textDecoration: "underline", fontWeight: "bold"}}>  {name}  </span>
            having PAN: <span style={{textDecoration: "underline", fontWeight: "bold"}}>{panNumber}</span> Resident of / Registered
            Office / Office at <span style={{textDecoration: "underline",  fontWeight: "bold"}}>{address} </span>
             and includes its successors, executors, administrators, officers,
            heirs and permitted assigns (hereafter referred to as “Partner”) of
            the Other Part.
          </p>
          <p>
            Xpand Wealth and Partner will be collectively referred to as
            “Parties” and severally as “Party”
          </p>
          <strong>WHEREAS:</strong>
          <p>
            1. Xpand Wealth is inter alia in the business of distribution of
            various financial products and provision of services, including but
            not limited to, Mutual Funds, Equity Advisory Services, Real Estate,
            Fixed Deposits of Companies, Insurance and support the Partner for
            conducting the distribution business.
          </p>
          <p>
            2. Xpand Wealth intends to appoint certain Sub-Distributors/Partners
            in the conduct of its distribution business who will be authorized
            to distribute the product and undertake related Services.
          </p>
          <p>
            3. The Partner and Xpand Wealth are signing this Agreement to agree
            upon certain terms and condition.
          </p>
          <strong>I. Definitions</strong>
          <p>
            1. <strong>“Distributor”</strong>, <strong>“Partner”</strong>, <strong>“Business Partner”</strong> or <strong>“Xpand Wealth
            Partner”</strong> shall mean and include any person who is eligible to
            distribute products or services offered by Xpand Wealth and duly
            appointed and recognized as a Business Partner under its division
          </p>
          <p>
            2. <strong>“Client(s)”</strong> or <strong>“Customer”</strong> shall mean and include persons making
            transactions through the Partner in various Products and Services
            offered by Xpand Wealth.
          </p>
          <p>
            3. <strong>“Xpand Wealth Portal”</strong> encompasses all the websites, links, and
            pages provided by Xpand Wealth. It grants access to web pages,
            online desks, user accounts, utilities, forms, surveys, reports,
            financial tools, articles, documents, media content, news, Products,
            Services, market information, third-party links, and more. The Xpand
            Wealth Portal is accessible through various technologies and
            platforms, including mobile platforms, operating systems, and mobile
            applications. It includes all online user desks, mobile
            applications, and mobile desks, such as the Partner Desk, Account
            Desk, and Client Desk.
          </p>
        </div>
        <div className="page" id="page"
        style={{marginTop: "10px"}}
        >
        <div className="pdf-logo">
        <img src={brandLogo} alt="brandLogo" />
          </div>
         <p> 4. <strong>“Partner Desk”</strong> shall mean the areas in the Xpand Wealth Portal to which the Partner gets access by virtue 
of a unique log-in identity and password. The Partner Desk contains information related to distribution 
business, Client information, MIS and other related information specific to the Partner.</p>
<p>5. <strong>“Client Desk”</strong> shall mean the areas in the Xpand Wealth Portal to which the Client gets access by virtue of a 
unique log-in identity and password. The Client Desk contains their portfolio summary and other related 
information specific to the Client.</p>
<p>6. <strong>“PSC” </strong> shall mean the Partner Service Center as run and operated by Xpand Wealth and/or any other entity 
as appointed by Xpand Wealth.</p>
<p>7. <strong>“Product(s)”</strong> shall mean financial or non-financial products available with Xpand Wealth from time to time 
and offered specifically to the Partners for distributing such products to their Clients in any mode or format.</p>
<p>8. <strong>“Service(s)”</strong> shall mean support and related services to promote the distribution business of the Partners as 
specifically offered by Xpand Wealth from time to time. Some of the Services may require acceptance of
specific terms & conditions by the Partner.</p>
<p>9. <strong>“SRO”</strong> shall mean a self-regulatory organization recognized by Securities and Exchange Board of India for 
the distribution of equity advisory services.</p>
<p>10. <strong>“Transaction(s)”</strong> shall mean all transactions made by Clients, including but not limited to, any purchase,
redemption, sale, switch or transfer, in any of the Products and Services in any manner or mode, either directly 
or indirectly at any point of time.</p>
<p>11. <strong>“IA Regulations”</strong> shall mean Securities and Exchange Board of India (Investment Advisers) Regulations,
2013, as may be amended from time to time.</p>
<p>12. <strong>"Intellectual Property"</strong> or “IP” shall mean any ideas, concepts, creations, discoveries, inventions, 
improvements, know-how, trade or business secrets, trademarks, service marks, designs, copyrights, utility 
models, tools, devices, models, methods, procedures, processes, systems, principles, algorithms, works of 
authorship, flowcharts, drawings, books, papers, sketches, formulas, teaching techniques, electronic codes, 
proprietary techniques, research and development projects and data, and other confidential and proprietary 
information, computer programming code, databases, data, documents, instruction manuals, records, 
memoranda, notes, user guides, whether in printed or machine-readable form or any other format. This 
includes information that may or may not be copyrightable, patentable, or protected under other intellectual 
property laws, as well as any written or verbal instructions or comments.</p>
<strong>II. General Interpretation:</strong>
<p>a) In this Agreement, the headings are used for convenience and ease of reference and are not to be
construed in the construction or interpretation of any provision of the Agreement.</p>
<p>b) In this Agreement, unless the context specifies otherwise, reference to the singular includes a reference to 
the plural and vice versa, and reference to any gender includes a reference to all other gender.</p>
<p>c) If there are any contrary provisions in this Agreement and the Schedules, the provisions of this Agreement 
shall prevail over the Schedule.</p>
<p>d) In this Agreement, unless the context specifies otherwise, references to the Recitals, Clauses, Annexure,
Appendix and Schedules shall be deemed to be a reference to the recitals, clauses, Annexure, appendix and 
schedules of this Agreement</p>
        </div>
        <div className="page" id="page"
        style={{marginTop: "10px"}}
        >
        <div className="pdf-logo">
        <img src={brandLogo} alt="brandLogo" />
          </div>
          <p>e) If there are any contrary provisions in this Agreement and any subsequent Addendum, the provisions of the
Addendum shall prevail over this Agreement.</p>
<p>f) Any word or meaning not defined in this Agreement shall have the same meaning as the definitions under
this Agreement.</p>
<p>g) The word "includes" shall be construed as "without limitation".</p>
<strong>III. Scope (Eligibility & Appointment) and Terms of Appointment:</strong>
<p>The satisfactory fulfillment of the below mentioned terms is required for the Partner to enter into this 
Agreement:</p>
<p>1. Prior to entering into this Agreement, any individual seeking to distribute equity advisory services must 
meet the qualifications and eligibility criteria specified by the relevant laws, regulations, and rules. These 
qualifications are necessary to engage in the distribution of such Products and receive Services offered by 
Xpand Wealth.</p>
<p>2. The Partner or Partner Representative must possess all necessary eligibility and valid certifications to engage 
in the distribution and/or advisory of Products in India. This must be done in accordance with all applicable 
laws and guidelines issued by industry regulators, self-regulatory organizations (SROs), or any other authority, 
as may be updated from time to time.</p>
<p>3. Xpand Wealth reserves the right to add, amend, suspend, or terminate any Product or Service at its sole 
discretion. These decisions will be based on internal guidelines, procedures, and compliance with applicable 
laws.</p>
<p>4. Upon the execution of this Agreement, Xpand Wealth will register the Partner and issue an Appointment 
Letter confirming their eligibility to distribute specific Products and receive Services. If a Partner is not initially 
eligible to distribute a particular Product but becomes eligible at a later date, they may gain eligibility upon 
submission of relevant proofs and details to Xpand Wealth. The eligibility will be confirmed by Xpand Wealth 
after reviewing the submitted documents.</p>
<p>5. The Partner must disclose the names of individuals engaged in the distribution of Xpand Wealth's Products 
and Services on their behalf. This includes providing their relevant certifications, eligibility for specific Products 
and Services, and their relationship with the Partner. The Partner is responsible for updating Xpand Wealth 
with any changes related to these individuals, such as new joinings or retirements. All individuals involved 
must adhere to the terms and conditions of this Agreement and comply with applicable laws.</p>
<p>6. Xpand Wealth grants the Partner a non-transferable, revocable, and non-exclusive license to use the Xpand 
Wealth Portal and other online facilities strictly for the legitimate purposes outlined in this Agreement.</p>
<p>7. If a non-individual applicant is enrolled as a Partner, they must designate an individual (referred to as 
"Partner Representative") associated with the Partner in an authorized capacity such as an employee, director, 
partner, or similar role. The Partner Representative will be authorized to act on behalf of the Partner and 
undertake all necessary functions, actions, or decisions. The details of the Partner Representative must be 
provided to Xpand Wealth, and any changes in the Partner Representative must be communicated to Xpand 
Wealth within a reasonable timeframe.</p>
<p>8. The Partner Representative has the authority to bind the Partner to this Agreement, and the Partner must 
sign this Agreement through an authorized signatory. All actions taken by the Partner Representative will have 
a binding effect on the Partner, including but not limited to any Transactions, signing of Service Agreements, or 
any requests. Any acts or omissions by the Partner Representative will be treated as acts or omissions of the 
Partner under this Agreement.</p>
        </div>
        <div className="page" id="page"
        style={{marginTop: "10px"}}
        >
        <div className="pdf-logo">
        <img src={brandLogo} alt="brandLogo" />
          </div>
          <p>9. Any individual engaged in the distribution of Xpand Wealth's Products or provision of Services on behalf of 
the Partner will bind the Partner. All actions or omissions by these individuals will be treated as acts or 
omissions of the Partner under this Agreement.</p>
<p>10. The relationship between the Partner and Xpand Wealth shall be on a 'principal-to-principal' basis. The 
Partner is not an agent or employee or representative of Xpand Wealth and shall have no rights whatsoever to 
represent or bind or act on behalf of Xpand Wealth so as to bind or make party or engage Xpand Wealth into 
any contract, agreement, legal relationship, any form of liability, etc. with any person, either directly or 
indirectly in any manner whatsoever. This includes, but is not limited to, actions taken in performance of any 
activity, duty or service related directly or indirectly to the distribution of any Product or receipt of or provision 
of any Service rendered to any Client as a Partner subject to the applicable laws.</p>
<p>11. The Partner by the virtue of this Agreement is eligible to earn Commissions and other incentives on
business mobilized in eligible Products and Services offered by Xpand Wealth. Xpand Wealth shall 
communicate such rates separately from time to time.</p>
<p>12. The Partner is free to nominate the nominee(s) to whom the benefits of any Commission due shall accrue
upon the death of the Partner subject to applicable laws. However, the same shall be applicable only in case of 
Partners who are individuals. The Agreement shall be deemed to be terminated from the date of the death of 
the Partner.</p>
<div className="para-column">
<strong>IV. Rights & Obligations of Partner:</strong>
<strong style={{margin: "10px 15px"}}>A) Client Handling:</strong>
</div>
<p>1. The Partner shall maintain all the information of their Clients required for the conduct of its business and as 
required under applicable KYC and other applicable laws and shall make available any such information to 
Xpand Wealth upon request.</p>
<p>2. The Partner shall be allowed to create and manage "Group Account" or family account of multiple related
Clients. Xpand Wealth in no case shall be responsible to the Client for any issue or dispute arising out of 
incomplete or incorrect grouping of Clients done by the Partner, including but not limited to display of 
confidential information to any other Client, Partner or any third party.</p>
<p>3. The Partner shall be allowed to request Xpand Wealth the user-id and password of the Client Desks for their 
Group Accounts. The Clients can access their Client Desk with such requested log in ID & password through the
Xpand Wealth Portal.</p>
<p>4. The Client of the Partner who has opened account with Xpand Wealth may be given a user id and password 
to access his Transactions and holdings through the account.</p>
<p>5. The Client and the Partner will be responsible for the bona-fide use of Xpand Wealth Portal and also for the 
privacy and security of such log in Id and password. Xpand Wealth in no case shall be responsible to any Client 
or Partner, whatsoever, for the unauthorized access of Xpand Wealth Portal or for the leakage of any 
confidential information of a Client.</p>
<p>6. The Partner shall review and re-verify the information or any data made available by Xpand Wealth in any 
mode or manner, including Xpand Wealth Portal, before making any decision or recommendation or any Client
communication based on such information or data.</p>
<p>7. The Partner shall act in the best interests of the Client in true spirit and shall abide by all the rules,
regulations, applicable laws and Xpand Wealth's internal governance and policies in terms of distribution of 
the Products & Services and set up high service standards and governance.</p>

        </div>
        <div className="page" id="page"
        style={{marginTop: "10px"}}
        >
        <div className="pdf-logo">
        <img src={brandLogo} alt="brandLogo" />
          </div>
         <p> 8. Xpand Wealth reserves the right to conduct Audit of the Partner's Business and inspect relevant documents 
in connection with the Agreement. Partner shall support and co-operate in providing such information in a
timely manner.</p>
<p>9. The Partners understands and acknowledges that the Client opening an account with Xpand Wealth
becomes a Client of Xpand Wealth and Xpand Wealth shall be required to communicate to such Clients directly 
from time to time.</p>
<strong>B) Statutory Requirements:</strong>
<p>1. The Partner specifically ensures that any person involved or engaged in selling or distribution of equity 
advisory services on behalf the Partner, is eligible and duly authorized to do so and has the necessary 
certification as required by applicable laws and guidelines released by regulators & governing authorities, 
including SEBI, AMFI and any industry SRO.</p>
<p>2. The Partner engaged in distribution of equity advisory services declares that neither Partner nor any of his
related party or associate is acting as Investment Adviser under IA Regulations. In case, a Partner or any
related party or associate wishes to operate as an "Investment Adviser", a written communication must be 
made to Xpand Wealth, without failure and the Partner shall either terminate this Agreement or shall only be
eligible subject to adherence to certain terms and conditions of Xpand Wealth in addition to the IA 
Regulations.</p>
<p>3. The Partner shall be solely responsible for adhering to the implementation of KYC (Know Your Customer)
and AML (Anti Money Laundering) norms, processes, compliances under the PMLA regulations & guidelines 
given by the regulatory authorities, AMCs, SRO and Xpand Wealth from time to time. In case of any 
noncompliance thereof, the Partner agrees that XPAND WEALTH cannot be held responsible for the same.</p>
<strong>C) General Rights & Obligations</strong>
<p>1.The Partner is prohibited from using any name, reference, trademark, logo, or copyright associated with any 
entity, business, Product, Service, brand, company, customer, associate, or Employee of Xpand Wealth, 
whether directly or indirectly, in any mode, format, medium, or manner, unless expressly approved in writing 
by Xpand Wealth. However, the Partner may utilize the exact format, language, and presentation in their 
communication or literature for a pre-approved purpose as explicitly communicated by Xpand Wealth.</p>
<p>2. The Partner is granted the right to access and utilize the Xpand Wealth Portal and other designated websites 
as provided and updated by Xpand Wealth periodically. It is the sole responsibility of the Partner to ensure the 
protection and privacy of their user ID and password for the Xpand Wealth Portal and any other online 
facilities offered by Xpand Wealth.</p>
<p>3. The Partner must inform Xpand Wealth about the individuals authorized to access the Xpand Wealth Portal 
on their behalf. Any requests or actions performed by these authorized individuals shall be considered as 
authorized by the Partner. The user login ID and password provided to the Partner during the registration 
process will serve as the administrative user ID and password for the Partner Desk.</p>
<p>4. The Partner shall be allowed to access only open and visitor spaces at all PSCs ("Partner Service Centers") 
during business /operating hours. Access to restricted /confidential areas at any PSC is strictly prohibited to 
Partner. The Partner is also strictly prohibited to use the IT, communication or any other assets (computers, 
networks, internet, printers, scanners, telephones, projectors, etc.) of the PSC.</p>
<p>5. The Partner shall have no right to inspect and/or have any access to the books, records, documents,
facilities, systems, infrastructure, processes, work manners, data, methodologies, etc. of Xpand Wealth and/or 
of any PSC, either in part or in full. The Partner may, however, make written requests to Xpand Wealth for any 
specific information relating only to the Partner’s own business with Xpand Wealth.</p>

        </div>
        <div className="page" id="page"
        style={{marginTop: "10px"}}
        >
        <div className="pdf-logo">
        <img src={brandLogo} alt="brandLogo" />
          </div>
         <strong> V. Rights and Obligations of Xpand Wealth:</strong>
<p>1. Xpand Wealth reserves the right to accept or reject any applicant for any Product or Service under this 
Agreement.</p>
<p>2. Xpand Wealth shall take due care while handling any confidential information of Clients. However, Xpand 
Wealth shall not be held liable for the disclosure of any confidential information of the Clients.</p>
<p>3. Xpand Wealth retains the sole right and discretion to change, amend or modify the terms of this Agreement 
and/or Terms & Conditions of any of the Products or Services as available with & offered by Xpand Wealth
without prior notice.</p>
<p>4. Au-Fait Fincare Private Limited asserts full ownership and rights over the 'Xpand Wealth Portals' and will 
offer Products, Services, and associated features as deemed appropriate and feasible. These Services and 
features are provided without any expressed guarantees or warranties, and on an "As Is and Where Is" basis. 
Xpand Wealth holds no liability or obligations to provide these Services/features to its Clients and their 
offering is solely at the discretion of Xpand Wealth as a privilege. Xpand Wealth regularly conducts 
maintenance and development activities on all 'Xpand Wealth Portals' and their components or contents. 
During such activities, partial or complete unavailability of the Xpand Wealth Portal services may occur for 
necessary durations.</p>
<p>5. Xpand Wealth retains the right to collect, store, and analyze various forms of data, information, and 
feedback from Partners and Clients to understand their preferences, requirements, trends, and potential 
business opportunities. Such analysis and information are considered the exclusive Intellectual Property of 
Xpand Wealth and will be handled and kept confidential as deemed appropriate by Xpand Wealth.</p>
<p>6. The Partner acknowledges that Xpand Wealth reserves the right to communicate with Clients to provide 
information about Products, Services, Transactions, promotions, subscriptions, markets, etc., and to gather 
feedback and interests regarding any Transactions conducted or Products/Services offered. These 
communications may occur at the sole discretion of Xpand Wealth without prior notice to the Partner.</p>
<p>7. Xpand Wealth reserves the right to recover Commissions and/or other previously paid amounts from future 
payments due to the Partner or request reimbursement from the Partner, as permitted by applicable laws or 
as deemed suitable and necessary by Xpand Wealth for valid reasons. This may include cases of incorrectly 
processed payments, outstanding charges or dues, or any void or invalid business or Transactions, among 
other circumstances.</p>
<p>8. The Partner acknowledges that Xpand Wealth reserves the right to make decisions regarding operational 
and transactional matters in compliance with directives or applicable laws concerning classifications, 
treatment, recognition, etc., when dealing with Clients, investments, Transactions, or any other relevant 
purposes. These decisions will be made at the discretion of Xpand Wealth and as deemed appropriate.</p>
<p>9. Xpand Wealth reserves the right to allocate, change, shift, and transfer the relationship of the Partner to 
any of Xpand Wealth Employee or any Non-Employee playing a similar role.</p>
<p>10. Xpand Wealth also reserves the right to set-off and/or deduct any due amounts by Partner from the 
accrued commissions or brokerages etc., amounts payable to the Partner at the discretion of Xpand Wealth.</p>
<strong>VI. Fees & Commission:</strong>
<p>1. The Partner shall be entitled to receive a certain percentage and/or amount by way of the commissions, 
including incentives, (hereinafter referred to as “Commission”) on the distribution business mobilised by the 
Partner subject to the Commission rates as decided and communicated by Xpand Wealth from time to time, 
refer Annexure 1. Any Commission payable to the Partner shall become due for the period under consideration
subject to the fulfilment of following conditions</p>

        </div>
        <div className="page" id="page"
        style={{marginTop: "10px"}}
        >
        <div className="pdf-logo">
        <img src={brandLogo} alt="brandLogo" />
          </div>
         <p> a) Compliance with applicable laws, regulations & guidelines of industry regulators, SRO or any other 
concerned authority</p>
<p>b) On Adherence of terms of this Agreement</p>
<p>2. The Commissions payable shall be separately communicated from time to time.</p>
<div className="para-column">
<strong>VII. Representations and Warranties:</strong>
<strong style={{margin: "10px 15px"}}>A) Partner’s Representations and Warranties</strong>
</div>
<p>1. The Partner is competent to enter into a legally binding contract and this Agreement under applicable laws 
and that it is not incompetent to contract within the meaning of the Indian Contract Act, 1872 as amended 
from time to time.</p>
<p>2. The Partner is a "fit and proper person" fulfilling the following criteria:</p>
<p>a) financial integrity;</p>
<p>b) absence of convictions or civil liabilities;</p>
<p>c) competence;</p>
<p>d) good reputation and character;</p>
<p>e) efficiency and honesty; and</p>
<p>f) absence of any disqualification to participate in or perform any activity in securities markets</p>
<p>3. The Partner represents that</p>
<p>a) It is duly organized and validly existing under the laws of India and has full authorization to become and act 
as a Partner with Xpand Wealth.</p>
<p>b) There are no circumstances, which would entitle any person to declare the Partner as insolvent or subject to 
winding up or have a receiver appointed over the whole or any part of its assets.</p>
<p>c) This Agreement has been duly and validly executed and delivered and constitutes a legal, valid and binding 
obligation enforceable in accordance with its' terms.</p>
<p>d) All necessary consents and approvals contemplated herein have been or shall be obtained from the 
governmental, regulatory or statutory authorities for the performance of its obligations under the Agreement.</p>
<p>e) Its entry into, exercise of its rights and/or performance of or compliance with this Agreement does not and 
shall not violate any other agreement/terms and conditions.</p>
<p>4. Partner represents that it does not fall within the definition of "Investment Adviser" as per the extant IA
Regulations.</p>
<p>5. The Partner shall not misrepresent its independent relationship with Xpand Wealth in any way and must not 
use Xpand Wealth’s name or any logo or Intellectual Property without prior written permission of Xpand 
Wealth.</p>
<p>6. The Partner shall not permit or encourage the access to desks on Xpand Wealth Portals to unauthorised 
persons. Any information obtained from the Xpand Wealth Portal shall be used or reproduced only for the 
bona-fide and permitted purposes by the Partner.</p>
<strong>B) Xpand Wealth’s Representations and Warranties</strong>
<p>1. Xpand Wealth shall maintain appropriate infrastructure deemed adequate to provide the Products and 
Services through its PSCs and through Xpand Wealth Portal.
</p>
        </div>
        <div className="page" id="page"
        style={{marginTop: "10px"}}
        >
        <div className="pdf-logo">
        <img src={brandLogo} alt="brandLogo" />
          </div>
         <p> 2. Xpand Wealth Portal shall have secured environment with adequate firewalls and other means of access 
control, which in the opinion of Xpand Wealth are adequate and capable of protecting the network against 
unauthorized access from time to time.</p>
<p>3. Xpand Wealth shall nominate any employee(s) /non-employee(s) as a central source of interaction to 
service the Partner in performance of their various activities under this Agreement.</p>
<p>4. Xpand Wealth represents that it is into equity advisory services only and does not provide the execution
services under its business.</p>
<p>5. Xpand Wealth represents that all tools, reports, calculators, etc. including the Need Assessment tool, 
provided in any mode or manner, including on Xpand Wealth Portal are for the purpose of providing incidental 
support services to the primary financial Product distribution business of the Partner.</p>
<strong>VIII. Disclaimer:</strong>
<p>1. Any Product or Service licensed, offered or purchased through Xpand Wealth and/or Xpand Wealth Portal, is 
provided on an "AS IS" basis and without warranties of any kind, either expressed or implied, directly or 
indirectly, including, but not limited to warranties of title or non-infringement or warranties of merchantability 
and fitness for a particular purpose, other than those warranties which are implied by and incapable of
exclusion, restriction or modification under the laws applicable to this Agreement.</p>
<p>2. Xpand Wealth does not represent or warrant that the Xpand Wealth Portals, Services of Xpand Wealth
offices, PSCs and all other related support services will be uninterrupted, complete, accurate, authenticate, 
timely, updated, compatible, reliable, relevant or error-free. Xpand Wealth shall make appropriate efforts to 
ensure Xpand Wealth Portals and other services are free from defects, viruses, trojans, harmful components 
and that any defects will be corrected as deemed appropriate by Xpand Wealth. Xpand Wealth, however, does 
not warrant that all defects will be corrected in specific time or that the Xpand Wealth Portals or the servers 
are free from viruses, trojans or other harmful components.</p>
<p>3. The Partner agrees that Xpand Wealth or Xpand Wealth’s directors, officers, employees, agents, sponsors, 
consultants, associates, service providers, business partners or other representatives shall not be responsible 
or liable for any direct, indirect, incidental, consequential, special, exemplary, punitive or any other damages
(including without limitation – loss of profits, loss or corruption of data, loss of goodwill, work stoppage,
computer failure or malfunction, transaction failure, transaction delay, interruption of business, etc) under any 
contract, negligence, strict liability or other theory arising out of or relating in any way with the use of the 
PSCs, Xpand Wealth Portal or Products and Services or in reliance of the information available on Xpand 
Wealth Portal & related services.</p>
<p>4. Xpand Wealth cannot be held liable for any Transaction or application related delay, rejection, mistake, loss 
of document, etc. caused for any reason whatsoever, submitted by the Partner of their Clients, directly or
indirectly, to Xpand Wealth in any mode or manner.</p>
<p>5. The Partner acknowledges that the availability of Xpand Wealth Portal, Products, Services and/or other 
online facilities are subject to interruption for reasons beyond Xpand Wealth’s control and such interruptions 
may happen without any prior notice to the Partner at any point of time.</p>
<p>6. If the foregoing limitations are held to be unenforceable, the maximum liability of Xpand Wealth and its 
service providers to the Partner shall not exceed the amount of fees paid by Partner for availing access to
particular Products or Services through Xpand Wealth in any mode or manner.</p>
<p>7. The Partner should make written suggestions to Xpand Wealth for rectification of any defects or 
shortcomings noticed in Xpand Wealth Portals and other Product or Services offered or made available him or 
his Client.</p>
        </div>
        <div className="page" id="page"
        style={{marginTop: "10px"}}
        >
        <div className="pdf-logo">
        <img src={brandLogo} alt="brandLogo" />
          </div>
          <p>8. Xpand Wealth represents that it has taken due care and caution in providing information on Xpand Wealth
Portal and such information or references may be taken from external reliable sources as deemed appropriate 
by Xpand Wealth. However, Xpand Wealth does not make any guarantees or warranties whatsoever, 
expressed or implied, regarding the accuracy, adequacy, timeliness, completeness, reliability, functionality, 
fullness of any information, data, analysis, reports, etc., in any mode or manner. Xpand Wealth shall not be 
responsible for any errors or omissions or for the decisions and consequences thereof based on any 
information, data, analysis, reports, etc., made available by Xpand Wealth, in any mode or manner, to Partner 
or any other entity.</p>
<p>9. Any information, data, market analysis, research reports, etc. made available by Xpand Wealth, in any 
mode, manner or format, shall not be construed as a representation on the legality, feasibility, fitness or 
validity of any security, Product or Service under applicable laws.</p>
<p>10. Xpand Wealth may place on its Portal contents like advertisements, banners, reports, articles, audio /video 
files, literatures, including those of Xpand Wealth and third party Products and Services, including links to third 
party websites. However, Xpand Wealth does not validate or qualify or endorse such contents and is not 
responsible for any such content or information, claims, statements and for any decision taken thereof, for any 
reason whatsoever.</p>
<p>11. The Partner understands and agrees that no joint venture, partnership, employment or agency
relationship exists between the Partner on one hand and Xpand Wealth on the other as a result of this 
Agreement.</p>
<p>12. Xpand Wealth offers Products and Services through PSCs and Xpand Wealth Portal, which are linked to the 
back-end infrastructure of Xpand Wealth. The Partner acknowledges and agrees that the PSC and/or Xpand 
Wealth Portal and the linked back-end infrastructure may be inaccessible and/or unavailable at times. In such 
an event Xpand Wealth will not be liable for any damage, liability, action, cause of action, suit, claim, demand, 
loss, cost, and expense arising directly or indirectly from or in connection with any such inaccessibility and/or 
unavailability.</p>
<p>13. Execution of and performance under this Agreement by Xpand Wealth does not and shall not in any 
manner be treated as an advice under the IA Regulations.</p>
<strong>IX. Intellectual Property:</strong>
<p>1. "Intellectual Property"/ “IP” includes ideas, concepts, creations, discoveries, inventions, improvements, 
know how, trade or business secrets; trademarks, service marks, copyrights, designs, utility models, tools, 
devices, models, methods, procedures, processes, systems, principles, algorithms, works of authorship, 
flowcharts, drawings, books, spread sheets, papers, models, sketches, formulas, information, teaching 
techniques, electronic codes, proprietary techniques, research and development projects and data, and other 
confidential and proprietary information, computer programming code, databases, data, documents, reports, 
diagrams, instruction manuals, records, memoranda, notes, user guides; in either printed or machine readable form, the whether or not copyrightable or patentable or protectable under any other intellectual 
property law, or any written or verbal instructions or comments.</p>
<p>2. Au-Fait Fincare Private Limited solely owns all 'Intellectual Property' existing, created or held in the 
Products, Services, Xpand Wealth Portal, in any mode or manner.</p>
<p>3. The Partner shall have no right to use the Intellectual Property in any manner whatsoever except to the 
extent expressly permitted by Xpand Wealth under this Agreement.</p>
<strong>X. Confidentiality:</strong>
<p>1. The Parties agree to treat all information as confidential and privileged in the nature of intellectual property, 
supplied or received by it and shall not divulge or disclose the same to any third party.</p>
        </div>
        <div className="page" id="page"
        style={{marginTop: "10px"}}
        >
        <div className="pdf-logo">
        <img src={brandLogo} alt="brandLogo" />
          </div>
         <p> 2. Such obligation shall not apply to information that has entered into the public domain other than as a result 
of a breach by one of the Parties of such obligation, or is required to be disclosed further to a court order or as 
well as any information, which has been expressly excluded from the confidentiality requirement in writing. 
The Parties further agree not to use any such information to the detriment of the other Party in any way 
whatsoever, at any time.</p>
<p>3. The Partner agrees that it will use all confidential information received by it from Clients and Xpand Wealth
only in connection with the purpose of fulfilling its obligations under this Agreement and for no other purpose
whatsoever. The Partner agrees not to make copies of any such confidential information or any part thereof 
except for the performance of the obligations under this Agreement.</p>
<p>4. The Partner is responsible for maintaining and protecting the confidentiality of any login-id and password
issued by Xpand Wealth for access to Xpand Wealth Portal, including Partner Desk. The Partner agrees to 
accept responsibility for all activities that occur under his log in-id and password. Xpand Wealth shall not be 
held liable for any actions, claims, damages, losses, suits, proceedings, demands or expenses, costs, charges in 
respect thereof or otherwise on account of the use of Portal, or other such online facilities by person/s other 
than those authorized and/or nominated by the Partner.</p>
<strong>XI. Term, Suspension & Termination:</strong>
<p>1. The appointment of the Partner shall be effective from the date of appointment as mentioned in the
appointment letter as communicated by Xpand Wealth and shall remain effective unless terminated by either 
Party.</p>
<p>2. Each Party hereto is free to terminate this Agreement by serving one month advance notice to the other
Party without assigning any reason.</p>
<p>3. Without limiting other remedies, Xpand Wealth may issue a warning, temporarily or indefinitely suspend or 
terminate this Agreement and/or refuse to provide or temporarily suspend, or stop access to any Product 
and/or Service, including access to ' Xpand Wealth Portals', in part or in full, to Partner if:</p>
<p>a) The Partner commits breach of any terms of this Agreement or the linked policies and information
incorporated herein by reference, including the written policies and procedures posted on the Xpand Wealth
Portal; or</p>
<p>b) Xpand Wealth is unable to verify or authenticate any information provided by Partner in any manner or
fashion; or</p>
<p>c) Non-payment of any charges, fees or any dues by the Partner; or</p>
<p>d) Xpand Wealth has a reason to believe that Partner’s actions may cause legal liability for and/or harm the
business interests of Xpand Wealth or any of its' service providers, other Partners or associates.</p>
<p>4. Xpand Wealth reserves the right to terminate this Agreement if the Partner commits a breach of any of its'
obligations here-in-under and does not remedy such breach within 30 (thirty) days of receipt of written notice 
from Xpand Wealth requiring such remedy;</p>
<p>a) Unauthorized/unfair usage of the Intellectual Property, Xpand Wealth brand name, logo, Portals, etc for any
purpose.</p>
<p>b) Partner is believed to be providing misleading information or not acting in the interest of the Client or 
entering into any unethical trade practice.</p>

        </div>
        <div className="page" id="page"
        style={{marginTop: "10px"}}
        >
        <div className="pdf-logo">
        <img src={brandLogo} alt="brandLogo" />
          </div>
        <p>  5. Xpand Wealth reserves the right to immediately suspend or terminate this Agreement, with or without 
advance notice, upon the occurrence of any of the following events:</p>
<p>a) The Partner engages in the use of abusive language or mental harassment towards any Xpand Wealth 
official through any mode, manner, or format, for reasons beyond the control of Xpand Wealth or based on 
unrealistic non-committed expectations.</p>
<p>b) The Partner attempts to defame Xpand Wealth by providing false or irrelevant information to regulatory 
bodies or any other party.</p>
<p>c) The Partner collects cash from Clients and issues cheques or demand drafts from their own account or a 
third-party account, or utilizes such funds for non-bonafide purposes.</p>
<p>d) The Partner becomes insolvent, undergoes liquidation or receivership, or has an administrative receiver 
appointed over their assets. Additionally, if any judgment is executed against their property or assets, or if any 
other business-related circumstances arise that could adversely affect the performance of obligations under 
this Agreement.</p>
<p>e) If the Partner provides untrue, inaccurate, outdated, or incomplete information as required by this 
Agreement, or if they conceal any information that is material to this Agreement.</p>
<p>6. In the event of Agreement termination, all other Services, regardless of any remaining tenure or 
subscription period, will also be terminated. The Partner shall not be entitled to any refund or compensation 
due to the termination mentioned above.</p>
<p>7. Xpand Wealth cannot be held liable for any consequences arising from the suspension or termination of the 
Partner. Xpand Wealth is not responsible for refunding fees, taking actions, addressing claims, compensating 
for damages or losses, dealing with suits or proceedings, fulfilling demands or expenses, covering costs and 
investments in infrastructure, systems, employees, etc., or any charges related to such suspension or 
termination.</p>
<p>8. Upon the death of an individual Partner, the Partner status shall be automatically terminated as of the date 
of death. The nominated person or legal heir will be entitled to receive any pending dues owed to the 
deceased Partner.</p>
<strong>XII. Indemnification:</strong>
<p>1. The Partner hereby agrees, undertakes, and covenants with Xpand Wealth that it will defend and 
indemnify Xpand Wealth, its directors, affiliates, officers, employees, successors, assigns, heirs, 
executors, administrators, and their respective estates and effects, against any and all damages, 
liabilities, actions, causes of action, suits, claims, demands, losses, costs, and expenses, directly or 
indirectly arising from or in connection with the Partner's actions or omissions under this 
Agreement.</p>
<p>2. Xpand Wealth shall not be held liable for any delays in the execution, rejection, receipt, or delivery 
of any Transaction or information, including but not limited to log-in information, account 
statements, Transaction confirmations, electronically or otherwise, made through Xpand Wealth or 
its entities for any reason whatsoever.</p>
<p>3. Xpand Wealth shall not be responsible or liable for any damages, liabilities, actions, causes of 
action, suits, claims, demands, losses, costs, and expenses, whether direct, indirect, incidental, or 
consequential, resulting from delays, non-executions, rejections, non-communication, 
miscommunication of information, or non-payment of proceeds related to any Transaction or 
request for any Product and Service made through Xpand Wealth for any reason whatsoever.</p>

        </div>
        <div className="page" id="page"
        style={{marginTop: "10px"}}
        >
        <div className="pdf-logo">
        <img src={brandLogo} alt="brandLogo" />
          </div>
         <p> 4. In the event that the aforementioned limitation is deemed unenforceable, the maximum liability 
of Xpand Wealth and its service providers to the Partner shall be limited to the amount of fees 
actually paid by the Partner for the ordered or availed Products or Services through the Xpand
Wealth Portal.</p>
<strong>XIII. Dispute Resolution:</strong>
<p>1. In the event of any claim, controversy, difference, or dispute arising between the Partner and Xpand Wealth, 
both parties shall make reasonable efforts to resolve the matter through mutual negotiations within a
reasonable period of time, as agreed upon.</p>
<p>2. If the dispute remains unresolved through negotiation within the specified reasonable period, the matter 
shall be referred to arbitration in Delhi, in accordance with the Indian Arbitration and Conciliation Act, 1996, 
currently in force. The arbitration proceedings shall be conducted by a sole arbitrator appointed by Xpand 
Wealth, in accordance with the rules incorporated by reference in this clause.</p>
<p>3. The arbitration proceedings shall be conducted in the English language. The Parties involved undertake to 
maintain the confidentiality of the arbitration proceedings, as well as any information, pleadings, documents, 
evidence, and all related matters.</p>
<p>Notwithstanding the above, either Xpand Wealth or the Partner reserves the right to approach the appropriate 
court of jurisdiction to seek interim or equitable relief, if deemed necessary.
XIV. Miscellaneous:</p>
<p>1. The Partner is not entitled to subcontract or transfer any of its rights and obligations under this Agreement
without prior written consent of Xpand Wealth. Xpand Wealth may assign all or part of its obligations under 
this Agreement.</p>
<p>2. If any provision of this Agreement is held to be invalid, illegal, or unenforceable then such provision shall be 
enforced to the maximum extent permissible so as to affect the intent of this Agreement, all other provisions 
will nevertheless continue in full force and effect.</p>
<p>3. For any further clarifications and details that the Partner may require the Partner is required to contact the 
person handling the relationship of such a Partner.</p>
<p>4. The relationship between Xpand Wealth and the Partner shall be that of independent contractors. No party 
shall be an agent of the other, and no employees or agents of any party shall be considered the employees or 
agents of the other party. Each party shall be responsible for its employees' and agents’ compliance with all 
applicable laws.</p>
<p>5. The Agreement constitutes the complete agreement between the parties and supersedes all prior or
contemporaneous agreements or representations, written or oral, concerning the subject matter of this
Agreement.</p>
<strong>XV. Governing Law:</strong>
<p>All Transactions done by the Clients through Xpand Wealth Portals shall be governed by and construed in 
accordance with the laws of India, without regard to the rules regarding conflicts of law. Subject to arbitration, 
any litigation regarding this Agreement or any Transaction or any action at law or in equity arising out of or 
relating to this Agreement shall be filed only in the competent Courts of Delhi alone and the Partner hereby 
agrees, consents and submits to the jurisdiction of such courts for the purpose of litigating any such action.</p>

        </div>
        <div className="page" id="page"
        style={{marginTop: "10px"}}
        >
        <div className="pdf-logo">
        <img src={brandLogo} alt="brandLogo" />
          </div>
         <strong> XVI. Non-Solicitation:</strong>
<p>During the term of this Agreement and for a period of 12 months from the date of Termination of this 
Agreement, unless otherwise agreed to by Xpand Wealth, Partner shall not directly or indirectly employ or 
attempt to employ or assist anyone else to employ any person who is in the employment of Xpand Wealth or 
any of its affiliates at the time of the alleged prohibited conduct, or was in the employment of Xpand Wealth
or its affiliates at any time during the preceding twelve months.</p>
<div className="d-flex">
  <div className="img-box">
  <img src={signatureUrl} alt="Signature" />
  <strong style={{height: "40px"}}>Signature by Partner </strong>
  </div>
  <div className="img-box">
  <img src={xpandSignature} alt="" />
  <strong style={{height: "40px"}}>Signature by Xpand Wealth Signatory</strong>
  </div>
</div>
        </div>
      </div>
    </>
  );
};

export default Agreement;
