import axios from "axios";
import { Api_Base_Url } from "./matcher";

export const unslugify = str => {
  return str.split('_').join(' ')
//   .replace(/\w\S*/g, function (txt) {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
//   })
}

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const getCurrentDate = () => {
  const currentDate = new Date();
  return currentDate.toISOString().split("T")[0];
};

export const getCheckinDetails = () => {
  const time = getCurrentDate()
  const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
  const id = loginUserData?._id
  axios.get(`${Api_Base_Url}/rm/attendancedetail?rmid=${id}&currentdate=${time}`).then((res) => {
    //  console.log(res?.data);
     localStorage.setItem("checkinStatus", res?.data?.data?.is_login);
     localStorage.setItem("checkinTime", res?.data?.data?.checkIN);
     localStorage.setItem("checkoutDate", res?.data?.data?.checkInOutDate);
    //  console.log(res?.data?.data?.is_login);
    //  console.log(res?.data?.data?.checkIN);
  })
 }

 


