import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Api_Base_Url } from '../../constant/matcher'

const leadManagementService = createApi({  
    reducerPath: 'leadManagement',
    baseQuery: fetchBaseQuery({
        baseUrl: Api_Base_Url,
    }),
    endpoints: (builder) => {
        return {
            // select input data for leadStageList
            leadStageList: builder.query({
                query: () => {
                    return {
                        url: 'lead/stage-list',
                        method: 'GET',
                    }
                }
            }),
            // input data for lead status
            leadStatus: builder.query({
                query: () => {
                    return {
                        url: 'lead/status-of-lead',
                        method: 'GET',
                    }
                }
            }),
        }
    }
})

export const {
    useLeadStageListQuery,
    useLeadStatusQuery,
} = leadManagementService;
export default leadManagementService