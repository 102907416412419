// --localhost file path--
// export const PUBLIC_PATH="http://localhost:3000/";
// export const PUBLIC_PATH="https://xpand-u.com/";
export const PUBLIC_PATH = "https://xpandwealth.com/";
// export const PUBLIC_PATH="https://infotiqq.com/xpandone/";

//  ----server files path---
// dir-->output-file-path->for qr
// export const Server_Path_static = "https://xpand-u.com/admin/";
export const Server_Path_static = "https://xpandwealth.com/admin/";

// ---API Base URL---
export const Api_Base_Url = 'https://xpandwealth.com/admin/api/v1';
// export const Api_Base_Url = "https://xpand-u.com/admin/api/v1";

// export const qrpath = "https://xpand-u.com/admin/output-file-path/";
// export const urlpath = "https://xpand-u.com/form-by-scan";
export const qrpath = "https://xpandwealth.com/admin/output-file-path/";
export const urlpath = "https://xpandwealth.com/form-by-scan";
// export const pdfpath = "https://xpand-u.com/admin/public/agreement";
export const pdfpath = "https://xpandwealth.com/admin/public/agreement/";

// ===rm login
// 8130388996
// Madhu@123

// rm login
// 8287555307
// Sanket@123

// rm login
// 8765432109
// 123456
