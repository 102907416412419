import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom'

function PrivateRouteEmployee({children}) {
    const userToken = useSelector(state => state.userReducer.userTokenFmRm)
    // const userData = useSelector(state => state.userReducer.userData)
    // console.log(userData?.slug.toLowerCase());

  return userToken && userToken !== 'undefined' ? children : <Navigate to={`/login/team`} />
}

export default PrivateRouteEmployee