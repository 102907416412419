import { saveAs } from "file-saver";

// Redirect function for handling URLs with multiple slashes
export const handleMultipleSlashRedirect = async () => {
   if (window.location.pathname.match(/\/{2,}/)) {
      const newPath = window.location.pathname.replace(/\/{2,}/g, "/");
      window.location.replace(newPath);
   }
};
// concatenate two string with parenthesis
export const concatTwoStrWithParenthesis = (str1, str2) => {
   // here concatenating when str1 is not equal to website,null,undefined and empty string
   if (
      str1.toLowerCase() !== "website" &&
      str1 !== null &&
      str1 !== undefined &&
      str1 !== "" &&
      str1 !== " "
   ) {
      return str1 + "(" + str2 + ")";
   }
   return str1;
};

// Date format to show date in dd/mm/yyyy
export const showformatDate = (dateString) => {
   if (!dateString) {
      return "";
   }

   const [year, month, day] = dateString.split("-");
   return `${day}-${month}-${year}`;
};

export const formatDate = (isoDate) => {
   const date = new Date(isoDate);

   const day = date.getDate();
   const month = date.getMonth() + 1;
   const year = date.getFullYear();

   const hours = date.getHours();
   const minutes = date.getMinutes();

   const formattedDate = `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
   }${month}-${year} ${hours}:${minutes < 10 ? "0" : ""}${minutes} `;

   return formattedDate;
};

export const dateFormat = (dateStr) => {
   const dateObj = new Date(dateStr);
   const day = dateObj.getUTCDate();
   const month = dateObj.getUTCMonth() + 1; // Month is 0-based, so add 1
   const year = dateObj.getUTCFullYear();
   return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
 }

 export const formatAmount = (amount) => {
   if (amount >= 10000000) { 
     return (amount / 10000000)?.toLocaleString('en-IN', { maximumFractionDigits: 2 }) + " Cr";
   } else if (amount >= 100000) { 
     return (amount / 100000)?.toLocaleString('en-IN', { maximumFractionDigits: 2 }) + " L";
   } else { 
     return amount?.toLocaleString('en-IN', { maximumFractionDigits: 2 })
   }
 }

export function formatNumberWithCommas(number) {
   return new Intl.NumberFormat('en-IN').format(number);
 }
 