import React, { useState, useEffect } from 'react'
import { useProductSelectQuery } from '../../../store/services/loginService.js'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { useSelector } from 'react-redux'

function Product ({ formData, setFormData }) {
  // ==========QR EVENT DETAILS===============
  // const qrEventData = useSelector(state => state?.userReducer?.qrDetails)
  // const qrProduct = qrEventData?.product

  // api for select options
  const [selectedProduct, setSelectedProduct] = useState()
  // api for select options
  // for multiselect
  const multiResp = useProductSelectQuery()
  var multiSelectData =
    multiResp?.isSuccess &&
    !multiResp?.isLoading &&
    multiResp.status === 'fulfilled' &&
    !multiResp?.isError &&
    multiResp?.data?.productsList

  const searchOptions =
    multiSelectData &&
    multiSelectData?.map(({ name, _id }) => ({
      label: name,
      value: _id
    }))

  // let defaultVal = []
  // if (qrProduct?.length > 0) {
  //   for (let i = 0; i < qrProduct?.length; i++) {
  //     let obj =
  //       searchOptions && searchOptions?.find(el => el.value === qrProduct[i])
  //     if (obj !== false) defaultVal?.push(obj)
  //   }
  // }

  useEffect(() => {
    // if (qrEventData !== null && qrProduct.length > 0) {
    //   let defPro = []
    //   if (defaultVal?.length > 0) {
    //     for (let i = 0; i < defaultVal.length; i++) {
    //       defPro.push(defaultVal[i].value)
    //     }
    //   }
    //   setFormData({ ...formData, product: defPro })
    // } 

      let productData = []
      var products = []
      if (selectedProduct?.length > 0) {
        for (let i = 0; i < selectedProduct.length; i++) {
          products.push(selectedProduct[i].value)
        }
        productData = products
      }
      setFormData({ ...formData, product: productData })
    
  }, [selectedProduct, multiSelectData])

  return (
    <>
      <div className='row my-3'>
        {/* <label>Select Products</label> */}
        <div className='react-multiselect form-group'>
          <Select
            closeMenuOnSelect={false}
            isMulti
            className='basic-single w-100'
            classNamePrefix='select'
            // value={defaultVal.length > 0 ? defaultVal : selectedProduct}
            value={selectedProduct}
            onChange={setSelectedProduct}
            isClearable={true}
            placeholder='Select Products*'
            isSearchable={true}
            name='product'
            options={searchOptions ? searchOptions : []}
          
          />
        </div>
      </div>
    </>
  )
}

export default Product
