import React from 'react'
import { Link } from 'react-router-dom'
import '../../styles/hoverCards.css'
import { saveAs } from 'file-saver';

function ImageCards ({ bgImg, cardCaption, downloadLink, imageName }) {
  const dowloadFileAtUrl = (url) => {
    saveAs(url, 'file')
    // var blob = new Blob([url], {type: "all"});
    // saveAs(blob, "fileasf.jpeg");
    
  } 
//   function forceDownload(url, fileName) {
//     var xhr = new XMLHttpRequest();
//     xhr.open("GET", url, true);
//     xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
//     xhr.responseType = "blob";
//     xhr.onload = function () {
//         var urlCreator = window.URL || window.webkitURL;
//         var imageUrl = urlCreator.createObjectURL(this.response);
//         var tag = document.createElement('a');
//         tag.href = imageUrl;
//         tag.download = fileName;
//         document.body.appendChild(tag);
//         tag.click();
//         document.body.removeChild(tag);
//     }
//     xhr.send();
// } 
  return (
    <>
      <div className='imageCardWrapper mb-5'>
        <article className='card card--1'>
          <div className='card__info-hover'>
            <div className='card__clock-info'>
              <div className='card__like me-5'>
                <Link to={downloadLink} target="_blank">
                  <img
                    src={
                      'https://cdn-icons-png.flaticon.com/128/1047/1047251.png'
                    }
                    alt='icons'
                  />
                </Link>
              </div>
              <div className='' style={{cursor:'pointer'}}>
                <div onClick={() => dowloadFileAtUrl(downloadLink)}>
                  <img
                    src={
                      'https://cdn-icons-png.flaticon.com/128/4436/4436531.png'
                    }
                    alt='icons'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='imagWrapper position-relative'>
            <div className='bgImgOverlay'></div>
            <div className='card__img' style={{ backgroundImage: `url(${bgImg})` }}></div>
            <div to className='card_link'>
              <div
                className='card__img--hover'
                style={{ backgroundImage: `url(${bgImg})` }}
              ></div>
            </div>
          </div>
          <div className='card__info'>
            {/* <span className='card__category'> Recipe</span> */}
            <h3 className='card__title text-capitalize'>{cardCaption}</h3>
            {/* <span className='card__by'>
              by{' '}
              <Link to="" className='card__author' title='author'>
                Celeste Mills
              </Link>
            </span> */}
          </div>
        </article>
      </div>
    </>
  )
}

export default ImageCards
