import { useContext, useEffect } from "react";
import Routing from "./routes/Routing";
import { ProfileCompletionContext } from "./components/ProfileCompletionContext";
import { handleMultipleSlashRedirect } from "./utils/helper";

function App() {
   const { fetchProfileStatus } = useContext(ProfileCompletionContext);

   useEffect(() => {
      fetchProfileStatus();
      handleMultipleSlashRedirect();
   }, []);

   return (
      <>
         <Routing />
      </>
   );
}

export default App;
