import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InvestorSidebar from '../../components/investor/InvestorSidebar'
import Footer from "../../components/Footer";
// import DashboardBanner from '../../components/general/DashboardBanner'
import { useSelector } from "react-redux";
import InvProfile from "./InvProfile";
import { PUBLIC_PATH } from "../../constant/matcher";

const Investorprofilepage = () => {
// loggedIn user Data form react-redux
const loggedInUserData = useSelector(
    (state) => state?.userReducer?.userData
 );
 const [dynamicRoute, setdynamicRoute] = useState("");
 useEffect(() => {
    loggedInUserData?.role === "rm"
       ? setdynamicRoute("team")
       : setdynamicRoute("dashboard");
 }, [loggedInUserData]);

 const [SmallWrapper, setSmallWrapper] = useState("small-wrapper");
 const [navShow, setNavShow] = useState(true);
 // navigation content wrapper handler
 const navContWrapp = (boolData) => {
    setNavShow(boolData);
 };
  return (
    <>
    <InvestorSidebar navContWrapp={navContWrapp} />
    {/* content wrapper section */}
    <section
       className={`dash-content-wrapper  ${SmallWrapper}`}
       style={{
          paddingLeft: navShow ? "230px" : "70px",
          background: "whitesmoke",
       }}
    >
         {/* banner sec */}
         <div id='banner'>
          <div className='container-fluid p-0'>
            <div className='banner-img p-0'>
              <img
                src={PUBLIC_PATH + 'assets/img/dash-banner.jpg'}
                alt=''
                className='img-fluid'
              />
            </div>
          </div>
        </div>

       <div className="breadcrumbs">
          <div className="container">
             <p>
                <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                   Dashboard
                </Link>{" "}
                {/* <i className='fa-solid fa-angle-right'></i> Home{' '} */}
                <i className="fa-solid fa-angle-right"></i> Profile
             </p>
          </div>
       </div>

       <div className="container content-wrapper details-page px-4 py-2">
          {/* <Profile
       imgSrc={PUBLIC_PATH + 'assets/img/team/team-2.jpg'}
       profileTag={'FiNC Fit'}
       username={'Mikichan'}
       userProfession={'Finance Manager'}
       fbLink={''}
       instaLink={''}
       twitterLink={''}
       editLink={''}
     /> */}

          <InvProfile/>
       </div>

       {/* <!-- ======= Footer ======= --> */}
       <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
       />
    </section>
 </>
  )
}

export default Investorprofilepage