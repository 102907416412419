import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Api_Base_Url, PUBLIC_PATH } from "../../constant/matcher";
import InvestorSidebar from "../../components/investor/InvestorSidebar";
import CryptoJS from "crypto-js";
import "../../styles/card.css";
import Footer from "../../components/Footer";
import LandingCards from "../../components/franchise/LandingCards";
import HoverCards from "../../components/cardsDesigns/HoverCards";
import { useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  formatAmount,
  formatDate,
  formatNumberWithCommas,
} from "../../utils/helper";
import DashboardBanner from "../../components/general/DashboardBanner";

function InvestorDashboard() {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [link, setLink] = useState("");
  const [navShow, setNavShow] = useState(true);
  const [registerNowPopup, setRegisterNowPopup] = useState(false);
  const [successStatus, setSuccessStatus] = useState("fade");
  const [sipAmounts, setSipAmounts] = useState("");
  const [eqAmounts, setEqAmounts] = useState("");
  const [amount, setAmount] = useState("");
  const [input, setInput] = useState("");
  const [sipValues, setSipValues] = useState([]);
  const [eqValues, setEqValues] = useState([]);
  const [equityValues, setEquityValues] = useState([]);
  const [eqinput, setEqInput] = useState("");
  const [update, setUpdate] = useState("");
  const [data, setData] = useState({
    amount: "",
    remark: "",
  });
  const [equitySum, setEquitySum] = useState(0);
  const [mfSum, setMFSum] = useState(0);
  const [realSum, setRealSum] = useState(0);
  const [totalEUM, setTotalEUM] = useState("");
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };
  const [quickInvest, setQuickInvest] = useState({
    username: loggedInUserData?.eliteusername,
    password: loggedInUserData?.elitepassword,
    loginType: loggedInUserData?.eliterole,
    loginFor: loggedInUserData?.eliterole,
    callbackUrl: window.location.href,
  });
  const [qickPass, setQickPass] = useState("");

  var CryptoJSAesJson = {
    stringify: function (cipherParams) {
      var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j);
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(j.ct),
      });
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
      return cipherParams;
    },
  };

  useEffect(() => {
    var key = "W@ealth!@$#$(%#H(^%)";
    // Encrypt
    var EncryptedPass = CryptoJS.AES.encrypt(
      JSON.stringify(quickInvest.password),
      key,
      { format: CryptoJSAesJson }
    ).toString();

    // console.log(EncryptedPass)

    setQickPass(EncryptedPass);

    setQuickInvest({ ...quickInvest, [quickInvest.password]: EncryptedPass });
  }, []);

  const closeSuccessModal = () => {
    setSuccessStatus("fade");
    setRegisterNowPopup(false);
  };

  const showMF = async () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    await axios
      .get(`${Api_Base_Url}/investor/my-profile?id=${id}`)
      .then((res) => {
        const data = res?.data?.data;
        if (
          data?.personalinfo?.eliteusername !== "" ||
          data?.personalinfo?.elitepassword !== "" ||
          data?.personalinfo?.eliterole !== ""
        ) {
          setLink("https://wealthelite.in/Login/login/validate-user");
        } else {
          toast.warn(
            "You don't have valid credential to login. Please contact your RM"
          );
        }
      });
  };

  const handleSupportChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSupport = (e) => {
    e.preventDefault();
    const loggedInUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const userId = loggedInUserData?._id;
    const rmId = loggedInUserData?.assignTo;
    const reqBody = {
      amount: data.amount,
      remarks: data.remark,
      invertorId: userId,
      rmid: rmId,
    };
    const response = axios.post(
      `${Api_Base_Url}/investor/equity-topup`,
      reqBody,
      {
        header: {
          ContentType: "application/json",
        },
      }
    );
    if (response) {
      setSuccessStatus("d-flex");
      setData({
        amount: "",
        remark: "",
      });
    } else {
      console.log("Error");
    }
  };

  const getSIP = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData.clintcode;
    axios
      .get(`${Api_Base_Url}/investor/investor-dashboard/totalbusiness?id=${id}`)
      .then((res) => {
        setEquitySum(res?.data?.data[0]?.equity[0]?.totalvalue || 0);
        setMFSum(res?.data?.data[0]?.mutualfund[0]?.totalvalue || 0);
        setRealSum(res?.data?.data[0]?.realestate[0]?.totalvalue || 0);
        setTotalEUM(
          (res?.data?.data[0]?.sip[0]?.sum || 0) +
            (res?.data?.data[0]?.equity[0]?.totalvalue || 0) +
            (res?.data?.data[0]?.mutualfund[0]?.totalvalue || 0) +
            (res?.data?.data[0]?.realestate[0]?.totalvalue || 0)
        );
        setUpdate(res?.data?.data[0]?.lastupdate[0]?.createdAt);
      });
  };

  const getSipAmount = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const code = loginUserData.clintcode;
    axios
      .get(`${Api_Base_Url}/investor/inv-forcaste?clientcode=${code}`)
      .then((res) => {
        const sip = res?.data?.data?.sipData[0]?.sum;
        const eq = res?.data?.data?.EQData[0]?.totalvalue;
        setSipAmounts(sip);
        setEqAmounts(eq);
      });
  };

  const yearsArray = [5, 10, 15, 20, 25];
  const percentagesArray = [0.18, 0.2, 0.22];
  const percentagesArraySIP = [0.14, 0.16, 0.18];

  const calculateSIPValue = (percentage, years) => {
    const SIP = sipAmounts ? sipAmounts : 0;
    const sipValue =
      mfSum * (1 + percentage) ** years +
      (SIP * (((1 + percentage / 12) ^ (years * 12)) - 1)) / (percentage / 12);
    return sipValue;
  };

  const calculateUserSIPValue = (percentage, years) => {
    if (amount) {
      const sipValue =
        (amount * (((1 + percentage / 12) ^ (years * 12)) - 1)) /
        (percentage / 12);
      return sipValue;
    }

    return "";
  };

  const calculateSIP = () => {
    const sipValuesArray = yearsArray.map((years) => {
      return percentagesArraySIP.map((percentage) => {
        const sipuserVal = calculateUserSIPValue(percentage, years);
        const sipVal = calculateSIPValue(percentage, years);
        const totalSipVal = sipuserVal + sipVal;
        return formatAmount(totalSipVal);
      });
    });

    setSipValues(sipValuesArray);
  };

  const calculateEqValue = (percentage, years) => {
    const SIP = eqAmounts ? eqAmounts : 0;
    const eqValue = SIP * (1 + percentage) ** years;
    return eqValue;
  };

  const calculateUserEQValue = (percentage, years) => {
    const SIP = eqAmounts + Number(input);
    if (input) {
      const eqValue = SIP * (1 + percentage) ** years;
      return eqValue;
    }
  };

  const calculateEQ = () => {
    const sipValuesArray = yearsArray.map((years) => {
      return percentagesArray.map((percentage) => {
        return calculateUserEQValue(percentage, years);
      });
    });
    setEqValues(sipValuesArray);
  };

  const calculateSEquityValue = (percentage, years) => {
    if (eqinput) {
      const sipValue =
        eqinput * (1 + percentage / 12) ** (years * 12) - 1 / (percentage / 12);
      return sipValue;
    }
  };

  const calculateEquity = () => {
    const sipValuesArray = yearsArray.map((years) => {
      return percentagesArray.map((percentage) => {
        const sipValue = calculateEqValue(percentage, years);
        const equityValue = calculateSEquityValue(percentage, years);
        const totalValue = sipValue + equityValue;
        return formatAmount(totalValue);
      });
    });
    setEquityValues(sipValuesArray);
  };

  useEffect(() => {
    getSIP();
    getSipAmount();
  }, []);

  return (
    <>
      <InvestorSidebar navContWrapp={navContWrapp} />
      <ToastContainer />
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        <DashboardBanner />
        <div className="breadcrumbs">
          <div className="container-fluid" style={{ padding: "0 10px" }}>
            <p>
              <Link to={`/dashboard/${loggedInUserData?.role}`}>
                Dashboard{" "}
              </Link>{" "}
              {/* <i className='fa-solid fa-angle-right'></i> Franchise */}
            </p>
          </div>
        </div>

        <div className="container-fluid mb-4" style={{ padding: "0 10px" }}>
          <div className="row">
            <div className="col-12 p-0">
              <div className="franchise_tab">
                <div className="tab_title_btn">
                  {/* <div className='container'> */}
                  <div
                    className="position-absolute"
                    style={{
                      zIndex: "999",
                      left: "50%",
                      right: "20%",
                      opacity: "0",
                      visiblity: "hidden",
                    }}
                  ></div>
                  {/* </div> */}
                  {/* <div className="btn me-3 reg-btn">Notification</div> */}
                  {/* <div className='btn me-3 reg-btn'>
                    <form
                      method='post'
                      className='quickLogForm'
                      action='https://wealthelite.in/Login/login/validate-user'
                    >
                      <input
                        type='text'
                        placeholder='Name *'
                        name='username'
                        readOnly
                        value={quickInvest.username}
                      />
                      <input
                        type='text'
                        placeholder='Password *'
                        name='password'
                        readOnly
                        value={qickPass}
                      />
                      <input
                        type='text'
                        placeholder='Login Type *'
                        name='loginType'
                        readOnly
                        value={quickInvest.loginType}
                      />
                      <input
                        type='text'
                        placeholder='Login For *'
                        name='loginFor'
                        readOnly
                        value={quickInvest.loginFor}
                      />
                      <input
                        type='text'
                        placeholder='Callback *'
                        name='callbackUrl'
                        readOnly
                        value={window.location.href}
                      />
                      {
                        
                      }
                      <input
                        type='submit'
                        // ref={quickInvestDash}
                        value='Invest In MF'
                        style={{ marginLeft: '-28px' }}
                      />
                    </form>
                   
                  </div> */}
                  <div className="btn reg-btn">Refer Us</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          // style={{ margin: '0 10px' }}
        >
          {/* for business_dashboard tab */}
          <div id="business_dashboard" className="tabcontent active">
            <div className="tab_title d-flex mb-3 justify-content-between align-items-center">
              <h3>
                <img
                  className="me-1"
                  src={PUBLIC_PATH + "assets/img/icons/assets.png"}
                  alt=""
                  width="25"
                />
                Investment Portfolio{" "}
                <span className="title_amnt">
                  : &#8377; {formatAmount(totalEUM)}
                </span>{" "}
                <span className="title_updated">
                  last updated as on, {update ? formatDate(update) : ""}
                </span>
              </h3>
            </div>
            <div className="container-fluid px-0">
              <div className="row px-0 mb-3 investor_dashboard frachise_plan_cards d-flex justify-content-between align-items-center">
                <LandingCards
                  cardTitle={"Equity"}
                  cardAmount={formatAmount(equitySum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/cash.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_up_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-up"></i>}
                />
                <LandingCards
                  cardTitle={"Mutual Fund"}
                  cardAmount={formatAmount(mfSum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/payment.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_up_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-up"></i>}
                />
                {/* <LandingCards
                  cardTitle={'SIP Book'}
                  cardAmount={'0 Cr'}
                  iconSrc={PUBLIC_PATH + 'assets/img/icons/report.png'}
                  putUpValue={'0.00%'}
                  updatesTime={'NA'}
                  investType={'put_up_value'}
                  arrowDirection={<i className='fa-solid fa-arrow-up'></i>}
                /> */}
                <LandingCards
                  cardTitle={"Real Estate"}
                  cardAmount={formatAmount(realSum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/real-estate.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_down_value"}
                  link="/dashboard/investor/real-estate"
                  arrowDirection={<i className="fa-solid fa-arrow-down"></i>}
                />
                <div className="pb-0 franchise_amnt_dashboardInfo langing_cards_main land_card_2">
                  <div className="wrap">
                    <img
                      src={PUBLIC_PATH + "assets/img/chart_img.png"}
                      alt="chart"
                      style={{ width: "70%", maxHeight: "270px" }}
                    />
                  </div>
                  {/* <img
                    src={PUBLIC_PATH + 'assets/img/chart_img.png'}
                    alt='chart'
                    style={{ width: '75%', maxHeight: '270px' }}
                  /> */}
                </div>
              </div>
              <div className="row inv_card_cont_wrapper_2 my-3">
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <HoverCards
                    iconsSrc={"assets/img/icons/person_invest.svg"}
                    cardTitle={"Invest/Topup In MF"}
                    colorClass="wallet"
                  />
                </div>
                {/* credentialing human-resources */}
                <div
                  className="col-sm-6 col-md-6 col-lg-3"
                  onClick={() => setRegisterNowPopup(true)}
                >
                  <div className="hover__card mb-3  ">
                    <Link className={`hover_card card_hover_sha  education`}>
                      <div className="circle position-relative">
                        <div className="overlay"></div>
                        <img
                          src={PUBLIC_PATH + "assets/img/icons/reports.png"}
                          alt="icons"
                        />
                      </div>
                      <p>{"Top Up Equity Portfolio"}</p>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <HoverCards
                    iconsSrc={"assets/img/icons/growth.png"}
                    cardTitle={"View MF Portfolio"}
                    colorClass="credentialing"
                  />
                </div>
                {/* <div className='col-sm-6 col-md-6 col-lg-3'>
                  <HoverCards
                    iconsSrc={'assets/img/icons/venture.png'}
                    cardTitle={'View Equity Portfolio'}
                    colorClass='human-resources'
                  />
                </div> */}
                <div className="col-12 px-4 my-3">
                  <div className="row table_calc_cont">
                    <div className="tab_title d-flex mb-3 justify-content-between align-items-center">
                      <h3>
                        <img
                          className="me-1"
                          src={PUBLIC_PATH + "assets/img/icons/payment.png"}
                          alt=""
                          width="25"
                        />
                        Your Investment Forcast
                      </h3>
                    </div>
                    <div className="col-6">
                      <div className="table_wrapper">
                        <div className="tableAmntPara">
                          <p>
                            &#128073; Your Investment Forecast on{" "}
                            <span className="amntInptSpan">
                              <span className="rupeeInptSIgn">&#8377; </span>{" "}
                              <input
                                type="number"
                                readOnly
                                value={sipAmounts ? sipAmounts : 0}
                                className="amount_fillup"
                              />
                            </span>
                          </p>
                        </div>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">My SIP </th>
                              <th scope="col">14%</th>
                              <th scope="col">16%</th>
                              <th scope="col">18%</th>
                            </tr>
                          </thead>
                          <tbody>
                            {yearsArray.map((years, index) => (
                              <tr key={index}>
                                <td>{years} Year</td>
                                {percentagesArraySIP.map(
                                  (percentage, innerIndex) => (
                                    <td key={innerIndex}>
                                      {formatAmount(
                                        calculateSIPValue(percentage, years)
                                      )}
                                    </td>
                                  )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="table_wrapper">
                        <div className="tableAmntPara">
                          <p>
                            &#128073; See New Forecast If I top up the SIP
                            amount by{" "}
                            <span className="amntInptSpan">
                              <span className="rupeeInptSIgn">&#8377;</span>
                              <input
                                type="number"
                                placeholder="Amount"
                                value={amount}
                                onChange={(event) =>
                                  setAmount(event.target.value)
                                }
                                className="amount_fillup"
                              />
                            </span>
                            <button
                              className="reg-btn ms-3"
                              onClick={calculateSIP}
                            >
                              Calculate
                            </button>
                          </p>
                        </div>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">My SIP</th>
                              <th scope="col">14%</th>
                              <th scope="col">16%</th>
                              <th scope="col">18%</th>
                            </tr>
                          </thead>
                          <tbody>
                            {yearsArray.map((years, index) => (
                              <tr key={index}>
                                <td>{years} Year</td>
                                {sipValues[index]?.map(
                                  (sipValue, innerIndex) => (
                                    <td key={innerIndex}>{sipValue}</td>
                                  )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-4 my-3">
                  <div className="row table_calc_cont">
                    <div className="tab_title d-flex mb-3 justify-content-between align-items-center">
                      <h3>
                        <img
                          className="me-1"
                          src={PUBLIC_PATH + "assets/img/icons/equity.png"}
                          alt=""
                          width="25"
                        />
                        Your Investment Forcast
                      </h3>
                    </div>
                    <div className="col-md-6 three_grid_table col-lg-6">
                      <div className="tableAmntPara">
                        <p>
                          &#128073; Your Investment Forecast on{" "}
                          <span className="amntInptSpan">
                            <span className="rupeeInptSIgn">&#8377;</span>
                            <input
                              type="number"
                              //placeholder='amount'
                              readOnly
                              value={eqAmounts ? eqAmounts : 0}
                              className="amount_fillup"
                            />
                          </span>
                        </p>
                      </div>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">My Investment</th>
                            <th scope="col">18%</th>
                            <th scope="col">20%</th>
                            <th scope="col">22%</th>
                          </tr>
                        </thead>
                        <tbody>
                          {yearsArray.map((years, index) => (
                            <tr key={index}>
                              <td>{years} Year</td>
                              {percentagesArray.map(
                                (percentage, innerIndex) => (
                                  <td key={innerIndex}>
                                    {formatAmount(
                                      calculateEqValue(percentage, years)
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6 three_grid_table col-lg-6">
                      <div className="tableAmntPara">
                        <p>
                          &#128073; See New Forecast If I top up the investment
                          amount by{" "}
                          <span className="amntInptSpan">
                            <span className="rupeeInptSIgn">&#8377;</span>
                            <input
                              type="number"
                              placeholder="Amount"
                              value={input}
                              onChange={(event) => setInput(event.target.value)}
                              className="amount_fillup"
                            />

                            <button
                              className="reg-btn ms-3"
                              onClick={calculateEQ}
                            >
                              Calculate
                            </button>
                          </span>
                        </p>
                      </div>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">My Investment</th>
                            <th scope="col">18%</th>
                            <th scope="col">20%</th>
                            <th scope="col">22%</th>
                          </tr>
                        </thead>
                        <tbody>
                          {yearsArray?.map((years, index) => (
                            <tr key={index}>
                              <td>{years} Year</td>
                              {eqValues[index]?.map((sipValue, innerIndex) => (
                                <td key={innerIndex}>
                                  {sipValue ? formatAmount(sipValue) : 0}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6 three_grid_table col-lg-6">
                      <div className="tableAmntPara">
                        <p>
                          &#128073; See New Forecast If I start the SIP amount
                          by{" "}
                          <span className="amntInptSpan">
                            <span className="rupeeInptSIgn">&#8377;</span>
                            <input
                              type="number"
                              value={eqinput}
                              onChange={(event) =>
                                setEqInput(event.target.value)
                              }
                              className="amount_fillup"
                            />
                            <button
                              className="reg-btn ms-3"
                              onClick={calculateEquity}
                            >
                              Calculate
                            </button>
                          </span>
                        </p>
                      </div>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">New SIP</th>
                            <th scope="col">18%</th>
                            <th scope="col">20%</th>
                            <th scope="col">22%</th>
                          </tr>
                        </thead>
                        <tbody>
                          {yearsArray.map((years, index) => (
                            <tr key={index}>
                              <td>{years} Year</td>
                              {equityValues[index]?.map(
                                (sipValue, innerIndex) => (
                                  <td key={innerIndex}>{sipValue}</td>
                                )
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =========Create Support========= */}
        {registerNowPopup && (
          <div className="form_popups popup__container">
            <div className="chagne_pass_form">
              <div className="container">
                <div className="row">
                  <div className="col-12 d-flex align-items-center position-relative justify-content-center">
                    <div
                      className="formByScan_container CreatLeadFormWrapper h-auto"
                      id="formByScan_container"
                      style={{ overflow: "visible", padding: "20px 15px" }}
                    >
                      <div
                        className="popup_close_icon createLead"
                        onClick={() => setRegisterNowPopup(false)}
                      >
                        <i
                          className="fa fa-xmark"
                          onClick={() => setRegisterNowPopup(false)}
                        ></i>
                      </div>
                      <form onSubmit={handleSupport}>
                        <h4>Form</h4>
                        <div className="mb-3">
                          <label
                            for="recipient-name"
                            className="col-form-label"
                          >
                            Enter Amount:
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Subject"
                            id="amount"
                            name="amount"
                            required
                            value={data.amount}
                            onChange={handleSupportChange}
                          />
                        </div>
                        <div className="mb-3">
                          <label for="message-text" className="col-form-label">
                            Enter Remark:
                          </label>
                          <textarea
                            className="form-control"
                            placeholder="Remark..."
                            id="remark"
                            name="remark"
                            required
                            value={data.remark}
                            onChange={handleSupportChange}
                          ></textarea>
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="generate_support">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <SuccessModal successStatus={'d-flex'} /> */}
        <div
          className={`modal custom-modal align-items-center ${successStatus}`}
          id="formSuccessModal"
          style={{ background: "rgba(0,0,0,0.3)" }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="formSuccessModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header " style={{ background: "#FF4B2B" }}>
                <h5 className="modal-title" id="formSuccessModalLabel">
                  Success
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeSuccessModal}
                  // data-bs-dismiss='modal'
                  // aria-label='Close'
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-popup-inner">
                  <div className="form-icon success">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 130.2 130.2"
                    >
                      <circle
                        className="path circle"
                        fill="none"
                        strokeWidth="6"
                        strokeMiterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <polyline
                        className="path check"
                        fill="none"
                        strokeWidth="6"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="100.2,40.2 51.5,88.8 29.8,67.5 "
                      />
                    </svg>
                  </div>
                  <div className="form-body">
                    <p>
                      Thank you for filling out form. Your Form Submitted
                      Succesfully
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="popup-btn "
                  style={{ background: "#FF4B2B" }}
                  // data-bs-dismiss='modal'
                  onClick={closeSuccessModal}
                >
                  Okay
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
    </>
  );
}

export default InvestorDashboard;
