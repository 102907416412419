import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { Api_Base_Url, PUBLIC_PATH } from '../constant/matcher'
import '../styles/card.css'
import Footer from '../components/Footer'
import LandingCards from '../components/franchise/LandingCards'
import HoverCards from '../components/cardsDesigns/HoverCards'
import { useSelector } from "react-redux";
import FranchiseSidebar from "../components/franchise/FranchiseSidebar";
import axios from 'axios';
import FieldOutput from "./franchise/Lms/FieldOutput";
import { formatAmount, formatDate } from '../utils/helper'

const InvDetails = () => {
 // loggedIn user Data form react-redux
 const loggedInUserData = useSelector(state => state?.userReducer?.userData)
 const [dynamicRoute, setdynamicRoute] = useState("");
 const [navShow, setNavShow] = useState(true)
 const [userInfo, setUserInfo] = useState([])
 const [equitySum, setEquitySum] = useState(0);
  const [mfSum, setMFSum] = useState(0);
  const [realSum, setRealSum] = useState(0);
  const [totalEUM, setTotalEUM] = useState("")
  const [sipAmounts, setSipAmounts] = useState("");
  const [eqAmounts, setEqAmounts] = useState("");
  const [amount, setAmount] = useState("");
  const [input, setInput] = useState("");
  const [sipValues, setSipValues] = useState([]);
  const [eqValues, setEqValues] = useState([]);
  const [equityValues, setEquityValues] = useState([]);
  const [eqinput, setEqInput] = useState('')
  const [update, setUpdate] = useState("");
  
 // navigation content wrapper handler
 const navContWrapp = boolData => {
   setNavShow(boolData)
 }
 
 useEffect(() => {
    loggedInUserData?.role === "rm"
       ? setdynamicRoute("team")
       : setdynamicRoute("dashboard");
 }, [loggedInUserData]);

 const { _id, clintcode } = useParams()

  const getUser = () => {
    axios.get(`${Api_Base_Url}/rm/single-client?id=${_id}`).then((res) => {
      setUserInfo(res?.data?.clientDetails)
    })
  }

  const getSIP = () => {
    axios
      .get(`${Api_Base_Url}/investor/investor-dashboard/totalbusiness?id=${clintcode}`)
      .then((res) => {
        setEquitySum(res?.data?.data[0]?.equity[0]?.totalvalue || 0);
        setMFSum(res?.data?.data[0]?.mutualfund[0]?.totalvalue || 0);
        setRealSum(res?.data?.data[0]?.realestate[0]?.totalvalue || 0);
        setTotalEUM(
          (res?.data?.data[0]?.sip[0]?.sum || 0) +
          (res?.data?.data[0]?.equity[0]?.totalvalue || 0) +
          (res?.data?.data[0]?.mutualfund[0]?.totalvalue || 0) +
          (res?.data?.data[0]?.realestate[0]?.totalvalue || 0)
        )
        setUpdate(res?.data?.data[0]?.lastupdate[0]?.createdAt);
      });
      
  };

  const getSipAmount = () => {
    axios
      .get(`${Api_Base_Url}/investor/inv-forcaste?clientcode=${clintcode}`)
      .then((res) => {
        const sip = res?.data?.data?.sipData[0]?.sum;
        const eq = res?.data?.data?.EQData[0]?.totalvalue;
        setSipAmounts(sip);
        setEqAmounts(eq);
      });
      
  };

  const yearsArray = [5, 10, 15, 20, 25];
  const percentagesArray = [0.18, 0.20, 0.22];
  const percentagesArraySIP = [0.14, 0.16, 0.18];

  const calculateSIPValue = (percentage, years) => {
    const SIP = sipAmounts ? sipAmounts : 0;
    const sipValue =
    mfSum * (1+percentage)**years +  SIP * (((1+(percentage/12)^(years*12))-1))/(percentage/12)
    return sipValue; 
  };

  const calculateUserSIPValue = (percentage, years) => {
    if (amount) {
      const sipValue =
      amount * (((1+(percentage/12)^(years*12))-1))/(percentage/12)
      return sipValue;
    }

    return "";
  };

  const calculateSIP = () => {
    const sipValuesArray = yearsArray.map((years) => {
      return percentagesArraySIP.map((percentage) => {
        const sipuserVal = calculateUserSIPValue(percentage, years);
        const sipVal = calculateSIPValue(percentage, years)
        const totalSipVal = sipuserVal + sipVal
        return formatAmount(totalSipVal)
      });
    });

    setSipValues(sipValuesArray);
  };

  const calculateEqValue = (percentage, years) => {
    const SIP = eqAmounts ? eqAmounts : 0;
    const eqValue = SIP * (1 + percentage) ** (years);
    return eqValue;
  };

  const calculateUserEQValue = (percentage, years) => {
    const SIP = eqAmounts + Number(input);
    if (input) {
      const eqValue = SIP * (1 + percentage) ** (years);
      return eqValue;
    }

    
  };
  const calculateEQ = () => {
    const sipValuesArray = yearsArray.map((years) => {
      return percentagesArray.map((percentage) => {
        return calculateUserEQValue(percentage, years);
      });
    });
    setEqValues(sipValuesArray);
  };

  const calculateSEquityValue = (percentage, years) => {
    if(eqinput){
      const sipValue =
         eqinput * ((1+(percentage/12))**(years*12))-1/( percentage / 12)
      return sipValue;
    }
  };

  const calculateEquity = () => {
    const sipValuesArray = yearsArray.map((years) => {
      return percentagesArray.map((percentage) => {
        const sipValue = calculateEqValue(percentage, years);
        const equityValue = calculateSEquityValue(percentage, years);
        const totalValue = sipValue + equityValue;
        return formatAmount(totalValue);
      });
    });
    setEquityValues(sipValuesArray);
    
  };


  useEffect(() => {
    getUser()
    getSIP()
    getSipAmount()
  }, [])

  return (
    <>
      <FranchiseSidebar navContWrapp={navContWrapp} />
    <section
      className={`dash-content-wrapper small-wrapper`}
      style={{ paddingLeft: navShow ? '230px' : '70px' }}
    >
      {/* banner sec */}
      <div id='banner'>
        <div className='container-fluid p-0'>
          <div className='banner-img p-0'>
            <img
              src={PUBLIC_PATH + 'assets/img/dash-banner.jpg'}
              alt=''
              className='img-fluid'
            />
          </div>
        </div>
      </div>
    
          <div className='breadcrumbs'>
        {/* <div className='wrap'> */}
          <div className='container'>
          <p>
                     <Link className='ps-3' to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                        Dashboard
                     </Link>{" "}
                     <i className="fa-solid fa-angle-right"></i> My Invester
                  </p>
    
          {/* </div> */}
        </div>
                    </div>
        

        <div
                        className="field__container"
                        style={{ margin: "60px 20px" }}
                     >
                        <div>
                           {/* <======= heading  ======> */}
                           <div className="position-absolute">
                              <div
                                 className="position-relative"
                                 style={{ top: "-40px" }}
                              >
                                 <h2 className="fs-4 ps-3 heading-text-large">
                                     Personal Details
                                 </h2>
                              </div>
                           </div>
                           {/* <========== content =========> */}
                           <div className="row py-2 px-md-3 mt-2 mx-2">
                              {userInfo?.name && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-12 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Name" /> */}
                                             <p>Name: </p>
                                          </span>
                                          <div className="detail_value col col-12 col-md-6 col-xl-7">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={userInfo?.name}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              {userInfo?.email && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-12 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Email" /> */}
                                             <p> Email: </p>
                                          </span>
                                          <div className="detail_value col col-12 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={userInfo?.email}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              {userInfo?.phone && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-12 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Phone" /> */}
                                             <p>Phone: </p>
                                          </span>
                                          <div className="detail_value col col-12 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={userInfo?.phone}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              {userInfo?.address && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-12 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="Address" /> */}
                                             <p>Address: </p>
                                          </span>
                                          <div className="detail_value col col-12 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={userInfo?.address}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              {userInfo?.city && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-12 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="City" /> */}
                                             <p>City: </p>
                                          </span>
                                          <div className="detail_value col col-12 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={userInfo?.city?.city}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                              {userInfo?.product && (
                                 <>
                                    <div className="col col-12 col-md-6 col-xl-4 px-md-1 mt-1">
                                       <div className="row m-1 ">
                                          <span className="detail_heading col col-12 col-md-6 col-xl-3 text-capitalize ">
                                             {/* <FieldOutput value="State" /> */}
                                             <p> Product:</p>
                                          </span>
                                          <div className="detail_value col col-12 col-md-6 col-xl-9">
                                             <div className="overflow-auto ">
                                                <FieldOutput
                                                   value={userInfo?.product?.map((result) => result?.name).join(', ')}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              )}
                           
                           
                           </div>
                        </div>
                     </div>

     

      <div
        className='content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5'
        // style={{ margin: '0 10px' }}
      >
        {/* for business_dashboard tab */}
        <div id='business_dashboard' className='tabcontent active'>
          <div className='tab_title d-flex mb-3 justify-content-between align-items-center'>
            <h3>
              <img
                className='me-1'
                src={PUBLIC_PATH + 'assets/img/icons/assets.png'}
                alt=''
                width='25'
              />
              Investment Portfolio{' '}
              <span className='title_amnt'>: &#8377; {formatAmount(totalEUM)}</span>{' '}
              <span className='title_updated'>
                last updated as on, {update ? formatDate(update) : ""}
              </span>
            </h3>
          </div>
          <div className='container-fluid px-0'>
            <div className='row px-0 mb-3 investor_dashboard frachise_plan_cards d-flex justify-content-between align-items-center'>
              <LandingCards
                cardTitle={'Equity'}
                cardAmount={formatAmount(equitySum)}
                iconSrc={PUBLIC_PATH + 'assets/img/icons/cash.png'}
                putUpValue={'0.00%'}
                updatesTime={'NA'}
                investType={'put_up_value'}
                arrowDirection={<i className='fa-solid fa-arrow-up'></i>}
              />
              <LandingCards
                cardTitle={'Mutual Fund'}
                cardAmount={formatAmount(mfSum)}
                iconSrc={PUBLIC_PATH + 'assets/img/icons/payment.png'}
                putUpValue={'0.00%'}
                updatesTime={'NA'}
                investType={'put_up_value'}
                arrowDirection={<i className='fa-solid fa-arrow-up'></i>}
              />
              {/* <LandingCards
                cardTitle={'SIP Book'}
                cardAmount={'0 Cr'}
                iconSrc={PUBLIC_PATH + 'assets/img/icons/report.png'}
                putUpValue={'0.00%'}
                updatesTime={'NA'}
                investType={'put_up_value'}
                arrowDirection={<i className='fa-solid fa-arrow-up'></i>}
              /> */}
              <LandingCards
                cardTitle={'Real Estate'}
                cardAmount={formatAmount(realSum)}
                iconSrc={PUBLIC_PATH + 'assets/img/icons/real-estate.png'}
                putUpValue={'0.00%'}
                updatesTime={'NA'}
                investType={'put_down_value'}
                arrowDirection={<i className='fa-solid fa-arrow-down'></i>}
              />
              <div className='pb-0 franchise_amnt_dashboardInfo langing_cards_main land_card_2'>
                <div className='wrap'>
                  <img
                    src={PUBLIC_PATH + 'assets/img/chart_img.png'}
                    alt='chart'
                    style={{ width: '70%', maxHeight: '270px' }}
                  />
                </div>
                {/* <img
                  src={PUBLIC_PATH + 'assets/img/chart_img.png'}
                  alt='chart'
                  style={{ width: '75%', maxHeight: '270px' }}
                /> */}
              </div>
            </div>
            <div className='row inv_card_cont_wrapper_2 my-3'>
            <div className="col-sm-6 col-md-6 col-lg-3">
                  <HoverCards
                    iconsSrc={"assets/img/icons/person_invest.svg"}
                    cardTitle={"Invest/Topup In MF"}
                    colorClass="wallet"
                  />
                </div>
                {/* credentialing human-resources */}
                <div
                  className="col-sm-6 col-md-6 col-lg-3"
                  
                >
                  <div className="hover__card mb-3  ">
                    <Link className={`hover_card card_hover_sha  education`}>
                      <div className="circle position-relative">
                        <div className="overlay"></div>
                        <img
                          src={PUBLIC_PATH + "assets/img/icons/reports.png"}
                          alt="icons"
                        />
                      </div>
                      <p>{"Top Up Equity Portfolio"}</p>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <HoverCards
                    iconsSrc={"assets/img/icons/growth.png"}
                    cardTitle={"View MF Portfolio"}
                    colorClass="credentialing"
                  />
                </div>
              {/* <div className='col-sm-6 col-md-6 col-lg-3'>
                <HoverCards
                  iconsSrc={'assets/img/icons/venture.png'}
                  cardTitle={'View Equity Portfolio'}
                  colorClass='human-resources'
                />
              </div> */}
                <div className="col-12 px-4 my-3">
                  <div className="row table_calc_cont">
                    <div className="tab_title d-flex mb-3 justify-content-between align-items-center">
                      <h3>
                        <img
                          className="me-1"
                          src={PUBLIC_PATH + "assets/img/icons/payment.png"}
                          alt=""
                          width="25"
                        />
                        Your Investment Forcast
                      </h3>
                    </div>
                    <div className="col-6">
                      <div className="table_wrapper">
                        <div className="tableAmntPara">
                          <p>
                            &#128073;Your Investment Forecast on{" "}
                            <span className="amntInptSpan">
                              <span className="rupeeInptSIgn">&#8377; </span>{" "}
                              <input
                                type="number"
                                readOnly
                                value={sipAmounts ? sipAmounts : 0}
                                className="amount_fillup"
                              />
                            </span>
                          </p>
                        </div>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">My SIP</th>
                              <th scope="col">14%</th>
                              <th scope="col">16%</th>
                              <th scope="col">18%</th>
                            </tr>
                          </thead> 
                          <tbody>
                            {yearsArray.map((years, index) => (
                              <tr key={index}>
                                <td>{years} Year</td>
                                {percentagesArraySIP.map(
                                  (percentage, innerIndex) => (
                                    <td key={innerIndex}>
                                      {formatAmount(calculateSIPValue(percentage, years))}
                                    </td>
                                  )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="table_wrapper">
                        <div className="tableAmntPara">
                          <p>
                            &#128073; See New Forecast If I top up the SIP amount by{" "}
                            <span className="amntInptSpan">
                              <span className="rupeeInptSIgn">&#8377;</span>
                              <input
                                type="number"
                                placeholder="Amount"
                                value={amount}
                                onChange={(event) =>
                                  setAmount(event.target.value)
                                }
                                className="amount_fillup"
                              />
                            </span>
                            <button
                              className="reg-btn ms-3"
                              onClick={calculateSIP}
                            >
                              Calculate
                            </button>
                          </p>
                        </div>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">My SIP </th>
                              <th scope="col">14%</th>
                              <th scope="col">16%</th>
                              <th scope="col">18%</th>
                            </tr>
                          </thead>
                          <tbody>
                            {yearsArray.map((years, index) => (
                              <tr key={index}>
                                <td>{years} Year</td>
                                {sipValues[index]?.map(
                                  (sipValue, innerIndex) => (
                                    <td key={innerIndex}>
                                      {formatAmount(sipValue)}
                                    </td>
                                  )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-4 my-3">
                  <div className="row table_calc_cont">
                    <div className="tab_title d-flex mb-3 justify-content-between align-items-center">
                      <h3>
                        <img
                          className="me-1"
                          src={PUBLIC_PATH + "assets/img/icons/equity.png"}
                          alt=""
                          width="25"
                        />
                         Your Investment Forcast
                      </h3>
                    </div>
                    <div className="col-md-6 three_grid_table col-lg-6">
                      <div className="tableAmntPara">
                        <p>
                          &#128073; Your Investment Forecast on{" "}
                          <span className="amntInptSpan">
                            <span className="rupeeInptSIgn">&#8377;</span>
                            <input
                              type="number"
                              //placeholder='amount'
                              readOnly
                              value={eqAmounts ? eqAmounts : 0}
                              className="amount_fillup"
                            />
                          </span>
                        </p>
                      </div>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">My Investment </th>
                            <th scope="col">18%</th>
                            <th scope="col">20%</th>
                            <th scope="col">22%</th>
                          </tr>
                        </thead>
                        <tbody>
                          {yearsArray.map((years, index) => (
                            <tr key={index}>
                              <td>{years} Year</td>
                              {percentagesArray.map(
                                (percentage, innerIndex) => (
                                  <td key={innerIndex}>
                                    {formatAmount(calculateEqValue(percentage, years))}
                                  </td>
                                )
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6 three_grid_table col-lg-6">
                      <div className="tableAmntPara">
                        <p>
                          &#128073; See New Forecast If I top up the investment amount by{" "}
                          <span className="amntInptSpan">
                            <span className="rupeeInptSIgn">&#8377;</span>
                            <input
                              type="number"
                              placeholder="Amount"
                              value={input}
                              onChange={(event) => setInput(event.target.value)}
                              className="amount_fillup"
                            />

                            <button
                              className="reg-btn ms-3"
                              onClick={calculateEQ}
                            >
                              Calculate
                            </button>
                          </span>
                        </p>
                      </div>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">My Investment </th>
                            <th scope="col">18%</th>
                            <th scope="col">20%</th>
                            <th scope="col">22%</th>
                          </tr>
                        </thead>
                        <tbody>
                          {yearsArray.map((years, index) => (
                            <tr key={index}>
                              <td>{years} Year</td>
                              {eqValues[index]?.map((sipValue, innerIndex) => (
                                <td key={innerIndex}>
                                  {sipValue ? formatAmount(sipValue) : 0}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6 three_grid_table col-lg-6">
                      <div className="tableAmntPara">
                        <p>
                          &#128073; See New Forecast If I start the SIP amount by{" "}
                          <span className="amntInptSpan">
                            <span className="rupeeInptSIgn">&#8377;</span>
                            <input
                              type="number"
                              value={eqinput}
                              onChange={(event) => setEqInput(event.target.value)}
                              className="amount_fillup"
                              
                            />
                             <button
                              className="reg-btn ms-3"
                              onClick={calculateEquity}
                            >
                              Calculate
                            </button>
                          </span>
                        </p>
                      </div>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">New SIP </th>
                            <th scope="col">18%</th>
                            <th scope="col">20%</th>
                            <th scope="col">22%</th>
                          </tr>
                        </thead>
                        <tbody>
                        {yearsArray.map((years, index) => (
                            <tr key={index}>
                              <td>{years} Year</td>
                              {equityValues[index]?.map((sipValue, innerIndex) => (
                                <td key={innerIndex}>
                                  {sipValue}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <Footer
        logoLink={'/'}
        home={'/#home'}
        about={'/#about'}
        service={'/#service'}
        faq={'/#faq'}
        testimonial={'/#testimonials'}
      />
    </section>
  </>
  )
}

export default InvDetails