import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { PUBLIC_PATH } from '../../constant/matcher'
import FranchiseSidebar from '../../components/franchise/FranchiseSidebar'
import DashCards from '../../components/DashCards'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../../styles/card.css'
import Footer from '../../components/Footer'
import { unslugify } from '../../constant/utils'
import SkeletonLoader from '../../components/skeleton/SkeletonLoader'
import SkeletonCardLoader from '../../components/skeleton/SkeletonCardLoader'
import {
  useGetAllFranchiseProductsQuery
} from '../../store/services/dashboardService'
import { useSelector } from 'react-redux'
import DashboardBanner from '../../components/general/DashboardBanner'
import Loader from '../../components/general/Loader'
import AlertCompleteProfile from '../../components/cardsDesigns/AlertCompleteProfile'

function Dashboard () {
  // loggedIn user Data form react-redux
  const [isloading, setIsLoading] = useState(true)
  const loggedInUserData = useSelector(state => state?.userReducer?.userData)
  var loggedUserCategory = loggedInUserData && loggedInUserData?.userType
  const [dynamicRoute, setdynamicRoute] = useState("");
  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
  }, [loggedInUserData]);

  // API to get product using filter
  let subCategoryId = loggedInUserData?.subCategory
  let userType = loggedInUserData?.role
  var productId = ''
  let productArr = loggedInUserData?.product?.map((items, index) => {
    productId =
      productId +
      items +
      (loggedInUserData?.product.length === index + 1 ? '' : ',')

    return productId
  })

  let filterData =
    'category=' +
    // userType +
    loggedInUserData?.category +
    // '&subCategory=' +
    // subCategoryId +
    '&productType=xpand&product=' +
    // loggedInUserData?.product[0]
    productId
    console.log(loggedInUserData)
  let filterData_1 =
    'category=' +
    // userType +
    loggedInUserData?.category +
    // '&subCategory=' +
    // subCategoryId +
    '&productType=product&product=' +
    // loggedInUserData?.product[0]
    productId

  // const productApiData = useGetAllProductsQuery(filterData)
  const productApiData = useGetAllFranchiseProductsQuery(filterData)

  // useEffect(() => {
  //   console.log(productApiData);
  // }, [productApiData]);

  // const productApiData_1 = useGetAllProductsQuery(filterData_1)
  const productApiData_1 = useGetAllFranchiseProductsQuery(filterData_1)

  const serverPath =
    productApiData?.isSuccess &&
    !productApiData?.isLoading &&
    productApiData.status === 'fulfilled' &&
    !productApiData?.isError &&
    productApiData?.data?.path

  const serverPath_1 =
    productApiData_1?.isSuccess &&
    !productApiData_1?.isLoading &&
    productApiData_1.status === 'fulfilled' &&
    !productApiData_1?.isError &&
    productApiData_1?.data?.path

  const dashContent =
    productApiData?.isSuccess &&
    !productApiData?.isLoading &&
    productApiData.status === 'fulfilled' &&
    !productApiData?.isError &&
    productApiData?.data?.products

  const dashContent_1 =
    productApiData_1?.isSuccess &&
    !productApiData_1?.isLoading &&
    productApiData_1.status === 'fulfilled' &&
    !productApiData_1?.isError &&
    productApiData_1?.data?.products

  let uniqueXpandData = []
  let uniqueProductData = []
  // useEffect(() => {
  //   console.log(productApiData)
  // }, [productApiData])

  // to hide blank section
  useEffect(() => {
    const proSec = document.getElementsByClassName('prod_div_sec')
    const proColSec = document.getElementsByClassName('slick-track')

    const proColSecArr = Array.from(proColSec)
    const proSecArr = Array.from(proSec)

    if (proColSec) {
      for (let i = 0; i < proColSecArr.length; i++) {
        const element = proColSecArr[i]
        if (element.childNodes.length === 0) {
          element.parentNode.parentNode.parentNode.parentNode.style.display =
            'none'
        }
      }
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, [productApiData_1])

  const [navShow, setNavShow] = useState(true)

  // navigation content wrapper handler
  const navContWrapp = boolData => {
    setNavShow(boolData)
  }

  var settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        // breakpoint: 1545,
        breakpoint: 1445,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  var testiSettings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <>
      {
      isloading ? (
        <Loader/>
      )
      : 
      (
   
        <>
      <FranchiseSidebar navContWrapp={navContWrapp} />
      {loggedInUserData?.role === "cp" && (
    <AlertCompleteProfile/>
      )}
      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? '230px' : '70px' }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className='breadcrumbs'>
          <div className='container'>
            <p>
              <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>Dashboard</Link>{' '}
              <i className='fa-solid fa-angle-right'></i> X-Content
            </p>
          </div>
        </div>

        <div className='content-wrapper main-dash-wrapper'>
        
            {dashContent && dashContent?.length > 0 && (
            <section className='xpand-acandemy pt-0 inner-bg'>
              <div className='container container-wrapper'>
                <div className='row inner-content'>
                  <div className='col-12 about-product-inner'>
                    <div className='mt-2 about-products'>
                      <div className='sec-title sec-title_list'>
                        <h2 className='text-capitalize'>Xpand Academy</h2>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 dash_slider_content_card about-product-inner-2 slider-container'>
                    <Slider {...settings}>
                      {dashContent &&
                        dashContent?.length !== 0 &&
                        dashContent?.map((productItems, index) => {
                          if (
                            !uniqueXpandData.includes(
                              productItems?.contentId?.topic
                            )
                          ) {
                            uniqueXpandData.push(productItems?.contentId?.topic)
                            return (
                              <>
                                <DashCards
                                  key={productItems?.contentId?._id}
                                  cardTitle={productItems?.contentId?.topic}
                                  cardDesc={
                                    productItems?.contentId?.description
                                  }
                                  cardLink={`/team/rm/listingdetail/${productItems?.contentId?._id}`}
                                  cardImg={
                                    serverPath + productItems?.contentId?.image
                                  }
                                  pdfCounter={
                                    productItems?.contentId?.pdf?.length
                                  }
                                  VideoCounter={
                                    productItems?.contentId?.videos?.length
                                  }
                                />
                              </>
                            )
                          }
                        })}
                      {dashContent?.length === 0 && (
                        <div className='text-secondary'>Data Not Available</div>
                      )}
                      {productApiData?.isLoading && (
                        <>
                          <div className='d-flex align-items-center py-3 px-2'>
                            <SkeletonCardLoader />
                            <SkeletonCardLoader />
                            <SkeletonCardLoader />
                          </div>
                        </>
                      )}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
          )}
            {dashContent_1 &&
            dashContent_1?.length > 0 &&
            dashContent_1?.map((productItems, index) => {
              return (
                <>
                  {Object.keys(productItems)?.map((itemName, index) => {
                    return (
                      <>
                        <section
                          className='about-product pt-0 inner-bg'
                          key={index}
                        >
                          <div className='container container-wrapper'>
                            <div className='row inner-content'>
                              <div className='col-12 about-product-inner'>
                                <div className='mt-2 about-products'>
                                  <div className='sec-title sec-title_list'>
                                    <h2 className='text-capitalize'>
                                      About {unslugify(itemName)}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`col-12 dash_slider_content_card about-product-inner-2 slider-container prod_col_cont prod_col_cont_${index}`}
                                key={index}
                              >
                                <Slider {...settings}>
                                  {productItems[itemName]?.map(
                                    (items, index) => {
                                      if (
                                        !uniqueProductData.includes(
                                          items?.contentId?.topic
                                        )
                                      ) {
                                        uniqueProductData.push(
                                          items?.contentId?.topic
                                        )
                                        return (
                                          <DashCards
                                            key={items?.contentId?._id}
                                            cardTitle={items?.contentId?.topic}
                                            cardDesc={
                                              items?.contentId?.description
                                            }
                                            cardLink={`/team/rm/listingdetail/${items?.contentId?._id}`}
                                            cardImg={
                                              serverPath_1 +
                                              items?.contentId?.image
                                            }
                                            pdfCounter={
                                              items?.contentId?.pdf?.length
                                            }
                                            VideoCounter={
                                              items?.contentId?.videos?.length
                                            }
                                          />
                                        )
                                      }
                                    }
                                  )}
                                  {productItems[itemName]?.length === 0 && (
                                    <div className='text-secondary'>
                                      Data Not Available
                                    </div>
                                  )}
                                </Slider>
                              </div>
                            </div>
                          </div>
                        </section>
                      </>
                    )
                  })}
                </>
              )
            })}
          {productApiData_1?.isLoading && <SkeletonLoader />}

        </div>

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={'/'}
          home={'/#home'}
          about={'/#about'}
          service={'/#service'}
          faq={'/#faq'}
          testimonial={'/#testimonials'}
        />
      </section>
      </>
           )
          }
    </>
  )
}

export default Dashboard
