import React, { useEffect,  useState } from "react";
import { Link } from "react-router-dom";
import FranchiseSidebar from "../components/franchise/FranchiseSidebar";
import { useSelector } from "react-redux";
import DashboardBanner from "../components/general/DashboardBanner";
import { Api_Base_Url} from "../constant/matcher";
import Footer from "../components/Footer";
import axios from "axios";
import { showformatDate } from "../utils/helper";
import Loader from "../components/general/Loader";

const RegisterBusiness = (props) => {
  const [record, setRecord] = useState([]);
  const [isloading, setIsLoading] = useState(true)
  const [successStatus, setSuccessStatus] = useState("fade");
  const [businessData, setBusinessData] = useState({
    date: "",
    clientName: "",
    panNumber: "",
    businessType: "",
    role: null,
    scheme: null,
    amount: "",
    remark: "",
  });
  const [clientSuggestions, setClientSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedClientId, setSelectedClientId] = useState("");
  const [selectedClientPan, setSelectedClientPan] = useState("");
  const [aMCName, setAMCName] = useState([]);
  const [scheme, setScheme] = useState([]);
  const [registerNowPopup, setRegisterNowPopup] = useState(false);
  const [showList, setShowList] = useState(false)
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [dynamicRoute, setdynamicRoute] = useState("");
  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
  }, [loggedInUserData]);

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  const closeSuccessModal = () => {
    setSuccessStatus("fade");
    setRegisterNowPopup(false)
    
  };

  const today = new Date();
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() - 45);

  const getClientName = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    let id = loginUserData._id;
    axios.get(`${Api_Base_Url}/rm/my-client?id=${id}`).then((res) => {
      const clients = res?.data?.myClient;
    const sortedClients = clients.sort((a, b) => a.name.localeCompare(b.name));

    setClientSuggestions(sortedClients);

    });
  };

 
  const getAMCName = () => {
    axios.get(`${Api_Base_Url}/rm/rmBussinessamc`).then((res) => {
      setAMCName(res?.data?.data);
    });
  };

  const handleAMCSelection = (e) => {
    let id = e.target.value;
    axios.get(`${Api_Base_Url}/rm/rmBussinessschema?id=${id}`).then((res) => {
      setScheme(res?.data?.data);
    });
  };

  const handleChange = (e) => {
    
    if(e.target.name === "clientName"){
      const { value } = e.target;
      setInputValue(value);
    
      // When the client name is selected, find the corresponding client's data
      const selectedClient = clientSuggestions.find(
        (client) => client.name === value
      );
      if (selectedClient) {
        setSelectedClientId(selectedClient._id);
        if (selectedClient.invtdocument[0]?.panNumber) {
          setSelectedClientPan(selectedClient?.invtdocument[0]?.panNumber);
        } else {
          setSelectedClientPan("");
        }
      } else {
        // Handle the case when no client is selected
        setSelectedClientId("");
        setSelectedClientPan("");
      }
      setShowList(true)
    }
    setBusinessData({
      ...businessData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (client) => {
    setInputValue(client.name);
    setSelectedClientId(client._id);
    const panNumber =
      client.invtdocument[0]?.panNumber || "No PAN Number available";
    setSelectedClientPan(panNumber);
    setShowList(false); // Hide suggestions after selecting a name
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    if (!businessData.role) {
      businessData.role = null;
    }
    console.log("BD", businessData);
    const formData = new FormData();
    businessData.clientName = selectedClientId;
    businessData.panNumber = selectedClientPan;
    formData.append("date", businessData.date);
    formData.append("investorID", selectedClientId);
    formData.append("panNumber", selectedClientPan);
    formData.append("bussiness", businessData.businessType);
    if (businessData.role !== null) {
      formData.append("amcName", businessData.role);
    }
    formData.append("schemeName", businessData.scheme);
    formData.append("amount", businessData.amount);
    formData.append("remarks", businessData.remark);
    // console.log("FormData entries:");
    // for (const entry of formData.entries()) {
    //   console.log(entry);
    // }
    const response = await axios.post(
      `${Api_Base_Url}/rm/rmBussinessadd?assignTo=${id}`,
      formData,
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    if (response.status === 201) {
      setSuccessStatus("d-flex");
    }
    setBusinessData({
      date: "",
      clientName: "",
      panNumber: "",
      businessType: "",
      role: "",
      scheme: "",
      amount: "",
      remark: "",
    });
    getBusinessData();
   
  };

const getMyClient = () => {
  const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
  const id = loginUserData._id;
  const role = loginUserData.role
  axios.get(`${Api_Base_Url}/${role}/my-client?id=${id}`).then((res) => {
    const myClientsData = res?.data?.myClient;
    // console.log(myClientsData);
    getBusinessData(myClientsData)
  });
}

  const getBusinessData = async (myClientsData) => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    const assign = loginUserData.assignTo
    const role = loginUserData.role
    const assignId = role === 'rm' ? id : assign
    const response = await  axios.get(`${Api_Base_Url}/rm/rmBussiness?assignTo=${assignId}`)
      const sortedData = response?.data?.data
      const myClientId =  myClientsData?.map((client) => client?._id)
      // console.log(myClientsData);
      const filteredData = role === 'rm' ? sortedData : sortedData.filter((item) =>   myClientId?.includes(item?.investorID?._id))
      // console.log(filteredData);
      filteredData.sort((a, b) =>  new Date(b.date) - new Date(a.date));
      // console.log(sortedData);
      setRecord(filteredData); 
  };

  useEffect(() => {
    getMyClient()
    getClientName();
    getBusinessData();
    getAMCName();
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, []);
  return (
    <>
    {
      isloading ? (
        <Loader/>
      )
      : 
      (
   
        <>
      <FranchiseSidebar navContWrapp={navContWrapp} />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                Dashboard
              </Link>{" "}
              <i className="fa-solid fa-angle-right"></i> Register Business
            </p>
          </div>
        </div>

        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          style={{ background: "whitesmoke" }}
        >
         
          <div className="row table__row__wrapp">
            <div className="client_table_container py-2">
              <div className="register-business">
                <h5> Register Business Details </h5>
                <div className="sidebar_link_a">
                  <Link
                    
                    className="reg-btn"
                   onClick={() => setRegisterNowPopup(true)}
                
                  >
                    Register New Business
                  </Link>
                </div>
              </div>
              <div className="w-100 overflow-auto">
                <table className="table table-hover  table-striped  client__table">
                  <thead>
                    <tr>
                      <th scope="col">Sr No.</th>
                      <th scope="col">Date </th>
                      <th scope="col">Client Name </th>
                      <th scope="col">PAN </th>
                      <th scope="col">Business Type </th>
                      <th scope="col">AMC Name </th>
                      <th scope="col">Scheme Name </th>
                      <th scope="col">Amount </th>
                      <th scope="col">Remark </th>
                      <th scope="col">Status </th>
                      <th scope="col">Admin Remark </th>
                      <th scope="col">System Entry</th>
                    </tr>
                  </thead>
                  <tbody>
                    {record &&
                      record?.map((result, index) => {
                        return (
                          <tr key={result._id}>
                            <td className="text-black "> {index + 1} </td>
                            <td> {showformatDate(result?.date)} </td>
                            <td>{result?.investorID?.name}</td>
                            <td>{result?.panNumber}</td>
                            <td>{result?.bussiness}</td>
                            <td>{result?.amcName?.name === "null" ? '' : result?.amcName?.name}</td>
                            <td>{result?.schemeName === "null" ? '' : result?.schemeName}</td>
                            <td>{result?.amount}</td>
                            <td>{result?.remarks}</td>
                            <td>{result?.approvalStatus}</td>
                            <td>{result?.HOremarks}</td>
                            <td>{result?.systemEntiryStatus}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* <---- Register New Business Form Popup Start --> */}
        {/* {showPopup && (
               <> */}
               {
                registerNowPopup && (

               
                <div className="form_popups popup__container">
        <div className="chagne_pass_form">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex align-items-center position-relative justify-content-center">
                <div
                  className="formByScan_container CreatLeadFormWrapper h-auto"
                  id="formByScan_container"
                  style={{ overflow: "visible", padding: "10px 15px" }}
                >
                  <div
                    className="popup_close_icon createLead"
                    onClick={() => setRegisterNowPopup(false)}
                  >
                    <i
                      className="fa fa-xmark"
                      onClick={() => setRegisterNowPopup(false)}
                    ></i>
                  </div>
       
                <form
                  // onSubmit={onFormSubmit}
                  className="contact-form m-3 pb-0 register-form"
                  autoComplete="off"
                  onSubmit={submitForm}
                  style={{padding: "0"}}

                >
                 

                  <div className="col">
                       <input
                       type="text"
                       list="clientNames"
                       name="clientName"
                       id="clientName"
                       value={inputValue}
                       onChange={handleChange}
                       className="form-control rounded border-white mb-2 form-input"
                       placeholder="Client Name *"
                       required
                      />
                      {
                        showList && (
                      
                   <ul className="registerbusiness-list">
        
        {clientSuggestions
          .filter((client) =>
            client.name.toLowerCase().includes(inputValue.toLowerCase())
          )
          .map((client) => (
            <>
            <li
              key={client._id}
              onClick={() => 
                handleSelect(client)
              }
            >
              {client.name}
            </li>
            <hr />
           </>
          ))}
      </ul>
          
          )
        }
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control rounded border-white mb-2 form-input"
                      id="pan"
                      name="panNumber"
                      placeholder="PAN"
                      value={selectedClientPan}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <select
                      name="businessType"
                      className="form-select  rounded border-white mb-2 form-input"
                      required
                      value={businessData.businessType}
                      onChange={handleChange}
                    >
                      <option value="">Select Business Type *</option>
                      <option value="SIP">SIP</option>
                      <option value="Lumpsum">Lumpsum</option>
                      <option value="EqualityAdvisor">Equity Advisory</option>
                      <option value="MontleyRentalIncome">
                        Montley Rental Income
                      </option>
                    </select>
                  </div>
                  {["SIP", "Lumpsum"].includes(businessData.businessType) && (
                    <>
                      <div className="col">
                        <select
                          name="role"
                          className="form-select rounded border-white mb-2 form-input"
                          value={businessData.role}
                          onChange={(e) => {
                            handleChange(e);
                            handleAMCSelection(e);
                          }}
                        >
                          <option value="">Select AMC Name *</option>
                          {aMCName?.map((res) => {
                            return (
                              <option value={res?._id}>{res?.name}</option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="col">
                        <select
                          name="scheme"
                          className="form-select rounded border-white mb-2 form-input"
                          value={businessData.scheme}
                          onChange={handleChange}
                        >
                          <option value="">Select Scheme Name *</option>
                          {scheme?.map((res) => {
                            return (
                              <option value={res?.name}>{res?.name}</option>
                            );
                          })}
                        </select>
                      </div>
                    </>
                  )}
                  <div className="col">
                    <input
                      type="number"
                      className="form-control rounded border-white mb-2 form-input"
                      id="amount"
                      name="amount"
                      placeholder="Amount"
                      value={businessData.amount}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="date"
                      className="form-control rounded border-white mb-2 form-input"
                      id="date"
                      name="date"
                      required
                      placeholder="Enter Date"
                      value={businessData.date}
                      onChange={handleChange}
                      min={maxDate.toISOString().split("T")[0]}
                      max={today.toISOString().split("T")[0]}
                    />
                  </div>
                  <div className="col">
                    <textarea
                      className="form-control rounded border-white mb-2 form-input"
                      id="remark"
                      name="remark"
                      placeholder="Remark"
                      value={businessData.remark}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col submit-button-wrapper mt-3">
                    <button type="submit">Submit</button>
                  </div>
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       )
      }
            
        {/* <SuccessModal successStatus={'d-flex'} /> */}
        <div
          className={`modal custom-modal align-items-center ${successStatus}`}
          id="formSuccessModal"
          style={{ background: "rgba(0,0,0,0.3)" }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="formSuccessModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header " style={{ background: "#FF4B2B" }}>
                <h5 className="modal-title" id="formSuccessModalLabel">
                  Success
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeSuccessModal}
                  // data-bs-dismiss='modal'
                  // aria-label='Close'
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-popup-inner">
                  <div className="form-icon success">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 130.2 130.2"
                    >
                      <circle
                        className="path circle"
                        fill="none"
                        strokeWidth="6"
                        strokeMiterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <polyline
                        className="path check"
                        fill="none"
                        strokeWidth="6"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="100.2,40.2 51.5,88.8 29.8,67.5 "
                      />
                    </svg>
                  </div>
                  <div className="form-body">
                    <p>
                      Thank you for filling out our form. Your Business Registration
                      completed Successfully.
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="popup-btn "
                  style={{ background: "#FF4B2B" }}
                  // data-bs-dismiss='modal'
                  onClick={closeSuccessModal}
                >
                  Okay
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
      </>
           )
          }
    </>
  );
};

export default RegisterBusiness;


