import React from 'react'
import '../../styles/card.css'
import '../../styles/loaders.css'

function SkeletonCardLoader () {
  return (
    <>
      {/* <div className='container px-4 my-4'>
          <div className="row">
            <div className="col-md-4"> */}
              <div className='skel_card_cont me-4 pt-3'>
                <div className='skel_cards'>
                  <div className="skel_card_img"></div>
                  <div className='skel_heading mx-3 my-3'></div>
                  <div className="skel_card_desc px-3"><p></p></div>
                  <div className="skel_counters d-flex align-items-center justify-content-between p-3">
                    <h2></h2>
                    <h2></h2>
                  </div>
                  <div className="skel_card_offer pt-0 pb-2 px-3">
                    <p></p>
                  </div>
                </div>
                <div className='skel_cards'></div>
                <div className='skel_cards'></div>
              </div>
            {/* </div>
          </div>
      </div> */}
    </>
  )
}

export default SkeletonCardLoader
