import React from "react";

const FieldOutput = (props) => {
  // console.log("FiledOutput : ", props);

  return (
    <>
      <p className=""> {props?.value} </p>
    </>
  );
};

export default FieldOutput;
