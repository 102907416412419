import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useProductSelectQuery } from '../../../store/services/loginService.js'

const Readonlyproduct = ({product}) => {
    const productValue = product ? [{ label: product, value: product }] : [];
  
   
    const [selectedProduct, setSelectedProduct] = useState(product)

    const multiResp = useProductSelectQuery()
  var multiSelectData =
    multiResp?.isSuccess &&
    !multiResp?.isLoading &&
    multiResp.status === 'fulfilled' &&
    !multiResp?.isError &&
    multiResp?.data?.productsList

    const searchOptions =
    multiSelectData &&
    multiSelectData?.map(({ name, _id }) => ({
      label: name,
      value: _id
    }))

    const selectedoption= multiSelectData && multiSelectData.filter(fitem => selectedProduct.includes(fitem._id)).map((item) =>({
      label: item.name,
      value: item._id
    }));
  


  return (
    <div className='row my-3'>
    <div className='react-multiselect form-group'>
      <Select
        closeMenuOnSelect={false}
        isMulti
        className='basic-single w-100'
        classNamePrefix='select'
        value={selectedoption}
        isClearable={false}
        isSearchable={false}
        name='product'
        options={searchOptions ? searchOptions : []}
        // options={[]} // Pass an empty array as options since this is read-only
        isDisabled // Disable the Select component
      />
    </div>
  </div>
  )
}

export default Readonlyproduct