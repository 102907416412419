import React from 'react'
import '../../styles/card.css'
import { FaHourglassStart } from "react-icons/fa";
import { MdRestartAlt } from "react-icons/md";
import { RiUserFollowFill } from "react-icons/ri";
import { VscVmActive,VscVmOutline } from "react-icons/vsc";
import { TbMoneybag } from "react-icons/tb";
import { GiTakeMyMoney,GiReceiveMoney } from "react-icons/gi";



function MyClientsCards (props) {
  return (
    <>
        <div className={`card client__card radius-10 border-start border-0 border-3 card_border-${props.borderColor}`} style={{height: '95%'}} onClick={props.onClick}>
          <div className='card-body'>
            <div className='d-flex align-items-center'>
              <div>
                <p className='mb-0 client_card_title text-secondary text-uppercase'>{props.cardTitle}</p>
                <h4 className={`my-1 card_text_clr_${props.textColor}`}>{props.cardValues}</h4>
                <p className='mb-0 font-13'>{props.updateTime}</p>
              </div>
              <div className={`widgets-icons-2 rounded-circle bg-gradient-${props.bgGradient} text-white ms-auto`}>
                {props.iconsNumb === 1 && <FaHourglassStart />}
                {props.iconsNumb === 2 && <i class="fa-solid fa-circle-exclamation"></i>}
                {props.iconsNumb === 3 && <i class="fa-solid fa-check"></i>}
                {props.iconsNumb === 4 && <MdRestartAlt />}
                {props.iconsNumb === 5 && <RiUserFollowFill />}
                {props.iconsNumb === 6 && <VscVmActive />}
                {props.iconsNumb === 7 && <VscVmOutline />}
                {props.iconsNumb === 8 && <TbMoneybag />}
                {props.iconsNumb === 9 && <GiTakeMyMoney />}
                {props.iconsNumb === 10 && <GiReceiveMoney />}
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
 
export default MyClientsCards
