import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { ProfileCompletionContext } from "../ProfileCompletionContext";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const AlertCompleteProfile = () => {
   const { isProfileComplete } = useContext(ProfileCompletionContext);
   const [dynamicRoute, setdynamicRoute] = useState();
   const userData = useSelector((state) => state?.userReducer?.userData);

   useEffect(() => {
      userData?.role === "rm"
         ? setdynamicRoute("team")
         : setdynamicRoute("dashboard");
   }, [userData]);

   return (
      <>
         {!isProfileComplete &&  (
            <>
            <marquee className="com-pro-alert" width="60%" direction="left" height="65px" style={{width: "60%"}}>
               <div className="" role="alert">
                  <i class="fa-solid fa-triangle-exclamation" style={{color: "#842029"}}></i>
                  <span style={{color: "#842029"}}>Your Profile is not completed !{" "}</span>
                  <Link
                     to={`/${dynamicRoute}/${userData?.role}/profile`}
                     className="alert-link"
                     style={{color: "#842029"}}
                  >
                     Click here to complete
                  </Link>
                  
               </div>
            </marquee>
            </>
         )}
      </>
   );
};
export default AlertCompleteProfile;
