import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PUBLIC_PATH } from "../../constant/matcher";
import Sidebar from "../../components/Sidebar";
import DetailsCard from "../../components/DetailsCard";
import { unslugify } from "../../constant/utils";
import Footer from "../../components/Footer";
import DashboardBanner from "../../components/general/DashboardBanner";
import { useSelector } from "react-redux";
import {
  useGetProductsApiQuery,
  useGetAllProductsQuery,
} from "../../store/services/dashboardService";
import Loader from "../../components/general/Loader";
import SkeletonLoader from "../../components/skeleton/SkeletonLoader";

function DetailsPage() {
  const [navShow, setNavShow] = useState(true);
  const [SmallWrapper, setSmallWrapper] = useState(" small-wrapper ");

  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  var loggedUserCategory = loggedInUserData && loggedInUserData?.userType;

  // API to get product using filter
  let subCategoryId = loggedInUserData?.subCategory;
  let userType = loggedInUserData?.userType;
  let productId = "";
  let productArr = loggedInUserData?.product?.map((items, index) => {
    productId =
      productId +
      items +
      (loggedInUserData?.product.length === index + 1 ? "" : ",");

    return productId;
  });

  // filter params to get product data
  let filterData =
    "category=" +
    userType +
    "&subCategory=" +
    subCategoryId +
    "&productType=product&product=" +
    // loggedInUserData?.product[0]
    productId;

  // api call
  const productApiData = useGetAllProductsQuery(filterData);

  // const [uniqueData, setUniqueData] = useState();
  let uniqueData = [];
  let key = 0;

  // useEffect(() => {
  //   console.log(uniqueData)
  // }, [uniqueData])

  // dynami sever path for files
  const serverPath =
    productApiData?.isSuccess &&
    !productApiData?.isLoading &&
    productApiData.status === "fulfilled" &&
    !productApiData?.isError &&
    productApiData?.data?.path;

  // iterate products array from response
  const dashContent =
    productApiData?.isSuccess &&
    !productApiData?.isLoading &&
    productApiData.status === "fulfilled" &&
    !productApiData?.isError &&
    productApiData?.data?.products;

  // useEffect(() => {
  //   console.log(dashContent)
  // }, [dashContent])

  // to hide blank section
  useEffect(() => {
    const proSec = document.getElementsByClassName("prod_div_sec");
    const proColSec = document.getElementsByClassName("prod_col_cont");

    const proColSecArr = Array.from(proColSec);
    const proSecArr = Array.from(proSec);

    if (proColSecArr) {
      for (let i = 0; i < proColSecArr.length; i++) {
        const element = proColSecArr[i];
        if (element.childNodes.length === 0) {
          element.parentNode.parentNode.parentNode.parentNode.style.display =
            "none";
        }
      }
    }
  }, [productApiData]);

  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  return (
    <>
      <Sidebar navContWrapp={navContWrapp} />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper  ${SmallWrapper}`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to="/dashboard">Dashboard</Link>{" "}
              {/* <i className='fa-solid fa-angle-right'></i> Home{' '} */}
              {/* <i className='fa-solid fa-angle-right'></i> Listing{' '} */}
              <i className="fa-solid fa-angle-right"></i> Know Products
            </p>
          </div>
        </div>

        {/* {dashContent &&
          dashContent !== 'undefined' &&
          dashContent?.map((productItems, index) => {
            return (
              <section className='xpand-acandemy pb-2 pt-0 inner-bg' key={index+3}>
                <div className='container container-wrapper'>
                  <div className='row inner-content'>
                    {Object.keys(productItems)?.map((itemName, index) => {
                      return (
                        <>
                          <div className='col-12 about-product-inner' key={index}>
                            <div className='mt-4 about-products'>
                              <div className='sec-title sec-title_list'>
                                <h2>About {unslugify(itemName)}</h2>
                              </div>
                            </div>
                          </div>
                          {productItems[itemName]?.map((items, index) => {
                            // if(!uniqueData.includes(items?.topic)) {
                            //   uniqueData.push(items?.topic)
                              return (
                                <div
                                  className='col-xl-4 col-md-6 pro_del_card col_details mb-1'
                                  key={index + 1}
                                >
                                  <DetailsCard
                                    key={index + 2}
                                    cardTitle={items?.topic}
                                    cardDesc={items?.description}
                                    pdfLink={`/dashboard/details/${items?._id}`}
                                    videoLink={`/dashboard/details/${items?._id}`}
                                    cardImg={serverPath + items?.image}
                                    seeMore={`/dashboard/details/${items?._id}`}
                                    pdfCounter={items?.pdf?.length}
                                    VideoCounter={items?.videos?.length}
                                    raiseQueryLink={''}
                                  />
                                </div>
                              )
                            // } 
                          })}
                          {productItems[itemName]?.length === 0 && (
                            <div className='text-secondary'>
                              Data Not Available
                            </div>
                          )}
                        </>
                      )
                    })}
                  </div>
                </div>
              </section>
            )
          })}
        {productApiData?.isLoading && <SkeletonLoader />} */}

        {dashContent &&
          dashContent?.length !== 0 &&
          dashContent?.map((productItems, index) => {
            return (
              <>
                {Object.keys(productItems)?.map((itemName, index) => {
                  // if(productItems[itemName].length === 0) {
                  //   key=0
                  // } else {
                  //   key=1
                  // }
                  return (
                    <>
                      <section
                        // className={`about-product pb-2 pt-0 inner-bg ${key===0?'d-none':'d-block'}`}
                        className={`about-product prod_div_sec pb-2 pt-0 inner-bg`}
                        key={index}
                      >
                        <div className="container container-wrapper">
                          <div className="row inner-content">
                            <div className="col-12 about-product-inner">
                              <div className="mt-2 about-products">
                                <div className="sec-title sec-title_list">
                                  <h2 className="text-capitalize">
                                    About {unslugify(itemName)}
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div
                                className={`row dash_slider_content_card about-product-inner-2 slider-container prod_col_cont prod_col_cont_${index}`}
                              >
                                {productItems[itemName]?.map((items, index) => {
                                  if (
                                    !uniqueData.includes(
                                      items?.contentId?.topic
                                    )
                                  ) {
                                    uniqueData.push(items?.contentId?.topic);
                                    return (
                                      <div
                                        className="col-xl-4 col-md-6 pro_del_card col_details mb-1"
                                        key={index + 2}
                                      >
                                        <DetailsCard
                                          key={index}
                                          cardTitle={items?.contentId?.topic}
                                          cardDesc={
                                            items?.contentId?.description
                                          }
                                          pdfLink={`/dashboard/details/${items?.contentId?._id}`}
                                          videoLink={`/dashboard/details/${items?.contentId?._id}`}
                                          cardImg={
                                            serverPath + items?.contentId?.image
                                          }
                                          seeMore={`/dashboard/details/${items?.contentId?._id}`}
                                          pdfCounter={
                                            items?.contentId?.pdf?.length
                                          }
                                          VideoCounter={
                                            items?.contentId?.videos?.length
                                          }
                                          productId={items?.product}
                                          topcId={items?.contentId?._id}
                                        />
                                      </div>
                                    );
                                  }
                                })}
                                {productApiData?.isLoading && (
                                  <SkeletonLoader />
                                )}
                                {/* {dashContent?.length === 0 && (
                                  <div className='text-secondary'>
                                    Data Not Available
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </>
                  );
                })}
              </>
            );
          })}
        {productApiData?.isLoading && <SkeletonLoader />}

        {dashContent && dashContent?.length === 0 && (
          <div className="container container-wrapper">
            <div
              className="row details-card-wrapper inner-content details-page-card inner-bg"
              style={{ background: "aliceblue" }}
            >
              <div className="col-12 pb-0 dash_slider_content_card about-product-inner-2 slider-container">
                <div className="text-secondary">
                  Products Data is Not Available
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
    </>
  );
}

export default DetailsPage;
