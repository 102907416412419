import React from 'react'

function NumOfCP (props) {
  return (
    <>
      <div className='franchise_amnt_dashboardInfo land_card_1'>
        <div className='wrap'>
          <div className='franchise_inve'>
            <div className='card_6_cont'>
              <div className='franchise_inve_content mb-3'>
                <h6 className='inve_cont_main text-start'>{props.name}</h6>
                <p className='inve_cont_main'>{props.totalCP}</p>
              </div>
              <div className='franchise_inve_content'>
                <h6>Equity </h6>
                <p>{props.eqClients}</p>
              </div>
              <div className='franchise_inve_content'>
                <h6>Mutual Funds </h6>
                <p>{props.SIPClients}</p>
              </div>
              <div className='franchise_inve_content'>
                <h6>Real Estate </h6>
                <p>{props.RECLients}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NumOfCP
