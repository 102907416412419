import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRegisterNowApiMutation } from "../../store/services/registerApiService";
import '../../styles/inquiryFormStyle.css'
import { Api_Base_Url } from "../../constant/matcher";

function RegisterNowPopup(props) {
  const userData = useSelector((state) => state.userReducer.userData);
  const [checkValidation, setCheckValidation] = useState("");

  const panRef = useRef();
  const adharRef = useRef();
  const signRef = useRef();
  const chequeRef = useRef();

  const [panLabel, setPanLabel] = useState("No file Chosen");
  const [aadharLabel, setAadharLabel] = useState("No file Chosen");
  const [chequeLabel, setChequeLabel] = useState("No file Chosen");
  const [signLabel, setSignLabel] = useState("No file Chosen");
  const [isDisableBtn, setIsDisableBtn] = useState(true);

  const panCapitalize = () => {
    const panVal = document.getElementById("panNumber");
    if (panVal) {
      panVal.value = panVal.value.toUpperCase();
    }
  };

  const [successStatus, setSuccessStatus] = useState("fade");
  const closeSuccessModal = () => {
    setSuccessStatus("fade");
    props.closeRegPopup();
  };

  const [selectedFile, setSelectedFile] = useState({
    panNumber: "",
    address: "",
    panCard: "",
    aadharCard: "",
    cheque: "",
    signature: "",
    leadUserId: "",
    termsContidions: "",
  });
  const fileChangeHandler = (e) => {
    let filename = e.target.files;
    setSelectedFile({ ...selectedFile, [e.target.name]: filename[0] });
    // to set filename in dummy inputs
    e.target.name === "panCard" && setPanLabel(filename[0].name);
    e.target.name === "aadharCard" && setAadharLabel(filename[0].name);
    e.target.name === "signature" && setSignLabel(filename[0].name);
    e.target.name === "cheque" && setChequeLabel(filename[0].name);
  };

  useEffect(() => {
    setSelectedFile({ ...selectedFile, leadUserId: userData._id });
  }, [userData]);

  // register now api
  const [postRegData, resp = {}] = useRegisterNowApiMutation();

  useEffect(() => {
    if (resp && !resp?.isLoading && resp?.isSuccess && resp?.data?.success) {
      setSuccessStatus("d-flex");
    }
  }, [resp]);

  const regSubmitHandler = (e) => {
    // console.log("clicked")
    e.preventDefault();
    if (selectedFile.termsContidions === true) {
      setCheckValidation("");
      const panRegex = /^(?=.{1,10}$)[A-Z]{5}[0-9]{1,4}[A-Z]{1}$/;
      if (!panRegex.test(selectedFile.panNumber)) {
        setCheckValidation("Please enter a valid PAN number");
        setTimeout(() => {
          setCheckValidation("");
        }, 5000);
        return;
      }
      const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
      const leadUserId = loginUserData?.lmsId;;

      // Make the API request to check if PAN number already exists
      const queryParams = `?pan=${encodeURIComponent(selectedFile.panNumber)}&leadUserId=${encodeURIComponent(leadUserId)}`;
      const apiUrl = `${Api_Base_Url}/register/checkexistpan${queryParams}`
      fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
            .then((result) => {
              // Check if PAN number already exists
              if (result.success === false) {
                setCheckValidation('PAN Number already registered');
                setTimeout(() => {
                  setCheckValidation("");
                }, 5000);
                return
              } 
              else {
      const formData = new FormData();
      formData.append("panNumber", selectedFile.panNumber);
      formData.append("address", selectedFile.address);
      formData.append("panCard", selectedFile.panCard);
      formData.append("aadharCard", selectedFile.aadharCard);
      formData.append("cheque", selectedFile.cheque);
      formData.append("signature", selectedFile.signature);
      formData.append("leadUserId", selectedFile.leadUserId);

      // console.log("submit", formData);
      // post formdata in api
      postRegData(formData);
      // console.log(formData)
    }
  })
      } 
     else {
      setCheckValidation("Please accept terms & conditions");
      setTimeout(() => {
        setCheckValidation("");
      }, 5000);
    }
  };

  useEffect(() => {
    const element = document.getElementById("invester_register");

    // Check if all required fields are filled and terms & conditions are accepted
    if (
      selectedFile.panNumber !== "" &&
      selectedFile.address !== "" &&
      selectedFile.termsContidions === true
    ) {
      // Enable the "View Agreement" button only when all conditions are met
      setIsDisableBtn(false);
      element.style.backgroundColor = "#ff4b2b";
    } else {
      // Disable the "View Agreement" button only when all conditions are met
      setIsDisableBtn(true);
      element.style.backgroundColor = "grey";
      element.style.borderColor = "grey";
    }
  }, [selectedFile]);

  return (
    <>
      <div className="form_popups popup__container">
        <div className="chagne_pass_form">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="">
                  <div
                    className="formByScan_container pt-2 position-relative"
                    id="formByScan_container"
                    style={{ overflow: "visible" }}
                  >
                    <div
                      className="popup_close_icon"
                      onClick={props.closeRegPopup}
                    >
                      <i
                        className="fa fa-xmark"
                        onClick={props.closeRegPopup}
                      ></i>
                    </div>
                    <form
                      autoComplete="off"
                      encType="multipart/form-data"
                      className="registerNowForm"
                      onSubmit={regSubmitHandler}
                    >
                      <h2
                        className="form-heading text-center"
                        style={{ fontSize: "22px" }}
                      >
                        Register Now
                      </h2>
                      {/* <marquee className=''>
                      <p className='text-capitalize mb-0 text-danger'>
                        Upload the Below documents (self-attested)
                      </p>
                    </marquee> */}
                      {checkValidation !== "" && (
                        <p className="text-danger text-center">
                          {checkValidation}
                        </p>
                      )}
                      <label>
                        Enter Your PAN Number{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="PAN Number*"
                        required
                        autoComplete="new-password"
                        name="panNumber"
                        id="panNumber"
                        onKeyUp={panCapitalize}
                        value={selectedFile.panNumber}
                        onChange={(e) =>
                          setSelectedFile({
                            ...selectedFile,
                            panNumber: e.target.value,
                          })
                        }
                      />
                      <label>
                        Enter Your Address{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Address*"
                        required
                        name="address"
                        id="address"
                        //   onKeyUp={panCapitalize}
                        value={selectedFile.address}
                        onChange={(e) =>
                          setSelectedFile({
                            ...selectedFile,
                            address: e.target.value,
                          })
                        }
                      />
                      <div className="fileInputWrapper">
                        <label>Upload Signature Image</label>
                        {/* hidden input fields */}
                        <input
                          type="file"
                          placeholder="Upload PAN Card"
                          name="signature"
                          accept="image/*"
                          ref={signRef}
                          className="regFileInputs"
                          // value={selectedFile.sign}
                          onChange={fileChangeHandler}
                        />
                        {/* dummy input for file inputs */}
                        <div className="dummyInput">
                          <div className="dummyInputPlaceholder">
                            <span className="panFile">{signLabel}</span>
                          </div>
                          <div
                            className="fileIcon btn"
                            onClick={() => signRef.current.click()}
                          >
                            Browse
                          </div>
                        </div>
                      </div>
                      <div className="fileInputWrapper">
                        <label>Upload PAN Image</label>
                        <input
                          type="file"
                          placeholder="Upload PAN Card"
                          name="panCard"
                          accept="image/*"
                          ref={panRef}
                          className="regFileInputs"
                          // value={selectedFile.panImage}
                          onChange={fileChangeHandler}
                        />
                        <div className="dummyInput">
                          <div className="dummyInputPlaceholder">
                            <span className="panFile">{panLabel}</span>
                          </div>
                          <div
                            className="fileIcon btn"
                            onClick={() => panRef.current.click()}
                          >
                            Browse
                          </div>
                        </div>
                      </div>
                      <div className="fileInputWrapper">
                        <label>Upload Aadhar Image</label>
                        <input
                          type="file"
                          placeholder="Upload PAN Card"
                          name="aadharCard"
                          accept="image/*"
                          ref={adharRef}
                          className="regFileInputs"
                          // value={selectedFile.aadhar}
                          onChange={fileChangeHandler}
                        />
                        <div className="dummyInput">
                          <div className="dummyInputPlaceholder">
                            <span className="panFile">{aadharLabel}</span>
                          </div>
                          <div
                            className="fileIcon btn"
                            onClick={() => adharRef.current.click()}
                          >
                            Browse
                          </div>
                        </div>
                      </div>
                      <div className="fileInputWrapper">
                        <label>Upload Cancelled Cheque</label>
                        {/* hidden input field */}
                        <input
                          type="file"
                          placeholder="Upload PAN Card"
                          name="cheque"
                          accept="image/*"
                          ref={chequeRef}
                          className="regFileInputs"
                          // value={selectedFile.cheque}
                          onChange={fileChangeHandler}
                        />
                        {/* dummy input for file */}
                        <div className="dummyInput">
                          <div className="dummyInputPlaceholder">
                            <span className="panFile">{chequeLabel}</span>
                          </div>
                          <div
                            className="fileIcon btn"
                            onClick={() => chequeRef.current.click()}
                          >
                            Browse
                          </div>
                        </div>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="termsContidions"
                          id="t&c"
                          required
                          // value={selectedFile.termsContidions}
                          onChange={(e) =>
                            setSelectedFile({
                              ...selectedFile,
                              termsContidions: e.target.checked,
                            })
                          }
                        />
                        <label
                          className="termsLabel form-check-label"
                          htmlFor="terms"
                        >
                          Accept Terms & Conditions{" "}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      {/* <div className="agreement__doc btn text-white" style={{background:'#00ad51'}}>
                        View Agreemnet
                      </div> */}
                      <div className="mt-2 text-center">
                        <button className=" subBtn"
                        id="invester_register"
                         disabled={isDisableBtn}
                        >Register</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <SuccessModal successStatus={'d-flex'} /> */}
        <div
          className={`modal custom-modal align-items-center ${successStatus}`}
          id="formSuccessModal"
          style={{ background: "rgba(0,0,0,0.3)" }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="formSuccessModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header " style={{ background: "#FF4B2B" }}>
                <h5 className="modal-title" id="formSuccessModalLabel">
                  Success
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeSuccessModal}
                  // data-bs-dismiss='modal'
                  // aria-label='Close'
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-popup-inner">
                  <div className="form-icon success">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 130.2 130.2"
                    >
                      <circle
                        className="path circle"
                        fill="none"
                        strokeWidth="6"
                        strokeMiterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <polyline
                        className="path check"
                        fill="none"
                        strokeWidth="6"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="100.2,40.2 51.5,88.8 29.8,67.5 "
                      />
                    </svg>
                  </div>
                  <div className="form-body">
                    <p>
                      Thank you for filling out our registration form. We are
                      glad that you joined us.
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="popup-btn "
                  style={{ background: "#FF4B2B" }}
                  // data-bs-dismiss='modal'
                  onClick={closeSuccessModal}
                >
                  Okay
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterNowPopup;
