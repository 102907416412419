import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { useGetCityListApiQuery } from '../../../store/services/loginService.js'

function SubCategory ({ formData, setFormData }) {
  // api for select options
  const [selectedCity, setSelectedCity] = useState(null)

  const cityResp = useGetCityListApiQuery()
  const cityFetchedData =
    cityResp?.isSuccess &&
    !cityResp?.isLoading &&
    cityResp.status === 'fulfilled' &&
    !cityResp?.isError &&
    cityResp?.data?.cities

  const searchOptions =
    cityFetchedData &&
    cityFetchedData?.map(({ city, _id }) => ({
      label: city,
      value: _id
    }))

    useEffect(() => {
      setFormData({ ...formData, city: selectedCity?.value })
    }, [selectedCity]);
  
  return (
    <>
      <div className='row my-3'>
        {/* <label>Select Your City</label> */}
        <div
          className='autoCompleteCity form-group'
          // style={{ marginTop: '5px', marginBottom: '2px' }}
        >
          <Select
            className='basic-single w-100'
            classNamePrefix='select'
            defaultValue={formData}
            onChange={setSelectedCity}
            isClearable={true}
            placeholder={'City'}
            isSearchable={true}
            name='city'
            options={searchOptions?searchOptions:[]}
          />
        </div>
      </div>
    </>
  )
}

export default SubCategory
