import React, { useState, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginScreen from "../pages/LoginScreen";
import Dashboard from "../pages/dashboard/Dashboard";
import DetailsPage from "../pages/dashboard/DetailsPage";
import DetailsPageProduct from "../pages/dashboard/DetailsPageProduct";
import ListingPage from "../pages/dashboard/ListingPage";
import ChannelPartner from "../pages/ChannelPartner";
import Homepage from "../pages/Homepage";
import LandingPage from "../pages/franchise/LandingPage";
import FormByScan from "../pages/FormByScan";
import EmployeeLogin from "../pages/EmployeeLogin";
import PrivateRoute from "../routes/PrivateRoute";
import PrivateRouteEmployee from "../routes/PrivateRouteEmployee";
import PublicRouteEmployee from "./PublicRouteEmployee";
import PublicRoutePre from "./PublicRoutePre";
import ProfilePage from "../pages/dashboard/ProfilePage";
import ProfilePageFranchise from "../pages/franchise/ProfilePage";
import RmProfilePage from "../pages/franchise/RM/RmProfilePage";
import UpdateProfile from "../pages/dashboard/UpdateProfile";
import XContent from "../pages/franchise/XContent";
import XContentdetail from "../pages/franchise/XContentdetail";
import InvestorDashboard from "../pages/investor/InvestorDashboard";
import MyClients from "../pages/franchise/MyClients.js";
import MyCP from "../pages/franchise/MyCP";
import FormDownload from "../pages/franchise/FormDownload";
import LandingPageRm from "../pages/franchise/RM/LandingPageRm";
import CreativePage from "../pages/franchise/CreativePage";
import MyQueries from "../pages/dashboard/MyQueries";
import LeadDetails from "../pages/franchise/Lms/LeadDetails";
import AllLeads from "../pages/franchise/Lms/AllLeads";
import LeadDetailsTabPage from "../pages/franchise/Lms/LeadDetailsTabPage";
import { useSelector } from "react-redux";
import XpandSupport from "../pages/franchise/XpandSupportCp";
import SupportTickets from "../components/franchise/rm/SupportTickets";
import InvAllLead from "../pages/franchise/Lms/InvAllLead";
import RegisterBusiness from "../pages/RegisterBusiness";
import LPQuery from "../components/franchise/rm/LPQuery";
import Helpdesk from "../components/franchise/rm/Helpdesk";
import ForgetPassword from "../components/ForgetPassword"
import BusinessPlan from "../pages/BusinessPlan";
import CPDetails from "../pages/CPDetails";
import CPAllLead from "../pages/CPAllLead";
import InvDetails from "../pages/InvDetails";
import InvProfile from "../components/investor/InvProfile";
import Investorprofilepage from "../components/investor/Investorprofilepage";
import Xcontent from "../components/investor/Xcontent";
import InvSupport from "../components/investor/InvSupport";
import EquityRequest from "../pages/EquityRequest";
import FranchiseDashboard from "../pages/franchise/FranchiseDashboard";
import FMProfile from "../pages/franchise/FMProfile";
import BusinessDesign from "../pages/BusinessDesign";
import RMBusinessDesign from "../pages/RMBusinessDesign";
import RevenueUpdate from "../pages/RevenueUpdate.js";
import RealStateList from "../pages/investor/RealStateList.js";
import RealStateDetails from "../pages/investor/RealStateDetails.js";

function Routing() {
   // login response data from redux state
   const userData = useSelector((state) => state?.userReducer?.userData);
   const userTokenRM = useSelector(state => state.userReducer.userTokenFmRm)
   const userToken = useSelector(state => state.userReducer.userToken)
   const [dynamicRoute, setdynamicRoute] = useState();
   // set dynamic url for client login of cp and fm
   useEffect(() => {
      if (userData?.role !== "investor" && userData?.role !== "rm" &&  userData?.role !== "fm") {
         setdynamicRoute("/" + userData?.role);
      }
   }, [userData]);
   return (
      <>
         {/* <BrowserRouter basename='xpandone/'> */}
         <BrowserRouter>
            <Routes>
               {/* front dashboard routes */}
               {
                  userData?.role === "rm" ? (
                     <Route exact path="/" element={userTokenRM ? <Navigate to={`/team/rm`} /> : <ChannelPartner />} />
                  )
                  : 
                  (
                     <Route exact path="/" element={userToken ? (
                        userData?.role ? (
                           <Navigate to={`/dashboard/${userData?.role}`} />
                        )
                        : 
                        (
                           <Navigate to="/dashboard" />
                        )
                     ) 
                     :( <ChannelPartner />)} />
                  )
               }
               <Route exact path="/investor" element={<Homepage />} />
               <Route path="form-by-scan" element={<FormByScan />} />
               <Route
                  path="/login/:loginuser"
                  element={
                     <PublicRoutePre>
                        <LoginScreen />
                     </PublicRoutePre>
                  }
               />
               <Route
                  path="/login/team"
                  element={
                     <PublicRouteEmployee>
                        <EmployeeLogin />
                     </PublicRouteEmployee>
                  }
               />
                  <Route
                  path="/login/team/forgot-pass"
                  element={
                     <PublicRouteEmployee>
                        <ForgetPassword />
                     </PublicRouteEmployee>
                  }
               />
               ForgetPassword
               {/* <Route path='/inquiry' element={<InquiryPage />} /> */}

               {/* prelead dashboard routes */}
               <Route path="/dashboard">
                  <Route
                     path=""
                     element={
                        <PrivateRoute>
                           <Dashboard />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="listing-xpand"
                     element={
                        <PrivateRoute>
                           <DetailsPage />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="listing-product"
                     element={
                        <PrivateRoute>
                           <DetailsPageProduct />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="profile"
                     element={
                        <PrivateRoute>
                           <ProfilePage />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="my-queries"
                     element={
                        <PrivateRoute>
                           <MyQueries />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="details/:categoryid"
                     element={
                        <PrivateRoute>
                           <ListingPage />
                        </PrivateRoute>
                     }
                  />
               </Route>

               {/* dashboard clients routes */}
               <Route path={`/dashboard${dynamicRoute}`}>
                  <Route
                     path=""
                     element={
                        <PrivateRoute>
                           <LandingPage />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="x-content"
                     element={
                        <PrivateRoute>
                           <XContent />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="profile"
                     element={
                        <PrivateRoute>
                           <ProfilePageFranchise />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="my-clients"
                     element={
                        <PrivateRoute>
                           <MyClients />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="my-clients/:_id/:clintcode"
                     element={
                        <PrivateRoute>
                           <InvDetails/>
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="my-cp"
                     element={
                        <PrivateRoute>
                           <MyCP />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="revenue"
                     element={
                        <PrivateRoute>
                          <RevenueUpdate/>
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="form-download"
                     element={
                        <PrivateRoute>
                           <FormDownload />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="xpandsupport"
                     element={
                        <PrivateRoute>
                           {" "}
                           <XpandSupport />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="creative-download"
                     element={
                        <PrivateRoute>
                           <CreativePage />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="my-leads"
                     element={
                        <PrivateRoute>
                           <AllLeads />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="my-invleads"
                     element={
                        <PrivateRoute>
                         <CPAllLead/>
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="my-leads/:leadid"
                     element={
                        <PrivateRoute>
                           <LeadDetails />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="my-invleads/:leadid"
                     element={
                        <PrivateRoute>
                           <LeadDetails />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="register-business"
                     element={
                        <PrivateRoute>
                           <RegisterBusiness />
                        </PrivateRoute>
                     }
                  />
                   <Route
                     path="register-business"
                     element={
                        <PrivateRoute>
                           <RegisterBusiness />
                           </PrivateRoute>
                     }
                  />
                    <Route
                     path="business-plan"
                     element={
                        <PrivateRoute>
                          <BusinessPlan/>
                          </PrivateRoute>
                     }
                  />
                   <Route
                     path="business-design"
                     element={
                        <PrivateRoute>
                           <BusinessDesign/>
                        </PrivateRoute>
                      
                     }
                  />

               </Route>

               {/* Investor client Dashboard */}
               <Route path="/dashboard/investor">
                  <Route
                     path=""
                     element={
                        <PrivateRoute>
                           <InvestorDashboard />
                        </PrivateRoute>
                     }
                  />
                   <Route
                     path="invprofile"
                     element={
                        <PrivateRoute>
                          <Investorprofilepage/>
                        </PrivateRoute>
                     }
                  />
                   <Route
                     path="x-content"
                     element={
                        <PrivateRoute>
                          <Xcontent/>
                        </PrivateRoute>
                     }
                  />
                   <Route
                     path="x-support"
                     element={
                        <PrivateRoute>
                         <InvSupport/>
                        </PrivateRoute>
                     }
                  />
                         <Route
                     path="real-estate"
                     element={
                        <PrivateRoute>
                          <RealStateList/>
                        </PrivateRoute>
                      
                     }
                  />
                   <Route
                     path="real-estate/:clientcode/:propertyCode"
                     element={
                        <PrivateRoute>
                         <RealStateDetails/>
                        </PrivateRoute>
                      
                     }
                  />
               </Route>
               {/* Franchise dashboard routes */}
               <Route path={`/dashboard/fm`}>
            <Route path=""
                element={
                <PrivateRoute>
                     <FranchiseDashboard/>
                  </PrivateRoute>
                }
                
               />
                   <Route
                     path="xpandsupport"
                     element={
                        <PrivateRoute>
                           {" "}
                          <XpandSupport/>
                        </PrivateRoute>
                     }
                  />
                   <Route
                     path="register-business"
                     element={
                        <PrivateRoute>
                           <RegisterBusiness />
                           </PrivateRoute>
                     }
                  />
                  <Route
                     path="business-plan"
                     element={
                        <PrivateRoute>
                          <BusinessPlan/>
                          </PrivateRoute>
                     }
                  />
                  <Route
                  path="profile"
                  element={
                     <PrivateRoute>
                        <FMProfile/>
                     </PrivateRoute>
                  }
                  />
                  <Route
                     path="my-clients"
                     element={
                        <PrivateRoute>
                           <MyClients />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="my-cp"
                     element={
                        <PrivateRoute>
                           <MyCP />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="form-download"
                     element={
                        <PrivateRoute>
                           <FormDownload />
                        </PrivateRoute>
                     }
                  />
                 
                  <Route
                     path="creative-download"
                     element={
                        <PrivateRoute>
                           <CreativePage />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="x-content"
                     element={
                        <PrivateRoute>
                           <XContent />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="my-leads"
                     element={
                        <PrivateRoute>
                           <AllLeads />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="my-invleads"
                     element={
                        <PrivateRoute>
                        <InvAllLead/>
                        </PrivateRoute>
                     }
                  />
                   <Route
                     path="business-design"
                     element={
                        <PrivateRoute>
                           <BusinessDesign/>
                        </PrivateRoute>
                      
                     }
                  />
                   <Route
                     path="my-leads/:leadid"
                     element={
                        <PrivateRoute>
                           <LeadDetails />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path="my-invleads/:leadid"
                     element={
                        <PrivateRoute>
                           <LeadDetails />
                        </PrivateRoute>
                     }
                  />
                   <Route
                     path="my-clients/:_id"
                     element={
                        <PrivateRoute>
                           <InvDetails/>
                        </PrivateRoute>
                     }
                  />
                   <Route
                     path="my-leads/:leadid"
                     element={
                        <PrivateRoute>
                           <LeadDetails />
                        </PrivateRoute>
                     }
                  />
                   <Route
                     path="my-leads/:leadid/:usertype"
                     element={
                        <PrivateRoute>
                           <LeadDetails />
                           </PrivateRoute>
                     }
                  />
                   <Route
                     path="my-invleads/:leadid/:usertype"
                     element={
                        <PrivateRoute>
                           <LeadDetails />
                           </PrivateRoute>
                     }
                  />
               </Route>
               {/* Employee dashboard routes */}
               <Route path="/team/rm">
                  <Route
                     path=""
                     exact
                     element={
                        <PrivateRouteEmployee>
                           <LandingPageRm />
                        </PrivateRouteEmployee>
                     }
                  />
                  <Route
                     path="x-content"
                     element={
                        <PrivateRouteEmployee>
                           <XContent />
                        </PrivateRouteEmployee>
                     }
                  />
                  <Route
                     path="listingdetail/:categoryid"
                     element={<XContentdetail />}
                  />
                  <Route
                     path="profile"
                     element={
                        <PrivateRouteEmployee>
                           <RmProfilePage />
                        </PrivateRouteEmployee>
                     }
                  />
                  <Route
                     path="my-clients"
                     element={
                        <PrivateRouteEmployee>
                           <MyClients />
                        </PrivateRouteEmployee>
                     }
                  />
                  <Route
                     path="my-cp"
                     element={
                        <PrivateRouteEmployee>
                           <MyCP />
                        </PrivateRouteEmployee>
                     }
                  />
                  <Route
                     path="form-download"
                     element={
                        <PrivateRouteEmployee>
                           <FormDownload />
                        </PrivateRouteEmployee>
                     }
                  />
                  <Route
                     path="creative-download"
                     element={
                        <PrivateRouteEmployee>
                           <CreativePage />
                        </PrivateRouteEmployee>
                     }
                  />
                  <Route
                     path="support-ticket"
                     element={
                        <PrivateRouteEmployee>
                           {" "}
                           <SupportTickets />{" "}
                        </PrivateRouteEmployee>
                     }
                  />
                   <Route
                     path="lpquery"
                     element={
                        <PrivateRouteEmployee>
                           {" "}
                          <LPQuery/>
                        </PrivateRouteEmployee>
                     }
                  />
                   <Route
                     path="help-desk"
                     element={
                        <PrivateRouteEmployee>
                           {" "}
                          <Helpdesk/>
                        </PrivateRouteEmployee>
                     }
                  />
                  <Route
                     path="my-leads"
                     element={
                        <PrivateRouteEmployee>
                           <AllLeads />
                        </PrivateRouteEmployee>
                     }
                  />
                  <Route
                     path="my-invleads"
                     element={
                        <PrivateRouteEmployee>
                           <InvAllLead />
                        </PrivateRouteEmployee>
                     }
                  />
                  <Route
                     path="my-leads/:leadid/:usertype"
                     element={
                        <PrivateRouteEmployee>
                           <LeadDetails />
                        </PrivateRouteEmployee>
                     }
                  />
                  <Route
                     path="my-invleads/:leadid/:usertype"
                     element={
                        <PrivateRouteEmployee>
                           <LeadDetails />
                        </PrivateRouteEmployee>
                     }
                  />
                  <Route
                     path="register-business"
                     element={
                        <PrivateRouteEmployee>
                           <RegisterBusiness />
                        </PrivateRouteEmployee>
                     }
                  />
                    <Route
                     path="business-plan"
                     element={
                        <PrivateRouteEmployee>
                          <BusinessPlan/>
                        </PrivateRouteEmployee>
                     }
                  />
                    <Route
                     path="rmbusiness-design"
                     element={
                        <PrivateRouteEmployee>
                         <RMBusinessDesign/>
                        </PrivateRouteEmployee>
                     }
                  />
                  <Route
                     path="equity"
                     element={
                        <PrivateRouteEmployee>
                          <EquityRequest/>
                        </PrivateRouteEmployee>
                     }
                  />
                   <Route
                     path="my-cp/:_id/:role"
                     element={
                        <PrivateRouteEmployee>
                          <CPDetails/>
                        </PrivateRouteEmployee>
                     }
                  />
                   <Route
                     path="my-clients/:_id/:clintcode"
                     element={
                        <PrivateRouteEmployee>
                          <InvDetails/>
                        </PrivateRouteEmployee>
                     }
                  />
                   <Route
                     path="revenue"
                     element={
                        <PrivateRouteEmployee>
                          <RevenueUpdate/>
                        </PrivateRouteEmployee>
                     }
                  />
                  
               </Route>
             
               
               {/* ============================================================================ */}
               {/* =====Testing Investor ROUTES======= */}
               <Route
                  path="/tabs"
                  element={
                     <>
                        <LeadDetailsTabPage />
                     </>
                  }
               />
               <Route
                  path="update-profile"
                  element={
                     <>
                        <UpdateProfile />
                     </>
                  }
               />
            </Routes>
         </BrowserRouter>
      </>
   );
}

export default Routing;
