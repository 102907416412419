import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PUBLIC_PATH } from "../../constant/matcher";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import DashboardBanner from "../../components/general/DashboardBanner";
import MyClientsCards from "../../components/cardsDesigns/MyClientsCards";
import {
  useProductSelectQuery,
  useGetCityListApiQuery,
} from "../../store/services/loginService";
import { useMyRaiseQueryApiQuery } from "../../store/services/registerApiService";
import DiscussionForum from "../../components/general/DiscussionForum";
import MyClientCard from "../../components/cardsDesigns/MyClientCard";

function MyQueries() {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);

  const [queryPopup, setQueryPopup] = useState(false);
  const [navShow, setNavShow] = useState(true);
  const [selectedQueryId, setSelectedQueryId] = useState(null);
  const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
  let lmsuserid = loginUserData._id
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  // close popup on esc key
  window.addEventListener("keyup", function (e) {
    var key = e.which || e.keyCode;
    if (key === 27) {
      setQueryPopup(false);
    }
  });

  // chatpopu handeler
  const chatPopuHandler = (queryId) => {
    // console.log("Opening popup for item ID:", queryId);
    setSelectedQueryId(queryId);
    setQueryPopup(true);
  };

  // close discussion chat popup
  const closeChatPopup = () => {
    setQueryPopup(false);
  };

  // api for Get Products
  const productApi = useProductSelectQuery();
  var productApiData =
    productApi?.isSuccess &&
    !productApi?.isLoading &&
    productApi.status === "fulfilled" &&
    !productApi?.isError &&
    productApi?.data?.productsList;

  // Funtion to Get Product Name By its ID
  const productNameById = (productId) => {
    let productName = [];
    let arr = [];
    for (let i = 0; i < productId?.length; i++) {
      arr =
        productApiData && productApiData?.find((el) => el._id === productId[i]);
      if (arr !== false) productName.push(arr?.name);
    }
    return productName.length > 0
      ? productName[0] +
          (productName.length > 1
            ? "+" + (productName.length - 1).toString()
            : "")
      : "";
  };
  // ++=====Function For Product Toolpit========
  const productToolpit = (productId) => {
    let productString = "";
    let productName = [];
    let arr = [];
    for (let i = 0; i < productId?.length; i++) {
      arr =
        productApiData && productApiData?.find((el) => el._id === productId[i]);
      if (arr !== false) productName.push(arr?.name);
    }
    return productName.length > 0
      ? productName.map((name, index) => {
          productString = name;
          return productString;
        })
      : "";
  };

  // Get CITY API
  const cityResp = useGetCityListApiQuery();
  const cityFetchedData =
    cityResp?.isSuccess &&
    !cityResp?.isLoading &&
    cityResp.status === "fulfilled" &&
    !cityResp?.isError &&
    cityResp?.data?.cities;
  // Funtion to Get City Name By its Id
  const cityNameById = (cityId) => {
    let city = "";
    for (let i = 0; i < 1; i++) {
      city =
        cityFetchedData && cityFetchedData?.find((el) => el._id === cityId);
      if (city !== false) return city?.city;
    }
  };

  // My CLients Api
  const [myQueries, setMyQueries] = useState(null);
  const myQueryResp = useMyRaiseQueryApiQuery(loggedInUserData?._id);

  useEffect(() => {
    // console.log(myQueryResp);
    if (
      myQueryResp?.isSuccess &&
      !myQueryResp?.isLoading &&
      !myQueryResp?.isError &&
      myQueryResp?.data?.success
    ) {
      setMyQueries(myQueryResp?.data?.myQuery);
    }
  }, [myQueryResp]);

  return (
    <>
      <Sidebar navContWrapp={navContWrapp} />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        {/* banner sec */}
        {/* banner sec */}
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to="/dashboard">Dashboard</Link>{" "}
              <i className="fa-solid fa-angle-right"></i> My Queries
            </p>
          </div>
        </div>

        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          style={{ background: "whitesmoke" }}
        >
          <div className="row mb-4">
            <div className="col-md-3 mb-2">
              <MyClientCard
                cardTitle={"In Progress"}
                cardValues={"0"}
                updateTime={"Since last month"}
                borderColor={1}
                bgGradient={1}
                textColor={1}
                iconsNumb={1}
              />
            </div>
            <div className="col-md-3 mb-2">
              <MyClientCard
                cardTitle={"In Progress"}
                cardValues={"0"}
                updateTime={"Since last month"}
                borderColor={1}
                bgGradient={1}
                textColor={1}
                iconsNumb={1}
              />
            </div>
            <div className="col-md-3 mb-2">
              <MyClientCard
                cardTitle={"Completed"}
                cardValues={"0"}
                updateTime={"Since last week"}
                borderColor={2}
                bgGradient={2}
                textColor={2}
                iconsNumb={2}
              />
            </div>
            <div className="col-md-3 mb-2">
              <MyClientCard
                cardTitle={"Total Queries"}
                cardValues={myQueries ? myQueries?.length : 0}
                updateTime={"Since last week"}
                borderColor={4}
                bgGradient={4}
                textColor={4}
                iconsNumb={4}
              />
            </div>
          </div>
          <div className="row table__row__wrapp">
            <div className="client_table_container py-2">
              <h2>My Queries</h2>
              <div className="w-100 overflow-auto">
                <table className="table table-striped client__table">
                  <thead>
                    <tr>
                      <th scope="col">Sr No.</th>
                      <th scope="col">Query Number</th>
                      <th scope="col">Query Description</th>
                      <th scope="col">View Query</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myQueries &&
                      myQueries?.length > 0 &&
                      myQueries?.map((item, index) => {
                        return (
                          <>
                            <tr key={item?._id}>
                              <td>{index + 1}</td>
                              <td>{item?._id}</td>
                              <td>{item?.description}</td>
                              <td className="ps-4">
                                <Link
                                  to=""
                                  className="ps-3"
                                  onClick={() => chatPopuHandler(item?._id)}
                                >
                                  <i className="fa-solid fa-eye"></i>
                                </Link>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    {myQueries?.isLoading && "Loading your Queries..."}
                    {!myQueries?.isLoading &&
                      myQueries?.length === 0 &&
                      "Queries not found"}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* =========discussion chat forum========= */}
        {queryPopup && (
          <DiscussionForum
            closeChatPopup={closeChatPopup}
            queryId={selectedQueryId}
            lmsuserid={lmsuserid}
          />
        )}

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
    </>
  );
}

export default MyQueries;
