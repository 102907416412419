import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import InvestorSidebar from "../../components/investor/InvestorSidebar";
import Footer from "../../components/Footer";
import DashboardBanner from "../../components/general/DashboardBanner";
import Loader from "../../components/general/Loader";
import { useSelector } from "react-redux";
import axios from "axios";
import { Api_Base_Url } from "../../constant/matcher";
import { formatNumberWithCommas } from "../../utils/helper";

const RealStateDetails = () => {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [dynamicRoute, setdynamicRoute] = useState("");
  const [isloading, setIsLoading] = useState(true);
  const [listDetails, setListDetails] = useState([]);
  const { clientcode, propertyCode } = useParams(); 
  
  useEffect(() => { 
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [loggedInUserData]);

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };


  const getList = () => {
    axios
      .get(`${Api_Base_Url}/investor/mridetail?clientcode=${clientcode}`)
      .then((res) => {
        const filteredData = res?.data?.data.filter(item => item.propertyCode === propertyCode);
        setListDetails(filteredData);
      });
  };

  function getMonthName(dateStr) {
    const date = new Date(dateStr);
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthName = monthNames[date.getMonth()];
  
    return monthName;
  }

  function convertDateToCustomFormat(dateStr) {
    const date = new Date(dateStr);
    
    // Extract year, month, and day from the date
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const day = date.getDate().toString().padStart(2, '0');
  
    // Create the custom date format: DD-MM-YYYY
    const customDate = `${day}-${month}-${year}`;
  
    return customDate;
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      {isloading ? (
        <Loader />
      ) : (
        <>
          <InvestorSidebar navContWrapp={navContWrapp} />
          {/* content wrapper section */}
          <section
            className={`dash-content-wrapper small-wrapper`}
            style={{ paddingLeft: navShow ? "230px" : "70px" }}
          >
            <DashboardBanner />

            <div className="breadcrumbs">
              <div className="container">
                <p>
                  <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                    Dashboard
                  </Link>{" "}
                  <i className="fa-solid fa-angle-right"></i> Real Estate
                </p>
              </div>
            </div>

            <div
              className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
              style={{ background: "whitesmoke" }}
            >
              <div className="row table__row__wrapp">
                <div className="client_table_container py-2">
                  <div className="d-flex justify-content-between">
                   
                  </div>
                  <div className="w-100 overflow-auto">
                    <table className="table table-striped client__table">
                      <thead>
                        <tr>
                          <th scope="col">Sr No.</th>
                          <th scope="col">Property Code</th>

                          <th scope="col">Month</th>

                          <th scope="col">AR / rent</th>

                          <th scope="col">AR / rent (Amount)</th>
                          <th scope="col">GST (If Applicable)</th>
                          <th scope="col">TDS</th>

                          <th scope="col">Net Amount</th>

                          <th scope="col">Received on</th>
                          <th scope="col">Amount Pending (If any)</th>
                          <th scope="col">Payment Mode</th>
                          <th scope="col">Expenses</th>
                          <th scope="col">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listDetails &&
                          listDetails.map((item, index) => (
                            <tr key={item?._id}>
                              <td>{index + 1}</td>
                              <td>{item?.propertyCode}</td>
                              <td>{getMonthName(item?.Month)}</td>
                              <td>{item?.AR_Rent}</td>
                              <td>{formatNumberWithCommas(item?.AR_Rent_amount)}</td>
                              <td>{item?.GST}</td>
                              <td>{formatNumberWithCommas(item?.TDS)}</td>
                              <td>{formatNumberWithCommas(item?.netAmount)}</td>
                              <td>{convertDateToCustomFormat(item?.receivedOn)}</td>
                              <td>{formatNumberWithCommas(item?.AmountPending)}</td>
                              <td>{item?.PaymentMode}</td>
                              <td>{item?.expenses}</td>
                              <td>{item?.remark}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Footer
              logoLink={"/"}
              home={"/#home"}
              about={"/#about"}
              service={"/#service"}
              faq={"/#faq"}
              testimonial={"/#testimonials"}
            />
          </section>
        </>
      )}
    </>
  );
};

export default RealStateDetails;
