import React from "react";

function Button({ color, text, onClick,side, isDisabled, backgroundColor, boxShadow }) {
  return (
    <button
      className={`btn accept`}
      onClick={onClick}
      disabled={isDisabled}
      style={{backgroundColor:`${backgroundColor}`, border:'rgb(238, 238, 238)', color, boxShadow}}
    >
      {
        side==='left' && <i className={`bx bx-chevron-${side}`} style={{color}}></i>
      }
      {text}
      {
        side==='right' && <i className={`bx bx-chevron-${side}`} style={{color}}></i>
      }
    </button>
  );
}

export default Button;