import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import PureCounter from '@srexi/purecounterjs'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../styles/inquiryFormStyle.css'
import { PUBLIC_PATH } from '../constant/matcher'
import Footer from '../components/Footer'
import HeroForm from '../components/general/HeroForm'
import InterestedFrom from '../components/general/InterestedFrom'
import NewsLetter from '../components/general/NewsLetter'
import Header from '../components/Header'
import TestiCards from '../components/TestiCards'
import testimonialData from '../constant/testimonialData.json'

function ChannelPartner () {
  // const [loginSuggestion, setLoginSuggestion] = useState('d-none')
  const [mobileSlick, setMobileSlick] = useState(false)

  // const loginOptions = () => {
  //   if(loginSuggestion === 'd-none') {
  //     setLoginSuggestion('d-block')
  //   } else {
  //     setLoginSuggestion('d-none')
  //   }
  // }
  

  useEffect(() => {
    if (window.screen.width < 768) {
      setMobileSlick(true)
    } else {
      setMobileSlick(false)
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
    // for mobile slick
    setTimeout(() => {
      if (window.location.hash !== '') {
        window.location.href = window.location.hash
      }
    }, 300)
  }, [])

  var settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  var productSetting = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  useEffect(() => {
    AOS.init({
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false
    })
  }, [])
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <Header />
      {/* <header
        id='header'
        className='header d-flex align-items-center fixed-top'
        >
        <div className='container-fluid container-xl d-flex align-items-center justify-content-between'>
          <Link to='/' className='logo d-flex align-items-center'>
            <img src={PUBLIC_PATH + 'xPandLogo.png'} alt='brandLogo' />
          </Link>

          <i className='mobile-nav-toggle mobile-nav-show bi bi-list'></i>
          <i className='mobile-nav-toggle mobile-nav-hide d-none bi bi-x'></i>
          <nav id='navbar' className='navbar'>
            <ul>
              <li>
                <a
                  rel='noopener noreferrer'
                  href='#hero'
                  className='active nav-link-a'
                  onClick={activeHandler}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  rel='noopener noreferrer'
                  href='#about'
                  onClick={activeHandler}
                  className='nav-link-a'
                >
                  About
                </a>
              </li>
              <li>
                <a
                  rel='noopener noreferrer'
                  href='#features'
                  className='nav-link-a'
                  onClick={activeHandler}
                >
                  Why Xpand
                </a>
              </li>
              <li>
                <a
                  rel='noopener noreferrer'
                  href='#service'
                  className='nav-link-a'
                  onClick={activeHandler}
                >
                  Products
                </a>
              </li>
              <li>
                <a
                  rel='noopener noreferrer'
                  href='#testimonials'
                  className='nav-link-a'
                  onClick={activeHandler}
                >
                  Testimonials
                </a>
              </li>
              <li>
                <a
                  rel='noopener noreferrer'
                  href='#faq'
                  className='nav-link-a'
                  onClick={activeHandler}
                >
                  FAQs
                </a>
              </li>
              <li className='header-btn'>
                <a className='get-a-quote' href='#get-quote' className="nav-link-a">
                  Join Now
                </a>
              </li>
              <li className='header-btn'>
                <div
                  // to=''
                  className='ms-0 get-a-quote log-drop-main position-relative'
                  style={{ cursor: 'pointer' }}
                  onClick={loginOptions}
                >
                  Login <i className='fa-sharp fa-solid fa-caret-down'></i>
                    <div className={`${loginSuggestion} login-dropdown`} id='login-dropdown'>
                      <ul className='position-relative'>
                        <li>
                          <Link to='/login/channel-partner'  
                            className='nav-link-a'
                            id="login-as-cp"
                          >
                            As Channel Partner
                          </Link>
                        </li>
                        <li>
                          <Link to='/login/investor' id="login-as-inv" className='nav-link-a'>As Investor</Link>
                        </li>
                        <div className='log-arrow'>
                          <i className='fa-solid fa-caret-right'></i>
                        </div>
                      </ul>
                    </div>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </header> */}
      {/* <!-- End Header --> */}
      {/* <!-- End Header --> */}

      {/* <!-- ======= Hero Section ======= --> */}
      <section id='hero' className='hero d-flex align-items-center'>
        <div className='container'>
          <div className='row gy-4 d-flex justify-content-between'>
            <div className='hero-form col-md-6 d-flex flex-column justify-content-center position-relative'>
              <h2 className='text-uppercase hero-heading' data-aos='fade-up'>
                Take a First Step Towards Your Independent Financial Journey
              </h2>
              <div className='position-absolute arrow-animated'>
                <img
                  src={PUBLIC_PATH + 'assets/img/arrowGif.gif'}
                  alt='arrow'
                  className=''
                />
              </div>
              <div className='banner-text'>
                <h3
                  data-aos='fade-up'
                  className=' text-center text-white text-uppercase'
                >
                  Unlock Potential Of <br /> Unlimited Passive Income
                </h3>
              </div>
            </div>

            <div
              className='col-md-6 col-lg-4 hero-img \'
              // className='col-md-6 col-lg-4 order-1 order-md-2 hero-img'
              data-aos='zoom-out'
            >
              <HeroForm />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero Section --> */}

      <main id='main'>
        {/* <!-- ======= About Us Section ======= --> */}
        <section id='about' className='about'>
          <div className='container' data-aos='fade-up'>
            <div className='row d-block d-lg-none'>
              <div className='section-header p-0'>
                <h2>About Xpand Wealth</h2>
              </div>
            </div>
            <div className='row gy-4'>
              <div
                className='col-lg-6 position-relative align-self-start order-lg-last order-first'
                data-aos='fade-left'
              >
                <img
                  src={PUBLIC_PATH + 'assets/img/about.jpg'}
                  className='img-fluid aboutImg'
                  style={{
                    display: 'block',
                    width: '100%',
                    maxHeight: '350px'
                  }}
                  alt='img'
                />
              </div>
              <div className='col-lg-6 content order-last  order-lg-first'>
                <h2 className='mb-0 about-heading d-none d-lg-block'>
                  About Xpand Wealth
                </h2>
                <p>
                  Xpand Wealth, a new age platform with its state-of-the-art
                  financial distributor network model, offers one stop solution
                  to help channel partners to expand their business horizon.
                  Xpand Wealth with its unique blend of technology in investment
                  management helps in building bespoke investment solutions
                  encompassing Financial advisory, Mutual fund Investments and
                  Monthly rental income investments.
                </p>
                <p className=''>
                  Xpand Wealth understands the growing need of channel partners
                  to deliver optimal investment solution based upon the need and
                  risk profile of a client. Being on Xpand Wealth platform is
                  win-win for all the stakeholders.
                </p>
                <ul className='tabs-cp-in'>
                  <li className='me-3'>
                    <Link to='/' className={''}>
                      Channel Partner
                    </Link>
                  </li>
                  <li className='invest_btn'>
                    <Link to='/investor' className=''>
                      Investor
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id='features' className='features'>
          <div className='container'>
            <div className='row d-block d-lg-none'>
              <div className='section-header p-0'>
                <h2>Why Xpand Wealth</h2>
              </div>
            </div>
            <div
              className='row gy-4 align-items-center features-item'
              data-aos='fade-up'
            >
              <div className='col-lg-5' data-aos='fade-right'>
                <img
                  src={PUBLIC_PATH + 'assets/img/serv-1.jpg'}
                  className='img-fluid'
                  style={{
                    display: 'block',
                    maxHeight: '300px',
                    margin: 'auto'
                  }}
                  alt=''
                />
              </div>
              <div className='col-lg-7' data-aos='fade-left'>
                <h2 className='mb-0 d-none d-lg-block about-heading'>
                  Why Xpand Wealth
                </h2>
                <ul className='flex-lists '>
                  <li>
                    {/* <h5> */}
                    <i className='bi bi-check'></i>
                    Be a part of the Financial Distribution Network, where more
                    than 200 plus chartered accountants are already present and
                    helping the clients to achieve their goal.
                    {/* </h5> */}
                  </li>
                  <li>
                    {/* <h5> */}
                    <i className='bi bi-check'></i>
                    As a channel partner, you will get 24X7 access to the
                    knowledge repository on equity investments, mutual fund
                    investments and commercial real estate repository.
                    {/* </h5> */}
                  </li>
                  <li>
                    {/* <h5> */}
                    <i className='bi bi-check'></i>
                    Get expert advice on equity investments, mutual fund
                    investments and commercial real estate investments.
                    {/* </h5> */}
                  </li>
                  <li>
                    {/* <h5> */}
                    <i className='bi bi-check'></i>
                    Get back office and transaction support for clients.
                    {/* </h5> */}
                  </li>
                  <li>
                    {/* <h5> */}
                    <i className='bi bi-check'></i>
                    Get periodic review report of client’s investments.
                    {/* </h5> */}
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- Features Item --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        {/* <!-- ======= About Us Section ======= --> */}
        <section id='opportunity' className='opportunity about pb-0'>
          <div className='container' data-aos='fade-up'>
            <div className='row d-block d-lg-none'>
              <div className='section-header p-0'>
                <h2>Opportunity With Xpand</h2>
              </div>
            </div>
            <div className='row gy-4'>
              <div
                className='col-lg-6 position-relative align-self-start order-lg-last order-first'
                data-aos='fade-left'
              >
                <img
                  src={PUBLIC_PATH + 'assets/img/opportunity.jpg'}
                  className='opportunityImg'
                  alt='img'
                />
              </div>
              <div className='col-lg-6 content order-last  order-lg-first'>
                <h2 className='about-heading mb-0 d-none d-lg-block'>
                  Opportunity With Xpand
                </h2>
                <p className='opp-List'>
                  <div>
                    <i className='bi bi-check'></i> As a channel partners, you
                    can grow exponentially by servicing the sunrise sector like
                    investment advisory having low penetration.
                    {/* <br /> */}
                  </div>
                  {/* <p> */}
                  <div>
                    <i className='bi bi-check'></i> Work with the best in the
                    investment advisory and real estate rental income business.
                  </div>
                  {/* </p> */}
                  {/* <br /> */}
                  {/* <p> */}
                  <div>
                    <i className='bi bi-check'></i> Earn passive income by
                    leveraging your network of clients.
                    {/* </p> */}
                  </div>
                  {/* <br /> */}
                  {/* <p> */}
                  <div>
                    <i className='bi bi-check'></i> Scope of multifold increase
                    in passive income by using Xpand Wealth boutique investment
                    offering.
                  </div>
                  {/* </p> */}
                  {/* <br /> */}
                  {/* <p> */}
                  <div>
                    <i className='bi bi-check'></i> Adding value proposition to
                    clients by offering higher yielding investment products to
                    reach their financial goals.
                  </div>
                  {/* </p> */}
                  {/* <br /> */}
                  <div>
                    {/* <p> */}
                    <i className='bi bi-check'></i> Participating in India
                    growth story and benefiting from rising trend of
                    financialization through Xpand Wealth.
                  </div>
                </p>
                {/* <ul className='opportunity_facts'>
                  <li data-aos='fade-up' data-aos-delay='100'>
                    <i className='bi bi-check'></i>
                    <div>
                      <h5>
                        India’s MF to GDP ratio stands at 16.2% vs Global
                        Average of 75%
                      </h5>
                    </div>
                  </li>
                  <li data-aos='fade-up' data-aos-delay='200'>
                    <i className='bi bi-check'></i>
                    <div>
                      <h5>
                        3.7 Crore unique mutual fund vs 70 Crore+ Indian Pan
                        Card Holders
                      </h5>
                    </div>
                  </li>
                  <li data-aos='fade-up' data-aos-delay='300'>
                    <i className='bi bi-check'></i>
                    <div>
                      <h5>
                        11 Crore Dmat Account in 2023 vs 4 Crore Dmat Account in
                        2020
                      </h5>
                    </div>
                  </li>
                  <li data-aos='fade-up' data-aos-delay='400'>
                    <i className='bi bi-check'></i>
                    <div>
                      <h5>
                        Only 1,324 SEBI registered investment advisors vs 12
                        Crore+ Registered Investors
                      </h5>
                    </div>
                  </li>
                  <li data-aos='fade-up' data-aos-delay='500'>
                    <i className='bi bi-check'></i>
                    <div>
                      <h5>
                        1.26 lakh mutual fund distributors vs 143 Crore
                        Population
                      </h5>
                    </div>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Opportunity Section --> */}

        {/* <!-- ======= Services Section ======= --> */}
        <section id='service' className='services'>
          <div className='container' data-aos='fade-up'>
            <div className='section-header pt-0'>
              {/* <span>Our Services</span> */}
              <h2>Investment offering of Xpand Wealth</h2>
            </div>

            {/* slider on mobile screen */}
            {mobileSlick === true && (
              <div className='row px-4'>
                <Slider {...productSetting}>
                  <div
                    // className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards'
                    data-aos='fade-up'
                    data-aos-delay='200'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={
                            PUBLIC_PATH + 'assets/img/logistic-service-1.jpg'
                          }
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          href="#!"
                          rel='noopener noreferrer'
                          className='stretched-link'
                        >
                          Equity Investment Advisory
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          SEBI Registered Investment Advisors.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Value Investing Based approach.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Strong track record of return generation.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Superior Risk Management Framework.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- End Card Item --> */}

                  <div
                    // className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards'
                    data-aos='fade-up'
                    data-aos-delay='100'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/mutual-fund.jpg'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                        >
                          Mutual Fund Investments
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          Strong footprint in the MF business.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Bespoke Mutual Fund Portfolio Construction
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Active Mutual Fund Return Optimization.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Strong Backoffice Support
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- End Card Item --> */}

                  <div
                    // className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards'
                    data-aos='fade-up'
                    data-aos-delay='300'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/storage-service-1.jpg'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                        >
                          Monthly Rental Income Investments
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          RERA compliant Real Estate advisory.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Strong track record of leasing of commercial property
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          History of delivering high monthly rental yield.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Regular update on rental management.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- End Card Item --> */}

                  <div
                    // className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards'
                    data-aos='fade-up'
                    data-aos-delay='400'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/fund-investor.jpg'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                        >
                          Term Insurance
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          Basket of Insurance Schemes to choose from.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Need based Insurance Advise of schemes.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Strong support in claims settlement.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Strong back-office documents facilitation.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    // className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards'
                    data-aos='fade-up'
                    data-aos-delay='400'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/health.png'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                        >
                          Health Insurance
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          Basket of Insurance Schemes to choose from.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Need based Insurance Advise of schemes.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Strong support in claims settlement.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Strong back-office documents facilitation.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- End Card Item --> */}

                  <div
                    // className='col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards'
                    data-aos='fade-up'
                    data-aos-delay='600'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/dmat.jpg'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                        >
                          Demat Account
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          India’s reputed stock brokers on the platform.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Reputed stock broker selection guide.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Demat Account opening Facilitation.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Document handling support.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    // className='col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards'
                    data-aos='fade-up'
                    data-aos-delay='600'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/loans.jpg'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                        >
                          Loan Syndication
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          Collaboration with various banks for loans facility.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Working Capital loan and overdraft loan arrangement
                          for SME/MSME.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Attractive Interest rate display board.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Document handling support.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    // className='col-lg-3 col-md-4 col-sm-6'
                    className='moblieSlickCards'
                    data-aos='fade-up'
                    data-aos-delay='600'
                  >
                    <div className='card'>
                      <div className='card-img'>
                        <img
                          src={PUBLIC_PATH + 'assets/img/marketing-support.jpg'}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <h3>
                        <a
                          rel='noopener noreferrer'
                          href='#!'
                          className='stretched-link'
                          // style={{ textTransform: 'capitalize' }}
                        >
                          Fixed Income Products
                        </a>
                      </h3>
                      <ul>
                        <li>
                          <i className='bi bi-check'></i>
                          Investment options of high rated Fixed income
                          products.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Regular update on AAA rated Fixed Deposits & Tax-free
                          Bonds.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          High Yielding debt portfolio guide.
                        </li>
                        <li>
                          <i className='bi bi-check'></i>
                          Back-office support from Head office.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Slider>
                {/* <!-- End Card Item --> */}
              </div>
            )}

            {/* products cards on large screen */}
            {mobileSlick === false && (
              <div className='row gy-4'>
                <div
                  className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='200'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/logistic-service-1.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Equity Investment Advisory
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        SEBI Registered Investment Advisors.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Value Investing Based approach.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Strong track record of return generation.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Superior Risk Management Framework.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Card Item --> */}

                <div
                  className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/mutual-fund.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Mutual Fund Investments
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        Strong footprint in the MF business.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Bespoke Mutual Fund Portfolio Construction
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Active Mutual Fund Return Optimization.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Strong Backoffice Support
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Card Item --> */}

                <div
                  className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='300'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/storage-service-1.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Monthly Rental Income Investments
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        RERA compliant Real Estate advisory.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Strong track record of leasing of commercial property
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        History of delivering high monthly rental yield.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Regular update on rental management.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Card Item --> */}

                <div
                  className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='400'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/fund-investor.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Term Insurance
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        Basket of Insurance Schemes to choose from.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Need based Insurance Advise of schemes.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Strong support in claims settlement.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Strong back-office documents facilitation.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className='col-lg-3 col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='400'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/health.png'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Health Insurance
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        Basket of Insurance Schemes to choose from.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Need based Insurance Advise of schemes.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Strong support in claims settlement.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Strong back-office documents facilitation.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Card Item --> */}

                <div
                  className='col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='600'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/dmat.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Demat Account
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        India’s reputed stock brokers on the platform.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Reputed stock broker selection guide.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Demat Account opening Facilitation.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Document handling support.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className='col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='600'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/loans.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                      >
                        Loan Syndication
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        Collaboration with various banks for loans facility.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Working Capital loan and overdraft loan arrangement for
                        SME/MSME.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Attractive Interest rate display board.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Document handling support.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className='col-lg-3 col-md-4 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='600'
                >
                  <div className='card'>
                    <div className='card-img'>
                      <img
                        src={PUBLIC_PATH + 'assets/img/marketing-support.jpg'}
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <h3>
                      <a
                        rel='noopener noreferrer'
                        href='#!'
                        className='stretched-link'
                        // style={{ textTransform: 'capitalize' }}
                      >
                        Fixed Income Products
                      </a>
                    </h3>
                    <ul>
                      <li>
                        <i className='bi bi-check'></i>
                        Investment options of high rated Fixed income products.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Regular update on AAA rated Fixed Deposits & Tax-free
                        Bonds.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        High Yielding debt portfolio guide.
                      </li>
                      <li>
                        <i className='bi bi-check'></i>
                        Back-office support from Head office.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- End Card Item --> */}
              </div>
            )}
          </div>
        </section>
        {/* <!-- End Services Section --> */}

        {/* <!-- ======= Call To Action Section ======= --> */}
        <section
          id='call-to-action'
          className='call-to-action call-to-action-cp pt-0'
        >
          <div className='row'>
            <div className='section-header mb-0'>
              {/* <span>Features</span> */}
              <h2 className='mb-0'>XPAND SUPPORT</h2>
            </div>
          </div>
          <div className='container-fluid' data-aos='zoom-out'>
            <div className='row justify-content-center p-0'>
              <div className='call-sec-img p-0'>
                <img
                  src={PUBLIC_PATH + 'assets/img/cp-support.png'}
                  className=''
                  alt='call-to-atcn'
                />
              </div>
              {/* <div className='col-lg-8 text-center'>
                <h3>Start your Journey with Us</h3>
                <p>
                  Start your investment journey with us and let the wealth generation happen in most pleasurable way.
                </p>
                <p>
                Xpand Wealth will take care of all your financial need being taken care by industry experts having deep domain knowledge and track record of wealth generation.
                </p>
                <p>
                With Xpand Wealth, make yourself knowledge ready and increase your financial wisdom.
                </p>
                <Link to='' className='cta-btn'>
                  Call To Action
                </Link>
              </div> */}
            </div>
          </div>
        </section>
        {/* <!-- End Call To Action Section --> */}

        {/* <!-- ======= Testimonials Section ======= --> */}
        <section
          id='testimonials'
          className='testimonials'
          style={{ paddingBottom: '90px' }}
        >
          <div className='container-fluid'>
            <div className='row'>
              <div className='section-header mb-0 pb-0'>
                {/* <span>Testimonials</span> */}
                <h2
                  style={{ color: 'white', margin: 0, paddingBottom: '20px' }}
                >
                  Testimonials
                </h2>
              </div>
            </div>
            <div className='row px-3'>
              <div className='col-lg-12 testiCards_arrow'>
                <Slider {...settings}>
                  {testimonialData?.map((items, index) => {
                    return (
                      <TestiCards
                        key={index}
                        testiPara={items?.description}
                        writerName={items?.name}
                        location={items?.location}
                          // writerDesig={items?.designation}
                      />
                    )
                  })}
                  {/* <TestiCards 
                    testiPara={`Proin iaculis purus consequat sem cure digni ssim donec
                    porttitora entum suscipit rhoncus. Accusantium quam,
                    ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                    risus at semper.`}
                    writerName={'Daiane smith'}
                    writerDesig={'Birla'}
                  />
                  <TestiCards 
                    testiPara={`Proin iaculis purus consequat sem cure digni ssim donec
                    porttitora entum suscipit rhoncus. Accusantium quam,
                    ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                    risus at semper.`}
                    writerName={'Daiane smith'}
                    writerDesig={'Birla'}
                  />
                  <TestiCards 
                    testiPara={`Proin iaculis purus consequat sem cure digni ssim donec
                    porttitora entum suscipit rhoncus. Accusantium quam,
                    ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                    risus at semper.`}
                    writerName={'Daiane smith'}
                    writerDesig={'Birla'}
                  /> */}
                </Slider>
              </div>
            </div>
          </div>
        </section>

        <section className='testi_iframe px-3 pb-0'>
          <div className='container'>
            <div className='row'>
              <div className='video-responsive'>
                <iframe
                  width='100%'
                  height='500'
                  className='youtubeIframe'
                  src={`https://www.youtube.com/embed/dEVbhejrVNA`}
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  title='Embedded youtube'
                />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Testimonials Section --> */}

        {/* <!-- ======= Frequently Asked Questions Section ======= --> */}
        <section id='faq' className='faq'>
          <div className='container' data-aos='fade-up'>
            <div className='section-header'>
              {/* <span>Frequently Asked Questions</span> */}
              <h2>Frequently Asked Questions</h2>
            </div>

            <div
              className='row justify-content-center'
              data-aos='fade-up'
              data-aos-delay='200'
            >
              <div className='col-lg-10'>
                <div className='accordion accordion-flush' id='faqlist'>
                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-1'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        How to get associated with Xpand Wealth?
                      </button>
                    </h3>
                    <div
                      id='faq-content-1'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        To become a channel partner, one need to enroll by
                        signing MoU with Xpand Wealth.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-2'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Do I need to submit money as fee to be on Xpand
                        Platform?
                      </button>
                    </h3>
                    <div
                      id='faq-content-2'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        No upfront investment required to get associated as
                        channel partner.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-3'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Can one become channel partner while focusing on his/her
                        core profession?
                      </button>
                    </h3>
                    <div
                      id='faq-content-3'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        Becoming part of financial distribution network just
                        require your passion to create alternative passive
                        income source, therefore can be done along with your
                        core profession.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-4'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        What are the eligibility requirement to get associated
                        as channel partner?
                      </button>
                    </h3>
                    <div
                      id='faq-content-4'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        {/* <i className='bi bi-question-circle question-icon'></i> */}
                        Anyone who has strong passion for financial products
                        distribution can become a channel partner.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-5'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        What do I need to do as channel partner?
                      </button>
                    </h3>
                    <div
                      id='faq-content-5'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        As a channel partner, one need to motivate clients, help
                        them in building their wealth and in-turn generate
                        business in their respective geographies.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-6'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Does I need to take care of the documentation part for
                        clients?
                      </button>
                    </h3>
                    <div
                      id='faq-content-6'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        No, the client’s documentation part will be taken care
                        by our experienced relationship managers.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-7'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        What are the major product segment that Xpand Wealth
                        offers?
                      </button>
                    </h3>
                    <div
                      id='faq-content-7'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        Xpand Wealth has a product basket of Equity Investment
                        Advisory, Mutual Fund Investment, Monthly Income
                        Products, Corporate Fixed Deposit, Insurance Products
                        and Bonds
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-8'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Do I get any training support on products?
                      </button>
                    </h3>
                    <div
                      id='faq-content-8'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        Yes, you will get product training for each product by
                        experts.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-9'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Do I get my business overview in real time?
                      </button>
                    </h3>
                    <div
                      id='faq-content-9'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        Yes, you will access to know your business contribution
                        in granular form.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}

                  {/* <!-- # Faq item--> */}

                  <div className='accordion-item'>
                    <h3 className='accordion-header'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#faq-content-10'
                      >
                        <i className='bi bi-question-circle question-icon'></i>
                        Do I get any income as channel partner?
                      </button>
                    </h3>
                    <div
                      id='faq-content-10'
                      className='accordion-collapse collapse'
                      data-bs-parent='#faqlist'
                    >
                      <div className='accordion-body'>
                        Yes, you will earn your contribution in every investment
                        product segment as channel partner. The revenue model
                        will be e-mailed to you.
                      </div>
                    </div>
                  </div>
                  {/* <!-- # Faq item--> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Frequently Asked Questions Section --> */}
      </main>
      {/* <!-- End #main --> */}

      <section id='get-quote' className='interested mt-0 pt-0'>
        <div className='container'>
          <div className='row'>
            <div className='section-header' data-aos='fade-up'>
              {/* <span>Get A Quote</span> */}
              <h2>Register Now</h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6' data-aos='fade-right'>
              <div className='form-img' style={{ height: '100%' }}>
                <img
                  src={PUBLIC_PATH + 'assets/img/get-tch.jpg'}
                  alt=''
                  className='img-responsive'
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            </div>
            <div className='col-md-6' data-aos='fade-left'>
              <InterestedFrom />
            </div>
          </div>
        </div>
      </section>

      <section
        className='container news-letter footer-top pt-0 pb-2'
        id='News-letter'
      >
        <hr />
        {/* newsLetter */}
        <NewsLetter />
        <hr />
      </section>

      {/* <!-- ======= Footer ======= --> */}
      
      <footer id='footer' className='footer'>
        <div className='container'>
          <div className='row gy-4'>
            <div className='col-lg-5 col-md-12 footer-info'>
              <Link
                // rel='noopener noreferrer'
                to='/'
                className='logo d-flex align-items-center'
              >
                <img
                  src={PUBLIC_PATH + 'xPandLogo.png'}
                  alt='footer-logo'
                  className='img-fluid'
                />
              </Link>
              <p className="ps-2">
                Xpand Wealth, a new age platform with its state-of-the-art
                financial distributor network model, offers one stop solution to
                help channel partners to expand their business horizon. Xpand
                Wealth with its unique blend of technology in investment
                management helps in building bespoke investment solutions
                encompassing Financial advisory, Mutual fund Investments and
                Monthly rental income investments.
              </p>
              {/* <div className='social-links d-flex mt-4'>
                <a rel='noopener noreferrer' href='#!' className='twitter'>
                  <i className='bi bi-twitter'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='facebook'>
                  <i className='bi bi-facebook'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='instagram'>
                  <i className='bi bi-instagram'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='linkedin'>
                  <i className='bi bi-linkedin'></i>
                </a>
              </div> */}
            </div>

            <div className='col-lg-2 col-6 footer-links'>
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a rel='noopener noreferrer' href='/#hero'>
                    Home
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href='/#about'>
                    About
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href='/#service'>
                    Products
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href='/#faq'>
                    FAQs
                  </a>
                </li>
                <li>
                  <a rel='noopener noreferrer' href='/#testimonials'>
                    Testimonials
                  </a>
                </li>
                <li>
                  <Link to='/login/team'>Team Login</Link>
                </li>
                {/* <li>
                  <Link to='/franchise'>Partner Login</Link>
                </li>
                <li>
                  <Link to='/form-by-scan'>Qr Scan</Link>
                </li> */}
              </ul>
            </div>

            <div className='col-lg-5 col-md-12 footer-contact'>
              <h4>Contact Us</h4>
              <p>
                <div>
                  <h6 className='regOff'>REGISTERED OFFICE:</h6>
                  <div className='mb-2'>
                    109-P NO-4 LSC, Shrestha Vihar, New Delhi- 110092
                  </div>
                </div>
                {/* <br /> */}
                <div>
                  <h6 className='regOff'>CORPORATE OFFICE:</h6>
                  <p className='mb-3'>
                    304, Tower 4, Assotech Business Cresterra, Sector-135,
                    Noida–201304
                  </p>
                </div>
                <div className='' style={{ lineHeight: 0 }}>
                  <strong>Phone:</strong> +91-8800308006
                </div>
                <br />
                <div className='mb-3' style={{ lineHeight: 1 }}>
                  <strong>For Investor Support:</strong> support@xpandwealth.com
                </div>
                {/* <br /> */}
                <div className='' style={{ lineHeight: 1 }}>
                  <strong>For Business Partner Support:</strong>{' '}
                  help@xpandwealth.com
                </div>
                <br />
              </p>
            </div>
          </div>
        </div>

        <div className='container mt-4'>
          <div className='copyright'>
            &copy; Copyright{' '}
            <strong>
              <span>Xpand Wealth</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
      {/* <!-- End Footer --> */}
      {/* <!-- End Footer --> */}
    </>
  )
}

export default ChannelPartner
