import React, { useState, useEffect, useRef } from "react";
import { Api_Base_Url, PUBLIC_PATH } from "../../constant/matcher";
import { useGetProfileQuery } from "../../store/services/dashboardService";
import { Link, useLocation, NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  loggedInUserData,
  logout,
  setLoginUserToken,
} from "../../store/reducer/authReducer";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function Sidebar(props) {
  const { navContWrapp } = props;
  const [navSideHide, setNavSideHide] = useState(true);
  const [dynamicRoute, setdynamicRoute] = useState();
  const userData = useSelector((state) => state?.userReducer?.userData);
  const logoutSlug = userData && userData?.slug;
  const [showDropdown, setShowDropdown] = useState({
    lms: false,
    supportTickets: false,
    business: false,
  });
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [checkOutPopUp, setCheckOutPopUp] = useState(false);
  const [checkInTime, setCheckInTime] = useState(null);
  const [timer, setTimer] = useState(0);
  const [isCheckinClicked, setIsCheckinClicked] = useState(false);
  const [quickInvestSi, setquickInvestSi] = useState({
    username: userData?.eliteusername,
    password: userData?.elitepassword,
    loginType: userData?.eliterole,
    loginFor: userData?.eliterole,
    callbackUrl: window.location.href,
  });
  const [showPortal, setShowPortal] = useState(true)
  const [qickPass, setQickPass] = useState("");
  const [url, setUrl] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const changeHandler = e => {
  //   setquickInvestSi({ ...quickInvestSi, [e.target.name]: e.target.value })
  // }

  // for foramting encription
  var CryptoJSAesJson = {
    stringify: function (cipherParams) {
      var j = {
        ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
      };
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j);
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(j.ct),
      });
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
      return cipherParams;
    },
  };

  useEffect(() => {
    userData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");

    var key = "W@ealth!@$#$(%#H(^%)";
    // Encrypt
    var EncryptedPass = CryptoJS.AES.encrypt(
      JSON.stringify(quickInvestSi.password),
      key,
      { format: CryptoJSAesJson }
    ).toString();

    // console.log(EncryptedPass)

    setQickPass(EncryptedPass);

    setquickInvestSi({
      ...quickInvestSi,
      [quickInvestSi.password]: EncryptedPass,
    });

    window.scrollTo({ top: 0, left: 0, behavior: "instant" });

    let sidebarNavIcons = document.getElementsByClassName("sidebar_icons");
    sidebarNavIcons = Array.from(sidebarNavIcons);

    let sidebarNavLinks = document.getElementsByClassName("side_hide");
    sidebarNavLinks = Array.from(sidebarNavLinks);

    if (window.screen.width <= 992) {
      setNavSideHide(false);
      sidebarNavIcons.forEach((icons) => {
        icons.style.display = "block";
        icons.style.marginRight = "0px";
      });
      sidebarNavLinks.forEach((links) => {
        links.style.display = "none";
      });
    } else {
      setNavSideHide(true);
      sidebarNavIcons.forEach((icons) => {
        icons.style.display = "inline";
        icons.style.marginRight = "7px";
      });
      sidebarNavLinks.forEach((links) => {
        links.style.display = "inline";
      });
    }
    setUrl(location.pathname);
  }, [userData, location]);

  const profileResp = useGetProfileQuery(userData?._id);

  const userProfileData =
    profileResp?.isSuccess &&
    !profileResp?.isLoading &&
    profileResp.status === "fulfilled" &&
    !profileResp?.isError &&
    profileResp?.data?.success &&
    profileResp?.data?.user;

  let sidebarNavIcons = document.getElementsByClassName("sidebar_icons");
  sidebarNavIcons = Array.from(sidebarNavIcons);
  sidebarNavIcons.forEach((icons) => {
    icons.addEventListener("mousemove", () => {
      icons.style.color = "black";
    });
    icons.addEventListener("mouseout", () => {
      icons.style.color = "#fff";
    });
  });

  const sidebarController = () => {
    let sidebarNavIcons = document.getElementsByClassName("sidebar_icons");
    sidebarNavIcons = Array.from(sidebarNavIcons);

    let sidebarNavLinks = document.getElementsByClassName("side_hide");
    sidebarNavLinks = Array.from(sidebarNavLinks);

    if (navSideHide === true) {
      setNavSideHide(false);
      navContWrapp(false);
      // setNavShow(false)
      sidebarNavIcons.forEach((icons) => {
        // icons.style.display = 'block'
        setTimeout(() => {
          icons.style.display = "block";
        }, 200);
        icons.style.marginRight = "0px";
      });
      sidebarNavLinks.forEach((links) => {
        links.style.display =   "none";
      });
    } else {
      setNavSideHide(true);
      // setNavShow(true)
      navContWrapp(true);
      sidebarNavIcons.forEach((icons) => {
        icons.style.marginRight = "5px";
        icons.style.display = "inline";
      });
      sidebarNavLinks.forEach((links) => {
        setTimeout(() => {
          links.style.display = "inline";
        }, 200);
      });
    }
  };

  const onLogout = () => {
    dispatch(logout());
    navigate(logoutSlug);
  };

  const formatTimer = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    return formattedTime;
  };

  const formatTime = (time) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };
    return new Date(time).toLocaleString("en-US", options);
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    return currentDate.toISOString().split("T")[0];
  };

  useEffect(() => {
   const portalStatus =  localStorage.getItem("showPortalButton")
   if(portalStatus === "null"){
    setShowPortal(false)
   }
   else{
    setShowPortal(true)
   }
   const storedCheckInStatus = localStorage.getItem("checkinStatus");
   if (storedCheckInStatus === "1") {
      setShowCheckIn(true);
      const storedCheckinTime =  localStorage.getItem("checkinTime");
      setCheckInTime(storedCheckinTime);
      if (storedCheckinTime) {
         const currentTime = new Date().getTime();
         const storedTime = new Date(storedCheckinTime).getTime();
         const elapsedSeconds = Math.floor(
            (currentTime - storedTime) / 1000
         );
         setTimer(elapsedSeconds);
      }
   } else {
      setShowCheckIn(false);
      setCheckInTime(null);
   }
   const interval = setInterval(() => {
      if (showCheckIn) {
         setTimer((prevTimer) => prevTimer + 1);
      }
   }, 1000);
   return () => clearInterval(interval);
  
}, [showCheckIn]);
 

  const handleCheckin = async (e) => {
    e.preventDefault()
    setIsCheckinClicked(true);
    try {
      if ("geolocation" in navigator) {
        // Get the user's current location
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
            const currentTime = new Date();
            const checkinTimeStr = currentTime.toISOString();
            
            // Set the check-in time
            setCheckInTime(checkinTimeStr);
            
            try {
              // Make a POST request to the server
              const response = await axios.post(
                `${Api_Base_Url}/rm/checkinout`,
                {
                  checkInTime: checkinTimeStr,
                  rmid: loginUserData._id,
                  currentdate: getCurrentDate(),
                  checkInlat: latitude,
                  checkInlong: longitude,
                }
              );
                  // console.log(response?.data?.data);
              if (response?.data && response?.data?.data?.is_login === 1) {
                if (response.data.key === 1) {
                  toast.warn("You have already checked in for today.");
                } else {
                  
                  setShowCheckIn(true);
                  setCheckInTime(checkinTimeStr);
                  setTimer(0);
                  localStorage.setItem("checkinStatus", response?.data?.data?.is_login);
                  localStorage.setItem("checkinTime", response?.data?.data?.checkIN);
                  localStorage.setItem("checkoutDate", response?.data?.data?.checkInOutDate)
                  // console.log("checkoutDate", response?.data);
                }
              } else {
                toast.warn("Unable to check in. Please try again.");
                
              }
            } catch (error) {
              console.error("Error during check-in:", error.message);
              
            }
            finally {
              setIsCheckinClicked(false);
            }
          },
          (error) => {
            if (error.code === 1) {
              alert("Please allow location access to check in.");
            } else {
              console.error("Error getting location:", error.message);
            }
          }
        );
      } else {
        alert("Geolocation is not available in your browser.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  
  const handleCheckout = async () => {
    
       const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
     const currentTime = new Date();
     const checkoutTimeStr = currentTime.toISOString();
    try {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
           
            const response = await axios.post(`${Api_Base_Url}/rm/checkinout`, {
                   checkInTime: checkoutTimeStr,
                   checkOutTime: checkoutTimeStr,
                   rmid: loginUserData._id,
                   currentdate: getCurrentDate(),
                   checkOutlat: latitude,
                   checkOutlong: longitude,
                });
                setCheckOutPopUp(false);
            setShowCheckIn(false);
           
            localStorage.removeItem("checkinStatus");
            localStorage.removeItem("checkinTime");
            localStorage.setItem("checkinStatus", response?.data?.result?.is_login);
            localStorage.setItem("checkinTime", response?.data?.result?.checkIN);
            
          },
          (error) => {
            if (error.code === 1) {
              alert("Please allow location access to check in.");
            } else {
              console.error("Error getting location:", error.message);
            }
          }
        );
      } else {
        alert("Geolocation is not available in your browser.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }; 
  
  const handleAutoCheckout = async () => {
    
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const currentTime = new Date();
    const currentdate = localStorage.getItem("checkoutDate")
    // console.log(currentdate);
    currentTime.setDate(currentTime.getDate() - 1);
    currentTime.setHours(23, 59, 0, 0);
    const checkoutTimeStr = currentTime.toISOString();
 try {
   if ("geolocation" in navigator) {
     navigator.geolocation.getCurrentPosition(
       async (position) => {
         const latitude = position.coords.latitude;
         const longitude = position.coords.longitude;
         const response = await axios.post(`${Api_Base_Url}/rm/checkinout`, {
                checkInTime: checkoutTimeStr,
                checkOutTime: checkoutTimeStr,
                rmid: loginUserData._id,
                currentdate: currentdate,
                checkOutlat: latitude,
                checkOutlong: longitude,
             });
             setCheckOutPopUp(false);
         setShowCheckIn(false);
         localStorage.removeItem("checkinStatus");
         localStorage.removeItem("checkinTime");
         localStorage.setItem("checkinStatus", response?.data?.result?.is_login);
         localStorage.setItem("checkinTime", response?.data?.result?.checkIN);
       },
       (error) => {
         if (error.code === 1) {
           alert("Please allow location access to check in.");
         } else {
           console.error("Error getting location:", error.message);
         }
       }
     );
   } else {
     alert("Geolocation is not available in your browser.");
   }
 } catch (error) {
   console.error("An error occurred:", error);
 }
}; 

const checkDayChangeInterval = setInterval(() => {
      const currentTime = getCurrentDate()
      const storedCheckinDate = localStorage.getItem("checkinDate");
      // console.log("currentDate", currentTime);
      // console.log("storedCheckinDate",storedCheckinDate);
      if (currentTime !== storedCheckinDate) {
       
        handleAutoCheckout()
        clearInterval(checkDayChangeInterval); 
      }
    }, 4 * 60 * 60 * 1000);
  
  
  
 
  /* Code for change portal from CP to Invester */
  const handlePortalChange = (e) => {
    e.preventDefault();
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const phone = loginUserData?.phone;
    const requestBody = {
      phone: phone.toString(),
    };
    axios
      .post(`${Api_Base_Url}/cp/portal-switch`, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // Access the success property from the response data
        const success = response.data.success;

        // Check the value of success
        if (success) {
          // Handle success here
          // console.log("Success:", success);
          localStorage.removeItem("user-token");
          localStorage.removeItem("loginUserData");
          localStorage.setItem("user-token", response?.data?.token);
          localStorage.setItem(
            "loginUserData",
            JSON.stringify(response?.data?.user)
          );
          dispatch(loggedInUserData(response?.data?.user));
          dispatch(setLoginUserToken(response?.data?.token));
          navigate(`/dashboard/${response?.data?.user?.role}`);
        } else {
          // Handle failure here
          toast.error("You dont have valid Credentials to move");
          console.log("Request was not successful.");
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  };

  

  return (
    <>
      <ToastContainer />
      <div className="container-fluid dashboard-container">
        <div className="row d-flex align-items-center justify-content-center">
          <div
            className={`sidebar-container p-0 smallScreenSidebar`}
            style={{
              // width: navSideHide ? "230px" : "110px",
              width: navSideHide || (showDropdown.lms || showDropdown.supportTickets || showDropdown.business) ? "230px" : "70px",
              left:
                window.screen.width < 600
                  ? navSideHide || (showDropdown.lms || showDropdown.supportTickets || showDropdown.business)
                    ? "0px"
                    : "-230px"
                  : 0,
            }}
          >
            <div className="dash-sidebar">
              <div className="sidebar-top py-3 px-3">
                {/* <h3 className='text-white text-center user-name'>Hi, USER</h3> */}
                <div className="user-panel">
                  <div className="pull-left image">
                    <img
                      src={PUBLIC_PATH + "assets/img/team/profile.png"}
                      className="img-circle"
                      alt="userImage"
                    />
                  </div>
                  <div className="pull-left side_hide info">
                    <p className="ms-2 mb-0 text-capitalize">
                      {userProfileData?.name
                        ? userProfileData?.name
                        : userData?.name}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className='main-nav py-5'>
                <h3 className='text-white text-center user-name'>Hi, USER</h3>
              </div> */}
              <div className="sidebar-links">
                <ul className="side-list pb-2">
                  {/* <---- Home ----> */}
                  <li>
                    <Link
                      to={`/${dynamicRoute}/${userData?.role}`}
                      className={
                        "sidebar_link_a" +
                        (url === `/${dynamicRoute}/${userData?.role}`
                          ? " active"
                          : "")
                      }
                      // className='sidebar_link_a active'
                      //onClick={activeHandler}
                    >
                      <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-house" : "fa-solid sidebar_icons fa-house"}></i>{" "}
                      <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}>Home</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="" className="sidebar_link_a">
                      <i className="fa-solid sidebar_icons fa-inbox"></i>{" "}
                      <span className="side_hide">Quick Invest</span>
                    </Link>
                  </li> */}

                  {/* <---- LMS ----> */}

                  <li
                    style={{ padding: "0" }}
                    onClick={() => {
                    
                      showDropdown?.lms === true
                        ? setShowDropdown({
                            ...showDropdown,
                            lms: false,
                           
                          })
                          
                        : setShowDropdown({
                            ...showDropdown,
                            lms: true,
                          });
                        
                    }}
                  >
                    <li
                      className={` ${
                        showDropdown?.lms && "active-sub-menu-dropdown"
                      }`}
                     
                    >
                      <Link
                        className="sidebar_link_a"
                        style={
                          navSideHide || (showDropdown.lms || showDropdown.supportTickets || showDropdown.business)
                            ? null
                            : {
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }
                        }
                      >
                        <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-calendar-days" : "fa-solid fa-calendar-days sidebar_icons"}></i>
                        <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}>LMS</span>
                        {!showDropdown?.lms ? (
                          <i
                            className="fa-solid fa-caret-down"
                            style={
                              navSideHide
                                ? null
                                : {
                                    paddingLeft: "7px",
                                  }
                            }
                          ></i>
                        ) : (
                          <i
                            className="fa-solid fa-caret-up"
                            style={
                              navSideHide
                                ? null
                                : {
                                    paddingLeft: "7px",
                                  }
                            }
                          ></i>
                        )}
                      </Link>
                    </li>
                    {showDropdown?.lms && (
                      <ul
                        className="drop-down-bar sub-menu list-unstyled "
                        style={
                          navSideHide
                            ? null
                            : {
                                textAlign: "center",
                                paddingTop: "0",
                                // paddingLeft: "3px",
                              }
                        }
                      >
                        {userData?.role !== "cp" && (
                          <li className="sub-menu-list1" id="sub-menu-list1">
                            <NavLink
                              to={`/${dynamicRoute}/${userData?.role}/my-leads`}
                              className="sidebar_link_a "
                              id="sub-menu-link1"
                              style={
                                navSideHide
                                  ? {
                                      paddingLeft: "50px",
                                    }
                                  : null
                              }
                            >
                              <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline fa-id-card-clip " :"fa fa-id-card-clip sidebar_icons"}></i>
                              <span
                                style={
                                  navSideHide || (showDropdown.lms || showDropdown.supportTickets || showDropdown.business)
                                    ? null
                                    : {
                                        display: "none",
                                      }
                                }
                              >
                                CP Leads
                              </span>
                            </NavLink>
                          </li>
                        )}
                        <li className="sub-menu-list2" id="sub-menu-list2">
                          <NavLink
                            to={`/${dynamicRoute}/${userData?.role}/my-invleads`}
                            className="sidebar_link_a"
                            id="sub-menu-link2"
                            style={
                              navSideHide 
                                ? {
                                    paddingLeft: "50px",
                                  }
                                : null
                            }
                          >
                            <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline fa-users" : "fa-solid sidebar_icons fa-users"}></i>
                            <span
                              style={
                                navSideHide || (showDropdown.lms || showDropdown.supportTickets || showDropdown.business)
                                  ? null
                                  : {
                                      display: "none",
                                    }
                              }
                            >
                              Investor Leads
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>

                  {/* <---- My Channel Partner ----> */}
                  {userData?.role !== "cp" && (
                    <li>
                      <NavLink
                        to={`/${dynamicRoute}/${userData?.role}/my-cp`}
                        className="sidebar_link_a"
                      >
                        <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa fa-id-card-clip  d-inline" : "fa fa-id-card-clip sidebar_icons"}></i>{" "}
                        <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}>My Channel Partner</span>
                      </NavLink>
                    </li>
                  )}

                  {/* <---- My Investor ----> */}
                  <li>
                    <NavLink
                      to={`/${dynamicRoute}/${userData?.role}/my-clients`}
                      className="sidebar_link_a"
                    >
                      <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-user" : "fa-solid sidebar_icons fa-user"}></i>{" "}
                      <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}>My Investor</span>
                    </NavLink>
                  </li>

                  {/* <---- X-Content ----> */}
                  <li>
                    <NavLink
                      to={`/${dynamicRoute}/${userData?.role}/x-content`}
                      className="sidebar_link_a"
                    >
                      <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-folder-open" :"fa-solid sidebar_icons fa-folder-open"}></i>{" "}
                      <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}>X-Content</span>
                    </NavLink>
                  </li>

                  {/* <---- Support Tickets ----> */}
                  {userData?.role !== "cp" && (
                  
                    <>
                      {userData?.role === "rm" && (
                        <li
                          style={{ padding: "0" }}
                          onClick={() => {
                            showDropdown?.supportTickets === true
                              ? setShowDropdown({
                                  ...showDropdown,
                                  supportTickets: false,
                                })
                              : setShowDropdown({
                                  ...showDropdown,
                                  supportTickets: true,
                                });
                          }}
                        >
                          <li
                            className={` ${
                              showDropdown?.supportTickets &&
                              "active-sub-menu-dropdown"
                            }`}
                          >
                            <Link
                              className="sidebar_link_a"
                              style={
                                navSideHide || (showDropdown.lms || showDropdown.supportTickets || showDropdown.business)
                                  ? null
                                  : {
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }
                              }
                            >
                              <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa fa-envelope-open-text d-inline " : "fa fa-envelope-open-text sidebar_icons "}></i>
                              <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}>Support Tickets</span>
                              {!showDropdown?.supportTickets ? (
                                <i
                                  className="fa-solid fa-caret-down"
                                  style={
                                    navSideHide
                                      ? null
                                      : {
                                          paddingLeft: "7px",
                                        }
                                  }
                                ></i>
                              ) : (
                                <i
                                  className="fa-solid fa-caret-up"
                                  style={
                                    navSideHide
                                      ? null
                                      : {
                                          paddingLeft: "7px",
                                        }
                                  }
                                ></i>
                              )}
                            </Link>
                          </li>
                          {showDropdown?.supportTickets && (
                            <ul
                              className="drop-down-bar sub-menu list-unstyled "
                              style={
                                navSideHide
                                  ? null
                                  : {
                                      textAlign: "center",
                                      paddingTop: "0",
                                    }
                              }
                            >
                              <li
                                // className="sub-menu-list1 accordion-body "
                                className="sub-menu-list1  "
                                id="sub-menu-list1"
                              >
                                <NavLink
                                  to={`/${dynamicRoute}/${userData?.role}/support-ticket`}
                                  className="sidebar_link_a "
                                  id="sub-menu-link1"
                                  style={
                                    navSideHide
                                      ? {
                                          paddingLeft: "50px",
                                        }
                                      : null
                                  }
                                >
                                  <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa fa-id-card-clip d-inline" :"fa fa-id-card-clip sidebar_icons"}></i>
                                  <span
                                    style={
                                      navSideHide || (showDropdown.lms || showDropdown.supportTickets || showDropdown.business)
                                        ? null
                                        : {
                                            display: "none",
                                          }
                                    }
                                  >
                                    X - Support
                                  </span>
                                </NavLink>
                              </li>

                              <li
                                className="sub-menu-list2"
                                id="sub-menu-list2"
                              >
                                <NavLink
                                  to={`/${dynamicRoute}/${userData?.role}/lpquery`}
                                  className="sidebar_link_a"
                                  id="sub-menu-link2"
                                  style={
                                    navSideHide
                                      ? {
                                          paddingLeft: "50px",
                                        }
                                      : null
                                  }
                                >
                                  <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline fa-users" : "fa-solid sidebar_icons fa-users"}></i>
                                  <span
                                    style={
                                      navSideHide || (showDropdown.lms || showDropdown.supportTickets || showDropdown.business)
                                        ? null
                                        : {
                                            display: "none",
                                          }
                                    }
                                  >
                                    LP Query
                                  </span>
                                </NavLink>
                              </li>
                              <li
                                className="sub-menu-list2"
                                id="sub-menu-list2"
                              >
                                <NavLink
                                  to={`/${dynamicRoute}/${userData?.role}/help-desk`}
                                  className="sidebar_link_a"
                                  id="sub-menu-link2"
                                  style={
                                    navSideHide
                                      ? {
                                          paddingLeft: "50px",
                                        }
                                      : null
                                  }
                                >
                                  <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline fa-users":"fa-solid sidebar_icons fa-users"}></i>
                                  <span
                                    style={
                                      navSideHide || (showDropdown.lms || showDropdown.supportTickets || showDropdown.business)
                                        ? null
                                        : {
                                            display: "none",
                                          }
                                    }
                                  >
                                    Help Desk
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          )}
                        </li>
                      )}
                    </>
                  )}

                  {/* <---- Form Download ----> */}
                  <li>
                    <NavLink
                      to={`/${dynamicRoute}/${userData?.role}/form-download`}
                      className="sidebar_link_a"
                    >
                      <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-file-arrow-down" : "fa-solid sidebar_icons fa-file-arrow-down"}></i>{" "}
                      <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}>Form Downloads</span>
                    </NavLink>
                  </li>

                  {/* <---- Creative Download ----> */}
                  <li>
                    <NavLink
                      to={`/${dynamicRoute}/${userData?.role}/creative-download`}
                      className="sidebar_link_a"
                    >
                      <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-layer-group" : "fa-solid sidebar_icons fa-layer-group"}></i>{" "}
                      <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}>Creative Download</span>
                    </NavLink>
                  </li>

                  {userData?.role !== "rm" && (
                    <li>
                      <NavLink
                        to={`/${dynamicRoute}/${userData?.role}/xpandsupport`}
                        className="sidebar_link_a"
                      >
                        <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-headset" : "fa-solid sidebar_icons fa-headset"}></i>{" "}
                        <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}>X-pand Support</span>
                      </NavLink>
                    </li>
                  )}
                   <li>
                      <NavLink
                        to={`/${dynamicRoute}/${userData?.role}/revenue`}
                        className="sidebar_link_a"
                      >
                        <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-layer-group" : "fa-solid sidebar_icons fa-layer-group"}></i>{" "}
                        <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}>Revenue Update</span>
                      </NavLink>
                    </li>
                  {/* <---- Register Business ----> */}
                  <li>
                    <NavLink
                      to={`/${dynamicRoute}/${userData?.role}/register-business`}
                    >
                      <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-briefcase" : "fa-solid sidebar_icons fa-briefcase"}></i>{" "}
                      <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}>Register Business</span>
                    </NavLink>
                  </li>

                  {/* <---- Create Business for CP ----> */}
                  {
                    userData?.role === 'cp' && (
                  <li
                    style={{ padding: "0" }}
                    onClick={() => {
                      showDropdown?.business === true
                        ? setShowDropdown({
                            ...showDropdown,
                            business: false,
                          })
                        : setShowDropdown({
                            ...showDropdown,
                            business: true,
                          });
                    }}
                  >
                    <li
                      className={` ${
                        showDropdown?.business && "active-sub-menu-dropdown"
                      }`}
                    >
                      <Link
                        className="sidebar_link_a"
                        style={
                          navSideHide || (showDropdown.lms || showDropdown.supportTickets || showDropdown.business)
                            ? null
                            : {
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }
                        }
                      >
                        <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-calendar-days" : "fa-solid fa-calendar-days sidebar_icons"}></i>
                        <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}> Business Plan</span>
                        {!showDropdown?.business ? (
                          <i
                            className="fa-solid fa-caret-down"
                            style={
                              navSideHide
                                ? null
                                : {
                                    paddingLeft: "7px",
                                  }
                            }
                          ></i>
                        ) : (
                          <i
                            className="fa-solid fa-caret-up"
                            style={
                              navSideHide
                                ? null
                                : {
                                    paddingLeft: "7px",
                                  }
                            }
                          ></i>
                        )}
                      </Link>
                    </li>
                    {showDropdown?.business && (
                      <ul
                        className="drop-down-bar sub-menu list-unstyled "
                        style={
                          navSideHide
                            ? null
                            : {
                                textAlign: "center",
                                paddingTop: "0",
                                // paddingLeft: "3px",
                              }
                        }
                      >
                       
                          <li className="sub-menu-list1" id="sub-menu-list1">
                            <NavLink
                              to={`/${dynamicRoute}/${userData?.role}/business-plan`}
                              className="sidebar_link_a "
                              id="sub-menu-link1"
                              style={
                                navSideHide
                                  ? {
                                      paddingLeft: "30px",
                                    }
                                  : null
                              }
                            >
                              <i className="fa fa-id-card-clip sidebar_icons"></i>
                              <span
                                style={
                                  navSideHide
                                    ? null
                                    : {
                                        display: "none",
                                      }
                                }
                              >
                                Create Business Plan
                              </span>
                            </NavLink>
                          </li>
                      
                        <li className="sub-menu-list2" id="sub-menu-list2">
                          <NavLink
                            to={`/${dynamicRoute}/${userData?.role}/business-design`}
                            className="sidebar_link_a"
                            id="sub-menu-link2"
                            style={
                              navSideHide
                                ? {
                                    paddingLeft: "30px",
                                  }
                                : null
                            }
                          >
                            <i className="fa-solid sidebar_icons fa-users"></i>
                            <span
                              style={
                                navSideHide
                                  ? null
                                  : {
                                      display: "none",
                                    }
                              }
                            >
                             Design Business Plan
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>
   
   )
  }
   {/* <---- Create Business for RM ----> */}
   {
                    userData?.role === 'rm' && (
                  <li
                    style={{ padding: "0" }}
                    onClick={() => {
                      showDropdown?.business === true
                        ? setShowDropdown({
                            ...showDropdown,
                            business: false,
                          })
                        : setShowDropdown({
                            ...showDropdown,
                            business: true,
                          });
                    }}
                  >
                    <li
                      className={` ${
                        showDropdown?.business && "active-sub-menu-dropdown"
                      }`}
                    >
                      <Link
                        className="sidebar_link_a"
                        style={
                          navSideHide || (showDropdown.lms || showDropdown.supportTickets || showDropdown.business)
                            ? null
                            : {
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }
                        }
                      >
                        <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-calendar-days" :"fa-solid fa-calendar-days sidebar_icons"}></i>
                        <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}> Business Plan</span>
                        {!showDropdown?.business ? (
                          <i
                            className="fa-solid fa-caret-down"
                            style={
                              navSideHide
                                ? null
                                : {
                                    paddingLeft: "7px",
                                  }
                            }
                          ></i>
                        ) : (
                          <i
                            className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline fa-caret-up " :"fa-solid fa-caret-up"}
                            style={
                              navSideHide
                                ? null
                                : {
                                    paddingLeft: "7px",
                                  }
                            }
                          ></i>
                        )}
                      </Link>
                    </li>
                    {showDropdown?.business && (
                      <ul
                        className="drop-down-bar sub-menu list-unstyled "
                        style={
                          navSideHide
                            ? null
                            : {
                                textAlign: "center",
                                paddingTop: "0",
                                // paddingLeft: "3px",
                              }
                        }
                      >
                       
                          <li className="sub-menu-list1" id="sub-menu-list1">
                            <NavLink
                              to={`/${dynamicRoute}/${userData?.role}/business-plan`}
                              className="sidebar_link_a "
                              id="sub-menu-link1"
                              style={
                                navSideHide
                                  ? {
                                      paddingLeft: "30px",
                                    }
                                  : null
                              }
                            >
                              <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa fa-id-card-clip  d-inline" :"fa fa-id-card-clip sidebar_icons"}></i>
                              <span
                                style={
                                  navSideHide || (showDropdown.lms || showDropdown.supportTickets || showDropdown.business)
                                    ? null
                                    : {
                                        display: "none",
                                      }
                                }
                              >
                                Create Business Plan
                              </span>
                            </NavLink>
                          </li>
                      
                        <li className="sub-menu-list2" id="sub-menu-list2">
                          <NavLink
                            to={`/${dynamicRoute}/${userData?.role}/rmbusiness-design`}
                            className="sidebar_link_a"
                            id="sub-menu-link2"
                            style={
                              navSideHide
                                ? {
                                    paddingLeft: "30px",
                                  }
                                : null
                            }
                          >
                            <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline fa-users" :"fa-solid sidebar_icons fa-users"}></i>
                            <span
                              style={
                                navSideHide || (showDropdown.lms || showDropdown.supportTickets || showDropdown.business)
                                  ? null
                                  : {
                                      display: "none",
                                    }
                              }
                            >
                             Design Business Plan
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>
   
   )
  }
  
                  {/* <---- Equity Request ----> */}

                  {userData?.role === "rm" && (
                    <li>
                      <NavLink
                        to={`/${dynamicRoute}/${userData?.role}/equity`}
                        className="sidebar_link_a"
                      >
                        <i class={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-money-bill-wave" : "fa-solid fa-money-bill-wave sidebar_icons"}></i>{" "}
                        <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}> Equity Request</span>
                      </NavLink>
                    </li>
                  )}
                  {/* <----- My Revenue ------> */}
                  {/* <li>
                    <Link
                      to=""
                      className="sidebar_link_a"
                      //onClick={activeHandler}
                    >
                      <i className="fa-solid sidebar_icons fa-coins"></i>{" "}
                      <span className="side_hide">My Revenue</span>
                    </Link>
                  </li> */}
                  {/* <---- My Profile ----> */}
                  <li>
                    <NavLink to={`/${dynamicRoute}/${userData?.role}/profile`}>
                      <i className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-user" : "fa-solid sidebar_icons fa-user"}></i>{" "}
                      <span className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}>My Profile</span>
                    </NavLink>
                  </li>

                  {/* <------ Logout ------> */}
                  <li>
                    <Link to="#" onClick={() => setLogoutPopup(true)}>
                      <i
                        className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "fa-solid d-inline  fa-right-from-bracket" : "fa-solid sidebar_icons fa-right-from-bracket"}
                        onClick={() => setLogoutPopup(true)}
                      ></i>{" "}
                      <span
                        className={(showDropdown.lms || showDropdown.supportTickets || showDropdown.business) === true ? "" : "side_hide"}
                        onClick={() => setLogoutPopup(true)}
                      >
                        Logout
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="p-0">
            <header
              id="header"
              className="header sidebarHeader px-3 d-flex align-items-center top-nav justify-content-between"
            >
              <div className="container-fluid d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between">
                  <Link
                    to=""
                    className="logo sidebar_link_a"
                    //onClick={activeHandler}
                    // className=''
                  >
                    {/* <!-- Uncomment the line below if you also wish to use an image logo --> */}
                    <img src={PUBLIC_PATH + "xPandLogo.png"} alt="brandLogo" />
                  </Link>

                  <i
                    onClick={sidebarController}
                    className="fa-solid fa-bars fs-5  show-sidebar-menu"
                    style={{
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
                {/* <!-- .navbar --> */}
                <div className="loguser-links">
                  {/* <----- logout btn ----> */}
                  <div className="register-links">
                    {showPortal && (
                    userData?.role !== "rm" && userData?.role !== "fm" && (
                      <div className="reg-btn">
                        <Link
                          onClick={handlePortalChange}
                          to=""
                          className="sidebar_link_a"
                        >
                          Switch to Invester
                        </Link>
                      </div>
                    ))}
                  </div>

                  {/* <-------  CheckIn CheckOut --------> */}
                  {userData?.role === "rm" && (
                    <div className="register-links d-flex ">
                      {showCheckIn ? (
                        <>
                          <div className="d-flex flex-row justify-content-center  align-items-center ">
                            <span className="checkin-timer ">
                              {formatTimer(timer)}
                            </span>
                            <h6 className="pe-2 mt-2">
                              {formatTime(checkInTime)}
                            </h6>
                            <div
                              className="reg-btn"
                              onClick={() => setCheckOutPopUp(true)}
                            >
                              <Link to="" className="sidebar_link_a">
                                Check Out
                              </Link>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                         
                            <Link to="" className="sidebar_link_a reg-btn" onClick={handleCheckin} disabled={isCheckinClicked} >
                              Checkin
                            </Link>
                          
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </header>

            {/* <!-- End Header --> */}

            {/* <!-- End Footer --> */}
          </div>
        </div>
      </div>
      {logoutPopup && (
        <div className="cd-popup" role="alert">
          <div className="cd-popup-container">
            <img
              src={PUBLIC_PATH + "assets/img/icons/logout.png"}
              alt="logoutLogo"
              className="popupImg"
            />
            <h5>Are you sure you want to logout?</h5>
            <ul className="cd-buttons">
              <li>
                <Link to="" onClick={onLogout}>
                  Yes
                </Link>
              </li>
              <li>
                <Link to="" onClick={() => setLogoutPopup(false)}>
                  No
                </Link>
              </li>
            </ul>
            <Link
              to=""
              className="cd-popup-close img-replace"
              onClick={() => setLogoutPopup(false)}
            ></Link>
          </div>
        </div>
      )}

      {/* <-- CheckOut Popup ---> */}
      {checkOutPopUp && (
        <div className="cd-popup" role="alert">
          <div className="cd-popup-container">
            <img
              src={PUBLIC_PATH + "assets/img/icons/logout.png"}
              alt="logoutLogo"
              className="popupImg"
            />
            <h5>Are you sure you want to Check-Out?</h5>
            <ul className="cd-buttons">
              <li>
                <Link to="" onClick={handleCheckout}>
                  Yes
                </Link>
              </li>
              <li>
                <Link to="" onClick={() => setCheckOutPopUp(false)}>
                  No
                </Link>
              </li>
            </ul>
            <Link
              to=""
              className="cd-popup-close img-replace"
              onClick={() => setCheckOutPopUp(false)}
            ></Link>
          </div>
        </div>
      )}

      {/* <!-- cd-popup-container --> */}
      {/* <!-- cd-popup --> */}

      {/* popup form form regiter more details */}
      {/* <RegistrationPopupForm  /> */}
    </>
  );
}

export default Sidebar;
