import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { PUBLIC_PATH } from "../constant/matcher";

function Header(props) {
  const [loginSuggestion, setLoginSuggestion] = useState("d-none");
  const loginOptions = () => {
    if (loginSuggestion === "d-none") {
      setLoginSuggestion("d-block");
    } else {
      setLoginSuggestion("d-none");
    }
  };

  // ====Login route handler
  const loginScreenHandler = (e) => {
    let eventRoute = e.target.getAttribute("dataId");
    let windowRoute = window.location.pathname;
    if (eventRoute !== windowRoute) {
      window.location.replace(`${eventRoute}`);
    }
  };

  const activeHandler = (e) => {
    let btnLinks = document.getElementsByClassName("nav-link-a");
    btnLinks = Array.from(btnLinks);
    btnLinks.forEach((btnLink) => {
      if (
        btnLink.classList.contains("active") &&
        !e.target.classList.contains("active")
      ) {
        e.target.classList.add("active");
        btnLink.classList.remove("active");
      }
    });
  };

  useEffect(() => {
    /* Mobile nav toggle*/
    const mobileNavShow = document.querySelector(".mobile-nav-show");
    const mobileNavHide = document.querySelector(".mobile-nav-hide");

    function mobileNavToogle() {
      document.querySelector("body").classList.toggle("mobile-nav-active");
      mobileNavShow.classList.toggle("d-none");
      mobileNavHide.classList.toggle("d-none");
    }

    document.querySelectorAll(".mobile-nav-toggle").forEach((el) => {
      el.addEventListener("click", function (event) {
        // console.log('run', el)
        event.preventDefault();
        mobileNavToogle();
      });
    });
    /**
     * Hide mobile nav on same-page/hash links
     */
    document.querySelectorAll(".nav-link-a").forEach((navbarlink) => {
      navbarlink.addEventListener("click", () => {
        if (document.querySelector(".mobile-nav-active")) {
          mobileNavToogle();
        }
      });
      if (!navbarlink.hash) return;
      let section = document.querySelector(navbarlink.hash);
      if (!section) return;
    });

    const selectHeader = document.querySelector("#header");
    if (selectHeader) {
      window.addEventListener("scroll", () => {
        window.scrollY > 100
          ? selectHeader.classList.add("sticked")
          : selectHeader.classList.remove("sticked");
      });
    }
  }, []);

  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header
        id="header"
        className="header d-flex align-items-center fixed-top"
      >
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <Link
            to={props.headLogoLink ? props.headLogoLink : "/"}
            className="logo d-flex align-items-center"
          >
            {/* <!-- Uncomment the line below if you also wish to use an image logo --> */}
            <img src={PUBLIC_PATH + "xPandLogo.png"} alt="brandLogo" />
          </Link>

          <div className="d-flex align-items-center">
            <i className="mobile-nav-toggle mobile-nav-show   fa-solid fa-bars"></i>
            <i className="mobile-nav-toggle mobile-nav-hide d-none fa-solid fa-xmark "></i>
          </div>
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a
                  rel="noopener noreferrer"
                  href={props.home ? props.home : "#hero"}
                  className="active nav-link-a"
                  onClick={activeHandler}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  href={props.about ? props.about : "#about"}
                  onClick={activeHandler}
                  className="nav-link-a"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  href={props.features ? props.features : "#features"}
                  className="nav-link-a"
                  onClick={activeHandler}
                >
                  Why Xpand
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  href={props.service ? props.service : "#service"}
                  className="nav-link-a"
                  onClick={activeHandler}
                >
                  Products
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  href={props.testimonial ? props.testimonial : "#testimonials"}
                  className="nav-link-a"
                  onClick={activeHandler}
                >
                  Testimonials
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  href={props.faq ? props.faq : "#faq"}
                  className="nav-link-a"
                  onClick={activeHandler}
                >
                  FAQs
                </a>
              </li>
              {/* <li className='header-btn'>
                <a className='get-a-quote' href='#get-quote' className="nav-link-a">
                  Join Now
                </a>
              </li> */}
              <li className="header-btn responsiveLoginOptionLg">
                <div
                  // to=''
                  className="ms-0 get-a-quote log-drop-main position-relative"
                  style={{ cursor: "pointer" }}
                  onClick={loginOptions}
                >
                  Login/Signup{" "}
                  <i className="fa-sharp fa-solid fa-caret-down"></i>
                  {/* {loginSuggestion && ( */}
                  <div
                    className={`${loginSuggestion} login-dropdown`}
                    id="login-dropdown"
                  >
                    <ul className="position-relative">
                      <li>
                        <Link
                          to=""
                          dataId={"/login/channel-partner"}
                          onClick={loginScreenHandler}
                          className="nav-link-a"
                          id="login-as-cp"
                        >
                          As Channel Partner
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          dataId={"/login/investor"}
                          onClick={loginScreenHandler}
                          id="login-as-inv"
                          className="nav-link-a"
                        >
                          As Investor
                        </Link>
                      </li>
                     
                      <div className="log-arrow">
                        <i className="fa-solid fa-caret-right"></i>
                      </div>
                    </ul>
                  </div>
                  {/* )} */}
                </div>
              </li>
            </ul>
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>
    </>
  );
}

export default Header;
