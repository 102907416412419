import React, { useEffect, useState } from 'react'
import FranchiseSidebar from "../../components/franchise/FranchiseSidebar";
import { Link, useSearchParams } from "react-router-dom";
import DashboardBanner from "../../components/general/DashboardBanner";
import { useSelector } from 'react-redux';
import FranchisePopup from "../../components/general/FranchisePopup";
import { Api_Base_Url, PUBLIC_PATH } from '../../constant/matcher';
import LandingCards from '../../components/franchise/LandingCards';
import NumOfCP from '../../components/franchise/NumOfCP';
import MonthSummary from '../../components/franchise/rm/MonthSummary';
import CreateLead from '../../components/franchise/CreateLead';
import Footer from '../../components/Footer';
import NoticeBoard from '../../components/general/NoticeBoard';
import NewInvestment from "../../components/franchise/rm/NewInvestment";
import BarChartComp from '../../components/charts/BarChartComp';
import axios from 'axios';
import { formatAmount } from '../../utils/helper';

const FranchiseDashboard = () => {
  const [navShow, setNavShow] = useState(true);
  // loggedIn user Data form react-redux
 const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
 const [dynamicRoute, setdynamicRoute] = useState("");
 const [createLeadPopup, setCreateLeadPopup] = useState(false);
 const [quickPopup, setQuickPopup] = useState(false);
 const [searchParams, setSearchParams] = useSearchParams();
 const [SmallWrapper, setSmallWrapper] = useState(" small-wrapper ");
 const [totalCp, setTotalCp] = useState([]);
 const [sipSum, setSipSum] = useState(0);
 const [equitySum, setEquitySum] = useState(0);
 const [mfSum, setMFSum] = useState(0);
 const [realSum, setRealSum] = useState(0);
 const [totalEUM, setTotalEUM] = useState("")
 const [totaleqclient, setTotaleqclient] = useState('')
 const [totalsipclient, setTotalsipclient] = useState('')
 const [totallumpclient, setTotallumpclient] = useState('')
 const [totalrealclient, setTotalrealclient] = useState('')
 const [invEquity, setInvEquity] = useState('')
 const [invReal, setInvReal] = useState('')
 const [invsip, setInvsip] = useState('')
 const [invlump, setInvlump] = useState('')
 const [remptioneEquity, setRemptioneEquity] = useState('')
 const [remptioneReal, setRemptioneReal] = useState('')
 const [remptionesip, setRemptionesip] = useState('')
 const [remptionelump, setRemptionelump] = useState('')
 useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");

  }, [loggedInUserData]);

  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };
  const [qickPass, setQickPass] = useState("");
  const [quickInvest, setQuickInvest] = useState({
    username: loggedInUserData?.eliteusername,
    password: loggedInUserData?.elitepassword,
    loginType: loggedInUserData?.eliterole,
    loginFor: loggedInUserData?.eliterole,
    callbackUrl: window.location.href,
  });

  // revenue charts data
  const revenueChartData = [
    {
      name: 'Total',
      this_month: 761500,
      lastMonth: 1100000
    },
    {
      name: 'Eq',
      this_month: 2000000,
      lastMonth: 1000000
    },
    {
      name: 'MF',
      this_month: 1000000,
      lastMonth: 50000
    },
    {
      name: 'MF-SIP',
      this_month: 1500000,
      lastMonth: 50000
    }
  ]

  const removeQueryParams = () => {
    const param = searchParams.get("et");
    if (param) {
      searchParams.delete("et");
      setSearchParams(searchParams);
    }
  };

  const closePopupHandler = () => {
    removeQueryParams();
    setQuickPopup(false);
  };

  const closeCreateLead = () => {
    setCreateLeadPopup(false);
  };

   // ====================Number of CP=========================
   const getTotalCP = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios.get(`${Api_Base_Url}/fm/my-channel-partner?id=${id}`).then((res) => {
      setTotalCp(res?.data?.myChannel);
    });
  }
  const eqClientsCount =
  totalCp?.filter((result) =>
  result.product.some((name) => name.name === "Equity Advisory")
).length || 0;
  const SIPClientsCount =
  totalCp?.filter((result) =>
  result.product.some((name) => name.name === "Mutual Fund")
).length || 0;
   
  const RECLientsCount =
  totalCp?.filter((result) =>
  result.product.some((name) => name.name === "Monthly Rental Income")
).length || 0;


// ====================Total AUM=========================
const getSIP = () => {
  const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
  const id = loginUserData._id;
  axios
    .get(`${Api_Base_Url}/fm/fmdashboard/totalbusiness?fmid${id}`)
    .then((res) => {
      setSipSum(res?.data?.data[0]?.sip[0]?.sum || 0);
      setEquitySum(res?.data?.data[0]?.equity[0]?.totalvalue || 0);
      setMFSum(res?.data?.data[0]?.mutualfund[0]?.totalvalue || 0);
      setRealSum(res?.data?.data[0]?.realestate[0]?.totalvalue || 0);
      setTotalEUM(
        (res?.data?.data[0]?.sip[0]?.sum || 0) +
        (res?.data?.data[0]?.equity[0]?.totalvalue || 0) +
        (res?.data?.data[0]?.mutualfund[0]?.totalvalue || 0) +
        (res?.data?.data[0]?.realestate[0]?.totalvalue || 0)
      )
    });
  }

  // ==================== API for  Business Updated=========================
const getEquity = () => {
  const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
  const id = loginUserData._id;
  axios
    .get(`${Api_Base_Url}/fm/businessupdate?fmid=${id}`) 
    .then((res) => {
      // setEquity(res?.data?.data[0]?.equity[0]?.sum || 0);
      setTotaleqclient(res?.data?.data[0]?.["totalclient-Equity"][0]?.clientcode || 0);
      setTotalrealclient(res?.data?.data[0]?.["totalclient-realstate"][0]?.clientcode || 0)
      setTotallumpclient(res?.data?.data[0]?.["totalclient-mf-lumpsum"][0]?.clientcode || 0)
      setTotalsipclient(res?.data?.data[0]?.["totalclient-mfsip"][0]?.clientcode || 0)
      setInvEquity(res?.data?.data[0]?.["invesment-Equity"][0]?.sum || 0)
      setInvReal(res?.data?.data[0]?.["invesment-realestate"][0]?.totalvalue || 0)
      setInvlump(res?.data?.data[0]?.["invesment-mf"][0]?.totalvalue || 0)
      setInvsip(res?.data?.data[0]?.["invesment-sip"][0]?.totalvalue || 0)
      setRemptioneEquity(res?.data?.data[0]?.["remption-equity"][0]?.sum || 0)
      setRemptioneReal(res?.data?.data[0]?.["remption-realstate"][0]?.sum || 0)
      setRemptionelump(res?.data?.data[0]?.["invesment-sip"][0]?.sum || 0)
      setRemptionesip(res?.data?.data[0]?.["invesment-sip"][0]?.sum || 0)
      console.log("Total Client", res?.data?.data[0]?.["totalclient-Equity"][0]?.clientcode);
    });
};

useEffect(() => {
  getTotalCP()
  getSIP()
  getEquity()
}, [])

  return (
    <>
    <FranchiseSidebar navContWrapp={navContWrapp} />
   {/* content wrapper section */}
   <section
        className={`dash-content-wrapper  ${SmallWrapper}`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container-fluid" style={{ padding: "0 10px" }}>
            <p>
              <Link to="/dashboard">Dashboard </Link>{" "}
              {/* <i className='fa-solid fa-angle-right'></i> Franchise */}
            </p>
          </div>
        </div>
        <div className="container-fluid mb-4" style={{ padding: "0 10px" }}>
          <div className="row">
            <div className="col-12 p-0">
              <div className="franchise_tab">
                <div className="tab_title_btn">
                  {/* <div className='container'> */}
                  <div
                    className="position-absolute"
                    style={{
                      zIndex: "999",
                      left: "50%",
                      right: "20%",
                      opacity: "0",
                      visiblity: "hidden",
                    }}
                  >
                    {/* <form>
                      <input
                        type='text'
                        placeholder='Name *'
                        name='username'
                        readOnly value={quickInvest.username}
                        onChange={changeHandler}
                      />
                      <input
                        type='text'
                        placeholder='Password *'
                        name='password'
                        readOnly value={quickInvest.password}
                        onChange={changeHandler}
                      />
                      <input
                        type='text'
                        placeholder='Login Type *'
                        name='loginType'
                        readOnly value={quickInvest.loginType}
                        onChange={changeHandler}
                      />
                      <input
                        type='text'
                        placeholder='Login For *'
                        name='loginFor'
                        readOnly value={quickInvest.loginFor}
                        onChange={changeHandler}
                      />
                      <input
                        type='text'
                        placeholder='Callback *'
                        name='callbackUrl'
                        readOnly value={quickInvest.callbackUrl}
                        onChange={changeHandler}
                      />
                    </form> */}
                  </div>
                  {/* </div> */}
                 
                      <div className="btn me-3 reg-btn">
                      <form
                        method="post"
                        className="quickLogForm"
                        action="https://wealthelite.in/Login/login/validate-user"
                      >
                        <input
                          type="text"
                          placeholder="Name *"
                          name="username"
                          readOnly
                          value={quickInvest.username}
                        />
                        <input
                          type="text"
                          placeholder="Password *"
                          name="password"
                          readOnly
                          value={qickPass}
                        />
                        <input
                          type="text"
                          placeholder="Login Type *"
                          name="loginType"
                          readOnly
                          value={quickInvest.loginType}
                        />
                        <input
                          type="text"
                          placeholder="Login For *"
                          name="loginFor"
                          readOnly
                          value={quickInvest.loginFor}
                        />
                        <input
                          type="text"
                          placeholder="Callback *"
                          name="callbackUrl"
                          readOnly
                          value={window.location.href}
                        />
                        <input
                          type="submit"
                          // ref={quickInvestDash}
                          value="Invest In MF"
                          style={{ marginLeft: "-28px" }}
                        />
                      </form>
                     
                    </div>
                   
                 
                  <div
                    className="btn reg-btn"
                    onClick={() => setCreateLeadPopup(true)}
                  >
                    Create lead 
                  </div>
                </div>
              </div>
              {quickPopup && (
                <FranchisePopup closePopupHandler={closePopupHandler} />
              )}
            </div>
          </div>
        </div>

        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          // style={{ margin: '0 10px' }}
        >
          {/* for business_dashboard tab */}
          <div id="business_dashboard" className="tabcontent active">
            <div className="tab_title d-flex mb-3 justify-content-between align-items-center">
              <h3>
                <img
                  className="me-1"
                  src={PUBLIC_PATH + "assets/img/icons/assets.png"}
                  alt=""
                  width="25"
                />
                Total AUM <span className="title_amnt">: &#8377; {formatAmount(totalEUM)}</span>{" "}
                <span className="title_updated">
                  last updated as on, 1 May, 2023
                </span>
              </h3>
            </div>
            <div className="container-fluid px-0">
              <div className="row mb-3 frachise_plan_cards d-flex justify-content-between align-items-center">
                <LandingCards
                  cardTitle={"Equity"}
                  cardAmount={formatAmount(equitySum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/cash.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_up_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-up"></i>}
                />
                <LandingCards
                  cardTitle={"Mutual Fund"}
                  cardAmount={formatAmount(mfSum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/payment.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_up_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-up"></i>}
                />
                <LandingCards
                  cardTitle={"Real Estate"}
                  cardAmount={formatAmount(realSum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/real-estate.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_down_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-down"></i>}
                />
                <LandingCards
                  cardTitle={"SIP Book"}
                  cardAmount={formatAmount(sipSum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/report.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_up_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-up"></i>}
                />
              </div>
              <div className="row" >
                <div className="col-12 col-md-8 franchise_chart_inv">
                  <div className="row">
                    {/* ======First ROW COntent Starts======== */}
                    <div className="col-md-6 mb-3 numb_inv_card">
                      {/* <div className='franchise_chart franchise_amnt_card'> */}
                      {/* =====Number of CP for RM=========== */}
                      
                      <NumOfCP
                      name={'Number of Channel Partner'}
                        totalCP={totalCp ? totalCp.length : 0}
                        eqClients={eqClientsCount}
                        SIPClients={SIPClientsCount}
                        RECLients={RECLientsCount}
                      />
                      {/* </div> */}
                    </div>
                    <div className="col-md-6 mb-3 ">
                
                <NewInvestment
                  equity={0}
                  MFSIP={0}
                  MFLump={0}
                  RealEstate={0}
                />
              </div>
                    <div
                      className='revenue_barchar_container mb-3 col-md-12'
                      // style={{ width: '99%' }}
                    >
                      <div className='franchise_chart revenue_chart'>
                        <div className='mb-3 chart_text d-flex justify-content-between align-items-center'>
                          <h6 className=''>
                            Revenue Update{' '}
                            <span className='revenue_amount'>(&#8377; 0k)</span>
                          </h6>
                          <div className='more_details'>
                            <div className='dropdown'>
                              <form className='position-relative'>
                                <i className='fa-solid fa-calendar-days calender_icon'></i>
                                <select className='form-select select_time_inpt'>
                                  <option value=''>This month</option>
                                  <option value='1'>Quarterly</option>
                                  <option value='2'>This year</option>
                                </select>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className='revenue_barchart'>
                          <BarChartComp
                            revenueChartData={revenueChartData}
                            xTags={'name'}
                            dataTags={'this_month'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ========Meeting Schedule Starts================= */}
                <div className="col-12 col-md-4 mb-3 meeting_list_grid" style={{height: "410px"}}>
                <div className="meeting_sec">
                    
                    <NoticeBoard />
                  </div>
                </div>
                {/* ========Meeting Schedule Ends================= */}
              </div>

              <div className="row" >
                <div className="col-md-12">
                  <div className="row" >

                    {/* <div className="col-md-12 mb-3 ">
                
                      <NewInvestment
                        equity={0}
                        MFSIP={0}
                        MFLump={0}
                        RealEstate={0}
                      />
                    </div> */}
                    {/* <div className="col-md-6 mb-3" >
                      
                      <MonthSummary
                        newMeetings={0}
                        repeatMeetings={0}
                        mouSign={0}
                        NewClAdded={0}
                      />
                    </div> */}
                   
                    <div className="col-md-12 h-100">
                      <div className="franchise_inve table_data_container">
                        <div className="table_sec_heading mb-3 d-flex align-items-center justify-content-between">
                          <h3>Updates</h3>
                          <div className="dropdown">
                            <form className="position-relative">
                              <i className="fa-solid fa-calendar-days calender_icon"></i>
                              <select
                                className="form-select select_time_inpt"
                                
                              >
                                <option value="This month">This month</option>
                                <option value="Jan to Mar">Jan to Mar</option>
                                <option value="Apr to Jun">Apr to Jun</option>
                                <option value="Jul to Sep">Jul to Sep</option>
                                <option value="Oct to Dec">Oct to Dec</option>
                                <option value="This Year">This Year</option>
                              </select>
                            </form>
                          </div>
                        </div>
                        <div className="table_wrapper">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col">MF-SIP</th>
                                <th scope="col">Equity</th>
                                <th scope="col">MRI</th>
                                <th scope="col">MF-LS</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Target</th>
                                <td>
                                 0
                                </td>
                                <td>
                                 0
                                </td>
                                <td>
                                 0
                                </td>
                                <td>
                                  0
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Achieved</th>
                                <td>0</td>
                                <td>0</td>
                                <td>
                                  0
                                </td>
                                <td>0</td>
                              </tr>
                              <tr>
                                <th scope="row">Gap</th>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
              </div>
              <div className="row mt-3">
                <div className="col-md-12 table_grid_container">
                  <div className="franchise_inve table_data_container">
                    <div className="table_sec_heading mb-3 d-flex align-items-center justify-content-between">
                      <h3>Business Update</h3>
                      <div className="dropdown">
                        <form className="position-relative">
                          <i className="fa-solid fa-calendar-days calender_icon"></i>
                          <select className="form-select select_time_inpt">
                            <option value="">This month</option>
                            <option value="1">Quarterly</option>
                            <option value="2">This year</option>
                          </select>
                        </form>
                      </div>
                    </div>
                    <div className="table_wrapper">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Equity</th>
                            <th scope="col">MF-SIP</th>
                            <th scope="col">MF-Lumpsum</th>
                            <th scope="col">Real Estate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Total Client</th>
                            <td>{totaleqclient}</td>
                            <td>{totalsipclient}</td>
                            <td>{totallumpclient}</td>
                            <td>{totalrealclient}</td>
                          </tr>
                          <tr>
                            <th scope="row">Investment</th>
                            <td>{invEquity}</td>
                            <td>{invsip}</td>
                            <td>{invlump}</td>
                            <td>{invReal}</td>
                          </tr>
                          <tr>
                            <th scope="row">Redemption</th>
                            <td>{remptioneEquity}</td>
                            <td>{remptionesip}</td>
                            <td>{remptionelump}</td>
                            <td>{remptioneReal}</td>
                          </tr>
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* =================create Lead Popup============== */}
        {createLeadPopup && <CreateLead closeCreateLead={closeCreateLead} />}

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
    </>
  )
}

export default FranchiseDashboard