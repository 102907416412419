import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Footer from "../../Footer";
import { Api_Base_Url } from "../../../constant/matcher";
import ChatBox from "../../../pages/ChatBox";
import AlertCompleteProfile from "../../cardsDesigns/AlertCompleteProfile";
import DashboardBanner from "../../general/DashboardBanner";
import MyClientsCards from "../../cardsDesigns/MyClientsCards";
import FranchiseSidebar from "../FranchiseSidebar";
import DisscussionBox from "./DisscussionBox";
import { dateFormat } from "../../../utils/helper";
import Loader from "../../general/Loader";

const Helpdesk = () => {
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [dynamicRoute, setdynamicRoute] = useState("");
  const [selectedQueryId, setSelectedQueryId] = useState(null);
  const [queryPopup, setQueryPopup] = useState(false);
  const [supportList, setSupportList] = useState([]);
  const [sub, setSub] = useState("")
  const [ticket, setTicket] = useState("")
  const [remark, setRemark] = useState("")
  const [isloading, setIsLoading] = useState(true)
  const [supportData, setSupportData] = useState({
    subject: "",
    category: "",
    message: "",
  });
  const [successStatus, setSuccessStatus] = useState("fade");
  const [registerNowPopup, setRegisterNowPopup] = useState(false);
  const [active, setActive] = useState("");
  const [inactive, setInactive] = useState("");
  const [closed, setClosed] = useState("");
  const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
  let lmsuserid = loginUserData.leadUserId;
  let name = loginUserData.name
  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
  }, [loggedInUserData]);

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  const chatPopuHandler = (_id) => {
    // console.log("Opening popup for item ID:", _id);
    setSelectedQueryId(_id);
    setQueryPopup(true);
  };

  // close discussion chat popup
  const closeChatPopup = () => {
    setQueryPopup(false);
  };

  const closeSuccessModal = () => {
    setSuccessStatus("fade");
    setRegisterNowPopup(false)
    
  };

  const handleChange = (e) => {
    setSupportData({ ...supportData, [e.target.name]: e.target.value });
   
  };
  const handleSupport = async (e) => {
    e.preventDefault();
    const loggedInUserData = JSON.parse(
      localStorage.getItem("loginUserData")
    );
    const userType = loggedInUserData.role;
    const rmId = loggedInUserData._id;
    const sendBy = loggedInUserData.role
    try {
      const formData = new FormData();
      formData.append("subject", supportData.subject);
      formData.append("description", supportData.message);
      formData.append("usertype", userType);
      formData.append("category", supportData.category);
      formData.append("rmId", rmId);
      formData.append("sendBy", sendBy)
      const response = await axios.post(`${Api_Base_Url}/rm/helpdesk`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.success) {
        setSuccessStatus("d-flex");
      
      }
      getSupportList()
      setSupportData({
        subject: "",
        category: "",
        message: "",
      })
    } catch (error) {}
  };
  
  const getSupportList = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const rmId = loginUserData._id;

    axios.get(`${Api_Base_Url}/rm/helpdesk/list?rmid=${rmId}`).then((res) => {
      const data = res?.data?.result
      setSupportList(data);
      const active = data.filter(result => result.status === 1).length;
      const inActive = data.filter(result => result.status === 0).length;
      const closed = data.filter(result => result.status === 2).length;
      setActive(active)
      setInactive(inActive)
      setClosed(closed)
      setSub(res?.data?.result[0]?.subject)
      setTicket(res?.data?.result[0]?.ticket)
      setRemark(res?.data?.result[0]?.description)
    });
  };

  useEffect(() => {
    getSupportList();
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, []);

  return (
    <>
     {
      isloading ? (
       <Loader/>
      )
      : 
      (
   
        <>
      <FranchiseSidebar navContWrapp={navContWrapp} />
      {loggedInUserData?.role !== "rm" && <AlertCompleteProfile />}
      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                Dashboard
              </Link>{" "}
              <i className="fa-solid fa-angle-right"></i> Xpand Support
            </p>
          </div>
        </div>

        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          style={{ background: "whitesmoke" }}
        >
          <div className="row mb-4">
            <div className="col-md-4 mb-2">
              <MyClientsCards
                cardTitle={"Active"}
                cardValues={active}
                // updateTime={"Since last month"}
                borderColor={4}
                bgGradient={4}
                textColor={4}
                iconsNumb={2}
              />
            </div>
            <div className="col-md-4 mb-2">
              <MyClientsCards
                  cardTitle={"In Active"}
                  cardValues={inactive}
                  borderColor={3}
                  bgGradient={3}
                  textColor={3}
                  iconsNumb={1}
              />
            </div>
            <div className="col-md-4 mb-2">
              <MyClientsCards
                 cardTitle={"Closed"}
                 cardValues={"0"}
                 borderColor={2}
                 bgGradient={2}
                 textColor={2}
                 iconsNumb={3}
              />
            </div>
          </div>
          <div className="row table__row__wrapp">
            <div className="client_table_container py-2">
              <div className="d-flex justify-content-between">
                <h2>Xpand Support</h2>
                <button
                  type="button"
                  className="generate_support"
                  onClick={() => setRegisterNowPopup(true)}
                >
                  Create Support
                </button>
              </div>
              <div className="w-100 overflow-auto">
                <table className="table table-striped client__table">
                  <thead>
                    <tr>
                    <th scope="col">Sr No.</th>
                      <th scope="col">Date</th>
                      <th scope="col">Ticket no.</th>
                      {/* <th scope="col">CP / INV Code</th> */}
                      <th scope="col">Created By</th>
                      <th scope="col">Category</th>
                      <th scope="col">Status</th>
                      <th scope="col">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {supportList &&
                      supportList?.map((result, index) => {
                        return (
                          <tr key={result?._id}>
                            <td>{index + 1}</td>
                            <td>{dateFormat(result?.createdAt)}</td>
                          <td>{result?.ticket}</td>
                          {/* <td>{}</td> */}
                          <td>{loggedInUserData?.name}</td>
                          <td>{result?.category}</td>
                          <td>{result?.status === 0 ? "InActive" : "Active"}</td>
                            <td className="ps-4">
                              <Link
                                to=""
                                className="ps-3"
                                onClick={() => chatPopuHandler(result?._id)}
                              >
                                <i className="fa-solid fa-eye"></i>
                              </Link>
                            </td>
                          </tr>
                          
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* =========discussion chat forum========= */}
        {queryPopup && (
          <DisscussionBox
          selectedQueryId={selectedQueryId}
            closeChatPopup={closeChatPopup}
            queryPopup={queryPopup}
            lmsuserid={lmsuserid}
            name={name}
            subject={sub}
            ticket={ticket}
            remark={remark}
          />
        )}
        {/* =========Create Support========= */}
        {
                registerNowPopup && (

               
                <div className="form_popups popup__container">
        <div className="chagne_pass_form">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex align-items-center position-relative justify-content-center">
                <div
                  className="formByScan_container CreatLeadFormWrapper h-auto"
                  id="formByScan_container"
                  style={{ overflow: "visible", padding: "10px 15px" }}
                >
                  <div
                    className="popup_close_icon createLead"
                    onClick={() => setRegisterNowPopup(false)}
                  >
                    <i
                      className="fa fa-xmark"
                      onClick={() => setRegisterNowPopup(false)}
                    ></i>
                  </div>
                <form onSubmit={handleSupport} className="m-2">
                <h4>Create Support</h4>
                  <div className="mb-2">
                    <label for="recipient-name" className="col-form-label">
                     Enter Subject:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      id="recipient-name"
                      name="subject"
                      required
                      value={supportData.subject}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label for="recipient-name" className="col-form-label">
                     Choose Category:
                    </label>
                    <select
                      name="category"
                      id="recipient-title"
                      className="form-control"
                      value={supportData.category}
                      onChange={handleChange}
                    >
                      <option value="">Select Category</option>
                      <option value="document">Documnet</option>
                      <option value="other">other</option>
                    </select>
                  </div>
                  <div className="mb-2">
                    <label for="message-text" className="col-form-label">
                     Enter Message:
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Message..."
                      id="message-text"
                      required
                      name="message"
                      value={supportData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="generate_support mt-2">
                      Submit
                    </button>
                  </div>
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       )
      }
        {/* <SuccessModal successStatus={'d-flex'} /> */}
        <div
          className={`modal custom-modal align-items-center ${successStatus}`}
          id="formSuccessModal"
          style={{ background: "rgba(0,0,0,0.3)" }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="formSuccessModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header " style={{ background: "#FF4B2B" }}>
                <h5 className="modal-title" id="formSuccessModalLabel">
                  Success
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeSuccessModal}
                  // data-bs-dismiss='modal'
                  // aria-label='Close'
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-popup-inner">
                  <div className="form-icon success">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 130.2 130.2"
                    >
                      <circle
                        className="path circle"
                        fill="none"
                        strokeWidth="6"
                        strokeMiterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <polyline
                        className="path check"
                        fill="none"
                        strokeWidth="6"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="100.2,40.2 51.5,88.8 29.8,67.5 "
                      />
                    </svg>
                  </div>
                  <div className="form-body">
                    <p style={{letterSpacing: "2px"}}>
                      Support Created Successfully
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="popup-btn "
                  style={{ background: "#FF4B2B" }}
                  // data-bs-dismiss='modal'
                  onClick={closeSuccessModal}
                >
                  Okay
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
      </>
           )
          }
    </>
  );
};

export default Helpdesk;
