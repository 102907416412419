import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FranchiseSidebar from "../../components/franchise/FranchiseSidebar";
import Footer from "../../components/Footer";
// import DashboardBanner from '../../components/general/DashboardBanner'
import Profile from "../../components/general/Profile";
import { PUBLIC_PATH } from "../../constant/matcher";
import { useSelector } from "react-redux";
import ClientProfile from "../../components/general/ClientProfile";
import DashboardBanner from "../../components/general/DashboardBanner";
import AlertCompleteProfile from "../../components/cardsDesigns/AlertCompleteProfile";

function ProfilePage() {
   // loggedIn user Data form react-redux
   const loggedInUserData = useSelector(
      (state) => state?.userReducer?.userData
   );
   const [dynamicRoute, setdynamicRoute] = useState("");
   useEffect(() => {
      loggedInUserData?.role === "rm"
         ? setdynamicRoute("team")
         : setdynamicRoute("dashboard");
   }, [loggedInUserData]);

   const [SmallWrapper, setSmallWrapper] = useState("small-wrapper");
   const [navShow, setNavShow] = useState(true);
   // navigation content wrapper handler
   const navContWrapp = (boolData) => {
      setNavShow(boolData);
   };
   return (
      <>
         <FranchiseSidebar navContWrapp={navContWrapp} />
         <AlertCompleteProfile loggedInUserData={loggedInUserData} />
         {/* content wrapper section */}
         <section
            className={`dash-content-wrapper  ${SmallWrapper}`}
            style={{
               paddingLeft: navShow ? "230px" : "70px",
               background: "whitesmoke",
            }}
         >
            <DashboardBanner />

            <div className="breadcrumbs">
               <div className="container">
                  <p>
                     <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                        Dashboard
                     </Link>{" "}
                     {/* <i className='fa-solid fa-angle-right'></i> Home{' '} */}
                     <i className="fa-solid fa-angle-right"></i> Profile
                  </p>
               </div>
            </div>

            <div className="container content-wrapper details-page px-4 py-2">
               {/* <Profile
            imgSrc={PUBLIC_PATH + 'assets/img/team/team-2.jpg'}
            profileTag={'FiNC Fit'}
            username={'Mikichan'}
            userProfession={'Finance Manager'}
            fbLink={''}
            instaLink={''}
            twitterLink={''}
            editLink={''}
          /> */}

               <ClientProfile />
            </div>

            {/* <!-- ======= Footer ======= --> */}
            <Footer
               logoLink={"/"}
               home={"/#home"}
               about={"/#about"}
               service={"/#service"}
               faq={"/#faq"}
               testimonial={"/#testimonials"}
            />
         </section>
      </>
   );
}

export default ProfilePage;
