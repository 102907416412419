import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Api_Base_Url, PUBLIC_PATH } from "../../constant/matcher";
import FranchiseSidebar from "../../components/franchise/FranchiseSidebar";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import DashboardBanner from "../../components/general/DashboardBanner";
import MyClientsCards from "../../components/cardsDesigns/MyClientsCards";
import AlertCompleteProfile from "../../components/cardsDesigns/AlertCompleteProfile";
import axios from "axios";
import ChatBox from "../ChatBox";
import { dateFormat } from "../../utils/helper";

function XpandSupport(props) {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [dynamicRoute, setdynamicRoute] = useState("");
  const [selectedQueryId, setSelectedQueryId] = useState(null);
  const [queryPopup, setQueryPopup] = useState(false);
  const [supportList, setSupportList] = useState([]);
  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [successStatus, setSuccessStatus] = useState("fade");
  const [active, setActive] = useState("");
  const [inactive, setInactive] = useState("");
  const [closed, setClosed] = useState("");
  const [sub, setSub] = useState("")
  const [ticket, setTicket] = useState("")
  const [remark, setRemark] = useState("")
  const [registerNowPopup, setRegisterNowPopup] = useState(false);
  const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
  let lmsuserid = loginUserData.leadUserId;
  const name = loginUserData.name;

  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
  }, [loggedInUserData]);

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

  const chatPopuHandler = (_id) => {
    // console.log("Opening popup for item ID:", _id);
    setSelectedQueryId(_id);
    setQueryPopup(true);
  };

  // close discussion chat popup
  const closeChatPopup = () => {
    setQueryPopup(false);
  };
  const closeSuccessModal = () => {
    setSuccessStatus("fade");
    setRegisterNowPopup(false);
  };

  const handleSupport = async (e) => {
    e.preventDefault();
    const creatorcode = localStorage.getItem("clientId")
    // console.log("CHECK",creatorcode);
    const loggedInUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const userId = loggedInUserData._id;
    const userType = loggedInUserData.role;
    const rmId = loggedInUserData.assignTo;
    const createdby = loggedInUserData.name
    try {
      const formData = new FormData();
      formData.append("subject", subject);
      formData.append("description", description);
      formData.append("userId", userId);
      formData.append("usertype", userType);
      formData.append("category", title);
      formData.append("rmId", rmId); 
      formData.append("createdby", createdby); 
      formData.append("creatorcode", creatorcode); 
      const response = await axios.post(
        `${Api_Base_Url}/xsupport/createsupport`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        setSuccessStatus("d-flex");
      }
      getSupportList();
      setTitle("");
      setDescription("");
      setSubject("");
    } catch (error) {}
  };

  const getStatusLabel = (status) => {
    if (status === 0) {
      return "InActive";
    } else if (status === 1) {
      return "Active";
    } else if (status === 2) {
      return "Closed";
    } else {
      return ""; 
    }
  };
  

  const getSupportList = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const userId = loginUserData._id;

    axios
      .get(`${Api_Base_Url}/xsupport/supportlist?userid=${userId}`)
      .then((res) => {
        setSupportList(res?.data?.data);
        setSub(res?.data?.data[0]?.subject)
      setTicket(res?.data?.data[0]?.ticket)
      setRemark(res?.data?.data[0]?.description)
      const active = res?.data?.data.filter(result => result.status === 1).length;
      const inActive = res?.data?.data.filter(result => result.status === 0).length;
      const closed = res?.data?.data.filter(result => result.status === 2).length;
      setActive(active)
      setInactive(inActive)
      setClosed(closed)
      });
  };

  useEffect(() => {
    getSupportList();
  }, []);

  return (
    <>
      <FranchiseSidebar navContWrapp={navContWrapp} />
      {loggedInUserData?.role === "cp" && <AlertCompleteProfile />}
      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to={`/${dynamicRoute}/${loggedInUserData?.role}`}>
                Dashboard
              </Link>{" "}
              <i className="fa-solid fa-angle-right"></i> Xpand Support
            </p>
          </div>
        </div>

        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          style={{ background: "whitesmoke" }}
        >
          <div className="row mb-4">
            <div className="col-md-4 mb-2">
              <MyClientsCards
                cardTitle={"Active"}
                cardValues={active}
                
                borderColor={1}
                bgGradient={1}
                textColor={1}
                iconsNumb={1}
              />
            </div>
            <div className="col-md-4 mb-2">
              <MyClientsCards
                cardTitle={"In Active"}
                cardValues={inactive}
               
                borderColor={4}
                bgGradient={4}
                textColor={4}
                iconsNumb={1}
              />
            </div>
            <div className="col-md-4 mb-2">
              <MyClientsCards
                cardTitle={"Completed"}
                cardValues={closed}
               
                borderColor={3}
                bgGradient={3}
                textColor={3}
                iconsNumb={3}
              />
            </div>
          </div>
          <div className="row table__row__wrapp">
            <div className="client_table_container py-2">
              <div className="d-flex justify-content-between">
                <h2>Xpand Support</h2>
                <button
                  type="button"
                  className="generate_support"
                  onClick={() => setRegisterNowPopup(true)}
                >
                  Create Support
                </button>
              </div>
              <div className="w-100 overflow-auto">
                <table className="table table-striped client__table">
                  <thead>
                    <tr>
                      <th scope="col">Sr No.</th>
                      <th scope="col">Date</th>
                      <th scope="col">Ticket no.</th>
                      <th scope="col">Client Code</th>
                      <th scope="col">Created By</th>
                      <th scope="col">Category</th>
                      <th scope="col">Status</th>
                      <th scope="col">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {supportList &&
                      supportList?.map((result, index) => {
                        return (
                          <tr key={result?._id}>
                            <td>{index + 1}</td>
                            <td>{dateFormat(result?.createdAt)}</td>
                            <td>{result?.ticket}</td>
                            <td>{result?.creatorcode}</td>
                            <td>{result?.createdby}</td>
                            <td>{result?.category}</td>
                            <td>
                              {getStatusLabel(result?.status)}
                            </td>
                            <td className="ps-4">
                              <Link
                                to=""
                                className="ps-3"
                                onClick={() => chatPopuHandler(result?._id)}
                              >
                                <i className="fa-solid fa-eye"></i>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* =========discussion chat forum========= */}
        {queryPopup && (
          <ChatBox
          

            selectedQueryId={selectedQueryId}
            closeChatPopup={closeChatPopup}
            queryPopup={queryPopup}
            lmsuserid={lmsuserid}
            name={name}
            subject={sub}
         ticket={ticket}
         remark={remark}
          />
        )}
        {/* =========Create Support========= */}
        {
                registerNowPopup && (

               
                <div className="form_popups popup__container">
        <div className="chagne_pass_form">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex align-items-center position-relative justify-content-center">
                <div
                  className="formByScan_container CreatLeadFormWrapper h-auto"
                  id="formByScan_container"
                  style={{ overflow: "visible", padding: "10px 15px" }}
                >
                  <div
                    className="popup_close_icon createLead"
                    onClick={() => setRegisterNowPopup(false)}
                  >
                    <i
                      className="fa fa-xmark"
                      onClick={() => setRegisterNowPopup(false)}
                    ></i>
                  </div>
                <form onSubmit={handleSupport}>
                  <h4>Create Support</h4>
                  <div className="mb-2">
                    <label for="recipient-name" className="col-form-label">
                     Enter Subject:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      id="recipient-name"
                      required
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                  <div className="mb-2">
                    <label for="recipient-name" className="col-form-label">
                    Choose  Category:
                    </label>
                    <select
                      name=""
                      id="recipient-title"
                      className="form-control"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      <option value="Backoffice">Backoffice</option>
                      <option value="Business Related">Business Related</option>
                      <option value="Business Plan">Business Plan</option>
                      <option value="Revenue">Revenue</option>
                    </select>
                  </div>
                  <div className="mb-2">
                    <label for="message-text" className="col-form-label">
                    Enter Message:
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Message"
                      id="message-text"
                      required
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="generate_support">
                      Submit
                    </button>
                  </div>
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       )
      }
        {/* <SuccessModal successStatus={'d-flex'} /> */}
        <div
          className={`modal custom-modal align-items-center ${successStatus}`}
          id="formSuccessModal"
          style={{ background: "rgba(0,0,0,0.3)" }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="formSuccessModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header " style={{ background: "#FF4B2B" }}>
                <h5 className="modal-title" id="formSuccessModalLabel">
                  Success
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeSuccessModal}
                  // data-bs-dismiss='modal'
                  // aria-label='Close'
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-popup-inner">
                  <div className="form-icon success">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 130.2 130.2"
                    >
                      <circle
                        className="path circle"
                        fill="none"
                        strokeWidth="6"
                        strokeMiterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <polyline
                        className="path check"
                        fill="none"
                        strokeWidth="6"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="100.2,40.2 51.5,88.8 29.8,67.5 "
                      />
                    </svg>
                  </div>
                  <div className="form-body">
                    <p>
                      Thank you for filling out our form. Your Support Created Succesfully
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="popup-btn "
                  style={{ background: "#FF4B2B" }}
                  // data-bs-dismiss='modal'
                  onClick={closeSuccessModal}
                >
                  Okay
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
    </>
  );
}

export default XpandSupport;
