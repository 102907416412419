import React, { useRef, useEffect } from "react";
import "../../../styles/lms.css";

function LeadDetialTabs() {
  return (
    <>
      <div className="lead__input__tabs">
        <div className="accordion accordion-flush" id="faqlist">
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#faq-content-1"
              >
                How to get associated with Xpand Wealth?
              </button>
            </h3>
            <div
              id="faq-content-1"
              className="accordion-collapse collapse"
              data-bs-parent="#faqlist"
            >
              <div className="accordion-body">
                To become a channel partner, one need to enroll by signing MoU
                with Xpand Wealth.
              </div>
            </div>
          </div>
          {/* <!-- # Faq item--> */}

          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#faq-content-2"
              >
                Do I need to submit money as fee to be on Xpand Platform?
              </button>
            </h3>
            <div
              id="faq-content-2"
              className="accordion-collapse collapse"
              data-bs-parent="#faqlist"
            >
              <div className="accordion-body">
                No upfront investment required to get associated as channel
                partner.
              </div>
            </div>
          </div>
          {/* <!-- # Faq item--> */}
        </div>
      </div>
    </>
  );
}

export default LeadDetialTabs;
