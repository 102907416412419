import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Api_Base_Url, PUBLIC_PATH } from "../constant/matcher";
import Header from "../components/Header";
import axios from "axios";

import "../styles/loginFormStyle.css";
import {
  useCpInvestorLoginMutation,
  useUserTypeSelectQuery,
} from "../store/services/loginService";
import {
  setLoginUserTokenFmRm,
  loggedInUserData,
} from "../store/reducer/authReducer";

function ForgetPassword() {
  const [validCred, setValidCred] = useState(false);
  const [otpPage, setOtpPage] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [loginMobileValidation, setLoginMobileValidation] = useState(false);
  const [otpValidation, setOtpValidation] = useState(false);
  const [passValidation, setPassValidation] = useState(false);
  const [userTypeCate, setUserTypeCate] = useState("");
  const [showResend, setShowResend] = useState(false);
const [loginDetails, setLoginDetails] = useState({
  phone: "",
  otp: "",
  password: "",
});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginuser } = useParams();
  const [timerSeconds, setTimerSeconds] = useState(10);
  useEffect(() => {
      const timer = setInterval(() => {
        setTimerSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      if (timerSeconds === 0) {
        setShowResend(true);
        clearInterval(timer);
      }
      // Clean up the interval when the component is unmounted
      return () => clearInterval(timer);
  }, [timerSeconds]);

  // API FOR USERTYPE
  const userTypeResp = useUserTypeSelectQuery();
  const userTypeData =
    userTypeResp?.isSuccess &&
    !userTypeResp?.isLoading &&
    userTypeResp.status === "fulfilled" &&
    !userTypeResp?.isError &&
    userTypeResp?.data?.categoryList;

  useEffect(() => {
    setUserTypeCate(
      loginuser && loginuser === "Investor"
        ? userTypeData[1]?._id
        : userTypeData[0]?._id
    );
  }, [loginuser, userTypeData]);

  // LOGIN API
  const [postResetPassword, response = {}] = useCpInvestorLoginMutation();

  // login api handler
  useEffect(() => {
    //  console.log(response);
    if (response?.error?.data) {
      setValidCred(true);
    }
    if (
      response?.isSuccess &&
      !response?.isLoading &&
      response.status === "fulfilled" &&
      !response?.isError
    ) {
      setLoginDetails({
        phone: "",
        password: "",
      });
     localStorage.removeItem("user-token");
     localStorage.setItem("user_Fm_Rm-token", response?.data?.token);
     localStorage.setItem(
        "loginUserData",
        JSON.stringify(response?.data?.user)
      );
      dispatch(setLoginUserTokenFmRm(response?.data?.token));
      dispatch(loggedInUserData(response?.data?.user));

      navigate("/team/rm");
    }
  }, [response, dispatch, navigate]);

  

  const loginChangeHandler = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };
  const checkOtp = async (payload) => {
    const response = await axios.post(
      `${Api_Base_Url}/user/reset-password-otp`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response) {
      setNewPass(true);
    } else {
      setOtpValidation(true);
    }
  };

  const resetPass = (payload) => {
    const response = axios.post(
      `${Api_Base_Url}/user/reset-password`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response) {
      navigate("/login/team");
    } else {
      setPassValidation(true);
    }
  };
//  resend otp handler
const resendOtp = () => {
  let numForOtp = {
    otp: "",
    password: "",
    phone: loginDetails.phone
  };

  if (loginDetails.phone !== "") {
    const response = axios.post(
      `${Api_Base_Url}/user/reset-password-phone`,
      numForOtp,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setShowResend(false);
    setTimerSeconds(10);
   
  } else {
    
  }
};

  const getData = (payload) => {
    const response = axios.post(
      `${Api_Base_Url}/user/reset-password-phone`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response);
    if (response) {
      setOtpPage(true);
    } else {
      console.log("error");
    }
  };
  const loginSubmit = (e) => {
    e.preventDefault();
    var loginData = loginDetails;
    if (
      loginDetails.phone &&
      loginDetails.password === "" &&
      loginDetails.otp === ""
    ) {
      if (loginDetails.phone.length === 10) {
        setLoginMobileValidation(false);
        setValidCred(false);
        getData(loginData);
      } else {
        setLoginMobileValidation(true);
      }
    } else if (loginDetails.otp && loginDetails.password === "") {
      if (loginDetails.otp.length === 6) {
        setOtpValidation(false);
        checkOtp(loginData);
      } else {
        setOtpValidation(true);
      }
    } else {
      resetPass(loginData);
    }
  };

  useEffect(() => {
    if (window.screen.width >= 768) {
      window.scrollTo({ top: 500, left: 0, behavior: "instant" });
    }
    if (window.screen.width < 768) {
      window.scrollTo({ top: 280, left: 0, behavior: "instant" });
    }

    let mobInpt = document.getElementById("moblie-login");
    mobInpt.oninput = () => {
      if (mobInpt.value.length > mobInpt.maxLength)
        mobInpt.value = mobInpt.value.slice(0, mobInpt.maxLength);
    };
  }, []);
  return (
    <>
      <Header
        home="/"
        about="/#about"
        features="/#features"
        service="/#service"
        testimonial="/#testimonials"
        faq="/#faq"
      />
      {/* <!-- End Header --> */}

      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row gy-4 d-flex justify-content-between">
            <div className="hero-form col-md-7 d-flex flex-column justify-content-center position-relative">
              <h2 className="text-uppercase hero-heading">
                Take a First Step Towards Your Independent Financial Journey
              </h2>
              {/* <div className='position-absolute arrow-animated'>
                <img
                  src={PUBLIC_PATH + 'assets/img/arrowGif.gif'}
                  alt='arrow'
                  className=''
                />
              </div> */}
              <div className="banner-text">
                <h3 className="mt-4 text-center text-white text-uppercase">
                  Unlock Potential Of <br /> Unlimited Passive Income
                </h3>
              </div>
            </div>

            <div className="d-none d-md-block col-md-5 col-lg-5 hero-img">
              <img
                src={PUBLIC_PATH + "assets/img/hero-img.svg"}
                className="img-fluid mb-3 mb-lg-0"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero Section --> */}

      <section
        className="px-4 main d-flex align-items-center justify-content-center"
        style={{ background: "whitesmoke" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="container form-slider-container" id="container">
                <div
                  className="form-container sign-up-container"
                  id="sign-up-container"
                ></div>
                <div
                  className="form-container sign-in-container"
                  id="sign-in-container"
                >
                  <form onSubmit={loginSubmit}>
                    <h2 className="form-heading mb-3">Forgot Password</h2>
                    <div className="mobile-form-heading mb-4 w-100 d-flex align-items-center justify-content-center">
                      <h3 id="" className="">
                        Sign In
                      </h3>
                    </div>
                    {/* mobile validation */}
                    {loginMobileValidation && (
                      <p className="mb-2 text-danger text-center">
                        Please put 10 digit mobile number !
                      </p>
                    )}
                    {otpValidation && (
                      <p className="mb-2 text-danger text-center">
                        Please enter a valid OTP !
                      </p>
                    )}
                    {passValidation && (
                      <p className="mb-2 text-danger text-center">
                        Error ... Please try again !
                      </p>
                    )}
                    {/* valid credentials */}
                    {validCred && (
                      <p className="mb-2 text-danger text-center">
                        Please Enter Valid credentials !
                      </p>
                    )}
                    {!newPass ? (
                      <div>
                        {" "}
                        {otpPage ? (
                          <>
                            <input
                              type="number"
                              id="moblie-login"
                              maxLength="6"
                              name="otp"
                              placeholder="Enter your OTP *"
                              required
                              value={loginDetails.otp}
                              onChange={loginChangeHandler}
                            />
                           <div className="resend__opt ms-1 mb-1">
                        {showResend && (
                          <span
                            onClick={resendOtp}
                            className="resend__otp text-uppercase"
                          >
                            Resend OTP
                          </span>
                        )}
                        {!showResend && (
                          <span>
                            Resend OTP in{" "}
                            <span id="resendTimer">{timerSeconds}s</span>
                          </span>
                        )}
                      </div>
                          </>
                        ) : (
                          <>
                            <input
                              type="number"
                              id="moblie-login"
                              maxLength="10"
                              name="phone"
                              placeholder="Mobile Number *"
                              required
                              value={loginDetails.phone}
                              onChange={loginChangeHandler}
                            />
                          </>
                        )}
                      </div>
                    ) : (
                      <input
                        type="password"
                        id="moblie-login"
                        name="password"
                        placeholder="Enter a new Password *"
                        required
                        value={loginDetails.password}
                        onChange={loginChangeHandler}
                      />
                    )}

                    <button className="mt-3">
                      { newPass ? "Reset" : "Proceed"}
                    </button>
                    {/* </Link> */}
                  </form>
                </div>
                <div className="overlay-container">
                  <div className="overlay">
                    <div className="overlay-panel overlay-left">
                      <h1>Hello, Friend!</h1>
                      <p>
                        Enter your personal details and start journey with us
                      </p>
                      {/* <button className='ghost' id='signIn'>
                  Sign In
                </button> */}
                    </div>
                    <div className="overlay-panel overlay-right">
                      <h3>Recover Your Password</h3>
                      <p>Lost your way? Don't worry, we'll help you find it.</p>
                      {/* <button className='ghost' id='signUp'>
                  Sign Up
                </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Footer ======= --> */}
      <footer id="footer" className="footer">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <Link
                // rel='noopener noreferrer'
                to="/"
                className="logo d-flex align-items-center"
              >
                <img
                  src={PUBLIC_PATH + "xPandLogo.png"}
                  alt="footer-logo"
                  className="img-fluid"
                />
              </Link>
              <p className="ps-2">
                Xpand Wealth, a new age platform with its state-of-the-art
                financial distributor network model, offers one stop solution to
                help channel partners to expand their business horizon. Xpand
                Wealth with its unique blend of technology in investment
                management helps in building bespoke investment solutions
                encompassing Financial advisory, Mutual fund Investments and
                Monthly rental income investments.
              </p>
              {/* <div className='social-links d-flex mt-4'>
                <a rel='noopener noreferrer' href='#!' className='twitter'>
                  <i className='bi bi-twitter'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='facebook'>
                  <i className='bi bi-facebook'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='instagram'>
                  <i className='bi bi-instagram'></i>
                </a>
                <a rel='noopener noreferrer' href='#!' className='linkedin'>
                  <i className='bi bi-linkedin'></i>
                </a>
              </div> */}
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a rel="noopener noreferrer" href="/#hero">
                    Home
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="/#about">
                    About
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="/#service">
                    Products
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="/#faq">
                    FAQs
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="/#testimonials">
                    Testimonials
                  </a>
                </li>
                <li>
                  <Link to="/login/team">Team Login</Link>
                </li>
                {/* <li>
                  <Link to='/franchise'>Partner Login</Link>
                </li>
                <li>
                  <Link to='/form-by-scan'>Qr Scan</Link>
                </li> */}
              </ul>
            </div>

            <div className="col-lg-5 col-md-12 footer-contact">
              <h4>Contact Us</h4>
              <p>
                <div>
                  <h6 className="regOff">REGISTERED OFFICE:</h6>
                  <div className="mb-2">
                    109-P NO-4 LSC, Shrestha Vihar, New Delhi- 110092
                  </div>
                </div>
                {/* <br /> */}
                <div>
                  <h6 className="regOff">CORPORATE OFFICE:</h6>
                  <p className="mb-3">
                    304, Tower 4, Assotech Business Cresterra, Sector-135,
                    Noida–201304
                  </p>
                </div>
                <div className="" style={{ lineHeight: 0 }}>
                  <strong>Phone:</strong> +91-8800308006
                </div>
                <br />
                <div className="mb-3" style={{ lineHeight: 1 }}>
                  <strong>For Investor Support:</strong> support@xpandwealth.com
                </div>
                {/* <br /> */}
                <div className="" style={{ lineHeight: 1 }}>
                  <strong>For Business Partner Support:</strong>{" "}
                  help@xpandwealth.com
                </div>
                <br />
              </p>
            </div>
          </div>
        </div>

        <div className="container mt-4">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Xpand Wealth</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
      {/* <!-- End Footer --> */}
    </>
  );
}

export default ForgetPassword;
