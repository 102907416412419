import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PUBLIC_PATH } from "../../../constant/matcher";
import FranchiseSidebar from "../../../components/franchise/FranchiseSidebar";
import Footer from "../../../components/Footer";
import { useSelector } from "react-redux";
import "../../../styles/lms.css";
import DashboardBanner from "../../../components/general/DashboardBanner";
import LeadDetialTabs from "../../../components/franchise/Lms/LeadDetialTabs";

function LeadDetails() {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  const [dynamicRoute, setdynamicRoute] = useState("");
  useEffect(() => {
    loggedInUserData?.role === "rm"
      ? setdynamicRoute("team")
      : setdynamicRoute("dashboard");
  }, [loggedInUserData]);

  const [navShow, setNavShow] = useState(true);
  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };
  return (
    <>
      <FranchiseSidebar navContWrapp={navContWrapp} />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper small-wrapper`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        {/* banner sec */}
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container">
            <p>
              <Link to={"/" + dynamicRoute + "/" + loggedInUserData?.role}>
                Dashboard
              </Link>{" "}
              <i className="fa-solid fa-angle-right"></i>
              <Link
                to={`/${dynamicRoute + "/" + loggedInUserData?.role}/my-leads`}
              >
                {" "}
                My Leads
              </Link>{" "}
              <i className="fa-solid fa-angle-right"></i> Lead Detials
            </p>
          </div>
        </div>

        <div
          className="content-wrapper viewLeadDetails main-dash-wrapper pt-3 px-4   pb-5"
          style={{ background: "" }}
        >
          <div className="row">
            <div className="p-0">
              <h2>Lead Details</h2>
            </div>
          </div>
          <div className="row">
            <LeadDetialTabs />
          </div>
        </div>

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
    </>
  );
}

export default LeadDetails;
