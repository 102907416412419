import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PUBLIC_PATH } from "../../constant/matcher";
import { useRaiseQueryApiMutation } from "../../store/services/registerApiService";
import { useSelector } from "react-redux";
import '../../styles/inquiryFormStyle.css'

function RaisedQueryComp(props) {
  const userData = useSelector((state) => state?.userReducer?.userData);

  const [validationCheck, setValidationCheck] = useState("");
  const [successStatus, setSuccessStatus] = useState("fade");
  const closeSuccessModal = () => {
    setSuccessStatus("fade");
    props?.closeQueryPopup();
  };

  // const [queryFormDetails, setQueryFormDetails] = useState({
  //   message: '',
  //   productId: '',
  //   topicId: '',
  //   productType: ','
  // });

  const [message, setMessage] = useState("");

  // raise query api
  const [postQuery, queryResp = {}] = useRaiseQueryApiMutation();
  useEffect(() => {
    // console.log(queryResp?.data);
    if (
      queryResp &&
      !queryResp?.isLoading &&
      queryResp?.isSuccess &&
      queryResp?.data?.success
    ) {
      setSuccessStatus("d-flex");
    }
    if (
      queryResp &&
      !queryResp?.isLoading &&
      queryResp?.isSuccess &&
      !queryResp?.data?.success
    ) {
      setValidationCheck("an error occured");
    }
  }, [queryResp]);
  const submitQuery = (e) => {
    e.preventDefault();
    let finalData = {};
    finalData.productId = props.productId;
    finalData.productTitleId = props.topicId;
    finalData.subject = props.title;
    finalData.description = message;
    finalData.userId = userData?._id;
    // console.log(finalData);
    // post data in api
    postQuery(finalData);
  };

  return (
    <>
      <div className="form_popups popup__container">
        {/* <div className='chagne_pass_form'> */}
        <div className="raised_query_wrapper p-0 position-relative overflow-visible">
          <div className="popup_close_icon" onClick={closeSuccessModal}>
            <i className="fa fa-xmark" onClick={closeSuccessModal}></i>
          </div>
          <div
            className="raiseQimg text-center p-2 rounded pt-4 mb-2"
            style={{ background: "aliceblue" }}
          >
            <img
              src={PUBLIC_PATH + "assets/img/querybg.png"}
              className="img-fluid m-auto text-center"
              alt="icon"
              style={{ width: "180px" }}
            />
            <div className="queryTitle">{props.title}</div>
          </div>
          <div className="queryForm px-3 pb-3">
            <form onSubmit={submitQuery}>
              {validationCheck !== "" && (
                <p className="text-danger mb-0 text-center">
                  {validationCheck}
                </p>
              )}
              <div className="form-group">
                <label
                  className="mb-2"
                  style={{ fontSize: "16px", fontWeight: "600" }}
                >
                  Please Write Us About Your Query
                </label>
                <textarea
                  rows="3"
                  name="message"
                  className="w-100 form-control"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type Your Message..."
                ></textarea>
              </div>
              <div className="text-end mt-3">
                <button className="btn subBtn">{queryResp?.isLoading?'Submit...': 'Submit'}</button>
              </div>
            </form>
          </div>
        </div>
        {/* </div> */}
      </div>
      {/* <SuccessModal successStatus={'d-flex'} /> */}
      <div
        className={`modal custom-modal align-items-center ${successStatus}`}
        id="formSuccessModal"
        style={{ background: "rgba(0,0,0,0.3)" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="formSuccessModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header " style={{ background: "#FF4B2B" }}>
              <h5 className="modal-title" id="formSuccessModalLabel">
                Success
              </h5>
              <button
                type="button"
                className="close"
                onClick={closeSuccessModal}
                // data-bs-dismiss='modal'
                // aria-label='Close'
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-popup-inner">
                <div className="form-icon success">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 130.2 130.2"
                  >
                    <circle
                      className="path circle"
                      fill="none"
                      strokeWidth="6"
                      strokeMiterlimit="10"
                      cx="65.1"
                      cy="65.1"
                      r="62.1"
                    />
                    <polyline
                      className="path check"
                      fill="none"
                      strokeWidth="6"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      points="100.2,40.2 51.5,88.8 29.8,67.5 "
                    />
                  </svg>
                </div>
                <div className="form-body">
                  <p>
                    Thank you for filling out our query form. We are glad that
                    you joined us.
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Link
                to="#"
                className="popup-btn "
                style={{ background: "#FF4B2B" }}
                // data-bs-dismiss='modal'
                onClick={closeSuccessModal}
              >
                Okay
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RaisedQueryComp;
