import React from "react";

const FieldDateInput = (props) => {
   // console.log("FiledDateInput : ", props);
   const handleChange = (event) => {
      props.handleChange({
         name: props?.name,
         value: event?.target?.value,
      });
   };
   return (
      <>
         <p className="m-0 ">
            <input
               type="date"
               className="updateInputBox form-control h-auto"
               aria-describedby="inputGroup-sizing-sm"
               name={props?.name}
               value={props?.value}
               onChange={handleChange}
            />
         </p>
      </>
   );
};

export default FieldDateInput;
