import React, { useEffect, useState, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Api_Base_Url, PUBLIC_PATH } from "../../../constant/matcher";
import FranchiseSidebar from "../../../components/franchise/FranchiseSidebar";
import "../../../styles/card.css";
import Footer from "../../../components/Footer";
import LandingCards from "../../../components/franchise/LandingCards";
import "../../../styles/franchiseSecStyle.css";
import CryptoJS from "crypto-js";
import { useSelector } from "react-redux";
import FranchisePopup from "../../../components/general/FranchisePopup";
import NumOfCP from "../../../components/franchise/NumOfCP";
import SupportTickets from "../../../components/franchise/rm/SupportTickets";
import MonthSummary from "../../../components/franchise/rm/MonthSummary";
import NewInvestment from "../../../components/franchise/rm/NewInvestment";
import NoticeBoard from "../../../components/general/NoticeBoard";
import CreateLead from "../../../components/franchise/CreateLead";
import DashboardBanner from "../../../components/general/DashboardBanner";
import axios from "axios";
import { formatAmount, formatDate, showformatDate } from "../../../utils/helper";
import Loader from "../../../components/general/Loader";
import {  getCheckinDetails } from "../../../constant/utils";

function Dashboard() {
  // loggedIn user Data form react-redux
  const loggedInUserData = useSelector((state) => state?.userReducer?.userData);
  // console.log(loggedInUserData)

  // for quick invest
  const [searchParams, setSearchParams] = useSearchParams();
  const [quickPopup, setQuickPopup] = useState(false);

  const [createLeadPopup, setCreateLeadPopup] = useState(false);
  const [meetingUpdate, setMeetingUpdate] = useState([]);
  const [cpCount, setCpCount] = useState([]);
  const [totalCp, setTotalCp] = useState([]);
  const [monthly, setMonthly] = useState([]);
  const [targetData, setTargetData] = useState({});
  const [realInvestement, setRealInvestement] = useState('');
  const [equityInvestement, setEquityInvestement] = useState('');
  const [sipInvestement, setSIPnvestement] = useState('');
  const [lumpInvestement, setLumpInvestement] = useState('');
  const [sipSum, setSipSum] = useState(0);
  const [equitySum, setEquitySum] = useState(0);
  const [mfSum, setMFSum] = useState(0);
  const [realSum, setRealSum] = useState(0);
  const [equity, setEquity] = useState(0);
  const [update, setUpdate] = useState('');
  const [achieved, setAchieved] = useState([]);
  const [selected, setSelected] = useState("This month");
  const [totalCount, setTotalCount] = useState('');
  const [status0Count, setStatus0Count] = useState('');
  const [status1Count, setStatus1Count] = useState('');
  const [status2Count, setStatus2Count] = useState('');
  const [show, setShow] = useState(false)
  const [totalEUM, setTotalEUM] = useState("")
  const [totaleqclient, setTotaleqclient] = useState('')
const [totalsipclient, setTotalsipclient] = useState('')
const [totallumpclient, setTotallumpclient] = useState('')
const [totalrealclient, setTotalrealclient] = useState('')
const [invEquity, setInvEquity] = useState('')
const [invReal, setInvReal] = useState('')
const [invsip, setInvsip] = useState('')
const [invlump, setInvlump] = useState('')
const [remptioneEquity, setRemptioneEquity] = useState('')
const [remptioneReal, setRemptioneReal] = useState('')
const [remptionesip, setRemptionesip] = useState('')
const [remptionelump, setRemptionelump] = useState('')
const [slugs, setSlugs] = useState('')
  const [isloading, setIsLoading] = useState(true)
  const closeCreateLead = () => {
    setCreateLeadPopup(false);
  };
  const removeQueryParams = () => {
    const param = searchParams.get("et");
    if (param) {
      searchParams.delete("et");
      setSearchParams(searchParams);
    }
  };
  const closePopupHandler = () => {
    removeQueryParams();
    setQuickPopup(false);
  };
  useEffect(() => {
    if (searchParams?.size > 0) {
      setQuickPopup(true);
    }
  }, [searchParams]);

  const [navShow, setNavShow] = useState(true);
  const [SmallWrapper, setSmallWrapper] = useState(" small-wrapper ");

  // const quickInvestDash = useRef()

  const [quickInvest, setQuickInvest] = useState({
    username: loggedInUserData?.eliteusername,
    password: loggedInUserData?.elitepassword,
    loginType: loggedInUserData?.eliterole,
    loginFor: loggedInUserData?.eliterole,
    callbackUrl: window.location.href,
  });
  // useEffect(() => {
  //   console.log(quickInvest);
  // }, [quickInvest]);

  const [qickPass, setQickPass] = useState("");

  // for foramting encription
  var CryptoJSAesJson = {
    stringify: function (cipherParams) {
      var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j);
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(j.ct),
      });
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
      return cipherParams;
    },
  };

  useEffect(() => {
    var key = "W@ealth!@$#$(%#H(^%)";
    // Encrypt
    var EncryptedPass = CryptoJS.AES.encrypt(
      JSON.stringify(quickInvest.password),
      key,
      { format: CryptoJSAesJson }
    ).toString();

    // console.log(EncryptedPass)

    setQickPass(EncryptedPass);

    setQuickInvest({ ...quickInvest, [quickInvest.password]: EncryptedPass });
  }, []);

  // navigation content wrapper handler
  const navContWrapp = (boolData) => {
    setNavShow(boolData);
  };

// ====================Upcoming Meetings=========================
let slug ;
  const getMeeting = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios.get(`${Api_Base_Url}/rm/upcomingmeeting?rmid=${id}`).then((res) => {
      const upcomingMeetings = res?.data?.leaddata || [];
      upcomingMeetings.sort(
        (a, b) => new Date(a?.nextContacted) - new Date(b?.nextContacted)
      );
      setMeetingUpdate(upcomingMeetings);
      const channelPartner = upcomingMeetings.find(meeting => meeting?.userType?.name === 'Channel Partner');
    const investor = upcomingMeetings.find(meeting => meeting?.userType?.name === 'Investor');
    slug = channelPartner ? 'cp' : (investor ? 'invt' : '');
    setSlugs(slug)
      // console.log("SLUG",slug);
    });
  };

  // ====================Supporting Tickets=========================
  const getSupportList = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const rmId = loginUserData._id;

    axios.get(`${Api_Base_Url}/rm/supportrequest?rmid=${rmId}`).then((res) => {
      const supportData = res?.data?.data;
      setTotalCount(supportData.length)
      const status0Count = supportData.filter(result => result.status === 0).length;
    
    const status1Count = supportData.filter(result => result.status === 1).length;
    const status2Count = supportData.filter(result => result.status === 2).length;
      setStatus0Count(status0Count)
      setStatus1Count(status1Count)
      setStatus2Count(status2Count)
    });
  };

  const getCpCount = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios.get(`${Api_Base_Url}/rm/enumerate?rmid=${id}`).then((res) => {
      setCpCount(res?.data?.newData);
    });
  };

  // ====================Number of CP=========================
  const getTotalCP = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios.get(`${Api_Base_Url}/rm/my-channel-partner?id=${id}`).then((res) => {
      setTotalCp(res?.data?.myChannel);
      console.log(res?.data?.myChannel);
    });
  }
  const eqClientsCount = totalCp?.filter((result) =>result?.product?.name === "Equity Advisory").length || 0
  const SIPClientsCount = totalCp?.filter((result) =>result?.product?.name === "Mutual Fund").length || 0
  const RECLientsCount = totalCp?.filter((result) =>result?.product?.name === "Monthly Rental Income").length || 0
//   totalCp?.filter((result) =>
//   result?.product?.some((name) => name?.name === "Monthly Rental Income")
// ).length || 0;
// ====================End of CP=========================
  // ====================Monthly Summary=========================
  const MonthlySummary = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios.get(`${Api_Base_Url}/rm/monthsummery?rmid=${id}`).then((res) => {
      setMonthly(res?.data?.data);
    });
  };

// ====================Total AUM=========================
  const getSIP = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios
      .get(`${Api_Base_Url}/rm/rmdashboard/totalbusiness?rmid=${id}`)
      .then((res) => {
        setSipSum(res?.data?.data[0]?.sip[0]?.sum || 0);
        setEquitySum(res?.data?.data[0]?.equity[0]?.totalvalue || 0);
        setMFSum(res?.data?.data[0]?.mutualfund[0]?.totalvalue || 0);
        setRealSum(res?.data?.data[0]?.realestate[0]?.totalvalue || 0);
        setTotalEUM(
          (res?.data?.data[0]?.equity[0]?.totalvalue || 0) +
          (res?.data?.data[0]?.mutualfund[0]?.totalvalue || 0) +
          (res?.data?.data[0]?.realestate[0]?.totalvalue || 0)
        )
        setUpdate(res?.data?.data[0]?.lastupdate[0]?.createdAt)
      });
  };

  // ==================== API for  Business Updated=========================
  const getEquity = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios
      .get(`${Api_Base_Url}/rm/rmdashboard/businessupdate?rmid=${id}`) 
      .then((res) => {
        // setEquity(res?.data?.data[0]?.equity[0]?.sum || 0);
        setTotaleqclient(res?.data?.data[0]?.["totalclient-Equity"][0]?.clientcode || 0);
        setTotalrealclient(res?.data?.data[0]?.["totalclient-realstate"][0]?.clientcode || 0)
        setTotallumpclient(res?.data?.data[0]?.["totalclient-mf-lumpsum"][0]?.clientcode || 0)
        setTotalsipclient(res?.data?.data[0]?.["totalclient-mfsip"][0]?.clientcode || 0)
        setInvEquity(res?.data?.data[0]?.["invesment-Equity"][0]?.sum || 0)
        setInvReal(res?.data?.data[0]?.["invesment-realestate"][0]?.totalvalue || 0)
        setInvlump(res?.data?.data[0]?.["invesment-mf"][0]?.totalvalue || 0)
        setInvsip(res?.data?.data[0]?.["invesment-sip"][0]?.totalvalue || 0)
        setRemptioneEquity(res?.data?.data[0]?.["remption-equity"][0]?.sum || 0)
        setRemptioneReal(res?.data?.data[0]?.["remption-realstate"][0]?.sum || 0)
        setRemptionelump(res?.data?.data[0]?.["invesment-sip"][0]?.sum || 0)
        setRemptionesip(res?.data?.data[0]?.["invesment-sip"][0]?.sum || 0)
        // console.log("Total Client", res?.data?.data[0]?.["totalclient-Equity"][0]?.clientcode);
      });
  };

  // ==================== API for Total client from Business Updated=========================
  const getTotalClienty = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
  
    axios
      .get(`${Api_Base_Url}/rm/rmdashboard/totalclient?rmid=${id}`)
      .then((res) => {
        // setTotalClient(res?.data?.data[0]?.equityclient[0]?.clientcode || 0);
      });
  };

  const newMeetings =
  monthly.find((item) => item._id === "New Meeting")?.count || 0;
const repeatMeeting =
  monthly.find((item) => item._id === "Repeat Meeting")?.count || 0;
const mou = monthly.find((item) => item._id === "MOU")?.count || 0;
const NewClAdded =
  monthly.find((item) => item._id === "NewClAdded")?.count || 0;

  // ==================== API for Target from Update Card=========================
  const handleOptionChange = (event) => {
    setSelected(event.target.value)
  };

 
    const monthMapping = {
      "Jan to Mar": ["January", "February", "March"],
      "Apr to Jun": ["April", "May", "June"],
      "Jul to Sep": ["July", "August", "September"],
      "Oct to Dec": ["October", "November", "December"],
      "This Year": ['January','February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',]
    }

  const getCurrentMonthData = (data) => {
    const currentMonth = new Date().toLocaleString("en-US", { month: "long" });
    if (data[currentMonth]) {
      return data[currentMonth];
    }
    return {};
  };

  const getUpdates = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios
      .get(`${Api_Base_Url}/rm/rmBussinessamount?rmid=${id}`)
      .then((res) => {
        let filteredData = {};
        if (selected === "This month") {
          // Filter data for the current month
          const currentMonthData = getCurrentMonthData(res?.data?.amount);
          filteredData = currentMonthData;
        } else {
          // Filter data for selected months
          const selectedMonths = monthMapping[selected];
          if (selected) {
            selectedMonths?.forEach((month) => {
              if (res?.data?.amount[month]) {
                // Merge data for selected months
                Object.keys(res?.data?.amount[month]).forEach((key) => {
                  filteredData[key] =
                    (filteredData[key] || 0) + res?.data?.amount[month][key];
                });
              }
            });
          }
        }
  
        setTargetData(filteredData);
        
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }

   // ==================== End of Target=========================

   // ==================== API for Achived from Update Card=========================
  const getAchieved = async () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    const { startDate, endDate } = calculateStartAndEndDate(selected);
   await axios
      .get(
        `${Api_Base_Url}/rm/rmBussinessRecord/?rmid=${id}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        setAchieved(res?.data.data[0]?.monthlyTotals || []);
       
      });
  };
  

  const getBusinessAmount = (businessType) => {
    const businessItem = achieved?.find(
      (item) => item.business === businessType
    );
    return businessItem ? businessItem?.totalAmount : 0;
  };
 
  // Function to calculate start and end dates based on the selected option
  const calculateStartAndEndDate = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0"); 
    let startDate, endDate;

    if (selected === "This month") {
      startDate = `${currentYear}-${currentMonth}-01`;
      const lastDay = new Date(currentYear, currentDate.getMonth() + 1, 0);
      const lastDayOfMonth = lastDay.getDate().toString().padStart(2, "0"); 

      endDate = `${currentYear}-${currentMonth}-${lastDayOfMonth}`;

      return { startDate, endDate };
    } else if (selected === "Jan to Mar") {
      startDate = `${currentYear}-01-01`;
      endDate = `${currentYear}-03-31`;

      return { startDate, endDate };
    } else if (selected === "Apr to Jun") {
      startDate = `${currentYear}-04-01`;
      endDate = `${currentYear}-06-30`;

      return { startDate, endDate };
    } else if (selected === "Jul to Sep") {
      startDate = `${currentYear}-07-01`;
      endDate = `${currentYear}-09-30`;
      return { startDate, endDate };
    } else if (selected === "Oct to Dec") {
      startDate = `${currentYear}-10-01`;
      endDate = `${currentYear}-12-31`;

      return { startDate, endDate };
    }
     else if (selected === "This Year") {
      startDate = `${currentYear}-01-01`;
      endDate = `${currentYear}-12-31`;

      return { startDate, endDate };
    }
    
    // Default case: Return empty start and end dates
    return { startDate: null, endDate: null };
  };

  const showMF = () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
    axios.get(`${Api_Base_Url}/rm/profile?id=${id}`).then((res) => {
      const data = res?.data?.profileData
      if (data.eliterole && data.eliteusername && data.elitepassword) {
        setShow(true);
      }
    })
  }

  // ==================== API for New Investement=========================
  const getInvestement = async () => {
    const loginUserData = JSON.parse(localStorage.getItem("loginUserData"));
    const id = loginUserData._id;
   await axios
      .get(
        `${Api_Base_Url}/rm/newinvestment?rmid=${id}`
      )
      .then((res) => {
        setEquityInvestement(res?.data.data[0]?.Equity[0]?.sum );
        setRealInvestement(res?.data.data[0]?.realestate[0]?.sum );
        setSIPnvestement(res?.data.data[0]?.sip[0]?.sum )
        setLumpInvestement(res?.data.data[0]?.lumpsum[0]?.sum )
      });
  };

  useEffect(() => {
    getCheckinDetails()
    getTotalCP()
    getMeeting();
    getCpCount();
    MonthlySummary();
    getSIP();
    getUpdates();
    getEquity();
    getTotalClienty();
    getSupportList()
    getAchieved();
    showMF()
    getInvestement()
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, [selected]);

  return (
    <>
    {
      isloading ? (
        <Loader/>
      )
      : 
      (
   
        <>
      <FranchiseSidebar navContWrapp={navContWrapp} />

      {/* content wrapper section */}
      <section
        className={`dash-content-wrapper  ${SmallWrapper}`}
        style={{ paddingLeft: navShow ? "230px" : "70px" }}
      >
        <DashboardBanner />

        <div className="breadcrumbs">
          <div className="container-fluid" style={{ padding: "0 10px" }}>
            <p>
              <Link to="/dashboard">Dashboard </Link>{" "}
              {/* <i className='fa-solid fa-angle-right'></i> Franchise */}
            </p>
          </div>
        </div>
        <div className="container-fluid mb-4" style={{ padding: "0 10px" }}>
          <div className="row">
            <div className="col-12 p-0">
              <div className="franchise_tab">
                <div className="tab_title_btn">
                  {/* <div className='container'> */}
                  <div
                    className="position-absolute"
                    style={{
                      zIndex: "999",
                      left: "50%",
                      right: "20%",
                      opacity: "0",
                      visiblity: "hidden",
                    }}
                  >
                    {/* <form>
                      <input
                        type='text'
                        placeholder='Name *'
                        name='username'
                        readOnly value={quickInvest.username}
                        onChange={changeHandler}
                      />
                      <input
                        type='text'
                        placeholder='Password *'
                        name='password'
                        readOnly value={quickInvest.password}
                        onChange={changeHandler}
                      />
                      <input
                        type='text'
                        placeholder='Login Type *'
                        name='loginType'
                        readOnly value={quickInvest.loginType}
                        onChange={changeHandler}
                      />
                      <input
                        type='text'
                        placeholder='Login For *'
                        name='loginFor'
                        readOnly value={quickInvest.loginFor}
                        onChange={changeHandler}
                      />
                      <input
                        type='text'
                        placeholder='Callback *'
                        name='callbackUrl'
                        readOnly value={quickInvest.callbackUrl}
                        onChange={changeHandler}
                      />
                    </form> */}
                  </div>
                  {/* </div> */}
                  {
                    show && (
                      <div className="btn me-3 reg-btn">
                      <form
                        method="post"
                        className="quickLogForm"
                        action="https://wealthelite.in/Login/login/validate-user"
                      >
                        <input
                          type="text"
                          placeholder="Name *"
                          name="username"
                          readOnly
                          value={quickInvest.username}
                        />
                        <input
                          type="text"
                          placeholder="Password *"
                          name="password"
                          readOnly
                          value={qickPass}
                        />
                        <input
                          type="text"
                          placeholder="Login Type *"
                          name="loginType"
                          readOnly
                          value={quickInvest.loginType}
                        />
                        <input
                          type="text"
                          placeholder="Login For *"
                          name="loginFor"
                          readOnly
                          value={quickInvest.loginFor}
                        />
                        <input
                          type="text"
                          placeholder="Callback *"
                          name="callbackUrl"
                          readOnly
                          value={window.location.href}
                        />
                        <input
                          type="submit"
                          // ref={quickInvestDash}
                          value="Invest In MF"
                          style={{ marginLeft: "-28px" }}
                        />
                      </form>
                     
                    </div>
                    )
                  }
                 
                  <div
                    className="btn reg-btn"
                    onClick={() => setCreateLeadPopup(true)}
                  >
                    Create lead 
                  </div>
                </div>
              </div>
              {quickPopup && (
                <FranchisePopup closePopupHandler={closePopupHandler} />
              )}
            </div>
          </div>
        </div>

        <div
          className="content-wrapper franchise_tab_content main-dash-wrapper pt-3 px-3 pb-5"
          // style={{ margin: '0 10px' }}
        >
          {/* for business_dashboard tab */}
          <div id="business_dashboard" className="tabcontent active">
            <div className="tab_title d-flex mb-3 justify-content-between align-items-center">
              <h3>
                <img
                  className="me-1"
                  src={PUBLIC_PATH + "assets/img/icons/assets.png"}
                  alt=""
                  width="25"
                />
                Total AUM <span className="title_amnt">: &#8377; {formatAmount(totalEUM)}</span>{" "}
                <span className="title_updated">
                  last updated as on, {update ? formatDate(update) : ""}
                </span>
              </h3>
            </div>
            <div className="container-fluid px-0">
              <div className="row mb-3 frachise_plan_cards d-flex justify-content-between align-items-center">
                <LandingCards
                  cardTitle={"Equity"}
                  cardAmount={formatAmount(equitySum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/cash.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_up_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-up"></i>}
                />
                <LandingCards
                  cardTitle={"Mutual Fund"}
                  cardAmount={formatAmount(mfSum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/payment.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_up_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-up"></i>}
                />

                <LandingCards
                  cardTitle={"Real Estate"}
                  cardAmount={formatAmount(realSum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/real-estate.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_down_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-down"></i>}
                />
                <LandingCards
                  cardTitle={"SIP Book"}
                  cardAmount={formatAmount(sipSum)}
                  iconSrc={PUBLIC_PATH + "assets/img/icons/report.png"}
                  putUpValue={"0.00%"}
                  updatesTime={"NA"}
                  investType={"put_up_value"}
                  arrowDirection={<i className="fa-solid fa-arrow-up"></i>}
                />
              </div>
              <div className="row" >
                <div className="col-12 col-md-8 franchise_chart_inv">
                  <div className="row" style={{marginTop: "20px"}}>
                    {/* ======First ROW COntent Starts======== */}
                    <div className="col-md-6 mb-3 numb_inv_card">
                      {/* <div className='franchise_chart franchise_amnt_card'> */}
                      {/* =====Number of CP for RM=========== */}
                      <NumOfCP
                      name={'Number of Channel Partner'}
                        totalCP={totalCp ? totalCp.length : 0}
                        eqClients={eqClientsCount}
                        SIPClients={SIPClientsCount}
                        RECLients={RECLientsCount}
                      />
                      {/* </div> */}
                    </div>
                    <div className="col-md-6 mb-3 pie_chart_cont" > 

                      <div className="franchise_amnt_dashboardInfo land_card_2" >
                        <div className="wrap">
                          <div className="franchise_inve">
                            <div className="card_6_cont">
                              <div className="franchise_inve_content mb-3">
                                <h6 className="inve_cont_main text-start">
                                  Supporting Tickets
                                </h6>
                                <p className="inve_cont_main">
                                  {/* {props.suppTickets} */}
                                  <p>{totalCount}</p>
                                </p>
                              </div>
                              <div className="franchise_inve_content">
                                <h6>Not Initiated</h6>
                                {/* <p>{props.notInit}</p> */}
                                <p>{status0Count ? status0Count : 0}</p>
                              </div>
                              <div className="franchise_inve_content">
                                <h6>In Progress</h6>
                                {/* <p>{props.pendingTickts}</p> */}
                                <p>{status1Count ? status1Count : 0}</p>
                              </div>
                              <div className="franchise_inve_content">
                                <h6>Closed</h6>
                                {/* <p>{props.pendingTickts}</p> */}
                                <p>{status2Count ? status2Count : 0}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ========Meeting Schedule Starts================= */}
                <div className="col-12 col-md-4 mb-3 meeting_list_grid" style={{height: "270px"}}>
                  <div className="meeting_sec" style={{height: "270px", overflowY: "scroll"}}>
                    <div className="meeting_content-wrapper">
                      <div className="sec_title">
                        <h6>Upcoming Meetings</h6>
                      </div>
                      {meetingUpdate &&
                        meetingUpdate?.map((result) => {
                          return (
                            <Link to={`my-leads/${result._id}/${slugs}`}>
                              <div 
                                className="meeting_list d-flex align-items-center"
                                key={result._id}
                              >
                                <div className="meeting_time">
                                  {/* <h6>07:00</h6> */}
                                </div>
                                <div className="meeting_content d-flex align-items-center">
                                  <div className="icon">
                                    <i>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                        />
                                      </svg>
                                    </i>
                                  </div>
                                  <div className="main_cont ms-2">
                                    <h6 className="mb-0">{result?.name}</h6>
                                    <p
                                      className="m-0"
                                      style={{ fontWeight: "500" }}
                                    >
                                      {result?.userType?.name}
                                    </p>
                                    <p
                                      className="m-0"
                                      style={{ fontWeight: "500" }}
                                    >
                                      {result?.leadstatus?.status}
                                    </p>
                                    <p
                                      className="m-0"
                                      style={{ fontWeight: "500" }}
                                    >
                                      {showformatDate(result?.nextContacted)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                </div>
                {/* ========Meeting Schedule Ends================= */}
              </div>

              <div className="row" >
                <div className="col-md-8">
                  <div className="row" >

                    <div className="col-md-6 mb-3 ">
                      <NewInvestment
                        equity={equityInvestement ? equityInvestement : 0}
                        MFSIP={sipInvestement ? sipInvestement : 0}
                        MFLump={lumpInvestement ? lumpInvestement : 0}
                        RealEstate={realInvestement ? realInvestement : 0}
                      />
                    </div>
                    <div className="col-md-6 mb-3" >
                      <MonthSummary
                        newMeetings={newMeetings}
                        repeatMeetings={repeatMeeting}
                        mouSign={mou}
                        NewClAdded={NewClAdded}
                      />
                    </div>
                   
                    <div className="col-md-12 h-100">
                      <div className="franchise_inve table_data_container">
                        <div className="table_sec_heading mb-3 d-flex align-items-center justify-content-between">
                          <h3>Updates</h3>
                          <div className="dropdown">
                            <form className="position-relative">
                              <i className="fa-solid fa-calendar-days calender_icon"></i>
                              <select
                                className="form-select select_time_inpt"
                                onChange={handleOptionChange}
                                value={selected}
                              >
                                <option value="This month">This month</option>
                                <option value="Jan to Mar">Jan to Mar</option>
                                <option value="Apr to Jun">Apr to Jun</option>
                                <option value="Jul to Sep">Jul to Sep</option>
                                <option value="Oct to Dec">Oct to Dec</option>
                                <option value="This Year">This Year</option>
                              </select>
                            </form>
                          </div>
                        </div>
                        <div className="table_wrapper">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col">MF-SIP</th>
                                <th scope="col">Equity</th>
                                <th scope="col">MRI</th>
                                <th scope="col">MF-LS</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Target</th>
                                <td>
                                  {targetData?.SIP}
                                </td>
                                <td>
                                 {targetData?.EA}
                                </td>
                                <td>
                                  {targetData?.MRI}
                                </td>
                                <td>
                                 {targetData?.LUMPSUM}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Achieved</th>
                                <td>{getBusinessAmount("SIP")}</td>
                                <td>{getBusinessAmount("EqualityAdvisor")}</td>
                                <td>
                                  {getBusinessAmount("MontleyRentalIncome")}
                                </td>
                                <td>{getBusinessAmount("Lumpsum")}</td>
                              </tr>
                              <tr>
                                <th scope="row">Gap</th>
                                <td>{(targetData?.SIP ?? 0) - getBusinessAmount("SIP")}</td>
                                <td>{(targetData?.EA ?? 0) - getBusinessAmount("EqualityAdvisor")}</td>
                                <td>{(targetData?.MRI ?? 0) - getBusinessAmount("MontleyRentalIncome")}</td>
                                <td>{(targetData?.LUMPSUM ?? 0) - getBusinessAmount("Lumpsum")}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  {/* ======target table===== */}
                  {/* <div className='col-md-6 table_grid_container'> */}
                  <div className="meeting_sec">
                    <NoticeBoard />
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 table_grid_container">
                  <div className="franchise_inve table_data_container">
                    <div className="table_sec_heading mb-3 d-flex align-items-center justify-content-between">
                      <h3>Business Update</h3>
                      <div className="dropdown">
                        <form className="position-relative">
                          <i className="fa-solid fa-calendar-days calender_icon"></i>
                          <select className="form-select select_time_inpt">
                            <option value="">This month</option>
                            <option value="1">Quarterly</option>
                            <option value="2">This year</option>
                          </select>
                        </form>
                      </div>
                    </div>
                    <div className="table_wrapper">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Equity</th>
                            <th scope="col">MF-SIP</th>
                            <th scope="col">MF-Lumpsum</th>
                            <th scope="col">Real Estate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Total Client</th>
                            <td>{totaleqclient}</td>
                            <td>{totalsipclient}</td>
                            <td>{totallumpclient}</td>
                            <td>{totalrealclient}</td>
                          </tr>
                          <tr>
                            <th scope="row">Investment</th>
                            <td>{invEquity}</td>
                            <td>{invsip}</td>
                            <td>{invlump}</td>
                            <td>{invReal}</td>
                          </tr>
                          <tr>
                            <th scope="row">Redemption</th>
                            <td>{remptioneEquity}</td>
                            <td>{remptionesip}</td>
                            <td>{remptionelump}</td>
                            <td>{remptioneReal}</td>
                          </tr>
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* =================create Lead Popup============== */}
        {createLeadPopup && <CreateLead closeCreateLead={closeCreateLead} />}

        {/* <!-- ======= Footer ======= --> */}
        <Footer
          logoLink={"/"}
          home={"/#home"}
          about={"/#about"}
          service={"/#service"}
          faq={"/#faq"}
          testimonial={"/#testimonials"}
        />
      </section>
           </>
           )
          }
    </>
  );
}

export default Dashboard;
